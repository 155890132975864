import { updateChartData } from 'model/saga-actions/vizMiddlewareActions'
import { memo, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as viewSel from 'common/store/viewSelector'
import * as modelSel from 'model/store/modelSelector'
import { prepareVarFilter } from 'common/utils/views'

export const ChartVarDataLoader = ({ tid, teamId, aid, cid, vid, modelProps, filter, sort, dates }) => {
	// Subscribe to data
	const viewId = useSelector((state) => viewSel.selectViewId(state, aid))
	const selectChartBreakdown = useMemo(() => viewSel.makeSelectChartBreakdown({ aid, cid, vid }), [aid, cid, vid])
	const breakdown = useSelector((state) => selectChartBreakdown(state))
	const page = useSelector((state) => viewSel.selectViewChartPage(state, aid, cid, vid))
	const varProps = useSelector((state) => modelSel.selectModelVarProps(state, aid, vid))

	const varFilter = useMemo(() => {
		return prepareVarFilter(breakdown, filter, (el) => el.catId)
	}, [breakdown, filter])

	const varSort = useMemo(() => {
		return prepareVarFilter(breakdown, sort, (el) => el.catId)
	}, [breakdown, sort])

	// Execute load if inputs change
	return (
		<Execute
			tid={tid}
			teamId={teamId}
			aid={aid}
			cid={cid}
			viewId={viewId}
			vid={vid}
			breakdown={breakdown}
			page={page}
			modelProps={modelProps}
			varProps={varProps}
			filter={varFilter}
			sort={varSort}
			dates={dates}
		/>
	)
}

const Execute = memo(({ tid, teamId, aid, cid, viewId, vid, breakdown, page, modelProps, varProps, filter, sort, dates }) => {
	const dispatch = useDispatch()
	useEffect(() => {
		if (validFilter(filter)) dispatch(updateChartData({ tid, teamId, aid, cid, viewId, vid, page, breakdown, modelProps, varProps, filter, sort, dates }))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	})
}, areEqual)

function areEqual(prev, next) {
	return (
		// IDs
		prev.tid === next.tid &&
		prev.teamId === next.teamId &&
		prev.aid === next.aid &&
		prev.cid === next.cid &&
		prev.vid === next.vid &&
		// View props
		JSON.stringify(prev.breakdown) === JSON.stringify(next.breakdown) &&
		prev.page === next.page &&
		JSON.stringify(prev.filter) === JSON.stringify(next.filter) &&
		JSON.stringify(prev.sort) === JSON.stringify(next.sort) &&
		prev.dates?.startDate === next.dates?.startDate &&
		prev.dates?.endDate === next.dates?.endDate &&
		prev.dates?.frequency === next.dates?.frequency &&
		// Model props
		prev.modelProps?.confidence?.confidence === next.modelProps?.confidence?.confidence &&
		// Variable props
		prev.varProps?.categoryAggr === next.varProps?.categoryAggr
	)
}

function validFilter(filter) {
	let valid = true
	if (filter.length > 0) {
		for (const column of filter) {
			if ('value' in column && Array.isArray(column['value']) && column['value'].length === 0) valid = false
		}
	}
	return valid
}
