import { useCallback, useRef } from 'react'

export const useResize = (onChange, onFinalize, direction = 'horizontal') => {
	const callbacksRef = useRef({
		onChange,
		onFinalize
	})
	callbacksRef.current.onChange = onChange
	callbacksRef.current.onFinalize = onFinalize

	let m_pos = useRef()

	const onMouseDown = useCallback(
		(e) => {
			document.addEventListener('mouseup', onMouseUp, false)
			document.addEventListener('mousemove', onResize, false)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[direction]
	)

	const onMouseUp = useCallback(
		(e) => {
			document.removeEventListener('mouseup', onMouseUp, false)
			document.removeEventListener('mousemove', onResize, false)
			callbacksRef.current.onFinalize()
			m_pos.current = null
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[direction]
	)

	// let m_pos
	const onResize = useCallback(
		(e) => {
			if (e.stopPropagation) e.stopPropagation()
			if (e.preventDefault) e.preventDefault()
			const prevPos = m_pos.current
			const mousePos = direction === 'horizontal' ? e.x : e.y
			m_pos.current = mousePos
			if (!prevPos) return
			const diff = prevPos - mousePos
			callbacksRef.current.onChange(diff)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[direction]
	)

	return { onMouseDown }
}
