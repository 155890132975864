import { Button, Tooltip as MuiTooltip } from '@mui/material'
import clsx from 'clsx'
import { cellText } from 'common/components/grid/cells/Cell'
import { AddIcon, SettingsIcon } from 'common/icons/index'
import React, { memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { areEqual } from 'react-window'
import { TABLE_PARAMS } from 'table/constants/tableParameters'
import { DATA_TYPES } from 'common/constants/dataTypes'

function Tooltip({ title, children }) {
	return (
		<MuiTooltip title={title} TransitionProps={{ timeout: { appear: 200, enter: 200, exit: 0 } }}>
			{children}
		</MuiTooltip>
	)
}

// Do not include: cancel, onChangeCellRef, onOpenContextMenu, onSearchColumnRef, onCancelSearchColumnRef, onChangeFileRef, onStartEditing, onStopEditing
function cellAreEqual(prev, next) {
	return (
		prev.rIndex === next.rIndex &&
		prev.cIndex === next.cIndex &&
		prev.cId === next.cId &&
		prev.isRowHeader === next.isRowHeader &&
		prev.isNewRow === next.isNewRow &&
		prev.focus === next.focus &&
		prev.type === next.type &&
		JSON.stringify(prev.typeProps) === JSON.stringify(next.typeProps) &&
		prev.item === next.item &&
		prev.isDesigning === next.isDesigning &&
		prev.style.width === next.style.width &&
		prev.style.height === next.style.height &&
		prev.style.left === next.style.left &&
		prev.style.right === next.style.right &&
		prev.style.top === next.style.top &&
		prev.searchResults === next.searchResults &&
		JSON.stringify(prev.fileTransmit) === JSON.stringify(next.fileTransmit)
	)
}

const CellRender = memo(
	({
		// Common props
		rIndex,
		cIndex,
		cId,
		isRowHeader,
		isNewRow,
		focus,
		cancel,
		type,
		typeProps,
		item,
		isDesigning,
		style,
		// Common functions
		onChangeCellRef,
		onOpenContextMenu,
		// Props and functions for type reference
		searchResults,
		onSearchColumnRef,
		onCancelSearchColumnRef,
		// Props and functions for type file
		fileTransmit,
		onChangeFileRef,
		onStartEditing,
		onStopEditing
	}) => {
		const { t } = useTranslation(['common'])
		// // ###########################
		// // #### CONSTANTS
		// // ###########################
		const isNewCol = cId === TABLE_PARAMS.NEW_COL_ID
		// ###########################
		// #### CELL CHANGE HANDLER
		// ###########################
		const setItemGeneral = useCallback(
			(value) => onChangeCellRef.current(value, rIndex, cIndex),
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[rIndex, cIndex]
		)
		const setItemFile = useCallback(
			(file, fileId, fileName, fileSize, fileType) => onChangeFileRef.current(rIndex, cIndex, file, fileId, fileName, fileSize, fileType),
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[rIndex, cIndex]
		)
		const setItem = type === DATA_TYPES.file.key ? setItemFile : setItemGeneral
		// ###########################
		// #### CELL RENDERER
		// ###########################
		const typeObj = type ? DATA_TYPES[type] : DATA_TYPES.text
		const cellRenderer = typeObj?.renderer
		const Cell = cellRenderer?.component
		const cellConfig = cellRenderer?.config
		// ###########################
		// #### SEARCH ON OTHER TABLES
		// ###########################
		const search = {
			onSearchRef: onSearchColumnRef,
			onCancelSearchRef: onCancelSearchColumnRef,
			searchResults: searchResults
		}
		return (
			<div id={'cellWrapper#' + cIndex + '#' + rIndex} className={clsx('grid-cell-wrapper', (isNewRow || isNewCol) && !focus && 'grid-cell-group')} style={style}>
				{/* New row and column buttons */}
				{isNewRow && !focus && isRowHeader && (
					<div className="grid-row-buttons-wrapper bg-inherit w-full">
						<Button size="small" startIcon={<AddIcon className="grid-icon " />} className="add-row  grid-button" onClick={() => setItem(null)}>
							{t('table:buttons.addRow')}
						</Button>
					</div>
				)}
				{/* Row buttons when designing */}
				{!isNewRow && isRowHeader && !focus && (
					<div className="grid-row-buttons-wrapper bg-inherit">
						{/* Hover buttons */}
						<div className="grid-row-buttons-wrapper-hidden">
							<Tooltip title={t('table:tooltip.settings')} leaveTouchDelay={0}>
								<span>
									<SettingsIcon className="grid-button-settings" onMouseDown={(e) => onOpenContextMenu({ e, rowIndex: rIndex, colIndex: cIndex })} />
								</span>
							</Tooltip>
						</div>
					</div>
				)}
				{/* Row number */}
				{isRowHeader && <span className="mr-6 text-xs text-textGray">{rIndex}</span>}
				{/* Cell value*/}
				{Cell && (
					<Cell
						rowIndex={rIndex}
						colIndex={cIndex}
						item={item}
						setItem={setItem}
						focus={focus}
						cancel={cancel}
						typeProps={typeProps}
						config={cellConfig}
						search={search}
						startEditing={onStartEditing}
						stopEditing={onStopEditing}
						colWidth={style.width}
						fileTransmit={fileTransmit}
					/>
				)}
			</div>
		)
	},
	cellAreEqual
)

// Do not include: cancel, onChangeCellRef, onOpenContextMenu, onCellClick, onColumnResize, onCellClick, onDragStart, onDragEnd, onDrop, onDragEnter, onDragLeave, onDragOver, onTableTypeRef
function cellStickyRowAreEqual(prev, next) {
	return (
		prev.rIndex === next.rIndex &&
		prev.cIndex === next.cIndex &&
		prev.cId === next.cId &&
		prev.isRowHeader === next.isRowHeader &&
		prev.isColHeader === next.isColHeader &&
		prev.focus === next.focus &&
		prev.type === next.type &&
		JSON.stringify(prev.typeProps) === JSON.stringify(next.typeProps) &&
		prev.item === next.item &&
		prev.isDesigning === next.isDesigning &&
		prev.style.width === next.style.width &&
		prev.style.height === next.style.height &&
		prev.style.left === next.style.left &&
		prev.style.right === next.style.right &&
		prev.style.top === next.style.top &&
		prev.isDragActive === next.isDragActive
	)
}

const CellStickyRowRender = memo(
	({
		// Common props
		rIndex,
		cIndex,
		cId,
		isRowHeader,
		isColHeader,
		focus,
		cancel,
		type,
		typeProps,
		item,
		isDesigning,
		style,
		// Common functions
		onChangeCellRef,
		onOpenContextMenu,
		// Props and functions for row header
		onCellClick,
		onColumnResize,
		onDragStart,
		onDragEnd,
		onDrop,
		onDragEnter,
		onDragLeave,
		onDragOver,
		isDragActive,
		onTableTypeRef
	}) => {
		const { t } = useTranslation(['common'])

		// // ###########################
		// // #### CONSTANTS
		// // ###########################
		const isNewCol = cId === TABLE_PARAMS.NEW_COL_ID
		const typeObj = DATA_TYPES[type]

		// ###########################
		// #### CELL CHANGE HANDLER
		// ###########################
		const setItem = useCallback(
			(value) => onChangeCellRef.current(value, rIndex, cIndex),
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[rIndex, cIndex]
		)

		// ###########################
		// #### CELL RENDERER
		// ###########################
		const cellRenderer = cellText
		const Cell = cellRenderer?.component
		const cellConfig = cellRenderer?.config

		return (
			<div
				className={clsx('grid-cell-wrapper grid-draggable', isNewCol && !focus && 'grid-cell-group')}
				style={style}
				draggable={isColHeader && !isRowHeader && isDesigning ? true : false}
				onDragStart={(e) => onDragStart(e, cIndex, rIndex)}
				onDragEnd={onDragEnd}
				onClick={onCellClick}
			>
				{/* Column resize handle */}
				{isColHeader && <div className="grid-column-resize" onMouseDown={(e) => onColumnResize(e, cIndex)} />}

				{/* Row drop area */}
				{isColHeader && !isRowHeader && isDesigning && isDragActive && (
					<>
						<div
							className="grid-col-drop-left"
							onDrop={isDragActive ? (e) => onDrop(e, cIndex, rIndex) : undefined}
							onDragEnter={isDragActive ? (e) => onDragEnter(e) : undefined}
							onDragLeave={isDragActive ? (e) => onDragLeave(e) : undefined}
							onDragOver={isDragActive ? (e) => onDragOver(e) : undefined}
						/>
						<div
							className="grid-col-drop-right"
							onDrop={isDragActive ? (e) => onDrop(e, cIndex + 1, rIndex) : undefined}
							onDragEnter={isDragActive ? (e) => onDragEnter(e) : undefined}
							onDragLeave={isDragActive ? (e) => onDragLeave(e) : undefined}
							onDragOver={isDragActive ? (e) => onDragOver(e) : undefined}
						/>
					</>
				)}

				{/* New column button */}
				{isNewCol && !focus && isColHeader && (
					<div className="grid-row-buttons-wrapper bg-inherit w-full">
						<Button size="small" startIcon={<AddIcon className="grid-icon" />} className="add-column grid-button" onClick={() => setItem(null)}>
							{t('table:buttons.addColumn')}
						</Button>
					</div>
				)}

				{/* Column buttons when designing */}
				{isColHeader && !isNewCol && isDesigning && (
					<div className="grid-row-buttons-wrapper bg-inherit">
						{/* Hover buttons */}
						{!isRowHeader && (
							<div className="grid-row-buttons-wrapper-hidden">
								<Tooltip title={t('table:tooltip.settings')} TransitionProps={{ timeout: { appear: 200, enter: 200, exit: 0 } }}>
									<span>
										<SettingsIcon
											className="grid-button-settings"
											onClick={(e) => {
												if (e.preventDefault) e.preventDefault()
												if (e.stopPropagation) e.stopPropagation()
											}}
											onMouseDown={(e) => onOpenContextMenu({ e, rowIndex: rIndex, colIndex: cIndex })}
										/>
									</span>
								</Tooltip>
							</div>
						)}

						{/* Column icons */}
						<Tooltip title={typeObj.label}>
							<span onClick={(e) => onTableTypeRef.current({ x: e.clientX, y: e.clientY }, cIndex)}>{React.createElement(typeObj.icon, { className: 'grid-icon-round' })}</span>
						</Tooltip>
					</div>
				)}

				{/* Cell value*/}
				{Cell && <Cell rowIndex={rIndex} colIndex={cIndex} item={item} setItem={setItem} focus={focus} cancel={cancel} typeProps={typeProps} config={cellConfig} className="text-textGray font-medium" />}
			</div>
		)
	},
	cellStickyRowAreEqual
)

function cellDataPreparation({ rIndex, cIndex, data, style, isRowHeader = false, isColHeader = false, CellRender }) {
	// ###########################
	// #### PARAMETERS
	// ###########################
	const items = data?.items
	const columns = data?.columns
	const editing = data?.editing
	const onChangeCellRef = data?.onChangeCellRef
	const onOpenContextMenu = data?.onOpenContextMenu
	const isDesigning = data?.isDesigning
	const rowCount = data?.rowCount
	const canEdit = data?.canEdit

	// ###########################
	// #### CONSTANTS
	// ###########################
	const column = columns && columns[cIndex]
	const cId = column && column.id
	const focus = editing?.is && editing?.cell && cIndex === editing?.cell?.col && rIndex === editing?.cell?.row
	const cancel = editing?.cancel
	const type = column?.type
	const typeProps = column?.typeProps
	const isNewRow = canEdit && !isColHeader && rIndex === rowCount - 1

	// ###########################
	// #### PROPS FOR COLUMN HEADER
	// ###########################
	const onCellClick = isColHeader && data?.onCellClick
	const onColumnResize = isColHeader && data?.onColumnResize
	const onDragStart = isColHeader && data?.onDragStart
	const onDragEnd = isColHeader && data?.onDragEnd
	const onDrop = isColHeader && data?.onDrop
	const onDragEnter = isColHeader && data?.onDragEnter
	const onDragLeave = isColHeader && data?.onDragLeave
	const onDragOver = isColHeader && data?.onDragOver
	const isDragActive = isColHeader && data?.dragIndex?.col && data?.dragIndex?.col !== cIndex
	const onTableTypeRef = isColHeader && data?.onTableTypeRef

	// ###########################
	// #### PROPS DEPENDING ON CELL TYPE
	// ###########################
	// Reference type
	const isReferenceType = type === DATA_TYPES.reference.key
	const onSearchColumnRef = isReferenceType ? data?.onSearchColumnRef : null
	const onCancelSearchColumnRef = isReferenceType ? data?.onCancelSearchColumnRef : null
	const searchResults = focus && isReferenceType ? data?.searchResults : null
	// File type
	const isFileType = type === DATA_TYPES.file.key
	const onChangeFileRef = data?.onChangeFileRef
	const fileTransmit = isFileType && focus ? data?.fileTransmit : null
	const onStartEditing = isFileType && data?.onStartEditing
	const onStopEditing = isFileType && data?.onStopEditing

	// ###########################
	// #### CONTENT
	// ###########################
	const item = isColHeader ? column?.label : isNewRow ? null : items && items[rIndex - 1] && items[rIndex - 1][cId] // -1 because of header row

	return (
		<CellRender
			// Common props
			rIndex={rIndex}
			cIndex={cIndex}
			cId={cId}
			isRowHeader={isRowHeader}
			isColHeader={isColHeader}
			isNewRow={isNewRow}
			focus={focus}
			cancel={cancel}
			type={type}
			typeProps={typeProps}
			item={item}
			isDesigning={isDesigning}
			style={style}
			// Common functions
			onChangeCellRef={onChangeCellRef}
			onOpenContextMenu={onOpenContextMenu}
			// Props and functions for column header
			onCellClick={onCellClick}
			onColumnResize={onColumnResize}
			onDragStart={onDragStart}
			onDragEnd={onDragEnd}
			onDrop={onDrop}
			onDragEnter={onDragEnter}
			onDragLeave={onDragLeave}
			onDragOver={onDragOver}
			isDragActive={isDragActive}
			onTableTypeRef={onTableTypeRef}
			// Props and functions for type reference
			searchResults={searchResults}
			onSearchColumnRef={onSearchColumnRef}
			onCancelSearchColumnRef={onCancelSearchColumnRef}
			// Props and functions for type file
			fileTransmit={fileTransmit}
			onChangeFileRef={onChangeFileRef}
			onStartEditing={onStartEditing}
			onStopEditing={onStopEditing}
		/>
	)
}

export const CellStickyColumn = memo(({ rowIndex, columnIndex, data, style }) => {
	if (data?.stickyRows > 0 && rowIndex < data?.stickyRows) return null
	return cellDataPreparation({ rIndex: rowIndex, cIndex: 0, data, style, isRowHeader: true, CellRender: CellRender })
}, areEqual)

export const CellStickyRow = memo(({ rowIndex, columnIndex, data, style }) => {
	if (data?.stickyCols > 0 && columnIndex < data?.stickyCols) return null
	return cellDataPreparation({ rIndex: 0, cIndex: columnIndex, data, style, isColHeader: true, CellRender: CellStickyRowRender })
}, areEqual)

export const CellStickyTopLeft = memo(({ data, style }) => {
	return cellDataPreparation({ rIndex: 0, cIndex: 0, data, style, isColHeader: true, isRowHeader: true, CellRender: CellStickyRowRender })
}, areEqual)

export const Cell = memo(({ rowIndex, columnIndex, data, style }) => {
	if (data?.stickyRows > 0 && rowIndex < data?.stickyRows) return null
	if (data?.stickyCols > 0 && columnIndex < data?.stickyCols) return null
	return cellDataPreparation({ rIndex: rowIndex, cIndex: columnIndex, data, style, CellRender: CellRender })
}, areEqual)
