import CreateTextCell from 'common/components/grid/cells/CreateTextCell'
import { parseNum } from 'common/components/grid/cells/CellNum'
import { printNumWithProps } from 'common/utils/numbers'
import { DATA_TYPES } from 'common/constants/dataTypes'

export const cellCurrency = {
	component: CreateTextCell,
	config: {
		alignRight: true,
		formatBlurredInput: ({ value, typeProps }) => {
			return printNumWithProps(value, DATA_TYPES.currency.key, typeProps)
		},
		formatCopyValue: ({ value, typeProps }) => {
			return printNumWithProps(value, DATA_TYPES.currency.key, typeProps)
		},
		parseUserInput: ({ value, typeProps }) => {
			const numStr = value?.replace(typeProps?.currencySymbol, '')
			return parseNum({ value: numStr, typeProps })
		},
		parsePastedValue: ({ value, typeProps, localeFallback }) => {
			const numStr = value?.replace(typeProps?.currencySymbol, '')
			return parseNum({ value: numStr, typeProps })
		}
	}
}
