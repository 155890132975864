import React, { useCallback } from 'react'
import {useDropzone} from 'react-dropzone'

export default function FileBrowse({ onChange }) {
	const onDrop = useCallback(acceptedFiles => {
		onChange(acceptedFiles[0])
	}, [])
	
	const {getRootProps, getInputProps} = useDropzone({
		onDrop, 
		maxFiles: 1,
		accept: {
			'text/csv': ['.csv'],
		}
	});

	return (
		<div className="flex justify-center items-center m-2">
			<div {...getRootProps({className: 'dropzone'})} className="flex flex-col justify-center items-center w-full h-64 bg-gray-100 rounded-lg border-gray-300 border-dashed cursor-pointer light:hover:bg-bray-900 light:bg-gray-800 hover:bg-gray-200 light:border-gray-700 light:hover:border-gray-600 light:hover:bg-gray-700">
				<input {...getInputProps()} />
				<svg aria-hidden="true" className="mb-3 w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
				<p className="mb-2 text-sm text-gray-500 light:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
				<p className="text-xs text-gray-500 light:text-gray-400">CSV</p>
			</div>
		</div>
	)
}