// #### SUBSCRIBE TO USER
export const LOAD_USER = 'userSaga/LOAD_USER'
export const CANCEL_USER = 'userSaga/CANCEL_USER'
// #### USER MANAGEMENT
export const UPDATE_USER = 'userSaga/UPDATE_USER'
export const DELETE_USER = 'userSaga/DELETE_USER'

// #### SUBSCRIBE TO USER
export const loadUser = ({ tid, uid }) => {
	return { type: LOAD_USER, tid, uid }
}

export const cancelUser = () => {
	return { type: CANCEL_USER }
}

// #### USER MANAGEMENT
export const updateUser = ({ tid, uid, content }) => {
	return { type: UPDATE_USER, tid, uid, content }
}

export const deleteUser = () => {
	return { type: DELETE_USER }
}
