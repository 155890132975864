import { MenuItem, TextField, Button } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { FREQUENCY } from 'common/constants/frequencies'
import useLocale from 'common/hooks/useLocale'
import { fromTimestampToDate, getDatePeriodStart } from 'common/utils/dates'
import { DateTime } from 'luxon'
import { DATE_FORMATS } from 'common/constants/formats'
import ErrorIcon from '@mui/icons-material/ErrorOutlineOutlined'
import * as viewSel from 'common/store/viewSelector'
import { setInView } from 'common/saga-actions/viewActions'
import { useAssetPermits } from 'common/hooks/useAssetPermits'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { SYSMSG_MODEL_SCROLL_TO_TODAY, pushSystemMessage } from 'common/store/globalReducer'

export default function ModelDatesVisualization({ aid, onClose, isDesigning }) {
	const dispatch = useDispatch()
	const { t } = useTranslation(['common', 'model'])
	const permits = useAssetPermits(aid)

	// #### REDUX
	const modelProps = useSelector((state) => state.model.model[aid]?.data?.modelProps)
	const selectVizDates = useMemo(() => viewSel.makeSelectVizDates({ aid }), [aid])
	const viewDates = useSelector((state) => selectVizDates(state))

	const typeProps = modelProps?.typeProps
	const dateProps = typeProps?.dateFormat ? DATE_FORMATS[typeProps.dateFormat] : null
	const locale = useLocale(dateProps?.locale)

	// Model props
	// const mStartDate = useMemo(() => fromTimestampToDate(modelProps.startDate), [modelProps.startDate])
	// const mEndDate = useMemo(() => fromTimestampToDate(modelProps.endDate), [modelProps.endDate])
	const mFrequency = modelProps.frequency

	// #### DEFAULT VALUES
	const defaultForm = {
		frequency: FREQUENCY.month.key,
		startDate: DateTime.now(),
		endDate: DateTime.now()
	}

	const defaultValidation = {
		frequency: { valid: true, error: '' },
		startDate: { valid: true, error: '' },
		endDate: { valid: true, error: '' }
	}
	// #### STATE
	const [form, setForm] = useState(defaultForm)
	const [validation, setValidation] = useState(defaultValidation)

	// #### EFFECTS
	useEffect(() => {
		setForm(initialize())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [viewDates])

	function initialize() {
		const startDate = viewDates?.startDate
		const endDate = viewDates?.endDate
		const frequency = viewDates?.frequency
		return { startDate, endDate, frequency }
	}

	// #### FORM FUNCTIONS
	function onChange(key, value) {
		let newForm = { ...form, [key]: value }
		if (key === 'frequency') {
			newForm = { ...newForm, ...onAdjustDate('startDate', form.startDate, newForm) }
			newForm = { ...newForm, ...onAdjustDate('endDate', form.endDate, newForm) }
		}
		setForm(newForm)
	}

	function onAccept(key, date) {
		let newForm = { ...form, ...onAdjustDate(key, date, form) }
		setForm(newForm)
	}

	function onAdjustDate(key, date, form) {
		if (!date) return { [key]: null }
		const utcDate = DateTime.utc(date.year, date.month, date.day, 0, 0, 0, 0)
		var adjustedDate = getDatePeriodStart(utcDate, form.frequency, locale.locale).toUTC()

		let changes = { [key]: adjustedDate }
		// if (key === 'startDate' && adjustedDate.toMillis() < mStartDate.toMillis()) changes[key] = mStartDate
		// else if (key === 'endDate' && adjustedDate.toMillis() > mEndDate.toMillis()) changes[key] = mEndDate
		return changes
	}

	function onSave() {
		const prev = initialize()
		// Identify if anything has changed
		if (prev.startDate?.toMillis() === form.startDate?.toMillis() && prev.endDate?.toMillis() === form.endDate?.toMillis() && prev.frequency === form.frequency) {
			onClose()
			return
		}

		// Validate data
		const newValidation = { ...validation }
		newValidation['startDate'] = !form.startDate ? { valid: false, error: 'error.required' } : { valid: true, error: '' }
		newValidation['endDate'] = !form.endDate ? { valid: false, error: 'error.required' } : { valid: true, error: '' }
		if (newValidation.startDate?.valid && newValidation.endDate?.valid)
			newValidation['startDate'] = form.startDate.toMillis() > form.endDate.toMillis() ? { valid: false, error: 'model:dates.errorStartGreaterThanEnd' } : { valid: true, error: '' }
		setValidation(newValidation)
		if (!newValidation.startDate.valid || !newValidation.endDate.valid) return

		// Save data
		const dates = { startDate: form.startDate.toJSDate(), endDate: form.endDate.toJSDate(), frequency: form.frequency }
		dispatch(setInView({ aid, changes: [{ path: 'dates', value: dates }], permits }))
		onClose()
	}

	function onShortcut(type) {
		if (type === 'today') dispatch(pushSystemMessage({ key: `model/${aid}`, value: SYSMSG_MODEL_SCROLL_TO_TODAY }))
		onClose()
	}

	return (
		<>
			<div className="flex flex-row items-center border-b border-b-borderGray my-4">
				<div className="px-2 py-1 rounded-full bg-primaryLight text-xs flex flex-row items-center hover:bg-primary cursor-pointer mb-4" onClick={() => onShortcut('today')}>
					<span>{t('model:dates.today')}</span>
					<ArrowForwardIcon className="ml-1 w-[15px] h-[15px] text-textGray" />
				</div>
			</div>

			<TextField
				select
				fullWidth
				variant="outlined"
				margin="none"
				size="small"
				value={form.frequency}
				onChange={(event) => onChange('frequency', event.target.value)}
				error={!validation.frequency.valid}
				helperText={!validation.frequency.valid && validation.frequency.error}
				inputProps={{ className: 'text-sm' }}
				InputLabelProps={{ className: 'text-sm' }}
				sx={{ placeholder: 'text-sm' }}
				label={t('model:dates.granularity')}
				className="mt-4"
				disabled={isDesigning}
			>
				{Object.keys(FREQUENCY).map((option) => {
					if (mFrequency != null && FREQUENCY[option].index >= FREQUENCY[mFrequency].index)
						return (
							<MenuItem key={FREQUENCY[option].key} value={FREQUENCY[option].key} className="text-sm">
								{t(FREQUENCY[option].label)}
							</MenuItem>
						)
				})}
			</TextField>

			<LocalizationProvider dateAdapter={AdapterLuxon}>
				<DatePicker
					value={form.startDate}
					onChange={(value) => onChange('startDate', value)}
					inputFormat={locale.dateMask.mask}
					label={t('model:dates.dateFrom')}
					onAccept={(value) => onAccept('startDate', value)}
					disabled={isDesigning}
					renderInput={(params) => (
						<TextField
							{...params}
							margin="none"
							size="small"
							fullWidth
							inputProps={{ ...params.inputProps, placeholder: locale.dateMask.placeholder, className: 'text-sm' }}
							InputProps={{ ...params.InputProps, onBlur: () => onAccept('startDate', form.startDate) }}
							InputLabelProps={{ className: 'text-sm' }}
							sx={{ svg: { width: '18px', height: '18px' }, placeholder: 'text-sm' }}
							error={!validation.startDate.valid}
							helperText={!validation.startDate.valid && t(validation.startDate.error, { field: t('model:dates.dateFrom') })}
							className="mt-4"
						/>
					)}
				/>
				<DatePicker
					value={form.endDate}
					onChange={(value) => onChange('endDate', value)}
					inputFormat={locale.dateMask.mask}
					label={t('model:dates.dateTo')}
					onAccept={(value) => onAccept('endDate', value)}
					disabled={isDesigning}
					renderInput={(params) => (
						<TextField
							{...params}
							margin="none"
							size="small"
							fullWidth
							inputProps={{ ...params.inputProps, placeholder: locale.dateMask.placeholder, className: 'text-sm' }}
							InputProps={{ ...params.InputProps, onBlur: () => onAccept('endDate', form.endDate) }}
							InputLabelProps={{ className: 'text-sm' }}
							sx={{ svg: { width: '18px', height: '18px' }, placeholder: 'text-sm' }}
							error={!validation.endDate.valid}
							helperText={!validation.endDate.valid && t(validation.endDate.error, { field: t('model:dates.dateTo') })}
							className="mt-4"
						/>
					)}
				/>
			</LocalizationProvider>

			<Button onClick={onSave} size="small" className="mt-4 buttonContainedContrast">
				{t('common:buttons.save')}
			</Button>
		</>
	)
}
