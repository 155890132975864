import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Avatar } from '@mui/material'
import HomeIcon from '@mui/icons-material/HomeOutlined'
import { ASSET_ROLES, TEAM_ROLES } from 'common/constants/roles'
import clsx from 'clsx'
import { PRODUCTS } from 'common/constants/products'

export default function BillableUsers() {
	const { t } = useTranslation('common')

	// #### REDUX
	const users = useSelector((state) => state.billing?.billableUsers?.result)
	const teamId = useSelector((state) => state.team.teamId)
	const teamName = useSelector((state) => state.team?.team?.name)
	const assets = useSelector((state) => state.asset.assets?.result)

	return (
		<div className="max-w-[700px] paper p-4">
			{users?.map((userObj, index) => {
				const user = userObj.data
				return (
					<div key={`billableUser#${user.uid}`} className={clsx('flex flex-row items-start text-sm', index !== 0 && 'pt-3 mt-3 border-t border-t-borderGray')}>
						<div className="flex flex-row items-center flex-1 shrink-0">
							<Avatar className="mr-4 text-xs w-[25px] h-[25px] uppercase bg-primary">{(user?.name || user?.email).charAt(0)}</Avatar>
							<div className="flex flex-col">
								<span className="text-sm">{(user?.name || '') + ' ' + (user?.surname || '')}</span>
								<span className="text-xs text-textGray">{user?.email}</span>
							</div>
						</div>
						<div className="flex flex-col text-xs ml-4 flex-1 shrink-0 self-start">
							{user.teams?.map((team) => {
								const role = TEAM_ROLES[team.role]
								if (team.teamId === teamId)
									return (
										<div key={`billableUserTeam#${team.teamId}`} className="flex flex-row items-center mb-1">
											<Avatar className={`h-[20px] w-[20px] mr-2`} variant="rounded">
												<HomeIcon className="text-[15px]" />
											</Avatar>
											<span> {`${teamName} (${t(role.label)})`}</span>
										</div>
									)
								else return void 0
							})}

							{user.assets?.map((assetEl) => {
								const assetId = assetEl.assetId
								const role = ASSET_ROLES[assetEl.role]
								const asset = assets ? assets[assetId] : null
								const product = asset ? PRODUCTS[asset.type] : null
								if (!asset) return void 0
								return (
									<div key={`billableUserAsset#${assetId}`} className="flex flex-row items-center mb-1">
										<Avatar className={`h-[20px] w-[20px] mr-2`} variant="rounded">
											{React.createElement(product.icon, { className: 'text-[13px]' })}
										</Avatar>
										<span> {`${asset.name} (${t(role.label)})`}</span>
									</div>
								)
							})}
						</div>
					</div>
				)
			})}
		</div>
	)
}
