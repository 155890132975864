import CloseIcon from '@mui/icons-material/Close'
import SettingsIcon from '@mui/icons-material/SettingsOutlined'
import { Autocomplete, Collapse, IconButton, MenuItem, TextField } from '@mui/material'
import ColorPicker from 'common/components/ColorPicker'
import { CHART_AXIS, CHART_SHAPES, CHART_TYPES } from 'common/constants/charts'
import { THEMES } from 'common/constants/themes'
import { getThemeColor } from 'common/utils/theme'
import { t } from 'i18next'
import { MODEL_NEW_CHART_VAR } from 'model/constants/modelParameters'
import { updateChart } from 'model/saga-actions/chartActions'
import { selectModelVars } from 'model/store/modelSelector'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export default function ChartSettingsVars({ aid, chart }) {
	const dispatch = useDispatch()

	// #### REDUX
	const tid = useSelector((state) => state.auth.tid)
	const variables = useSelector((state) => selectModelVars(state, aid))

	// #### STATE
	const [inputValue, setInputValue] = useState('')
	const [settingsOpen, setSettingsOpen] = useState(false)
	const [colorPicker, setColorPicker] = useState({ anchor: null, varIndex: null })
	const [variablesList, setVariablesList] = useState([])

	// #### EFFECTS
	useEffect(() => {
		var newVarList = []
		Object.values(variables)?.forEach((variable) => {
			if (!(chart.variables?.findIndex((el) => el.id === variable.id) >= 0)) newVarList.push(variable)
		})
		setVariablesList(newVarList)
	}, [variables, chart?.variables])

	// #### FUNCTIONS
	function onAddVariable(e, variable) {
		setInputValue('')
		const numVar = chart.variables?.length || 0
		const themeIndex = numVar % THEMES.length
		const theme = THEMES[themeIndex]
		const color = getThemeColor(theme)
		const newVariable = { ...MODEL_NEW_CHART_VAR, id: variable.id, type: chart.type, color, categories: variable.categories || [], categoryAggr: variable.varProps?.categoryAggr }
		const newVariables = [...chart.variables, newVariable]
		dispatch(updateChart({ tid, aid, cid: chart.id, content: { variables: newVariables } }))
	}

	function onRemoveVariable(varIndex) {
		var newVariables = [...chart.variables]
		newVariables.splice(varIndex, 1)
		dispatch(updateChart({ tid, aid, cid: chart.id, content: { variables: newVariables } }))
	}

	function onToggleSettings(varId) {
		if (settingsOpen !== varId) setSettingsOpen(varId)
		else setSettingsOpen(null)
	}

	function onChangeProperty(varIndex, property, value) {
		var newVariables = [...chart.variables]
		var newVariable = { ...chart.variables[varIndex], [property]: value }
		newVariables[varIndex] = newVariable
		dispatch(updateChart({ tid, aid, cid: chart.id, content: { variables: newVariables } }))
	}

	function onSelectColor({ color }) {
		onChangeProperty(colorPicker.varIndex, 'color', color)
		setColorPicker({ anchor: null, varIndex: null })
	}

	return (
		<div>
			{chart.variables?.map((variable, varIndex) => (
				<div key={'charVar#' + variable.id} className="bg-bgGray mb-2 px-4 py-1 rounded">
					<div className="flex flex-row items-center">
						<span
							className={`rounded-full w-[15px] h-[15px] mr-4 cursor-pointer hover:border hover:border-black`}
							style={{ backgroundColor: variable.color }}
							onClick={(event) => setColorPicker({ anchor: event.currentTarget, varIndex: varIndex })}
						/>
						<span className="flex-1 text-sm">{variables[variable.id]?.label}</span>
						<IconButton className="text-textGray" size="small" onClick={() => onToggleSettings(variable.id)} color={settingsOpen === variable.id ? 'primary' : 'inherit'}>
							<SettingsIcon style={{ fontSize: '15px' }} />
						</IconButton>
						<IconButton className="ml-1 text-textGray" size="small" onClick={() => onRemoveVariable(varIndex)}>
							<CloseIcon style={{ fontSize: '15px' }} />
						</IconButton>
					</div>
					<Collapse in={settingsOpen === variable.id}>
						<TextField
							select
							label={t('common:chartTypes.type')}
							fullWidth
							variant="outlined"
							margin="dense"
							size="small"
							value={variable.type}
							inputProps={{ className: 'text-sm' }}
							InputLabelProps={{ className: 'text-sm' }}
							onChange={(e) => onChangeProperty(varIndex, 'type', e.target.value)}
						>
							{Object.keys(CHART_TYPES).map((option) => (
								<MenuItem key={CHART_TYPES[option].key} value={CHART_TYPES[option].key}>
									{t(CHART_TYPES[option].varLabel)}
								</MenuItem>
							))}
						</TextField>
						{variable.type === CHART_TYPES.line.key && (
							<TextField
								select
								label={t('common:chartShapes.shape')}
								fullWidth
								variant="outlined"
								margin="dense"
								size="small"
								value={variable.shape}
								inputProps={{ className: 'text-sm' }}
								InputLabelProps={{ className: 'text-sm' }}
								onChange={(e) => onChangeProperty(varIndex, 'shape', e.target.value)}
							>
								{Object.keys(CHART_SHAPES).map((option) => (
									<MenuItem key={CHART_SHAPES[option].key} value={CHART_SHAPES[option].key}>
										{t(CHART_SHAPES[option].label)}
									</MenuItem>
								))}
							</TextField>
						)}
						<TextField
							select
							label={t('common:chartAxis.axis')}
							fullWidth
							variant="outlined"
							margin="dense"
							size="small"
							value={variable.axis}
							inputProps={{ className: 'text-sm' }}
							InputLabelProps={{ className: 'text-sm' }}
							onChange={(e) => onChangeProperty(varIndex, 'axis', e.target.value)}
						>
							{Object.keys(CHART_AXIS).map((option) => (
								<MenuItem key={CHART_AXIS[option].key} value={CHART_AXIS[option].key}>
									{t(CHART_AXIS[option].label)}
								</MenuItem>
							))}
						</TextField>
					</Collapse>
				</div>
			))}
			<ColorPicker
				isOpen={Boolean(colorPicker?.anchor)}
				anchor={colorPicker?.anchor}
				themes={THEMES}
				onSelect={onSelectColor}
				onClose={() => setColorPicker({ anchor: null, varIndex: null })}
				isCustomEnabled
				currentColor={colorPicker?.varIndex != null ? chart.variables[colorPicker.varIndex].color : null}
			/>
			<Autocomplete
				blurOnSelect={true}
				clearOnBlur={true}
				options={variablesList}
				getOptionLabel={(option) => option.label}
				value={null}
				inputValue={inputValue}
				onChange={onAddVariable}
				onInputChange={(e, value) => setInputValue(value)}
				renderInput={(params) => (
					<TextField
						{...params}
						variant="outlined"
						fullWidth
						size="small"
						placeholder={t('model:defaults.addVariable')}
						InputProps={{ ...params.InputProps, className: 'text-sm' }}
						InputLabelProps={{ className: 'text-sm' }}
						sx={{ placeholder: 'text-sm' }}
					/>
				)}
				classes={{ listbox: 'text-sm', noOptions: 'text-sm' }}
			/>
		</div>
	)
}
