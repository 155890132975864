import { collection, addDoc } from 'firebase/firestore'
import { backend, axios } from 'common/config/backend'
import Papa from 'papaparse'
// import fileDownload from 'js-file-download'
import { db } from 'common/config/firebase'
import { throwAPIError } from 'common/config/errors'

const FILE_NAME = 'fileApi'

async function readFirstLinesPromise(file) {
	return new Promise((resolve, reject) => {
		let parsedData
		Papa.parse(file, {
			worker: true,
			preview: 3,
			//delimiter: ',',
			chunk: function (results) {
				parsedData = results
			},
			error: function (err, file, inputElem, reason) {
				reject(reason)
			},
			complete: function () {
				resolve(parsedData)
			}
		})
	})
}

export async function readFirstLines(file) {
	return readFirstLinesPromise(file)
		.then((results) => {
			if (results.data == null || results.data.length < 3 || results.data[0].length < 2) return { headers: null, firstLine: null, success: false }
			else
				return {
					headers: results.data[0],
					firstLine: results.data[1],
					secondLine: results.data[2],
					success: true
				}
		})
		.catch((error) => {
			throwAPIError(error, null, FILE_NAME, readFirstLines.name)
		})
}

export async function uploadFile(token, tenantId, teamId, userId, file, content) {
	let headers = { headers: { Authorization: 'Bearer ' + token } }
	const params = { mode: 'write', tenantId, userId, teamId, content: { ...content, name: file.name, createdBy: userId, status: 'Pending' } }

	return backend
		.post('/files', params, headers)
		.then((resp) => {
			if (resp.status !== 200) return { status: 'error', message: resp.statusText }
			delete params['content']
			params['tableId'] = content['destinationProps']['selectTable']
			params['fileId'] = resp['data']['data']['fileId']
			backend.post('/files/getSignedUrl', params, headers).then((res) => {
				if (res.status !== 200) return { status: 'error', message: res.statusText }
				let headers = { 'Content-Type': 'text/csv' }
				return axios.put(res.data.data, file, { headers }).then((resp) => {
					return resp.data
				})
			})
			return resp['data']
		})
		.then((resp) => resp.data)
		.catch((error) => {
			throwAPIError(error, error.response.status, FILE_NAME, uploadFile.name)
		})
}

export async function generateFileId(tenantId, assetId) {
	return await (
		await addDoc(collection(db, 'tenants', tenantId, 'connectors', assetId, 'files'), {})
	).id
}

// COULD NOT FIND A REFERENCE TO THIS FUNCTION
// export async function downloadFile(token, tid, wid, cid, fileId, filename) {
// 	let config = {
// 		headers: { Authorization: 'Bearer ' + token },
// 		params: { mode: 'read', tenantId: tid, workspaceId: wid, connectionId: cid, fileId }
// 	}
// 	return backend
// 		.get('/files/getSignedUrl', config)
// 		.then((resp) => {
// 			if (resp.status !== 200) return { status: 'error', message: resp.statusText }
// 			return axios
// 				.get(resp.data.data)
// 				.then((res) => {
// 					if (res.status !== 200) return { status: 'error', message: res.statusText }

// 					return res
// 				})
// 				.then((res) => res)
// 		})
// 		.then((resp) => {
// 			if (resp.status !== 200) return { status: 'error', message: resp.statusText }
// 			else return fileDownload(resp.data, filename)
// 		})
// }

export async function deleteFile(token, tenantId, teamId, userId, fileId) {
	try {
		let config = {
			data: { tenantId, teamId, userId, fileId },
			headers: { Authorization: 'Bearer ' + token }
		}
		backend
			.delete('/files', config)
			.then((resp) => {
				if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, deleteFile.name)
			})
			.catch((error) => {
				throwAPIError(error, error.response.status, FILE_NAME, deleteFile.name)
			})
	} catch (error) {}
}
