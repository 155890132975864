import { auth } from 'common/config/firebase'
import { onAuthStateChanged, signOut, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from 'firebase/auth'
import { backend } from 'common/config/backend'
import i18n from 'common/config/i18n'
import store from 'common/store/store'
import { loginSuccess, logoutSuccess } from 'common/store/authReducer'
import { throwAPIError } from 'common/config/errors'

const FILE_NAME = 'authApi'

export async function loadAuthChannel() {
	const listener = onAuthStateChanged(auth, (user) => {
		if (user) store.dispatch(loginSuccess({ uid: user.uid, tid: user.tenantId, email: user.email }))
		else store.dispatch(logoutSuccess())
	})
	return () => listener()
}

export async function getAuthToken() {
	const currentUser = auth.currentUser
	if (!currentUser) return { token: null }
	else
		return auth.currentUser
			.getIdToken()
			.then((idToken) => {
				return idToken
			})
			.then((token) => ({ token }))
			.catch((errorApi) => {
				var error = 'Error retrieving auth token'
				throw error
			})
}

export async function logout() {
	return signOut(auth)
		.then((response) => true)
		.then((success) => ({ success }))
		.catch((error) => {
			throw error
		})
}

export async function signInWithGoogle(tenantId, locale) {
	var provider = new GoogleAuthProvider()
	auth.tenantId = tenantId
	return signInWithPopup(auth, provider)
		.then(async function (result) {
			const { tenantId, uid, email, displayName } = result.user
			await updateGoogleUser(tenantId, uid, email, { name: displayName, comms: true, locale: locale })
		})
		.catch((error) => {
			throwAPIError(error, null, FILE_NAME, signInWithGoogle.name)
		})
}

async function updateGoogleUser(tenantId, userId, email, content) {
	const params = {
		tenantId: tenantId,
		userId: userId,
		email: email,
		content: content
	}
	const { token } = await getAuthToken()
	const headers = {
		headers: {
			Authorization: 'Bearer ' + token
		}
	}
	return backend
		.patch('/users/provided', params, headers)
		.then((resp) => {
			if (resp.status !== 200) throw resp.statusText
			return resp.data
		})
		.catch((err) => {
			throw i18n.t('common:error.email_in_use')
		})
}

export async function getUserTenant(email) {
	let params = {
		email: email
	}
	return backend
		.get('/users', { params })
		.then((resp) => {
			if (resp.status !== 200) return { status: 'error', message: resp.statusText }
			return resp.data
		})
		.catch((errorApi) => {
			throw i18n.t('common:error.unexpected')
		})
}

export async function login(email, password, tenant) {
	auth.tenantId = tenant
	return signInWithEmailAndPassword(auth, email, password)
		.then((response) => response.user.uid)
		.then((uid) => ({ uid }))
		.catch((errorApi) => {
			var error = errorApi.message
			switch (errorApi.code) {
				case 'auth/user-not-found':
					error = i18n.t('common:error.user_not_exist')
					break
				case 'auth/wrong-password':
					error = i18n.t('common:error.password_incorrect')
					break
				default:
					//define a default error
					error = i18n.t('common:error.user_not_exist')
					break
			}
			throw error
		})
}

export async function resetPassword(tenantId, email) {
	let params = {tenantId, email}
	return backend
		.post('/users/resetPassword', params)
		.then((resp) => {
			return resp.data
		})
		.catch((err) => {
			throw i18n.t('common:error.email_not_exist')
		})
}

export function signup(name, surname, email, password, locale, tenantId, comms = true) {
	return backend
		.post('/users', { tenantId, name, surname, email, password, locale, comms })
		.then((resp) => {
			if (resp.status !== 200) throw resp.statusText
			return resp.data
		})
		.catch((err) => {
			throw i18n.t('common:error.email_in_use')
		})
}
