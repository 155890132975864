import { TEAM_ROLES } from 'common/constants/roles'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

export function useTeamPermits() {
	const uid = useSelector((state) => state.auth.uid)
	const teamUser = useSelector((state) => (uid && state.team?.teamUsers?.result ? state.team?.teamUsers?.result[uid] : null))

	const teamPermits = useMemo(() => {
		if (teamUser?.role === TEAM_ROLES.admin.key) return { canAdmin: true, canCreate: true, canEdit: true, canView: true }
		else if (teamUser?.role === TEAM_ROLES.create.key) return { canAdmin: false, canCreate: true, canEdit: true, canView: true }
		else if (teamUser?.role === TEAM_ROLES.edit.key) return { canAdmin: false, canCreate: false, canEdit: true, canView: true }
		else if (teamUser?.role === TEAM_ROLES.view.key) return { canAdmin: false, canCreate: false, canEdit: false, canView: true }
		else return { canAdmin: false, canCreate: false, canEdit: false, canView: false }
	}, [teamUser?.role])

	return teamPermits
}
