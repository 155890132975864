import { useTranslation } from 'react-i18next'
import { Dialog, DialogTitle, DialogContent, Divider, DialogActions, CircularProgress, Button, Avatar, Accordion, AccordionSummary, AccordionDetails, TextField, MenuItem } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { fileSchema } from 'automation/utils/schema'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingButton } from '@mui/lab'
import { FILES } from 'automation/constants/datasources'
import FileBrowse from 'common/components/FileBrowse'
import { extractFileHeaders, uploadFile } from 'automation/saga-actions/fileActions'
import { cancelFileChanges, cleanFileUpload, cleanUploadingFile } from 'automation/store/fileReducer'
import { Add, SelectAll, ExpandMore } from '@mui/icons-material'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import NewSchema from './NewSchema'
import TableTypeRefTable from 'table/screens/type/TableTypeRefTable'
import { validateNotEmpty } from 'common/utils/validate'
import { showMessage } from 'common/saga-actions/globalActions'

export default function NewFile({ isOpen, onClose }) {
	const { t } = useTranslation(['common', 'automation'])
	const dispatch = useDispatch()
	let { teamId } = useParams()
	const tid = useSelector((state) => state.auth.tid)
	const sid = useSelector((state) => state.space.spaceOpen)
	const newFile = useSelector((state) => state.file.fileUpload)
	const isLoading = useSelector((state) => state.file.uploading)
	const isProcessing = useSelector((state) => state.file.currentFile?.data?.status)
	const error = useSelector((state) => state.file.currentFile?.data?.error)
	
	const defaultForm = {
		destinationProps: {
			tableName: '',
			selectTable: '',
		},
		sourceProps: {
			schema: []
		}
	}

	const defaultValidation = {
		tableName: { valid: true, error: '' },
		selectTable: { valid: true, error: '' },
		schema: { valid: true, error: '' }
	}

	const [form, setForm] = useState(defaultForm)
	const [file, setFile] = useState(null)
	const [fields, setFields] = useState()
	const [loading, setLoading] = useState(false)
	const [expanded, setExpanded] = useState('table');
	const [selectAction, setSelectAction] = useState('new')
	const [showVariables, setShowVariables] = useState(false)
    const [showTables, setShowTables] = useState(false)
	const [validation, setValidation] = useState(defaultValidation)
	const loadKey = `connectorTable#${form.destinationProps.selectTable}`

	useEffect(() => {
		dispatch(cleanFileUpload())
		dispatch(cancelFileChanges())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if(error) {
			dispatch(showMessage({ content: error, isPersistent: true }))
			onClose()
		}
		return () => {
			dispatch(cancelFileChanges())
		}
	}, [error])

	useEffect(() => {
		if (file) {
			setLoading(true)
			dispatch(extractFileHeaders(file))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [file])

	useEffect(() => {
		if(isLoading === null) return;
		if(!isLoading && isProcessing === 'Done') {
			dispatch(cleanUploadingFile())
			onClose()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading, isProcessing])

	useEffect(() => {
		if (!newFile) return
		setLoading(false)
		const schema = fileSchema(newFile)
		setForm({ ...form, sourceProps: {...form.sourceProps, schema: schema} })
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [newFile, fields])

	function onChange(key, value) {
		if (key === 'file') {
			setFile(value)
		} else if (key === 'fields') {
			setFields(value)
		} else setForm({ ...form, [key]: value })
	}

	const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    }

	function onTable(action) {
        setSelectAction(action)
        if(action === 'select') {
            setShowTables(true)
			setShowVariables(true)
        } else {
			setShowTables(false)
			setShowVariables(false)
		}
    }

	function validate() {
		//TODO validate schema
        if(showTables) {
			const validTable = showTables && validateNotEmpty(form.destinationProps.selectTable)
			setValidation({...defaultValidation, selectTable: validTable})
			if(validTable.valid) onImport()
		} else {
			const validTableName = !showTables && validateNotEmpty(form.destinationProps.tableName)
			setValidation({...defaultValidation, tableName: validTableName})
			if(validTableName.valid) onImport()
		}
    }

	function onImport() {
		dispatch(uploadFile({tid, teamId, file, form, sid}))
	}

	return (
		<Dialog onClose={onClose} open={isOpen} maxWidth="md" fullWidth={true} PaperProps={{style: {
			minHeight: '90%',
			maxHeight: '90%',
		  }}}>
			<DialogTitle className='text-sm text-center'>
				<span>{t(`automation:create.fileUpload`)}&nbsp;</span>
			</DialogTitle>
			<Divider />
			<DialogContent>
				<div className='ml-8 mr-8 mt-2 mb-2'>
					<div className="flex flex-row items-center mb-4">
						<Avatar className={`h-[30px] w-[30px] mr-4 bg-transparent`} variant="rounded">
							{React.createElement(FILES.csv.icon, { className: 'text-[20px]' })}
						</Avatar>
						<div>
							<div className="text-sm font-medium">
								<>
									<span>CSV</span>
								</>
							</div>
							<div className="text-xs text-textGray leading-none truncate">{t(FILES.csv.desc)}</div>
						</div>
					</div>
					{!file && (
						<FileBrowse file={file} onChange={(file) => onChange('file', file)} />
					)}
					{file && !loading && (
						<Accordion className="mb-4" expanded={expanded === 'file'} onChange={handleChange('file')}>
							<AccordionSummary
								expandIcon={<ExpandMore />}
							>
								<div>
									<div className="text-sm font-medium">
										<div className="text-md text-textGray leading-none truncate m-1">{t("automation:create.importFile")}</div>
									</div>
									<div className="text-xs text-gray-400 leading-none truncate m-1">{t("automation:create.importFileDesc")}</div>
								</div>
							</AccordionSummary>
							<AccordionDetails>
								<div className="col-span-full paper cursor-pointer hover:bg-bgGray p-2 m-2 flex flex-row items-center justify-between ">
									<div className="flex flex-row items-center">
										<Avatar className={`h-[30px] w-[30px] mr-2 bg-transparent items-center `} variant="rounded">
											{React.createElement(FILES.csv.icon, { className: 'text-[20px]' })}
										</Avatar>
										<div className="text-sm text-textGray leading-none items-center ">{file.name}</div>
									</div>
									<DeleteForeverIcon onClick={() => setFile(null)}/>
								</div>
							</AccordionDetails>
						</Accordion>
					)}
					{file && !loading && (
						<>
							<Accordion expanded={expanded === 'table'} onChange={handleChange('table')}>
								<AccordionSummary
									expandIcon={<ExpandMore />}
								>
									<div className="ml-2 mr-2">
										<div className="text-sm font-medium">
											<div className="text-md text-textGray leading-none truncate">{t("automation:create.destinationTable")}</div>
										</div>
										<div className="text-xs text-gray-400 leading-none truncate mt-1">{t("automation:create.destinationTableDesc")}</div>
									</div>
								</AccordionSummary>
								<AccordionDetails>
									<div className="ml-2 mr-2">
										<div className="text-xs text-gray-400 leading-none truncate mb-1">{t("automation:create.selectTableDesc")}</div>
										<TextField 
											id="action" 
											select 
											InputProps={{
												className: "text-sm"
											}}
											InputLabelProps={{
												className: "text-sm"
											}}
											variant="outlined" 
											className='text-sm grid-50' 
											size="small" 
											fullWidth
											value={selectAction} 
											onChange={(event) => onTable(event.target.value)}
										>
											<MenuItem key="new" value="new">
												<Add className='text-sm mr-2'/>
												{t("automation:create.emptyTable")}
											</MenuItem>
											<MenuItem key="select" value="select">
												<SelectAll className='text-sm mr-2'/>
												{t("automation:create.useTable")}
											</MenuItem>
										</TextField>
										<div className="mt-3" />
										{!showTables && (
											<TextField
												id="tableName"
												label={t('automation:create.tableName')}
												variant="outlined"
												className='text-sm'
												InputProps={{
													className: "text-sm"
												}}
												InputLabelProps={{
													className: "text-sm"
												}}
												size="small"
												fullWidth
												onChange={(event) => onChange('destinationProps', {...form.destinationProps, tableName: event.target.value})}
												value={form.destinationProps.tableName || ''}
												error={validation === null ? false : !validation.tableName.valid}
												helperText={
													validation !== null &&
													!validation.tableName.valid &&
													t(validation.tableName.error, {
														field: t('automation:create.tableName')
													})
												}
											/>
										)}
										{showTables && (
											<TableTypeRefTable
												loadKey={loadKey}
												teamId={teamId}
												onChange={(value) => onChange('destinationProps', {...form.destinationProps, selectTable: value})}
												value={form.destinationProps.selectTable}
												validation={validation.selectTable}
												fieldName={t('model:connectData.table')}
												showLabel
											/>
											
										)}
									</div>
								</AccordionDetails>
							</Accordion>
							<Accordion expanded={expanded === 'schema'} onChange={handleChange('schema')}>
								<AccordionSummary
									expandIcon={<ExpandMore />}
								>
									<div>
										<div className="text-sm font-medium">
											<div className="text-md text-textGray leading-none truncate m-1">{t("automation:create.fieldsMapping")}</div>
										</div>
										<div className="text-xs text-gray-400 leading-none truncate m-1">{t("automation:create.fieldsMappingDesc")}</div>
									</div>
								</AccordionSummary>
								<AccordionDetails>
									<NewSchema form={form} type='csv' showVariables={showVariables} onChange={onChange}/>
								</AccordionDetails>
							</Accordion>
						</>
					)}
				</div>
			</DialogContent>
			<DialogActions>
				<div className='mr-8'>
					<Button
						variant="outlined"
						className="mb-4 mr-2 normal-case"
						onClick={onClose}
					>
						{t('automation:buttons.cancel')}
					</Button>
					<LoadingButton
						loading={isLoading || isProcessing === 'Pending'}
						variant="contained"
						className="mr-2 mb-4 buttonContainedContrast normal-case"
						onClick={validate}
						loadingIndicator={<CircularProgress size={20}/>}
					>
						{t('automation:buttons.import')}
					</LoadingButton>
				</div>
				
			</DialogActions>
		</Dialog>
	)
}
