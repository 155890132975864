export const TEAM_ROLES = {
	admin: {
		key: 'admin',
		label: 'common:roles.admin',
		minUsers: 1,
		onlyAdminCanChange: true
	},
	create: {
		key: 'create',
		label: 'common:roles.create',
		minUsers: 0,
		onlyAdminCanChange: false
	},
	edit: {
		key: 'edit',
		label: 'common:roles.edit',
		minUsers: 0,
		onlyAdminCanChange: false
	},
	view: {
		key: 'view',
		label: 'common:roles.view',
		minUsers: 0,
		onlyAdminCanChange: false
	}
}

export const ASSET_ROLES = {
	edit: {
		key: 'edit',
		label: 'common:roles.edit'
	},
	view: {
		key: 'view',
		label: 'common:roles.view'
	}
}

export const TABLE_ROLES = { edit: ASSET_ROLES.edit, view: ASSET_ROLES.view }
export const MODEL_ROLES = { edit: ASSET_ROLES.edit, view: ASSET_ROLES.view }
