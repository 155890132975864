// import { RANGE_KEYWORDS, RANGE_EXAMPLES } from 'common/constants/formulas'
import i18n from 'common/config/i18n'

export function renderHelpPanel(view, activeElement, activeArgument) {
	const cursor = view.state.selection.main.anchor
	let dom = document.createElement('div')
	dom.id = 'cm-help-panel'

	// #### HEADER
	let header = document.createElement('div')
	dom.appendChild(header)
	header.classList.add('cm-help-panel-section')
	header.classList.add('cm-help-panel-separator')

	let title = document.createElement('div')
	header.appendChild(title)
	title.classList.add('cm-help-panel-row')
	title.innerHTML = `${activeElement.label}(`
	activeElement.args?.map((arg, i) => {
		const isActive = i == activeArgument || (i < activeArgument && arg.isUnbounded)
		title.innerHTML += `${isActive ? '<span class="cm-help-panel-highlight cm-help-panel-active-text">' : ''}${arg.isOptional ? '[' : ''}${arg.label}${arg.isUnbounded ? '; ...' : ''}${
			arg.isOptional ? ']' : ''
		}${isActive ? '</span>' : ''}${i != activeElement.args.length - 1 ? '; ' : ''}`
	})
	title.innerHTML += ')'

	// if (activeArgument < activeElement.args.length && activeElement.args[activeArgument].isTimeRange) renderRangeHelp(dom, view, activeElement, activeArgument)
	// else 
	renderStandardHelp(dom, view, activeElement, activeArgument)

	return { dom }
}

export function renderStandardHelp(dom, view, activeElement, activeArgument) {
	// #### ABOUT
	let about = document.createElement('div')
	dom.appendChild(about)
	about.classList.add('cm-help-panel-section')
	about.classList.add('cm-help-panel-separator')
	about.appendChild(renderTitle(i18n.t('formula:helper.about')))

	let aboutText = document.createElement('div')
	about.appendChild(aboutText)
	aboutText.innerHTML = `${activeElement.detail}`

	// #### ARGUMENTS
	let args = document.createElement('div')
	dom.appendChild(args)
	args.classList.add('cm-help-panel-section')
	args.classList.add('cm-help-panel-separator')
	args.appendChild(renderTitle(i18n.t('formula:helper.arguments')))

	activeElement.args?.map((arg, i) => {
		const isActive = i == activeArgument || (i < activeArgument && arg.isUnbounded)
		let argument = document.createElement('div')
		args.appendChild(argument)
		if (isActive) argument.classList.add('cm-help-panel-highlight')
		if (i != activeElement.args.length - 1) argument.classList.add('cm-help-panel-list-item')
		argument.innerHTML = `${isActive ? '<div class="cm-help-panel-active-text">' : '<div>'}${arg.label}${arg.isUnbounded ? '...' : ''}${arg.isOptional ? ' [optional]' : ''}${
			arg.isUnbounded ? ' repeatable' : ''
		}${isActive ? '</div>' : '</div>'}`
		argument.innerHTML += `<div><i>${arg.detail}</i></div>`
	})

	// #### EXAMPLE
	let examples = document.createElement('div')
	dom.appendChild(examples)
	examples.classList.add('cm-help-panel-section')
	examples.appendChild(renderTitle(i18n.t('formula:helper.example')))

	let exampleText = document.createElement('div')
	examples.appendChild(exampleText)
	exampleText.innerHTML = activeElement.example
}

// export function renderRangeHelp(dom, view, activeElement, activeArgument) {
// 	// #### SYNTAXIS
// 	let syntaxis = document.createElement('div')
// 	dom.appendChild(syntaxis)
// 	syntaxis.classList.add('cm-help-panel-section')
// 	syntaxis.classList.add('cm-help-panel-separator')
// 	syntaxis.appendChild(renderTitle(i18n.t('formula:helper.syntaxis')))

// 	let syntaxisText = document.createElement('div')
// 	syntaxis.appendChild(syntaxisText)
// 	syntaxisText.innerHTML = `start_period:end_period`

// 	// #### EXAMPLES
// 	let examples = document.createElement('div')
// 	dom.appendChild(examples)
// 	examples.classList.add('cm-help-panel-section')
// 	examples.classList.add('cm-help-panel-separator')
// 	examples.appendChild(renderTitle(i18n.t('formula:helper.examples')))

// 	RANGE_EXAMPLES.map((example, index) => {
// 		let li = document.createElement('li')
// 		li.id = `cm-help-panel-range-example-${index}`
// 		li.style.cssText += `overflow-x: hidden; text-overflow: ellipsis; display: flex; flex-direction: row; align-items: center; justify-content: space-between; padding: 3px 0px; height: 25px`
// 		li.innerHTML = `<span>${example.key}</span><span style="color: rgba(0,0,0,0.5); font-weight: 400; margin-left: 15px;">${example.detail}</span>`
// 		examples.appendChild(li)
// 	})

// 	// #### KEYWORDS
// 	let keywords = document.createElement('div')
// 	dom.appendChild(keywords)
// 	keywords.classList.add('cm-help-panel-section')
// 	keywords.appendChild(renderTitle(i18n.t('formula:helper.keywords')))

// 	RANGE_KEYWORDS.map((keyword, index) => {
// 		let li = document.createElement('li')
// 		li.id = `cm-help-panel-range-keyword-${index}`
// 		li.style.cssText += `overflow-x: hidden; text-overflow: ellipsis; display: flex; flex-direction: row; align-items: center; justify-content: space-between; padding: 3px 0px; height: 25px`
// 		li.innerHTML = `<span>${keyword.key}</span><span style="color: rgba(0,0,0,0.5); font-weight: 400; margin-left: 15px;">${keyword.detail}</span>`
// 		keywords.appendChild(li)
// 	})
// }

function renderTitle(label) {
	let title = document.createElement('span')
	title.className = 'cm-help-panel-title-text'
	title.textContent = label
	return title
}
