// #### INITIAL DATA LOADING
export const LOAD_DATA = 'tableDataSaga/LOAD_DATA'
export const CLEAR_DATA = 'tableDataSaga/CLEAR_DATA'
// #### ROW OPERATIONS
export const ADD_ROWS = 'tableDataSaga/ADD_ROWS'
export const UPDATE_ROWS = 'tableDataSaga/UPDATE_ROWS'
export const DELETE_ROW = 'tableDataSaga/DELETE_ROW'
export const DOWNLOAD_CSV = 'tableDataSaga/DOWNLOAD_CSV'
// #### SEARCH
export const SEARCH_COLUMN = 'tableDataSaga/SEARCH_COLUMN'
// #### FILE OPERATIONS
export const UPLOAD_FILE = 'tableDataSaga/UPLOAD_FILE'
export const FILE_TRANSMIT_SUCCESS = 'tableDataSaga/FILE_TRANSMIT_SUCCESS'
export const DELETE_FILE = 'tableDataSaga/DELETE_FILE'

// #### INITIAL DATA LOADING
export const loadData = ({ tid, teamId, aid, sort, filter, page, limit, isInitial }) => {
	return { type: LOAD_DATA, tid, teamId, aid, sort, filter, page, limit, isInitial }
}

export const clearData = ({ tid, teamId, aid}) => {
	return { type: CLEAR_DATA, tid, teamId, aid }
}

// #### DATA OPERATIONS
export const addRows = ({ tid, teamId, aid, inserts }) => {
	return { type: ADD_ROWS, tid, teamId, aid, inserts }
}

export const updateRows = ({ tid, teamId, aid, updates }) => {
	return { type: UPDATE_ROWS, tid, teamId, aid, updates }
}

export const deleteRow = ({ tid, teamId, aid, rowId, rowIndex }) => {
	return { type: DELETE_ROW, tid, teamId, aid, rowId, rowIndex }
}

export const downloadCSV = ({ tid, teamId, aid, filter, sort }) => {
	return { type: DOWNLOAD_CSV, tid, teamId, aid, filter, sort }
}

// #### SEARCH
export const searchColumn = ({ key, tid, teamId, table, column, search, isInitial, nextPage }) => {
	return { type: SEARCH_COLUMN, key, tid, teamId, table, column, search: search ? search.toLowerCase() : null, isInitial, nextPage: isInitial ? 1 : nextPage }
}

// #### FILE OPERATIONS
export const uploadFile = ({ tid, teamId, aid, rowId, colId, rowIndex, file, fileName, fileSize, fileType }) => {
	return { type: UPLOAD_FILE, tid, teamId, aid, rowId, colId, rowIndex, file, fileName, fileSize, fileType }
}

export const fileTransmitSuccess = ({ tid, teamId, aid, rowId, colId, rowIndex, fileId, fileName, fileSize, fileType, filePath, fileFullPath }) => {
	return { type: FILE_TRANSMIT_SUCCESS, tid, teamId, aid, rowId, colId, rowIndex, fileId, fileName, fileSize, fileType, filePath, fileFullPath }
}

export const deleteFile = ({ tid, teamId, aid, rowId, colId, rowIndex, fileId }) => {
	return { type: DELETE_FILE, tid, teamId, aid, fileId, rowId, colId, rowIndex }
}
