import { customAlphabet } from 'nanoid'
import slugify from 'react-slugify'

//const alphabet = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
const alphabet = 'abcdefghijklmnopqrstuvwxyz0123456789'

export function generateKey(length = 10) {
	const nanoid = customAlphabet(alphabet, length)
	return nanoid()
}

export function generateFriendlyKey(literal, includeRandomness = true, delimiter = '_') {
	const cleanLiteral = literal.replace('-', '_')
	const slug = slugify(cleanLiteral, { delimiter: delimiter })
	const shortSlug = slug.length > 19 ? slug.substring(0, 19) : slug
	const key = includeRandomness ? shortSlug + '_' + generateKey(5) : shortSlug
	return key
}
