import { createSlice } from '@reduxjs/toolkit'

export const billingSlice = createSlice({
	name: 'billing',
	initialState: {
		customer: null,
		billableUsers: null
	},
	reducers: {
		loadCustomer: (state, action) => {
			state.customer = { loaded: true, ...action.payload }
		},
		cancelCustomer: (state, action) => {
			state.customer = null
		},
		loadBillableUsers: (state, action) => {
			state.billableUsers = { loaded: true, ...action.payload }
		},
		cancelBillableUsers: (state, action) => {
			state.billableUsers = null
		}
	}
})

export const { loadCustomer, cancelCustomer, loadBillableUsers, cancelBillableUsers } = billingSlice.actions

export default billingSlice.reducer
