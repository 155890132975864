import { PRODUCTS } from 'common/constants/products'
import { MODEL_NEW, MODEL_NEW_VIEW } from 'model/constants/modelParameters'
import { TABLE_NEW, TABLE_NEW_VIEW } from 'table/constants/tableParameters'

// #### SUBSCRIBE TO ASSETS
export const LOAD_ASSETS = 'assetSaga/LOAD_ASSETS'
export const CANCEL_ASSETS = 'assetSaga/CANCEL_ASSETS'
// #### SUBSCRIBE TO ASSET
export const LOAD_ASSET = 'assetSaga/LOAD_ASSET'
export const CANCEL_ASSET = 'assetSaga/CANCEL_ASSET'
// #### SEARCH & DROP-DOWN SELECTION
export const SEARCH_ASSETS = 'assetSaga/SEARCH_ASSETS'
export const LOAD_SELECTED_ASSET = 'assetSaga/LOAD_SELECTED_ASSET'
// #### MANAGE ASSET
export const CREATE_ASSET = 'assetSaga/CREATE_ASSET'
export const UPDATE_ASSET = 'assetSaga/UPDATE_ASSET'
export const DELETE_ASSET = 'assetSaga/DELETE_ASSET'
export const COPY_ASSET = 'assetSaga/COPY_ASSET'
// SUBSCRIBE TO USERS
export const LOAD_ASSET_USERS = 'assetSaga/LOAD_ASSET_USERS'
export const CANCEL_ASSET_USERS = 'assetSaga/CANCEL_ASSET_USERS'
// #### MANAGE PERMITS
export const INVITE_USER = 'assetSaga/INVITE_USER'
export const UPDATE_USER = 'assetSaga/UPDATE_USER'
export const DELETE_USER = 'assetSaga/DELETE_USER'
// #### UPLOAD SCREENSHOT
export const UPLOAD_SCREENSHOT = 'assetSaga/UPLOAD_SCREENSHOT'
export const FILE_TRASMIT_SUCCESS = 'assetSaga/FILE_TRASMIT_SUCCESS'

// #### SUBSCRIBE TO ASSETS
export const loadAssets = ({ tid, uid, teamId }) => {
	return { type: LOAD_ASSETS, tid, uid, teamId }
}

export const cancelAssets = () => {
	return { type: CANCEL_ASSETS }
}

// #### SUBSCRIBE TO ASSET
export const loadAsset = ({ tid, aid }) => {
	return { type: LOAD_ASSET, tid, aid }
}

export const cancelAsset = ({ id }) => {
	return { type: CANCEL_ASSET, id }
}

// #### SEARCH & DROP-DOWN SELECTION
export const searchAssets = ({ key, tid, uid, searchTerm, startAfter, assetTypes, where }) => {
	return { type: SEARCH_ASSETS, key, tid, uid, searchTerm: searchTerm?.toLowerCase(), startAfter, assetTypes, where }
}

export const loadSelectedAsset = ({ key, tid, uid, aid }) => {
	return { type: LOAD_SELECTED_ASSET, key, tid, uid, aid }
}

// #### MANAGE TEAMS
export const createAsset = ({ tid, uid, teamId, assetType, content, defaults = assetType === PRODUCTS.table.key ? TABLE_NEW : assetType === PRODUCTS.model.key ? MODEL_NEW : null }) => {
	return { type: CREATE_ASSET, tid, uid, teamId, assetType, content, defaults, view: assetType === PRODUCTS.table.key ? TABLE_NEW_VIEW : assetType === PRODUCTS.model.key ? MODEL_NEW_VIEW : null }
}

export const updateAsset = ({ tid, aid, content, loadingKey = 'updateAsset' }) => {
	return { type: UPDATE_ASSET, tid, aid, content, loadingKey }
}

export const deleteAsset = ({ tid, aid, assetType }) => {
	return { type: DELETE_ASSET, tid, aid, assetType }
}

export const copyAsset = ({ tid, teamId, aid }) => {
	return { type: COPY_ASSET, tid, teamId, aid }
}
// SUBSCRIBE TO USERS
export const loadAssetUsers = ({ tid, aid }) => {
	return { type: LOAD_ASSET_USERS, tid, aid, id: aid }
}

export const cancelAssetUsers = ({ id }) => {
	return { type: CANCEL_ASSET_USERS, id }
}

// #### MANAGE PERMITS
export const inviteUser = ({ tid, teamId, uid, aid, email, role }) => {
	return { type: INVITE_USER, tid, teamId, uid, aid, email, role }
}

export const updateUser = ({ tid, teamId, aid, userId, role }) => {
	return { type: UPDATE_USER, tid, teamId, aid, userId, role }
}

export const deleteUser = ({ tid, teamId, aid, userId }) => {
	return { type: DELETE_USER, tid, teamId, aid, userId }
}

// #### UPLOAD SCREENSHOT
export const uploadScreenshot = ({ tid, teamId, aid, file }) => {
	return { type: UPLOAD_SCREENSHOT, tid, teamId, aid, file }
}

export const fileTransmitSuccess = ({ tid, aid, filePath, downloadUrl }) => {
	return { type: FILE_TRASMIT_SUCCESS, tid, aid, filePath, downloadUrl }
}
