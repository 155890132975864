import { createSlice } from '@reduxjs/toolkit'
import { PUBLIC_TENANT_ID } from 'common/config/tenant'

const initialState = {
	uid: null,
	email: null,
	tid: PUBLIC_TENANT_ID,
	isLoggedIn: false,
	checkDone: false
}

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		loginSuccess: (state, action) => {
			state.uid = action.payload.uid
			state.email = action.payload.email
			state.tid = action.payload.tid
			state.isLoggedIn = true
			state.checkDone = true
		},
		logoutSuccess: (state, action) => {
			state.uid = initialState.uid
			state.email = initialState.email
			state.tid = initialState.tid
			state.isLoggedIn = false
			state.checkDone = true
		},
		cancelAuthentication: (state, action) => {
			state.uid = initialState.uid
			state.email = initialState.email
			state.tid = initialState.tid
			state.isLoggedIn = initialState.isLoggedIn
			state.checkDone = initialState.checkDone
		}
	}
	// extraReducers: (builder) => {
	// 	builder.addCase(actions.loginSuccess, (state, action) => {
	// 		state.tid = action.payload.tid
	// 		state.uid = action.payload.uid
	// 		state.email = action.payload.email
	// 	})
	// }
})

export const { loginSuccess, logoutSuccess, cancelAuthentication } = authSlice.actions

export default authSlice.reducer

// export const loadingOn = createAction('global/LOADING_ON', function prepare({ text }) {
// 	return {
// 		payload: { text }
// 	}
// })
