import ModelIcon from '@mui/icons-material/Functions'
import TableIcon from '@mui/icons-material/GridOn'
import FolderIcon from '@mui/icons-material/FolderOpen'
import TemplateIcon from '@mui/icons-material/DashboardOutlined'
import DataIcon from '@mui/icons-material/LeaderboardOutlined'
import DataSourceIcon from '@mui/icons-material/LayersOutlined'
import CommunityIcon from '@mui/icons-material/Public'

export const PRODUCTS = {
	table: {
		key: 'table',
		name: 'common:products.table',
		namePlural: 'common:products.tables',
		description: 'common:products.tableDesc',
		icon: TableIcon,
		theme: 'stheme-red1',
		header: 'common:products.plan'
	},
	model: {
		key: 'model',
		name: 'common:products.model',
		namePlural: 'common:products.models',
		description: 'common:products.modelDesc',
		icon: ModelIcon,
		theme: 'stheme-blue1'
	},
	datasource: {
		key: 'datasource',
		name: 'common:products.datasource',
		namePlural: 'common:products.datasources',
		description: 'common:products.datasourceDesc',
		icon: DataSourceIcon,
		theme: 'stheme-purple1',
		header: 'common:products.automate'
	},
	folder: {
		key: 'folder',
		name: 'common:products.folder',
		namePlural: 'common:products.folders',
		description: 'common:products.folderDesc',
		icon: FolderIcon,
		theme: 'stheme-yellow1'
	}
}

export const SECTIONS = {
	community: {
		key: 'community',
		name: 'common:community.community',
		namePlural: 'common:community.community',
		description: 'common:community.communityDesc',
		icon: CommunityIcon,
		theme: 'stheme-green1',
		header: 'common:community.share'
	}
}

export const COMMUNITY = {
	template: {
		key: 'template',
		name: 'common:community.template',
		namePlural: 'common:community.templates',
		description: 'common:community.templateDesc',
		icon: TemplateIcon,
		theme: 'stheme-orange1'
	},
	data: {
		key: 'data',
		name: 'common:community.data',
		namePlural: 'common:community.data',
		description: 'common:community.dataDesc',
		icon: DataIcon,
		theme: 'stheme-teal1'
	}
}
