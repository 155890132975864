import { call, takeEvery, put, spawn, select } from 'redux-saga/effects'
import * as api from 'common/api/spaceApi'
import * as authApi from 'common/api/authApi'
import * as storeAction from 'common/store/spaceReducer'
import * as sagaAction from 'common/saga-actions/spaceActions'
import * as subscribeHelper from 'common/saga/helpers/subscribeHelper'
import * as globalStoreAction from 'common/store/globalReducer'
import * as globalSagaAction from 'common/saga-actions/globalActions'
import { generateKeywords } from 'common/saga/helpers/utils'
import { throwError } from 'common/config/errors'
const FILE_NAME = 'spaceSagas'

const getTid = (state) => state.auth.tid
const getUid = (state) => state.auth.uid
const getTeamId = (state) => state.team.teamId

// #### PATH TO THE COLLECTION
function defaultUserSpacePath(includeDocId) {
	const path = [
		{ collection: 'tenants', param: 'tid', value: null },
		{ collection: 'users', param: 'uid', value: null },
		{ collection: 'spaces', param: includeDocId ? 'sid' : null, value: null }
	]
	return path
}

// function defaultUsersPath(includeDocId) {
// 	const path = [
// 		{ collection: 'tenants', param: 'tid', value: null },
// 		{ collection: 'spaces', param: 'sid', value: null },
// 		{ collection: 'users', param: includeDocId ? 'userId' : null, value: null }
// 	]
// 	return path
// }

// #### SUBSCRIBE TO SPACES
function* loadSpacesReq() {
	const queryConfig = subscribeHelper.queryConfig({
		path: defaultUserSpacePath(false),
		where: [{ attribute: 'teamId', operator: '==', param: 'teamId', value: null }],
		orderBy: [{ attribute: 'name', order: 'asc' }],
		returnType: 'map'
	})
	const sagaConfig = subscribeHelper.sagaConfig({ loadAction: sagaAction.LOAD_SPACES, loadResponse: storeAction.loadSpaces, cancelAction: sagaAction.CANCEL_SPACES, cancelResponse: storeAction.cancelSpaces })
	yield spawn(subscribeHelper.subscribe, sagaConfig, queryConfig)
}

// #### MANAGE TEAMS
function* createSpaceReq() {
	yield takeEvery(sagaAction.CREATE_SPACE, createSpace)
}

function* createSpace(triggeredAction) {
	try {
		const { content } = triggeredAction
		yield put(globalStoreAction.loadingOn({ key: 'newSpace' }))
		const tid = yield select(getTid)
		const uid = yield select(getUid)
		const teamId = yield select(getTeamId)
		yield call(createSpaceExecute, tid, uid, teamId, content)
	} catch (err) {
		const content = throwError(err, FILE_NAME, createSpace.name)
		yield put(globalSagaAction.showMessage({ content }))
	} finally {
		yield put(globalStoreAction.loadingOff({ key: 'newSpace' }))
	}
}

export function* createSpaceExecute(tid, uid, teamId, _content) {
	// const spaces = yield select(getSpaces)
	// const numSpaces = (spaces && Object.keys(spaces)?.length) || 0
	// const themeIndex = numSpaces % THEMES.length
	// const theme = THEMES[themeIndex]
	const keywords = generateKeywords(_content.name)
	const content = { ..._content, keywords, teamId }
	const { token } = yield call(authApi.getAuthToken)
	const { data } = yield call(api.createSpace, token, tid, uid, teamId, content)
	const spaceId = data.spaceId
	return spaceId
}

function* updateSpaceReq() {
	yield takeEvery(sagaAction.UPDATE_SPACE, updateSpace)
}

function* updateSpace(triggeredAction) {
	const { sid, content: _content } = triggeredAction
	try {
		yield put(globalStoreAction.loadingOn({ key: 'updateSpace', item: sid }))
		const tid = yield select(getTid)
		const uid = yield select(getUid)
		const teamId = yield select(getTeamId)
		let content = { ..._content }
		if (content.name) {
			const keywords = generateKeywords(content.name)
			content = { ...content, keywords }
		}
		const { token } = yield call(authApi.getAuthToken)
		yield call(api.updateSpace, token, tid, uid, teamId, sid, content)
	} catch (err) {
		const content = throwError(err, FILE_NAME, updateSpace.name)
		yield put(globalSagaAction.showMessage({ content }))
	} finally {
		yield put(globalStoreAction.loadingOff({ key: 'updateSpace', item: sid }))
	}
}

function* deleteSpaceReq() {
	yield takeEvery(sagaAction.DELETE_SPACE, deleteSpace)
}

function* deleteSpace(triggeredAction) {
	const { sid } = triggeredAction
	try {
		yield put(globalStoreAction.loadingOn({ key: 'deleteSpace', item: sid }))
		const tid = yield select(getTid)
		const teamId = yield select(getTeamId)
		const { token } = yield call(authApi.getAuthToken)
		yield call(api.deleteSpace, token, tid, teamId, sid)
	} catch (err) {
		const content = throwError(err, FILE_NAME, deleteSpace.name)
		yield put(globalSagaAction.showMessage({ content }))
	} finally {
		yield put(globalStoreAction.loadingOff({ key: 'deleteSpace', item: sid }))
	}
}

export default function* root() {
	// #### SUBSCRIBE TO SPACES
	yield spawn(loadSpacesReq)
	// #### MANAGE SPACES
	yield spawn(createSpaceReq)
	yield spawn(updateSpaceReq)
	yield spawn(deleteSpaceReq)
}
