// SAGA ACTIONS
export const SHOW_MESSAGE = 'globalSaga/SHOW_MESSAGE'
export const DOWNLOAD_FILE = 'globalSaga/DOWNLOAD_FILE'

// ACTIONS FOR SCREENS & SAGAS
// messageType = error, warning, info, success
export const showMessage = ({ key = 'global', item = null, content, messageType = 'error', isPersistent = false }) => {
	return { type: SHOW_MESSAGE, key, item, content, messageType, isPersistent }
}

export const downloadFile = ({ userId, signedUrl }) => {
	return { type: DOWNLOAD_FILE, userId, signedUrl }
}