import { useSelector, useDispatch } from 'react-redux'
import { inviteUser, updateUser, deleteUser } from 'common/saga-actions/teamActions'
import Share from 'common/components/Share'
import { useTranslation } from 'react-i18next'
import { TEAM_ROLES } from 'common/constants/roles'
import { useTeamPermits } from 'common/hooks/useTeamPermits'

export default function TeamShare({ isOpen, onClose }) {
	const { t } = useTranslation('common')
	const dispatch = useDispatch()
	const { canAdmin } = useTeamPermits()

	const tid = useSelector((state) => state.auth.tid)
	const uid = useSelector((state) => state.auth.uid)
	const teamId = useSelector((state) => state.team.teamId)
	const name = useSelector((state) => state.team.team?.name)
	const users = useSelector((state) => state.team.teamUsers?.result)
	const loadingInvite = useSelector((state) => state.global.loading?.inviteTeamUser)
	const loadingUpdate = useSelector((state) => state.global.loading?.updateTeamUser)

	function onInviteUser({ email, role }) {
		dispatch(inviteUser({ tid, uid, teamId, email, role }))
	}

	function onUpdateUser({ userId, role }) {
		dispatch(updateUser({ tid, teamId, userId, role }))
	}

	function onDeleteUser({ userId }) {
		dispatch(deleteUser({ tid, teamId, userId }))
	}

	return (
		<Share
			isOpen={isOpen}
			onClose={onClose}
			name={name}
			product={t('common:team.team')}
			users={users}
			loadingInvite={loadingInvite}
			loadingUpdate={loadingUpdate}
			onInviteUser={onInviteUser}
			onUpdateUser={onUpdateUser}
			onDeleteUser={onDeleteUser}
			roles={TEAM_ROLES}
			isAdmin={canAdmin}
		/>
	)
}
