import Layout from 'common/screens/layout/Layout'
import ModelBar from 'model/screens/ModelBar'
import Model from 'model/screens/Model'
import TableBar from 'table/screens/TableBar'
import Table from 'table/screens/Table'
import { useSplitWindow } from 'common/hooks/useSplitWindow'
import { PRODUCTS } from 'common/constants/products'
import TeamMenu from 'common/screens/team/TeamMenu'

export default function Asset() {
	const { type } = useSplitWindow(0)
	const { type: type2 } = useSplitWindow(1)

	return (
		<Layout
			menuContent={TeamMenu}
			barContent={type === PRODUCTS.table.key ? TableBar : type === PRODUCTS.model.key ? ModelBar : null}
			secondaryBar={type2 === PRODUCTS.table.key ? TableBar : type2 === PRODUCTS.model.key ? ModelBar : null}
			secondaryContent={type2 === PRODUCTS.table.key ? Table : type2 === PRODUCTS.model.key ? Model : null}
			isBodyScrollable={false}
		>
			{type === PRODUCTS.table.key ? <Table /> : type === PRODUCTS.model.key ? <Model /> : null}
		</Layout>
	)
}
