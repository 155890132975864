// import { useState, useEffect, useCallback } from 'react'
// import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useLocation, matchPath, useParams } from 'react-router'
import useNavigate from 'common/hooks/useNavigate'
import { Breadcrumbs as MuiBreadcrumbs, Chip } from '@mui/material'
import { COMMUNITY, SECTIONS } from 'common/constants/products'
import clsx from 'clsx'
import React from 'react'

export default function Breadcrumbs() {
	const { t } = useTranslation(['common', 'automation'])
	const { navigate } = useNavigate()
	let { teamId, folderId } = useParams()
	const { pathname } = useLocation()

	// #### REDUX
	// const teamName = useSelector((state) => state.team.team?.name)
	// const assets = useSelector((state) => state.asset.assets?.result)

	// #### STATE
	// const [folders, setFolders] = useState([])

	// #### EFFECTS
	// useEffect(() => {
	// 	if (folderId) setFolders(foldersPath(folderId))
	// 	else setFolders([])
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [folderId, assets])

	// Create an array with the folder hierarchy
	// const foldersPath = useCallback(
	// 	(folderId) => {
	// 		if (!assets) return []
	// 		const asset = assets[folderId]
	// 		if (!asset) return []
	// 		const folderName = asset.name
	// 		const folderParentId = asset.folderId
	// 		const folder = { id: folderId, name: folderName }
	// 		if (folderParentId) return [...foldersPath(folderParentId), folder]
	// 		else return [folder]
	// 	},
	// 	[assets]
	// )

	return (
		<MuiBreadcrumbs className="mx-4 mt-4 mb-4">
			{/* {matchPath('/team/:teamId/*', pathname) && (
				<Chip
					label={teamName}
					onClick={matchPath('/team/:teamId', pathname) ? undefined : () => navigate(`/team/${teamId}`)}
					icon={<HomeIcon className="breadcrumpIcon" />}
					size="small"
					className={clsx('breadcrump', !matchPath('/team/:teamId', pathname) && 'breadcrumpClickable')}
				/>
			)} */}

			{/* {matchPath('/team/:teamId/datasources', pathname) && (
				<Chip label={t(PRODUCTS.datasource.namePlural)} icon={React.createElement(PRODUCTS.datasource.icon, { className: 'breadcrumpIcon' })} size="small" className="breadcrump" />
			)}

			{matchPath('/team/:teamId/billing', pathname) && <Chip label={t('common:team.billing')} icon={<BillingIcon className="breadcrumpIcon" />} size="small" className="breadcrump" />} */}

			{matchPath('/team/:teamId/community/*', pathname) && (
				<Chip
					label={t('common:community.shareWithCommunity')}
					onClick={matchPath('/team/:teamId/community', pathname) ? undefined : () => navigate(`/team/${teamId}/community`)}
					icon={React.createElement(SECTIONS.community.icon, { className: 'breadcrumpIcon' })}
					size="small"
					className={clsx('breadcrump', !matchPath('/team/:teamId/community', pathname) && 'breadcrumpClickable')}
				/>
			)}

			{matchPath('/team/:teamId/community/template/*', pathname) && (
				<Chip label={t(COMMUNITY.template.name)} icon={React.createElement(COMMUNITY.template.icon, { className: 'breadcrumpIcon' })} size="small" className="breadcrump" />
			)}

			{matchPath('/team/:teamId/community/data/*', pathname) && (
				<Chip label={t(COMMUNITY.data.name)} icon={React.createElement(COMMUNITY.data.icon, { className: 'breadcrumpIcon' })} size="small" className="breadcrump" />
			)}

			{/* {matchPath('/team/:teamId/folder/:folderId', pathname) &&
				folders?.map((folder) => (
					<Chip
						key={folder.id}
						label={folder.name}
						onClick={folder.id === folderId ? undefined : () => navigate(`/team/${teamId}/folder/${folder.id}`)}
						icon={React.createElement(PRODUCTS.folder.icon, { className: 'breadcrumpIcon' })}
						size="small"
						className={clsx('breadcrump', folder.id !== folderId && 'breadcrumpClickable')}
					/>
				))} */}
		</MuiBreadcrumbs>
	)
}
