import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	user: null
}

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		loadUser: (state, action) => {
			state.user = { loaded: true, ...action.payload }
		},
		cancelUser: (state, action) => {
			state.user = null
		}
	}
})

export const { loadUser, cancelUser } = userSlice.actions

export default userSlice.reducer
