import React, { useEffect, useRef, useState } from 'react'
import { Button, Menu, MenuItem, TextField } from '@mui/material'
import PlayIcon from '@mui/icons-material/PlayArrowOutlined'
import CalendarIcon from '@mui/icons-material/CalendarMonthOutlined'
import { Chart, ReactGoogleChartEvent } from 'react-google-charts'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MoreIcon from '@mui/icons-material/MoreVert'

export default function DemoSeq({ aid }) {
	const italian = aid === 'AtKT7yxX0fQjWf4kW3p0'

	const [line, setLine] = useState('LN01')
	const [tasks, setTasks] = useState([])
	const [menu, setMenu] = useState(null)
	const [chartEditor, setChartEditor] = useState()
	const [chartWrapper, setChartWrapper] = useState()
	const [google, setGoogle] = useState()
	const selected = useRef()
	const chartRef = useRef()

	const OPS = {
		LN01: [
			['OP0001', italian ? 'Valido' : 'Válido', new Date(2023, 4, 20, 12, 41), new Date(2023, 4, 21, 12, 44)],
			['OP0002', italian ? 'Valido' : 'Válido', new Date(2023, 4, 21, 12, 45), new Date(2023, 4, 22, 12, 52)],
			['OP0003', italian ? 'Valido' : 'Válido', new Date(2023, 4, 22, 12, 5), new Date(2023, 4, 22, 19, 19)],
			['OP0004', italian ? 'Valido' : 'Válido', new Date(2023, 4, 22, 19, 20), new Date(2023, 4, 23, 11, 29)],
			['OP0005', italian ? 'Valido' : 'Válido', new Date(2023, 4, 27, 15, 2), new Date(2023, 4, 28, 15, 54)],
			['OP0006', italian ? 'Valido' : 'Válido', new Date(2023, 4, 23, 11, 30), new Date(2023, 4, 23, 15, 54)],
			['OP0007', italian ? 'Valido' : 'Válido', new Date(2023, 4, 23, 15, 55), new Date(2023, 4, 24, 1, 10)],
			['OP0008', italian ? 'Valido' : 'Válido', new Date(2023, 4, 26, 1, 50), new Date(2023, 4, 26, 20, 50)],
			['OP0009', italian ? 'Valido' : 'Válido', new Date(2023, 4, 28, 15, 55), new Date(2023, 4, 29, 1, 10)],
			['OP0010', italian ? 'Valido' : 'Válido', new Date(2023, 4, 18, 12, 0), new Date(2023, 4, 18, 19, 9)],
			['OP0011', italian ? 'Valido' : 'Válido', new Date(2023, 4, 24, 1, 11), new Date(2023, 4, 25, 1, 42)],
			['OP0012', italian ? 'Valido' : 'Válido', new Date(2023, 4, 18, 19, 10), new Date(2023, 4, 19, 12, 15)],
			['OP0013', italian ? 'Valido' : 'Válido', new Date(2023, 4, 29, 1, 11), new Date(2023, 4, 30, 1, 42)],
			['OP0014', italian ? 'Valido' : 'Válido', new Date(2023, 4, 26, 20, 51), new Date(2023, 4, 27, 15, 1)],
			['OP0015', italian ? 'Valido' : 'Válido', new Date(2023, 4, 25, 1, 42), new Date(2023, 4, 26, 1, 49)],
			['OP0016', italian ? 'Valido' : 'Válido', new Date(2023, 4, 19, 12, 16), new Date(2023, 4, 19, 18, 24)],
			['OP0017', italian ? 'Valido' : 'Válido', new Date(2023, 4, 19, 18, 25), new Date(2023, 4, 20, 12, 40)],
			['OP0018', italian ? 'Valido' : 'Válido', new Date(2023, 4, 30, 1, 42), new Date(2023, 5, 1, 4, 32)]
		],
		LN02: [
			['OP0019', italian ? 'Valido' : 'Válido', new Date(2023, 4, 20, 12, 41), new Date(2023, 4, 21, 12, 44)],
			['OP0020', italian ? 'Valido' : 'Válido', new Date(2023, 4, 21, 12, 45), new Date(2023, 4, 22, 12, 52)],
			['OP0021', italian ? 'Valido' : 'Válido', new Date(2023, 4, 22, 12, 5), new Date(2023, 4, 22, 19, 19)],
			['OP0022', italian ? 'Valido' : 'Válido', new Date(2023, 4, 22, 19, 20), new Date(2023, 4, 23, 11, 29)],
			['OP0023', italian ? 'Valido' : 'Válido', new Date(2023, 4, 27, 15, 2), new Date(2023, 4, 28, 15, 54)],
			['OP0024', italian ? 'Valido' : 'Válido', new Date(2023, 4, 23, 11, 30), new Date(2023, 4, 23, 15, 54)],
			['OP0025', italian ? 'Valido' : 'Válido', new Date(2023, 4, 23, 15, 55), new Date(2023, 4, 24, 1, 10)],
			['OP0026', italian ? 'Valido' : 'Válido', new Date(2023, 4, 26, 1, 50), new Date(2023, 4, 26, 20, 50)],
			['OP0027', italian ? 'Valido' : 'Válido', new Date(2023, 4, 28, 15, 55), new Date(2023, 4, 29, 1, 10)],
			['OP0028', italian ? 'Valido' : 'Válido', new Date(2023, 4, 18, 12, 0), new Date(2023, 4, 18, 19, 9)],
			['OP0029', italian ? 'Valido' : 'Válido', new Date(2023, 4, 24, 1, 11), new Date(2023, 4, 25, 1, 42)],
			['OP0030', italian ? 'Valido' : 'Válido', new Date(2023, 4, 18, 19, 10), new Date(2023, 4, 19, 12, 15)],
			['OP0031', italian ? 'Valido' : 'Válido', new Date(2023, 4, 29, 1, 11), new Date(2023, 4, 30, 1, 42)],
			['OP0032', italian ? 'Valido' : 'Válido', new Date(2023, 4, 26, 20, 51), new Date(2023, 4, 27, 15, 1)],
			['OP0033', italian ? 'Valido' : 'Válido', new Date(2023, 4, 25, 1, 42), new Date(2023, 4, 26, 1, 49)],
			['OP0034', italian ? 'Valido' : 'Válido', new Date(2023, 4, 19, 12, 16), new Date(2023, 4, 19, 18, 24)],
			['OP0035', italian ? 'Valido' : 'Válido', new Date(2023, 4, 19, 18, 25), new Date(2023, 4, 20, 12, 40)],
			['OP0036', italian ? 'Valido' : 'Válido', new Date(2023, 4, 30, 1, 42), new Date(2023, 5, 1, 4, 32)]
		],
		LN03: [
			['OP0037', italian ? 'Valido' : 'Válido', new Date(2023, 4, 20, 12, 41), new Date(2023, 4, 21, 12, 44)],
			['OP0038', italian ? 'Valido' : 'Válido', new Date(2023, 4, 21, 12, 45), new Date(2023, 4, 22, 12, 52)],
			['OP0039', italian ? 'Valido' : 'Válido', new Date(2023, 4, 22, 12, 5), new Date(2023, 4, 22, 19, 19)],
			['OP0040', italian ? 'Valido' : 'Válido', new Date(2023, 4, 22, 19, 20), new Date(2023, 4, 23, 11, 29)],
			['OP0041', italian ? 'Valido' : 'Válido', new Date(2023, 4, 27, 15, 2), new Date(2023, 4, 28, 15, 54)],
			['OP0042', italian ? 'Valido' : 'Válido', new Date(2023, 4, 23, 11, 30), new Date(2023, 4, 23, 15, 54)],
			['OP0043', italian ? 'Valido' : 'Válido', new Date(2023, 4, 23, 15, 55), new Date(2023, 4, 24, 1, 10)],
			['OP0044', italian ? 'Valido' : 'Válido', new Date(2023, 4, 26, 1, 50), new Date(2023, 4, 26, 20, 50)],
			['OP0045', italian ? 'Valido' : 'Válido', new Date(2023, 4, 28, 15, 55), new Date(2023, 4, 29, 1, 10)],
			['OP0046', italian ? 'Valido' : 'Válido', new Date(2023, 4, 18, 12, 0), new Date(2023, 4, 18, 19, 9)],
			['OP0047', italian ? 'Valido' : 'Válido', new Date(2023, 4, 24, 1, 11), new Date(2023, 4, 25, 1, 42)],
			['OP0048', italian ? 'Valido' : 'Válido', new Date(2023, 4, 18, 19, 10), new Date(2023, 4, 19, 12, 15)],
			['OP0049', italian ? 'Valido' : 'Válido', new Date(2023, 4, 29, 1, 11), new Date(2023, 4, 30, 1, 42)]
		]
	}

	const OPS_FR = {
		LN01: [
			['1438264', italian ? 'Valido' : 'Válido', new Date(2023, 4, 20, 12, 41), new Date(2023, 4, 21, 12, 44)],
			['1438265', italian ? 'Valido' : 'Válido', new Date(2023, 4, 21, 12, 45), new Date(2023, 4, 22, 12, 52)],
			['1438266', italian ? 'Valido' : 'Válido', new Date(2023, 4, 22, 12, 5), new Date(2023, 4, 22, 19, 19)],
			['1438267', italian ? 'Valido' : 'Válido', new Date(2023, 4, 22, 19, 20), new Date(2023, 4, 23, 11, 29)],
			['1438268', italian ? 'Valido' : 'Válido', new Date(2023, 4, 27, 15, 2), new Date(2023, 4, 28, 15, 54)],
			['1438269', italian ? 'Valido' : 'Válido', new Date(2023, 4, 23, 11, 30), new Date(2023, 4, 23, 15, 54)],
			['1438270', italian ? 'Valido' : 'Válido', new Date(2023, 4, 23, 15, 55), new Date(2023, 4, 24, 1, 10)],
			['1438271', italian ? 'Valido' : 'Válido', new Date(2023, 4, 26, 1, 50), new Date(2023, 4, 26, 20, 50)],
			['1438280', italian ? 'Valido' : 'Válido', new Date(2023, 4, 28, 15, 55), new Date(2023, 4, 29, 1, 10)],
			['1438281', italian ? 'Valido' : 'Válido', new Date(2023, 4, 18, 12, 0), new Date(2023, 4, 18, 19, 9)],
			['1438282', italian ? 'Valido' : 'Válido', new Date(2023, 4, 24, 1, 11), new Date(2023, 4, 25, 1, 42)],
			['1438283', italian ? 'Valido' : 'Válido', new Date(2023, 4, 18, 19, 10), new Date(2023, 4, 19, 12, 15)],
			['1438284', italian ? 'Valido' : 'Válido', new Date(2023, 4, 29, 1, 11), new Date(2023, 4, 30, 1, 42)],
			['1438285', italian ? 'Valido' : 'Válido', new Date(2023, 4, 26, 20, 51), new Date(2023, 4, 27, 15, 1)],
			['1438286', italian ? 'Valido' : 'Válido', new Date(2023, 4, 25, 1, 42), new Date(2023, 4, 26, 1, 49)],
			['1438287', italian ? 'Valido' : 'Válido', new Date(2023, 4, 19, 12, 16), new Date(2023, 4, 19, 18, 24)],
			['1438288', italian ? 'Valido' : 'Válido', new Date(2023, 4, 19, 18, 25), new Date(2023, 4, 20, 12, 40)],
			['1438289', italian ? 'Valido' : 'Válido', new Date(2023, 4, 30, 1, 42), new Date(2023, 5, 1, 4, 32)]
		],
		LN02: [
			['1438290', italian ? 'Valido' : 'Válido', new Date(2023, 4, 20, 12, 41), new Date(2023, 4, 21, 12, 44)],
			['1438291', italian ? 'Valido' : 'Válido', new Date(2023, 4, 21, 12, 45), new Date(2023, 4, 22, 12, 52)],
			['OP0021', italian ? 'Valido' : 'Válido', new Date(2023, 4, 22, 12, 5), new Date(2023, 4, 22, 19, 19)],
			['OP0022', italian ? 'Valido' : 'Válido', new Date(2023, 4, 22, 19, 20), new Date(2023, 4, 23, 11, 29)],
			['OP0023', italian ? 'Valido' : 'Válido', new Date(2023, 4, 27, 15, 2), new Date(2023, 4, 28, 15, 54)],
			['OP0024', italian ? 'Valido' : 'Válido', new Date(2023, 4, 23, 11, 30), new Date(2023, 4, 23, 15, 54)],
			['OP0025', italian ? 'Valido' : 'Válido', new Date(2023, 4, 23, 15, 55), new Date(2023, 4, 24, 1, 10)],
			['OP0026', italian ? 'Valido' : 'Válido', new Date(2023, 4, 26, 1, 50), new Date(2023, 4, 26, 20, 50)],
			['OP0027', italian ? 'Valido' : 'Válido', new Date(2023, 4, 28, 15, 55), new Date(2023, 4, 29, 1, 10)],
			['OP0028', italian ? 'Valido' : 'Válido', new Date(2023, 4, 18, 12, 0), new Date(2023, 4, 18, 19, 9)],
			['OP0029', italian ? 'Valido' : 'Válido', new Date(2023, 4, 24, 1, 11), new Date(2023, 4, 25, 1, 42)],
			['OP0030', italian ? 'Valido' : 'Válido', new Date(2023, 4, 18, 19, 10), new Date(2023, 4, 19, 12, 15)],
			['OP0031', italian ? 'Valido' : 'Válido', new Date(2023, 4, 29, 1, 11), new Date(2023, 4, 30, 1, 42)],
			['OP0032', italian ? 'Valido' : 'Válido', new Date(2023, 4, 26, 20, 51), new Date(2023, 4, 27, 15, 1)],
			['OP0033', italian ? 'Valido' : 'Válido', new Date(2023, 4, 25, 1, 42), new Date(2023, 4, 26, 1, 49)],
			['OP0034', italian ? 'Valido' : 'Válido', new Date(2023, 4, 19, 12, 16), new Date(2023, 4, 19, 18, 24)],
			['OP0035', italian ? 'Valido' : 'Válido', new Date(2023, 4, 19, 18, 25), new Date(2023, 4, 20, 12, 40)],
			['OP0036', italian ? 'Valido' : 'Válido', new Date(2023, 4, 30, 1, 42), new Date(2023, 5, 1, 4, 32)]
		],
		LN03: [
			['OP0037', italian ? 'Valido' : 'Válido', new Date(2023, 4, 20, 12, 41), new Date(2023, 4, 21, 12, 44)],
			['OP0038', italian ? 'Valido' : 'Válido', new Date(2023, 4, 21, 12, 45), new Date(2023, 4, 22, 12, 52)],
			['OP0039', italian ? 'Valido' : 'Válido', new Date(2023, 4, 22, 12, 5), new Date(2023, 4, 22, 19, 19)],
			['OP0040', italian ? 'Valido' : 'Válido', new Date(2023, 4, 22, 19, 20), new Date(2023, 4, 23, 11, 29)],
			['OP0041', italian ? 'Valido' : 'Válido', new Date(2023, 4, 27, 15, 2), new Date(2023, 4, 28, 15, 54)],
			['OP0042', italian ? 'Valido' : 'Válido', new Date(2023, 4, 23, 11, 30), new Date(2023, 4, 23, 15, 54)],
			['OP0043', italian ? 'Valido' : 'Válido', new Date(2023, 4, 23, 15, 55), new Date(2023, 4, 24, 1, 10)],
			['OP0044', italian ? 'Valido' : 'Válido', new Date(2023, 4, 26, 1, 50), new Date(2023, 4, 26, 20, 50)],
			['OP0045', italian ? 'Valido' : 'Válido', new Date(2023, 4, 28, 15, 55), new Date(2023, 4, 29, 1, 10)],
			['OP0046', italian ? 'Valido' : 'Válido', new Date(2023, 4, 18, 12, 0), new Date(2023, 4, 18, 19, 9)],
			['OP0047', italian ? 'Valido' : 'Válido', new Date(2023, 4, 24, 1, 11), new Date(2023, 4, 25, 1, 42)],
			['OP0048', italian ? 'Valido' : 'Válido', new Date(2023, 4, 18, 19, 10), new Date(2023, 4, 19, 12, 15)],
			['OP0049', italian ? 'Valido' : 'Válido', new Date(2023, 4, 29, 1, 11), new Date(2023, 4, 30, 1, 42)]
		]
	}

	const LINES = ['LN01', 'LN02', 'LN03']

	useEffect(() => {
		let lOPS = aid === 'ynuiFW2O82u5EOKBf1WO' ? OPS_FR : OPS
		const sortedOps = lOPS[line].sort((a, b) => (a[2] > b[2] ? 1 : b[2] > a[2] ? -1 : 0))
		setTasks(sortedOps)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [line])

	// #### FUNCTIONS
	const data = [
		[
			{ type: 'string', id: italian ? 'Ordine' : 'Orden' },
			{ type: 'string', id: italian ? 'Valido' : 'Válido' },
			{ type: 'date', id: 'Start' },
			{ type: 'date', id: 'End' }
		],
		...tasks
	]

	const options = {
		colors: ['#74db9a', '#db7474', '#db7474', '#db7474', '#db7474', '#db7474'],
		timeline: { showBarLabels: false },
		language: italian ? 'it' : 'es'
		// allowHtml: true
	}

	const chartEvents = [
		{
			eventName: 'select',
			callback: ({ chartWrapper }) => {
				const chart = chartWrapper.getChart()
				const selection = chart.getSelection()
				if (selection.length === 1) {
					const [selectedItem] = selection
					// const dataTable = chartWrapper.getDataTable()
					const { row, column } = selectedItem
					// setSelected(row)
					selected.current = row

					// setSelectedRow(row)
				}
			}
		},
		{
			eventName: 'ready',
			callback: ({ chartWrapper }) => {
				setChartWrapper(chartWrapper)
			}
		}
	]

	function moveUp() {
		const index = selected.current
		if (!index || index === 0) return

		const newTasks = [...tasks]
		const prevTask = tasks[index - 1]
		const curTask = tasks[index]

		newTasks[index - 1] = curTask
		newTasks[index] = prevTask
		selected.current = index - 1

		let startDate = prevTask[2]
		for (let i = index - 1; i < newTasks.length; i++) {
			const current = newTasks[i]
			const duration = current[3] - current[2]
			const newStart = startDate
			var newEnd = new Date(newStart)
			newEnd = new Date(newEnd.getTime() + duration)
			newTasks[i][2] = newStart
			newTasks[i][3] = newEnd
			startDate = newEnd

			if (current[0] === 'OP0011') {
				if (i <= 9) newTasks[i][1] = italian ? 'Errore: Materiale 1 mancante' : 'Error: Falta material 1'
				else newTasks[i][1] = italian ? 'Valido' : 'Válido'
			} else if (current[0] === '1438282') {
				if (i <= 9) newTasks[i][1] = italian ? 'Errore: Materiale 1 mancante' : 'Error: Falta material 1'
				else newTasks[i][1] = italian ? 'Valido' : 'Válido'
			}
		}

		setTasks(newTasks)
	}

	function moveDown() {
		const index = selected.current + 1
		if (!index || index > tasks.length - 1) return

		const newTasks = [...tasks]
		const prevTask = tasks[index - 1]
		const curTask = tasks[index]

		newTasks[index - 1] = curTask
		newTasks[index] = prevTask
		selected.current = index

		let startDate = prevTask[2]
		for (let i = index - 1; i < newTasks.length; i++) {
			const current = newTasks[i]
			const duration = current[3] - current[2]
			const newStart = startDate
			var newEnd = new Date(newStart)
			newEnd = new Date(newEnd.getTime() + duration)
			newTasks[i][2] = newStart
			newTasks[i][3] = newEnd
			startDate = newEnd

			if (current[0] === 'OP0011') {
				if (i <= 9) newTasks[i][1] = italian ? 'Errore: Materiale 1 mancante' : 'Error: Falta material 1'
				else newTasks[i][1] = italian ? 'Valido' : 'Válido'
			} else if (current[0] === '1438282') {
				if (i <= 9) newTasks[i][1] = italian ? 'Errore: Materiale 1 mancante' : 'Error: Falta material 1'
				else newTasks[i][1] = italian ? 'Valido' : 'Válido'
			}
		}

		setTasks(newTasks)
	}

	function moveLine() {
		const index = selected.current
		if (!index) return
		const newTasks = [...tasks]
		newTasks.splice(index, 1)

		const prevTask = tasks[index - 1]

		let startDate = prevTask[2]
		for (let i = index - 1; i < newTasks.length; i++) {
			const current = newTasks[i]
			const duration = current[3] - current[2]
			const newStart = startDate
			var newEnd = new Date(newStart)
			newEnd = new Date(newEnd.getTime() + duration)
			newTasks[i][2] = newStart
			newTasks[i][3] = newEnd
			startDate = newEnd

			// if (current[0] === 'OP0011') {
			// 	if (i <= 9) newTasks[i][1] = 'Error: Falta material 1'
			// 	else newTasks[i][1] = 'Válido'
			// }
		}

		setTasks(newTasks)
	}

	return (
		<div className="p-4 flex flex-col">
			<div className="flex flex-row items-center justify-between">
				<div className="flex flex-col">
					<div className="text-xl font-medium text-textGray">{italian ? 'SEQUENZIATORE' : 'SECUENCIADOR'}</div>
					<div className="text-xs text-textGray mt-1">{italian ? 'Aggiornato il 4 luglio 2023 13:33 da Victor Garcia' : 'Actualizado el 15 de abril de 2024 13:33 por Victor Garcia'}</div>
				</div>
				<div className="flex flex-row items-center">
					<Button variant="contained" className="buttonContainedGray w-[35px] min-w-[35px] mr-4" disabled>
						<CalendarIcon className="text-xl" />
					</Button>
					<Button variant="contained" className="buttonContainedPrimary w-[35px] min-w-[35px]">
						<PlayIcon className="text-xl" />
					</Button>
				</div>
			</div>

			<div className="flex flex-row items-center justify-between">
				<TextField
					select
					InputProps={{
						className: 'text-sm'
					}}
					InputLabelProps={{
						className: 'text-sm'
					}}
					variant="outlined"
					className="text-sm my-4"
					size="small"
					value={line}
					onChange={(e) => setLine(e.target.value)}
				>
					<MenuItem key="LN01" value="LN01" className="text-sm">
						LN01
					</MenuItem>
					<MenuItem key="LN02" value="LN02" className="text-sm">
						LN02
					</MenuItem>
					<MenuItem key="LN03" value="LN03" className="text-sm">
						LN03
					</MenuItem>
				</TextField>
				<div className="flex flex-row items-center">
					<Button variant="contained" className="buttonContainedGray w-[35px] min-w-[35px] mr-4" onClick={moveUp}>
						<ExpandLessIcon className="text-xl" />
					</Button>
					<Button variant="contained" className="buttonContainedGray w-[35px] min-w-[35px] mr-4" onClick={moveDown}>
						<ExpandMoreIcon className="text-xl" />
					</Button>
					<Button variant="contained" className="buttonContainedGray w-[35px] min-w-[35px]" onClick={(e) => setMenu(e.currentTarget)}>
						<MoreIcon className="text-xl" />
					</Button>
				</div>
			</div>
			<Chart
				ref={chartRef}
				chartType="Timeline"
				data={data}
				width="100%"
				height="1000px"
				options={options}
				chartEvents={chartEvents}
				chartLanguage={italian ? 'it' : 'es'}
				// getChartEditor={({ chartEditor, chartWrapper, google }) => {
				// 	setChartEditor(chartEditor)
				// 	setChartWrapper(chartWrapper)
				// 	setGoogle(google)
				// }}
			/>

			{/* <Chart chartType="Gantt" width="100%" height="50%" data={data} options={options} /> */}
			<Menu anchorEl={menu} open={Boolean(menu)} onClose={() => setMenu(null)}>
				<MenuItem dense onClick={() => setMenu(null)}>
					{italian ? 'Blocca gli ordini' : 'Bloquear órdenes'}
				</MenuItem>
				<MenuItem dense onClick={() => setMenu(null)}>
					{italian ? 'Sblocca gli ordini' : 'Desbloquear órdenes'}
				</MenuItem>
				{LINES.filter((el) => el != line)?.map((el) => (
					<MenuItem dense onClick={moveLine}>
						{italian ? 'Passare a ' : 'Mover a '}
						{el}
					</MenuItem>
				))}
			</Menu>
		</div>
	)
}
