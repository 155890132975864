import axios from 'axios'

const version = 'v1'
const endpoint = `https://${process.env.REACT_APP_API_ENDPOINT}/${version}`
//const endpoint = `http://${process.env.REACT_APP_API_ENDPOINT}/singularly-app-dev/europe-west1/launchApi/${version}`

const backend = axios.create({
	baseURL: endpoint
})

export { backend, axios, endpoint }
