import colorLib from '@kurkle/color'

export function getThemeColor(theme) {
	const rootStyle = getComputedStyle(document.body)
	const color = rootStyle.getPropertyValue(`--${theme}`)
	return color ? color.trim() : null
}

export function transparentize(color, opacity) {
	if (!color) return
	var alpha = opacity === undefined ? 0.5 : 1 - opacity
	return colorLib(color).alpha(alpha).rgbString()
}
