import { useSelector } from 'react-redux'
import { t } from 'i18next'
import { useEffect, useMemo, useState } from 'react'
import { Avatar, Tab, Tabs } from '@mui/material'
import * as tableDataSel from 'table/store/tableDataSelector'
import { DATA_TYPES } from 'common/constants/dataTypes'
import FileIcon from '@mui/icons-material/InsertDriveFileOutlined'

export default function TableCard({ aid, rowIndex, tabs, variables }) {
	// #### STATE
	const [lastRowIndex, setLastRowIndex] = useState(null)
	const [tab, setTab] = useState(0)

	const METADATA_TYPES = [DATA_TYPES.updatedAt, DATA_TYPES.updatedBy, DATA_TYPES.createdAt, DATA_TYPES.createdBy]
	const TITLE_EXCLUSIONS = [DATA_TYPES.file.key, DATA_TYPES.checkbox.key]
	const isImage = { 'image/jpeg': true, 'image/png': true, 'image/gif': true, 'image/svg': true, 'image/jpg': true }

	// #### REDUX
	const rowData = useSelector((state) => tableDataSel.selectTableRowData(state, aid, lastRowIndex))

	// #### MEMOS Title & avatar data
	const mainColumn = useMemo(() => {
		if (tabs?.length > 0 && tabs[0]?.variables?.length > 0) return tabs[0]?.variables[0]
		else return null
	}, [tabs])

	// Title renderer
	const {
		typeProps: mcTypeProps,
		Cell: McCell,
		cellConfig: mcCellConfig
	} = useMemo(() => {
		const nullValue = { typeProps: null, Cell: null, cellConfig: null }
		if (!mainColumn || !variables || !variables[mainColumn]) return nullValue
		const variable = variables[mainColumn]
		if (TITLE_EXCLUSIONS.includes(variable.type)) return nullValue
		const _typeProps = variable?.typeProps
		const renderer = getCellRenderer(variable.type, _typeProps)
		return renderer
	}, [mainColumn, variables])

	// Avatar data
	const cardAvatar = useMemo(() => {
		if (!rowData || !mainColumn || !variables || !variables[mainColumn]) return null
		const variable = variables[mainColumn]
		const hasCardAvatar = DATA_TYPES[variable.type].cardAvatar
		if (!hasCardAvatar) return null
		const { cellConfig } = getCellRenderer(variable.type, variable.typeProps)
		const varData = rowData[mainColumn]
		const varText =
			variable.type === DATA_TYPES.reference.key && typeof varData === 'object'
				? varData?.value
				: typeof varData !== 'object'
				? cellConfig?.formatBlurredInput({ value: varData, typeProps: variable.typeProps })
				: null
		const cardAvatar = varText ? (varText + ' ')[0] : null
		return cardAvatar
	}, [mainColumn, variables, rowData])

	// Updated at renderer
	const CellUpdatedAt = DATA_TYPES.updatedAt.renderer.component
	const configUpdatedAt = DATA_TYPES.updatedAt.renderer.config
	const typePropsUpdatedAt = useMemo(() => {
		const variable = variables ? variables[DATA_TYPES.updatedAt.metadataId] : null
		return variable?.typeProps || DATA_TYPES.updatedAt.defaultProps
	}, [variables])

	// #### EFFECTS
	// Update last row index
	useEffect(() => {
		if (rowIndex != null) setLastRowIndex(rowIndex)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rowIndex])

	// Keep tab always active
	useEffect(() => {
		if (!tabs || tab > tabs?.length) setTab(0)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tabs])

	// Prepare renderer	for the given type
	function getCellRenderer(type, _typeProps) {
		const typeObj = DATA_TYPES[type]
		const typeProps = _typeProps || typeObj.defaultProps
		const cellRenderer = typeObj?.renderer
		const Cell = cellRenderer?.component
		const defaultCellConfig = cellRenderer?.config
		let cellConfig = defaultCellConfig ? { ...defaultCellConfig } : {}
		cellConfig = { ...cellConfig, alignRight: false, textWrap: true }
		return { typeProps, Cell, cellConfig }
	}

	if (rowData == null) return <div className="p-4 text-sm text-center">{t('table:card.selectRow')}</div>
	else
		return (
			<div className="w-full p-4 flex flex-col">
				<div className="flex flex-row mb-4 items-center">
					<Avatar className="h-[40px] w-[40px] mr-4 bg-contrast" variant="rounded">
						{cardAvatar}
					</Avatar>
					<div className="flex flex-col">
						{McCell && rowData && rowData[mainColumn] ? (
							<McCell
								rowIndex={lastRowIndex}
								colIndex={0}
								item={rowData[mainColumn]}
								setItem={null}
								focus={false}
								cancel={false}
								typeProps={mcTypeProps}
								config={mcCellConfig}
								className="text-xl text-textGray font-medium"
							/>
						) : (
							<span className="text-xl text-textGray font-medium">n/a</span>
						)}

						{rowData[DATA_TYPES.updatedAt.metadataId] && rowData[DATA_TYPES.updatedBy.metadataId] && (
							<span className="text-xs">
								{t('table:card.lastUpdate')}&nbsp;
								<CellUpdatedAt
									rowIndex={lastRowIndex}
									colIndex={0}
									item={rowData[DATA_TYPES.updatedAt.metadataId]}
									setItem={null}
									focus={false}
									cancel={false}
									typeProps={typePropsUpdatedAt}
									config={configUpdatedAt}
									className="text-xs"
								/>
								&nbsp;
								{t('table:card.by')} {rowData[DATA_TYPES.updatedBy.metadataId]}
							</span>
						)}
					</div>
				</div>

				<Tabs value={tab} onChange={(e, value) => setTab(value)} orientation="horizontal" variant="scrollable" scrollButtons="auto" className="tabs shrink-0" classes={{ indicator: 'tabsIndicator' }}>
					{tabs?.map((el) => (
						<Tab key={`card#tab#${el.id}`} label={el.name} className="tab" classes={{ selected: 'tabSelected' }} />
					))}
					<Tab label={t('table:card.metadata')} className="tab" classes={{ selected: 'tabSelected' }} />
				</Tabs>
				{tabs &&
					tab < tabs?.length &&
					tabs[tab]?.variables?.map((vid) => {
						const variable = variables[vid]
						const varData = rowData ? rowData[vid] : null
						const _typeProps = variable?.typeProps
						const isFile = variable?.type === DATA_TYPES.file.key
						const { typeProps, Cell, cellConfig } = getCellRenderer(variable.type, _typeProps)

						if (!varData) return void 0
						return (
							<div key={`card#var#${vid}`}>
								<div className="text-xs mt-4 font-medium text-textGray whitespace-normal">{variable?.label}</div>
								<div className="text-sm mt-1 whitespace-normal break-normal">
									{!isFile && Cell && <Cell rowIndex={lastRowIndex} colIndex={0} item={varData} setItem={null} focus={false} cancel={false} typeProps={typeProps} config={cellConfig} />}
									{isFile && Array.isArray(varData) && (
										<div className="grid gap-[20px] grid-cols-tableCardImages auto-rows-[300px]">
											{varData?.map((file) => (
												<a key={`card#file#${file.id}`} href={file.fullPath} download target="_blank" rel="noreferrer">
													{isImage[file.type] ? (
														<img loading="lazy" src={file.fullPath} className="w-full h-full object-cover rounded cursor-pointer" alt="" />
													) : (
														<div className="w-full h-full bg-bgGray flex flex-col items-center justify-center rounded">
															<FileIcon className="w-[80px] h-[80px] text-textGray" />
															<span className="text-sm">{file.name}</span>
														</div>
													)}
												</a>
											))}
										</div>
									)}
								</div>
							</div>
						)
					})}
				{tab >= tabs?.length &&
					METADATA_TYPES.map((typeObj) => {
						const variable = variables ? variables[typeObj.metadataId] : null
						const type = typeObj.key
						const _typeProps = variable?.type || typeObj.defaultProps
						const varData = rowData ? rowData[typeObj.metadataId] : null
						const { typeProps, Cell, cellConfig } = getCellRenderer(type, _typeProps)

						if (!varData) return void 0
						return (
							<div key={`card#metadata#${typeObj.key}`}>
								<div className="text-xs mt-4 font-medium text-textGray whitespace-normal">{typeObj.label}</div>
								<div className="text-sm mt-1 whitespace-normal break-normal">
									{Cell && <Cell rowIndex={lastRowIndex} colIndex={0} item={varData} setItem={null} focus={false} cancel={false} typeProps={typeProps} config={cellConfig} />}
								</div>
							</div>
						)
					})}
			</div>
		)
}
