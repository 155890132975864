import { useDispatch, useSelector } from 'react-redux'
import { ModelChart } from 'model/screens/charts/ModelChart'
import { t } from 'i18next'
import React, { useCallback, useEffect, useRef } from 'react'
import { useStateRef } from 'common/hooks/grid/useStateRef'
import { moveChart } from 'model/saga-actions/chartActions'
import * as modelSel from 'model/store/modelSelector'

// @TODO make draggable auto-scroll areas larger (so that when you approach the area, the scroll activates)
// https://www.bennadel.com/blog/3460-automatically-scroll-the-window-when-the-user-approaches-the-viewport-edge-in-javascript.htm
// Attach an event listener to "drag" to track the mouse position while dragging
export default function ModelCharts({ aid, isVizReady, isDesigning, filter, sort }) {
	const dispatch = useDispatch()

	// #### REDUX
	const tid = useSelector((state) => state.auth.tid)
	const teamId = useSelector((state) => state.asset.asset[aid]?.data?.teamId)
	const tabIndex = useSelector((state) => modelSel.selectModelTabIndex(state, aid))
	const tabCharts = useSelector((state) => modelSel.selectModelTabCharts(state, aid, tabIndex))
	const variables = useSelector((state) => modelSel.selectModelVars(state, aid))
	const modelProps = useSelector((state) => modelSel.selectModelProps(state, aid))

	// #### STATE
	const dragIndexRef = useRef()
	const dragInitialOrderRef = useRef()
	const [orderedCharts, orderedChartsRef, setOrderedCharts] = useStateRef([])

	useEffect(() => {
		setOrderedCharts(tabCharts)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tabCharts])

	// #### DRAG & DROP
	const onDragStart = useCallback((e, dragIndex) => {
		if (document.activeElement.tagName === 'INPUT') {
			if (e.preventDefault) e.preventDefault()
			if (e.stopPropagation) e.stopPropagation()
			return false
		}
		dragIndexRef.current = dragIndex
		dragInitialOrderRef.current = orderedChartsRef.current
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const onDragEnd = useCallback(
		(e) => {
			dragIndexRef.current = null
			dragInitialOrderRef.current = null
			dispatch(moveChart({ tid, aid, tabIndex, charts: orderedChartsRef.current }))
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[tid, aid, tabIndex]
	)

	const onDragEnter = useCallback((e, dropIndex) => {
		if (e.preventDefault) e.preventDefault()
		if (e.stopPropagation) e.stopPropagation()
		const dragIndex = dragIndexRef.current
		const initialOrder = dragInitialOrderRef.current

		let newOrderedCharts = [...initialOrder]
		const chart = newOrderedCharts[dragIndex]
		newOrderedCharts.splice(dragIndex, 1)
		newOrderedCharts.splice(dropIndex, 0, chart)
		setOrderedCharts(newOrderedCharts)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const onDrop = useCallback((e) => {
		if (e.preventDefault) e.preventDefault()
		if (e.stopPropagation) e.stopPropagation()
	}, [])

	const onDragLeave = useCallback((e) => {
		if (e.preventDefault) e.preventDefault()
		if (e.stopPropagation) e.stopPropagation()
	}, [])

	const onDragOver = useCallback((e) => {
		if (e.preventDefault) e.preventDefault()
		if (e.stopPropagation) e.stopPropagation()
	}, [])

	return (
		<div className="w-full @container">
			{!orderedCharts?.length > 0 && <div className="text-sm text-textGray m-8 text-center">{t('model:chart.empty')}</div>}
			<div className="m-4 grid gap-[32px] grid-cols-1">
				{/* @6xl:grid-cols-2 */}
				{orderedCharts?.map((cid, index) => (
					<div
						key={cid}
						className="bg-white relative"
						draggable={isDesigning}
						onDragStart={isDesigning ? (e) => onDragStart(e, index) : undefined}
						onDragEnd={isDesigning ? onDragEnd : undefined}
						onDragEnter={isDesigning ? (e) => onDragEnter(e, index) : undefined}
						onDrop={isDesigning ? onDrop : undefined}
						onDragLeave={isDesigning ? onDragLeave : undefined}
						onDragOver={isDesigning ? onDragOver : undefined}
					>
						<ModelChart tid={tid} teamId={teamId} aid={aid} cid={cid} isVizReady={isVizReady} isDesigning={isDesigning} variables={variables} modelProps={modelProps} filter={filter} sort={sort} />
					</div>
				))}
			</div>
		</div>
	)
}
