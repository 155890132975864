import CreateTextCell from 'common/components/grid/cells/CreateTextCell'
import { cellNum } from 'common/components/grid/cells/CellNum'
import { DATA_TYPES } from 'common/constants/dataTypes'
import { printNumWithProps } from 'common/utils/numbers'

export const cellPercent = {
	component: CreateTextCell,
	config: {
		alignRight: true,
		formatBlurredInput: ({ value, typeProps }) => {
			return printNumWithProps(value, DATA_TYPES.percent.key, typeProps)
		},
		formatCopyValue: ({ value, typeProps }) => {
			return printNumWithProps(value, DATA_TYPES.percent.key, typeProps)
		},
		parseUserInput: ({ value, typeProps }) => {
			const hasSymbol = value.indexOf('%') >= 0
			const numStr = value?.replace('%', '')
			return cellNum.config.parsePastedValue({ value: numStr, typeProps, isPercent: hasSymbol })
		},
		parsePastedValue: ({ value, typeProps, localeFallback }) => {
			const hasSymbol = value.indexOf('%') >= 0
			const numStr = value?.replace('%', '')
			return cellNum.config.parsePastedValue({ value: numStr, typeProps, isPercent: hasSymbol })
		}
	}
}
