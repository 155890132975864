import { select, put, call, takeEvery, spawn } from 'redux-saga/effects'
import * as api from 'common/api/userApi'
import * as authApi from 'common/api/authApi'
import * as storeAction from 'common/store/userReducer'
import * as sagaAction from 'common/saga-actions/userActions'
import * as subscribeHelper from 'common/saga/helpers/subscribeHelper'
import * as globalStoreAction from 'common/store/globalReducer'
import * as globalSagaAction from 'common/saga-actions/globalActions'
import { throwError } from 'common/config/errors'

const FILE_NAME = 'userSagas'

const getTid = (state) => state.auth.tid
const getUid = (state) => state.auth.uid

// #### PATH TO THE COLLECTION
function defaultPath(includeDocId) {
	const path = [
		{ collection: 'tenants', param: 'tid', value: null },
		{ collection: 'users', param: includeDocId ? 'uid' : null, value: null }
	]
	return path
}

// #### SUBSCRIBE TO USER
function* loadUserReq() {
	const queryConfig = subscribeHelper.queryConfig({ path: defaultPath(true), returnType: 'doc' })
	const sagaConfig = subscribeHelper.sagaConfig({ loadAction: sagaAction.LOAD_USER, loadResponse: storeAction.loadUser, cancelAction: sagaAction.CANCEL_USER, cancelResponse: storeAction.cancelUser })
	yield spawn(subscribeHelper.subscribe, sagaConfig, queryConfig)
}

// #### USER MANAGEMENT
function* updateUserReq() {
	yield takeEvery(sagaAction.UPDATE_USER, updateUser)
}

function* updateUser(triggeredAction) {
	const { tid, uid, content } = triggeredAction
	try {
		yield put(globalStoreAction.loadingOn({ key: 'updateUser' }))
		const { token } = yield call(authApi.getAuthToken)
		yield call(api.updateUser, token, tid, uid, content)
	} catch (err) {
		const content = throwError(err, FILE_NAME, updateUser.name)
		yield put(globalSagaAction.showMessage({ content }))
	} finally {
		yield put(globalStoreAction.loadingOff({ key: 'updateUser' }))
	}
}

function* deleteUserReq() {
	yield takeEvery(sagaAction.DELETE_USER, deleteUser)
}

function* deleteUser(triggeredAction) {
	try {
		const tid = yield select(getTid)
		const uid = yield select(getUid)
		yield put(globalStoreAction.loadingOn({ key: 'deleteUser' }))
		const { token } = yield call(authApi.getAuthToken)
		yield call(api.deleteUser, token, tid, uid)
	} catch (err) {
		const content = throwError(err, FILE_NAME, deleteUser.name)
		yield put(globalSagaAction.showMessage({ content }))
	} finally {
		yield put(globalStoreAction.loadingOff({ key: 'deleteUser' }))
	}
}

export default function* root() {
	// #### SUBSCRIBE TO USER
	yield spawn(loadUserReq)
	// #### USER MANAGEMENT
	yield spawn(updateUserReq)
	yield spawn(deleteUserReq)
}
