import { Button, RadioGroup, FormControlLabel, Radio, Tooltip, Accordion, AccordionSummary, AccordionDetails, Collapse, Switch, TextField, Checkbox, Menu, MenuItem } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import TableTypeRefTable from 'table/screens/type/TableTypeRefTable'
import TableTypeRefVariable from 'table/screens/type/TableTypeRefVariable'
import { DATA_TYPES } from 'common/constants/dataTypes'
import { validateNotEmpty, validateInteger } from 'common/utils/validate'
import { cancelSelectedTable } from 'table/store/tableReducer'
import { loadSelectedTable } from 'table/saga-actions/tableActions'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AssetFilters from '../../common/components/search/AssetFilters'
import { useStateRef } from 'common/hooks/grid/useStateRef'
import { cloneDeep } from 'lodash'
import { clearVariableBreakdown } from 'model/store/modelDataReducer'
import { connectTable, disconnectTable } from 'model/saga-actions/engineMiddlewareActions'

export default function ModelConnectTable({ aid, position, onClose, varId, variable }) {
	const dispatch = useDispatch()
	const { t } = useTranslation(['common', 'model'])
	const loadKey = `connectTable#${aid}`

	// #### REDUX
	const tid = useSelector((state) => state.auth.tid)
	const teamId = useSelector((state) => state.asset.asset[aid]?.data?.teamId)
	const sourceProps = variable?.sourceProps
	const breakdown = useSelector((state) => state.modelData.breakdown[aid])
	const isVarsLoaded = useSelector((state) => state.table.selected[loadKey]?.loaded) || false
	const variables = useSelector((state) => state.table.selected[loadKey]?.data?.variables)

	// #### DEFAULT VALUES
	const defaultForm = {
		selectTable: '',
		selectVariable: '',
		type: 'table',
		dateType: 'single',
		date: '',
		endDate: '',
		filters: [],
		advanced: {
			fillMissing: false,
			fillStrategy: 'value',
			fillValue: 0,
			filterByDate: 'all'
		},
		excludeStart: false,
		excludeEnd: false
	}

	const defaultValidation = {
		selectTable: { valid: true, error: '' },
		selectVariable: { valid: true, error: '' },
		dateType: { valid: true, error: '' },
		date: { valid: true, error: '' },
		endDate: { valid: true, error: '' },
		fillValue: { valid: true, error: '' }
	}

	// #### STATE
	const [openPanel, setOpenPanel] = useState('connection')
	const [form, formRef, setForm] = useStateRef(defaultForm)
	const [validation, setValidation] = useState(defaultValidation)

	// #### EFFECTS
	useEffect(() => {
		const lKey = loadKey
		if (form.selectTable) dispatch(loadSelectedTable({ key: lKey, tid, aid: form.selectTable }))
		return () => dispatch(cancelSelectedTable({ key: lKey }))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form.selectTable])

	useEffect(() => {
		setForm({
			selectTable: sourceProps?.selectTable || defaultForm.selectTable,
			selectVariable: sourceProps?.selectVariable || defaultForm.selectVariable,
			type: 'table',
			dateType: sourceProps?.dateType || defaultForm.dateType,
			date: sourceProps?.date || defaultForm.date,
			endDate: sourceProps?.endDate || defaultForm.endDate,
			filters: sourceProps?.filters || defaultForm.filters,
			advanced: {
				fillMissing: sourceProps?.advanced?.fillMissing || defaultForm.advanced.fillMissing,
				fillStrategy: sourceProps?.advanced?.fillStrategy || defaultForm.advanced.fillStrategy,
				fillValue: sourceProps?.advanced?.fillValue || defaultForm.advanced.fillValue,
				filterByDate: sourceProps?.advanced?.filterByDate || defaultForm.advanced.filterByDate
			},
			excludeStart: sourceProps?.excludeStart || defaultForm.excludeStart,
			excludeEnd: sourceProps?.excludeEnd || defaultForm.excludeEnd
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceProps])

	// #### FUNCTIONS
	function onChange(key, value) {
		if (form[key] === value) return
		let relatedChanges = {}
		if (key === 'table') relatedChanges = { selectVariable: '', date: '', endDate: '', filters: [] }
		if (key === 'dateType' && value === 'single') relatedChanges = { endDate: '' }
		else if (key === 'dateType' && value === 'none') relatedChanges = { date: '', endDate: '' }
		setForm({ ...formRef.current, [key]: value, ...relatedChanges })
	}

	function onChangeAdvanced(key, value) {
		if (form.advanced[key] === value) return
		var newForm = cloneDeep(form)
		newForm['advanced'][key] = value
		setForm(newForm)
	}

	function onChangeExclude(key, event) {
		var newForm = cloneDeep(form)
		newForm[key] = event.target.checked
		setForm(newForm)
	}

	function onConnect() {
		let tableValid = validateNotEmpty(form.selectTable)
		let variableValid = validateNotEmpty(form.selectVariable)
		let dateTypeValid = validateNotEmpty(form.dateType)
		let dateValid = form.dateType === 'single' || form.dateType === 'range' ? validateNotEmpty(form.date) : { valid: true, error: '' }
		let endDateValid = form.dateType === 'range' ? validateNotEmpty(form.endDate) : { valid: true, error: '' }
		let fillValueValid = form.advanced.fillMissing ? validateInteger(form.advanced.fillValue + '') : { valid: true, error: '' }
		setValidation({ ...validation, selectTable: tableValid, selectVariable: variableValid, dateType: dateTypeValid, date: dateValid, endDate: endDateValid, fillValue: fillValueValid })
		if (tableValid.valid && variableValid.valid && dateTypeValid.valid && dateValid.valid && endDateValid.valid && fillValueValid.valid) {
			// If table changes, category breakdown must be reset
			const hasTableChanged = !(sourceProps?.selectTable === form.selectTable)
			if (breakdown && varId in breakdown && hasTableChanged) dispatch(clearVariableBreakdown({ id: aid, variableId: varId }))
			const catProps = hasTableChanged ? { categories: null } : {}
			const isStatic = form.dateType === 'none'
			const content = { id: varId, sourceProps: { ...form }, ...catProps, varProps: {isStatic}, expressions: null }
			dispatch(connectTable({ tid, teamId, aid, id: varId, content }))
			onClose()
		}
	}

	function onDisconnect() {
		dispatch(disconnectTable({ tid, teamId, aid, id: varId, tableId: form.selectTable }))
		if (breakdown && varId in breakdown) dispatch(clearVariableBreakdown({ id: aid, variableId: varId }))
		onClose()
	}

	function togglePanel(key) {
		if (openPanel === key) setOpenPanel(null)
		else setOpenPanel(key)
	}

	return (
		<>
			<div className="flex-1 px-4 text-sm mt-4">
				<Accordion expanded={openPanel === 'connection'} onChange={() => togglePanel('connection')}>
					<AccordionSummary className="text-sm m-0 min-h-0" classes={{ content: 'my-2 flex flex-col' }} expandIcon={<ExpandMoreIcon fontSize="small" />}>
						{t('model:connectData.connection')}
						<div className="text-xs text-textGray">{t('model:connectData.connectionDesc', {entity: 'Table'})}</div>
					</AccordionSummary>
					<AccordionDetails>
						<TableTypeRefTable
							loadKey={loadKey}
							teamId={teamId}
							onChange={(value) => onChange('selectTable', value)}
							value={form.selectTable}
							validation={validation.selectTable}
							fieldName={t('model:connectData.table')}
							showLabel
						/>
						<Collapse in={Boolean(form.selectTable)}>
							<div className="mt-4" />
							<TableTypeRefVariable
								aid={aid}
								variables={variables}
								types={[DATA_TYPES.num.key, DATA_TYPES.percent.key, DATA_TYPES.currency.key]}
								onChange={(value) => onChange('selectVariable', value)}
								value={form.selectVariable}
								validation={validation.selectVariable}
								fieldName={t('model:connectData.field')}
								showLabel
							/>
							<div className="mt-4 mb-2 text-xs font-medium text-textGray">{t('model:connectData.timestepAssign')}</div>
							<RadioGroup value={form.dateType} onChange={(e) => onChange('dateType', e.target.value)}>
								<Tooltip title={t('model:connectData.dateSingleInfo')} placement="right">
									<FormControlLabel
										value="single"
										control={<Radio size="small" classes={{ root: 'py-1' }} />}
										label={t('model:connectData.dateSingle')}
										classes={{ label: 'text-sm' }}
									/>
								</Tooltip>
								<Tooltip title={t('model:connectData.dateRangeInfo')} placement="right">
									<FormControlLabel value="range" control={<Radio size="small" classes={{ root: 'py-1' }} />} label={t('model:connectData.dateRange')} classes={{ label: 'text-sm' }} />
								</Tooltip>
								<Tooltip title={t('model:connectData.dateNoneInfo')} placement="right">
									<FormControlLabel value="none" control={<Radio size="small" classes={{ root: 'py-1' }} />} label={t('model:connectData.dateNone')} classes={{ label: 'text-sm' }} />
								</Tooltip>
							</RadioGroup>
							{(form.dateType === 'single' || form.dateType === 'range') && (
								<>
									<div className="mt-4" />
									<TableTypeRefVariable
										aid={aid}
										variables={variables}
										varId={varId}
										variable={variable}
										isModelVersion={true}
										types={[DATA_TYPES.date.key]}
										onChange={(value) => onChange('date', value)}
										value={form.date}
										validation={validation.date}
										fieldName={form.dateType === 'range' ? t('model:connectData.startDate') : t('model:connectData.date')}
										showLabel
									/>
									{form.dateType === 'range' && (
										<FormControlLabel control={<Checkbox checked={form.excludeStart} onChange={(event) => onChangeExclude('excludeStart', event)} size='small' />} label={t('model:connectData.excludeStart')} classes={{ label: 'text-sm' }}/>
									)}
								</>
							)}
							{form.dateType === 'range' && (
								<>
									<div className="mt-4" />
									<TableTypeRefVariable
										aid={aid}
										variables={variables}
										varId={varId}
										variable={variable}
										isModelVersion={true}
										types={[DATA_TYPES.date.key]}
										onChange={(value) => onChange('endDate', value)}
										value={form.endDate}
										validation={validation.endDate}
										fieldName={t('model:connectData.endDate')}
										showLabel
									/>
									<FormControlLabel control={<Checkbox checked={form.excludeEnd} onChange={(event) => onChangeExclude('excludeEnd', event)} size='small'/>} label={t('model:connectData.excludeEnd')} classes={{ label: 'text-sm' }}/>
								</>
							)}
						</Collapse>
					</AccordionDetails>
				</Accordion>
				<Accordion expanded={openPanel === 'filters'} onChange={() => togglePanel('filters')}>
					<AccordionSummary className="text-sm m-0 min-h-0" classes={{ content: 'my-2 flex flex-col' }} expandIcon={<ExpandMoreIcon fontSize="small" />}>
						{t('model:connectData.optionalFilters')}
						<div className="text-xs text-textGray">{t('model:connectData.filtersDesc')}</div>
					</AccordionSummary>
					<AccordionDetails>
						<AssetFilters aid={aid} variables={variables} isLoaded={isVarsLoaded} loadKey={loadKey} filters={form?.filters} onChange={(filters) => onChange('filters', filters)} />
					</AccordionDetails>
				</Accordion>
				<Accordion expanded={openPanel === 'advanced'} onChange={() => togglePanel('advanced')}>
					<AccordionSummary className="text-sm m-0 min-h-0" classes={{ content: 'my-2 flex flex-col' }} expandIcon={<ExpandMoreIcon fontSize="small" />}>
						{t('model:connectData.optionalSettings')}
						<div className="text-xs text-textGray">{t('model:connectData.settingsDesc')}</div>
					</AccordionSummary>
					<AccordionDetails>
						<FormControlLabel
							control={<Switch checked={form.advanced.fillMissing} onChange={(e) => onChangeAdvanced('fillMissing', e.target.checked)} color="primary" size="small" />}
							label={t('model:connectData.fillMissing')}
							classes={{ root: '', label: 'ml-2 text-sm font-medium text-textGray' }}
						/>
						{form.advanced.fillMissing && (
							<TextField
								type="number"
								fullWidth
								label={t('model:connectData.fillValue')}
								variant="outlined"
								margin="dense"
								className="mt-4"
								size="small"
								value={form.advanced.fillValue}
								onChange={(event) => onChangeAdvanced('fillValue', event.target.value)}
								inputProps={{ step: 1, className: 'text-sm' }}
							/>
						)}
						{form.dateType === 'single' && (
							<TextField
								id="table"
								select
								label={t("model:connectData.filterByDate")}
								variant="outlined"
								className='text-sm mt-4'
								InputProps={{
									className: "text-sm"
								}}
								InputLabelProps={{
									className: "text-sm"
								}}
								size="small"
								fullWidth
								value={form.advanced.filterByDate}
								onChange={(event) => onChangeAdvanced('filterByDate', event.target.value)}
							>
								<MenuItem key="all" value='all'>
									{t("model:connectData.allInPeriod")}
								</MenuItem>
								<MenuItem key="starting_day" value='starting_day'>
									{t("model:connectData.startPeriod")}
								</MenuItem>
							</TextField>
						)}
					</AccordionDetails>
				</Accordion>
			</div>

			<div className="px-4 mt-4 flex flex-row items-center">
				{sourceProps && sourceProps?.type === 'table' && (
					<Button onClick={onDisconnect} size="small" className="buttonOutlinedGray mr-2 text-black flex-1" variant="outlined">
						{t('model:buttons.disconnect')}
					</Button>
				)}
				<Button onClick={onConnect} size="small" className="buttonContainedContrast flex-1">
					{sourceProps && sourceProps?.type === 'table' ? t('common:buttons.update') : t('model:buttons.connect')}
				</Button>
			</div>
		</>
	)
}
