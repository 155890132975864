import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { CircularProgress, MenuItem, TextField, IconButton, Menu, Avatar } from '@mui/material'
import useFormatter from 'common/hooks/useFormatter'
import { sortArrayOfObjects, filterArrayOfObjects } from 'common/utils/arrays'
import MoreIcon from '@mui/icons-material/MoreVert'
import ActionConfirm from 'common/components/ActionConfirm'
import { FILES } from 'automation/constants/datasources'
import { deleteFile, searchFiles, loadFilesChanges, cancelFilesChanges } from 'automation/saga-actions/fileActions'
import useLazyLoading from 'common/hooks/useLazyLoading'
import { cancelSearchFiles } from 'automation/store/fileReducer'
import { useTeamPermits } from 'common/hooks/useTeamPermits'

export default function FileUploads() {
	const { t } = useTranslation('common')
	const dispatch = useDispatch()
	const { printTimestamp } = useFormatter()
	let { teamId } = useParams()
	const { canAdmin, canCreate } = useTeamPermits()

	// #### REDUX
	const tid = useSelector((state) => state.auth.tid)
	const teamUsers = useSelector((state) => state.team.teamUsers?.result)
	const isDeleting = useSelector((state) => state.global.loading?.deleteFile)
	const search = useSelector((state) => state.file.search)
	const assets = useSelector((state) => state.asset.assets?.result)

	// #### STATE
	const [filter, setFilter] = useState('all')
	const [sort, setSort] = useState({ select: 'updatedDesc', by: 'updatedAt', order: 'desc' })
	const [fileMenu, setFileMenu] = useState({ anchor: null, fid: null })
	const [isDeleteOpen, setIsDeleteOpen] = useState(null)

	// #### LAZY LOADING
	const triggerFetch = useLazyLoading(() => dispatch(searchFiles({ tid, teamId, startAfter: search?.startAfter, orderBy: [{ attribute: sort.by, order: sort.order }] })), search?.isLoading, search?.notFound)

	// #### EFFECTS
	// Load files
	useEffect(() => {
		dispatch(searchFiles({ tid, teamId, orderBy: [{ attribute: sort.by, order: sort.order }] }))
		return () => {
			dispatch(cancelSearchFiles())
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sort])

	// Load files changes
	useEffect(() => {
		dispatch(loadFilesChanges({ tid, teamId }))
		return () => {
			dispatch(cancelFilesChanges())
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// #### FUNCTIONS
	function onSort(value) {
		if (value === 'updatedAsc') setSort({ select: value, by: 'updatedAt', order: 'asc' })
		else if (value === 'updatedDesc') setSort({ select: value, by: 'updatedAt', order: 'desc' })
	}

	function onOpenFileMenu(e, fid) {
		if (e.preventDefault) e.preventDefault()
		if (e.stopPropagation) e.stopPropagation()
		setFileMenu({ anchor: e.currentTarget, fid })
	}

	function onDeleteOpen() {
		setIsDeleteOpen(fileMenu.fid)
		setFileMenu(null)
	}

	function onDeleteFile() {
		dispatch(deleteFile({ tid, teamId, fid: isDeleteOpen }))
		setIsDeleteOpen(null)
	}

	return (
		<>
			{/* Filter and sort assets */}
			<div className="m-4 flex flex-row items-center justify-between">
				<div className="flex flex-row items-center">
					<span className="text-sm text-textGray font-light">{t('common:assets.filter')}:&nbsp;&nbsp;</span>
					<TextField size="small" select variant="standard" value={filter} InputProps={{ disableUnderline: true, classes: { input: 'p-0 text-sm' } }} onChange={(e) => setFilter(e.target.value)}>
						<MenuItem value="all" className="text-sm">
							{t('common:assets.all')}
						</MenuItem>
						{Object.values(FILES).map((file) => (
							<MenuItem key={`filter#${file.key}`} value={file.key} className="text-sm">
								{t(file.name)}
							</MenuItem>
						))}
					</TextField>
				</div>
				<div className="flex flex-row items-center">
					<span className="text-sm text-textGray font-light">{t('common:assets.sort')}:&nbsp;&nbsp;</span>
					<TextField size="small" select variant="standard" value={sort.select} InputProps={{ disableUnderline: true, classes: { input: 'p-0 text-sm' } }} onChange={(e) => onSort(e.target.value)}>
						<MenuItem value="updatedAsc" className="text-sm">
							{t('common:sort.updatedAsc')}
						</MenuItem>
						<MenuItem value="updatedDesc" className="text-sm">
							{t('common:sort.updatedDesc')}
						</MenuItem>
					</TextField>
				</div>
			</div>
			{/* List files */}
			<div className="m-4 mt-4 grid gap-[32px] grid-cols-smallCards">
				{!search?.loaded &&
					[...Array(8)].map((el, i) => (
						<div key={`skeletton#${i}`} className="paper animate-pulse col-span-full p-4">
							<Avatar className="h-[30px] w-[30px] mr-4 bg-bgGray" variant="rounded">
								&nbsp;
							</Avatar>
						</div>
					))}
				{search?.result &&
					sortArrayOfObjects(
						filterArrayOfObjects(search.result, filter === 'all' ? '' : filter, (file) => file.data.type || ''),
						(file) => file.data[sort.by] || '',
						sort.order === 'asc' ? true : false,
						false
					).map((file, index) => {
						const fileDef = file.data.type ? FILES[file.data.type] : null
						const records = file.data?.records
						const status = file.data?.status
						const updated = file.data.updatedAt ? printTimestamp(file.data.updatedAt) : null
						const createdBy = file.data.createdBy && teamUsers && teamUsers[file.data.createdBy]
						const createdByStr = createdBy ? `${createdBy.name} ${createdBy.surname}` : null
						const isFileDeleting = (isDeleting && isDeleting[file.id]) || false
						const assetName = assets && file.data.destinationProps?.selectTable && assets[file.data.destinationProps?.selectTable]?.name

						return (
							<div key={file.id} className="col-span-full paper cursor-pointer hover:bg-bgGray p-4 flex flex-row items-center justify-between" /* onClick={() => onNavigate(file.id)} */>
								<div className="flex flex-row items-center flex-1">
									<span ref={index + 1 === search.result.length ? triggerFetch : undefined} />
									{React.createElement(fileDef.icon, { width: '30px', height: '30px', className: 'text-[20px] mr-4' })}
									<div>
										<div className="text-sm font-medium truncate">{file.data.name}</div>
										<div className="text-xs text-textGray leading-none truncate">
											{fileDef.manufacturer && fileDef.manufacturer + ' '}
											{t(fileDef.name)}
											&nbsp;&gt;&nbsp;
											{assetName} | {records && t('common:assets.records', { records: records })} | {status}
										</div>
									</div>
								</div>
								<div className="flex">
									<div className="flex-1">
										<div className="text-xs truncate">{updated && t('common:assets.updated', { date: updated })}</div>
										<div className="text-xs text-textGray truncate">{createdByStr && t('common:assets.by', { person: createdByStr })}</div>
									</div>
								</div>
								{isFileDeleting ? (
									<CircularProgress size={20} className="text-textGray mr-2" />
								) : (
									(canAdmin || canCreate) && (
										<IconButton size="small" onClick={(e) => onOpenFileMenu(e, file.id)}>
											<MoreIcon className="text-xl" />
										</IconButton>
									)
								)}
							</div>
						)
					})}
			</div>
			<Menu anchorEl={fileMenu?.anchor} keepMounted open={Boolean(fileMenu?.anchor)} onClose={() => setFileMenu(null)}>
				<MenuItem dense onClick={onDeleteOpen}>
					{t('common:buttons.delete')}
				</MenuItem>
			</Menu>
			<ActionConfirm open={Boolean(isDeleteOpen)} content={t('common:messages.deleteConfirm')} onClose={() => setIsDeleteOpen(null)} onConfirm={onDeleteFile} />
		</>
	)
}
