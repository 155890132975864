import { useDispatch, useSelector } from 'react-redux'
import { t } from 'i18next'
import { Dialog, DialogTitle, DialogActions, DialogContent, TextField, IconButton, Button, InputAdornment } from '@mui/material'
import { useState, useEffect } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { validateNotEmpty } from 'common/utils/validate'
import { createTeam, deleteApiKey, generateApiKey, updateTeam } from 'common/saga-actions/teamActions'
import { CopyAllRounded} from '@mui/icons-material'

export default function NewTeam({ tid, teamId, team, isOpen, onClose: onCloseExternal }) {
	const dispatch = useDispatch()

	// #### STORE
	const isCreating = useSelector((state) => state.global.loading?.newTeam)
	const _isUpdating = useSelector((state) => state.global.loading?.updateTeam)
	const isUpdating = teamId && _isUpdating && _isUpdating[teamId]
	const isGenerating = useSelector((state) => state.global.loading?.newApiKey)
	const isDeleting = useSelector((state) => state.global.loading?.deleteApiKey)

	// #### DEFAULT VALUES
	const defaultForm = {
		name: '',
		apiKey: '',
		apiId: ''
	}

	const defaultValidation = {
		name: { valid: true, error: '' }
	}

	// #### STATE
	const [form, setForm] = useState(defaultForm)
	const [validation, setValidation] = useState(defaultValidation)
	const [prevStatus, setPrevStatus] = useState({ isCreating: false, isUpdating: false })

	// #### EFFECTS
	useEffect(() => {
		if ((!isCreating && prevStatus.isCreating) || (!isUpdating && prevStatus.isUpdating)) onClose()
		setPrevStatus({ isCreating, isUpdating })
	}, [isCreating, isUpdating])

	useEffect(() => {
		if (teamId && isOpen) 
			setForm({ 
				name: team.name || defaultForm.name,
				apiKey: team.apiKey,
				apiId: team.apiId
			})
		else if (isOpen) resetData()
	}, [isOpen])

	useEffect(() => {
		if (teamId && !isCreating && !isDeleting) setForm({ name: team.name, apiKey: team.apiKey, apiId: team.apiId })
	}, [team, isCreating, isDeleting])

	// #### FUNCTIONS
	function resetData() {
		console.log('reset')
		setForm(defaultForm)
		setValidation(defaultValidation)
	}
	function onClose() {
		onCloseExternal()
	}

	function onChange(key, value) {
		setForm({ ...form, [key]: value })
	}

	function onGenerate() {
		dispatch(generateApiKey({ tid, teamId }))
	}

	function onDelete() {
		dispatch(deleteApiKey({ tid, teamId, apiId: form.apiId }))
	}

	function onSave() {
		let nameValid = validateNotEmpty(form.name)
		setValidation({ ...validation, name: nameValid })
		if (nameValid.valid) {
			if (teamId) dispatch(updateTeam({ teamId, content: form }))
			else dispatch(createTeam({ name: form.name }))
		}
	}

	return (
		<Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth={true}>
			<DialogTitle className="">
				<span className="text-lg font-bold">{teamId ? t('common:team.settings') : t('common:team.create')}</span>
				<IconButton size="small" className="absolute top-4 right-4 text-textGray" onClick={onClose}>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent className="">
				<div className="text-xs text-textGray mb-6">{t('common:team.createInfo')}</div>
				<TextField
					fullWidth
					variant="outlined"
					size="small"
					value={form.name}
					label={t('common:team.name')}
					onChange={(event) => onChange('name', event.target.value)}
					error={!validation.name.valid}
					inputProps={{ className: 'text-sm' }}
					InputLabelProps={{ className: 'text-sm' }}
				/>
				<TextField
					fullWidth
					className='mt-4'
					variant="outlined"
					disabled
					size="small"
					value={form.apiKey}
					label={t('common:team.apiKey')}
					InputProps={{
						endAdornment: 
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									edge="end"
									onClick={() => {navigator.clipboard.writeText(form.apiKey)}}
								>
									<CopyAllRounded />
								</IconButton>
							</InputAdornment>
					}}
					InputLabelProps={{ className: 'text-sm' }}
				/>
				<div className="text-right">
					{form.apiKey ? (
						<LoadingButton size="small" onClick={onDelete} loading={isDeleting}>
							{t('common:team.deleteApiKey')}
						</LoadingButton>
					) : (
						<LoadingButton size="small" onClick={onGenerate} loading={isGenerating}>
							{t('common:team.generate')}
						</LoadingButton>
					)}
				</div>
			</DialogContent>
			<DialogActions className="p-4">
				<Button variant="outlined" className="buttonOutlinedGray" size="small" onClick={onClose} disabled={isCreating || isUpdating}>
					{t('common:buttons.cancel')}
				</Button>
				<LoadingButton variant="contained" className="buttonContainedPrimary" size="small" onClick={onSave} loading={isCreating || isUpdating}>
					{t('common:buttons.save')}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	)
}
