import React, { useState, useEffect } from 'react'
import { Popover } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CopyBlock, tomorrow } from "react-code-blocks";

export default function ModelConnectApi({ teamId, aid, position, onClose, varId, variable }) {
	const { t } = useTranslation(['common', 'model'])
	const sourceProps = variable?.sourceProps

	// #### STATE
	const [tab, setTab] = useState(0)
	useEffect(() => {
		setTab(sourceProps?.type === 'variable' ? 1 : 0)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceProps])

	// #### POPOVER CONTROL
	const isPopoverOpen = Boolean(position)
	const code = `
import requests
url = "https://api.singularly.com/v1"

team_id = "${teamId}"
model_id = "${aid}"
row_id = "${varId}"

params = { "page": 0, "page_size": 100}
headers = { "x-api-key": {API_KEY} #Replace the api_key }

response = requests.get(
	url=f"{url}/teams/{team_id}/models/{model_id}/rows/{row_id}", 
	headers=headers, 
	params=params
)
`
	return (
		<Popover
			open={isPopoverOpen}
			anchorReference="anchorPosition"
			anchorPosition={position ? { top: position.y, left: position.x } : null}
			onClose={onClose}
			anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
			transformOrigin={{ vertical: 'top', horizontal: 'left' }}
			classes={{ paper: 'flex overflow-y-hidden' }}
		>
			<CopyBlock
				text={code}
				language={'python'}
				theme={tomorrow}
				showLineNumbers={true}
				codeBlock={false}
			/>
		</Popover>
	)
}
