export function sortArrayOfObjects(array, getItemLabel, isAsc = true, isString = true) {
	if (isString) {
		if (isAsc) return array.sort((a, b) => (getItemLabel(a).toLowerCase() > getItemLabel(b).toLowerCase() ? 1 : getItemLabel(b).toLowerCase() > getItemLabel(a).toLowerCase() ? -1 : 0))
		else return array.sort((a, b) => (getItemLabel(a).toLowerCase() < getItemLabel(b).toLowerCase() ? 1 : getItemLabel(b).toLowerCase() < getItemLabel(a).toLowerCase() ? -1 : 0))
	} else {
		if (isAsc) return array.sort((a, b) => (getItemLabel(a) > getItemLabel(b) ? 1 : getItemLabel(b) > getItemLabel(a) ? -1 : 0))
		else return array.sort((a, b) => (getItemLabel(a) < getItemLabel(b) ? 1 : getItemLabel(b) < getItemLabel(a) ? -1 : 0))
	}
}

export function filterArrayOfObjects(array, filterValue, getItemLabel) {
	return array.filter((item) => getItemLabel(item).toLowerCase().includes(filterValue.toLowerCase()))
}
