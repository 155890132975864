export default function WindowSplitBottomIcon({ ...props }) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill="currentColor" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M50 10C50 4.47715 45.5228 0 40 0L10 0C4.47715 0 0 4.47715 0 10V40C0 45.5228 4.47715 50 10 50H40C45.5228 50 50 45.5228 50 40V10ZM45 10C45 7.23858 42.7614 5 40 5L10 5C7.23857 5 5 7.23858 5 10V40C5 42.7614 7.23857 45 10 45H40C42.7614 45 45 42.7614 45 40V10Z"
			/>
			<path fillRule="evenodd" clipRule="evenodd" d="M40 37V25L10 25V37C10 38.6569 11.3431 40 13 40H37C38.6569 40 40 38.6569 40 37Z" />
		</svg>
	)
}