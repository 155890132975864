import { put, spawn, takeEvery } from 'redux-saga/effects'
import * as sagaAction from 'common/saga-actions/globalActions'
import * as storeAction from 'common/store/globalReducer'
import { error } from 'common/config/errors'


const delay = (ms) => new Promise((res) => setTimeout(res, ms))

export function* showMessageReq() {
	yield takeEvery(sagaAction.SHOW_MESSAGE, showMessage)
}

export function* showMessage(triggeredAction) {
	const { key, item, content, messageType, isPersistent } = triggeredAction
	try {
		yield put(storeAction.showMessage({ key, item, content, messageType }))
		if (!isPersistent) {
			yield delay(6000)
			yield put(storeAction.closeMessage({ key, item }))
		}
	} catch (err) {
		error(err)
	}
}

export function* downloadFileReq() {
	yield takeEvery(sagaAction.DOWNLOAD_FILE, downloadFile)
}

export function* downloadFile(triggeredAction) {
	const { userId, signedUrl } = triggeredAction
	try {
		yield put(storeAction.downloadUrl({ userId, signedUrl }))
	} catch (err) {
		error(err)
	}
}

export default function* root() {
	yield spawn(showMessageReq)
	yield spawn(downloadFileReq)
}
