import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import useNavigate from 'common/hooks/useNavigate'
import { useTranslation } from 'react-i18next'
import IntegratedTextInput from 'common/components/input/IntegratedTextInput'
import { updateAsset, deleteAsset } from 'common/saga-actions/assetActions'
import { SettingsIcon, DesignIcon, ChartIcon, CalendarIcon, CategoriesIcon, CalculationIcon, CloseIcon, SearchIcon } from 'common/icons/light/index'
import { Menu, MenuItem, Button } from '@mui/material'
import ActionConfirm from 'common/components/ActionConfirm'
import AssetShare from 'common/screens/asset/AssetShare'
import AssetSearch from 'common/screens/asset/AssetSearch'
import { useSplitWindow } from 'common/hooks/useSplitWindow'
import BarButton from 'common/screens/layout/BarButton'
import { toggleDesignMode } from 'model/store/modelReducer'
import ModelDates from 'model/screens/dates/ModelDates'
import PanelButton from 'common/screens/layout/PanelButton'
import ModelBreakdown from 'model/screens/breakdown/ModelBreakdown'
import ModelEngine from 'model/screens/ModelEngine'
import { useAssetPermits } from 'common/hooks/useAssetPermits'
import { MODEL_ROLES } from 'common/constants/roles'
import AccountButton from 'common/screens/account/AccountButton'
import { setInView } from 'common/saga-actions/viewActions'
import { MODEL_PARAMS } from 'model/constants/modelParameters'
import * as modelSel from 'model/store/modelSelector'
import * as modelDataSel from 'model/store/modelDataSelector'
import * as viewSel from 'common/store/viewSelector'
import { PRODUCTS } from 'common/constants/products'

export default function ModelBar({ windowIndex = 0, reduced = false }) {
	const { t } = useTranslation(['common', 'model'])
	const dispatch = useDispatch()
	const { navigate } = useNavigate()
	const { id: aid } = useSplitWindow(windowIndex)
	const { id: mainId, type: mainType } = useSplitWindow(0)
	const permits = useAssetPermits(aid)
	const { canCreate } = permits

	const tid = useSelector((state) => state.auth.tid)
	const teamId = useSelector((state) => state.team.teamId)
	const sid = useSelector((state) => state.space.sid)
	const name = useSelector((state) => state.asset.asset[aid]?.data?.name)
	const isDesigning = useSelector((state) => modelSel.selectModelIsDesigning(state, aid))
	const isEngineReady = useSelector((state) => modelDataSel.selectModelIsEngineReady(state, aid))
	const chartPanel = useSelector((state) => viewSel.selectViewChartPanel(state, aid))
	const searchPanel = useSelector((state) => viewSel.selectViewSearchPanel(state, aid))

	const [menuAnchor, setMenuAnchor] = useState(null)
	const [isDeleteOpen, setIsDeleteOpen] = useState(false)
	const [isShareOpen, setIsShareOpen] = useState(false)
	const [isDatesOpen, setIsDatesOpen] = useState(null)
	const [isCategoriesOpen, setIsCategoriesOpen] = useState(null)
	const [isChartMenuOpen, setIsChartMenuOpen] = useState(null)
	const [isCalculateOpen, setIsCalculateOpen] = useState(null)

	// #### FUNCTIONS
	function onUpdateAsset(value) {
		if (value?.length > 0) dispatch(updateAsset({ tid, aid, content: { name: value } }))
	}

	function onDeleteAsset() {
		dispatch(deleteAsset({ tid, aid, assetType: PRODUCTS.model.key }))
		setIsDeleteOpen(false)
		navigate(`/team/${teamId}/space/${sid}`)
	}

	function onSetChartPanel(e, value) {
		if (!value) return
		const isOpen = value !== 'none'
		const direction = value !== 'none' ? value : chartPanel?.direction || null
		const position = value === 'horizontal' ? 'right' : value === 'vertical' ? 'bottom' : chartPanel?.position || null
		const size = chartPanel?.size || MODEL_PARAMS.CHART_PANEL_SIZE
		dispatch(setInView({ aid, changes: [{ path: 'chartPanel', value: { isOpen, direction, position, size } }], permits }))
		setIsChartMenuOpen(null)
	}

	function onCloseRightPanel() {
		navigate(`/${mainType}/${mainId}`)
	}

	function onToggleSearch() {
		const isOpen = !searchPanel?.isOpen || false
		const direction = isOpen ? 'horizontal' : searchPanel?.direction || null
		const position = isOpen ? 'left' : searchPanel?.position || null
		const size = MODEL_PARAMS.SEARCH_PANEL_SIZE
		dispatch(setInView({ aid, changes: [{ path: 'searchPanel', value: { isOpen, direction, position, size } }], permits }))
	}

	return (
		<>
			<div className="mx-4 flex flex-row items-center justify-between flex-1 h-full">
				<div className="flex flex-row items-center h-full">
					<div>
						<IntegratedTextInput
							canGrow
							minWidth={20}
							value={name}
							setValue={onUpdateAsset}
							className="text-lg text-barTextStrong bg-barBg hover:shadow-barBorderStrong focus:shadow-barActive mr-2"
							isDisabled={!canCreate}
						/>
					</div>
					{canCreate && <BarButton icon={DesignIcon} className="design-button" label={t('model:menu.design')} isActive={isDesigning} onClick={() => dispatch(toggleDesignMode({ id: aid }))} />}
					<BarButton icon={SearchIcon} label={t('model:menu.search')} isActive={searchPanel?.isOpen} onClick={onToggleSearch} />
					<BarButton icon={ChartIcon} label={t('model:menu.charts')} onClick={(e) => setIsChartMenuOpen(e.currentTarget)} />
					<BarButton icon={CalendarIcon} className="model-dates-button" label={t('model:menu.dates')} onClick={(e) => setIsDatesOpen(e.currentTarget)} />
					{canCreate && isDesigning && isEngineReady && (
						<>
							<BarButton icon={CategoriesIcon} className="model-categories-button" label={t('model:menu.breakdown')} onClick={(e) => setIsCategoriesOpen(e.currentTarget)} />
							<BarButton icon={CalculationIcon} className="model-engine-button" label={t('model:menu.engine')} onClick={(e) => setIsCalculateOpen(e.currentTarget)} />
						</>
					)}
				</div>
				<div className="flex flex-row items-center text-barTextStrong h-full">
					{!reduced && (!isDesigning || isEngineReady) && (
						<>
							<AssetSearch />
							<div className="mr-4" />
							{canCreate && (
								<>
									<Button variant="contained" size="small" className="mr-4 buttonContainedContrast" onClick={() => setIsShareOpen(true)}>
										{t('common:buttons.share')}
									</Button>
									<BarButton icon={SettingsIcon} label={t('common:buttons.settings')} onClick={(e) => setMenuAnchor(e.currentTarget)} />
								</>
							)}
							<AccountButton />
						</>
					)}
					{reduced && windowIndex === 1 && <BarButton icon={CloseIcon} label={t('common:buttons.close')} onClick={onCloseRightPanel} />}
				</div>
			</div>
			<Menu anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={() => setMenuAnchor(null)}>
				<MenuItem dense onClick={() => setIsDeleteOpen(true)}>
					{t('model:model.delete')}
				</MenuItem>
			</Menu>
			<ActionConfirm open={isDeleteOpen} content={t('common:messages.deleteConfirm')} onClose={() => setIsDeleteOpen(false)} onConfirm={onDeleteAsset} />
			<AssetShare isOpen={isShareOpen} onClose={() => setIsShareOpen(false)} roles={MODEL_ROLES} />
			<ModelDates aid={aid} anchor={isDatesOpen} onClose={() => setIsDatesOpen(null)} />
			<ModelBreakdown aid={aid} anchor={isCategoriesOpen} onClose={() => setIsCategoriesOpen(null)} />
			<PanelButton anchor={isChartMenuOpen} value={chartPanel?.isOpen ? chartPanel.direction : 'none'} onClose={() => setIsChartMenuOpen(null)} onChange={onSetChartPanel} />
			<ModelEngine aid={aid} anchor={isCalculateOpen} onClose={() => setIsCalculateOpen(null)} />
		</>
	)
}
