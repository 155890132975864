import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function Config({connector, config, validation, onConfig}) {
    const { t } = useTranslation(['common', 'automation'])
    return (
        <div>
            <div className="text-sm font-medium mb-2 mt-6 ml-2">{connector.newConnection}</div>
            {connector.config.map((field, index) => (
                <div key={index}>
                    <div className="text-xs text-gray-400 leading-none ml-2">{field.desc}</div>
                    <div className="flex flex-row items-center">
                        <TextField
                            variant="outlined"
                            className='m-2 text-sm'
                            size="small"
                            label={field.label}
                            value={config[field.name]}
                            InputProps={{
                                className: "text-sm"
                            }}
                            InputLabelProps={{
                                className: "text-sm"
                            }}
                            type={field.type}
                            multiline={field.multiline}
                            fullWidth
                            onChange={(event) => onConfig(field.name, field.type === 'number' ? parseInt(event.target.value) : event.target.value)}
                            error={field.require ? (validation === null ? false : !validation[field.name].valid) : null}
                            helperText={field.require ? (
                                validation !== null &&
                                !validation[field.name].valid &&
                                t(validation[field.name].error, {
                                    field: field.label
                                })
                            ) : null}
                        />
                    </div>
                </div>    
            ))}
        </div>
    )
}