import React, { useRef, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import useNavigate from 'common/hooks/useNavigate'
import { TextField, InputAdornment, Popper, MenuItem, Avatar } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import { searchAssets } from 'common/saga-actions/assetActions'
import { PRODUCTS } from 'common/constants/products'
import useFormatter from 'common/hooks/useFormatter'
import useLazyLoading from 'common/hooks/useLazyLoading'
import { cancelSearchAssets } from 'common/store/assetReducer'
import { throttle } from 'throttle-debounce'

export default function AssetSearch() {
	const { t } = useTranslation('common')
	const dispatch = useDispatch()
	const { printTimestamp } = useFormatter()
	const { navigate } = useNavigate()
	const searchInputRef = useRef()
	const assetTypes = [PRODUCTS.table.key, PRODUCTS.model.key, PRODUCTS.folder.key]
	const key = 'global'

	// #### REDUX
	const tid = useSelector((state) => state.auth.tid)
	const uid = useSelector((state) => state.auth.uid)
	const search = useSelector((state) => state.asset.search[key])
	const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)

	// #### STATE
	const [searchTerm, setSearchTerm] = useState('')
	const [isResultsOpen, setIsResultsOpen] = useState(false)

	// #### EFFECTS
	useEffect(() => {
		return () => dispatch(cancelSearchAssets({ key }))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (searchTerm?.length > 0) searchThrottled(key, tid, uid, searchTerm, assetTypes)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchTerm])

	const searchThrottled = throttle(200, (key, tid, uid, searchTerm, assetTypes) => {
		dispatch(searchAssets({ key, tid, uid, searchTerm, assetTypes }))
	})

	// #### LAZY LOADING
	const triggerFetch = useLazyLoading(() => dispatch(searchAssets({ key, tid, uid, searchTerm, startAfter: search?.startAfter, assetTypes })), search?.isLoading, search?.notFound)

	// #### FUNCTIONS
	function onNavigate(id, type) {
		navigate(`/${type}/${id}`)
	}

	function onSearchTerm(value) {
		setSearchTerm(value)
		if (value?.length > 0) setIsResultsOpen(true)
		else setIsResultsOpen(false)
	}

	if (isLoggedIn)
		return (
			<>
				<TextField
					ref={searchInputRef}
					variant="outlined"
					placeholder={t('common:assets.search')}
					margin="dense"
					size="small"
					value={searchTerm}
					className="m-0 bg-barBorderLight rounded"
					sx={{
						'& .MuiOutlinedInput-notchedOutline': {
							border: 'none'
						},
						'&:hover .MuiOutlinedInput-notchedOutline': {
							border: '1px solid var(--bar-border-strong)'
						},
						'& .Mui-focused .MuiOutlinedInput-notchedOutline': {
							border: '2px solid var(--bar-active) !important'
						}
					}}
					InputProps={{
						spellCheck: false,
						startAdornment: (
							<InputAdornment position="start">
								<SearchIcon fontSize="small" className="text-barTextLight" />
							</InputAdornment>
						),
						endAdornment: (
							<InputAdornment position="end">
								<ClearIcon fontSize="small" className={searchTerm?.length > 0 ? 'text-barTextLight cursor-pointer' : 'text-barBorderLight'} onClick={() => onSearchTerm('')} />
							</InputAdornment>
						)
					}}
					inputProps={{ className: 'h-[13px] text-barTextStrong text-sm' }}
					onChange={(e) => onSearchTerm(e.target.value)}
					onFocus={() => onSearchTerm(searchTerm)}
					onBlur={() => setIsResultsOpen(false)}
				/>
				<Popper open={isResultsOpen} anchorEl={searchInputRef?.current} style={{ width: searchInputRef?.current?.offsetWidth }} className="z-50" placement="bottom-start">
					<div className="bg-white mt-1 rounded shadow py-2 max-h-[500px] overflow-y-scroll flex flex-col">
						{search?.result?.length === 0 && <div className="text-xs self-center">{t('common:assets.noResults')}</div>}
						{search?.result?.map((asset, index) => {
							const type = asset.data.type
							const product = PRODUCTS[type]
							const updated = asset.data.updatedAt ? printTimestamp(asset.data.updatedAt) : null
							return (
								<MenuItem key={`assetSearch#${asset.id}`} className="flex flex-row items-center" onMouseDown={() => onNavigate(asset.id, type)}>
									<span ref={index + 1 === search.result.length ? triggerFetch : undefined} />
									<Avatar className={`h-[22px] w-[22px] mr-4 ${product.theme} bg-[var(--bar-bg)]`} variant="rounded">
										{React.createElement(product.icon, { className: 'text-[16px]' })}
									</Avatar>
									<div className="flex flex-col">
										<span className="text-xs truncate">{asset.data.name}</span>
										<span className="text-xxs text-textGray truncate">{updated && t('common:assets.updated', { date: updated })}</span>
									</div>
								</MenuItem>
							)
						})}
					</div>
				</Popper>
			</>
		)
	else return void 0
}
