import CreateTextCell from 'common/components/grid/cells/CreateTextCell'

export const cellText = {
	component: CreateTextCell,
	config: {
		alignRight: false,
		formatBlurredInput: ({ value, typeProps }) => (value ? value : ''),
		formatCopyValue: ({ value, typeProps }) => (value ? value : ''),
		parseUserInput: ({ value, typeProps }) => value.trim() || null,
		parsePastedValue: ({ value, typeProps, localeFallback }) => value.replace(/[\n\r]+/g, ' ').trim() || null
	}
}
