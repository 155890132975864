import { useRef, useLayoutEffect } from 'react'
import clsx from 'clsx'

export default function IntegratedTextArea({ value, setValue, placeholder, isDisabled = false, className }) {
	const inputRef = useRef()
	const cancelRef = useRef()

	const parseUserInput = (value) => value.trim() || null
	const formatBlurredInput = (value) => (value ? value : '')

	useLayoutEffect(() => {
		var observer
		if (inputRef?.current) {
			observer = new ResizeObserver(onInput)
			observer.observe(inputRef.current)
		}
		return () => {
			if (observer) observer.unobserve(inputRef.current)
		}
	}, [])

	function onFocus(e) {
		cancelRef.current = false
	}

	function onBlur(e) {
		if (cancelRef.current) inputRef.current.value = formatBlurredInput(value)
		else {
			const newValue = parseUserInput(inputRef.current.value)
			if (value != newValue) setValue(newValue)
		}
	}

	function onKeyDown(e) {
		if (e.key === 'Escape') {
			if (e.preventDefault) e.preventDefault()
			if (e.stopPropagation) e.stopPropagation()
			cancelRef.current = true
			inputRef.current.blur()
		} else if (e.key == 'Enter') {
			if (e.preventDefault) e.preventDefault()
			if (e.stopPropagation) e.stopPropagation()
			inputRef.current.blur()
		}
	}

	function onInput() {
		if (!inputRef?.current) return
		const inputHeight = inputRef.current.style.height
		inputRef.current.style['height'] = '20px'
		const scrollHeight = inputRef.current.scrollHeight
		inputRef.current.style['height'] = `${scrollHeight}px`
	}

	// ###########################
	// #### RENDER
	// ###########################
	return (
		<textarea
			ref={inputRef}
			defaultValue={formatBlurredInput(value)} // We use an uncontrolled component for better performance
			placeholder={placeholder}
			className={clsx('px-1 border-0 rounded-sm self-stretch w-full hover:shadow-outer1 focus:shadow-outer2 focus:outline-none resize-none overflow-hidden', className, isDisabled && 'pointer-events-none')}
			tabIndex={-1} // Important to prevent any undesired "tabbing"
			onFocus={onFocus}
			onBlur={onBlur}
			onKeyDown={onKeyDown}
			readOnly={isDisabled}
			onInput={onInput}
		/>
	)
}
