import { useTranslation } from 'react-i18next'
import { Avatar, AvatarGroup, Button, Modal, CircularProgress } from '@mui/material'
import { memo, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { TABLE_PARAMS } from 'table/constants/tableParameters'
import DownloadIcon from '@mui/icons-material/DownloadOutlined'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import BrowseIcon from '@mui/icons-material/NoteAddOutlined'
import FileIcon from '@mui/icons-material/InsertDriveFileOutlined'
import ActionConfirm from 'common/components/ActionConfirm'

export default memo(function CreateFileCell({
	rowIndex,
	colIndex,
	item,
	setItem,
	focus = false,
	cancel = false,
	typeProps,
	config: { alignRight = false, parseUserInput, formatBlurredInput, parsePastedValue },
	startEditing,
	stopEditing,
	colWidth,
	fileTransmit
}) {
	const inputRef = useRef()
	const { t } = useTranslation(['common'])
	const modalRef = useRef()

	const isImage = { 'image/jpeg': true, 'image/png': true, 'image/gif': true, 'image/svg': true, 'image/jpg': true }

	// #### STATE
	const [currentFocus, setCurrentFocus] = useState({ is: false, cancel: false })
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [deleteFile, setDeleteFile] = useState(null)
	const [numPreviewItems, setNumPreviewItems] = useState(4)
	const [file, setFile] = useState(null)

	useEffect(() => {
		setCurrentFocus({ is: focus, cancel: cancel })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [focus])

	function openModal() {
		startEditing({ col: colIndex, row: rowIndex })
		setIsModalOpen(true)
	}

	function closeModal(nextRow = false) {
		stopEditing(nextRow)
		setFile(null)
		setIsModalOpen(false)
	}

	useLayoutEffect(() => {
		if (currentFocus.is) openModal()
		else if (isModalOpen) closeModal()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentFocus])

	// Calculate number of thumbnails to display based on column width
	useLayoutEffect(() => {
		// avatar cannot work with fewer than 2 items
		const numPreviewItems = Math.max(2, Math.floor(colWidth / TABLE_PARAMS.DEFAULT_ROW_HEIGHT))
		setNumPreviewItems(numPreviewItems)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [colWidth])

	// If modal is open, do not propagate clicks to parent components
	const onMouseDown = useCallback((e) => {
		if (e.preventDefault) e.preventDefault()
		if (e.stopPropagation) e.stopPropagation()
		const clickInside = modalRef.current.contains(e.target)
		if (!clickInside) closeModal()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const onKeyDown = useCallback((e) => {
		if (e.key === 'Enter') {
			if (e.preventDefault) e.preventDefault()
			if (e.stopPropagation) e.stopPropagation()
			closeModal(true)
		} else if (e.key === 'Escape') {
			if (e.preventDefault) e.preventDefault()
			if (e.stopPropagation) e.stopPropagation()
			closeModal()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// If modal is open, attach an onMouseDown event to the cell to prevent clicks from propagating to parent components
	useEffect(() => {
		if (isModalOpen && !deleteFile) {
			document.addEventListener('mousedown', onMouseDown, true)
			document.addEventListener('keydown', onKeyDown, true)
		}
		return () => {
			document.removeEventListener('mousedown', onMouseDown, true)
			document.removeEventListener('keydown', onKeyDown, true)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isModalOpen, deleteFile])

	function browse() {
		const input = inputRef.current
		if (input) {
			input.click()
			setFile(null)
		}
	}

	function onCancel() {
		setFile(null)
	}

	function upload() {
		if (!file) return
		// Convert file to blob (required for firebase storage to work)
		fetch(file.url)
			.then((response) => response.blob())
			.then((blob) => {
				setItem(blob, null, file.name, file.size, file.type)
			})
	}

	function onDeleteFile() {
		const fileId = deleteFile
		if (fileId) setItem(null, fileId, null, null, null)
		setDeleteFile(null)
	}

	function preview() {
		const input = inputRef.current
		if (!input) return
		const [file] = input.files
		if (!file) return
		const name = file.name
		const size = file.size
		const type = file.type
		const url = URL.createObjectURL(file)
		setFile({ name, size, type, url })
	}

	useEffect(() => {
		if (!fileTransmit?.loading && !fileTransmit?.isError) setFile(null)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fileTransmit?.loading])

	// ###########################
	// #### RENDER
	// ###########################
	return (
		<div className="absolute top-0 left-0 right-0 bottom-0 flex items-center">
			{item && Array.isArray(item) && (
				<AvatarGroup
					max={numPreviewItems}
					variant="square"
					spacing={-1}
					classes={{ avatar: `w-[${TABLE_PARAMS.DEFAULT_ROW_HEIGHT - 5}px] h-[${TABLE_PARAMS.DEFAULT_ROW_HEIGHT - 5}px]` }}
					onClick={openModal}
				>
					{item?.map((file) => (
						<Avatar key={`avatar#${rowIndex}#${colIndex}#${file.id}`} src={file.fullPath} variant="square" />
					))}
				</AvatarGroup>
			)}
			<Modal open={isModalOpen}>
				<div
					ref={modalRef}
					className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 overflow-scroll bg-white shadow border-1 border-borderGray outline-none w-[340px] md:w-[660px] rounded" //h-full lg:h-[660px]
				>
					<div className="grid auto-rows-[300px] p-[20px] gap-[20px] grid-cols-1 md:grid-cols-2">
						{item &&
							Array.isArray(item) &&
							item?.map((file) => (
								<div key={`file#${file.id}`} className="overflow-hidden">
									<div className="flex flex-row items-center justify-between h-[30px]">
										<span className="flex-1 overflow-hidden mr-4 whitespace-nowrap text-ellipsis text-sm">{file.name}</span>
										<span className="flex flex-row items-center text-textGray">
											<a href={file.fullPath} download target="_blank" rel="noreferrer" className="flex flex-row items-center text-textGray">
												<DownloadIcon fontSize="small" />
											</a>
											{focus && <DeleteIcon fontSize="small" className="ml-2 cursor-pointer" onClick={() => setDeleteFile(file.id)} />}
										</span>
									</div>
									<a href={file.url} download target="_blank" rel="noreferrer">
										{isImage[file.type] ? (
											<img loading="lazy" src={file.fullPath} className="w-full h-[calc(100%-30px)] object-cover border-2 border-transparent rounded" alt="" />
										) : (
											<div className="w-full h-[calc(100%-30px)] bg-white border-2 border-borderGray flex flex-col items-center justify-center rounded">
												<FileIcon className="w-[80px] h-[80px] text-textGray" />
											</div>
										)}
									</a>
								</div>
							))}
						{focus && (
							<div className="overflow-hidden" style={{ gridColumnEnd: item?.length >= 0 ? 'span 1' : 'span 2' }}>
								<div className="flex flex-row items-center justify-between h-[30px]" />
								<div
									className="w-full h-[calc(100%-30px)] bg-white border-2 border-borderGray border-dashed flex flex-col items-center justify-center rounded p-2"
									onClick={file ? undefined : browse}
									style={{ cursor: file ? undefined : 'pointer' }}
								>
									{file ? (
										<>
											{isImage[file.type] ? (
												<img loading="lazy" src={file.url} className="w-[80px] h-[80px] object-cover mb-4 rounded" alt="" />
											) : (
												<FileIcon className="w-[80px] h-[80px] text-textGrau" />
											)}

											<span className="mr-2 text-sm">{file.name}</span>
											<span className="text-textGray text-sm">{Math.round(file.size / 1024)} KB</span>
											{fileTransmit?.isError && <span className="mt-2 text-red-500 text-sm">{fileTransmit?.error}</span>}

											<div className="mt-4 flex flex-row items-center">
												{fileTransmit?.loading ? (
													<>
														<span>{`${Math.round(fileTransmit?.progress)}%`}&nbsp;</span>
														<CircularProgress size={15} className="text-textGray" />
													</>
												) : (
													<>
														<Button variant="outlined" onClick={onCancel} className="buttonOutlinedGray text-textGray">
															{t('common:buttons.cancel')}
														</Button>
														<Button variant="contained" onClick={upload} className="ml-2 buttonContainedContrast">
															{t('common:buttons.upload')}
														</Button>
													</>
												)}
											</div>
										</>
									) : (
										<>
											<BrowseIcon className="mb-4 w-[80px] h-[80px] text-textGray" />
											<span className="text-sm">{t('table:file.select')}</span>
										</>
									)}
								</div>
							</div>
						)}
					</div>
					{Boolean(deleteFile) && (
						<ActionConfirm
							open={Boolean(deleteFile)}
							title={t('table:messages.fileDeleteConfirm')}
							content={t('table:messages.fileDeleteConfirmContent')}
							onClose={() => setDeleteFile(null)}
							onConfirm={onDeleteFile}
						/>
					)}
					<input ref={inputRef} type="file" className="invisible" onChange={preview} />
				</div>
			</Modal>
		</div>
	)
})
