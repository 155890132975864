import { TIME_EXPRESSIONS, TIME_EXAMPLES, DELETED_VARIABLE, DELETED_ATTRIBUTE, RANGE_EXAMPLES, ABSOLUTE_EXAMPLES } from 'common/constants/formulas'
import { WidgetType } from '@codemirror/view'
import { EditorView, Decoration } from '@codemirror/view'
import { syntaxTree } from '@codemirror/language'
import { ViewPlugin } from '@codemirror/view'
import { closeHelper } from 'common/components/formula/ExtensionHelper'
import i18n from 'common/config/i18n'

const baseTheme = EditorView.baseTheme({
	'.cm-var-wrapper': { paddingLeft: '6px', paddingRight: '6px', paddingTop: '3px', paddingBottom: '3px', marginLeft: '3px', marginRight: '3px', backgroundColor: '#cce7ff', borderRadius: '4px' },
	'.cm-var-error-wrapper': { paddingLeft: '6px', paddingRight: '6px', paddingTop: '3px', paddingBottom: '3px', marginLeft: '3px', marginRight: '3px', backgroundColor: '#ffcdcc', borderRadius: '4px' },
	'.cm-var-label': { fontWeight: 500 },
	'.cm-var-label-time': { fontWeight: 300 },
	'.cm-var-icon': { cursor: 'pointer', '& > svg': { display: 'inline', verticalAlign: '-3px', paddingLeft: '3px', color: 'rgba(0, 0, 0, 0.6)' } }
})

export function identifyVariables({ variables, parent, clickStopPropagation = true, isDisabled = false, isFixed = false }) {
	const variablesMap = {}
	variables.map((variable) => (variablesMap[variable.key] = variable))

	function replaceVariables(view, isDisabled, prefix) {
		let widgets = []
		const doc = view?.state?.doc
		if (doc.length === 0 || doc.sliceString(0, 1) !== '=') return Decoration.set(widgets)

		for (let { from, to } of view.visibleRanges) {
			syntaxTree(view.state).iterate({
				from,
				to,
				enter: (node) => {
					if ((node.name === 'variableName' || node.name === 'invalid') && node.from > 0) {
						// Is variable after an opening bracket?
						let start = null
						let isAttribute = false
						const prevChar = view.state.doc.sliceString(node.from - 1, node.from)
						const prevChar2 = view.state.doc.sliceString(node.from - 2, node.from - 1)
						if (prevChar === '#' && prevChar2 === '{') {
							isAttribute = true
							start = node.from - 2
						} else if (prevChar === '{') start = node.from - 1
						else return

						// Is there a closing bracket?
						var end = -1
						var timeStart = -1
						var timeEnd = -1
						var timeExpression = null
						for (let i = node.to; i < view.state.doc.length && end < 0; i++) {
							let char = view.state.doc.sliceString(i, i + 1)
							if (char === '}') end = i + 1
							else if (char === '[') timeStart = i
							else if (char === ']') timeEnd = i + 1
						}
						if (end < 0) return
						const hasTime = timeStart > 0 && timeEnd > timeStart
						if (!hasTime) {
							timeStart = end - 1
							timeEnd = end - 1
						}
						timeExpression = view.state.doc.sliceString(timeStart + 1, timeEnd - 1)

						// Is variable a valid id?
						let varId = isAttribute ? view.state.doc.sliceString(node.from, end - 1) : view.state.doc.sliceString(node.from, node.to)
						const variable = variablesMap[varId]
						const isError = variable == null
						if (!variable && !isError) return

						// Print widget
						var widget
						if (isError) widget = new VariableErrorWidget(isAttribute ? DELETED_ATTRIBUTE.label : DELETED_VARIABLE.label)
						else widget = new VariableWidget(variable.label, isDisabled, !isAttribute, hasTime, timeStart, timeEnd, timeExpression, prefix)

						let deco = Decoration.replace({
							widget: widget
						})
						widgets.push(deco.range(start, end))
					}
				}
			})
		}
		return Decoration.set(widgets)
	}

	const variablesPlugin = ViewPlugin.fromClass(
		class {
			decorations
			clickStopPropagation
			isDisabled
			prefix

			constructor(view) {
				this.isDisabled = isDisabled
				this.prefix = isFixed ? 'cmbar' : 'cm'
				this.decorations = replaceVariables(view, this.isDisabled, this.prefix)
				this.clickStopPropagation = clickStopPropagation
			}

			update(update) {
				if (update.docChanged || update.viewportChanged) this.decorations = replaceVariables(update.view, this.isDisabled, this.prefix)
			}
		},
		{
			decorations: (v) => v.decorations,
			provide: (plugin) =>
				EditorView.atomicRanges.of((view) => {
					return view.plugin(plugin)?.decorations || Decoration.none
				}),

			eventHandlers: {
				mousedown: (e, view) => {
					let target = e.target
					while (!(target instanceof HTMLElement)) target = target.parentElement
					// Reacts to button to remove a variable
					// if ((target.id.startsWith('cm-var-remove') || target.id.startsWith('cmbar-var-remove')) && !isDisabled) {
					// 	if (clickStopPropagation && e.preventDefault) e.preventDefault()
					// 	if (clickStopPropagation && e.stopPropagation) e.stopPropagation()

					// 	const idStr = target.id
					// 	const idSplit = idStr.split('#')
					// 	const start = parseInt(idSplit[1])
					// 	const end = parseInt(idSplit[2])

					// 	view.dispatch({ changes: { from: start, to: end, insert: '' } })
					// 	return
					// }
					// Reacts to button to modify time
					if ((target.id.startsWith('cm-var-settings') || target.id.startsWith('cmbar-var-settings')) && !isDisabled) {
						if (clickStopPropagation && e.preventDefault) e.preventDefault()
						if (clickStopPropagation && e.stopPropagation) e.stopPropagation()

						const idStr = target.id
						const idSplit = idStr.split('#')
						const timeStart = parseInt(idSplit[1])
						const timeEnd = parseInt(idSplit[2])

						// view.dispatch({ effects: closeHelper.of(true) })

						setTimeout(() => {
							view.dispatch({ effects: closeHelper.of(true) })
						}, 50)

						return selectTime(view, view.posAtDOM(target), target, parent, timeStart, timeEnd)
					}
				}
			}
		}
	)

	return [baseTheme, [], variablesPlugin]
}

class VariableErrorWidget extends WidgetType {
	constructor(label) {
		super()
		this.label = label
	}

	eq(other) {
		return other.label === this.label
	}

	toDOM() {
		let wrap = document.createElement('span')
		wrap.className = 'cm-var-error-wrapper'

		let label = wrap.appendChild(document.createElement('span'))
		label.innerHTML = this.label
		label.className = 'cm-var-label'

		return wrap
	}

	ignoreEvent() {
		return false
	}
}

class VariableWidget extends WidgetType {
	constructor(label, isDisabled, canHaveTime, hasTime, timeStart, timeEnd, timeExpression, prefix) {
		super()
		this.label = label
		this.isDisabled = isDisabled
		this.canHaveTime = canHaveTime
		this.hasTime = hasTime
		this.timeStart = timeStart
		this.timeEnd = timeEnd
		this.timeExpression = timeExpression
		this.prefix = prefix
	}

	eq(other) {
		return (
			other.label === this.label &&
			other.isDisabled === this.isDisabled &&
			other.canHaveTime === this.canHaveTime &&
			other.hasTime === this.hasTime &&
			other.timeStart === this.timeStart &&
			other.timeEnd === this.timeEnd &&
			other.timeExpression === this.timeExpression &&
			other.prefix === this.prefix
		)
	}

	toDOM() {
		let wrap = document.createElement('span')
		wrap.className = 'cm-var-wrapper'
		wrap.id = `${this.prefix}-var-wrapper`

		let label = wrap.appendChild(document.createElement('span'))
		label.id = `${this.prefix}-var-label`
		label.className = 'cm-var-label'
		label.innerHTML = `${this.label}`

		if (this.canHaveTime) {
			const timeDefinition = TIME_EXPRESSIONS.find((item) => item.apply === this.timeExpression)
			const timeText = timeDefinition?.key === TIME_EXPRESSIONS[0].key ? '' : timeDefinition?.key || this.timeExpression
			if (timeText) {
				let time = wrap.appendChild(document.createElement('span'))
				time.id = `${this.prefix}-var-time`
				time.className = 'cm-var-label-time'
				time.innerHTML = ` | ${timeText}`
			}

			let icon = wrap.appendChild(document.createElement('span'))
			icon.id = `${this.prefix}-var-settings#${this.timeStart}#${this.timeEnd}`
			icon.className = 'cm-var-icon'
			icon.innerHTML = this.isDisabled
				? ''
				: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="15px" height="15px"><path d="m9.25 22-.4-3.2q-.325-.125-.612-.3-.288-.175-.563-.375L4.7 19.375l-2.75-4.75 2.575-1.95Q4.5 12.5 4.5 12.337v-.675q0-.162.025-.337L1.95 9.375l2.75-4.75 2.975 1.25q.275-.2.575-.375.3-.175.6-.3l.4-3.2h5.5l.4 3.2q.325.125.613.3.287.175.562.375l2.975-1.25 2.75 4.75-2.575 1.95q.025.175.025.337v.675q0 .163-.05.338l2.575 1.95-2.75 4.75-2.95-1.25q-.275.2-.575.375-.3.175-.6.3l-.4 3.2Zm2.8-6.5q1.45 0 2.475-1.025Q15.55 13.45 15.55 12q0-1.45-1.025-2.475Q13.5 8.5 12.05 8.5q-1.475 0-2.488 1.025Q8.55 10.55 8.55 12q0 1.45 1.012 2.475Q10.575 15.5 12.05 15.5Zm0-2q-.625 0-1.062-.438-.438-.437-.438-1.062t.438-1.062q.437-.438 1.062-.438t1.063.438q.437.437.437 1.062t-.437 1.062q-.438.438-1.063.438ZM12 12Zm-1 8h1.975l.35-2.65q.775-.2 1.438-.588.662-.387 1.212-.937l2.475 1.025.975-1.7-2.15-1.625q.125-.35.175-.738.05-.387.05-.787t-.05-.788q-.05-.387-.175-.737l2.15-1.625-.975-1.7-2.475 1.05q-.55-.575-1.212-.963-.663-.387-1.438-.587L13 4h-1.975l-.35 2.65q-.775.2-1.437.587-.663.388-1.213.938L5.55 7.15l-.975 1.7 2.15 1.6q-.125.375-.175.75-.05.375-.05.8 0 .4.05.775t.175.75l-2.15 1.625.975 1.7 2.475-1.05q.55.575 1.213.962.662.388 1.437.588Z"/></svg>`
		}
		return wrap
	}

	ignoreEvent() {
		return false
	}
}

const modalId = 'cm-var-modal'
const overlayId = 'cm-var-overlay'
const inputId = 'cm-var-input'
const helpId = 'cm-var-help'
const examplesToggleId = 'cm-var-examples'
const timeExprId = 'cm-var-timeexpr-'
const inputWrapperId = 'cm-var-input-wrapper'
const inputButtonId = 'cm-var-input-button'
// const labelStandardId = 'cm-var-label-standard'
const labelCustomId = 'cm-var-label-custom'

const examplesClosedInnerHTML = `${i18n.t(
	'formula:variableTime.examples'
)} <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="15px" height="15px"><path d="M0 0h24v24H0z" fill="none" /><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" /></svg>`
const examplesOpenInnerHTML = `${i18n.t(
	'formula:variableTime.examples'
)} <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="15px" height="15px"><path d="M0 0h24v24H0z" fill="none" /><path d="m7.4 15.375-1.4-1.4 6-6 6 6-1.4 1.4-4.6-4.6Z" /></svg>`

// Classnames don't work within this method, as it is external to codemirror
function selectTime(view, pos, target, parent, timeStart, timeEnd) {
	document.addEventListener('keydown', onModalKeyDown, true)

	// Determine position
	const targetPos = target.getBoundingClientRect()
	const parentPos = parent.getBoundingClientRect()
	const left = targetPos.left - parentPos.left
	const top = targetPos.top + target.offsetHeight - parentPos.top

	let wrap = document.createElement('ul')
	wrap.id = modalId
	wrap.style.cssText += `position: absolute; top: ${top}px; left: ${left}px; width: 350px; min-height: 200px; overflow-y: scroll; background-color: white; border: none; border-radius: 4px; box-shadow: 0 2px 6px 2px rgba(60, 64, 67, 0.15); padding: 15px 20px; z-index: 3`
	wrap.innerHTML = ''
	wrap.onclick = function (e) {
		if (e.preventDefault) e.preventDefault()
		if (e.stopPropagation) e.stopPropagation()
	}
	parent.appendChild(wrap)

	// let standard = timeCategory(wrap, i18n.t('formula:variableTime.shiftTime'), false, labelStandardId)

	TIME_EXPRESSIONS.forEach((time, index) => {
		let li = document.createElement('li')
		li.id = timeExprId + index
		li.style.cssText += `overflow-x: hidden; overflow-y: hidden; text-overflow: ellipsis; cursor: pointer; display: flex; flex-direction: row; align-items: center; justify-content: space-between; padding: 3px 0px; height: 25px`
		li.innerHTML = `<span>${time.label}</span><span style="color: rgba(0,0,0,0.5); font-weight: 400; margin-left: 15px;">${time.detail}</span>`
		li.onclick = function (e) {
			submitChange(e, time.apply, view, timeStart, timeEnd)
		}
		wrap.appendChild(li)
	})

	customTime(view, wrap, timeStart, timeEnd)

	let overlay = document.createElement('div')
	overlay.id = overlayId
	overlay.style.cssText += 'position:fixed; top:0; left: 0; right:0; bottom:0; background-color:transparent; display: block'
	overlay.onclick = function (e) {
		closeModal(e)
	}
	parent.appendChild(overlay)
}

function customTime(view, parent, timeStart, timeEnd) {
	let custom = timeCategory(parent, i18n.t('formula:variableTime.custom'), true, labelCustomId)

	let examplesButton = document.createElement('div')
	examplesButton.style.cssText += `display: flex; flex-direction: row; align-items: center; cursor: pointer;`
	examplesButton.id = examplesToggleId
	examplesButton.innerHTML = examplesClosedInnerHTML
	examplesButton.onclick = function (e) {
		toggleExamples(e)
	}
	custom.appendChild(examplesButton)

	let inputWrapper = document.createElement('div')
	inputWrapper.id = inputWrapperId
	inputWrapper.style.cssText += `display: flex; flex-direction: row; align-items: center; justify-content: space-between;`
	parent.appendChild(inputWrapper)

	let input = document.createElement('input')
	input.id = inputId
	input.style.cssText += `width: 100%; margin-right: 5px; padding: 3px 10px; border: 1px solid #9da6ab; border-radius: 3px; font-size: 0.6875rem;`
	input.placeholder = 'e.g., t-5'
	input.onkeydown = function (e) {
		if (e.key === 'Enter') submitCustomTime(e, view, timeStart, timeEnd)
	}
	inputWrapper.appendChild(input)

	let button = document.createElement('div')
	button.id = inputButtonId
	button.style.cssText += `cursor: pointer; padding: 0px 3px; font-weight: 500; color: rgba(0, 0, 0, 0.6);`
	button.innerHTML = i18n.t('common:buttons.ok')
	button.onclick = function (e) {
		submitCustomTime(e, view, timeStart, timeEnd)
	}
	inputWrapper.appendChild(button)

	customTimeHelp(parent)
}

function customTimeHelp(parent) {
	let helpWrapper = document.createElement('div')
	helpWrapper.id = helpId
	helpWrapper.style.cssText += `display: none;`
	parent.appendChild(helpWrapper)

	let helpBlock = document.createElement('ul')
	helpBlock.style.cssText += `background-color: rgba(250, 250, 250, 1); border-radius: 3px; margin-top: 9px; padding: 5px; list-style-position: inside;`
	helpBlock.innerHTML = `<span>${i18n.t('formula:timeExprExample.title')}</span>`
	helpWrapper.appendChild(helpBlock)

	TIME_EXAMPLES.forEach((example) => {
		let helpBlockExample = document.createElement('li')
		helpBlockExample.style.cssText += `list-style-type:disc; padding-left: 5px; margin-top: 2px;`
		helpBlockExample.innerHTML = `<strong>${example.label}</strong>&nbsp;${example.text}`
		helpBlock.appendChild(helpBlockExample)
	})

	let rangeBlock = document.createElement('ul')
	rangeBlock.style.cssText += `background-color: rgba(250, 250, 250, 1); border-radius: 3px; margin-top: 9px; padding: 5px; list-style-position: inside;`
	rangeBlock.innerHTML = `<span>${i18n.t('formula:rangeExample.title')}</span>`
	helpWrapper.appendChild(rangeBlock)

	RANGE_EXAMPLES.forEach((example) => {
		let helpBlockExample = document.createElement('li')
		helpBlockExample.style.cssText += `list-style-type:disc; padding-left: 5px; margin-top: 2px;`
		helpBlockExample.innerHTML = `<strong>${example.label}</strong>&nbsp;${example.text}`
		rangeBlock.appendChild(helpBlockExample)
	})

	let absoluteBlock = document.createElement('ul')
	absoluteBlock.style.cssText += `background-color: rgba(250, 250, 250, 1); border-radius: 3px; margin-top: 9px; padding: 5px; list-style-position: inside;`
	absoluteBlock.innerHTML = `<span>${i18n.t('formula:absoluteExample.title')}</span>`
	helpWrapper.appendChild(absoluteBlock)

	ABSOLUTE_EXAMPLES.forEach((example) => {
		let helpBlockExample = document.createElement('li')
		helpBlockExample.style.cssText += `list-style-type:disc; padding-left: 5px; margin-top: 2px;`
		helpBlockExample.innerHTML = `<strong>${example.label}</strong>&nbsp;${example.text}`
		absoluteBlock.appendChild(helpBlockExample)
	})
}

function timeCategory(parent, value, hasSeparator, id) {
	let category = document.createElement('li')
	category.id = id
	category.style.cssText += `display: flex; flex-direction: row; align-items: center; justify-content: space-between; padding-bottom: 9px; ${
		hasSeparator && 'border-top: 1px solid #f5f5f5; margin-top: 6px; padding-top: 9px;'
	} `
	category.innerHTML = `<span id="${id}-span" style="font-weight: ${hasSeparator ? 400 : 500};">${value}</span>`
	parent.appendChild(category)
	return category
}

function submitChange(e, value, view, start, end) {
	if (e.preventDefault) e.preventDefault()
	if (e.stopPropagation) e.stopPropagation()
	const insert = value?.length > 0 ? `[${value}]` : ''
	const cursor = start + (value?.length || 0)
	view.dispatch({ changes: { from: start, to: end, insert } })
	closeModal(e)
	view.focus()
	view.dispatch({ selection: { anchor: cursor, head: cursor } })
}

function submitCustomTime(e, view, timeStart, timeEnd) {
	if (e.preventDefault) e.preventDefault()
	if (e.stopPropagation) e.stopPropagation()

	let input = document.getElementById(inputId)
	const value = input.value
	if (!value || value.length < 1) return
	submitChange(e, value, view, timeStart, timeEnd)
}

function toggleExamples(e) {
	if (e.preventDefault) e.preventDefault()
	if (e.stopPropagation) e.stopPropagation()

	let help = document.getElementById(helpId)
	let examplesButton = document.getElementById(examplesToggleId)

	if (help.style.display === 'none') {
		help.style.display = 'block'
		examplesButton.innerHTML = examplesOpenInnerHTML
	} else {
		help.style.display = 'none'
		examplesButton.innerHTML = examplesClosedInnerHTML
	}
}

function closeModal(e) {
	if (e.preventDefault) e.preventDefault()
	if (e.stopPropagation) e.stopPropagation()

	document.removeEventListener('keydown', onModalKeyDown, true)

	let modal = document.getElementById(modalId)
	let overlay = document.getElementById(overlayId)
	modal.remove()
	overlay.remove()
}

function onModalKeyDown(e) {
	let input = document.getElementById(inputId)
	let inputHasFocus = document.activeElement === input
	if (!inputHasFocus && input) input.focus()

	if (e.key === 'Escape') closeModal(e)
	else if (e.key === 'Enter' && !inputHasFocus) {
		if (e.preventDefault) e.preventDefault()
		if (e.stopPropagation) e.stopPropagation()
	}
}
