import clsx from 'clsx'
import { Autocomplete, InputBase } from '@mui/material'
import { useFirstRender } from 'common/hooks/useFirstRender'
import { memo, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'

export default memo(function CreateSelectCell({
	item,
	setItem,
	focus = false,
	cancel = false,
	typeProps,
	config: { alignRight = false, parseUserInput, formatBlurredInput, parsePastedValue, textWrap = false },
	className
}) {
	const popperRef = useRef()
	const firstRender = useFirstRender()

	const itemList = typeProps?.itemList || []
	const getItemLabel = (item) => item

	// #### STATE
	const [currentFocus, setCurrentFocus] = useState({ is: false, cancel: false })
	const [prevFocus, setPrevFocus] = useState({ is: false, cancel: false })
	const [selectedValue, setSelectedValue] = useState()
	const [inputValue, setInputValue] = useState()
	const [isPopperOpen, setIsPopperOpen] = useState(false)

	useLayoutEffect(() => {
		setPrevFocus(currentFocus)
		setCurrentFocus({ is: focus, cancel: cancel })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [focus])

	function initialize() {
		if (!item) return
		setInputValue(item)
		setSelectedValue(item)
	}

	// Load item into state when item changes
	useEffect(() => {
		initialize()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [item])

	// Save results when focus is lost
	useLayoutEffect(() => {
		if (!currentFocus.is && !firstRender && prevFocus.is) {
			if (!currentFocus.cancel && selectedValue) {
				if (item !== selectedValue) setItem(selectedValue)
			} else initialize()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentFocus])

	// If select popper is open, do not propagate clicks to parent components
	const onMouseDown = useCallback((e) => {
		if (popperRef?.current) {
			if (e.preventDefault) e.preventDefault()
			if (e.stopPropagation) e.stopPropagation()

			const clickInside = popperRef?.current?.state?.elements?.popper?.contains(e.target)
			if (!clickInside) setIsPopperOpen(false)
			return
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// If cell is focused, attach an onMouseDown event to the cell to prevent clicks from propagating to parent components
	useEffect(() => {
		if (currentFocus.is) document.addEventListener('mousedown', onMouseDown, true)
		return () => {
			document.removeEventListener('mousedown', onMouseDown, true)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentFocus])

	// ###########################
	// #### RENDER
	// ###########################
	if (currentFocus.is) {
		return (
			<Autocomplete
				componentsProps={{ popper: { popperRef: popperRef } }}
				open={isPopperOpen}
				options={itemList}
				getOptionLabel={getItemLabel}
				value={selectedValue}
				inputValue={inputValue}
				onChange={(e, value) => setSelectedValue(value)}
				onInputChange={(e, value) => setInputValue(value)}
				renderInput={({ inputProps, InputProps }) => (
					<InputBase
						inputRef={InputProps.ref}
						autoFocus
						fullWidth
						margin="none"
						inputProps={{ ...inputProps, className: 'text-sm' }}
						endAdornment={InputProps.endAdornment}
						onFocus={(e) => {
							e.target.select()
						}}
						classes={{ root: 'flex items-center justify-center' }}
					/>
				)}
				classes={{ root: 'flex-1', listbox: 'text-sm', noOptions: 'text-sm' }}
				openOnFocus
				onOpen={() => setIsPopperOpen(true)}
				onClose={() => setIsPopperOpen(false)}
			/>
		)
	} else return <span className={clsx('text-sm', textWrap ? 'whitespace-normal break-normal' : 'text-ellipsis overflow-hidden', className)}>{item}</span>
})
