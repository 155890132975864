import { put, call, takeEvery } from 'redux-saga/effects'
import * as api from 'common/api/helpers/loadApi'
import { replaceParam } from 'common/saga/helpers/utils'
import * as globalStoreAction from 'common/store/globalReducer'
import { error } from 'common/config/errors'

// EXTERNAL
export const queryConfig = ({ path, returnType = 'doc' }) => {
	return {
		path, // path to document or collection
		returnType // doc, map or list
	}
}

export const sagaConfig = ({ loadAction, loadResponse }) => {
	return {
		loadAction, // load action
		loadResponse // reponse to load
	}
}

export function* load(sagaConfig, queryConfig) {
	yield takeEvery(sagaConfig.loadAction, loadInit, sagaConfig, queryConfig)
}

// INTERNAL
function* loadInit(sagaConfig, queryConfig, triggeredAction) {
	const key = triggeredAction.key

	try {
		// Loading on
		if (triggeredAction.loadingKey) yield put(globalStoreAction.loadingOn({ key: triggeredAction.loadingKey, item: key }))

		// Introduce values into parameters
		var newQueryConfig = {
			...queryConfig,
			path: replaceParam(queryConfig.path, triggeredAction)
		}

		// Execute query
		const result = queryConfig.returnType === 'doc' ? yield call(api.loadDoc, newQueryConfig) : yield call(api.loadCol, newQueryConfig)

		// Return result
		if (sagaConfig.loadResponse) yield put(sagaConfig.loadResponse({ ...result, key }))
	} catch (err) {
		// we are not currently surfacing these errors to user
		error(err)
	} finally {
		// Loading off
		if (triggeredAction.loadingKey) yield put(globalStoreAction.loadingOff({ key: triggeredAction.loadingKey, item: key }))
	}
}

export function* loadSynchronous(queryConfig, triggeredAction) {
	// Introduce values into parameters
	var newQueryConfig = {
		...queryConfig,
		path: replaceParam(queryConfig.path, triggeredAction)
	}

	// Execute query
	const result = queryConfig.returnType === 'doc' ? yield call(api.loadDoc, newQueryConfig) : yield call(api.loadCol, newQueryConfig)
	return result
}
