import { Button, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { validateNotEmpty } from 'common/utils/validate'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { clearVariableBreakdown } from 'model/store/modelDataReducer'
import ModelTypeRefVariable from './ModelTypeRefVariable'
import { connectVariable, disconnectVariable } from 'model/saga-actions/engineMiddlewareActions'

export default function ModelConnectVariable({ aid, position, onClose, varId, variable }) {
	const dispatch = useDispatch()
	const { t } = useTranslation(['common', 'model'])

	// #### REDUX
	const tid = useSelector((state) => state.auth.tid)
	const teamId = useSelector((state) => state.team.teamId)
	const sourceProps = variable?.sourceProps
	const breakdown = useSelector((state) => state.modelData.breakdown[aid])
	const loadKey = `selectedVariable#${varId}`
	const selectVariable = useSelector((state) => state.model.selectedVariable[loadKey]?.data)
	// #### DEFAULT VALUES
	const defaultForm = {
		selectVariable: '',
		modelId: '',
		varProps: {},
		categories: {},
		type: 'variable'
	}

	const defaultValidation = {
		selectVariable: { valid: true, error: '' },
	}

	// #### STATE
	const [openPanel, setOpenPanel] = useState('connection')
	const [form, setForm] = useState(defaultForm)
	const [validation, setValidation] = useState(defaultValidation)
	
	// #### EFFECTS
	useEffect(() => {
		setForm({
			...form,
			modelId: selectVariable?.modelId,
			type: 'variable',
			varProps: selectVariable?.varProps,
			categories: selectVariable?.categories,
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectVariable])

	useEffect(() => {
		setForm({
			selectVariable: sourceProps?.selectVariable || defaultForm.selectVariable,
			modelId: sourceProps?.modelId || defaultForm.modelId,
			type: 'variable',
			varProps: sourceProps?.varProps || defaultForm.varProps,
			categories: sourceProps?.categories || defaultForm.categories,
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceProps])

	// #### FUNCTIONS
	function onChange(key, value) {
		if (form[key] === value) return
		setForm({ ...form, [key]: value })
	}

	function onConnect() {
		let variableValid = validateNotEmpty(form.selectVariable)
		setValidation({ ...validation, selectVariable: variableValid })
		if (variableValid.valid) {
			const content = { sourceProps: { ...form }}
			dispatch(connectVariable({ tid, teamId, aid, id: varId, content }))
			onClose()
		}
	}

	function onDisconnect() {
		dispatch(disconnectVariable({ tid, teamId, aid, id: varId }))
		if (breakdown && varId in breakdown) dispatch(clearVariableBreakdown({ id: aid, variableId: varId }))
		onClose()
	}

	function togglePanel(key) {
		if (openPanel === key) setOpenPanel(null)
		else setOpenPanel(key)
	}
	return (
		<>
			<div className="flex-1 px-4 text-sm mt-4">
				<Accordion expanded={openPanel === 'connection'} onChange={() => togglePanel('connection')}>
					<AccordionSummary className="text-sm m-0 min-h-0" classes={{ content: 'my-2 flex flex-col' }} expandIcon={<ExpandMoreIcon fontSize="small" />}>
						{t('model:connectData.connection')}
						<div className="text-xs text-textGray">{t('model:connectData.connectionDesc', {entity: 'Variable'})}</div>
					</AccordionSummary>
					<AccordionDetails>
						<ModelTypeRefVariable 
                            aid={aid}
							loadKey={loadKey}
                            teamId={teamId} 
                            onChange={(value) => onChange('selectVariable', value)}
						    value={form.selectVariable}
                            validation={validation} 
                            fieldName={t('model:connectData.selectVariable')}
                            showLabel={true}
                        />
						
					</AccordionDetails>
				</Accordion>
			</div>

			<div className="px-4 mt-4 flex flex-row items-center">
				{sourceProps && sourceProps?.type === 'variable' && (
					<Button onClick={onDisconnect} size="small" className="buttonOutlinedGray mr-2 text-black flex-1" variant="outlined">
						{t('model:buttons.disconnect')}
					</Button>
				)}
				<Button onClick={onConnect} size="small" className="buttonContainedContrast flex-1">
					{sourceProps && sourceProps?.type === 'variable' ? t('common:buttons.update') : t('model:buttons.connect')}
				</Button>
			</div>
		</>
	)
}
