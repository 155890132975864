import { useTranslation } from 'react-i18next'
import Layout from 'common/screens/layout/Layout'
import TeamMenu from 'common/screens/team/TeamMenu'
import { COMMUNITY } from 'common/constants/products'
import { Avatar } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import CommunityAssets from 'common/screens/community/CommunityAssets'
import React from 'react'
import { useTeamPermits } from 'common/hooks/useTeamPermits'
import useNavigate from 'common/hooks/useNavigate'
import GenericBar from '../layout/GenericBar'

export default function Community() {
	const { t } = useTranslation('common')
	const { canCreate } = useTeamPermits()
	const { navigate } = useNavigate()

	// #### FUNCTIONS
	function onCreate(type) {
		navigate(`${type}`)
	}

	return (
		<Layout menuContent={TeamMenu} barContent={GenericBar}>
			{/* <Breadcrumbs /> */}
			<div className="mx-4 my-6 mb-8 text-3xl">{t('common:community.shareWithCommunity')}</div>

			{/* Publish new content */}
			{canCreate && (
				<div className="mx-4 mb-8 grid grid-cols-cards gap-x-[32px] gap-y-[16px] items-end">
					{[COMMUNITY.template, COMMUNITY.data].map((product) => {
						return (
							<div key={product.key}>
								<div className="mb-2 text-xs text-textGray uppercase">{product.header && t(product.header)}</div>
								<div className="flex flex-row justify-between items-center paper cursor-pointer p-4 hover:bg-bgGray" onClick={(e) => onCreate(product.key)}>
									<div className="flex flex-row items-center overflow-hidden">
										<Avatar className={`h-[30px] w-[30px] mr-4 ${product.theme} bg-[var(--bar-bg)]`} variant="rounded">
											{React.createElement(product.icon, { className: 'text-[20px]' })}
										</Avatar>
										<div>
											<div className="text-sm font-medium">
												<>
													<span>{t('common:community.publish')}&nbsp;</span>
													<span className="lowercase">{t(product.name)}</span>
												</>
											</div>
											<div className="text-xs text-textGray leading-none truncate">{t(product.description)}</div>
										</div>
									</div>
									<AddIcon size="small" className="text-textGray" />
								</div>
							</div>
						)
					})}
				</div>
			)}

			{/* Published assets */}
			<CommunityAssets />
		</Layout>
	)
}
