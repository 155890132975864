import clsx from 'clsx'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ModelRelationship from 'model/screens/breakdown/ModelRelationship'
import { Alert } from '@mui/material'

export default function ModelRelationships({ aid, onClose }) {
	const { t } = useTranslation(['common', 'model'])

	// #### REDUX
	const globalRelationships = useSelector((state) => state.team.teamRelationships?.result)
	const globalCategories = useSelector((state) => state.team.teamCategories?.result)

	// #### STATE
	const [isOpen, setIsOpen] = useState('new')

	function onDelete() {
		setIsOpen('new')
	}

	return (
		<>
			<Alert className="py-0 text-xs mb-4 " classes={{ message: 'flex flex-row items-center' }} severity="info">
				{t('model:relationships.help')}
			</Alert>
			<div className="flex flex-row">
				<div className="flex-1 shrink-0 mr-2">
					{globalRelationships &&
						Object.entries(globalRelationships)?.map((entry, index) => {
							const isRelOpen = isOpen === entry[0]
							return (
								<div
									key={`relList#${index}`}
									className={clsx('flex flex-row justify-between items-center px-4 bg-bgGray rounded cursor-pointer hover:bg-borderGray mb-3', isRelOpen && 'bg-borderGray')}
									onClick={() => setIsOpen(entry[0])}
								>
									<div className={clsx('text-sm py-2 truncate', isRelOpen && 'font-medium')}>
										{entry[1]?.mapping?.map((el, index) => `${index > 0 ? ' - ' : ''}${globalCategories[el.category]?.name}`)}
									</div>
									{isRelOpen && <ChevronRightIcon fontSize="small" className="text-textGray" />}
								</div>
							)
						})}

					<div
						className={clsx('flex flex-row justify-between items-center px-4 bg-bgGray rounded cursor-pointer hover:bg-borderGray', isOpen === 'new' && 'bg-borderGray')}
						onClick={() => setIsOpen('new')}
					>
						<div className={clsx('text-sm py-2', isOpen === 'new' && 'font-medium')}>{t('model:relationships.new')}</div>
						{isOpen === 'new' && <ChevronRightIcon fontSize="small" className="text-textGray" />}
					</div>
				</div>

				<div className="ml-2 flex-1 shrink-0 border-l border-borderGray pl-4">
					<ModelRelationship relKey={isOpen} aid={aid} onDelete={onDelete} />
				</div>
			</div>
		</>
	)
}
