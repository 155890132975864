import { createSlice } from '@reduxjs/toolkit'
import { isEqual } from 'lodash'
import { updateDiff } from 'common/store/helpers/diffHelper'
import { searchResults } from 'common/store/helpers/searchHelper'

export const modelSlice = createSlice({
	name: 'model',
	initialState: {
		// Data from firestore
		model: {},
		variables: {},
		// Local data
		rows: {},
		tabIndex: {},
		isDesigning: {},
		// Local data initialized from firestore
		isInitial: {},
		// Visualization properties (local data)
		renderProps: {
			// tabGroups: {},
			// groupVariables: {},
			// canGroupOpen: {},
			// canVarOpen: {},
			// categoryTables: {}
		},
		search: {},
		selectedVariable: {},
	},
	reducers: {
		loadModel(state, action) {
			const isI = isInitial(state.model, action)
			// Update in every load
			state.model[action.payload.id] = { loaded: true, ...action.payload }
			state.tabIndex[action.payload.id] = state.tabIndex[action.payload.id] || 0
			// Update visualization properties
			state.renderProps[action.payload.id] = state.renderProps[action.payload.id] || {}
			state.renderProps[action.payload.id].tabGroups = updateRenderProps({
				prev: state.renderProps[action.payload.id].tabGroups || [],
				next: action.payload.data?.tabs[state.tabIndex[action.payload.id] || 0]?.groups
			})
			state.renderProps[action.payload.id].groupVariables =
				action.payload.data?.tabs?.length > 0
					? updateRenderProps({
							prev: state.renderProps[action.payload.id].groupVariables || {},
							next: Object.fromEntries(action.payload.data?.tabs[state.tabIndex[action.payload.id] || 0]?.groups?.map((gId) => [gId, action.payload.data?.groups[gId]?.variables]))
					  })
					: null
			state.renderProps[action.payload.id].canGroupOpen = updateRenderProps({
				prev: state.renderProps[action.payload.id].canGroupOpen || {},
				next: action.payload.data?.groups ? Object.fromEntries(Object.values(action.payload.data?.groups)?.map((group) => [group.id, group.variables?.length > 0])) : {}
			})
			state.renderProps[action.payload.id].openGroups = updateRenderProps({
				prev: state.renderProps[action.payload.id].openGroups || {},
				next: action.payload.data?.groups ? Object.fromEntries(Object.values(action.payload.data?.groups)?.map((group) => [group.id, true])) : {},
				update: isI
			})
			state.renderProps[action.payload.id].categoryTables = updateRenderProps({
				prev: state.renderProps[action.payload.id].categoryTables || {},
				next: action.payload.data?.catProps?.categories ? Object.fromEntries(Object.values(action.payload.data?.catProps?.categories)?.map((cat) => [cat.selectTable, true])) : {}
			})
		},
		cancelModel: (state, action) => {
			state.model[action.payload.id] = null
			state.isDesigning[action.payload.id] = null
		},
		calculateRows: (state, action) => {
			state.rows[action.payload.id] = action.payload.rows
		},
		loadVariables(state, action) {
			const isI = isInitial(state.variables, action)
			// Update variables
			state.variables[action.payload.id] = state.variables[action.payload.id] || {}
			state.variables[action.payload.id].id = action.payload.id
			state.variables[action.payload.id].loaded = true
			state.variables[action.payload.id].notFound = action.payload.notFound
			state.variables[action.payload.id].result = state.variables[action.payload.id].result || {}
			updateDiff(state.variables[action.payload.id].result, action.payload.result, [])

			// Update render props
			state.renderProps[action.payload.id] = state.renderProps[action.payload.id] || {}
			state.renderProps[action.payload.id].canVarOpen = state.renderProps[action.payload.id].canVarOpen || {}
			state.renderProps[action.payload.id].canVarOpen = updateRenderProps({
				prev: state.renderProps[action.payload.id].canVarOpen || {},
				next: action.payload.result ? Object.fromEntries(Object.values(action.payload.result)?.map((variable) => [variable.id, variable.categories?.length > 0])) : {}
			})

			state.renderProps[action.payload.id].openVariables = state.renderProps[action.payload.id].openVariables || {}
			state.renderProps[action.payload.id].openVariables = updateRenderProps({
				prev: state.renderProps[action.payload.id].openVariables || {},
				next: action.payload.result ? Object.fromEntries(Object.values(action.payload.result)?.map((variable) => [variable.id, false])) : {},
				update: isI
			})
		},
		cancelVariables: (state, action) => {
			state.variables[action.payload.id] = null
		},
		selectTab: (state, action) => {
			state.tabIndex[action.payload.id] = action.payload.tabIndex
			// Update visualization properties
			state.renderProps[action.payload.id] = state.renderProps[action.payload.id] || {}
			state.renderProps[action.payload.id].tabGroups = updateRenderProps({
				prev: state.renderProps[action.payload.id].tabGroups || [],
				next: state.model[action.payload.id]?.data?.tabs[action.payload.tabIndex]?.groups
			})
			state.renderProps[action.payload.id].groupVariables = updateRenderProps({
				prev: state.renderProps[action.payload.id].groupVariables || {},
				next: Object.fromEntries(state.model[action.payload.id]?.data?.tabs[action.payload.tabIndex]?.groups?.map((gId) => [gId, state.model[action.payload.id]?.data?.groups[gId]?.variables]))
			})
		},
		toggleDesignMode: (state, action) => {
			state.isDesigning[action.payload.id] = state.isDesigning[action.payload.id] ? false : true
		},
		stopDesignMode: (state, action) => {
			state.isDesigning[action.payload.id] = false
		},
		toggleGroup: (state, action) => {
			state.renderProps[action.payload.id].openGroups = state.renderProps[action.payload.id].openGroups || {}
			state.renderProps[action.payload.id].openGroups[action.payload.groupId] = action.payload.isOpen
		},
		searchVariables: (state, action) => {
			state.search[action.payload.key] = searchResults(state.search[action.payload.key], action.payload)
		},
		cancelSearchVariables: (state, action) => {
			state.search[action.payload.key] = null
		},
		loadSelectedVariable: (state, action) => {
			state.selectedVariable[action.payload.key] = { loaded: true, ...action.payload }
		},
		cancelSelectedVariable: (state, action) => {
			state.selectedVariable[action.payload.key] = null
		}
	}
})

export const { loadModel, cancelModel, calculateRows, loadVariables, cancelVariables, selectTab, 
	toggleDesignMode, stopDesignMode, toggleGroup, searchVariables, cancelSearchVariables, 
	loadSelectedVariable, cancelSelectedVariable } = modelSlice.actions

export default modelSlice.reducer

function isInitial(state, action) {
	return !state[action.payload.id]?.loaded
}

function updateRenderProps({ prev, next, update = true }) {
	return isEqual(prev, next) || !update ? prev : next
}
