import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import AssetFilters from 'common/components/search/AssetFilters'
import AddIcon from '@mui/icons-material/Add'
import { Button, IconButton, Menu, MenuItem, TextField } from '@mui/material'
import { useMemo, useState } from 'react'
import AssetSort from 'common/components/search/AssetSort'
import { useAssetPermits } from 'common/hooks/useAssetPermits'
import { setInView } from 'common/saga-actions/viewActions'
import * as viewSel from 'common/store/viewSelector'
import * as modelSel from 'model/store/modelSelector'

export default function ModelSearch({ aid }) {
	const { t } = useTranslation('common')
	const dispatch = useDispatch()
	const permits = useAssetPermits(aid)

	// #### REDUX
	const selectCategoriesForSearch = useMemo(() => modelSel.makeSelectModelCategoriesForSearch({ aid }), [aid])
	const categories = useSelector((state) => selectCategoriesForSearch(state))
	const isLoaded = useSelector((state) => state.model.model[aid]?.loaded) || false
	const filter = useSelector((state) => viewSel.selectViewFilter(state, aid))
	const sort = useSelector((state) => viewSel.selectViewSort(state, aid))
	const itemsPerPage = useSelector((state) => viewSel.selectViewItemsPerPage(state, aid))
	const noCategories = !categories || Object.keys(categories)?.length <= 0

	// #### STATE
	const [isAddFilterOpen, setIsAddFilterOpen] = useState(false)
	const [isAddSortOpen, setIsAddSortOpen] = useState(false)
	const [itemsPerPageMenu, setItemsPerPageMenu] = useState(null)

	// #### FUNCTIONS
	function onChange(attribute, value) {
		dispatch(setInView({ aid, changes: [{ path: attribute, value }], permits }))
	}

	return (
		<>
			<div className="flex flex-col p-4 border-b border-b-borderGray">
				<div className="flex flex-row items-center justify-between">
					<span className="text-xs font-medium text-textGray uppercase">{t('model:search.filters')}</span>
					{!noCategories && (
						<IconButton size="small" onClick={() => setIsAddFilterOpen(!isAddFilterOpen)} className={isAddFilterOpen ? 'text-primary' : 'text-textGray'}>
							<AddIcon fontSize="small" />
						</IconButton>
					)}
				</div>

				{noCategories ? (
					<span className="text-xs pt-2">{t('model:search.noCatToFilter')}</span>
				) : (
					<AssetFilters
						aid={aid}
						variables={categories}
						isLoaded={isLoaded}
						isAddOpen={isAddFilterOpen}
						filters={filter}
						onChange={(filter) => onChange('filter', filter)}
						onCloseAdd={() => setIsAddFilterOpen(false)}
						saveOnBlur={true}
					/>
				)}
			</div>
			<div className="flex flex-col p-4 border-b border-b-borderGray">
				<div className="flex flex-row items-center justify-between">
					<span className="text-xs font-medium text-textGray uppercase">{t('model:search.sort')}</span>
					{!noCategories && (
						<IconButton size="small" onClick={() => setIsAddSortOpen(!isAddSortOpen)} className={isAddSortOpen ? 'text-primary' : 'text-textGray'}>
							<AddIcon fontSize="small" />
						</IconButton>
					)}
				</div>

				{noCategories ? (
					<span className="text-xs pt-2">{t('model:search.noCatToSort')}</span>
				) : (
					<AssetSort
						aid={aid}
						variables={categories}
						isLoaded={isLoaded}
						isAddOpen={isAddSortOpen}
						sort={sort}
						onChange={(sort) => onChange('sort', sort)}
						onCloseAdd={() => setIsAddSortOpen(false)}
					/>
				)}
			</div>

			<div className="flex flex-col p-4 border-b border-b-borderGray">
				<div className="flex flex-row items-center justify-between">
					<span className="text-xs font-medium text-textGray uppercase">{t('model:search.pagination')}</span>
				</div>

				{noCategories ? (
					<span className="text-xs pt-2">{t('model:search.noCatToPaginate')}</span>
				) : (
					<>
						<div className="mt-6 flex flex-row items-center justify-between">
							<div className="text-sm mr-1">{t('model:search.itemsPerPage')}</div>
							<Button size="small" className="buttonTextPrimary text-sm h-[20px]" onClick={(e) => setItemsPerPageMenu(e.currentTarget)}>
								{itemsPerPage}
							</Button>
							<Menu anchorEl={itemsPerPageMenu} open={Boolean(itemsPerPageMenu)} onClose={(e) => setItemsPerPageMenu(null)}>
								{[10, 20, 40, 50, 100, 300].map((num) => (
									<MenuItem
										key={`pageSize#${num}`}
										value={num}
										className="text-sm"
										onClick={() => {
											onChange('pagination.itemsPerPage', num)
											setItemsPerPageMenu(null)
										}}
									>
										{num}
									</MenuItem>
								))}
							</Menu>
						</div>
					</>
				)}
			</div>
		</>
	)
}
