// #### DIRECT SELECTORS
// Status
export const selectModelIsVizReady = (state, aid) => state.modelData.isVizReady[aid] || false
export const selectModelIsEngineReady = (state, aid) => state.modelData.isEngineReady[aid] || false
// Data
export const selectModelData = (state, aid) => state.modelData.data[aid]
export const selectModelBreakdown = (state, aid) => state.modelData.breakdown[aid]
export const selectModelBreakdownCount = (state, aid) => state.modelData.breakdownCount[aid]
export const selectModelErrors = (state, aid) => state.modelData.errors[aid]
// Chart Data
export const selectChartData = (state, aid, chartId) => (state.modelData.chartData[aid] ? state.modelData.chartData[aid][chartId] : null)
