import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { CircularProgress, IconButton, MenuItem, Dialog, DialogTitle, DialogContent, OutlinedInput, InputAdornment, TextField, Avatar, Divider, FormHelperText, Button } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { ASSET_ROLES as DEFAULT_ROLES, TEAM_ROLES } from 'common/constants/roles'
import { validateEmail } from 'common/utils/validate'
import LoadingButton from '@mui/lab/LoadingButton'
import clsx from 'clsx'
import PeopleIcon from '@mui/icons-material/PeopleOutlined'
import LinkIcon from '@mui/icons-material/Link'
import CodeIcon from '@mui/icons-material/Code'
import LockIcon from '@mui/icons-material/LockOutlined'
import PublicIcon from '@mui/icons-material/PublicOutlined'
import ContentCopy from '@mui/icons-material/ContentCopy'
import { sortArrayOfObjects } from 'common/utils/arrays'
import { showMessage } from 'common/saga-actions/globalActions'
import { useDispatch, useSelector } from 'react-redux'

export default function Share({
	isOpen,
	onClose,
	product,
	name,
	users,
	teamName,
	loadingInvite,
	loadingUpdate,
	loadingGeneralUpdate = false,
	onInviteUser,
	onUpdateUser,
	onDeleteUser,
	isEmbedCodeAllowed = false,
	isPublicAccessAllowed = false,
	publicAccess,
	onGeneralUpdate,
	roles = DEFAULT_ROLES,
	isAdmin = false
}) {
	const { t } = useTranslation('common')
	const dispatch = useDispatch()

	// #### REDUX
	const updateErrors = useSelector((state) => state.global.message?.updateUserRoleError)

	// #### DEFAULT VALUES
	const defaultForm = {
		email: '',
		role: roles.view.key
	}

	const defaultValidation = {
		email: { valid: true, error: '' },
		role: { valid: true, error: '' }
	}

	// #### STATE
	const [form, setForm] = useState(defaultForm)
	const [validation, setValidation] = useState(defaultValidation)
	const [isOpenEmbedCode, setIsOpenEmbedCode] = useState(false)
	const [code, setCode] = useState('')

	useEffect(() => {
		setForm(defaultForm)
		setValidation(defaultValidation)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen])

	// #### FUNCTIONS
	function onChange(key, text) {
		setForm({ ...form, [key]: text })
	}

	function onInvite() {
		let emailValid = validateEmail(form.email)
		// check if user is already a member
		if (emailValid.valid && users !== undefined && users !== null)
			Object.values(users).forEach((teamUser) => {
				if (teamUser.email === form.email && teamUser.status !== 'deleted') emailValid = { valid: false, error: 'error.user_already_member' }
			})
		setValidation({ ...validation, email: emailValid })
		if (emailValid.valid) {
			onInviteUser({ email: form.email, role: form.role })
			setForm(defaultForm)
		}
	}

	function onUpdate(userId, role) {
		const prevRole = users[userId].role
		if (prevRole === role) return
		if (prevRole === TEAM_ROLES.admin.key && Object.values(users)?.filter((user) => user.role === TEAM_ROLES.admin.key)?.length <= 1) {
			dispatch(showMessage({ key: 'updateUserRoleError', item: userId, content: t('common:error.teamAdmin'), messageType: 'error' }))
			return
		}
		if (role === 'remove') onDeleteUser({ userId })
		else if (role) onUpdateUser({ userId, role })
	}

	function onGeneralAccessUpdate(isPublic, role) {
		onGeneralUpdate({ isPublic, role })
	}

	async function copyLink() {
		await navigator.clipboard.writeText(window.location.href)
	}

	async function copyCode() {
		await navigator.clipboard.writeText(code)
	}

	async function openEmbeded() {
		const url = `${window.location.origin}/embed${window.location.pathname}`
		const code = `<iframe style="border: 1px solid rgba(0, 0, 0, 0.1);" width="800" height="450" 
src="${url}" allowfullscreen></iframe>`
		setCode(code)
		setIsOpenEmbedCode(true)
		onClose()
	}

	function onCloseEmbedCode() {
		setIsOpenEmbedCode(false)
	}

	return (
		<>
			<Dialog onClose={onClose} open={isOpen} maxWidth="sm" fullWidth={true}>
				<DialogTitle className="mb-2">
					<span className="text-lg">{t('common:buttons.share')}&nbsp;</span>
					<span className="lowercase text-lg">{product}&nbsp;</span>
					<span className="text-lg">{name}</span>
					<IconButton size="small" className="absolute top-4 right-4 text-textGray" onClick={onClose}>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
				<DialogContent>
					<div className="flex flex-row items-center">
						<OutlinedInput
							variant="outlined"
							margin="dense"
							size="small"
							fullWidth
							placeholder={t('common:team.email')}
							onChange={(event) => onChange('email', event.target.value)}
							value={form.email}
							error={!validation.email.valid}
							inputProps={{ className: 'text-sm' }}
							endAdornment={
								<InputAdornment position="end">
									<TextField
										size="small"
										select
										variant="standard"
										value={form.role}
										InputProps={{ disableUnderline: true, classes: { input: 'p-0 text-sm' } }}
										onChange={(event) => onChange('role', event.target.value)}
									>
										{Object.keys(roles).map((key) => {
											if (!isAdmin && key === TEAM_ROLES.admin.key) return void 0
											return (
												<MenuItem key={key} value={key} className="text-sm">
													{t(roles[key]?.label)}
												</MenuItem>
											)
										})}
									</TextField>
								</InputAdornment>
							}
						/>
						<LoadingButton
							loading={loadingInvite}
							size="small"
							variant="contained"
							className="ml-2 buttonContainedContrast"
							onClick={onInvite}
							loadingIndicator={<CircularProgress size={20} className="text-white" />}
						>
							{t('common:buttons.invite')}
						</LoadingButton>
					</div>
					<FormHelperText className="text-xs" error={!validation.email.valid}>
						{!validation.email.valid && t(validation.email.error, { field: t('common:team.email') })}
					</FormHelperText>

					<div className="font-medium mb-4 mt-6 text-sm">{t('common:share.people')}</div>
					{teamName && (
						<div className="flex flex-row items-center mb-4">
							<Avatar className="mr-4 text-xs w-[25px] h-[25px] uppercase bg-primary">
								<PeopleIcon className="w-[20px] h-[20px]" />
							</Avatar>
							<span className="lowercase first-letter:uppercase text-sm">{t('common:share.teamAccess', { team: teamName, product })}</span>
						</div>
					)}
					{users &&
						sortArrayOfObjects(Object.entries(users), (el) => (el[1].name || 'zzz') + (el[1].surname || 'zzz') + el[1].email)?.map((entry) => {
							const userId = entry[0]
							const user = entry[1]
							const isPending = user.status === 'pending'
							const isLoading = (loadingUpdate && loadingUpdate[userId]) || false
							const updateError = (updateErrors && updateErrors[userId]) || null
							const isAdminSelected = user?.role === TEAM_ROLES.admin.key

							if (user.status === 'deleted') return null
							return (
								<div key={'share#' + userId} className="flex flex-col mb-4">
									<div className="flex flex-row items-center justify-between">
										<div className="flex flex-row items-center text-sm">
											<Avatar className={clsx('mr-4 text-xs w-[25px] h-[25px] uppercase bg-primary', isPending ? 'bg-borderGray' : '')}>{(user?.name || user?.email).charAt(0)}</Avatar>
											<span className={isPending ? 'text-textGray' : ''}>{user.name ? user?.name + ' ' + (user?.surname || '') : user?.email}</span>
										</div>

										{isLoading ? (
											<CircularProgress size={15} className="text-textGray mr-8" />
										) : (
											<TextField
												size="small"
												select
												variant="standard"
												value={user?.role}
												InputProps={{ disableUnderline: true, classes: { input: 'p-0 text-sm' } }}
												onChange={(event) => onUpdate(userId, event.target.value)}
												disabled={isAdminSelected && !isAdmin}
											>
												{Object.keys(roles).map((key) => {
													if (!isAdminSelected && !isAdmin && key === TEAM_ROLES.admin.key) return void 0
													return (
														<MenuItem key={key} value={key} className="text-sm">
															{t(roles[key]?.label)}
														</MenuItem>
													)
												})}
												<Divider />
												<MenuItem key="remove" value="remove" className="text-sm">
													{t('common:buttons.remove')}
												</MenuItem>
											</TextField>
										)}
									</div>
									{updateError && <div className="text-xs text-red-600 self-end">{updateError?.content}</div>}
								</div>
							)
						})}

					{isPublicAccessAllowed && (
						<>
							<div className="font-medium mb-4 mt-6 text-sm">{t('common:share.general')}</div>
							<div className="flex flex-row items-center mb-4 justify-between">
								<div className="flex flex-row items-center">
									{loadingGeneralUpdate ? (
										<CircularProgress size={15} className="text-textGray mr-4" />
									) : (
										<Avatar className="mr-4 text-xs w-[25px] h-[25px] uppercase bg-primary">
											{publicAccess?.isPublic ? <PublicIcon className="w-[20px] h-[20px]" /> : <LockIcon className="w-[20px] h-[20px]" />}
										</Avatar>
									)}
									<TextField
										size="small"
										select
										variant="standard"
										value={publicAccess?.isPublic || false}
										InputProps={{ disableUnderline: true, classes: { input: 'p-0 text-sm' } }}
										onChange={(e) => onGeneralAccessUpdate(e.target.value, roles.view.key)}
									>
										<MenuItem value={false} className="text-sm">
											{t('common:share.generalRestricted')}
										</MenuItem>
										<MenuItem value={true} className="text-sm">
											{t('common:share.generalPublic')}
										</MenuItem>
									</TextField>
								</div>
								{publicAccess?.isPublic && (
									<TextField
										size="small"
										select
										variant="standard"
										value={publicAccess?.role || roles.view?.key}
										InputProps={{ disableUnderline: true, classes: { input: 'p-0 text-sm' } }}
										onChange={(e) => onGeneralAccessUpdate(true, e.target.value)}
									>
										<MenuItem value={roles.view?.key} className="text-sm">
											{t(roles.view?.label)}
										</MenuItem>
										{/* <MenuItem value={roles.edit?.key} className="text-sm">
											{t(roles.edit?.label)}
										</MenuItem> */}
									</TextField>
								)}
							</div>
						</>
					)}

					<Divider className="mt-6 mb-2" />
					<div className="flex flex-row items-center justify-between">
						<Button size="small" variant="text" className="buttonTextPrimary normal-case text-xs" onClick={() => copyLink()} startIcon={<LinkIcon className="text-textGray" />}>
							{t('common:share.copyLink')}
						</Button>
						{isEmbedCodeAllowed && (
							<Button size="small" variant="text" className="buttonTextPrimary normal-case text-xs" onClick={() => openEmbeded()} startIcon={<CodeIcon className="text-textGray" />}>
								{t('common:share.embedCode')}
							</Button>
						)}
					</div>
				</DialogContent>
			</Dialog>
			<Dialog onClose={onCloseEmbedCode} open={isOpenEmbedCode} maxWidth="sm" fullWidth={true}>
				<DialogTitle>
					<span>{t('common:share.copyPublicEmbedCode')}</span>
					<IconButton size="small" className="absolute top-4 right-4 text-textGray" onClick={onCloseEmbedCode}>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
				<DialogContent>
					<div className="flex flex-row items-center">
						<TextField disabled={true} fullWidth value={code} multiline inputProps={{ classes: { input: 'p-0 text-sm' } }} />
					</div>

					<Divider className="mt-6 mb-2" />
					<div className="flex justify-end">
						<Button size="small" variant="text" className="buttonTextPrimary normal-case" startIcon={<ContentCopy className="text-textGray" />} onClick={() => copyCode()}>
							{t('common:share.copy')}
						</Button>
					</div>
				</DialogContent>
			</Dialog>
		</>
	)
}
