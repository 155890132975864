import { Button, TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { generateKey } from 'common/utils/uuid'
import { DATA_TYPES } from 'common/constants/dataTypes'
import { MODEL_VAR_TYPES } from 'model/constants/modelTypes'
import { changeType } from 'table/saga-actions/tableActions'
import { updateVariable } from 'model/saga-actions/modelActions'
import ActionConfirm from 'common/components/ActionConfirm'
import TableTypeRefTable from './TableTypeRefTable'
import TableTypeRefVariable from './TableTypeRefVariable'
import { loadSelectedTable } from 'table/saga-actions/tableActions'
import { cancelSelectedTable } from 'table/store/tableReducer'
import { validateNotEmpty } from 'common/utils/validate'

export default function TableTypeSelection({ aid, varId, variable, variables, isModelVersion = false, onChangeVarId, onClose }) {
	const dispatch = useDispatch()
	const { t } = useTranslation(['common', 'table'])
	const VAR_TYPES = isModelVersion ? MODEL_VAR_TYPES : DATA_TYPES
	const loadKey = `typeSelection#${aid}`

	// #### REDUX
	const tid = useSelector((state) => state.auth.tid)
	const teamId = useSelector((state) => state.asset.asset[aid]?.data?.teamId)
	const table = useSelector((state) => state.table.selected[loadKey])

	// #### DEFAULT VALUES
	const defaultForm = {
		type: null,
		selectTable: '',
		selectVariable: ''
	}

	const defaultValidation = {
		type: { valid: true, error: '' },
		selectTable: { valid: true, error: '' },
		selectVariable: { valid: true, error: '' }
	}

	// #### STATE
	const [form, setForm] = useState(defaultForm)
	const [validation, setValidation] = useState(defaultValidation)
	const [types, setTypes] = useState([])
	const [inputValue, setInputValue] = useState('')
	const [isConfirmOpen, setIsConfirmOpen] = useState(false)
	const [disabledTypes, setDisabledTypes] = useState({})

	// #### EFFECTS
	// Convert types to list
	useEffect(() => {
		const newTypes = Object.keys(VAR_TYPES).map((key) => VAR_TYPES[key])
		setTypes(newTypes)
	}, [VAR_TYPES])

	useEffect(() => {
		var newDisabledTypes = {}
		Object.values(variables)?.map((variable) => {
			if (VAR_TYPES[variable.type]?.isMetadata) newDisabledTypes[variable.type] = true
			return void 0
		})
		setDisabledTypes(newDisabledTypes)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [variables])

	useEffect(() => {
		const lKey = loadKey
		if (form?.selectTable) dispatch(loadSelectedTable({ key: lKey, tid, aid: form.selectTable }))
		return () => dispatch(cancelSelectedTable({ key: lKey }))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form?.selectTable])

	// Load initial value
	useEffect(() => {
		initialize()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [variable])

	// #### FUNCTIONS
	function initialize() {
		if (variable) {
			const type = VAR_TYPES[variable.type]
			setForm({ type, selectTable: variable.typeProps?.selectTable || '', selectVariable: variable.typeProps?.selectVariable || '' })
			setInputValue(VAR_TYPES[variable.type].label)
		}
	}

	function executeChange() {
		if (form.type.key === variable.type && form.selectTable === variable.typeProps?.selectTable && form.selectVariable === variable.typeProps?.selectVariable) {
			onClose()
			return
		}
		const typeDestId = form.type.key
		const typeOriginId = variable.type
		const propsOrigin = VAR_TYPES[typeOriginId]
		const propsDest = VAR_TYPES[typeDestId]
		const isOriginMetadata = propsOrigin?.isMetadata
		const isDestMetadata = propsDest?.isMetadata
		const typePropsOrigin = variable.typeProps
		let typePropsDest = propsDest.defaultProps ? { ...propsDest.defaultProps } : {}
		if (typeDestId === DATA_TYPES.reference.key) typePropsDest = { ...typePropsDest, selectTable: form.selectTable, selectVariable: form.selectVariable }

		if (isModelVersion) dispatch(updateVariable({ tid, aid, vid: varId, content: { type: typeDestId, typeProps: typePropsDest } }))
		else {
			const varIdDest = isDestMetadata ? propsDest.metadataId : isOriginMetadata ? generateKey(6) : varId
			dispatch(changeType({ tid, teamId, aid, varIdOrigin: varId, varIdDest, typeOrigin: typeOriginId, typeDestination: typeDestId, typePropsOrigin, typePropsDest }))
			onChangeVarId(varIdDest) // update popper varId
		}
		onClose()
	}

	function onConfirm() {
		executeChange()
		setIsConfirmOpen(false)
	}

	function onButtonClick() {
		let typeValid = validateNotEmpty(form.type)
		let tableValid = { valid: true, error: '' }
		let varValid = { valid: true, error: '' }
		if (form.type?.key === DATA_TYPES.reference.key) {
			tableValid = validateNotEmpty(form.selectTable)
			if (tableValid?.valid) tableValid = table?.notFound ? { valid: false, error: 'error.required' } : { valid: true, error: '' }
			varValid = validateNotEmpty(form.selectVariable)
			if (varValid.valid) varValid = !table?.data?.variables || !table?.data?.variables[form.selectVariable] ? { valid: false, error: t('common:error.required') } : varValid
		}
		setValidation({ ...validation, type: typeValid, selectTable: tableValid, selectVariable: varValid })
		if (typeValid.valid && tableValid.valid && varValid.valid) {
			if (form.type.conversionGroup !== VAR_TYPES[variable.type].conversionGroup) setIsConfirmOpen(true)
			else executeChange()
		}
	}

	function onChange(attribute, value) {
		setForm({ ...form, [attribute]: value })
	}

	return (
		<>
			<Autocomplete
				blurOnSelect={true}
				clearOnBlur={false}
				options={types}
				getOptionLabel={(option) => option.label}
				getOptionDisabled={(option) => disabledTypes[option.key]}
				isOptionEqualToValue={(option, value) => option?.key === value?.key}
				value={form.type}
				inputValue={inputValue}
				onChange={(e, value) => onChange('type', value)}
				onInputChange={(e, value) => setInputValue(value)}
				disablePortal
				renderInput={(params) => (
					<TextField
						{...params}
						variant="outlined"
						fullWidth
						size="small"
						placeholder={t('table:typeSelect.select')}
						inputProps={{ ...params.inputProps, className: 'text-sm px-2 py-1' }}
						error={validation?.type && !validation?.type?.valid}
						helperText={!validation?.type?.valid && t(validation?.type.error, { field: t('table:typeSelect.type') })}
					/>
				)}
				classes={{ root: 'mt-4', listbox: 'max-h-[200px] text-sm' }}
				openOnFocus
				renderOption={(props, option, { selected }) => (
					<li {...props}>
						{React.createElement(option.icon, { className: 'mr-2 text-textGray w-[15px] h-[15px]' })}
						<span>{option.label}</span>
					</li>
				)}
			/>
			<div className="mt-2 text-xs">{form.type?.key && VAR_TYPES[form.type.key].desc}</div>

			{form.type?.key === DATA_TYPES.reference.key && (
				<div className="mt-4 border-t border-t-borderGray pt-6">
					<TableTypeRefTable
						loadKey={loadKey}
						aid={aid}
						teamId={teamId}
						onChange={(value) => onChange('selectTable', value)}
						value={form.selectTable}
						showLabel
						fieldName={t('table:typeSettings.selectTable')}
						validation={validation.selectTable}
					/>

					<div className="mt-4" />
					<TableTypeRefVariable
						aid={aid}
						variables={table?.data?.variables}
						types={[DATA_TYPES.text.key]}
						onChange={(value) => onChange('selectVariable', value)}
						value={form?.selectVariable}
						showLabel
						fieldName={t('table:typeSettings.selectVariable')}
						validation={validation.selectVariable}
					/>
				</div>
			)}
			<div className="flex-1" />
			<ActionConfirm open={isConfirmOpen} content={t('table:typeSelect.warning')} onClose={() => setIsConfirmOpen(false)} onConfirm={onConfirm} confirmButtonLabel={t('common:buttons.continue')} />
			<Button onClick={onButtonClick} size="small" className="mt-4 mb-4 buttonContainedContrast">
				{t('common:buttons.save')}
			</Button>
		</>
	)
}
