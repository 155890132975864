import { db } from 'common/config/firebase'
import { doc, collection, onSnapshot, where as dbWhere, orderBy as dbOrderBy, query as dbQuery } from 'firebase/firestore'
import { eventChannel } from 'redux-saga'
import { error as printError } from 'common/config/errors'

export async function subscribeDoc({ path }) {
	return eventChannel((emit) => {
		// Document path
		const queryPath = path?.reduce((prev, el) => `${prev}/${el.collection}/${el.value}`, '')
		// Create listener
		const listener = onSnapshot(
			doc(db, queryPath),
			(doc) => emit({ id: doc.id, data: doc.data(), notFound: false }),
			(error) => {
				printError(error)
				emit({ id: null, data: null, notFound: true }) // Note: when doc is not found, firestore returns security error
			}
		)
		// Destroy listener
		return () => listener()
	})
}

export async function subscribeCol({ path, where, orderBy, returnType }) {
	return eventChannel((emit) => {
		// Document path
		const queryPath = path?.reduce((prev, el) => (el.value ? `${prev}/${el.collection}/${el.value}` : `${prev}/${el.collection}`), '')
		// Query where
		const queryWhere = where?.map((el) => dbWhere(el.attribute, el.operator, el.value)) || []
		// Query orderBy
		const queryOrderBy = orderBy?.map((el) => dbOrderBy(el.attribute, el.order)) || []
		// Query
		const queryContraints = [...queryWhere, ...queryOrderBy]
		const query = dbQuery(collection(db, queryPath), ...queryContraints)
		// Create listener
		const listener = onSnapshot(
			query,
			(snapshot) => {
				var result = returnType === 'map' ? {} : []
				let notFound = true
				snapshot.forEach((doc) => {
					if (returnType === 'map') result[doc.id] = doc.data()
					else result.push({ id: doc.id, data: doc.data() })
					notFound = false
				})
				emit({ result, notFound })
			},
			(error) => {
				printError(error)
				emit({ result: null, notFound: true })
			}
		)
		// Destroy listener
		return () => listener()
	})
}
