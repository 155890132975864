import { call, takeEvery, put, select, spawn } from 'redux-saga/effects'
import * as api from 'common/api/assetApi'
import * as authApi from 'common/api/authApi'
import * as storeAction from 'common/store/assetReducer'
import * as sagaAction from 'common/saga-actions/assetActions'
import * as subscribeHelper from 'common/saga/helpers/subscribeHelper'
import * as loadHelper from 'common/saga/helpers/loadHelper'
import * as searchHelper from 'common/saga/helpers/searchHelper'
import * as globalStoreAction from 'common/store/globalReducer'
import * as globalSagaAction from 'common/saga-actions/globalActions'
import { generateKeywords } from 'common/saga/helpers/utils'
import { SEARCH_LIMIT } from 'common/constants/search'
import { generateKey } from 'common/utils/uuid'
import { error, throwError } from 'common/config/errors'
import * as tableSagas from 'table/saga/tableSagas'
import * as modelCategorySagas from 'model/saga/modelCategorySagas'
import { PRODUCTS } from 'common/constants/products'

const FILE_NAME = 'assetSagas'

const getUid = (state) => state.auth.uid

// #### PATH TO THE COLLECTION
function defaultUserAssetPath(includeDocId) {
	const path = [
		{ collection: 'tenants', param: 'tid', value: null },
		{ collection: 'users', param: 'uid', value: null },
		{ collection: 'assets', param: includeDocId ? 'aid' : null, value: null }
	]
	return path
}

function defaultAssetPath(includeDocId) {
	const path = [
		{ collection: 'tenants', param: 'tid', value: null },
		{ collection: 'assets', param: includeDocId ? 'aid' : null, value: null }
	]
	return path
}

function defaultUsersPath(includeDocId) {
	const path = [
		{ collection: 'tenants', param: 'tid', value: null },
		{ collection: 'assets', param: 'aid', value: null },
		{ collection: 'users', param: includeDocId ? 'userId' : null, value: null }
	]
	return path
}

// #### SUBSCRIBE TO ASSETS
function* loadAssetsReq() {
	const queryConfig = subscribeHelper.queryConfig({
		path: defaultUserAssetPath(false),
		where: [{ attribute: 'teamId', operator: '==', param: 'teamId', value: null }],
		orderBy: [{ attribute: 'name', order: 'asc' }],
		returnType: 'map'
	})
	const sagaConfig = subscribeHelper.sagaConfig({ loadAction: sagaAction.LOAD_ASSETS, loadResponse: storeAction.loadAssets, cancelAction: sagaAction.CANCEL_ASSETS, cancelResponse: storeAction.cancelAssets })
	yield spawn(subscribeHelper.subscribe, sagaConfig, queryConfig)
}

// #### SUBSCRIBE TO ASSET
function* loadAssetReq() {
	const queryConfig = subscribeHelper.queryConfig({ path: defaultAssetPath(true), returnType: 'doc' })
	const sagaConfig = subscribeHelper.sagaConfig({ loadAction: sagaAction.LOAD_ASSET, loadResponse: storeAction.loadAsset, cancelAction: sagaAction.CANCEL_ASSET, cancelResponse: storeAction.cancelAsset })
	yield spawn(subscribeHelper.subscribe, sagaConfig, queryConfig)
}

// #### SEARCH
function* searchAssetsReq() {
	const queryConfig = searchHelper.queryConfig({
		path: defaultUserAssetPath(false),
		where: [
			{ attribute: 'keywords', operator: 'array-contains', param: 'searchTerm', value: null },
			{ attribute: 'type', operator: 'in', param: 'assetTypes', value: null }
		],
		orderBy: [{ attribute: 'name', order: 'asc' }],
		limit: SEARCH_LIMIT.assets,
		returnType: 'list'
	})
	const sagaConfig = searchHelper.sagaConfig({
		loadAction: sagaAction.SEARCH_ASSETS,
		loadResponse: storeAction.searchAssets
	})
	const msgConfig = searchHelper.msgConfig({ fileName: FILE_NAME, functionName: searchAssetsReq.name })
	yield spawn(searchHelper.search, sagaConfig, queryConfig, msgConfig)
}

function* loadSelectedAssetReq() {
	const queryConfig = loadHelper.queryConfig({ path: defaultUserAssetPath(true) })
	const sagaConfig = loadHelper.sagaConfig({ loadAction: sagaAction.LOAD_SELECTED_ASSET, loadResponse: storeAction.loadSelectedAsset })
	yield spawn(loadHelper.load, sagaConfig, queryConfig)
}

// #### MANAGE ASSET
function* createAssetReq() {
	yield takeEvery(sagaAction.CREATE_ASSET, createAsset)
}

function* createAsset(triggeredAction) {
	const { tid, uid, teamId, content, defaults, view } = triggeredAction
	try {
		yield put(globalStoreAction.loadingOn({ key: 'newAsset', item: content.sid }))
		yield put(globalStoreAction.loadingOn({ key: 'newAsset', item: content.type }))
		const keywords = generateKeywords(content.name)
		const newContent = { ...content, keywords }
		const { token } = yield call(authApi.getAuthToken)
		yield call(api.createAsset, token, tid, uid, teamId, newContent, defaults, view)
	} catch (err) {
		const content = throwError(err, FILE_NAME, createAsset.name)
		yield put(globalSagaAction.showMessage({ content }))
	} finally {
		yield put(globalStoreAction.loadingOff({ key: 'newAsset', item: content.sid }))
		yield put(globalStoreAction.loadingOff({ key: 'newAsset', item: content.type }))
	}
}

export function* createAssetExecute(tid, teamId, uid, content) {
	const keywords = generateKeywords(content.name)
	const newContent = { ...content, keywords }
	const { token } = yield call(authApi.getAuthToken)
	const { data } = yield call(api.createAsset, token, tid, uid, teamId, newContent, null, null)
	return data['assetId']
}

function* updateAssetReq() {
	yield takeEvery(sagaAction.UPDATE_ASSET, updateAsset)
}

function* updateAsset(triggeredAction) {
	const { tid, aid, content, loadingKey } = triggeredAction
	try {
		const uid = yield select(getUid)
		yield put(globalStoreAction.loadingOn({ key: loadingKey, item: aid }))
		let newContent = { ...content }
		if (newContent.name) {
			const keywords = generateKeywords(content.name)
			newContent = { ...newContent, keywords }
		}
		const { token } = yield call(authApi.getAuthToken)
		yield call(api.updateAsset, token, tid, uid, aid, newContent)
	} catch (err) {
		const content = throwError(err, FILE_NAME, updateAsset.name)
		yield put(globalSagaAction.showMessage({ content }))
	} finally {
		yield put(globalStoreAction.loadingOff({ key: loadingKey, item: aid }))
	}
}

function* deleteAssetReq() {
	yield takeEvery(sagaAction.DELETE_ASSET, deleteAsset)
}

function* deleteAsset(triggeredAction) {
	const { tid, aid, assetType } = triggeredAction
	try {
		yield put(globalStoreAction.loadingOn({ key: 'deleteAsset', item: aid }))
		const { token } = yield call(authApi.getAuthToken)
		if (assetType === PRODUCTS.table.key) yield call(tableSagas.disconnectAllTables, { tid, aid })
		else if (assetType === PRODUCTS.model.key) yield call(modelCategorySagas.disconnectAllTables, { tid, aid })
		yield call(api.deleteAsset, token, tid, aid)
	} catch (err) {
		const content = throwError(err, FILE_NAME, deleteAsset.name)
		yield put(globalSagaAction.showMessage({ content }))
	} finally {
		yield put(globalStoreAction.loadingOff({ key: 'deleteAsset', item: aid }))
	}
}

function* copyAssetReq() {
	yield takeEvery(sagaAction.COPY_ASSET, copyAsset)
}

function* copyAsset(triggeredAction) {
	const { tid, teamId, aid } = triggeredAction
	try {
		yield put(globalStoreAction.loadingOn({ key: 'copyAsset', item: aid }))
		const { token } = yield call(authApi.getAuthToken)
		yield call(api.copyAsset, token, tid, teamId, aid)
	} catch (err) {
		const content = throwError(err, FILE_NAME, copyAsset.name)
		yield put(globalSagaAction.showMessage({ content }))
	} finally {
		yield put(globalStoreAction.loadingOff({ key: 'copyAsset', item: aid }))
	}
}

// SUBSCRIBE TO USERS
function* loadAssetUsersReq() {
	const queryConfig = subscribeHelper.queryConfig({
		path: defaultUsersPath(false),
		orderBy: [{ attribute: 'email', order: 'asc' }],
		returnType: 'map'
	})
	const sagaConfig = subscribeHelper.sagaConfig({
		loadAction: sagaAction.LOAD_ASSET_USERS,
		loadResponse: storeAction.loadAssetUsers,
		cancelAction: sagaAction.CANCEL_ASSET_USERS,
		cancelResponse: storeAction.cancelAssetUsers
	})
	yield spawn(subscribeHelper.subscribe, sagaConfig, queryConfig)
}

// #### MANAGE PERMITS
function* inviteUserReq() {
	yield takeEvery(sagaAction.INVITE_USER, inviteUser)
}

function* inviteUser(triggeredAction) {
	const { tid, teamId, uid, aid, email, role } = triggeredAction
	try {
		yield put(globalStoreAction.loadingOn({ key: 'inviteAssetUser' }))
		const { token } = yield call(authApi.getAuthToken)
		yield call(api.inviteUser, token, tid, teamId, uid, aid, email, role)
	} catch (err) {
		const content = throwError(err, FILE_NAME, inviteUser.name)
		yield put(globalSagaAction.showMessage({ content }))
	} finally {
		yield put(globalStoreAction.loadingOff({ key: 'inviteAssetUser' }))
	}
}

function* updateUserReq() {
	yield takeEvery(sagaAction.UPDATE_USER, updateUser)
}

function* updateUser(triggeredAction) {
	const { tid, teamId, aid, userId, role } = triggeredAction
	try {
		yield put(globalStoreAction.loadingOn({ key: 'updateAssetUser', item: userId }))
		const { token } = yield call(authApi.getAuthToken)
		yield call(api.updateUser, token, tid, teamId, aid, userId, role)
	} catch (err) {
		const content = throwError(err, FILE_NAME, updateUser.name)
		yield put(globalSagaAction.showMessage({ content }))
	} finally {
		yield put(globalStoreAction.loadingOff({ key: 'updateAssetUser', item: userId }))
	}
}

function* deleteUserReq() {
	yield takeEvery(sagaAction.DELETE_USER, deleteUser)
}

function* deleteUser(triggeredAction) {
	const { tid, teamId, aid, userId } = triggeredAction
	try {
		yield put(globalStoreAction.loadingOn({ key: 'deleteAssetUser' }))
		const { token } = yield call(authApi.getAuthToken)
		yield call(api.deleteUser, token, tid, teamId, aid, userId)
	} catch (err) {
		const content = throwError(err, FILE_NAME, deleteUser.name)
		yield put(globalSagaAction.showMessage({ content }))
	} finally {
		yield put(globalStoreAction.loadingOff({ key: 'deleteAssetUser' }))
	}
}

// #### UPLOAD SCREENSHOT
function* uploadScreenshotReq() {
	yield takeEvery(sagaAction.UPLOAD_SCREENSHOT, uploadScreenshot)
}

function* uploadScreenshot(triggeredAction) {
	const { tid, teamId, aid, file } = triggeredAction
	try {
		const uid = yield select(getUid)
		const folderPath = `${tid}/screenShot/${teamId}/${aid}`
		const filePath = `${folderPath}/${generateKey(16)}`
		const fileType = file.substring(file.indexOf(':') + 1, file.indexOf(';'))
		const fileContent = file.substring(file.indexOf(',') + 1)
		yield call(api.deleteFolder, folderPath)
		yield call(api.uploadFile, tid, aid, uid, fileContent, filePath, fileType)
	} catch (err) {
		// Background process, not visible to user
		error(err)
	}
}

function* fileTransmitSuccessReq() {
	yield takeEvery(sagaAction.FILE_TRASMIT_SUCCESS, fileTransmitSuccess)
}

function* fileTransmitSuccess(triggeredAction) {
	const { tid, aid, filePath, downloadUrl } = triggeredAction
	try {
		const screenShot = { filePath, fullPath: downloadUrl, updatedAt: new Date() }
		yield put(sagaAction.updateAsset({ tid, aid, content: { screenShot }, loadingKey: 'uploadAssetScreenshot' }))
	} catch (err) {
		// Background process, not visible to user
		error(err)
	}
}

export default function* root() {
	// #### SUBSCRIBE TO ASSETS
	yield spawn(loadAssetsReq)
	// #### SUBSCRIBE TO ASSET
	yield spawn(loadAssetReq)
	// #### SEARCH & DROP-DOWN SELECTION
	yield spawn(searchAssetsReq)
	yield spawn(loadSelectedAssetReq)
	// #### MANAGE TEAMS
	yield spawn(createAssetReq)
	yield spawn(updateAssetReq)
	yield spawn(deleteAssetReq)
	yield spawn(copyAssetReq)
	// SUBSCRIBE TO USERS
	yield spawn(loadAssetUsersReq)
	// #### MANAGE PERMITS
	yield spawn(inviteUserReq)
	yield spawn(updateUserReq)
	yield spawn(deleteUserReq)
	// #### UPLOAD SCREENSHOT
	yield spawn(uploadScreenshotReq)
	yield spawn(fileTransmitSuccessReq)
}
