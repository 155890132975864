import React, { useRef, useLayoutEffect, useEffect } from 'react'
import clsx from 'clsx'

export default function IntegratedTextInput({ value, setValue, placeholder, isDisabled = false, canGrow = false, minWidth = 0, className }) {
	const inputRef = useRef()
	const cancelRef = useRef()

	const parseUserInput = (value) => value.trim() || null
	const formatBlurredInput = (value) => (value ? value : '')

	useLayoutEffect(() => {
		var observer
		const input = inputRef?.current
		if (input && canGrow) {
			observer = new ResizeObserver(onInput)
			observer.observe(input)
		}
		return () => {
			if (observer) observer.unobserve(input)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [canGrow])

	useEffect(() => {
		const input = inputRef?.current
		if (input) {
			input.value = formatBlurredInput(value)
			onInput()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value])

	function onFocus(e) {
		cancelRef.current = false
	}

	function onBlur(e) {
		if (cancelRef.current) inputRef.current.value = formatBlurredInput(value)
		else {
			const newValue = parseUserInput(inputRef.current.value)
			if (value !== newValue) setValue(newValue)
		}
	}

	function onKeyDown(e) {
		if (e.key === 'Escape') {
			if (e.preventDefault) e.preventDefault()
			if (e.stopPropagation) e.stopPropagation()
			cancelRef.current = true
			inputRef.current.blur()
		} else if (e.key === 'Enter') {
			if (e.preventDefault) e.preventDefault()
			if (e.stopPropagation) e.stopPropagation()
			inputRef.current.blur()
		}
	}

	function onInput() {
		if (!inputRef?.current) return
		inputRef.current.style['width'] = `${minWidth}px`
		const scrollWdith = inputRef.current.scrollWidth
		inputRef.current.style['width'] = `${Math.max(scrollWdith + 10, minWidth)}px`
	}

	return (
		<input
			ref={inputRef}
			defaultValue={formatBlurredInput(value)} // We use an uncontrolled component for better performance
			placeholder={placeholder}
			className={clsx('px-1 border-0 rounded-sm self-stretch hover:shadow-outer1 focus:shadow-outer2 focus:outline-none', className, isDisabled && 'pointer-events-none')}
			tabIndex={-1} // Important to prevent any undesired "tabbing"
			onFocus={onFocus}
			onBlur={onBlur}
			onKeyDown={onKeyDown}
			readOnly={isDisabled}
			onInput={canGrow ? onInput : undefined}
			style={{
				minWidth: `${minWidth}px`
			}}
			spellCheck={false}
		/>
	)
}
