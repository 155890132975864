import { Button, CircularProgress, Popover, TextField } from '@mui/material'
import { getThemeColor } from 'common/utils/theme'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export default function ColorPicker({ isOpen, anchor, themes, onSelect, onClose, isCustomEnabled = false, isLoading = false, currentColor }) {
	const { t } = useTranslation(['common', 'planning'])

	// #### STATE
	const [customColor, setCustomColor] = useState('')

	// #### EFECTS
	useEffect(() => {
		if (currentColor) setCustomColor(currentColor)
		else setCustomColor('')
	}, [currentColor])

	// #### FUNCTIONS
	function onCustomColor() {
		if (!customColor || customColor.length !== 7 || customColor[0] !== '#') return
		onSelectColor(customColor, true)
	}

	function onSelectColor(value, isCustom) {
		const theme = isCustom ? null : value
		const color = isCustom ? value : getThemeColor(value)
		onSelect({ theme, color })
	}

	return (
		<Popover open={isOpen} anchorEl={anchor} onClose={onClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
			<div className="p-2 grid grid-cols-4 gap-[8px]">
				{themes.map((theme) => (
					<div
						key={'color' + theme}
						className={`h-[30px] w-[30px] rounded cursor-pointer ${theme} bg-[var(--default)] hover:border-2 hover:border-black`}
						onClick={() => onSelectColor(theme, false)}
					/>
				))}
				{isCustomEnabled && (
					<>
						<div className="flex flex-row items-center col-span-3 w-[106px]">
							<TextField
								label={t('common:colors.custom')}
								placeholder="#1e88e5"
								fullWidth
								variant="outlined"
								margin="dense"
								size="small"
								value={customColor}
								inputProps={{ className: 'text-sm' }}
								InputLabelProps={{ className: 'text-sm' }}
								onChange={(e) => setCustomColor(e.target.value)}
							/>
						</div>
						<span className="self-center">
							<Button size="small" className="pl-2 min-w-min buttonText" onClick={onCustomColor}>
								{t('common:buttons.ok')}
							</Button>
						</span>
					</>
				)}
			</div>
			{isLoading && (
				<div className="absolute top-0 left-0 right-0 bottom-0 bg-bgGray/80 flex justify-center items-center">
					<CircularProgress size={30} className="text-textGray" />
				</div>
			)}
		</Popover>
	)
}
