import { Button, CircularProgress, Link, TextField } from '@mui/material'
import { loginEmail, resetPassword, signInWithGoogle } from 'common/saga-actions/authActions'
import Alert from 'common/components/alert/Alert'
import { validateEmail, validatePassword } from 'common/utils/validate'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import LoadingButton from '@mui/lab/LoadingButton'
import { GoogleIcon } from 'common/icons'
import Disclaimer from 'common/screens/auth/Disclaimer'
import useLocale from 'common/hooks/useLocale'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { DOMAIN_URL } from 'common/config/domain'

export default function Login({ signupLink }) {
	const dispatch = useDispatch()
	const { t } = useTranslation('common')
	const { locale } = useLocale()
	const navigate = useNavigate()

	// Props from redux store
	const loading = useSelector((state) => state.global.loading?.login)
	const loadingResetPwd = useSelector((state) => state.global.loading?.resetPassword)

	// State
	const [form, setForm] = useState({ email: '', password: '' })
	const [validation, setValidation] = useState({
		email: { valid: true, error: '' },
		password: { valid: true, error: '' }
	})

	function onChange(key, text) {
		setForm({ ...form, [key]: text })
	}

	async function onSubmit(event) {
		event.preventDefault()
		const emailValid = validateEmail(form.email)
		const passwordValid = validatePassword(form.password)
		setValidation({
			...validation,
			email: emailValid,
			password: passwordValid
		})
		if (emailValid.valid && passwordValid.valid) dispatch(loginEmail({ email: form.email, password: form.password }))
	}

	function onResetPwd(event) {
		event.preventDefault()
		const emailValid = validateEmail(form.email)
		setValidation({
			...validation,
			email: emailValid
		})

		if (emailValid.valid) dispatch(resetPassword({ email: form.email }))
	}

	async function onGoogleSignUp() {
		dispatch(signInWithGoogle(locale))
	}

	function onSignup() {
		if (signupLink) signupLink()
		else navigate('/signup')
	}

	return (
		<div className="h-full w-full bg-gray-50 flex flex-col justify-center items-center p-4 md:p-0">
			<Helmet>
				<title>{t('login.title')} | Singularly</title>
				<link rel="canonical" href={`${DOMAIN_URL}/login`} />
			</Helmet>
			<div className="paper p-8 flex flex-col items-center w-full md:w-2/3 2xl:w-1/3">
				<Link href="https://singularly.com" target="_blank" rel="noopener">
					<img src="/logo-positive.png" alt="Logo" width="200" height="200" />
				</Link>
				<p className="text-2xl pb-4">{t('login.title')}</p>

				<div className="w-full">
					<Alert messageKey="login" className="mb-4" />
					<Alert messageKey="resetPassword" className="mb-4" />
				</div>

				{/* <Button
					onClick={onGoogleSignUp}
					fullWidth
					variant="outlined"
					className="bg-white text-black buttonOutlinedGray normal-case"
					disabled={loading}
					startIcon={<GoogleIcon className="googleIcon w-[20px] h-[20px]" />}
				>
					{t('login.button_with_google')}
				</Button>

				<div className="border-b border-borderGray leading-[0] my-10 w-full text-center">
					<span className="px-5 bg-white text-textGray text-sm leading-[0] lowercase">{t('common:login.or')}</span>
				</div> */}

				<TextField
					className="pb-4"
					variant="outlined"
					margin="none"
					size="small"
					required
					fullWidth
					id="email"
					label={t('login.email')}
					name="email"
					autoComplete="email"
					autoFocus
					onChange={(event) => onChange('email', event.target.value)}
					inputProps={{ className: 'text-sm' }}
					value={form.email}
					error={!validation.email.valid}
					helperText={
						!validation.email.valid &&
						t(validation.email.error, {
							field: t('login.email')
						})
					}
				/>
				<TextField
					className="pb-4"
					variant="outlined"
					margin="none"
					size="small"
					required
					fullWidth
					name="password"
					label={t('login.password')}
					type="password"
					id="password"
					autoComplete="current-password"
					onChange={(event) => onChange('password', event.target.value)}
					inputProps={{ className: 'text-sm' }}
					value={form.password}
					error={!validation.password.valid}
					helperText={
						!validation.password.valid &&
						t(validation.password.error, {
							field: t('login.password')
						})
					}
				/>

				<LoadingButton
					loading={loading || loadingResetPwd}
					variant="contained"
					fullWidth
					className="buttonGradient mb-4 normal-case"
					onClick={onSubmit}
					loadingIndicator={<CircularProgress size={20} className="text-white" />}
				>
					{t('login.button')}
				</LoadingButton>

				<div className="flex flex-row justify-between w-full mb-6">
					<Link className="text-black text-sm no-underline hover:underline" href="#" onClick={(event) => onResetPwd(event)}>
						{t('login.forgot')}
					</Link>
					{/* <Link className="text-black text-sm no-underline hover:underline cursor-pointer" onClick={() => onSignup()}>
						{t('login.no_account')}
					</Link> */}
				</div>
				<Disclaimer isSignUp={false} />
			</div>
		</div>
	)
}
