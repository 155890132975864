import { DATA_TYPES } from 'common/constants/dataTypes'
import { NUMBER_FORMATS } from 'common/constants/formats'

export function countDecimals(number) {
	if (Math.floor(number.valueOf()) === number.valueOf()) return 0
	var str = number.toString()
	return str.split('.')[1].length || 0
}

export function isPointDecimalSeparator(locale) {
	const numberWithGroupAndDecimalSeparator = 1000.1
	const separator = Intl.NumberFormat(locale)
		.formatToParts(numberWithGroupAndDecimalSeparator)
		.find((part) => part.type === 'decimal').value
	return separator === '.'
}

export function printNumWithProps(value, type, typeProps) {
	if (value === 'NaN') return value
	const numberFormat = typeProps?.numberFormat ? NUMBER_FORMATS[typeProps.numberFormat] : null
	const numOfDecimals = !typeProps?.allowDecimals ? 0 : typeProps?.numOfDecimals
	var number = !typeProps?.allowNegative ? Math.abs(value) : value
	number = type === DATA_TYPES.percent.key ? number * 100 : number
	const options = { ...numberFormat?.options, maximumFractionDigits: numOfDecimals }
	const numStr = typeof value === 'number' ? new Intl.NumberFormat(numberFormat?.locale, options).format(number) : ''
	if (!(numStr?.length > 0)) return numStr
	return type === DATA_TYPES.percent.key ? `${numStr}%` : type === DATA_TYPES.currency.key ? `${typeProps?.currencySymbol}${numStr}` : numStr
}
