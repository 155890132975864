export function isGroupOpen(viewGroups, groupId) {
	return (viewGroups && viewGroups[groupId]?.isOpen) || !viewGroups || !viewGroups[groupId] || viewGroups[groupId].isOpen == null || false
}

export function orderBreakdownValues(categoryOrder, categoryIDs, categoryLabels) {
	const orderedValues = categoryOrder?.map((category) => {
		const catId = category.id
		const position = catId ? categoryIDs?.indexOf(catId) : null
		const value = position != null && categoryLabels ? categoryLabels[position] : null
		return value
	})
	return orderedValues
}
