import AssetSearch from 'common/screens/asset/AssetSearch'
import AccountButton from 'common/screens/account/AccountButton'

export default function GenericBar() {
	return (
		<>
			<div className="mx-4 flex flex-row items-center justify-between flex-1 h-full">
				<div className="flex flex-row items-center text-lg text-barTextStrong"></div>
				<div className="flex flex-row items-center h-full">
					<AssetSearch />
					<div className="mr-4" />
					<AccountButton />
				</div>
			</div>
		</>
	)
}
