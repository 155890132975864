import { createSelector } from '@reduxjs/toolkit'
import { LOCALES, LOCALE_DATE_MASK } from 'common/constants/locales'

// #### DIRECT SELECTORS
// User
export const selectUserLocale = (state) => state.user.user?.data?.locale

export const makeSelectLocale = ({ customLocale }) => {
	const _selectUserLocale = (state) => selectUserLocale(state)

	const selectLocale = createSelector([_selectUserLocale], (userLocale) => {
		const browserLocale = navigator.language || navigator.userLanguage
		const locale = customLocale ? customLocale : userLocale ? userLocale : browserLocale && LOCALES[browserLocale] ? browserLocale : 'en-US'
		const dateMask = LOCALE_DATE_MASK[locale]

		return { locale, dateMask }
	})
	return selectLocale
}
