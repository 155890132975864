import { useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectView, refreshView } from 'common/store/viewReducer'
import { sortArrayOfObjects } from 'common/utils/arrays'
import { useAssetPermits } from 'common/hooks/useAssetPermits'
import * as viewSel from 'common/store/viewSelector'

export default function useViewManager({ aid, type }) {
	const dispatch = useDispatch()
	const { canCreate, canEdit } = useAssetPermits(aid)

	// #### REDUX
	const currentId = useSelector((state) => viewSel.selectViewId(state, aid))
	const currentType = useSelector((state) => viewSel.selectViewType(state, aid))
	const currentLoaded = useSelector((state) => viewSel.selectViewLoaded(state, aid))
	const shared = useSelector((state) => viewSel.selectSharedViews(state, aid))
	const personal = useSelector((state) => viewSel.selectPersonalViews(state, aid))
	const selectSharedInUse = useMemo(() => viewSel.makeSelectSharedInUse({ aid }), [aid])
	const sharedInUse = useSelector((state) => selectSharedInUse(state))
	const selectPersonalInUse = useMemo(() => viewSel.makeSelectPersonalInUse({ aid }), [aid])
	const personalInUse = useSelector((state) => selectPersonalInUse(state))

	// #### EFFECTS
	// Initialize current view
	function initializeView() {
		if (!shared?.loaded || !shared?.result) return
		const views = Object.entries(shared.result)
		if (!(views?.length > 0)) return
		const sorted = sortArrayOfObjects(views, (entry) => entry[1]?.name || '', true, true)
		const viewId = sorted[0][0]
		dispatch(selectView({ id: aid, vid: viewId, type: 'shared' }))
	}

	useEffect(() => {
		if (!currentId && shared?.loaded) initializeView()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [shared?.loaded, currentId])

	// Update shared views change
	useEffect(() => {
		// Disable view update if: (1) user can't update view (2) views are still not loaded (3) current is not a shared view
		if ((!canEdit && currentLoaded) || !shared?.loaded || currentType !== 'shared') return
		// Identify when a view has been deleted and redirect user
		if (currentId != null && currentType === 'shared' && !sharedInUse) {
			initializeView()
			return
		}
		// Update selected view
		if (currentId && sharedInUse) dispatch(refreshView({ id: aid, vid: currentId, type: 'shared', data: sharedInUse }))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sharedInUse])

	// Update personal views change
	useEffect(() => {
		// Disable view update if: (1) user can't update view (2) views are still not loaded (3) current is not a personal view
		if ((!canEdit && currentLoaded) || !personal?.loaded || currentType !== 'personal') return
		// Identify when a view has been deleted and redirect user
		if (currentId != null && currentType === 'personal' && !personalInUse) {
			initializeView()
			return
		}
		if (currentId && personalInUse) dispatch(refreshView({ id: aid, vid: currentId, type: 'personal', data: personalInUse }))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [personalInUse])

	return null
}
