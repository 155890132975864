import { createSelector } from '@reduxjs/toolkit'

// #### DIRECT SELECTORS
// Assets
export const selectAssets = (state) => state.asset.assets?.result

// #### DERIVATIVE SELECTORS
export const selectAssetsByParent = createSelector([selectAssets], (assets) => {
	if (!assets) return {}
	let assetsByParent = {}
	Object.entries(assets)?.map((entry) => {
		const parentId = entry[1].sid
		const asset = { ...entry[1], aid: entry[0] }
		if (!assetsByParent[parentId]) assetsByParent[parentId] = []
		assetsByParent[parentId].push(asset)
	})
	return assetsByParent
})
