import Chart from 'common/config/chart'
import { CHART_AXIS, CHART_SHAPES, CHART_STACK, CHART_TYPES } from 'common/constants/charts'
import { MODEL_PARAMS } from 'model/constants/modelParameters'
import { getDateIntervals, fromDateToKey, printDateWithProps } from 'common/utils/dates'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { THEMES } from 'common/constants/themes'
import { getThemeColor, transparentize } from 'common/utils/theme'
import { isEqual } from 'lodash'
import { selectModelVars } from 'model/store/modelSelector'

export default function ModelChartGraphic({ aid, chart, dates }) {
	const height = MODEL_PARAMS.CHART_HEIGHT
	const chartType = CHART_TYPES[chart.type]

	// #### REDUX
	const modelProps = useSelector((state) => state.model.model[aid]?.data?.modelProps)
	const modelVariables = useSelector((state) => selectModelVars(state, aid))
	const chartData = useSelector((state) => (state.modelData.chartData[aid] ? state.modelData.chartData[aid][chart.id] : null))

	// Time constants
	const generalProps = chart.generalProps
	const typeProps = modelProps.typeProps

	// #### STATE
	const [labels, setLabels] = useState([])
	const [labelKeys, setLabelKeys] = useState([])
	const [datasets, setDatasets] = useState([])
	const [variables, setVariables] = useState([])
	const [options, setOptions] = useState({})

	// #### EFFECTS
	useEffect(() => {
		if (dates) prepareDates()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dates])

	useEffect(() => {
		const newVariables = chart.variables.map((variable) => {
			return { ...variable, label: modelVariables[variable.id]?.label }
		})
		if (!isEqual(newVariables, variables)) setVariables(newVariables)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chart.variables, modelVariables])

	useEffect(() => {
		prepareData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [variables, chartData, generalProps.hasLegend, generalProps.stack, generalProps.hasIntervals])

	function prepareDates() {
		const dateIntervals = getDateIntervals(dates?.startDate, dates?.endDate, dates?.frequency)

		let labels = []
		let labelKeys = []
		const chartTypeProps = typeProps ? { ...typeProps, frequency: dates?.frequency } : {}
		dateIntervals.forEach((el) => {
			labels.push(printDateWithProps(el, chartTypeProps, dates?.locale?.locale))
			labelKeys.push(fromDateToKey(el))
		})
		setLabels(labels)
		setLabelKeys(labelKeys)
	}

	function prepareData() {
		var hasLeftAxis = false
		var hasRightAxis = false
		let datasets = []
		let colorIndex = 0
		variables?.forEach((variable) => {
			// Variable data
			const varData = chartData && chartData[variable.id]
			const meanPos = varData?.valueNames?.default
			const values = varData?.values
			const isBreakdown = varData?.isBreakdown

			// Uncertainty
			const highPos = varData?.valueNames?.high
			const lowPos = varData?.valueNames?.low

			// Variable axis
			if (variable.axis === CHART_AXIS.left.key) hasLeftAxis = true
			else hasRightAxis = true

			// Variable type
			const varType = CHART_TYPES[variable.type]

			// Create chart datasets
			values?.forEach((dataset, index) => {
				const label = isBreakdown ? `${variable.label} (${dataset?.l?.join(', ')})` : variable.label

				// Color
				let color = '#000'
				if (isBreakdown) {
					const themeIndex = colorIndex % THEMES.length
					const theme = THEMES[themeIndex]
					color = getThemeColor(theme)
					colorIndex += 1
				} else color = variable.color

				if (highPos && generalProps.hasIntervals && variable.type === CHART_TYPES.line.key)
					datasets.push({
						label,
						type: varType.chartJsType,
						backgroundColor: transparentize(color, 0.8),
						borderColor: transparentize(color, 0.8),
						yAxisID: variable.axis === CHART_AXIS.left.key ? 'y' : 'y1',
						tension: variable.type === CHART_TYPES.line.key && variable.shape === CHART_SHAPES.curve.key ? 0.4 : 0,
						data: labelKeys.map((labelKey) => dataset?.v[labelKey] && dataset?.v[labelKey][highPos]),
						stack: generalProps.stack === CHART_STACK.categories.key ? variable.id : undefined,
						fill: '+1',
						pointRadius: 0,
						showLine: false,
						hasLegend: false
					})

				datasets.push({
					label,
					type: varType.chartJsType,
					backgroundColor: color,
					borderColor: color,
					yAxisID: variable.axis === CHART_AXIS.left.key ? 'y' : 'y1',
					tension: variable.type === CHART_TYPES.line.key && variable.shape === CHART_SHAPES.curve.key ? 0.4 : 0,
					data: labelKeys.map((labelKey) => dataset?.v[labelKey] && dataset?.v[labelKey][meanPos]),
					stack: generalProps.stack === CHART_STACK.categories.key ? variable.id : undefined,
					hasLegend: true
				})

				if (lowPos && generalProps.hasIntervals && variable.type === CHART_TYPES.line.key)
					datasets.push({
						label,
						type: varType.chartJsType,
						backgroundColor: transparentize(color, 0.8),
						borderColor: transparentize(color, 0.8),
						yAxisID: variable.axis === CHART_AXIS.left.key ? 'y' : 'y1',
						tension: variable.type === CHART_TYPES.line.key && variable.shape === CHART_SHAPES.curve.key ? 0.4 : 0,
						data: labelKeys.map((labelKey) => dataset?.v[labelKey] && dataset?.v[labelKey][lowPos]),
						stack: generalProps.stack === CHART_STACK.categories.key ? variable.id : undefined,
						fill: '-1',
						pointRadius: 0,
						showLine: false,
						hasLegend: false
					})
			})
		})

		setDatasets(datasets)
		prepareOptions(hasLeftAxis, hasRightAxis)
	}

	function prepareOptions(hasLeftAxis, hasRightAxis) {
		var scales = {}

		if (hasLeftAxis)
			scales = {
				...scales,
				y: {
					type: 'linear',
					display: true,
					position: 'left'
				}
			}

		if (hasRightAxis)
			scales = {
				...scales,
				y1: {
					type: 'linear',
					display: true,
					position: 'right'
				}
			}

		if (hasLeftAxis && hasRightAxis)
			scales.y1 = {
				...scales.y1,
				grid: {
					drawOnChartArea: false // only want the grid lines for one axis to show up
				}
			}

		if ([CHART_STACK.categories.key, CHART_STACK.variables.key].includes(generalProps.stack)) {
			scales.x = { ...scales.x, stacked: true }
			scales.y = { ...scales.y, stacked: true }
		}

		const options = {
			responsive: true,
			maintainAspectRatio: false,
			plugins: {
				legend: {
					display: chart.generalProps.hasLegend ? true : false,
					position: 'bottom',
					align: 'end',
					labels: {
						filter: function (legendItem, data) {
							const index = legendItem.datasetIndex
							return data.datasets[index].hasLegend
						}
					}
				}
			},
			scales: scales
		}
		setOptions(options)
	}

	const data = {
		labels: labels,
		datasets: datasets
	}

	return <Chart type={chartType.chartJsType} options={options} data={data} height={height} style={{ height: `${height}px`, maxHeight: `${height}px` }} />
}
