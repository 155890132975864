import { select, put, takeEvery, call, spawn, cancel, fork, all } from 'redux-saga/effects'
import * as api from 'table/api/tableApi'
import * as storeAction from 'table/store/tableReducer'
import * as sagaAction from 'table/saga-actions/tableActions'
import * as dataSagaAction from 'table/saga-actions/tableDataActions'
import * as subscribeHelper from 'common/saga/helpers/subscribeHelper'
import * as crudHelper from 'common/saga/helpers/crudHelper'
import * as loadHelper from 'common/saga/helpers/loadHelper'
import * as authApi from 'common/api/authApi'
import * as globalStoreAction from 'common/store/globalReducer'
import * as globalSagaAction from 'common/saga-actions/globalActions'
import { generateKey } from 'common/utils/uuid'
import * as tableSel from 'table/store/tableSelector'
import { TABLE_NEW_VAR, TABLE_PARAMS } from 'table/constants/tableParameters'
import { DATA_TYPES } from 'common/constants/dataTypes'
import { set, cloneDeep } from 'lodash'
import { throwError } from 'common/config/errors'
import { deleteField } from 'firebase/firestore'

const FILE_NAME = 'tableSagas'

const getTables = (state) => state.table.table
const getTabIndex = (state) => state.table.tabIndex

// #### PATH TO THE COLLECTION
function defaultPath(includeDocId) {
	const path = [
		{ collection: 'tenants', param: 'tid', value: null },
		{ collection: 'tables', param: includeDocId ? 'aid' : null, value: null }
	]
	return path
}

// #### SUBSCRIBE TO TABLE
function* loadTableReq() {
	const queryConfig = subscribeHelper.queryConfig({ path: defaultPath(true), returnType: 'doc' })
	const sagaConfig = subscribeHelper.sagaConfig({ loadAction: sagaAction.LOAD_TABLE, loadResponse: storeAction.loadTable, cancelAction: sagaAction.CANCEL_TABLE, cancelResponse: storeAction.cancelTable })
	yield spawn(subscribeHelper.subscribe, sagaConfig, queryConfig)
}

// #### MANAGE TABLE
function* updateTableReq() {
	const queryConfig = crudHelper.queryConfig({ path: defaultPath(true), returnType: 'doc' })
	const sagaConfig = crudHelper.sagaConfig({ loadAction: sagaAction.UPDATE_TABLE })
	const msgConfig = crudHelper.msgConfig({ fileName: FILE_NAME, functionName: updateTableReq.name })
	yield spawn(crudHelper.update, sagaConfig, queryConfig, msgConfig)
}

function* deleteTabReq() {
	yield takeEvery(sagaAction.DELETE_TAB, deleteTab)
}

function* deleteTab(triggeredAction) {
	try {
		const { tid, aid, tabIndex } = triggeredAction
		const activeTabs = yield select(getTabIndex)
		const activeTab = activeTabs[aid]
		const tables = yield select(getTables)
		const table = tables[aid].data
		const tabs = table.tabs
		const variables = table.variables

		var newTabs = [...tabs]
		var newVariables = { ...variables }

		var tab = tabs[tabIndex]
		var varsToDelete = tab.variables

		// Delete variables
		varsToDelete?.map((variable) => delete newVariables[variable])
		// Delete tab
		newTabs.splice(tabIndex, 1)

		// Change tab if the tab to remove was active
		if (activeTab === tabIndex && activeTab > 0) yield put(storeAction.selectTab({ id: aid, tabIndex: activeTab - 1 }))

		// Save
		const updateContent = { tabs: newTabs, variables: newVariables }
		yield put(sagaAction.updateTable({ tid, aid, content: updateContent }))
	} catch (err) {
		const content = throwError(err, FILE_NAME, deleteTab.name)
		yield put(globalSagaAction.showMessage({ content }))
	}
}

// #### ROW AND COLUMN OPERATIONS
function* addColumnReq() {
	yield takeEvery(sagaAction.ADD_COLUMN, addColumn)
}

function* addColumn(triggeredAction) {
	try {
		const { tid, teamId, aid, rowUpdateId, rowUpdateIndex, rowUpdateValue } = triggeredAction
		const { token } = yield call(authApi.getAuthToken)
		const tables = yield select(getTables)
		const table = tables[aid].data
		const tabs = table.tabs
		const _tabIndex = yield select(getTabIndex)
		const tabIndex = _tabIndex[aid]
		const tab = tabs[tabIndex]
		const variables = table.variables
		const hasTypedLabel = rowUpdateId === TABLE_PARAMS.HEAD_ROW_ID && rowUpdateValue
		const referenceColumns = yield call(getReferenceColumns, aid, [])

		// create new variable
		var newItem = { ...TABLE_NEW_VAR, id: generateKey(6), tabId: tab.id }
		if (hasTypedLabel) newItem = { ...newItem, label: rowUpdateValue }
		// add variable to tab
		var newTabs = cloneDeep(tabs)
		const tabVars = newTabs[tabIndex].variables
		set(newTabs, `[${tabIndex}].variables`, tabVars ? [...tabVars, newItem.id] : [newItem.id])
		// add variable to variables
		const newVariables = { ...variables, [newItem.id]: newItem }
		// update row being edited
		yield call(api.createColumn, token, tid, teamId, aid, newItem.id, DATA_TYPES.text.backendType, null, referenceColumns)
		// save
		yield put(sagaAction.updateTable({ tid, aid, content: { variables: newVariables, tabs: newTabs } }))
		if (!hasTypedLabel && rowUpdateId && rowUpdateValue) yield put(dataSagaAction.updateRows({ tid, teamId, aid, updates: { [rowUpdateIndex]: { id: rowUpdateId, [newItem.id]: rowUpdateValue } } }))
	} catch (err) {
		const content = throwError(err, FILE_NAME, addColumn.name)
		yield put(globalSagaAction.showMessage({ content }))
	}
}

function* deleteColumnReq() {
	yield takeEvery(sagaAction.DELETE_COLUMN, deleteColumn)
}

function* deleteColumn(triggeredAction) {
	const { tid, teamId, aid, colId } = triggeredAction
	try {
		yield put(globalStoreAction.loadingOn({ key: 'deleteColumn' }))

		const tables = yield select(getTables)
		const table = tables[aid].data
		const tabs = table.tabs
		const _tabIndex = yield select(getTabIndex)
		const tabIndex = _tabIndex[aid]
		const variables = table.variables
		const variable = variables[colId]
		const typeProps = variable.typeProps
		const typeDef = DATA_TYPES[variable.type]
		const wasReferenceColumn = variable.type === DATA_TYPES.reference.key
		const referenceColumns = yield call(getReferenceColumns, aid, [colId])
		// Remove variable
		const newVariables = { ...variables }
		delete newVariables[colId]
		// Remove variable from tab
		const newTabs = cloneDeep(tabs)
		newTabs[tabIndex].variables = newTabs[tabIndex].variables.filter((el) => el !== colId)
		// delete data in backend
		if (!typeDef.isMetadata) {
			const { token } = yield call(authApi.getAuthToken)
			yield call(api.deleteColumn, token, tid, teamId, aid, colId, typeDef.backendType, typeProps, referenceColumns)
		}
		// save
		yield put(sagaAction.updateTable({ tid, aid, content: { variables: newVariables, tabs: newTabs } }))
		// disconnect table
		if (wasReferenceColumn) yield call(disconnectTable, { tid, aid, tableId: typeProps.selectTable, referenceColumns })
	} catch (err) {
		const content = throwError(err, FILE_NAME, deleteColumn.name)
		yield put(globalSagaAction.showMessage({ content }))
	} finally {
		yield put(globalStoreAction.loadingOff({ key: 'deleteColumn' }))
	}
}

function* moveColumnReq() {
	yield takeEvery(sagaAction.MOVE_COLUMN, moveColumn)
}

function* moveColumn(triggeredAction) {
	try {
		const { tid, aid, dragId, dragIndex, dropIndex } = triggeredAction
		const tables = yield select(getTables)
		const table = tables[aid].data
		const tabs = table.tabs
		const _tabIndex = yield select(getTabIndex)
		const tabIndex = _tabIndex[aid]

		// Remove variable from tab
		const newTabs = cloneDeep(tabs)
		newTabs[tabIndex].variables = newTabs[tabIndex].variables.filter((el) => el !== dragId)

		// Insert variable into new position
		const shift = dragIndex < dropIndex ? 1 : 0
		newTabs[tabIndex].variables.splice(dropIndex - shift, 0, dragId)
		// Save
		yield put(sagaAction.updateTable({ tid, aid, content: { tabs: newTabs } }))
	} catch (err) {
		const content = throwError(err, FILE_NAME, moveColumn.name)
		yield put(globalSagaAction.showMessage({ content }))
	}
}

function* changeColumnTabReq() {
	yield takeEvery(sagaAction.CHANGE_COLUMN_TAB, changeColumnTab)
}

function* changeColumnTab(triggeredAction) {
	try {
		const { tid, aid, colId, destTabId } = triggeredAction
		const tables = yield select(getTables)
		const table = tables[aid].data
		const tabs = table.tabs
		const _tabIndex = yield select(getTabIndex)
		const tabIndex = _tabIndex[aid]
		// Remove variable from tab
		const newTabs = cloneDeep(tabs)
		newTabs[tabIndex].variables = newTabs[tabIndex].variables.filter((el) => el !== colId)
		// Add variable to new tab
		const destTabIndex = tabs.findIndex((tab) => tab.id === destTabId)
		newTabs[destTabIndex].variables = newTabs[destTabIndex].variables || []
		newTabs[destTabIndex].variables.push(colId)
		// save && update variable reference to the tab
		yield put(sagaAction.updateTable({ tid, aid, content: { tabs: newTabs, [`variables.${colId}.tabId`]: destTabId } }))
	} catch (err) {
		const content = throwError(err, FILE_NAME, changeColumnTab.name)
		yield put(globalSagaAction.showMessage({ content }))
	}
}

function* getReferenceColumns(aid, excludeIds) {
	const columns = yield select((state) => tableSel.selectTableColumns(state, aid))
	const refColumns = columns?.filter((col) => col.type === DATA_TYPES.reference.key && !excludeIds.includes(col.id))
	return refColumns
}

function* changeTypeReq() {
	yield takeEvery(sagaAction.CHANGE_TYPE, changeType)
}

function* changeType(triggeredAction) {
	try {
		const { tid, teamId, aid, varIdOrigin, varIdDest, typeOrigin, typeDestination, typePropsOrigin, typePropsDest } = triggeredAction
		const propsOrigin = DATA_TYPES[typeOrigin]
		const propsDest = DATA_TYPES[typeDestination]
		const isOriginMetadata = propsOrigin?.isMetadata
		const isDestMetadata = propsDest?.isMetadata
		const { token } = yield call(authApi.getAuthToken)
		// Prepare list of resulting reference columns
		const isReferenceColumn = typeDestination === DATA_TYPES.reference.key
		const wasReferenceColumn = typeOrigin === DATA_TYPES.reference.key && !isReferenceColumn
		let referenceColumns = yield call(getReferenceColumns, aid, [varIdOrigin, varIdDest])
		if (isReferenceColumn) referenceColumns.push({ id: varIdDest, type: typeDestination, typeProps: typePropsDest, backendType: propsDest.backendType })

		if (!isOriginMetadata && !isDestMetadata) {
			// Neither origin nor destination is metadata
			// Inform type change to backend
			if (propsOrigin.backendType !== propsDest.backendType) {
				yield call(api.updateColumn, token, tid, teamId, aid, varIdOrigin, propsDest.backendType, propsOrigin.backendType, typePropsDest, typePropsOrigin, referenceColumns)
				yield put(
					sagaAction.updateTable({
						tid,
						aid,
						content: {
							[`variables.${varIdOrigin}.type`]: typeDestination,
							[`variables.${varIdOrigin}.typeProps`]: typePropsDest,
							[`variables.${varIdOrigin}.backendType`]: propsDest.backendType
						}
					})
				)
				// yield put(dataSagaAction.loadData({ tid, teamId, aid, sort: null, filter: null, page: 0, limit: 50, isInitial: true }))
			} else {
				yield put(
					sagaAction.updateTable({
						tid,
						aid,
						content: {
							[`variables.${varIdOrigin}.type`]: typeDestination,
							[`variables.${varIdOrigin}.typeProps`]: typePropsDest,
							[`variables.${varIdOrigin}.backendType`]: propsDest.backendType
						}
					})
				)
				// yield put(dataSagaAction.loadData({ tid, teamId, aid, sort: null, filter: null, page: 0, limit: 50, isInitial: true }))
			}
		} else {
			// Either origin or destination is metadata
			const tables = yield select(getTables)
			const table = tables[aid].data
			// Update variable id and type
			const variables = table.variables
			const variable = variables[varIdOrigin]
			const newVariables = { ...variables }
			newVariables[varIdDest] = { ...variable, id: varIdDest, type: typeDestination, typeProps: typePropsDest, backendType: propsDest.backendType }
			delete newVariables[varIdOrigin]
			// Update variable id in tabs
			const tabs = table.tabs
			const _tabIndex = yield select(getTabIndex)
			const tabIndex = _tabIndex[aid]
			const tab = tabs[tabIndex]
			const varIndex = tab.variables?.indexOf(varIdOrigin)

			const newTabs = cloneDeep(tabs)
			newTabs[tabIndex].variables[varIndex] = varIdDest

			// Update firestore
			yield put(sagaAction.updateTable({ tid, aid, content: { tabs: newTabs, variables: newVariables } }))
			// Origin is not metadata, then remove column from backend
			if (!isOriginMetadata) yield call(api.deleteColumn, token, tid, teamId, aid, varIdOrigin, propsOrigin.backendType, typePropsOrigin, referenceColumns)
			// Destination is not metadata, then create column in backend
			if (!isDestMetadata) yield call(api.createColumn, token, tid, teamId, aid, varIdDest, propsDest.backendType, typePropsDest, referenceColumns)
		}

		// Inform connected table
		if (isReferenceColumn) yield call(connectTable, { tid, aid, tableId: typePropsDest.selectTable })
		if (wasReferenceColumn) yield call(disconnectTable, { tid, aid, tableId: typePropsOrigin.selectTable, referenceColumns })
		yield put(dataSagaAction.loadData({ tid, teamId, aid, sort: null, filter: null, page: 0, limit: 50, isInitial: true }))
	} catch (err) {
		const content = throwError(err, FILE_NAME, changeType.name)
		yield put(globalSagaAction.showMessage({ content }))
	}
}

// #### DROP-DOWN SELECTION
function* loadSelectedTableReq() {
	const queryConfig = loadHelper.queryConfig({ path: defaultPath(true) })
	const sagaConfig = loadHelper.sagaConfig({ loadAction: sagaAction.LOAD_SELECTED_TABLE, loadResponse: storeAction.loadSelectedTable })
	yield spawn(loadHelper.load, sagaConfig, queryConfig)
}

function* subscribeSelectedTableReq() {
	const queryConfig = subscribeHelper.queryConfig({ path: defaultPath(true), returnType: 'doc' })
	yield takeEvery(sagaAction.SUBSCRIBE_SELECTED_TABLE, subscribeSelectedTable, queryConfig)
}

function* unsubscribeSelectedTableReq() {
	yield takeEvery(sagaAction.UNSUBSCRIBE_SELECTED_TABLE, unsubscribeSelectedTable)
}

var subscriptionCount = {}
var susbcriptionListener = {}
function* subscribeSelectedTable(queryConfig, triggeredAction) {
	try {
		const { aid } = triggeredAction
		if (!subscriptionCount[aid]) {
			subscriptionCount[aid] = 1
			const listener = yield fork(subscribeHelper.subscribeWithoutActions, storeAction.subscribeSelectedTable, queryConfig, triggeredAction)
			susbcriptionListener[aid] = listener
		} else subscriptionCount[aid]++
	} catch (err) {
		const content = throwError(err, FILE_NAME, subscribeSelectedTable.name)
		yield put(globalSagaAction.showMessage({ content }))
	}
}

function* unsubscribeSelectedTable(triggeredAction) {
	try {
		const { aid } = triggeredAction
		subscriptionCount[aid]--
		if (subscriptionCount[aid] === 0) {
			const listener = susbcriptionListener[aid]
			susbcriptionListener[aid] = null
			yield cancel(listener)
			yield put(storeAction.unsubscribeSelectedTable({ id: aid }))
		}
	} catch (err) {
		const content = throwError(err, FILE_NAME, unsubscribeSelectedTable.name)
		yield put(globalSagaAction.showMessage({ content }))
	}
}

// #### SYNC TABLES
function* connectTable(triggeredAction) {
	try {
		const { tid, aid, tableId } = triggeredAction
		yield put(sagaAction.updateTable({ tid, aid: tableId, content: { [`connectedTables.${aid}`]: true } }))
	} catch (err) {
		const content = throwError(err, FILE_NAME, connectTable.name)
		yield put(globalSagaAction.showMessage({ content }))
	}
}

function* disconnectTable(triggeredAction) {
	try {
		const { tid, aid, tableId, referenceColumns } = triggeredAction
		const hasMoreReferences = referenceColumns.findIndex((col) => col.typeProps?.selectTable === tableId) >= 0
		if (!hasMoreReferences) yield put(sagaAction.updateTable({ tid, aid: tableId, content: { [`connectedTables.${aid}`]: deleteField() } }))
	} catch (err) {
		const content = throwError(err, FILE_NAME, disconnectTable.name)
		yield put(globalSagaAction.showMessage({ content }))
	}
}

export function* disconnectAllTables(triggeredAction) {
	const { tid, aid } = triggeredAction
	const queryConfig = loadHelper.queryConfig({ path: defaultPath(true) })
	// Load table
	const table = yield call(loadHelper.loadSynchronous, queryConfig, { tid, aid })
	const variables = table?.data?.variables
	// Identfy tables to disconnect
	const tablesToDisconnect = {}
	variables &&
		Object.values(variables)?.forEach((variable) => {
			if (variable.type === DATA_TYPES.reference.key) {
				const selectTable = variable?.typeProps?.selectTable
				if (selectTable) tablesToDisconnect[selectTable] = true
			}
		})
	// Disconnect tables
	const disconnectIDs = Object.keys(tablesToDisconnect)
	yield all(disconnectIDs.map((id) => put(sagaAction.updateTable({ tid, aid: id, content: { [`connectedTables.${aid}`]: deleteField() } }))))
}

export default function* root() {
	// #### SUBSCRIBE TO TABLE
	yield spawn(loadTableReq)
	// #### MANAGE TABLE
	yield spawn(updateTableReq)
	yield spawn(deleteTabReq)
	// #### ROW AND COLUMN OPERATIONS
	yield spawn(addColumnReq)
	yield spawn(deleteColumnReq)
	yield spawn(moveColumnReq)
	yield spawn(changeColumnTabReq)
	yield spawn(changeTypeReq)
	// #### DROP-DOWN SELECTION
	yield spawn(loadSelectedTableReq)
	yield spawn(subscribeSelectedTableReq)
	yield spawn(unsubscribeSelectedTableReq)
}
