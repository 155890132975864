import { spawn } from 'redux-saga/effects'
import GlobalSagas from 'common/saga/globalSagas'
import AuthSagas from 'common/saga/authSagas'
import UserSagas from 'common/saga/userSagas'
import TeamSagas from 'common/saga/teamSagas'
import SpaceSagas from 'common/saga/spaceSagas'
import AssetSagas from 'common/saga/assetSagas'
import CommunitySagas from 'common/saga/communitySagas'
import BillingSagas from 'common/saga/billingSagas'
import ViewSagas from 'common/saga/viewSagas'
import ConnectorSagas from 'automation/saga/connectorSagas'
import FileSagas from 'automation/saga/fileSagas'
import SourceSagas from 'automation/saga/sourceSagas'
import TableSagas from 'table/saga/tableSagas'
import TableDataSagas from 'table/saga/tableDataSagas'
import ModelSagas from 'model/saga/modelSagas'
import ChartSagas from 'model/saga/chartSagas'
import ModelCategorySagas from 'model/saga/modelCategorySagas'
import ModelEngineSagas from 'model/saga/modelEngineSagas'
import ModelVizSagas from 'model/saga/modelVizSagas'

export default function* init() {
	yield spawn(GlobalSagas)
	yield spawn(AuthSagas)
	yield spawn(UserSagas)
	yield spawn(TeamSagas)
	yield spawn(SpaceSagas)
	yield spawn(AssetSagas)
	yield spawn(CommunitySagas)
	yield spawn(BillingSagas)
	yield spawn(ViewSagas)
	yield spawn(ConnectorSagas)
	yield spawn(FileSagas)
	yield spawn(SourceSagas)
	yield spawn(TableSagas)
	yield spawn(TableDataSagas)
	yield spawn(ModelSagas)
	yield spawn(ChartSagas)
	yield spawn(ModelCategorySagas)
	yield spawn(ModelEngineSagas)
	yield spawn(ModelVizSagas)
}
