import { createSlice } from '@reduxjs/toolkit'

export const spaceSlice = createSlice({
	name: 'space',
	initialState: {
		spaces: null,
		sid: null,
		space: null,
		spaceOpen: null // space that is open in the app's left menu
	},
	reducers: {
		loadSpaces: (state, action) => {
			state.spaces = { loaded: true, ...action.payload }
			state.space = state.sid && action.payload.result[state.sid] ? action.payload.result[state.sid] : null
		},
		cancelSpaces: (state, action) => {
			state.spaces = null
			state.space = null
			state.sid = null
			state.spaceOpen = null
		},
		setSpaceId: (state, action) => {
			state.sid = action.payload
			state.space = state.spaces?.result && action.payload ? state.spaces.result[action.payload] : null
		},
		setSpaceOpen: (state, action) => {
			state.spaceOpen = action.payload
		}
	}
})

export const { loadSpaces, cancelSpaces, setSpaceId, setSpaceOpen } = spaceSlice.actions

export default spaceSlice.reducer
