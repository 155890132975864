import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import useNavigate from 'common/hooks/useNavigate'
import Layout from 'common/screens/layout/Layout'
import TeamMenu from 'common/screens/team/TeamMenu'
import { PRODUCTS, SECTIONS } from 'common/constants/products'
import AddIcon from '@mui/icons-material/Add'
import { Avatar, CircularProgress, Menu, MenuItem } from '@mui/material'
import { createAsset } from 'common/saga-actions/assetActions'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
// import Breadcrumbs from 'common/screens/general/Breadcrumbs'
import { useTeamPermits } from 'common/hooks/useTeamPermits'
import SpaceAssets from './SpaceAssets'
import GenericBar from 'common/screens/layout/GenericBar'

export default function Space() {
	const { t } = useTranslation('common')
	const dispatch = useDispatch()
	let { sid } = useParams()
	const { navigate } = useNavigate()
	const { canCreate } = useTeamPermits()

	// #### REDUX
	const tid = useSelector((state) => state.auth.tid)
	const uid = useSelector((state) => state.auth.uid)
	const teamId = useSelector((state) => state.team.teamId)
	const loadingNew = useSelector((state) => state.global.loading?.newAsset)
	const space = useSelector((state) => state.space.spaces?.result[sid])

	// #### STATE
	const [communityMenu, setCommunityMenu] = useState(null)

	// #### FUNCTIONS
	function onCreateAsset(type) {
		dispatch(createAsset({ tid, uid, teamId, assetType: type, content: { type, name: t('common:assets.untitled'), sid } }))
	}

	return (
		<>
			<Layout menuContent={TeamMenu} barContent={GenericBar}>
				{/* <Breadcrumbs /> */}
				<div className="mx-4 my-6 mb-8 text-3xl">{space?.name}</div>

				{/* Create new assets */}
				{canCreate && (
					<div className="mx-4 mb-8 grid grid-cols-cards gap-x-[32px] gap-y-[16px] items-end">
						{[PRODUCTS.table, PRODUCTS.model, PRODUCTS.datasource, SECTIONS.community].map((product) => {
							const isCreating = (loadingNew && loadingNew[product.key]) || false
							const isDataSource = product.key === PRODUCTS.datasource.key
							const isFolder = product.key === PRODUCTS.folder.key
							const isCommunity = product.key === SECTIONS.community.key
							if (isFolder) return void 0 // don't display folders in the main product bar
							return (
								<div key={product.key} className={`first-${product.key}`}>
									<div className="mb-2 text-xs text-textGray uppercase">{product.header && t(product.header)}</div>
									<div
										className="flex flex-row justify-between items-center paper cursor-pointer p-4 hover:bg-bgGray"
										onClick={(e) => (isDataSource ? navigate(`/team/${teamId}/datasources`) : isCommunity ? setCommunityMenu(e.currentTarget) : !isCreating && onCreateAsset(product.key))}
									>
										<div className="flex flex-row items-center">
											<Avatar className={`h-[30px] w-[30px] mr-4 ${product.theme} bg-[var(--bar-bg)]`} variant="rounded">
												{React.createElement(product.icon, { className: 'text-[20px]' })}
											</Avatar>
											<div>
												<div className="text-sm font-medium">
													{isDataSource || isCommunity ? (
														<span>{t(product.namePlural)}</span>
													) : (
														<>
															<span>{t('common:assets.new')}&nbsp;</span>
															<span className="lowercase">{t(product.name)}</span>
														</>
													)}
												</div>
												<div className="text-xs text-textGray leading-none truncate">{t(product.description)}</div>
											</div>
										</div>
										{isCreating ? (
											<CircularProgress size={20} className="text-textGray" />
										) : isDataSource || isCommunity ? (
											<ChevronRightIcon size="small" className="text-textGray" />
										) : (
											<AddIcon size="small" className="text-textGray" />
										)}
									</div>
								</div>
							)
						})}
					</div>
				)}

				<Menu anchorEl={communityMenu} open={Boolean(communityMenu)} onClose={() => setCommunityMenu(null)}>
					<MenuItem dense>
						<a target="_blank" rel="noreferrer" href="https://singularly.com/templates">
							{t('common:community.exploreTemplates')}
						</a>
					</MenuItem>
					<MenuItem dense onClick={() => navigate(`/team/${teamId}/community`)}>
						{t('common:community.shareWithCommunity')}
					</MenuItem>
				</Menu>

				{/* Explore templates */}
				{/* {canCreate && (
				<div className="m-4 mb-8 rounded bg-bgGray p-4 flex flex-row justify-between items-center">
					<span className="text-sm text-textGray font-medium">{t('common:assets.exploreTemplates')}</span>
					<span>
						<Button size="small" variant="text" className="ml-2 buttonText normal-case" endIcon={<ChevronRightIcon className="text-textGray" />}>
							{t('common:assets.seeAll')}
						</Button>
					</span>
				</div>
			)} */}
				{/* Space assets */}
				<SpaceAssets sid={sid} />
			</Layout>
		</>
	)
}
