import { createSelector } from '@reduxjs/toolkit'
import { fromDateToKey, getDateIntervals } from 'common/utils/dates'
import { MODEL_PARAMS } from 'model/constants/modelParameters'
import { DATA_TYPES } from 'common/constants/dataTypes'
import * as viewSel from 'common/store/viewSelector'
import * as modelDataSel from 'model/store/modelDataSelector'
import * as teamSel from 'common/store/teamSelector'
import { isGroupOpen as _isGroupOpen } from 'model/utils/rows'
import { memoize } from 'proxy-memoize'
import { THEMES } from 'common/constants/themes'

// #### DIRECT SELECTORS
// Model
export const selectModelIsLoaded = (state, aid) => state.model.model[aid]?.loaded
export const selectModelProps = (state, aid) => state.model.model[aid]?.data?.modelProps
export const selectModelTypeProps = (state, aid) => state.model.model[aid]?.data?.modelProps?.typeProps
export const selectModelTabs = (state, aid) => state.model.model[aid]?.data?.tabs
export const selectModelGroups = (state, aid) => state.model.model[aid]?.data?.groups
export const selectModelCategoryIds = (state, aid) => state.model.model[aid]?.data?.catProps?.categories
export const selectModelDisplayIntervals = (state, aid) => state.model.model[aid]?.data?.confidence?.displayIntervals
export const selectModelDates = (state, aid) => state.model.model[aid]?.data?.modelProps
export const selectModelFrequency = (state, aid) => state.model.model[aid]?.data?.modelProps?.frequency
// Local model props
export const selectModelIsDesigning = (state, aid) => state.model.isDesigning[aid]
export const selectModelTabIndex = (state, aid) => state.model.tabIndex[aid]
// Variables
export const selectModelVarLoaded = (state, aid) => state.model.variables[aid]?.loaded
export const selectModelVars = (state, aid) => state.model.variables[aid]?.result
export const selectModelVarCategories = (state, aid, vid) => state.model.variables[aid]?.result[vid]?.categories
export const selectModelVarProps = (state, aid, vid) => state.model.variables[aid]?.result[vid]?.varProps
// Charts
export const selectModelTabCharts = (state, aid, tabIndex) => state.model.model[aid]?.data?.tabs[tabIndex]?.charts
// Model renderProps - to refactor
export const selectModelTabGroups = (state, aid) => state.model.renderProps[aid]?.tabGroups
export const selectModelGroupVariables = (state, aid) => state.model.renderProps[aid]?.groupVariables
export const selectModelCategoryTables = (state, aid) => state.model.renderProps[aid]?.categoryTables
export const selectModelCanGroupsOpen = (state, aid) => state.model.renderProps[aid]?.canGroupOpen
export const selectModelCanVarsOpen = (state, aid) => state.model.renderProps[aid]?.canVarOpen

// #### DERIVATIVE SELECTORS
export const makeSelectModelColumns = ({ aid }) => {
	const _selectModelTypeProps = (state) => selectModelTypeProps(state, aid)
	const _makeSelectVizDates = viewSel.makeSelectVizDates({ aid })
	const _selectVizDates = (state) => _makeSelectVizDates(state)

	const selectModelColumns = createSelector([_selectModelTypeProps, _selectVizDates], (typeProps, dates) => {
		if (!typeProps || !dates) return []

		const dateIntervals = getDateIntervals(dates.startDate.toUTC(), dates.endDate.toUTC(), dates.frequency)
		const dateIntervalsCols = dateIntervals.map((el) => ({
			id: fromDateToKey(el),
			type: DATA_TYPES.date.key,
			typeProps: { ...typeProps, frequency: dates.frequency },
			label: el.toISO(),
			object: el
		}))
		const columns = [
			{ id: MODEL_PARAMS.HEAD_COL_ID, type: DATA_TYPES.text.key, typeProps: DATA_TYPES.text.defaultProps },
			{ id: MODEL_PARAMS.STATIC_COL_ID, type: DATA_TYPES.text.key, typeProps: DATA_TYPES.text.defaultProps, label: MODEL_PARAMS.STATIC_COL_LABEL },
			...dateIntervalsCols
		]
		return columns
	})
	return selectModelColumns
}

export const makeSelectModelHiddenVars = ({ aid }) => {
	const _selectModelVars = (state) => selectModelVars(state, aid)
	// Memoize only executes when varProps.isHidden changes. However, it does not memoize correctly when returning new empty arrays/objects ¿?
	const selectModelHiddenVars = memoize((state) => {
		const variables = _selectModelVars(state)
		if (!variables) return {}
		const entries = Object.entries(variables)
		var hiddenVars = {}
		entries?.forEach((entry) => {
			const vid = entry[0]
			const isHidden = entry[1]?.varProps?.isHidden || false
			if (isHidden) hiddenVars[vid] = true
		})
		return hiddenVars
	})

	return selectModelHiddenVars
}

export const makeSelectModelRows = ({ aid }) => {
	// Model
	const _selectModelIsDesigning = (state) => selectModelIsDesigning(state, aid)
	const _selectModelVarLoaded = (state) => selectModelVarLoaded(state, aid)
	const _selectModelTabGroups = (state) => selectModelTabGroups(state, aid)
	const _selectModelGroupVariables = (state) => selectModelGroupVariables(state, aid)
	const _makeSelectModelHiddenVars = makeSelectModelHiddenVars({ aid })
	const _selectModelHiddenVars = (state) => _makeSelectModelHiddenVars(state)
	// Model data
	const _selectModelBreakdownCount = (state) => modelDataSel.selectModelBreakdownCount(state, aid)
	// Views
	const _selectViewGroups = (state) => viewSel.selectViewGroups(state, aid)
	const _selectViewVariables = (state) => viewSel.selectViewVariables(state, aid)

	const selectModelRows = createSelector(
		[_selectModelIsDesigning, _selectModelVarLoaded, _selectModelTabGroups, _selectModelGroupVariables, _selectModelBreakdownCount, _selectViewGroups, _selectViewVariables, _selectModelHiddenVars],
		(isDesigning, isVarLoaded, tabGroups, groupVariables, breakdownCount, viewGroups, viewVariables, hiddenVars) => {
			var rows = []
			if (!isVarLoaded) return []
			tabGroups?.forEach((groupId) => {
				// Add group
				const groupIndex = rows.length
				const isGroupOpen = _isGroupOpen(viewGroups, groupId)
				rows.push({ id: groupId, groupIndex, depth: 0, isGroup: true })

				// Add variables
				if (!isGroupOpen) return null
				groupVariables[groupId]?.forEach((varId) => {
					const varBreakDownCount = breakdownCount ? breakdownCount[varId] : null
					const isVarOpen = (viewVariables && viewVariables[varId]?.isOpen) || false
					const hide = !isDesigning && hiddenVars[varId]

					if (!hide) rows.push({ id: varId, groupIndex, depth: 1 })

					// Add breakdowns
					if (!isVarOpen || !varBreakDownCount || hide) return
					for (let i = 0; i < varBreakDownCount; i++) rows.push({ id: varId, isBreakdown: true, breakdownIndex: i, groupIndex, depth: 2, isFirst: i === 0, isLast: i === varBreakDownCount - 1 })
				})
			})

			return rows
		}
	)
	return selectModelRows
}

// Returns a map containing the model's categories with full category data. Only used in design mode by team members
export const makeSelectModelCategories = ({ aid }) => {
	const _selectModelCategoryIds = (state) => selectModelCategoryIds(state, aid)
	const selectModelCategories = memoize((state) => {
		const teamCategories = teamSel.selectCategories(state)
		const modelCategoryIds = _selectModelCategoryIds(state)
		if (!teamCategories || !modelCategoryIds) return {}

		let modelCategories = {}
		modelCategoryIds?.forEach((cat, index) => {
			const theme = THEMES[index % THEMES.length]
			modelCategories[cat.id] = { ...teamCategories[cat.id], theme }
		})

		return modelCategories
	})

	return selectModelCategories
}

// Returns an array containind the table IDs of the model's categories. Only used in design mode by team members
export const makeSelectModelCategoryTables = ({ aid }) => {
	const _selectModelCategoryIds = (state) => selectModelCategoryIds(state, aid)
	const makeSelectModelCategoryTables = memoize((state) => {
		const teamCategories = teamSel.selectCategories(state)
		const modelCategoryIds = _selectModelCategoryIds(state)
		if (!teamCategories || !modelCategoryIds) return []

		let modelCategories = []
		modelCategoryIds?.forEach((cat) => {
			const categoryTable = teamCategories[cat.id]?.selectTable
			if (!modelCategories.includes(categoryTable)) modelCategories.push(categoryTable)
		})

		return modelCategories
	})

	return makeSelectModelCategoryTables
}

// Returns a map containing category id and label of the model's categories. Used in view mode by all users
export const makeSelectModelCategoriesForSearch = ({ aid }) => {
	const _selectModelCategoryIds = (state) => selectModelCategoryIds(state, aid)
	const selectModelCategoriesForSearch = memoize((state) => {
		const modelCategoryIds = _selectModelCategoryIds(state)
		if (!modelCategoryIds) return {}

		let categoriesForSearch = {}
		modelCategoryIds?.forEach((cat) => {
			categoriesForSearch[cat.id] = { id: cat.id, label: cat.name, type: DATA_TYPES.text.key }
		})

		return categoriesForSearch
	})

	return selectModelCategoriesForSearch
}
