import { useState, useEffect } from 'react'
import html2canvas from 'html2canvas'
import { fromTimestampToDate } from 'common/utils/dates'
import { DateTime } from 'luxon'

export const useScreenShot = ({ type, quality } = {}) => {
	const [image, setImage] = useState(null)
	const [error, setError] = useState(null)

	const takeScreenShot = (node) => {
		if (!node) throw new Error('You should provide correct html node.')
		return html2canvas(node, { windowWidth: 1600, windowHeight: 900 })
			.then((canvas) => {
				const croppedCanvas = document.createElement('canvas')
				const croppedCanvasContext = croppedCanvas.getContext('2d')
				// init data
				const cropPositionTop = 0
				const cropPositionLeft = 0
				const cropWidth = canvas.width
				const cropHeight = canvas.height

				croppedCanvas.width = cropWidth
				croppedCanvas.height = cropHeight

				croppedCanvasContext.drawImage(canvas, cropPositionLeft, cropPositionTop)

				const base64Image = croppedCanvas.toDataURL(type, quality)

				setImage(base64Image)
				return base64Image
			})
			.catch(setError)
	}

	const resizeScreenShot = (image, width, height, callback) => {
		var img = new Image()
		img.src = image
		img.onload = function () {
			let canvas = document.createElement('canvas')
			let ctx = canvas.getContext('2d')
			canvas.width = width
			canvas.height = height
			ctx.drawImage(img, 0, 0, width, height)
			const resizedImage = canvas.toDataURL()
			callback(resizedImage)
		}
	}

	return { image, takeScreenShot, resizeScreenShot, error }
}

export const useControlScreenShot = ({ ref, updatedAt, isReady, callback }) => {
	const { takeScreenShot, resizeScreenShot } = useScreenShot()

	// this prevents taking more than 1 in a single session
	const [isScreenShotTaken, setIsScreenShotTaken] = useState(false)

	useEffect(() => {
		if (!isReady || isScreenShotTaken) return
		const lastUpdate = updatedAt ? fromTimestampToDate(updatedAt) : null
		const today = DateTime.now()
		var timer
		if (!lastUpdate || lastUpdate.toMillis() < today.toMillis() - 1000 * 60 * 60 * 24) timer = setTimeout(() => execute(), 5000)
		return () => {
			if (timer) clearTimeout(timer)
		}
	}, [isReady, updatedAt])

	function execute() {
		if (!ref.current) return
		takeScreenShot(ref.current).then((image) => resizeScreenShot(image, 800, 450, (resizedImage) => callback(resizedImage)))
		setIsScreenShotTaken(true)
	}
}
