import { t } from 'i18next'

export const ERRORS_DATA = {
	0: {
		key: 0,
		type: 'FORMULA_ERROR',
		label: t('common:dataErrors.formulaError'),
		desc: t('common:dataErrorsDesc.formulaError')
	},
	1: {
		key: 1,
		type: 'DELETED_DEPENDENCY',
		label: t('common:dataErrors.deletedDependency'),
		desc: t('common:dataErrorsDesc.deletedDependency')
	},
	2: {
		key: 2,
		type: 'BROKEN_DEPENDENCY',
		label: t('common:dataErrors.brokenDependency'),
		desc: t('common:dataErrorsDesc.brokenDependency')
	},
	3: {
		key: 3,
		type: 'CIRCULAR_DEPENDENCY',
		label: t('common:dataErrors.circularDependency'),
		desc: t('common:dataErrorsDesc.circularDependency')
	},
	4: {
		key: 4,
		type: 'INCOMPATIBLE_OFFSET',
		label: t('common:dataErrors.incompatibleOffset'),
		desc: t('common:dataErrorsDesc.incompatibleOffset')
	},
	5: {
		key: 5,
		type: 'DELETED_CATEGORY',
		label: t('common:dataErrors.deletedCategory'),
		desc: t('common:dataErrorsDesc.deletedCategory')
	},
	6: {
		key: 6,
		type: 'DATASET_SQL_ERROR',
		label: t('common:dataErrors.datasetSql'),
		desc: t('common:dataErrorsDesc.datasetSql')
	},
	7: {
		key: 7,
		type: 'CATEGORY_NOT_IN_LEVEL',
		label: t('common:dataErrors.categoryNotInLevel'),
		desc: t('common:dataErrorsDesc.categoryNotInLevel')
	},
	8: {
		key: 8,
		type: 'INVALID_ATTRIBUTE',
		label: t('common:dataErrors.invalidAttribute'),
		desc: t('common:dataErrorsDesc.invalidAttribute')
	},
	9: {
		key: 9,
		type: 'FORECAST_ERROR',
		label: t('common:dataErrors.forecastError'),
		desc: t('common:dataErrorsDesc.forecastError')
	},
	10: {
		key: 10,
		type: 'NON_NUMERIC_ATTRIBUTE',
		label: t('common:dataErrors.nonNumericAttribute'),
		desc: t('common:dataErrorsDesc.nonNumericAttribute')
	},
	11: {
		key: 11,
		type: 'VARIABLE_ERROR_REF',
		label: t('common:dataErrors.variableErrorRef'),
		desc: t('common:dataErrorsDesc.variableErrorRef')
	},
	12: {
		key: 12,
		type: 'CARDINALITY_LIMIT',
		label: t('common:dataErrors.cardinalityLimit'),
		desc: t('common:dataErrorsDesc.cardinalityLimit')
	},
}

export const ERROR_CELL_PRINT = t('common:dataErrors.value')
