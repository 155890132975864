// #### SUBSCRIBE TO COMMUNITY ASSETS
export const LOAD_COMMUNITY_ASSETS = 'communitySaga/LOAD_COMMUNITY_ASSETS'
export const CANCEL_COMMUNITY_ASSETS = 'communitySaga/CANCEL_COMMUNITY_ASSETS'
// #### LOAD COMMUNITY ASSET
export const LOAD_COMMUNITY_ASSET = 'communitySaga/LOAD_COMMUNITY_ASSET'
// #### MANAGE COMMUNITY ASSET
export const UPDATE_COMMUNITY_ASSET_DIRECT = 'communitySaga/UPDATE_COMMUNITY_ASSET_DIRECT'
export const CREATE_COMMUNITY_ASSET = 'communitySaga/CREATE_COMMUNITY_ASSET'
export const UPDATE_COMMUNITY_ASSET = 'communitySaga/UPDATE_COMMUNITY_ASSET'
export const DELETE_COMMUNITY_ASSET = 'communitySaga/DELETE_COMMUNITY_ASSET'
// #### UPLOAD IMAGE
export const FILE_TRASMIT_SUCCESS = 'communitySaga/FILE_TRASMIT_SUCCESS'
// #### COPY COMMUNITY ASSET
export const COPY_COMMUNITY_ASSET = 'communitySaga/COPY_COMMUNITY_ASSET'

// #### SUBSCRIBE TO COMMUNITY ASSETS
export const loadCommunityAssets = ({ tid, teamId }) => {
	return { type: LOAD_COMMUNITY_ASSETS, tid, teamId }
}

export const cancelCommunityAssets = () => {
	return { type: CANCEL_COMMUNITY_ASSETS }
}

// #### LOAD COMMUNITY ASSET
export const loadCommunityAsset = ({ tid, aid }) => {
	return { type: LOAD_COMMUNITY_ASSET, tid, aid }
}

// #### MANAGE COMMUNITY ASSET
export const updateCommunityAssetDirect = ({ tid, aid, content }) => {
	return { type: UPDATE_COMMUNITY_ASSET_DIRECT, tid, aid, content }
}

export const createCommunityAsset = ({ tid, teamId, content, images }) => {
	return { type: CREATE_COMMUNITY_ASSET, tid, teamId, content, images }
}

export const updateCommunityAsset = ({ tid, teamId, aid, content, images }) => {
	return { type: UPDATE_COMMUNITY_ASSET, tid, teamId, aid, content, images }
}

export const deleteCommunityAsset = ({ tid, teamId, aid }) => {
	return { type: DELETE_COMMUNITY_ASSET, tid, teamId, aid }
}

// #### UPLOAD IMAGE
export const fileTransmitSuccess = ({ tid, aid, fileId, downloadUrl }) => {
	return { type: FILE_TRASMIT_SUCCESS, tid, aid, fileId, downloadUrl }
}

// #### COPY COMMUNITY ASSET
export const copyCommunityAsset = ({ tid, aid, targetTeamId, targetTeamName, targetSpaceName }) => {
	return { type: COPY_COMMUNITY_ASSET, tid, aid, targetTeamId, targetTeamName, targetSpaceName }
}
