import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Layout from 'common/screens/layout/Layout'
import TeamMenu from 'common/screens/team/TeamMenu'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField } from '@mui/material'
import { useParams } from 'react-router-dom'
import { loadCommunityAsset, copyCommunityAsset } from 'common/saga-actions/communityActions'
import { cancelCommunityAsset } from 'common/store/communityReducer'
import clsx from 'clsx'
import { LoadingButton } from '@mui/lab'
import useNavigate from 'common/hooks/useNavigate'
import { validateNotEmpty } from 'common/utils/validate'
import GenericBar from 'common/screens/layout/GenericBar'

export default function CommunityUse() {
	const { t } = useTranslation('common')
	const dispatch = useDispatch()
	let { aid } = useParams()
	const { navigate } = useNavigate()
	const NEW_TEAM = '_new'

	// #### REDUX
	const tid = useSelector((state) => state.auth.tid)
	const asset = useSelector((state) => state.community.asset?.data)
	const assetLoaded = useSelector((state) => state.community.asset?.loaded)
	const teams = useSelector((state) => state.team.teams?.result)
	const teamsLoaded = useSelector((state) => state.team.teams?.loaded)
	const loaded = assetLoaded && teamsLoaded
	const creating = useSelector((state) => state.global.loading?.useTemplate)
	const saveSuccess = useSelector((state) => state.global.message?.useTemplateSuccess)

	// #### DEFAULT VALUES
	const defaultForm = {
		teamId: '',
		name: '',
		targetSpaceName: ''
	}

	const defaultValidation = {
		teamId: { valid: true, error: '' },
		name: { valid: true, error: '' },
		targetSpaceName: { valid: true, error: '' }
	}

	// #### STATE
	const [form, setForm] = useState(defaultForm)
	const [validation, setValidation] = useState(defaultValidation)

	// #### EFFECTS
	// Load data
	useEffect(() => {
		const lTid = tid
		const lAid = aid
		if (lTid && lAid) dispatch(loadCommunityAsset({ tid, aid }))
		return () => {
			if (lTid && lAid) dispatch(cancelCommunityAsset())
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tid, aid])

	// Redirect after save
	useEffect(() => {
		if (saveSuccess?.isVisible) navigate(`/team/${form.teamId}`)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [saveSuccess])

	// Select default team
	useEffect(() => {
		if (teamsLoaded && teams) {
			const teamIds = Object.keys(teams)
			const teamId = teamIds?.length > 0 ? teamIds[0] : null
			if (teamId) onChange('teamId', teamId)
		} else if (teamsLoaded) onChange('teamId', NEW_TEAM)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [teamsLoaded])

	// #### FUNCTIONS
	function onChange(key, value) {
		setForm({ ...form, [key]: value })
	}

	function onCancel() {
		navigate('/teams')
	}

	function onSave() {
		const isNew = form.teamId === NEW_TEAM
		const targetTeamId = isNew ? null : form.teamId
		const targetTeamName = isNew ? form.name : null
		const targetSpaceName = form.targetSpaceName

		const idValid = !isNew ? validateNotEmpty(targetTeamId) : { valid: true, error: '' }
		const nameValid = isNew ? validateNotEmpty(targetTeamName) : { valid: true, error: '' }
		const spaceValid = validateNotEmpty(targetSpaceName)
		setValidation({ teamId: idValid, name: nameValid, targetSpaceName: spaceValid })

		if (idValid.valid && nameValid.valid && spaceValid.valid) dispatch(copyCommunityAsset({ tid, aid, targetTeamId, targetTeamName, targetSpaceName }))
	}

	return (
		<Layout menuContent={TeamMenu} barContent={GenericBar}>
			<Dialog open={true} onClose={() => void 0} maxWidth="md" fullWidth={true}>
				<DialogTitle>{t('common:community.getStarted')}</DialogTitle>
				<DialogContent className={clsx('mt-2 flex flex-col', !loaded && 'items-center justify-center')}>
					{loaded ? (
						<>
							<div className="bg-bgGray rounded p-4">
								<div className="font-medium">{asset?.name}</div>
								<div>{asset?.shortDescription}</div>
								{/* <div className="my-2 border-b border-b-borderGray"></div> */}
								{/* <div className="mt-4 flex flex-row justify-start items-center flex-wrap"> */}
								<div className="mt-4 grid grid-cols-imageUpload auto-rows-[100px] gap-[15px]">
									{asset.images?.map((imageId, index) => {
										const imageUrl = asset.imagesURL ? asset.imagesURL[imageId] : null
										if (!imageUrl) return null
										return <img key={`communityImage#${index}`} loading="lazy" src={imageUrl} className="w-full h-full object-cover rounded" alt="" />
									})}
								</div>
							</div>
							<div className="text-sm font-medium mt-6">{t('common:community.selectTeam')}:</div>
							<TextField
								select
								fullWidth
								variant="outlined"
								margin="dense"
								size="small"
								value={form.teamId}
								onChange={(event) => onChange('teamId', event.target.value)}
								inputProps={{ className: 'text-sm' }}
								InputLabelProps={{ className: 'text-sm' }}
								sx={{ placeholder: 'text-sm' }}
								error={!validation.teamId.valid}
								helperText={
									!validation.teamId.valid &&
									t('common:' + validation.teamId.error, {
										field: t('common:community.name')
									})
								}
							>
								{Object.entries(teams).map((entry) => (
									<MenuItem key={`communityTeam#${entry[0]}`} value={entry[0]} className="text-sm">
										{entry[1].name}
									</MenuItem>
								))}
								<MenuItem value={NEW_TEAM} className="text-sm">
									{t('common:buttons.new')}
								</MenuItem>
							</TextField>
							{form.teamId === NEW_TEAM && (
								<div>
									<div className="text-sm font-medium mt-2">{t('common:community.teamName')}:</div>
									<TextField
										fullWidth
										variant="outlined"
										margin="dense"
										size="small"
										value={form.name}
										onChange={(event) => onChange('name', event.target.value)}
										inputProps={{ className: 'text-sm' }}
										InputLabelProps={{ className: 'text-sm' }}
										sx={{ placeholder: 'text-sm' }}
										error={!validation.name.valid}
										helperText={
											!validation.name.valid &&
											t('common:' + validation.name.error, {
												field: t('common:community.name')
											})
										}
									/>
								</div>
							)}
							<div>
								<div className="text-sm font-medium mt-2">{t('common:community.spaceName')}:</div>
								<TextField
									fullWidth
									variant="outlined"
									margin="dense"
									size="small"
									value={form.targetSpaceName}
									onChange={(event) => onChange('targetSpaceName', event.target.value)}
									inputProps={{ className: 'text-sm' }}
									InputLabelProps={{ className: 'text-sm' }}
									sx={{ placeholder: 'text-sm' }}
									error={!validation.targetSpaceName.valid}
									helperText={
										!validation.targetSpaceName.valid &&
										t('common:' + validation.targetSpaceName.error, {
											field: t('common:community.spaceName')
										})
									}
								/>
							</div>
						</>
					) : (
						<CircularProgress />
					)}
				</DialogContent>
				<DialogActions className="p-4">
					<Button variant="outlined" className="buttonOutlinedGray" size="small" onClick={onCancel} loading={creating}>
						{t('common:buttons.cancel')}
					</Button>
					<LoadingButton variant="contained" className="buttonContainedPrimary" size="small" onClick={onSave} loading={creating}>
						{t('common:buttons.save')}
					</LoadingButton>
				</DialogActions>
			</Dialog>
		</Layout>
	)
}
