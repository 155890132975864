import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Autocomplete, TextField } from '@mui/material'
import useLazyLoading from 'common/hooks/useLazyLoading'
import { loadSelectedAsset, searchAssets } from 'common/saga-actions/assetActions'
import { cancelSearchAssets, cancelSelectedAsset } from 'common/store/assetReducer'
import { useTranslation } from 'react-i18next'
import { PRODUCTS } from 'common/constants/products'
import { throttle } from 'throttle-debounce'
import ErrorIcon from '@mui/icons-material/ErrorOutlineOutlined'

export default function TableTypeRefTable({ aid, teamId, onChange, value, validation, fieldName, showLabel = false, disabled = false, loadKey = 'tableTypeRefTable' }) {
	const dispatch = useDispatch()
	const { t } = useTranslation(['common'])
	const assetTypes = [PRODUCTS.table.key]

	// #### STORE
	const tid = useSelector((state) => state.auth.tid)
	const uid = useSelector((state) => state.auth.uid)
	const search = useSelector((state) => state.asset.search[loadKey])
	const selectedItem = useSelector((state) => state.asset.selected[loadKey])
	const [error, setError] = useState(null)
	const where = [{ attribute: 'teamId', operator: '==', value: teamId }]

	// #### STATE
	const [searchTerm, setSearchTerm] = useState(null)
	const [searchResults, setSearchResults] = useState([])

	// #### EFFECTS
	// Clean search
	useEffect(() => {
		return () => dispatch(cancelSearchAssets({ key: loadKey }))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// Load selected item
	useEffect(() => {
		const lKey = loadKey
		if (value) dispatch(loadSelectedAsset({ key: lKey, tid, uid, aid: value }))
		return () => dispatch(cancelSelectedAsset({ key: lKey }))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value])

	// Show errors
	useEffect(() => {
		if (selectedItem?.loaded && selectedItem?.notFound) setError(t('common:error.value_deleted_long'))
		else setError(null)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedItem?.loaded, selectedItem?.notFound])

	// Initialize search results
	useEffect(() => {
		var newResults = search?.result?.filter((item) => getOptionId(item) !== aid) || []
		// Add selected item to results if it is not there - needed for autocomplete to work properly
		const hasSelectedItem = selectedItem && newResults.find((item) => getOptionId(item) === getOptionId(selectedItem))
		if (selectedItem && !hasSelectedItem) newResults.push(selectedItem)
		setSearchResults(newResults)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search, selectedItem])

	// Search
	useEffect(() => {
		searchThrottled(loadKey, tid, uid, searchTerm, assetTypes)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchTerm])

	const searchThrottled = throttle(200, (key, tid, uid, searchTerm, assetTypes) => {
		dispatch(searchAssets({ key, tid, uid, searchTerm, assetTypes, where }))
	})

	// #### LAZY LOADING
	const triggerFetch = useLazyLoading(() => dispatch(searchAssets({ key: loadKey, tid, uid, searchTerm, startAfter: search?.startAfter, assetTypes, where })), search?.isLoading, search?.notFound)

	// #### FUNCTIONS
	const getOptionId = (option) => option?.id || ''
	const getOptionLabel = (option) => option?.data?.name || ''

	function onSearchTerm(value) {
		setSearchTerm(value === '' ? null : value)
	}

	function onSelect(e, item) {
		const itemId = item?.id || null
		if (itemId === aid || itemId === value) return
		onChange(itemId)
	}

	// ###########################
	// #### RENDER
	// ###########################
	return (
		<>
			<Autocomplete
				options={searchResults}
				getOptionLabel={getOptionLabel}
				isOptionEqualToValue={(option, value) => getOptionId(option) === getOptionId(value)}
				value={!selectedItem?.notFound ? selectedItem || null : null}
				inputValue={searchTerm || ''}
				onChange={onSelect}
				onInputChange={(e, value) => onSearchTerm(value)}
				filterOptions={(i) => i}
				renderInput={(params) => (
					<TextField
						{...params}
						label={showLabel ? fieldName : null}
						variant="outlined"
						size="small"
						margin="none"
						fullWidth
						InputProps={{ ...params.InputProps, className: 'text-sm' }}
						InputLabelProps={{ className: 'text-sm' }}
						sx={{ placeholder: 'text-sm' }}
						error={validation && !validation.valid}
						helperText={
							validation &&
							!validation.valid &&
							validation.error &&
							t('common:' + validation.error, {
								field: fieldName
							})
						}
					/>
				)}
				renderOption={(props, option, { selected }) => (
					<li {...props} key={getOptionId(option)}>
						<span ref={searchResults?.length > 0 && getOptionId(option) === getOptionId(searchResults[searchResults.length - 1]) ? triggerFetch : undefined}>{getOptionLabel(option)}</span>
					</li>
				)}
				classes={{ root: 'flex-1', listbox: 'text-sm', noOptions: 'text-sm' }}
				openOnFocus
				disabled={disabled}
			/>
			{error && (
				<div className="flex flex-row items-center text-red-500 mt-1">
					<ErrorIcon fontSize="small" className="text-red-500 mr-2" />
					<span className="text-xs">{error}</span>
				</div>
			)}
		</>
	)
}
