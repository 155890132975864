import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close'
import { Dialog, DialogContent, DialogTitle, IconButton, Tab, Tabs } from '@mui/material'
import AccountLeave from 'common/screens/account/AccountLeave'
import AccountPrivacy from 'common/screens/account/AccountPrivacy'
import AccountProfile from 'common/screens/account/AccountProfile'
import AccountResetPassword from 'common/screens/account/AccountResetPassword'

export default function YourAccountModal({ isOpen, onClose }) {
	const { t } = useTranslation('common')

	// #### STATE
	const [step, setStep] = useState(0)

	return (
		<Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth={true}>
			<DialogTitle className='mb-2'>
				<div className="text-lg">{t('common:account.title')}</div>
				<IconButton size="small" className="absolute top-4 right-4 text-textGray" onClick={onClose}>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent className="flex flex-col md:flex-row">
				{/* Desktop version */}
				<Tabs value={step} onChange={(e, value) => setStep(value)} orientation="vertical" className="tabsVertical shrink-0 mr-6 hidden md:block" classes={{ indicator: 'tabsIndicator' }}>
					<Tab label={t('common:account.profile')} className="tabVertical" classes={{ selected: 'tabSelected' }} />
					<Tab label={t('common:account.password')} className="tabVertical" classes={{ selected: 'tabSelected' }} />
					<Tab label={t('common:account.privacy')} className="tabVertical" classes={{ selected: 'tabSelected' }} />
					<Tab label={t('common:account.leave')} className="tabVertical" classes={{ selected: 'tabSelected' }} />
				</Tabs>
				{/* Mobile version */}
				<Tabs value={step} onChange={(e, value) => setStep(value)} orientation="horizontal" className="tabs shrink-0 mb-6 md:hidden" classes={{ indicator: 'tabsIndicator' }}>
					<Tab label={t('common:account.profile')} className="tab" classes={{ selected: 'tabSelected' }} />
					<Tab label={t('common:account.password')} className="tab" classes={{ selected: 'tabSelected' }} />
					<Tab label={t('common:account.privacy')} className="tab" classes={{ selected: 'tabSelected' }} />
					<Tab label={t('common:account.leave')} className="tab" classes={{ selected: 'tabSelected' }} />
				</Tabs>

				{step === 0 ? <AccountProfile /> : step === 1 ? <AccountResetPassword /> : step === 2 ? <AccountPrivacy /> : <AccountLeave />}
			</DialogContent>
		</Dialog>
	)
}
