import i18n from 'common/config/i18n'
import { TextIcon, TagIcon, PercentIcon, CurrencyIcon, DateIcon, TimeIcon, CheckboxIcon, SelectIcon, FileIcon, UrlIcon, EmailIcon, UserIcon, AutoNumberIcon, TableIcon } from 'common/icons/index'
import { cellText, cellNum, cellPercent, cellCurrency, cellEmail, cellUrl, cellDate, cellTime, cellCheckbox, cellSelect, cellSearch, cellFile } from 'common/components/grid/cells/Cell'
import { NUMBER_FORMATS, DATE_FORMATS, TIME_FORMATS } from 'common/constants/formats'
import { SEARCH_LIMIT } from 'common/constants/search'
import { FREQUENCY } from './frequencies'

export const FILTER_OPERATORS = {
	text: [
		{ key: 'contains', label: 'table:filterOperator.contains', hasValue: true, hasMany: true },
		{ key: 'notContains', label: 'table:filterOperator.notContains', hasValue: true, hasMany: true },
		{ key: 'equals', label: 'table:filterOperator.equals', hasValue: true, hasMany: true },
		{ key: 'notEquals', label: 'table:filterOperator.notEquals', hasValue: true, hasMany: true },
		{ key: 'isEmpty', label: 'table:filterOperator.isEmpty', hasValue: false, hasMany: false },
		{ key: 'isNotEmpty', label: 'table:filterOperator.isNotEmpty', hasValue: false, hasMany: false }
	],
	num: [
		{ key: '=', label: 'table:filterOperator.=', hasValue: true, hasMany: false },
		{ key: '!=', label: 'table:filterOperator.!=', hasValue: true, hasMany: false },
		{ key: '<', label: 'table:filterOperator.<', hasValue: true, hasMany: false },
		{ key: '>', label: 'table:filterOperator.>', hasValue: true, hasMany: false },
		{ key: '<=', label: 'table:filterOperator.<=', hasValue: true, hasMany: false },
		{ key: '>=', label: 'table:filterOperator.>=', hasValue: true, hasMany: false },
		{ key: 'isEmpty', label: 'table:filterOperator.isEmpty', hasValue: false, hasMany: false },
		{ key: 'isNotEmpty', label: 'table:filterOperator.isNotEmpty', hasValue: false, hasMany: false }
	],
	checkbox: [
		{ key: 'isChecked', label: 'table:filterOperator.isChecked', hasValue: false, hasMany: false },
		{ key: 'isUnchecked', label: 'table:filterOperator.isUnchecked', hasValue: false, hasMany: false }
	],
	file: [
		{ key: 'isEmpty', label: 'table:filterOperator.isEmpty', hasValue: false, hasMany: false },
		{ key: 'isNotEmpty', label: 'table:filterOperator.isNotEmpty', hasValue: false, hasMany: false }
	]
}

export const DATA_TYPES = {
	text: {
		key: 'text',
		backendType: 'varchar',
		conversionGroup: 'varchar',
		isMetadata: false,
		label: i18n.t('table:types.text'),
		icon: TextIcon,
		desc: i18n.t('table:typeDesc.text'),
		typeProps: {},
		defaultProps: {},
		constants: {},
		renderer: cellText,
		filters: FILTER_OPERATORS.text,
		cardAvatar: true
	},
	boolean: {
		key: 'boolean',
		backendType: 'boolean',
		conversionGroup: 'varchar',
		isMetadata: false,
		label: i18n.t('table:types.boolean'),
		icon: TextIcon,
		desc: i18n.t('table:typeDesc.boolean'),
		typeProps: {},
		defaultProps: {},
		constants: {},
		renderer: cellText,
		filters: FILTER_OPERATORS.text,
		cardAvatar: true
	},
	// longText: { key: "longText", label: i18n.t("table:types.longText"), icon: LongTextIcon, desc: i18n.t("table:typeDesc.longText") },
	num: {
		key: 'num',
		backendType: 'float',
		conversionGroup: 'float',
		isMetadata: false,
		label: i18n.t('table:types.num'),
		icon: TagIcon,
		desc: i18n.t('table:typeDesc.num'),
		typeProps: { numberFormat: true, allowDecimals: true, numOfDecimals: true, allowNegative: true },
		defaultProps: { numberFormat: NUMBER_FORMATS.local.key, allowDecimals: true, numOfDecimals: 2, allowNegative: true },
		constants: { maxDecimals: 5 },
		renderer: cellNum,
		filters: FILTER_OPERATORS.num,
		cardAvatar: true
	},
	percent: {
		key: 'percent',
		backendType: 'float',
		conversionGroup: 'float',
		isMetadata: false,
		label: i18n.t('table:types.percent'),
		icon: PercentIcon,
		desc: i18n.t('table:typeDesc.percent'),
		typeProps: { numberFormat: true, allowDecimals: true, numOfDecimals: true, allowNegative: true },
		defaultProps: { numberFormat: NUMBER_FORMATS.local.key, allowDecimals: true, numOfDecimals: 2, allowNegative: true },
		constants: { maxDecimals: 5 },
		renderer: cellPercent,
		filters: FILTER_OPERATORS.num,
		cardAvatar: true
	},
	currency: {
		key: 'currency',
		backendType: 'float',
		conversionGroup: 'float',
		isMetadata: false,
		label: i18n.t('table:types.currency'),
		icon: CurrencyIcon,
		desc: i18n.t('table:typeDesc.currency'),
		typeProps: { numberFormat: true, currencySymbol: true, allowDecimals: true, numOfDecimals: true, allowNegative: true },
		defaultProps: { numberFormat: NUMBER_FORMATS.local.key, currencySymbol: '$', allowDecimals: true, numOfDecimals: 2, allowNegative: true },
		constants: { maxDecimals: 2 },
		renderer: cellCurrency,
		filters: FILTER_OPERATORS.num,
		cardAvatar: true
	},
	date: {
		key: 'date',
		backendType: 'timestamp',
		conversionGroup: 'timestamp',
		isMetadata: false,
		label: i18n.t('table:types.date'),
		icon: DateIcon,
		desc: i18n.t('table:typeDesc.date'),
		typeProps: { dateFormat: true, friendlyDate: true, includeTime: true, timeFormat: true },
		defaultProps: { dateFormat: DATE_FORMATS.local.key, friendlyDate: false, includeTime: false, timeFormat: TIME_FORMATS.h24.key, frequency: FREQUENCY.day.key },
		constants: {},
		renderer: cellDate,
		filters: FILTER_OPERATORS.num,
		cardAvatar: false
	},
	time: {
		key: 'time',
		backendType: 'timestamp',
		conversionGroup: 'timestamp',
		isMetadata: false,
		label: i18n.t('table:types.time'),
		icon: TimeIcon,
		desc: i18n.t('table:typeDesc.time'),
		typeProps: { timeFormat: true },
		defaultProps: { includeTime: true, timeFormat: TIME_FORMATS.h24.key }, // includeTime is true all the time
		constants: {},
		renderer: cellTime,
		filters: FILTER_OPERATORS.num,
		cardAvatar: false
	},
	checkbox: {
		key: 'checkbox',
		backendType: 'int',
		conversionGroup: 'int',
		isMetadata: false,
		label: i18n.t('table:types.checkbox'),
		icon: CheckboxIcon,
		desc: i18n.t('table:typeDesc.checkbox'),
		typeProps: {},
		defaultProps: {},
		constants: {},
		renderer: cellCheckbox,
		filters: FILTER_OPERATORS.checkbox,
		cardAvatar: false
	},
	select: {
		key: 'select',
		backendType: 'varchar',
		conversionGroup: 'varchar',
		isMetadata: false,
		label: i18n.t('table:types.select'),
		icon: SelectIcon,
		desc: i18n.t('table:typeDesc.select', { maxLength: SEARCH_LIMIT.tableColumns }),
		typeProps: { itemList: true },
		defaultProps: { itemList: [] },
		constants: {},
		renderer: cellSelect,
		filters: FILTER_OPERATORS.text,
		cardAvatar: true
	},
	reference: {
		key: 'reference',
		backendType: 'reference',
		conversionGroup: 'reference',
		isMetadata: false,
		label: i18n.t('table:types.reference'),
		icon: TableIcon,
		desc: i18n.t('table:typeDesc.reference'),
		typeProps: {},
		defaultProps: { selectTable: null, selectVariable: null },
		constants: {},
		renderer: cellSearch,
		filters: FILTER_OPERATORS.text,
		cardAvatar: true
	},
	// multiSelect: { key: 'multiSelect', backendType: 'varchar', label: i18n.t('table:types.multiSelect'), icon: MultiSelectIcon, desc: i18n.t('table:typeDesc.multiSelect'), typeProps: {}, defaultProps: {}, constants: {} },
	file: {
		key: 'file',
		backendType: 'jsonb',
		conversionGroup: 'file',
		isMetadata: false,
		label: i18n.t('table:types.file'),
		icon: FileIcon,
		desc: i18n.t('table:typeDesc.file'),
		typeProps: {},
		defaultProps: {},
		constants: {},
		renderer: cellFile,
		filters: FILTER_OPERATORS.file,
		cardAvatar: false
	},
	url: {
		key: 'url',
		backendType: 'varchar',
		conversionGroup: 'varchar',
		isMetadata: false,
		label: i18n.t('table:types.url'),
		icon: UrlIcon,
		desc: i18n.t('table:typeDesc.url'),
		typeProps: {},
		defaultProps: {},
		constants: {},
		renderer: cellUrl,
		filters: FILTER_OPERATORS.text,
		cardAvatar: true
	},
	email: {
		key: 'email',
		backendType: 'varchar',
		conversionGroup: 'varchar',
		isMetadata: false,
		label: i18n.t('table:types.email'),
		icon: EmailIcon,
		desc: i18n.t('table:typeDesc.email'),
		typeProps: {},
		defaultProps: {},
		constants: {},
		renderer: cellEmail,
		filters: FILTER_OPERATORS.text,
		cardAvatar: true
	},
	// user: { key: "user", label: i18n.t("table:types.user"), icon: UserIcon, desc: i18n.t("table:typeDesc.user"), typeProps: {}, defaultProps: {}, constants: {} },
	createdBy: {
		key: 'createdBy',
		backendType: 'varchar',
		conversionGroup: 'createdBy',
		isMetadata: true,
		metadataId: '_created_by',
		label: i18n.t('table:types.createdBy'),
		icon: UserIcon,
		desc: i18n.t('table:typeDesc.createdBy'),
		typeProps: {},
		defaultProps: {},
		constants: {},
		isDisabled: true,
		renderer: cellText,
		filters: FILTER_OPERATORS.text,
		cardAvatar: true
	},
	createdAt: {
		key: 'createdAt',
		backendType: 'date',
		conversionGroup: 'createdAt',
		isMetadata: true,
		metadataId: '_created_at',
		label: i18n.t('table:types.createdAt'),
		icon: DateIcon,
		desc: i18n.t('table:typeDesc.createdAt'),
		typeProps: { dateFormat: true, friendlyDate: true, includeTime: true, timeFormat: true },
		defaultProps: { dateFormat: DATE_FORMATS.local.key, friendlyDate: false, includeTime: true, timeFormat: TIME_FORMATS.h24.key },
		constants: {},
		renderer: cellDate,
		isDisabled: true,
		filters: FILTER_OPERATORS.num,
		cardAvatar: false
	},
	updatedBy: {
		key: 'updatedBy',
		backendType: 'varchar',
		conversionGroup: 'updatedBy',
		isMetadata: true,
		metadataId: '_update_by',
		label: i18n.t('table:types.updatedBy'),
		icon: UserIcon,
		desc: i18n.t('table:typeDesc.updatedBy'),
		typeProps: {},
		defaultProps: {},
		constants: {},
		isDisabled: true,
		renderer: cellText,
		filters: FILTER_OPERATORS.text,
		cardAvatar: true
	},
	updatedAt: {
		key: 'updatedAt',
		backendType: 'date',
		conversionGroup: 'updatedAt',
		isMetadata: true,
		metadataId: '_update_at',
		label: i18n.t('table:types.updatedAt'),
		icon: DateIcon,
		desc: i18n.t('table:typeDesc.updatedAt'),
		typeProps: { dateFormat: true, friendlyDate: true, includeTime: true, timeFormat: true },
		defaultProps: { dateFormat: DATE_FORMATS.local.key, friendlyDate: false, includeTime: true, timeFormat: TIME_FORMATS.h24.key },
		constants: {},
		renderer: cellDate,
		isDisabled: true,
		filters: FILTER_OPERATORS.num,
		cardAvatar: false
	}
	// autoNumber: {
	// 	key: 'autoNumber',
	// 	backendType: 'int',
	// 	conversionGroup: 'autoNumber',
	// 	isMetadata: true,
	// 	metadataId: '_autonumber',
	// 	label: i18n.t('table:types.autoNumber'),
	// 	icon: AutoNumberIcon,
	// 	desc: i18n.t('table:typeDesc.autoNumber'),
	// 	typeProps: { numberFormat: true },
	// 	defaultProps: { numberFormat: NUMBER_FORMATS.local.key, allowDecimals: false, numOfDecimals: 0, allowNegative: false },
	// 	constants: {},
	// 	renderer: cellNum,
	// 	isDisabled: true,
	// 	filters: FILTER_OPERATORS.num,
	// 	cardAvatar: true
	// }
}
