import CreateSearchCell from 'common/components/grid/cells/CreateSearchCell'

export const cellSearch = {
	component: CreateSearchCell,
	config: {
		alignRight: false,
		formatBlurredInput: ({ value, typeProps }) => value,
		formatCopyValue: ({ value, typeProps }) => value,
		parseUserInput: ({ value, typeProps }) => value,
		parsePastedValue: ({ value, typeProps, localeFallback }) => (value ? JSON.parse(value) : null)
	}
}
