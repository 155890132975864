import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, LinearProgress } from "@mui/material";
import { loadJobs } from "automation/saga-actions/connectorActions";
import { cancelJobs } from "automation/store/connectorReducer";
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import useLocale from "common/hooks/useLocale";
import { DateTime } from "luxon";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export default function Logs({ connectionId, isOpen, onClose }) {
    const { t } = useTranslation(['common', 'automation'])
	const dispatch = useDispatch()
    const { locale } = useLocale()
    let { teamId } = useParams()

    const jobs = useSelector((state) => state.connector.jobs)
    const loading = useSelector((state) => state.global.loading?.loadJobs)
	
    useEffect(() => {
        if(connectionId) dispatch(loadJobs({teamId, cid: connectionId}))
        return () => {
            dispatch(cancelJobs())
        }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [connectionId])

    return (
        <Dialog
            onClose={onClose}
            open={isOpen}
            maxWidth="md"
            fullWidth={true}
            PaperProps={{
                style: {
                    minHeight: '90%',
                    maxHeight: '90%'
                }
            }}
        >
            <DialogTitle className="text-sm text-center">
                <span>{t('common:buttons.logs')}&nbsp;</span>
            </DialogTitle>
            <Divider />
            <DialogContent>
                {loading && (
                    <div className="h-full w-full flex flex-col items-center justify-center text-barBg bg-bgGray">
                        <div className="mt-8 text-lg font-light text-textGray">{t('automation:messages.loading')}</div>
                        <LinearProgress className="w-[200px]" color="inherit" />
                    </div>
                )}
                {jobs &&
					Object.values(jobs).map((job) => {
						const id = job.job?.id
                        const status = job.job?.status
						const created = job.job?.createdAt ? DateTime.fromSeconds(job.job?.createdAt).setLocale(locale).toFormat("f") : null
                        const attempt = Object.values(job.attempts?.slice(-1))[0]
                        const duration = attempt?.endedAt - attempt?.createdAt
						return (
							<div key={id} className="col-span-full paper cursor-pointer hover:bg-bgGray p-4 flex flex-row items-center justify-between">
                                {status === 'succeeded' && (<CheckCircleIcon className="fill-green-700 mr-2" />)}
                                {status === 'running' && (<CircularProgress size={20} className="text-textGray mr-2" />)}
                                {(status === 'failed' || status === 'cancelled') && (<HighlightOffIcon className="fill-red-700 mr-2" />)}
                                
								<div className="flex flex-row items-center flex-1">
									<div>
										<div className="text-sm font-medium truncate">{status}</div>
                                        {status === 'succeeded' && (
                                            <div className="text-xs text-textGray leading-none truncate">
                                                {attempt?.totalStats.bytesEmitted}{t('automation:logs.kb') + ' | '}
                                                {attempt?.totalStats.recordsEmitted} {t('automation:logs.recordsEmitted') + ' | '}
                                                {attempt?.totalStats.recordsCommitted} {t('automation:logs.recordsCommitted') + ' | '}
                                                {duration}{'s'}
                                            </div>
                                        )}
									</div>
								</div>
								<div className="flex">
									<div className="text-xs truncate">{created && t('common:assets.created', { date: created })}</div>
									
								</div>
							</div>
						)
					})}
            </DialogContent>
            <DialogActions>
                <div className='mr-8'>
                    
                    <Button
                        variant="outlined"
                        className="mb-4 mr-2 normal-case"
                        onClick={onClose}
                    >
                        {t('common:buttons.close')}
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    )
}