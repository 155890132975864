import { put, take, call, takeEvery, takeLatest, cancel, spawn } from 'redux-saga/effects'
import * as api from 'common/api/authApi'
import * as storeAction from 'common/store/authReducer'
import * as sagaAction from 'common/saga-actions/authActions'
import * as globalStoreAction from 'common/store/globalReducer'
import * as globalSagaAction from 'common/saga-actions/globalActions'
import i18n from 'common/config/i18n'
import { PUBLIC_TENANT_ID } from 'common/config/tenant'
import { error, throwError } from 'common/config/errors'

const FILE_NAME = 'authSagas'

// #### AUTHENTICATION
export function* authenticateReq() {
	yield takeLatest(sagaAction.AUTHENTICATE, authenticate)
}

function* authenticate() {
	var listener = null
	try {
		listener = yield call(api.loadAuthChannel)
		yield take(sagaAction.CANCEL_AUTHENTICATE)
	} catch (err) {
		const content = throwError(err, FILE_NAME, authenticate.name)
		yield put(globalSagaAction.showMessage({ content }))
	} finally {
		if (listener) listener()
		yield put(storeAction.cancelAuthentication())
		yield cancel()
	}
}

export function* logoutReq() {
	yield takeEvery(sagaAction.LOGOUT, logout)
}

function* logout() {
	try {
		yield call(api.logout)
		yield put(globalStoreAction.closeMenu())
		window.location.href = '/'
	} catch (err) {
		const content = throwError(err, FILE_NAME, logout.name)
		yield put(globalSagaAction.showMessage({ content }))
	}
}

export function* signInWithGoogleReq() {
	yield takeEvery(sagaAction.SIGNIN_WITH_GOOGLE, signInWithGoogle)
}

function* signInWithGoogle(triggeredAction) {
	const { locale } = triggeredAction
	try {
		yield call(api.signInWithGoogle, PUBLIC_TENANT_ID, locale)
	} catch (err) {
		const content = throwError(err, FILE_NAME, signInWithGoogle.name)
		yield put(globalSagaAction.showMessage({ content }))
	}
}

export function* loginEmailReq() {
	yield takeEvery(sagaAction.LOGIN_EMAIL, loginEmail)
}

function* loginEmail(triggeredAction) {
	try {
		const { email, password } = triggeredAction
		yield put(globalStoreAction.loadingOn({ key: 'login' }))
		yield call(api.login, email, password, PUBLIC_TENANT_ID)
	} catch (err) {
		yield put(globalSagaAction.showMessage({ key: 'login', content: err, messageType: 'error' }))
		error(err)
	} finally {
		yield put(globalStoreAction.loadingOff({ key: 'login' }))
	}
}

export function* resetPasswordReq() {
	yield takeEvery(sagaAction.RESET_PWD, resetPassword)
}

function* resetPassword(triggeredAction) {
	try {
		const { email } = triggeredAction
		yield put(globalStoreAction.loadingOn({ key: 'resetPassword' }))
		yield call(api.resetPassword, PUBLIC_TENANT_ID, email)
		yield put(globalSagaAction.showMessage({ key: 'resetPassword', content: i18n.t('common:login.reset_success'), messageType: 'success' }))
	} catch (err) {
		yield put(globalSagaAction.showMessage({ key: 'resetPassword', content: err, messageType: 'error' }))
		error(err)
	} finally {
		yield put(globalStoreAction.loadingOff({ key: 'resetPassword' }))
	}
}

export function* signupReq() {
	yield takeEvery(sagaAction.SIGNUP, signup)
}

function* signup(triggeredAction) {
	try {
		const { name, surname, email, password, locale } = triggeredAction
		yield put(globalStoreAction.loadingOn({ key: 'login' }))
		yield call(api.signup, name, surname, email, password, locale, PUBLIC_TENANT_ID)
		yield call(api.login, email, password, PUBLIC_TENANT_ID)
	} catch (err) {
		yield put(globalSagaAction.showMessage({ key: 'login', content: err, messageType: 'error' }))
		error(err)
	} finally {
		yield put(globalStoreAction.loadingOff({ key: 'login' }))
	}
}

export default function* root() {
	// #### AUTHENTICATION
	yield spawn(authenticateReq)
	yield spawn(logoutReq)
	yield spawn(signInWithGoogleReq)
	yield spawn(loginEmailReq)
	yield spawn(resetPasswordReq)
	yield spawn(signupReq)
}
