import { useDispatch, useSelector } from 'react-redux'
import { t } from 'i18next'
import { Dialog, DialogTitle, DialogActions, DialogContent, TextField, IconButton, Button } from '@mui/material'
import { useState, useEffect } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { validateNotEmpty } from 'common/utils/validate'
import ColorPicker from 'common/components/ColorPicker'
import { DEFAULT_THEME, THEMES } from 'common/constants/themes'
import { createSpace, updateSpace } from 'common/saga-actions/spaceActions'

export default function NewSpace({ sid, space, isOpen, onClose: onCloseExternal }) {
	const dispatch = useDispatch()

	// #### STORE
	const isCreating = useSelector((state) => state.global.loading?.newSpace)
	const _isUpdating = useSelector((state) => state.global.loading?.updateSpace)
	const isUpdating = sid && _isUpdating ? _isUpdating[sid] || false : false

	// #### DEFAULT VALUES
	const defaultForm = {
		name: '',
		theme: DEFAULT_THEME
	}

	const defaultValidation = {
		name: { valid: true, error: '' },
		theme: { valid: true, error: '' }
	}

	// #### STATE
	const [form, setForm] = useState(defaultForm)
	const [validation, setValidation] = useState(defaultValidation)
	const [colorPicker, setColorPicker] = useState(null)
	const [prevStatus, setPrevStatus] = useState({ isCreating: false, isUpdating: false })

	// #### EFFECTS
	useEffect(() => {
		if ((!isCreating && prevStatus.isCreating) || (!isUpdating && prevStatus.isUpdating)) onClose()
		setPrevStatus({ isCreating, isUpdating })
	}, [isCreating, isUpdating])

	useEffect(() => {
		if (sid && isOpen) setForm({ name: space.name || defaultForm.name, theme: space.theme || defaultForm.theme })
		else if (isOpen) resetData()
	}, [isOpen])

	// #### FUNCTIONS
	function resetData() {
		setForm(defaultForm)
		setValidation(defaultValidation)
	}
	function onClose() {
		onCloseExternal()
	}

	function onChange(key, value) {
		setForm({ ...form, [key]: value })
	}

	function onChangeColor(theme) {
		onChange('theme', theme)
		setColorPicker(null)
	}

	function onSave() {
		let nameValid = validateNotEmpty(form.name)
		let themeValid = validateNotEmpty(form.theme)
		setValidation({ ...validation, name: nameValid, theme: themeValid })
		if (nameValid.valid && themeValid.valid) {
			if (sid) dispatch(updateSpace({ sid, content: form }))
			else dispatch(createSpace({ content: form }))
		}
	}

	return (
		<>
			<Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth={true}>
				<DialogTitle className="">
					<span className="text-lg font-bold">{sid ? t('common:space.settings') : t('common:space.create')}</span>
					<IconButton size="small" className="absolute top-4 right-4 text-textGray" onClick={onClose}>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
				<DialogContent className="">
					<div className="text-xs text-textGray mb-6">{t('common:space.createInfo')}</div>
					<div className="flex flex-row items-center">
						<div className={`w-[35px] h-[35px] rounded mr-2 cursor-pointer ${form.theme} bg-[var(--default)] hover:border hover:border-black`} onClick={(e) => setColorPicker(e.currentTarget)}></div>
						<TextField
							fullWidth
							variant="outlined"
							size="small"
							value={form.name}
							label={t('common:space.name')}
							onChange={(event) => onChange('name', event.target.value)}
							error={!validation.name.valid}
							inputProps={{ className: 'text-sm' }}
							InputLabelProps={{ className: 'text-sm' }}
						/>
					</div>
				</DialogContent>
				<DialogActions className="p-4">
					<Button variant="outlined" className="buttonOutlinedGray" size="small" onClick={onClose} disabled={isCreating || isUpdating}>
						{t('common:buttons.cancel')}
					</Button>
					<LoadingButton variant="contained" className="buttonContainedPrimary" size="small" onClick={onSave} loading={isCreating || isUpdating}>
						{t('common:buttons.save')}
					</LoadingButton>
				</DialogActions>
			</Dialog>
			<ColorPicker isOpen={Boolean(colorPicker)} anchor={colorPicker} themes={THEMES} onSelect={(color) => onChangeColor(color?.theme)} onClose={() => setColorPicker(null)} />
		</>
	)
}
