import { createSlice } from '@reduxjs/toolkit'
import { searchResults, mergeChanges } from 'common/store/helpers/searchHelper'

export const fileSlice = createSlice({
	name: 'file',
	initialState: {
		currentFile: null,
		fileUpload: null,
		uploading: null,
		search: null
	},
	reducers: {
		fileUpload: (state, action) => {
			state.fileUpload = { ...action.payload }
		},
		uploadingFile: (state, action) => {
			state.uploading = action.payload
		},
		cleanUploadingFile: (state, action) => {
			state.uploading = null
		},
		cleanFileUpload: (state, action) => {
			state.fileUpload = null
		},
		searchFiles: (state, action) => {
			state.search = searchResults(state.search, action.payload)
		},
		cancelSearchFiles: (state, action) => {
			state.search = null
		},
		loadFilesChanges: (state, action) => {
			state.search = mergeChanges(state.search, action.payload, true)
		},
		cancelFilesChanges: (state, action) => {},
		loadFileChanges: (state, action) => {
			state.currentFile = {...action.payload}
		},
		cancelFileChanges: (state, action) => {
			state.currentFile = null
		}
	}
})

export const { cleanFileUpload, fileUpload, searchFiles, cancelSearchFiles, loadFilesChanges, cancelFilesChanges, loadFileChanges, cancelFileChanges, uploadingFile, cleanUploadingFile } = fileSlice.actions

export default fileSlice.reducer
