import { put, call, takeEvery, select, spawn } from 'redux-saga/effects'
import * as sagaAction from 'automation/saga-actions/fileActions'
import * as api from 'automation/api/fileApi'
import * as authApi from 'common/api/authApi'
import * as storeAction from 'automation/store/fileReducer'
import * as subscribeHelper from 'common/saga/helpers/subscribeHelper'
import * as searchHelper from 'common/saga/helpers/searchHelper'
import * as globalStoreAction from 'common/store/globalReducer'
import * as globalSagaAction from 'common/saga-actions/globalActions'
import { SEARCH_LIMIT } from 'common/constants/search'
import { PRODUCTS } from 'common/constants/products'
import { generateKeywords } from 'common/saga/helpers/utils'
import { createAsset } from 'common/api/assetApi'
import { TABLE_PARAMS, TABLE_NEW_VIEW } from 'table/constants/tableParameters'
import i18n from 'common/config/i18n'
import { throwError } from 'common/config/errors'

const FILE_NAME = 'fileSagas'

const getUid = (state) => state.auth.uid

// PATH TO THE COLLECTION
function defaultPath(includeDocId) {
	const path = [
		{ collection: 'tenants', param: 'tid', value: null },
		{ collection: 'teams', param: 'teamId', value: null },
		{ collection: 'files', param: includeDocId ? 'fileId' : null, value: null }
	]
	return path
}

// #### PROCESS HEADERS
function* extractFileHeaders(triggeredAction) {
	const { file } = triggeredAction

	try {
		const { headers, firstLine, secondLine, success } = yield call(api.readFirstLines, file)
		yield put(storeAction.fileUpload({ headers, firstLine, secondLine, success }))
	} catch (err) {
		const content = throwError(err, FILE_NAME, extractFileHeaders.name)
		yield put(globalSagaAction.showMessage({ content }))
	}
}

function* extractFileHeadersReq() {
	yield takeEvery(sagaAction.EXTRACT_FILE_HEADERS, extractFileHeaders)
}

// #### MANAGE FILES
function* uploadFile(triggeredAction) {
	const { tid, teamId, sid, file, form } = triggeredAction
	try {
		const uid = yield select(getUid)
		const { token } = yield call(authApi.getAuthToken)
		yield put(storeAction.uploadingFile(true))
		if (form.destinationProps.selectTable === '') {
			const defaults = {
				variables: {},
				tabs: [
					{
						id: TABLE_PARAMS.HEAD_TAB_ID,
						name: i18n.t('table:defaults.mainTab'),
						variables: []
					}
				]
			}
			form.sourceProps.schema.forEach((col) => {
				if('selected' in col && col['selected']) {
					defaults['variables'][col['id']] = { ...col, tabId: TABLE_PARAMS.HEAD_TAB_ID }
					defaults.tabs[0].variables.push(col['id'])
				}
			})
			const keywords = generateKeywords(form.destinationProps.tableName)
			const newContent = { type: PRODUCTS.table.key, sid, name: form.destinationProps.tableName, keywords,  }
			const { data } = yield call(createAsset, token, tid, uid, teamId, newContent, defaults, TABLE_NEW_VIEW)
			form['destinationProps']['selectTable'] = data?.assetId
			const { fileId } = yield call(api.uploadFile, token, tid, teamId, uid, file, form)
			yield put(sagaAction.loadFileChanges({ tid, teamId, fileId }))
		} else {
			const { fileId } = yield call(api.uploadFile, token, tid, teamId, uid, file, form)
			yield put(sagaAction.loadFileChanges({ tid, teamId, fileId }))
		}
	} catch (err) {
		const content = throwError(err, FILE_NAME, uploadFile.name)
		yield put(globalSagaAction.showMessage({ content }))
	} finally {
		yield put(storeAction.uploadingFile(false))
	}
}

function* uploadFileReq() {
	yield takeEvery(sagaAction.UPLOAD_FILE, uploadFile)
}

function* deleteFile(triggeredAction) {
	const { tid, teamId, fid } = triggeredAction
	try {
		yield put(globalStoreAction.loadingOn({ key: 'deleteFile', item: fid }))
		const { token } = yield call(authApi.getAuthToken)
		const uid = yield select(getUid)
		yield call(api.deleteFile, token, tid, teamId, uid, fid)
	} catch (err) {
		const content = throwError(err, FILE_NAME, deleteFile.name)
		yield put(globalSagaAction.showMessage({ content }))
	} finally {
		yield put(globalStoreAction.loadingOff({ key: 'deleteFile', item: fid }))
	}
}

function* deleteFileReq() {
	yield takeEvery(sagaAction.DELETE_FILE, deleteFile)
}

// #### SEARCH
function* searchFilesReq() {
	const queryConfig = searchHelper.queryConfig({
		path: defaultPath(false),
		limit: SEARCH_LIMIT.files,
		returnType: 'list'
	})
	const sagaConfig = searchHelper.sagaConfig({
		loadAction: sagaAction.SEARCH_FILES,
		loadResponse: storeAction.searchFiles
	})
	const msgConfig = searchHelper.msgConfig({ fileName: FILE_NAME, functionName: searchFilesReq.name })
	yield spawn(searchHelper.search, sagaConfig, queryConfig, msgConfig)
}

// #### SUBSCRIBE TO File
function* loadFileReq() {
	const queryConfig = subscribeHelper.queryConfig({ path: defaultPath(true), returnType: 'doc' })
	const sagaConfig = subscribeHelper.sagaConfig({
		loadAction: sagaAction.LOAD_FILE_CHANGES,
		loadResponse: storeAction.loadFileChanges,
		cancelAction: sagaAction.CANCEL_FILE_CHANGES,
		cancelResponse: storeAction.cancelFileChanges
	})
	yield spawn(subscribeHelper.subscribe, sagaConfig, queryConfig)
}

// #### SUBSCRIBE TO FILES CHANGES
function* loadFilesChangesReq() {
	const queryConfig = subscribeHelper.queryConfig({
		path: defaultPath(false),
		where: [{ attribute: 'updatedAt', operator: '>=', param: 'startDate', value: null }],
		orderBy: [{ attribute: 'updatedAt', order: 'desc' }],
		returnType: 'map'
	})
	const sagaConfig = subscribeHelper.sagaConfig({
		loadAction: sagaAction.LOAD_FILES_CHANGES,
		loadResponse: storeAction.loadFilesChanges,
		cancelAction: sagaAction.CANCEL_FILES_CHANGES,
		cancelResponse: storeAction.cancelFilesChanges
	})
	yield spawn(subscribeHelper.subscribe, sagaConfig, queryConfig)
}

export default function* root() {
	// #### PROCESS HEADERS
	yield spawn(extractFileHeadersReq)
	// #### MANAGE FILES
	yield spawn(uploadFileReq)
	yield spawn(deleteFileReq)
	// #### SEARCH
	yield spawn(searchFilesReq)
	// #### SUBSCRIBE TO FILES CHANGES
	yield spawn(loadFilesChangesReq)
	yield spawn(loadFileReq)
}
