import { useState, createElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Collapse, IconButton, Menu, MenuItem, Popover, TextField } from '@mui/material'
import { useSelector } from 'react-redux'
import clsx from 'clsx'
import AddIcon from '@mui/icons-material/Add'
import { validateNotEmpty } from 'common/utils/validate'
import { sortArrayOfObjects } from 'common/utils/arrays'
import SettingsIcon from '@mui/icons-material/SettingsOutlined'
import ActionConfirm from 'common/components/ActionConfirm'
import * as viewSel from 'common/store/viewSelector'

export default function AssetViewsList({ aid, views, title, icon, description, onCreate, onUpdate, onDuplicate, onDelete, onSelect, keepOne = false, isDisabled = false, isDuplicateSharedDisabled = false }) {
	const { t } = useTranslation(['common'])

	// #### REDUX
	const currentId = useSelector((state) => viewSel.selectViewId(state, aid))
	const viewsArray = views ? Object.entries(views) : null
	const disableDelete = keepOne && !(viewsArray?.length > 1)

	// #### STATE
	const [isAddOpen, setIsAddOpen] = useState(false)
	const [isSettingsOpen, setIsSettingsOpen] = useState({ id: null, anchor: null })
	const [isDeleteOpen, setIsDeleteOpen] = useState(null)
	const [isNameOpen, setIsNameOpen] = useState({ id: null, anchor: null, action: null })
	const [inputValue, setInputValue] = useState('')
	const [inputValuePopover, setInputValuePopover] = useState('')
	const [validation, setValidation] = useState({ valid: true, error: '' })
	const [validationPopover, setValidationPopover] = useState({ valid: true, error: '' })

	// #### FUNCTIONS
	function onToggleAdd() {
		setIsAddOpen(!isAddOpen)
		setInputValue('')
	}

	function onSelectClick(vid) {
		onSelect(vid)
	}

	function onSaveClick() {
		let nameValid = validateNotEmpty(inputValue)
		setValidation(nameValid)
		if (nameValid.valid) {
			const content = { name: inputValue }
			onCreate(content)
			setInputValue('')
			setIsAddOpen(false)
		}
	}

	function onDeleteOpen() {
		setIsDeleteOpen(isSettingsOpen.id)
		setIsSettingsOpen({ id: null, anchor: null })
	}

	function onDeleteClick() {
		if (disableDelete) return
		onDelete(isDeleteOpen)
		setIsDeleteOpen(null)
	}

	function onDuplicateClick(type) {
		const action = type === 'shared' ? 'duplicateShared' : 'duplicatePersonal'
		setIsNameOpen({ ...isSettingsOpen, action })
		setIsSettingsOpen({ id: null, anchor: null })
	}

	function onRename() {
		const view = views[isSettingsOpen.id]
		setInputValuePopover(view.name)
		setIsNameOpen({ ...isSettingsOpen, action: 'rename' })
		setIsSettingsOpen({ id: null, anchor: null })
	}

	function onOpenSettings(e, id) {
		if (e.preventDefault) e.preventDefault()
		if (e.stopPropagation) e.stopPropagation()
		const elem = document.getElementById(`view#${id}`)
		setIsSettingsOpen({ id, anchor: elem })
	}

	function onNameSave() {
		let nameValid = validateNotEmpty(inputValuePopover)
		setValidationPopover(nameValid)
		if (nameValid.valid) {
			const name = inputValuePopover
			const action = isNameOpen.action
			const vid = isNameOpen.id
			if (action === 'duplicateShared' || action === 'duplicatePersonal') {
				const view = views[vid]
				const content = { ...view, name }
				const type = action === 'duplicateShared' ? 'shared' : 'personal'
				onDuplicate(type, content)
			} else if (action === 'rename') {
				const content = { name }
				onUpdate(vid, content)
			}
			setInputValuePopover('')
			setIsNameOpen({ id: null, anchor: null, action: null })
		}
	}

	return (
		<>
			<div className="flex flex-row items-center mb-2">
				{createElement(icon, { className: 'text-textGray w-[20px] h-[20px] mr-2' })}
				<span className="flex-1 text-sm font-medium text-textGray">{title}</span>
				<span className="text-xs mr-2 mt-[0.5px]">{description}</span>
				{!isDisabled && (
					<IconButton size="small" onClick={onToggleAdd} className={isAddOpen ? 'text-primary' : 'text-textGray'}>
						<AddIcon fontSize="small" />
					</IconButton>
				)}
			</div>

			<Collapse in={isAddOpen} classes={{ wrapperInner: 'flex flex-row items-center mb-4' }}>
				<TextField
					fullWidth
					variant="outlined"
					size="small"
					value={inputValue}
					onChange={(event) => setInputValue(event.target.value)}
					placeholder={t('common:views.viewName')}
					error={!validation.valid}
					inputProps={{ className: 'text-sm' }}
				/>
				<Button size="small" onClick={onSaveClick} variant="contained" className="ml-2 buttonContainedPrimary">
					{t('common:buttons.create')}
				</Button>
			</Collapse>

			{viewsArray &&
				sortArrayOfObjects(viewsArray, (entry) => entry[1]?.name || '', true, true)?.map((entry) => (
					<div
						id={`view#${entry[0]}`}
						key={`view#${entry[0]}`}
						className={clsx('flex flex-row justify-between items-center px-4 bg-bgGray rounded cursor-pointer hover:bg-borderGray mb-3 group', entry[0] === currentId && 'bg-borderGray')}
						onClick={() => onSelectClick(entry[0])}
					>
						<div className={clsx('text-sm py-2 truncate', entry[0] === currentId && 'font-medium')}>{entry[1].name}</div>

						{!isDisabled && (
							<IconButton className="text-textGray hidden group-hover:flex" size="small" onClick={(e) => onOpenSettings(e, entry[0])}>
								<SettingsIcon className="w-[15px] h-[15px]" />
							</IconButton>
						)}
					</div>
				))}

			<Menu
				anchorEl={isSettingsOpen?.anchor}
				open={Boolean(isSettingsOpen?.anchor)}
				onClose={() => setIsSettingsOpen({ id: null, anchor: null })}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			>
				<MenuItem dense onClick={onRename} className="text-sm">
					{t('common:buttons.rename')}
				</MenuItem>
				{!isDuplicateSharedDisabled && (
					<MenuItem dense onClick={() => onDuplicateClick('shared')} className="text-sm">
						{t('common:views.duplicateAsShared')}
					</MenuItem>
				)}
				<MenuItem dense onClick={() => onDuplicateClick('personal')} className="text-sm">
					{t('common:views.duplicateAsPersonal')}
				</MenuItem>
				<MenuItem dense onClick={onDeleteOpen} className="text-sm" disabled={disableDelete}>
					{t('common:buttons.delete')}
				</MenuItem>
			</Menu>
			<ActionConfirm open={Boolean(isDeleteOpen)} title={t('common:views.deleteConfirm')} onClose={() => setIsDeleteOpen(null)} onConfirm={onDeleteClick} />
			<Popover
				open={Boolean(isNameOpen?.anchor)}
				anchorEl={isNameOpen?.anchor}
				onClose={() => setIsNameOpen({ id: null, anchor: null, action: null })}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
				classes={{ paper: 'flex flex-row items-center shadow-md p-2 min-w-[200px]' }}
			>
				<TextField
					fullWidth
					autoFocus
					variant="outlined"
					size="small"
					value={inputValuePopover}
					onChange={(event) => setInputValuePopover(event.target.value)}
					placeholder={t('common:views.viewName')}
					error={!validationPopover.valid}
					inputProps={{ className: 'text-sm' }}
				/>
				<Button size="small" onClick={onNameSave} variant="contained" className="ml-2 buttonContainedPrimary">
					{t('common:buttons.save')}
				</Button>
			</Popover>
		</>
	)
}
