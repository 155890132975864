import { ASSET_ROLES } from 'common/constants/roles'
import { useTeamPermits } from 'common/hooks/useTeamPermits'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

export function useAssetPermits(aid) {
	const uid = useSelector((state) => state.auth.uid)
	const { canCreate, canEdit, canView } = useTeamPermits()
	const assetUser = useSelector((state) => (uid && aid && state.asset?.assetUsers && state.asset?.assetUsers[aid]?.result ? state.asset?.assetUsers[aid]?.result[uid] : null))

	const assetPermits = useMemo(() => {
		if (canCreate) return { canCreate: true, canEdit: true, canView: true }
		else if (canEdit || assetUser?.role === ASSET_ROLES.edit.key) return { canCreate: false, canEdit: true, canView: true }
		else if (canView || assetUser?.role === ASSET_ROLES.view.key) return { canCreate: false, canEdit: false, canView: true }
		else return { canCreate: false, canEdit: false, canView: false }
	}, [assetUser?.role, canCreate, canEdit, canView])

	return assetPermits
}
