import React, { useState, useEffect } from 'react'
import ModelConnectTable from './ModelConnectTable'
import { ClickAwayListener, Grow, IconButton, Popper, Tab, Tabs } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close'
import ModelConnectVariable from './ModelConnectVariable'

export default function ModelConnectData({ aid, position, onClose, varId, variable }) {
	const { t } = useTranslation(['common', 'model'])
	const sourceProps = variable?.sourceProps

	// #### STATE
	const [tab, setTab] = useState(0)
	useEffect(() => {
		setTab(sourceProps?.type === 'variable' ? 1 : 0)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceProps])

	// #### POPOVER CONTROL
	const isPopoverOpen = Boolean(position)

	return (
		<Popper open={isPopoverOpen} anchorEl={position} placement="right-start" transition>
			{({ TransitionProps }) => (
				<ClickAwayListener onClickAway={onClose}>
					<Grow {...TransitionProps}>
						<div className="flex flex-col pb-4 bg-white shadow-md w-[400px]">
							<div className="flex flex-row justify-between items-center border-b border-b-borderGray p-2 pl-4">
								<span className="text-md font-medium text-textGray">{sourceProps ? t('model:connectData.titleConnected') : t('model:connectData.title')}</span>
								<IconButton size="small" className="text-textGray" onClick={onClose}>
									<CloseIcon fontSize="small" />
								</IconButton>
							</div>
							<Tabs value={tab} onChange={(e, value) => setTab(value)} orientation="horizontal" className="tabs shrink-0" classes={{ indicator: 'tabsIndicator' }}>
								<Tab label={t('model:connectData.table')} className="tab" classes={{ selected: 'tabSelected' }} />
								<Tab label={t('model:connectData.variable')} className="tab" classes={{ selected: 'tabSelected' }} />
							</Tabs>
							{tab === 0 ? (
								<ModelConnectTable aid={aid} position={position} onClose={() => onClose(null, null)} varId={varId} variable={variable} />
							) : (
								<ModelConnectVariable aid={aid} position={position} onClose={() => onClose(null, null)} varId={varId} variable={variable} />
							)}
						</div>
					</Grow>
				</ClickAwayListener>
			)}
		</Popper>
	)
}
