export const THEMES = [
	'stheme-purple1',
	'stheme-blue1',
	'stheme-red1',
	'stheme-green1',
	'stheme-yellow1',
	'stheme-pink1',
	'stheme-orange1',
	'stheme-gray2',
	'stheme-gray1',
	'stheme-teal1',
	'stheme-cyan1',
	'stheme-purple2',
	'stheme-blue2',
	'stheme-red2',
	'stheme-green2',
	'stheme-yellow2',
	'stheme-pink2',
	'stheme-orange2',
	'stheme-teal2',
	'stheme-cyan2'
]

export const DEFAULT_THEME = 'stheme-purple1'
export const THEME_PREFIX = 'stheme-'
export const DEFAULT_CHART_COLOR = '#1d1d1d'
