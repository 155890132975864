import CreateFileCell from 'common/components/grid/cells/CreateFileCell'

export const cellFile = {
	component: CreateFileCell,
	config: {
		alignRight: false,
		formatBlurredInput: ({ value, typeProps }) => value,
		formatCopyValue: ({ value, typeProps }) => '',
		parseUserInput: ({ value, typeProps }) => value,
		parsePastedValue: ({ value, typeProps, localeFallback }) => null
	}
}
