import { useTranslation } from 'react-i18next'
import BrowseIcon from '@mui/icons-material/NoteAddOutlined'
import { useRef, useState } from 'react'
import { CloseIcon } from 'common/icons'
import { generateKey } from 'common/utils/uuid'

export default function CommunityImages({ images, setImages, validation }) {
	const { t } = useTranslation('common')
	const inputRef = useRef()
	const fileTypes = { 'image/jpeg': true, 'image/png': true }
	const fileTypesStr = Object.keys(fileTypes).reduce((accum, el, index) => (index > 0 ? accum + ', ' + el : el))

	// #### FUNCTIONS
	function browse() {
		const input = inputRef.current
		if (input) input.click()
	}

	function preview() {
		const input = inputRef.current
		if (!input) return
		const [file] = input.files
		if (!file) return
		const type = file.type
		const url = URL.createObjectURL(file)

		// Convert file to blob (required for firebase storage to work)
		fetch(url)
			.then((response) => response.blob())
			.then((blob) => {
				const newImages = [...images]
				newImages.push({ id: generateKey(6), type, url, blob })
				setImages(newImages)
			})
	}

	function removeFile(index) {
		const newImages = [...images]
		newImages.splice(index, 1)
		setImages(newImages)
	}

	return (
		<>
			<div className="grid grid-cols-imageUpload auto-rows-[100px] gap-[20px]">
				{images?.map((file, index) => (
					<div key={`communityImage#${index}`} className="relative">
						<img src={file.url} className="w-full h-full object-cover rounded" alt="" />
						<CloseIcon className="absolute right-1 top-1 w-[15px] h-[15px] bg-white/75 rounded-full cursor-pointer" onClick={() => removeFile(index)} />
					</div>
				))}

				<div className="bg-bgGray flex flex-col justify-center items-center cursor-pointer rounded" onClick={browse}>
					<BrowseIcon className="w-[25px] h-[25px] text-textGray" />
				</div>
			</div>
			{!validation?.valid && <div className="text-red-500 ml-4 mt-1 text-xs">{t(validation.error)}</div>}
			<input ref={inputRef} type="file" className="invisible w-0 h-0" onChange={preview} accept={fileTypesStr} />
		</>
	)
}
