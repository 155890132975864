import { Checkbox, Popover } from '@mui/material'
import { useSelector } from 'react-redux'
import { cloneDeep } from 'lodash'
import { useState, useCallback, useRef, useMemo } from 'react'
import clsx from 'clsx'
import DragIcon from '@mui/icons-material/DragIndicator'
import * as viewSel from 'common/store/viewSelector'
import * as modelSel from 'model/store/modelSelector'

export function ModelDrillDown({ aid, varId, position, onSave, onClose }) {
	const selectViewBreakdown = useMemo(() => viewSel.makeSelectVarBreakdown({ aid, vid: varId }), [aid, varId])
	const breakdown = useSelector((state) => selectViewBreakdown(state))

	return <ModelDrillDownMenu aid={aid} varId={varId} position={position} onSave={onSave} onClose={onClose} breakdown={breakdown} />
}

export function ChartDrillDown({ aid, cid, varId, position, onSave, onClose }) {
	const selectChartBreakdown = useMemo(() => viewSel.makeSelectChartBreakdown({ aid, cid, vid: varId }), [aid, cid, varId])
	const breakdown = useSelector((state) => selectChartBreakdown(state))

	return <ModelDrillDownMenu aid={aid} varId={varId} position={position} onSave={onSave} onClose={onClose} breakdown={breakdown} />
}

function ModelDrillDownMenu({ aid, varId, position, onSave, onClose, breakdown }) {
	// #### REDUX
	const categories = useSelector((state) => modelSel.selectModelCategoryIds(state, aid))

	const breakdownRef = useRef(breakdown)
	breakdownRef.current = breakdown

	// #### STATE
	const [dragIndex, setDragIndex] = useState(null)

	// #### POPOVER CONTROL
	const isPopoverOpen = Boolean(position)

	// #### FUNCTIONS
	function onChange(index, value) {
		let newBreakdown = cloneDeep(breakdown || [])
		newBreakdown[index] = { ...newBreakdown[index], active: value }
		onSave(varId, newBreakdown)
	}

	function onReorder(dragIndex, dropIndex) {
		let newBreakdown = cloneDeep(breakdownRef.current || [])
		const element = newBreakdown[dragIndex]
		const shift = dragIndex <= dropIndex ? 1 : 0
		newBreakdown.splice(dragIndex, 1)
		newBreakdown.splice(dropIndex - shift, 0, element)
		onSave(varId, newBreakdown)
	}

	// #### DRAG & DROP
	const onDragStart = useCallback((e, dragIndex) => {
		setDragIndex(dragIndex)
	}, [])

	const onDragEnd = useCallback((e) => {
		setDragIndex(null)
	}, [])

	const onDrop = useCallback((e, dragIndex, dropIndex) => {
		if (dragIndex === dropIndex) return
		onReorder(dragIndex, dropIndex)
		onDragLeave(e)
		onDragEnd(e)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const onDragEnter = useCallback((e, property) => {
		if (e.preventDefault) e.preventDefault()
		if (e.stopPropagation) e.stopPropagation()
		e.target.style[property] = '2px solid black'
	}, [])

	const onDragLeave = useCallback((e) => {
		if (e.preventDefault) e.preventDefault()
		if (e.stopPropagation) e.stopPropagation()
		e.target.style.borderTop = '0px'
		e.target.style.borderBottom = '0px'
	}, [])

	const onDragOver = useCallback((e) => {
		if (e.preventDefault) e.preventDefault()
		if (e.stopPropagation) e.stopPropagation()
	}, [])

	return (
		<Popover
			open={isPopoverOpen}
			anchorReference="anchorPosition"
			anchorPosition={position ? { top: position.y, left: position.x } : null}
			onClose={onClose}
			anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
			transformOrigin={{ vertical: 'top', horizontal: 'left' }}
			classes={{ paper: 'flex  shadow-md' }}
		>
			{isPopoverOpen && (
				<div className="flex-1 flex flex-col my-2">
					{breakdown?.map((cat, index) => {
						const catId = cat.catId
						const category = categories && categories.find((el) => el.id === catId)

						return (
							<div className="relative" key={`drilldown#${catId}`}>
								<div className="text-sm px-4 py-2 hover:bg-bgGray" onClick={() => onChange(index, !cat.active)} draggable={true} onDragStart={(e) => onDragStart(e, index)} onDragEnd={onDragEnd}>
									<div className="flex flex-row items-center">
										<Checkbox
											value="comms"
											onChange={(event) => onChange(index, event.target.checked)}
											checked={cat.active}
											className="p-0"
											classes={{ checked: '!text-contrast' }}
											size="small"
										/>
										<DragIcon fontSize="small" className="text-textGray ml-2 mr-2 cursor-move" />
										<div>{category.name}</div>
									</div>
								</div>
								<div
									onDrop={(e) => onDrop(e, dragIndex, index)}
									onDragEnter={(e) => onDragEnter(e, 'borderTop')}
									onDragLeave={(e) => onDragLeave(e)}
									onDragOver={(e) => onDragOver(e)}
									className={clsx('z-10 absolute top-0 left-0 bottom-1/2 right-0 bg-transparent')}
									style={{ display: dragIndex != null && dragIndex !== index ? 'block' : 'none' }}
								/>
								<div
									onDrop={(e) => onDrop(e, dragIndex, index + 1)}
									onDragEnter={(e) => onDragEnter(e, 'borderBottom')}
									onDragLeave={(e) => onDragLeave(e)}
									onDragOver={(e) => onDragOver(e)}
									className={clsx('z-10 absolute top-1/2 left-0 bottom-0 right-0 bg-transparent')}
									style={{ display: dragIndex != null && dragIndex !== index ? 'block' : 'none' }}
								/>
							</div>
						)
					})}
				</div>
			)}
		</Popover>
	)
}
