import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import common_en from 'common/locales/en.json'
import formula_en from 'common/locales/formula-en.json'
import model_en from 'model/locales/en.json'
import table_en from 'table/locales/en.json'
import automation_en from 'automation/locales/en.json'

i18n.use(initReactI18next)
	.use(LanguageDetector)
	.init({
		interpolation: { escapeValue: false }, // React already does escaping
		resources: {
			en: {
				common: common_en,
				formula: formula_en,
				model: model_en,
				table: table_en,
				automation: automation_en
			}
		},
		fallbackLng: 'en'
	})

export default i18n
