import { useTranslation } from 'react-i18next'
import { MenuItem, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material'
import PriceIcon from '@mui/icons-material/LocalOfferOutlined'
import { BILLING_CURRENCIES } from 'common/constants/plans'
import clsx from 'clsx'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'

export default function PlanConfig({ currency, frequency, onChangeCurrency, onChangeFrequency, className, centerSelector = true }) {
	const { t } = useTranslation('common')

	const customerFrequency = useSelector((state) => state.billing.customer?.data?.frequency)
	const customerCurrency = useSelector((state) => state.billing.customer?.data?.currency)

	useEffect(() => {
		if (customerCurrency) onChangeCurrency(customerCurrency)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customerCurrency])

	useEffect(() => {
		if (customerFrequency) onChangeFrequency(customerFrequency)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customerFrequency])

	if (!customerFrequency && !customerCurrency)
		return (
			<div className={clsx('w-full flex flex-row items-center relative', centerSelector ? 'justify-center' : 'justify-start', className)}>
				<ToggleButtonGroup size="small" value={frequency} exclusive onChange={onChangeFrequency}>
					<ToggleButton value="month" classes={{ root: 'normal-case', selected: '!bg-primaryLight' }}>
						{t('common:choosePlan.monthly')}
					</ToggleButton>
					<ToggleButton value="year" classes={{ root: 'normal-case', selected: '!bg-primaryLight !border-l-borderGray' }}>
						<span>{t('common:choosePlan.yearly')}</span>
						<PriceIcon className="ml-2 w-[15px] h-[15px] text-primary" />
						<span className="ml-2 text-primary ">{t('common:choosePlan.save')}</span>
					</ToggleButton>
				</ToggleButtonGroup>
				<div className="absolute right-0">
					<TextField select fullWidth variant="standard" size="small" value={currency} onChange={(e) => onChangeCurrency(e.target.value)} inputProps={{ className: 'text-sm' }}>
						{Object.values(BILLING_CURRENCIES).map((currency) => (
							<MenuItem key={currency.key} value={currency.key} className="text-sm">
								{`${t('common:choosePlan.pricesIn')} ${currency.symbol} ${currency.label}`}
							</MenuItem>
						))}
					</TextField>
				</div>
			</div>
		)
	else return void 0
}
