import { backend } from 'common/config/backend'
import { throwAPIError } from 'common/config/errors'
import { db } from 'common/config/firebase'
import { doc, writeBatch } from 'firebase/firestore'

const FILE_NAME = 'modelApi'

export async function updateVariable(tid, teamId, aid, vid, isPublish, updates) {
	const batch = writeBatch(db)

	if (isPublish) {
		const publishedVariableRefPath = `tenants/${tid}/teams/${teamId}/publishedVariables`
		const publishedVariableRef = doc(db, publishedVariableRefPath, vid)
		batch.update(publishedVariableRef, { ...updates }, { merge: true })
	}

	const variablePath = `tenants/${tid}/models/${aid}/variables`
	const variableRef = doc(db, variablePath, vid)

	batch.update(variableRef, { ...updates }, { merge: true })

	await batch.commit()
}

export async function deleteVariable(tid, teamId, aid, vid, isPublish) {
	const batch = writeBatch(db)

	if (isPublish) {
		const publishedVariableRefPath = `tenants/${tid}/teams/${teamId}/publishedVariables`
		const publishedVariableRef = doc(db, publishedVariableRefPath, vid)
		batch.delete(publishedVariableRef)
	}

	const variablePath = `tenants/${tid}/models/${aid}/variables`
	const variableRef = doc(db, variablePath, vid)
	batch.delete(variableRef)

	await batch.commit()
}

export async function setVariablePublic(tid, teamId, aid, vid, variable, updates) {
	const batch = writeBatch(db)

	const publishedVariableRefPath = `tenants/${tid}/teams/${teamId}/publishedVariables`
	const publishedVariableRef = doc(db, publishedVariableRefPath, vid)

	const variablePath = `tenants/${tid}/models/${aid}/variables`
	const variableRef = doc(db, variablePath, vid)
	if (updates['varProps.isPublish']) batch.set(publishedVariableRef, variable, { merge: true })
	else batch.delete(publishedVariableRef)
	batch.update(variableRef, { ...updates }, { merge: true })

	await batch.commit()
}

export async function createScheduler(token, tenantId, teamId, modelId, modelProps) {
	const params = { tenantId, teamId, modelId, modelProps }
	const headers = { headers: { Authorization: 'Bearer ' + token } }
	return backend
		.post('/scheduler', params, headers)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, createScheduler.name)
			return { data: resp.data?.data }
		})
		.catch((error) => {
			throwAPIError(error, error.response.status, FILE_NAME, createScheduler.name)
		})
}

export async function updateScheduler(token, tenantId, modelId, schedulerId, modelProps) {
	const params = { tenantId, modelId, schedulerId, modelProps }
	const headers = { headers: { Authorization: 'Bearer ' + token } }
	return backend
		.patch('/scheduler', params, headers)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, updateScheduler.name)
			return { data: resp.data?.data }
		})
		.catch((error) => {
			throwAPIError(error, error.response.status, FILE_NAME, updateScheduler.name)
		})
}

export async function deleteScheduler(token, tenantId, modelId, schedulerId) {
	const params = { data: { tenantId, modelId, schedulerId }, headers: { Authorization: 'Bearer ' + token } }
	return backend
		.delete('/scheduler', params)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, deleteScheduler.name)
			return { data: resp.data?.data }
		})
		.catch((error) => {
			throwAPIError(error, error.response.status, FILE_NAME, deleteScheduler.name)
		})
}

export async function exportVariable(token, tenantId, teamId, modelId, variableId) {
	const params = { tenantId, teamId, modelId, variableId }
	const headers = { headers: { Authorization: 'Bearer ' + token } }
	return backend
		.patch('/export', params, headers)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, exportVariable.name)
			return { data: resp.data?.data }
		})
		.catch((error) => {
			throwAPIError(error, error.response.status, FILE_NAME, exportVariable.name)
		})
}
