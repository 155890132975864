import { ERROR_CODE } from 'common/constants/errorsGeneral'
import { t } from 'i18next'

const log = (function (environment) {
	if (environment === 'production') return () => {}
	return (...args) => console.log(...args)
})(process.env.NODE_ENV)

const error = (function (environment) {
	if (environment === 'production') return () => {}
	return (...args) => console.error(...args)
})(process.env.NODE_ENV)

class APIException extends Error {
	constructor(message) {
		super(message)
		this.name = this.constructor.name
	}
}

function throwAPIError(exception, status, fileName, functionName) {
	error(exception, status, fileName, functionName)
	const code = ERROR_CODE[fileName] ? ERROR_CODE[fileName][functionName] : null
	const codeStr = code ? ` [${code}]` : ''
	const body = status === 507 ? t('common:error.limits') : status === 401 ? t('common:error.unauthorized') : t('common:error.unexpected')
	const message = body + codeStr
	throw new APIException(message)
}

function throwError(exception, fileName, functionName) {
	if (exception instanceof APIException) return exception.message
	error(exception, fileName, functionName)
	const code = ERROR_CODE[fileName] ? ERROR_CODE[fileName][functionName] : null
	const codeStr = code ? ` [${code}]` : ''
	const body = t('common:error.unexpected')
	const message = body + codeStr
	return message
}

export { log, error, throwAPIError, throwError }
