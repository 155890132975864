// #### GENERAL CHART CRUD
export const LOAD_CHARTS = 'modelChartSaga/LOAD_CHARTS'
export const CANCEL_CHARTS = 'modelChartSaga/CANCEL_CHARTS'
export const CREATE_CHART_HELPER = 'modelChartSaga/CREATE_CHART_HELPER'
export const UPDATE_CHART = 'modelChartSaga/UPDATE_CHART'
export const DELETE_CHART_HELPER = 'modelChartSaga/DELETE_CHART_HELPER'
// MANAGE CHARTS
export const CREATE_CHART = 'modelChartSaga/CREATE_CHART'
export const DELETE_CHART = 'modelChartSaga/DELETE_CHART'
export const MOVE_CHART = 'modelChartSaga/MOVE_CHART'
export const UPDATE_VARIABLE = 'modelChartSaga/UPDATE_VARIABLE'

// #### GENERAL CHART CRUD
export const loadCharts = ({ tid, aid }) => {
	return { type: LOAD_CHARTS, tid, aid, id: aid }
}

export const cancelCharts = ({ id }) => {
	return { type: CANCEL_CHARTS, id }
}

export const createChartHelper = ({ tid, aid, cid, content }) => {
	return { type: CREATE_CHART_HELPER, tid, aid, cid, id: cid, content }
}

export const updateChart = ({ tid, aid, cid, content }) => {
	return { type: UPDATE_CHART, tid, aid, cid, id: cid, content }
}

export const deleteChartHelper = ({ tid, aid, cid }) => {
	return { type: DELETE_CHART_HELPER, tid, aid, cid, id: cid }
}

// #### MANAGE CHARTS
export const createChart = ({ tid, teamId, aid, varId, chartType }) => {
	return { type: CREATE_CHART, tid, teamId, aid, varId, chartType }
}

export const deleteChart = ({ tid, aid, cid }) => {
	return { type: DELETE_CHART, tid, aid, cid }
}

export const moveChart = ({ tid, aid, tabIndex, charts }) => {
	return { type: MOVE_CHART, tid, aid, tabIndex, charts }
}

export const updateVariable = ({ tid, aid, vid, content }) => {
	return { type: UPDATE_VARIABLE, tid, aid, vid, content }
}
