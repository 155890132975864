import { DATE_FORMATS, TIME_FORMATS } from 'common/constants/formats'
import { FIRST_WEEKDAY, LOCALE_DATE_MASK } from 'common/constants/locales'
import { DateTime } from 'luxon'
import { FREQUENCY } from 'common/constants/frequencies'

// #### FUNCTIONS TO CONVERT DATES
// Used to conver firestore timestamps to javascript dates
export function fromTimestampToDate(timestamp) {
	return DateTime.fromMillis(timestamp.seconds * 1000).toUTC()
}

export function fromDateUTCToString(date, mask) {
	return DateTime.fromJSDate(date).toUTC().toFormat(mask)
}

export function fromDateToString(date, mask) {
	return DateTime.fromJSDate(date).toFormat(mask)
}

export function fromDateTimeToString(dateTime, mask) {
	return dateTime.toFormat(mask)
}

export function fromDateToDateTime(date) {
	return DateTime.fromJSDate(date).toUTC()
}

// Prepare a date object to be used as key map
export const fromDateToKey = (dateTime) => {
	if (!dateTime) return null
	const mask = 'yyyyMMddHH'
	const str = dateTime.toFormat(mask)
	return parseInt(str)
}

// #### FUNCTIONS TO PRINT DATES
export function printDate(dateTime, locale, mask) {
	const dateStr = dateTime.setLocale(locale).toFormat(mask.mask)
	return dateStr
}

export function printDateWithProps(dateTime, typeProps, localeFallback) {
	const hasDate = typeProps?.dateFormat
	const hasTime = typeProps?.includeTime
	const isFriendly = typeProps?.friendlyDate
	const dateProps = typeProps?.dateFormat ? DATE_FORMATS[typeProps.dateFormat] : null
	const timeProps = typeProps?.timeFormat ? TIME_FORMATS[typeProps.timeFormat] : null
	const locale = dateProps?.locale || localeFallback
	const localeMask = LOCALE_DATE_MASK[locale]
	const frequency = typeProps.frequency || FREQUENCY.day.key
	const mask = localeMask[isFriendly ? FREQUENCY[frequency].friendlyMask : FREQUENCY[frequency].mask]
	const printMask = `${hasDate ? mask : ''}${hasDate && hasTime ? ' ' : ''}${hasTime ? timeProps.mask : ''}`
	const formattedDate = dateTime.setLocale(locale).toFormat(printMask)
	return formattedDate
}

// #### FUNCTIONS TO WORK WITH PERIODS
function getWeekStart(dateTime, locale) {
	const fd = FIRST_WEEKDAY[locale]
	const day = dateTime.weekday % 7 // convert to 0=sunday .. 6=saturday
	const dayAdjust = day >= fd ? -day + fd : -day + fd - 7
	return dateTime.plus({ days: dayAdjust })
}

export function getDatePeriodStart(dateTime, type, locale) {
	// var newDateTime = dateTime.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
	var newDateTime = DateTime.utc(dateTime.year, dateTime.month, dateTime.day, 0, 0, 0, 0)
	if (type === FREQUENCY.week.key) return getWeekStart(newDateTime, locale)
	else return newDateTime.startOf(type)
}

export function addToDate(dateTime, type, amount) {
	const typePl = `${type}s`
	return dateTime.plus({ [typePl]: amount })
}

// Given a start and an end date, it returns an array with all dates in between
export function getDateIntervals(startDateTime, endDateTime, type) {
	var interval = startDateTime
	var result = []
	while (interval <= endDateTime) {
		result.push(interval)
		interval = addToDate(interval, type, 1)
	}
	return result
}

export function trucateDate(freq, date) {
	return DateTime.fromFormat(`${date}`, 'yyyyMMddHH').startOf(freq).toFormat('yyyyMMddHH')
}
