import { fork, call, put, select, takeEvery, spawn } from 'redux-saga/effects'
import * as sagaAction from 'table/saga-actions/tableDataActions'
import * as storeAction from 'table/store/tableDataReducer'
import * as api from 'table/api/tableDataApi'
import * as authApi from 'common/api/authApi'
import * as globalSagaAction from 'common/saga-actions/globalActions'
import * as globalStoreAction from 'common/store/globalReducer'
import { SEARCH_LIMIT } from 'common/constants/search'
import { generateKey } from 'common/utils/uuid'
import { t } from 'i18next'
import { throwError } from 'common/config/errors'
import * as viewSel from 'common/store/viewSelector'
import * as tableSel from 'table/store/tableSelector'
import { DATA_TYPES } from 'common/constants/dataTypes'

const FILE_NAME = 'tableDataSagas'

const getTeam = (state) => state.team.team
const getUid = (state) => state.auth.uid
const getUser = (state) => state.user.user
const getData = (state) => state.tableData.data
const getTotalRows = (state) => state.tableData.totalRows

// #### INITIAL DATA LOADING
function* clearDataReq() {
	yield takeEvery(sagaAction.CLEAR_DATA, clearData)
}

function* clearData(actionProps) {
	try {
		const { tid, teamId, aid } = actionProps
		const { token } = yield call(authApi.getAuthToken)
		yield call(api.clearData, token, tid, teamId, aid)
		yield put(sagaAction.loadData({tid, teamId, aid, page: 0, limit: 20, isInitial: true }))

	} catch (err) {
		const content = throwError(err, FILE_NAME, loadData.name)
		yield put(globalSagaAction.showMessage({ content }))
	}
}
function* loadDataReq() {
	yield takeEvery(sagaAction.LOAD_DATA, loadData)
}

function* loadData(actionProps) {
	try {
		const { tid, teamId, aid, sort: _sort, filter: _filter, page, limit, isInitial } = actionProps

		// Get data from store if it has not been passed as prop
		var filter = _filter
		if (filter == null) {
			const selectTableFilter = viewSel.makeSelectTableFilter({ aid })
			filter = yield select((state) => selectTableFilter(state))
		}

		var sort = _sort
		if (sort == null) {
			const selectTableSort = viewSel.makeSelectTableSort({ aid })
			sort = yield select((state) => selectTableSort(state))
		}

		// Query data
		const { token } = yield call(authApi.getAuthToken)
		const { data, count } = yield call(api.getData, token, tid, teamId, aid, sort, filter, page, limit, isInitial)
		const totalRows = isInitial ? count : null

		// Identify reference columns
		var referenceCols = []
		if (data?.length > 0) {
			Object.keys(data[0])?.forEach((colId) => {
				const split = colId.split('__')
				if (split?.length !== 2 || split[1] !== 'id') return
				const newColId = split[0]
				if (!referenceCols.includes(newColId)) referenceCols.push(newColId)
			})
		}

		// Format results: (1) Format reference cols (2) Put data in a map for lazy loading to work, where key = row number
		const dataMap = {}
		const pageIndex = page * limit
		data?.forEach((row, rIndex) => {
			let newRow = { ...row }
			referenceCols.forEach((refCol) => (newRow[refCol] = { id: row[`${refCol}__id`], value: row[refCol] }))
			dataMap[pageIndex + rIndex] = newRow
		})
		yield put(storeAction.loadData({ id: aid, data: dataMap, page, totalRows, isInitial }))
	} catch (err) {
		const content = throwError(err, FILE_NAME, loadData.name)
		yield put(globalSagaAction.showMessage({ content }))
	}
}

function* downloadCSVReq() {
	yield takeEvery(sagaAction.DOWNLOAD_CSV, downloadCSV)
}

function* downloadCSV(actionProps) {
	try {
		const { tid, teamId, aid, sort, filter } = actionProps
		yield put(globalStoreAction.loadingOn({ key: 'downloadCSV' }))
		yield put(globalSagaAction.showMessage({ key: `downloadCSV#${aid}`, content: t('table:messages.preparingFile'), messageType: 'info', isPersistent: false }))
		const { token } = yield call(authApi.getAuthToken)
		yield call(api.downloadCSV, token, tid, teamId, aid, sort, filter)
		yield put(globalStoreAction.loadingOff({ key: 'downloadCSV' }))
	} catch (err) {
		const content = throwError(err, FILE_NAME, downloadCSV.name)
		yield put(globalSagaAction.showMessage({ content }))
	}
}

// #### ROW OPERATIONS
function* userData() {
	const user = yield select(getUser)
	const userId = user?.id
	const userName = user?.data?.name
	const userSurname = user?.data?.surname
	const userFullName = `${userName}${userName && userSurname ? ' ' : ''}${userSurname ? userSurname : ''}`
	return { userId, userFullName }
}

function* addRowsReq() {
	yield takeEvery(sagaAction.ADD_ROWS, addRows)
}

function* addRows(triggeredAction) {
	const { tid, teamId, aid, inserts } = triggeredAction
	// Copy previous data
	const prevData = yield select(getData)
	const prevTotalRows = yield select(getTotalRows)

	try {
		const { userId, userFullName } = yield call(userData)
		const variables = yield select((state) => tableSel.selectTableVariables(state, aid))

		// Prepare data
		const newRows = inserts.map((insert) => {
			let newInsert = { ...insert }
			// Get ids for reference columns
			Object.entries(insert).forEach(([colId, colData]) => {
				const type = variables[colId]?.type
				if (colData != null && type === DATA_TYPES.reference.key) newInsert[colId] = colData.id
			})
			// Add metadata
			return {
				...newInsert,
				_created_by: userFullName,
				_created_by_id: userId,
				_created_at: new Date(),
				_update_at: new Date(),
				_update_by: userFullName,
				_update_by_id: userId
			}
		})
		// Save to backend
		const { token } = yield call(authApi.getAuthToken)
		const { data } = yield call(api.createRows, token, tid, teamId, aid, newRows)
		// Paste the data (used for reference columns)
		const newData = data?.map((row, index) => ({ ...row, ...inserts[index], id: row.id }))
		yield put(storeAction.addRows({ id: aid, data: newData }))
	} catch (err) {
		const content = throwError(err, FILE_NAME, addRows.name)
		yield put(globalSagaAction.showMessage({ content }))
		// If error, replace previous data
		yield put(storeAction.rollbackChange({ data: prevData, totalRows: prevTotalRows }))
	}
}

function* updateRowsReq() {
	yield takeEvery(sagaAction.UPDATE_ROWS, updateRows)
}

function* updateRows(triggeredAction) {
	const { tid, teamId, aid, updates } = triggeredAction
	// Copy previous data
	const variables = yield select((state) => tableSel.selectTableVariables(state, aid))
	const prevData = yield select(getData)
	const prevTotalRows = yield select(getTotalRows)
	try {
		const { userId, userFullName } = yield call(userData)
		// Update front before saving so that use can see changes instantly
		yield put(storeAction.updateRows({ id: aid, data: updates }))
		let newUpdates = { ...updates }
		// Prepare data
		Object.keys(updates).forEach((rowIndex) => {
			// Get ids for reference columns
			Object.entries(updates[rowIndex]).forEach(([colId, colData]) => {
				const type = variables[colId]?.type
				if (colData != null && type === DATA_TYPES.reference.key) newUpdates[rowIndex][colId] = colData.id
			})
			// Add metadata
			newUpdates[rowIndex] = { ...updates[rowIndex], _update_at: new Date(), _update_by: userFullName, _update_by_id: userId }
		})
		// Save to backend
		const { token } = yield call(authApi.getAuthToken)
		yield call(api.updateRows, token, tid, teamId, aid, newUpdates)
	} catch (err) {
		const content = throwError(err, FILE_NAME, updateRows.name)
		yield put(globalSagaAction.showMessage({ content }))
		// If error, replace previous data
		yield put(storeAction.rollbackChange({ data: prevData, totalRows: prevTotalRows }))
	}
}

function* deleteRowReq() {
	yield takeEvery(sagaAction.DELETE_ROW, deleteRow)
}

function* deleteRow(triggeredAction) {
	const { tid, teamId, aid, rowId, rowIndex } = triggeredAction
	try {
		const { token } = yield call(authApi.getAuthToken)
		yield call(api.deleteRow, token, tid, teamId, aid, rowId)
		yield put(storeAction.deleteRow({ id: aid, rowIndex }))
	} catch (err) {
		const content = throwError(err, FILE_NAME, deleteRow.name)
		yield put(globalSagaAction.showMessage({ content }))
	}
}

// #### SEARCH
function* searchColumnReq() {
	yield takeEvery(sagaAction.SEARCH_COLUMN, searchColumn)
}

function* searchColumn(triggeredAction) {
	const { key, tid, teamId, table, column, search, isInitial, nextPage } = triggeredAction
	try {
		// set loading on
		yield put(storeAction.search({ key, isLoading: true }))
		// get necessary data to make the search
		const { token } = yield call(authApi.getAuthToken)
		const maxNumResults = SEARCH_LIMIT.tableColumns
		// call search api
		const apiRes = yield call(api.search, token, tid, teamId, table, column, search, nextPage, maxNumResults)
		const result = apiRes?.data?.map((elem) => ({ id: elem.id, value: elem[column] }))
		const newNextPage = apiRes?.nextPage
		const notFound = !(result?.length > 0)
		// save result to store
		yield put(storeAction.search({ key, result, nextPage: newNextPage, notFound, isInitial, isLoading: false }))
	} catch (err) {
		const content = throwError(err, FILE_NAME, searchColumn.name)
		yield put(globalSagaAction.showMessage({ content }))
	}
}

// #### FILE OPERATIONS
function* uploadFileReq() {
	yield takeEvery(sagaAction.UPLOAD_FILE, uploadFile)
}

function* uploadFile(triggeredAction) {
	const { tid, teamId, aid, rowId, colId, rowIndex, file, fileName, fileSize, fileType } = triggeredAction
	try {
		// Check limits
		const team = yield select(getTeam)
		const kbSize = Math.ceil(fileSize / 1024)
		const consumption = (team?.consumption?.storage || 0) + kbSize
		if (consumption >= team?.limits?.storage) {
			yield put(storeAction.fileTransmit({ key: aid, loading: false, progress: 0, isError: true, error: t('common:error.limits', { field: t('common:limit.storage') }) }))
			return
		}

		// Set loading on
		yield put(storeAction.fileTransmit({ key: aid, loading: true, progress: 0, isError: false }))
		// Save to storage
		const uid = yield select(getUid)
		const fileId = generateKey(6)
		const filePath = `${tid}/table/${teamId}/${aid}/${colId}/${rowId}/${fileId}`
		yield fork(api.uploadFile, tid, teamId, aid, uid, rowId, colId, rowIndex, file, fileId, filePath, fileName, fileSize, fileType)
	} catch (err) {
		const content = throwError(err, FILE_NAME, uploadFile.name)
		yield put(globalSagaAction.showMessage({ content }))
	}
}

function* fileTransmitSuccessReq() {
	yield takeEvery(sagaAction.FILE_TRANSMIT_SUCCESS, fileTransmitSuccess)
}

function* fileTransmitSuccess(triggeredAction) {
	const { tid, teamId, aid, rowId, colId, rowIndex, fileId, fileName, fileSize, fileType, filePath, fileFullPath } = triggeredAction
	try {
		const _data = yield select(getData)
		const data = _data[aid]
		const currentFiles = data[rowIndex][colId]
		// Save to backend
		const kbSize = Math.ceil(fileSize / 1024)
		const fileProps = { id: fileId, name: fileName, size: kbSize, type: fileType, path: filePath, fullPath: fileFullPath }
		const newFiles = currentFiles ? [...currentFiles, fileProps] : [fileProps]
		const updates = { [rowIndex]: { id: rowId, [colId]: newFiles } }
		yield put(sagaAction.updateRows({ tid, teamId, aid, updates }))
		// Set loading off
		yield put(storeAction.fileTransmit({ key: aid, loading: false, progress: 0, isError: false, error: null }))
	} catch (err) {
		const content = throwError(err, FILE_NAME, fileTransmitSuccess.name)
		yield put(globalSagaAction.showMessage({ content }))
	}
}

function* deleteFileReq() {
	yield takeEvery(sagaAction.DELETE_FILE, deleteFile)
}

function* deleteFile(triggeredAction) {
	const { tid, teamId, aid, rowId, colId, rowIndex, fileId } = triggeredAction
	try {
		const filePath = `${tid}/table/${teamId}/${aid}/${colId}/${rowId}/${fileId}`
		// Save to backend
		const _data = yield select(getData)
		const data = _data[aid]
		const currentFiles = data[rowIndex][colId]
		const newFiles = currentFiles.filter((file) => file.id !== fileId)
		const updates = { [rowIndex]: { id: rowId, [colId]: newFiles } }
		yield put(sagaAction.updateRows({ tid, teamId, aid, updates }))
		// Save to storage
		yield fork(api.deleteFile, filePath)
	} catch (err) {
		const content = throwError(err, FILE_NAME, deleteFile.name)
		yield put(globalSagaAction.showMessage({ content }))
	}
}

export default function* root() {
	// #### INITIAL DATA LOADING
	yield spawn(loadDataReq)
	yield spawn(clearDataReq)
	// #### ROW OPERATIONS
	yield spawn(addRowsReq)
	yield spawn(updateRowsReq)
	yield spawn(deleteRowReq)
	yield spawn(downloadCSVReq)
	// #### SEARCH
	yield spawn(searchColumnReq)
	// #### FILE OPERATIONS
	yield spawn(uploadFileReq)
	yield spawn(fileTransmitSuccessReq)
	yield spawn(deleteFileReq)
}
