import globalReducer from 'common/store/globalReducer'
import authReducer from 'common/store/authReducer'
import userReducer from 'common/store/userReducer'
import teamReducer from 'common/store/teamReducer'
import spaceReducer from 'common/store/spaceReducer'
import assetReducer from 'common/store/assetReducer'
import communityReducer from 'common/store/communityReducer'
import navigationReducer from 'common/store/navigationReducer'
import billingReducer from 'common/store/billingReducer'
import viewReducer from 'common/store/viewReducer'
import connectorReducer from 'automation/store/connectorReducer'
import fileReducer from 'automation/store/fileReducer'
import sourceReducer from 'automation/store/sourceReducer'
import tableReducer from 'table/store/tableReducer'
import tableDataReducer from 'table/store/tableDataReducer'
import modelReducer from 'model/store/modelReducer'
import modelDataReducer from 'model/store/modelDataReducer'
import chartReducer from 'model/store/chartReducer'

// Combine all the reducers
const reducers = {
	global: globalReducer,
	auth: authReducer,
	user: userReducer,
	team: teamReducer,
	space: spaceReducer,
	asset: assetReducer,
	community: communityReducer,
	navigation: navigationReducer,
	billing: billingReducer,
	view: viewReducer,
	connector: connectorReducer,
	file: fileReducer,
	source: sourceReducer,
	table: tableReducer,
	tableData: tableDataReducer,
	model: modelReducer,
	modelData: modelDataReducer,
	chart: chartReducer
}

export default reducers
