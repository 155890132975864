import { useMemo } from 'react'
import { useLocation, matchPath, useParams } from 'react-router'
import { PRODUCTS } from 'common/constants/products'

export const useSplitWindow = (windowIndex = 0) => {
	let { aid, aid2 } = useParams()
	const { pathname } = useLocation()
	const isSplit = aid2 != null

	const primaryType = useMemo(
		() =>
			matchPath('/table/:aid/*', pathname) || matchPath('/embed/table/:aid/*', pathname)
				? PRODUCTS.table.key
				: matchPath('/model/:aid/*', pathname) || matchPath('/embed/model/:aid/*', pathname)
				? PRODUCTS.model.key
				: null,
		[pathname]
	)
	const secondaryType = useMemo(
		() =>
			matchPath('/table/:aid/table/:aid2', pathname) || matchPath('/model/:aid/table/:aid2', pathname)
				? PRODUCTS.table.key
				: matchPath('/model/:aid/model/:aid2', pathname) || matchPath('/table/:aid/model/:aid2', pathname)
				? PRODUCTS.model.key
				: null,
		[pathname]
	)
	const { id, type } = useMemo(
		() => (windowIndex === 0 ? { id: aid, type: primaryType } : windowIndex === 1 ? { id: aid2, type: secondaryType } : { id: null, type: null }),
		[aid, aid2, primaryType, secondaryType]
	)

	return { id, type, isSplit }
}
