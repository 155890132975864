// #### SUBSCRIBE TO VIEWS
export const LOAD_PERSONAL_VIEWS = 'viewSaga/LOAD_PERSONAL_VIEWS'
export const CANCEL_PERSONAL_VIEWS = 'viewSaga/CANCEL_PERSONAL_VIEWS'
export const LOAD_SHARED_VIEWS = 'viewSaga/LOAD_SHARED_VIEWS'
export const CANCEL_SHARED_VIEWS = 'viewSaga/CANCEL_SHARED_VIEWS'
// #### UPDATE VIEWS
export const CREATE_PERSONAL_VIEW = 'viewSaga/CREATE_PERSONAL_VIEW'
export const UPDATE_PERSONAL_VIEW = 'viewSaga/UPDATE_PERSONAL_VIEW'
export const DELETE_PERSONAL_VIEW = 'viewSaga/DELETE_PERSONAL_VIEW'
export const CREATE_SHARED_VIEW = 'viewSaga/CREATE_SHARED_VIEW'
export const UPDATE_SHARED_VIEW = 'viewSaga/UPDATE_SHARED_VIEW'
export const DELETE_SHARED_VIEW = 'viewSaga/DELETE_SHARED_VIEW'
// SET CONTENT
export const SET_IN_VIEW = 'viewSaga/SET_IN_VIEW'

// #### SUBSCRIBE TO VIEWS
export const loadPersonalViews = ({ tid, uid, aid }) => {
	return { type: LOAD_PERSONAL_VIEWS, tid, uid, aid, id: aid }
}

export const cancelPersonalViews = ({ id }) => {
	return { type: CANCEL_PERSONAL_VIEWS, id }
}

export const loadSharedViews = ({ tid, aid }) => {
	return { type: LOAD_SHARED_VIEWS, tid, aid, id: aid }
}

export const cancelSharedViews = ({ id }) => {
	return { type: CANCEL_SHARED_VIEWS, id }
}

// #### UPDATE VIEWS
export const createPersonalView = ({ tid, uid, aid, content }) => {
	return { type: CREATE_PERSONAL_VIEW, tid, uid, aid, content }
}

export const updatePersonalView = ({ tid, uid, aid, vid, content }) => {
	return { type: UPDATE_PERSONAL_VIEW, tid, uid, aid, vid, content }
}

export const deletePersonalView = ({ tid, uid, aid, vid }) => {
	return { type: DELETE_PERSONAL_VIEW, tid, uid, aid, vid }
}

export const createSharedView = ({ tid, aid, content }) => {
	return { type: CREATE_SHARED_VIEW, tid, aid, content }
}

export const updateSharedView = ({ tid, aid, vid, content }) => {
	return { type: UPDATE_SHARED_VIEW, tid, aid, vid, content }
}

export const deleteSharedView = ({ tid, aid, vid }) => {
	return { type: DELETE_SHARED_VIEW, tid, aid, vid }
}

// SET CONTENT
export const setInView = ({ aid, changes, permits }) => {
	return { type: SET_IN_VIEW, aid, changes, permits }
}
