import { useState, useCallback, useRef } from 'react'

export function useStateRef(initialValue) {
	const [variable, _setVariable] = useState(initialValue)

	const variableRef = useRef(variable)
	const setVariable = useCallback((data) => {
		variableRef.current = data
		_setVariable(data)
	}, [])

	return [variable, variableRef, setVariable]
}
