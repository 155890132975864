import { createSlice } from '@reduxjs/toolkit'

export const teamSlice = createSlice({
	name: 'team',
	initialState: {
		teams: null,
		teamId: null,
		team: null,
		teamUsers: null,
		teamCategories: null,
		teamRelationships: null,
		newTeamId: null // ID of a newly created team, used to redirect
	},
	reducers: {
		loadTeams: (state, action) => {
			state.teams = { loaded: true, ...action.payload }
			state.team = state.teamId && action.payload.result[state.teamId] ? action.payload.result[state.teamId] : null
		},
		cancelTeams: (state, action) => {
			state.teams = null
			state.team = null
			state.teamId = null
		},
		setTeamId: (state, action) => {
			state.teamId = action.payload
			state.team = state.teams?.result && action.payload ? state.teams.result[action.payload] : null
		},
		loadTeamUsers: (state, action) => {
			state.teamUsers = { loaded: true, ...action.payload }
		},
		cancelTeamUsers: (state, action) => {
			state.teamUsers = null
		},
		loadTeamCategories: (state, action) => {
			state.teamCategories = { loaded: true, ...action.payload }
		},
		cancelTeamCategories: (state, action) => {
			state.teamCategories = null
		},
		loadTeamRelationships: (state, action) => {
			state.teamRelationships = { loaded: true, ...action.payload }
		},
		cancelTeamRelationships: (state, action) => {
			state.teamRelationships = null
		},
		setNewTeamId: (state, action) => {
			state.newTeamId = action.payload
		}
	}
})

export const { loadTeams, cancelTeams, setTeamId, loadTeamUsers, cancelTeamUsers, loadTeamCategories, cancelTeamCategories, loadTeamRelationships, cancelTeamRelationships, setNewTeamId } = teamSlice.actions

export default teamSlice.reducer
