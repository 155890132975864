import { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { Button, Collapse } from '@mui/material'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { DATA_TYPES } from 'common/constants/dataTypes'
import TableTypeRefVariable from 'table/screens/type/TableTypeRefVariable'

export default function AssetSort({ aid, variables, isLoaded, onChange, sort, isAddOpen = true, onCloseAdd }) {
	const { t } = useTranslation(['common', 'table'])

	// #### STATE
	const [selectVariable, setSelectVariable] = useState('')

	// #### FUNCTIONS
	function onAddSort(e) {
		const variableId = selectVariable
		const variable = variableId && variableId !== '' && variables && variables[variableId]
		if (!variable) return
		const item = { id: variableId, direction: 'asc' }
		const newSort = sort ? [...sort, item] : [item]
		onChange(newSort)
		setSelectVariable('')
		if (onCloseAdd) onCloseAdd()
	}

	function onDeleteSort(index) {
		// Update filter
		var newSort = sort ? [...sort] : []
		newSort.splice(index, 1)
		onChange(newSort)
	}

	function onToggleSort(index) {
		var newSort = sort ? [...sort] : []
		newSort[index] = { ...newSort[index] }
		newSort[index].direction = newSort[index].direction === 'asc' ? 'desc' : 'asc'
		onChange(newSort)
	}

	return (
		<div className="bg-white flex flex-col">
			<Collapse in={isAddOpen} classes={{ wrapperInner: 'pt-2 flex flex-row items-center' }}>
				<TableTypeRefVariable
					aid={aid}
					variables={variables}
					types={Object.values(DATA_TYPES).map((el) => el.key)}
					onChange={(value) => setSelectVariable(value)}
					value={selectVariable}
					fieldName={t('table:search.sortField')}
					showLabel={true}
				/>
				<Button size="small" className="buttonText ml-2" onClick={onAddSort}>
					{t('common:buttons.add')}
				</Button>
			</Collapse>
			{isLoaded &&
				sort?.map((item, index) => {
					const variable = variables ? variables[item.id] : null
					return (
						<div key={`sort#${index}`} className={clsx('flex flex-row items-center justify-between my-2 pt-4', index !== 0 && 'border-t border-borderGray')}>
							<div className="text-sm flex flex-row items-center">{variable?.label}</div>
							<div className="flex flex-row items-center">
								<Button size="small" className="buttonTextPrimary text-sm h-[20px]" onClick={() => onToggleSort(index)}>
									{item.direction}
								</Button>
								<CloseIcon fontSize="small" className="text-textGray ml-2 cursor-pointer w-[15px] h-[15px]" onClick={() => onDeleteSort(index)} />
							</div>
						</div>
					)
				})}
		</div>
	)
}
