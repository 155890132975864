import GridTabs from 'common/components/grid/GridTabs'
import { PRODUCTS } from 'common/constants/products'
import AssetViews from 'common/screens/asset/AssetViews'
import { generateKey } from 'common/utils/uuid'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateTable, deleteTab } from 'table/saga-actions/tableActions'
import { selectTab } from 'table/store/tableReducer'

export default function TableTabs({ aid, isDesigning }) {
	const dispatch = useDispatch()

	// #### REDUX
	const tid = useSelector((state) => state.auth.tid)
	const tabs = useSelector((state) => state.table.table[aid]?.data?.tabs)
	const tabIndex = useSelector((state) => state.table.tabIndex[aid]) || 0

	// #### STATE
	const [tabsWithInfo, setTabsWithInfo] = useState([])

	// #### EFFECTS
	useEffect(() => {
		if (tabs) {
			// Information for each tab
			const tabsWithInfo = tabs.map((tab) => ({ ...tab, info: tab.variables?.length || 0 }))
			setTabsWithInfo(tabsWithInfo)
		}
	}, [tabs])

	function onSelectTab(tabIndex) {
		dispatch(selectTab({ id: aid, tabIndex }))
	}

	function onCreateTab() {
		const newTab = { id: generateKey(6), name: t('common:defaults.newTab') }
		const newTabs = tabs ? [...tabs, newTab] : [newTab]
		dispatch(updateTable({ tid, aid, content: { tabs: newTabs } }))
	}

	function onRenameTab(value, index) {
		if (!(value?.length > 0) || tabs[index] === value) return
		var newTabs = [...tabs]
		newTabs[index] = { ...tabs[index], name: value }
		dispatch(updateTable({ tid, aid, content: { tabs: newTabs } }))
	}

	function onDeleteTab(tabIndex) {
		dispatch(deleteTab({ tid, aid, tabIndex }))
	}

	function onMoveTab(dragIndex, dropIndex) {
		const tab = tabs[dragIndex]
		var newTabs = [...tabs]
		const shift = dragIndex < dropIndex ? 1 : 0
		newTabs.splice(dragIndex, 1)
		newTabs.splice(dropIndex - shift, 0, tab)
		dispatch(updateTable({ tid, aid, content: { tabs: newTabs } }))
	}

	return (
		<GridTabs
			tabs={tabsWithInfo}
			activeTab={tabIndex}
			showInfo={true}
			isDesigning={isDesigning}
			onSelect={onSelectTab}
			onCreate={onCreateTab}
			onRename={onRenameTab}
			onDelete={onDeleteTab}
			onMove={onMoveTab}
			rightContent={<AssetViews aid={aid} product={PRODUCTS.table.key} />}
		/>
	)
}
