import { createSelector } from '@reduxjs/toolkit'

// #### DIRECT SELECTORS
// Teams
export const selectNewTeamPending = (state) => state.team.newTeamId
export const selectTeams = (state) => state.team.teams?.result
export const selectCategories = (state) => state.team.teamCategories?.result

// #### DERIVATIVE SELECTORS
export const selectNewTeamId = createSelector([selectNewTeamPending, selectTeams], (newTeamId, teams) => {
	if (!newTeamId || !teams) return null
	if (teams[newTeamId]) return newTeamId
	else return null
})
