import { GridShadow } from 'common/components/grid/GridShadow'
import { InnerContainer } from 'common/components/grid/InnerContainer'
import { SelectionContext } from 'common/components/grid/SelectionContext'
import { SelectionRect } from 'common/components/grid/SelectionRect'
import { useBoundingClientRect } from 'common/hooks/grid/useBoundingClientRect'
import { useEdges } from 'common/hooks/grid/useEdges'
import { useMemoObject } from 'common/hooks/grid/useMemoObject'
import { useStateRef } from 'common/hooks/grid/useStateRef'
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AutoSizer from 'react-virtualized-auto-sizer'
import { VariableSizeGrid } from 'react-window'
import { AddIcon } from 'common/icons/index'
import { StickyContext } from 'common/components/grid/StickyContext'
import ContextMenu from 'common/components/ContextMenu'
import * as copyPasting from 'common/utils/copyPasting'
import { DATA_TYPES } from 'common/constants/dataTypes'
import useLocale from 'common/hooks/useLocale'
import FormulaBar from '../formula/FormulaBar'
import { isEqual } from 'lodash'

const Grid = forwardRef(
	(
		{
			items,
			columns,
			columnProps,
			rowCount,
			colCount,
			defaultRowHeight = 40,
			defaultColWidth = 100,
			data,
			disableKeyboard = false,
			showFormulaBar = false,
			showAddRowButton = false,
			canPasteExceedGrid = false,
			expandDirection = 'all',
			expandStickyCol = false,
			hasInsertRow = false,
			intermediateCell = null, // {col, row}
			// Callbacks called by grid - need a Ref
			getCellTypeFunction,
			onColumnResizeFunction,
			onDropFunction,
			onExpandCell,
			disableCellFunction,
			getFormulaFunction,
			getContextMenuFunction,
			onGridSizeChange,
			onGridItemsRendered,
			onPasteFunction,
			onCopyFunction,
			onDeleteFunction,
			onSetActiveCell,
			onSetSelection,
			// Cell renderer
			Cell,
			CellStickyColumn,
			CellStickyRow,
			CellStickyTopLeft
		},
		ref
	) => {
		// ###########################
		// #### DECLARATIVE METHODS TO EXPOSE TO PARENT
		// ###########################
		useImperativeHandle(ref, () => ({
			scrollTo
		}))

		// ###########################
		// #### CONFIGURATION
		// ###########################
		const { t } = useTranslation(['common'])
		const locale = useLocale()
		const localeRef = useRef(locale)
		localeRef.current = locale

		const MINIMUM_COLUMN_SIZE = 50
		const stickyRows = 1
		const stickyCols = 1

		// ###########################
		// #### REFERENCES
		// ###########################
		// Refs to UI elements
		const grid = useRef(null)
		const gridInnerRef = useRef(null)
		const gridOuterRef = useRef(null)
		const formulaBarRef = useRef(null)
		const stickyCellsRef = useRef(null)
		const getInnerBoundingClientRect = useBoundingClientRect(gridInnerRef)
		const getOuterBoundingClientRect = useBoundingClientRect(gridOuterRef)

		// Refs to row and column counts
		const stickyRowsRef = useRef()
		stickyRowsRef.current = stickyRows
		const stickyColsRef = useRef()
		stickyColsRef.current = stickyCols
		const rowCountRef = useRef()
		rowCountRef.current = rowCount
		const colCountRef = useRef()
		colCountRef.current = colCount
		const columnsRef = useRef(columns)
		columnsRef.current = columns

		// Refs to other props
		const disableKeyboardRef = useRef()
		disableKeyboardRef.current = disableKeyboard
		const intermediateCellRef = useRef()
		intermediateCellRef.current = intermediateCell

		// Refs to functions
		const callbacksRef = useRef({
			getCellTypeFunction,
			onColumnResizeFunction,
			onDropFunction,
			onExpandCell,
			disableCellFunction,
			getFormulaFunction,
			getContextMenuFunction,
			onGridSizeChange,
			onGridItemsRendered,
			onPasteFunction,
			onCopyFunction,
			onDeleteFunction,
			onSetActiveCell,
			onSetSelection
		})
		callbacksRef.current.getCellTypeFunction = getCellTypeFunction
		callbacksRef.current.onColumnResizeFunction = onColumnResizeFunction
		callbacksRef.current.onDropFunction = onDropFunction
		callbacksRef.current.onExpandCell = onExpandCell
		callbacksRef.current.disableCellFunction = disableCellFunction
		callbacksRef.current.getFormulaFunction = getFormulaFunction
		callbacksRef.current.getContextMenuFunction = getContextMenuFunction
		callbacksRef.current.onGridSizeChange = onGridSizeChange
		callbacksRef.current.onGridItemsRendered = onGridItemsRendered
		callbacksRef.current.onPasteFunction = onPasteFunction
		callbacksRef.current.onCopyFunction = onCopyFunction
		callbacksRef.current.onDeleteFunction = onDeleteFunction
		callbacksRef.current.onSetActiveCell = onSetActiveCell
		callbacksRef.current.onSetSelection = onSetSelection

		// ###########################
		// #### GRID SIZE
		// ###########################
		const [viewHeight, setViewHeight] = useState(0)
		const [viewWidth, setViewWidth] = useState(0)

		const onGridResize = useCallback(({ height, width }) => {
			setViewWidth(width)
			setViewHeight(height)
			if (callbacksRef.current.onGridSizeChange) callbacksRef.current.onGridSizeChange({ height, width })
		}, [])

		const edges = useEdges(gridOuterRef, viewWidth, viewHeight)

		// ###########################
		// #### VARIABLE COLUMN WIDTHS
		// ###########################
		const [columnWidths, columnWidthsRef, setColumnWidths] = useStateRef(columns?.map((col) => (columnProps && columnProps[col.id]?.width) || defaultColWidth))
		useEffect(
			() => setColumnWidths(columns?.map((col) => (columnProps && columnProps[col.id]?.width) || defaultColWidth)),
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[columns, columnProps]
		)
		useEffect(() => {
			if (grid.current) grid.current.resetAfterColumnIndex(0)
		}, [columnWidths])

		// Array with distance to left for each column
		const calcAccumulated = (array) => {
			let total = 0
			return array.map((w, i) => {
				total += w
				return total
			})
		}
		const [columnRights, columnRightsRef, setColumnRights] = useStateRef(calcAccumulated(columnWidths))
		useEffect(() => {
			setColumnRights(calcAccumulated(columnWidths))
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [columnWidths])

		// ###########################
		// #### COLUMN RESIZING
		// ###########################
		var resizePos = 0
		var resizeColumnIndex = 0
		var resizeWidth = 0

		const resize = useCallback((e) => {
			if (e.preventDefault) e.preventDefault()
			if (e.stopPropagation) e.stopPropagation()

			const dx = resizePos - e.x
			resizePos = e.x
			const newWidth = resizeWidth - dx

			if (newWidth >= MINIMUM_COLUMN_SIZE) {
				resizeWidth = newWidth
				var newWidths = [...columnWidthsRef.current]
				newWidths[resizeColumnIndex] = newWidth
				setColumnWidths(newWidths)
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])

		const onColumnResize = useCallback((e, columnIndex) => {
			if (e.preventDefault) e.preventDefault()
			if (e.stopPropagation) e.stopPropagation()
			isResizing = true
			document.addEventListener('mousemove', resize)
			setActiveCell(null)
			resizeColumnIndex = columnIndex
			resizePos = e.x
			resizeWidth = columnWidthsRef.current[columnIndex]
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])

		// ###########################
		// #### CELL DRAG & DROP
		// ###########################
		const [dragIndex, dragIndexRef, setDragIndex] = useStateRef(null)

		const onDragEnter = useCallback((e) => {
			if (e.preventDefault) e.preventDefault()
			if (e.stopPropagation) e.stopPropagation()
			e.target.classList.add('dragover')
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])

		const onDragLeave = useCallback((e) => {
			if (e.preventDefault) e.preventDefault()
			if (e.stopPropagation) e.stopPropagation()
			e.target.classList.remove('dragover')
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])

		const onDragOver = useCallback((e) => {
			if (e.preventDefault) e.preventDefault()
			if (e.stopPropagation) e.stopPropagation()
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])

		const onDrop = useCallback((e, col, row) => {
			if (e.preventDefault) e.preventDefault()
			if (e.stopPropagation) e.stopPropagation()
			e.target.classList.remove('dragover')
			const dragIndex = dragIndexRef.current
			const dropIndex = { col, row }
			if (callbacksRef.current.onDropFunction) callbacksRef.current.onDropFunction(dragIndex, dropIndex)
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])

		const onDragStart = useCallback((e, col, row) => {
			if (e.stopPropagation) e.stopPropagation()
			setDragIndex({ col, row })
			// var elem = document.getElementById('cellWrapper#' + dragIndex.col + '#' + dragIndex.row)
			// e.dataTransfer.setDragImage(elem, 0, 0)
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])

		const onDragEnd = useCallback((e) => {
			if (e?.preventDefault) e.preventDefault()
			if (e?.stopPropagation) e.stopPropagation()
			setDragIndex(null)
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])

		// ###########################
		// #### ACTIVE & SELECTED CELLS TRACKING
		// ###########################
		// Highlighted cell, null when not focused
		const [activeCell, activeCellRef, _setActiveCell] = useStateRef(null) // { row: 0, col: 0 }
		// The selection cell and the active cell are the two corners of the selection, null when nothing is selected
		const [selectionCell, selectionCellRef, setSelectionCell] = useStateRef(null) // { row: 0, col: 0 }
		const [expansionCell, expansionCellRef, setExpansionCell] = useStateRef(null) // { row: 0, col: 0 }
		const [selection, selectionRef, setSelection] = useStateRef(null)
		const [expansion, expansionRef, setExpansion] = useStateRef(null)

		// If selected row or column is deleted, set active cell to null
		useEffect(() => {
			if (!activeCell) return
			if (activeCell.row >= rowCount || activeCell.col >= colCount) setActiveCell(null)
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [rowCount, colCount])

		// Min and max of the current selection (rectangle defined by the active cell and the selection cell), null when nothing is selected
		useEffect(() => {
			let newSelection = null
			if (activeCell && selectionCell)
				newSelection = {
					min: { col: Math.min(activeCell.col, selectionCell.col), row: Math.min(activeCell.row, selectionCell.row) },
					max: { col: Math.max(activeCell.col, selectionCell.col), row: Math.max(activeCell.row, selectionCell.row) }
				}
			if (!isEqual(newSelection, selection)) setSelection(newSelection)
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [activeCell, selectionCell])

		// Callback outside function when selection changes
		useEffect(() => {
			if (callbacksRef?.current?.onSetSelection) callbacksRef.current.onSetSelection(selection)
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [selection])

		useEffect(() => {
			if (activeCell && expansionCell)
				setExpansion({
					min: { col: Math.min(activeCell.col, expansionCell.col), row: Math.min(activeCell.row, expansionCell.row) },
					max: { col: Math.max(activeCell.col, expansionCell.col), row: Math.max(activeCell.row, expansionCell.row) }
				})
			else setExpansion(null)
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [activeCell, expansionCell])

		const setActiveCell = useCallback((data) => {
			const newD = data
				? {
						col: data.col != null ? Math.max(0, Math.min(colCountRef.current - 1, data.col)) : activeCellRef.current?.col,
						row: data.row != null ? Math.max(0, Math.min(rowCountRef.current - 1, data.row)) : activeCellRef.current?.row
				  }
				: null

			_setActiveCell(newD)
			setSelectionCell(null)
			setExpansionCell(null)
			stopEditing(false)
			if (callbacksRef.current.onSetActiveCell) callbacksRef.current.onSetActiveCell(newD)
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])

		const select = useCallback((e) => {
			if (e.preventDefault) e.preventDefault()
			if (e.stopPropagation) e.stopPropagation()

			const cursorIndex = getCursorIndex(e, false)
			const activeCell = activeCellRef?.current

			if (!activeCell || !cursorIndex) return
			setSelectionCell({ col: cursorIndex.col, row: cursorIndex.row })
			stopEditing(false)
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])

		const expand = useCallback((e) => {
			if (e.preventDefault) e.preventDefault()
			if (e.stopPropagation) e.stopPropagation()

			const cursorIndex = getCursorIndex(e, false)
			const activeCell = activeCellRef?.current

			if (!activeCell || !cursorIndex) return
			const isVertical = expandDirection === 'vertical' || expandDirection === 'all'
			const isHorizontal = expandDirection === 'horizontal' || expandDirection === 'all'

			const adjustedRow = isVertical ? Math.max(cursorIndex.row, stickyRows) : activeCell.row // do not allow to expand to a sticky row
			const adjustedCol = isHorizontal ? Math.max(cursorIndex.col, stickyCols) : activeCell.col // do not allow to expand to a sticky col

			setExpansionCell({ col: adjustedCol, row: adjustedRow })
			stopEditing(false)
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])

		// ###########################
		// #### SCROLLING
		// ###########################
		// Scroll to any given cell making sure it is in view
		const scrollTo = useCallback(
			(cell) => {
				if (!viewHeight || !viewWidth) return

				const scrollTop = grid.current?.state.scrollTop
				const scrollLeft = grid.current?.state.scrollLeft
				var newScrollTop = scrollTop
				var newScrollLeft = scrollLeft

				const stickyRow = stickyRowsRef.current || 0
				const stickyCol = stickyColsRef.current || 0

				// Vertical scroll
				if (!cell.doNotScrollY && cell.row >= stickyRow) {
					const topMax = defaultRowHeight * cell.row - defaultRowHeight * stickyRows
					const topMin = defaultRowHeight * (cell.row + 1) - viewHeight
					if (scrollTop > topMax) newScrollTop = topMax
					else if (scrollTop < topMin) newScrollTop = topMin
				}

				// Horizontal scroll
				if (!cell.doNotScrollX && columnRights && cell.col >= stickyCol) {
					const leftMax = (columnRights[cell.col - 1] || 0) - (columnRights[stickyCols - 1] || 0)
					const leftMin = columnRights[cell.col] - viewWidth
					if (scrollLeft > leftMax) newScrollLeft = leftMax
					else if (scrollLeft < leftMin) newScrollLeft = leftMin
				}

				if (scrollTop !== newScrollTop || scrollLeft !== newScrollLeft) grid.current?.scrollTo({ scrollLeft: newScrollLeft, scrollTop: newScrollTop })
			},
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[viewHeight, viewWidth, columnRights, stickyCols, stickyRows]
		)

		// Scroll to the selectionCell cell when it changes
		useEffect(() => {
			if (selectionCell) scrollTo(selectionCell)
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [selectionCell])

		// Scroll to the expansionCell cell when it changes
		useEffect(() => {
			if (expansionCell) scrollTo(expansionCell)
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [expansionCell])

		// Scroll to the active cell when it changes
		useEffect(() => {
			if (activeCell) scrollTo(activeCell)
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [activeCell])

		// // ###########################
		// // #### EDITING
		// // ###########################
		const [editing, editingRef, setEditing] = useStateRef({ cell: null, prevCell: null, is: false, cancel: false, autoSelect: false })
		const [editingBar, editingBarRef, setEditingBar] = useStateRef({ cell: null, is: false, cancel: false, autoSelect: false })

		const startEditing = useCallback((cell, autoSelect = false) => {
			setSelectionCell(null)
			const activeCell = activeCellRef?.current
			if (cell.col !== activeCell.col || cell.row !== activeCell.row) setActiveCell(cell)
			if (!isCellDisabled(cell)) setEditing({ cell, prevCell: null, is: true, cancel: false, autoSelect: autoSelect })
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])

		const startEditingBar = useCallback(() => {
			setSelectionCell(null)
			const activeCell = activeCellRef?.current
			if (!isCellDisabled(activeCell)) setEditingBar({ cell: activeCell, is: true, cancel: false, autoSelect: false })
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])

		const stopEditing = useCallback((nextRow = true, cancel = false) => {
			const prevCell = editingRef.current.cell
			if (!prevCell) return
			const activeCell = activeCellRef?.current
			setEditing({ cell: null, prevCell, is: false, cancel: cancel, autoSelect: false })
			if (nextRow && activeCell) setActiveCell({ col: activeCell.col, row: activeCell.row + 1 })
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])

		const stopEditingBar = useCallback((cancel = false) => {
			setEditingBar({ cell: null, is: false, cancel: cancel, autoSelect: false })
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])

		const isCellDisabled = useCallback((cell) => {
			const disableCellCallback = callbacksRef.current.disableCellFunction
			if (!disableCellCallback) return false
			return disableCellCallback(cell.row, cell.col)
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])

		// // ###########################
		// // #### EXPANDING
		// // ###########################
		const onExpand = useCallback((expansionCell) => {
			const activeCell = activeCellRef.current
			callbacksRef.current.onExpandCell(activeCell.row, activeCell.col, expansionCell.row, expansionCell.col)
			setExpansionCell(null)
			setSelectionCell({ col: expansionCell.col, row: expansionCell.row })
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])

		// ###########################
		// #### MOUSE INTERACTION
		// ###########################
		var isSelecting = false
		var isExpanding = false
		var isResizing = false

		const getCursorIndex = useCallback((e, force = false) => {
			const innerBoundingClientRect = getInnerBoundingClientRect(force) //considers scroll
			const outerBoundingClientRect = getOuterBoundingClientRect(force) //does not consider scroll

			if (innerBoundingClientRect && outerBoundingClientRect) {
				let x = e.clientX - innerBoundingClientRect.left
				let y = e.clientY - innerBoundingClientRect.top

				let outerX = e.clientX - outerBoundingClientRect.left
				let outerY = e.clientY - outerBoundingClientRect.top

				// Identify clicked cell
				var col = columnRightsRef.current.findIndex((right) => x < right)
				var row = Math.floor(y / defaultRowHeight)

				// Check if click is on sticky column or row
				var outerCol = columnRightsRef.current.findIndex((right) => outerX < right)
				if (outerCol >= 0 && outerCol < stickyColsRef.current) col = outerCol

				var outerRow = outerY < defaultRowHeight ? 0 : -1
				if (outerY < defaultRowHeight && stickyRowsRef.current > 0) row = outerRow

				// Check if index is out of range
				if (col < 0) col = columnRightsRef.current.length - 1
				if (row >= rowCountRef.current) row = rowCountRef.current - 1
				return { col: col, row: row }
			}
			return null
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])

		const hasClickedInside = useCallback((e) => {
			return gridInnerRef?.current?.contains(e.target) || stickyCellsRef?.current?.contains(e.target)
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])

		const onCellClick = useCallback((e) => {
			const activeCell = activeCellRef?.current
			const editing = editingRef?.current?.is
			const cursorIndex = getCursorIndex(e, true)

			// Right click (let context menu event to handle it)
			const rightClick = e.button === 2
			if (rightClick) return true

			// Click on active cell to enable editing
			const clickOnActiveCell = (cursorIndex && activeCell && activeCell.col === cursorIndex.col && activeCell.row === cursorIndex.row && !isCellDisabled(activeCell)) || false
			if (clickOnActiveCell && editing) return true
			else if (clickOnActiveCell) {
				if (e.preventDefault) e.preventDefault()
				if (e.stopPropagation) e.stopPropagation()
				startEditing(activeCell)
			}
			// Click on new cell
			else setActiveCell(cursorIndex)
			return false
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])

		const onMouseDown = useCallback((e) => {
			const activeCell = activeCellRef?.current
			// const activeCellFormula = activeCellFormulaRef?.current
			const editing = editingRef?.current?.is
			const editingBar = editingBarRef?.current?.is

			let target = e.target
			while (!(target instanceof HTMLElement)) target = target.parentElement

			// Click on general formula bar
			const clickFormulaBar = formulaBarRef?.current?.contains(e.target)
			if (clickFormulaBar) {
				// If formula bar is already being edited, do nothing
				if (editingBar) return
				// Else, edit the bar
				if (activeCell) {
					const formula = callbacksRef.current.getFormulaFunction(activeCell.row, activeCell.col)
					if (formula.canHaveFormula) startEditingBar()
				}
				// If a cell was being edited, disable edition
				if (editing) stopEditing(false)
				return
			} else if (editingBar) stopEditingBar(false)

			// Click on codemirror editor, do nothing
			let targetCM = target
			let targetCMIndex = 0
			while (!(targetCM.id.startsWith('cm-') || targetCM.id.startsWith('cmbar-') || targetCMIndex > 5)) {
				if (targetCM.parentElement) targetCM = targetCM.parentElement
				targetCMIndex += 1
			}
			if (targetCM.id.startsWith('cm-')) {
				// enable editing if necessary
				if (!editing && !editingBar && activeCell) startEditing(activeCell)
				return
			}
			if (targetCM.id.startsWith('cmbar-')) {
				// enable editing if necessary
				if (!editing && !editingBar && activeCell) startEditingBar()
				return
			}

			// Click outside grid
			const clickInside = hasClickedInside(e)
			if (contextMenuRef.current) return
			if (!clickInside && !activeCell) return
			if (!clickInside && activeCell) {
				setActiveCell(null)
				return
			}

			// Click on draggable cell
			if (e.target instanceof HTMLElement && e.target.className.includes('grid-draggable')) {
				return
			}

			// Click on cell expand
			if (e.target instanceof HTMLElement && e.target.className.includes('grid-expand-rows-indicator')) {
				if (e.preventDefault) e.preventDefault()
				if (isCellDisabled(activeCell)) return
				isExpanding = true
				document.addEventListener('mousemove', expand)
				return
			}

			if (onCellClick(e)) return

			isSelecting = true
			document.addEventListener('mousemove', select)
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])

		const onMouseUp = useCallback((e) => {
			// Column resize
			if (isResizing) {
				isResizing = false
				document.removeEventListener('mousemove', resize)
				callbacksRef.current.onColumnResizeFunction && callbacksRef.current.onColumnResizeFunction(resizeColumnIndex, resizeWidth)
			}
			if (isSelecting) {
				isSelecting = false
				document.removeEventListener('mousemove', select)
			}
			if (isExpanding) {
				isExpanding = false
				const expansionCell = expansionCellRef.current
				if (expansionCell) onExpand(expansionCell)
				document.removeEventListener('mousemove', expand)
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])

		const onDoubleClick = useCallback((e) => {
			// Click on cell expand
			const activeCell = activeCellRef?.current
			if (e.target instanceof HTMLElement && e.target.className.includes('grid-expand-rows-indicator')) {
				if (e.preventDefault) e.preventDefault()
				if (isCellDisabled(activeCell)) return
				isExpanding = false
				const row = expandDirection === 'horizontal' ? activeCellRef.current.row : rowCountRef.current - 1 - (hasInsertRow ? 1 : 0)
				const col = expandDirection === 'vertical' ? activeCellRef.current.col : colCountRef.current - 1
				onExpand({ row, col })
				document.removeEventListener('mousemove', expand)
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])

		useEffect(() => {
			document.addEventListener('mousedown', onMouseDown, false)
			document.addEventListener('mouseup', onMouseUp, false)
			document.addEventListener('dblclick', onDoubleClick, false)
			return () => {
				document.removeEventListener('mousedown', onMouseDown, false)
				document.removeEventListener('mouseup', onMouseUp, false)
				document.removeEventListener('dblclick', onDoubleClick, false)
				if (isResizing) document.removeEventListener('mousemove', resize)
				if (isSelecting) document.removeEventListener('mousemove', select)
				if (isExpanding) document.removeEventListener('mousemove', expand)
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])

		// ###########################
		// #### KEYBOARD INTERACTION
		// ###########################
		const onKeyDown = useCallback((e) => {
			const activeCell = activeCellRef?.current
			const selectionCell = selectionCellRef?.current
			const editing = editingRef?.current?.is
			const editingBar = editingBarRef?.current?.is

			if (!activeCell || disableKeyboardRef.current || contextMenuRef.current) return

			// Formula bar being edited
			if (editingBar) {
				if (e.key === 'Escape') {
					stopEditingBar(true)
					if (e.preventDefault) e.preventDefault()
					if (e.stopPropagation) e.stopPropagation()
				} else if (e.key === 'Enter') stopEditingBar(false)
				return
			}

			// Grid navigation and selection
			if (e.key.startsWith('Arrow') || e.key === 'Tab') {
				if (editing && e.key.startsWith('Arrow')) return
				if (e.preventDefault) e.preventDefault()
				if (e.stopPropagation) e.stopPropagation()

				const add = ([x, y], cell) =>
					cell && {
						col: Math.max(0, Math.min(colCountRef.current - 1, cell.col + x)),
						row: Math.max(0, Math.min(rowCountRef.current - 1, cell.row + y))
					}

				if (e.key === 'Tab' && e.shiftKey) setActiveCell(add([-1, 0], activeCell))
				else if (e.key === 'Tab') setActiveCell(add([1, 0], activeCell))
				else if (e.ctrlKey || e.metaKey) {
					let newCol = e.shiftKey && selectionCell ? selectionCell.col : activeCell?.col
					let newRow = e.shiftKey && selectionCell ? selectionCell.row : activeCell?.row
					const intermediateCell = intermediateCellRef?.current

					if (intermediateCell?.col && ((e.key === 'ArrowLeft' && newCol > intermediateCell.col) || (e.key === 'ArrowRight' && newCol < intermediateCell.col))) newCol = intermediateCell.col
					else if (e.key === 'ArrowLeft') newCol = 0
					else if (e.key === 'ArrowRight') newCol = colCountRef.current - 1
					if (intermediateCell?.row && ((e.key === 'ArrowUp' && newRow > intermediateCell.row) || (e.key === 'ArrowDown' && newRow < intermediateCell.row))) newRow = intermediateCell.row
					else if (e.key === 'ArrowUp') newRow = 0
					else if (e.key === 'ArrowDown') newRow = rowCountRef.current - 1

					const newCell = { col: newCol, row: newRow }
					if (e.shiftKey) setSelectionCell(newCell)
					else setActiveCell(newCell)
				} else {
					const direction = {
						ArrowDown: [0, 1],
						ArrowUp: [0, -1],
						ArrowLeft: [-1, 0],
						ArrowRight: [1, 0]
					}[e.key]
					if (e.ctrlKey || e.metaKey) {
						direction[0] *= colCountRef.current
						direction[1] *= rowCountRef.current
					}
					if (e.shiftKey) setSelectionCell(add(direction, selectionCell || activeCell))
					else setActiveCell(add(direction, activeCell))
				}
				// Cell editing
			} else if (e.key === 'Escape') {
				if (e.preventDefault) e.preventDefault()
				if (e.stopPropagation) e.stopPropagation()
				if (!editing && !selectionCell) setActiveCell(null)
				stopEditing(false, true)
				return
			} else if ((e.key === 'Enter' || e.key === 'F2') && !e.ctrlKey && !e.metaKey && !e.altKey && !e.shiftKey) {
				if (editing) stopEditing()
				else startEditing(activeCell)
				return
			} else if (e.key === 'Backspace' || e.key === 'Delete') {
				if (!editing && !editingBar) deleteSelection()
			} else if (e.key.match(/^[ -~]$/) && !e.ctrlKey && !e.metaKey && !e.altKey) {
				if (!editing) startEditing(activeCell, true)
				return
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])

		useEffect(() => {
			document.addEventListener('keydown', onKeyDown)
			return () => document.removeEventListener('keydown', onKeyDown)
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])

		// ###########################
		// #### COPY-PASTE
		// ###########################
		const onPaste = useCallback(
			(e) => {
				const activeCell = activeCellRef?.current
				const selection = selectionRef?.current
				const editing = editingRef?.current?.is
				const editingBar = editingBarRef?.current?.is
				const locale = localeRef?.current
				const getCellTypeFunction = callbacksRef.current.getCellTypeFunction
				const getFormulaFunction = callbacksRef.current.getFormulaFunction

				if (!activeCell || editing || editingBar || disableKeyboardRef.current || contextMenuRef.current) return
				if (e.preventDefault) e.preventDefault()

				// Parse clipboard data
				let pasteData = null
				const types = e.clipboardData.types
				if (types.includes('application/singularly')) pasteData = copyPasting.parseTextPlainData(e.clipboardData.getData('application/singularly'))
				else if (types.includes('text/html')) pasteData = copyPasting.parseTextHtmlData(e.clipboardData.getData('text/html'))
				else if (types.includes('text/plain')) pasteData = copyPasting.parseTextPlainData(e.clipboardData.getData('text/plain'))
				else if (types.includes('text')) pasteData = copyPasting.parseTextPlainData(e.clipboardData.getData('text'))
				if (!pasteData) return

				// Calculate number of paste rows and cols
				const min = selection?.min || activeCell
				const max = selection?.max || activeCell
				const rowDiff = max.row - min.row + 1
				const colDiff = max.col - min.col + 1
				const replicate = pasteData?.length === 1 && pasteData[0]?.length === 1 && (rowDiff !== 1 || colDiff !== 1)
				var numPasteRows = replicate ? rowDiff : rowDiff === 1 && colDiff === 1 ? pasteData.length : Math.min(rowDiff, pasteData.length)
				if (!canPasteExceedGrid) numPasteRows = Math.min(numPasteRows, rowCountRef.current - min.row)

				var newData = {}
				var colIndexes = {}
				var maxPastedCols = 0
				for (let pasteRow = 0; pasteRow < numPasteRows; pasteRow++) {
					const rowIndex = min.row + pasteRow
					var numPasteCols = replicate ? colDiff : rowDiff === 1 && colDiff === 1 ? pasteData[pasteRow].length : Math.min(colDiff, pasteData[pasteRow].length)
					if (!canPasteExceedGrid) numPasteCols = Math.min(numPasteCols, colCountRef.current - min.col)
					if (numPasteCols > maxPastedCols) maxPastedCols = numPasteCols

					for (let pasteCol = 0; pasteCol < numPasteCols; pasteCol++) {
						const colIndex = min.col + pasteCol
						const value = replicate ? pasteData[0][0] : pasteData[pasteRow][pasteCol]

						// Prep parser
						const cellType = getCellTypeFunction(rowIndex, colIndex)
						if (!cellType) continue
						const type = cellType.type
						const typeProps = cellType.typeProps
						const cId = cellType.columnId
						colIndexes[cId] = colIndex

						const typeObj = DATA_TYPES[type]
						const cellRenderer = typeObj?.renderer
						const cellConfig = cellRenderer?.config
						const parsePastedValue = cellConfig.parsePastedValue

						// Parse pasted value
						if (!isCellDisabled({ col: colIndex, row: rowIndex }) && type !== DATA_TYPES.file.key) {
							const canHaveFormula = (getFormulaFunction && getFormulaFunction(rowIndex, colIndex)?.canHaveFormula) || false
							const parsedValue = canHaveFormula ? value : parsePastedValue({ value, typeProps, localeFallback: locale?.locale })
							newData[rowIndex] = newData[rowIndex] ? { ...newData[rowIndex], [cId]: parsedValue } : { [cId]: parsedValue }
						}
					}
				}

				const onPasteFunction = callbacksRef.current.onPasteFunction
				if (onPasteFunction) onPasteFunction(newData, colIndexes)

				setActiveCell({ col: min.col, row: min.row })
				setSelectionCell({ col: min.col + maxPastedCols - 1, row: min.row + numPasteRows - 1 })
			},
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[locale]
		)

		const onCopy = useCallback((e) => {
			const activeCell = activeCellRef?.current
			const selection = selectionRef?.current
			const editing = editingRef?.current?.is
			const editingBar = editingBarRef?.current?.is
			const onCopyFunction = callbacksRef.current.onCopyFunction

			if (!onCopyFunction || !activeCell || editing || editingBar || disableKeyboardRef.current || contextMenuRef.current) return
			if (e.preventDefault) e.preventDefault()

			const min = selection?.min || activeCell
			const max = selection?.max || activeCell

			// Put selected data into an array
			const copyResult = onCopyFunction(min, max)
			const copyData = copyResult.copyData
			const copyFormula = copyResult.copyFormula || copyData

			// Copy data into clipboard
			e.clipboardData.setData('application/singularly', copyFormula.map((row) => row.join('\t')).join('\n'))
			e.clipboardData.setData('text/plain', copyData.map((row) => row.join('\t')).join('\n'))
			e.clipboardData.setData(
				'text/html',
				`<table>${copyData.map((row) => `<tr>${row.map((cell) => `<td>${copyPasting.encodeHtml(String(cell || '')).replace(/\n/g, '<br/>')}</td>`).join('')}</tr>`).join('')}</table>`
			)
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])

		const deleteSelection = useCallback(() => {
			const activeCell = activeCellRef?.current
			const selection = selectionRef?.current
			const editing = editingRef?.current?.is
			const editingBar = editingBarRef?.current?.is
			const onDeleteFunction = callbacksRef.current.onDeleteFunction

			if (!onDeleteFunction || !activeCell || editing || editingBar || disableKeyboardRef.current || contextMenuRef.current) return

			const min = selection?.min || activeCell
			const max = selection?.max || activeCell

			onDeleteFunction(min, max)
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])

		const onCut = useCallback(
			(e) => {
				onCopy(e)
				deleteSelection()
			},
			[onCopy, deleteSelection]
		)

		useEffect(() => {
			document.addEventListener('copy', onCopy)
			document.addEventListener('cut', onCut)
			document.addEventListener('paste', onPaste)
			return () => {
				document.removeEventListener('copy', onCopy)
				document.removeEventListener('cut', onCut)
				document.removeEventListener('paste', onPaste)
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])

		// ###########################
		// #### CONTEXT MENU
		// ###########################
		// x,y coordinates of the right click
		const [contextMenu, contextMenuRef, setContextMenu] = useStateRef(null)

		const onOpenContextMenu = useCallback(({ e, rowIndex, colIndex, ...rest }) => {
			if (!callbacksRef.current.getContextMenuFunction) return
			if (e.preventDefault) e.preventDefault()
			if (e.stopPropagation) e.stopPropagation()

			setActiveCell({ row: rowIndex, col: colIndex })
			const contextMenu = callbacksRef.current.getContextMenuFunction({ rowIndex, colIndex, position: { x: e.clientX, y: e.clientY }, ...rest })
			if (!contextMenu || !contextMenu.hasMenu) return
			setContextMenu({ x: e.clientX, y: e.clientY, items: contextMenu.items })
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])

		const onContextMenu = useCallback((e) => {
			const clickInside = hasClickedInside(e)
			const editing = editingRef.current?.is
			if (clickInside && !editing) {
				if (e.preventDefault) e.preventDefault()
				if (e.stopPropagation) e.stopPropagation()
				const cursorIndex = getCursorIndex(e, true)
				onOpenContextMenu({ e, rowIndex: cursorIndex.row, colIndex: cursorIndex.col })
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])

		useEffect(() => {
			document.addEventListener('contextmenu', onContextMenu)
			return () => {
				document.removeEventListener('contextmenu', onContextMenu)
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])

		// ###########################
		// #### ADD ROW SHORTCUT
		// ###########################
		const [isLastRowVisible, setIsLastRowVisible] = useState(true)

		const onItemsRendered = useCallback(
			({ visibleRowStartIndex, visibleRowStopIndex }) => {
				if (callbacksRef.current.onGridItemsRendered) callbacksRef.current.onGridItemsRendered({ rowStartIndex: visibleRowStartIndex, rowStopIndex: visibleRowStopIndex })

				if (visibleRowStopIndex < rowCountRef.current - 1 - stickyRowsRef.current) setIsLastRowVisible(false)
				else setIsLastRowVisible(true)
			},
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[]
		)

		const goToLastRow = useCallback((e) => {
			if (e.preventDefault) e.preventDefault()
			if (e.stopPropagation) e.stopPropagation()
			grid.current.scrollToItem({ rowIndex: rowCountRef.current - 1 })
			setActiveCell({ row: rowCountRef.current - 1, col: 0 })
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])

		// ###########################
		// #### MEMOIZED DATA INPUTS TO GRID
		// ###########################
		const itemData = useMemoObject({
			items,
			columns,
			editing: editing,
			stickyRows,
			stickyCols,
			disableCellFunction,
			getFormulaFunction,
			setActiveCell,
			onCellClick,
			onColumnResize,
			onOpenContextMenu,
			onDragStart,
			onDragEnd,
			onDrop,
			onDragEnter,
			onDragLeave,
			onDragOver,
			dragIndex,
			onStartEditing: startEditing,
			onStopEditing: stopEditing,
			...data
		})

		const stickyContext = useMemoObject({
			CellStickyRow,
			CellStickyColumn,
			rowHeight: defaultRowHeight,
			columnWidths,
			columnRights
		})

		const selectionContext = useMemoObject({
			columnRights,
			columnWidths,
			rowHeight: defaultRowHeight,
			rowCount,
			stickyCols,
			stickyRows,
			activeCell,
			selection,
			expansion,
			isEditing: editing.is,
			isCellDisabled,
			expandStickyCol
		})

		const gridShadowData = useMemoObject({
			columnRights,
			rowHeight: defaultRowHeight,
			stickyCols,
			edges
		})

		const getColumnWidth = useCallback(
			(index) => {
				return columnWidths[index]
			},
			[columnWidths]
		)

		const getRowHeight = useCallback(
			(index) => {
				return defaultRowHeight
			},
			[defaultRowHeight]
		)

		// ###########################
		// #### MAIN GRID RENDERING
		// ###########################
		// This avoids that when the last row is deleted, the render crashes
		if (activeCell?.row >= rowCount || activeCell?.col >= colCount) return <div />
		// Main render
		return (
			<>
				{showFormulaBar && (
					<FormulaBar
						ref={formulaBarRef}
						rowIndex={activeCell && activeCell.row}
						colIndex={activeCell && activeCell.col}
						getFormulaFunction={getFormulaFunction}
						visible={true}
						focus={editingBar?.is}
						cancel={editingBar?.cancel}
						autoFocus={false}
						isFixed={true}
						isDisabled={
							activeCell
								? isCellDisabled(activeCell) || activeCell.row === stickyRows - 1 || activeCell.col === stickyCols - 1 || !getFormulaFunction(activeCell.row, activeCell.col).canHaveFormula
								: true
						}
						defaultRowHeight={defaultRowHeight}
					/>
				)}
				<div className="border-t-[0.5px] border-borderGray" style={{ flex: '1 1 auto', position: 'relative' }}>
					{contextMenu && <ContextMenu x={contextMenu.x} y={contextMenu.y} items={contextMenu.items} close={() => setContextMenu(null)} />}
					{showAddRowButton && !isLastRowVisible && (
						<div
							className="absolute bottom-[50px] left-[50px] bg-primaryLight z-10 border border-borderGray h-[30px} rounded-full shadow cursor-pointer flex flex-row items-center pl-2 pr-4 py-1 text-textGray hover:text-white hover:bg-primary"
							onClick={goToLastRow}
						>
							<AddIcon className="w-[18px] h-[18px] mr-2" />
							<span className="text-sm font-medium">{t('common:buttons.addRecord')}</span>
						</div>
					)}
					<AutoSizer onResize={onGridResize}>
						{({ height, width }) => (
							<>
								<SelectionContext.Provider value={selectionContext}>
									<SelectionRect isStickyAll={true} />
									<div ref={stickyCellsRef} style={{ position: 'absolute', top: 0, left: 0, zIndex: 4, height: defaultRowHeight, width: columnWidths[0], cursor: 'cell' }}>
										<CellStickyTopLeft style={{ height: defaultRowHeight, width: columnWidths[0] }} data={itemData} />
									</div>
									<StickyContext.Provider value={stickyContext}>
										<VariableSizeGrid
											ref={grid}
											innerRef={gridInnerRef}
											outerRef={gridOuterRef}
											className="cursor-cell"
											columnCount={colCount}
											rowCount={rowCount}
											columnWidth={getColumnWidth}
											rowHeight={getRowHeight}
											height={height}
											width={width}
											estimatedRowHeight={defaultRowHeight}
											estimatedColumnWidth={defaultColWidth}
											itemData={itemData}
											innerElementType={InnerContainer}
											onItemsRendered={showAddRowButton && onItemsRendered}
										>
											{Cell}
										</VariableSizeGrid>
									</StickyContext.Provider>
								</SelectionContext.Provider>
								<GridShadow value={gridShadowData} />
							</>
						)}
					</AutoSizer>
				</div>
			</>
		)
	}
)
export default Grid
