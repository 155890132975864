import { useState, useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import IntegratedTextInput from 'common/components/input/IntegratedTextInput'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import clsx from 'clsx'
import { IconButton } from '@mui/material'
import ActionConfirm from 'common/components/ActionConfirm'

export default function GridTabs({ tabs, activeTab, showInfo = false, isDesigning, onSelect, onCreate, onRename, onDelete, onMove, rightContent }) {
	const { t } = useTranslation(['common'])

	// #### STATE
	const [deleteItem, setDeleteItem] = useState(null)
	const [dragTabIndex, setDragTabIndex] = useState(null)

	// #### FUNCTIONS
	function onRenameTab(value, index) {
		if (!(value?.length > 0) || tabs[index] === value) return
		onRename(value, index)
	}

	function onOpenDeleteModal(e, index) {
		if (e.preventDefault) e.preventDefault()
		if (e.stopPropagation) e.stopPropagation()
		setDeleteItem(index)
	}

	function onDeleteTab() {
		if (deleteItem != null && deleteItem >= 0) {
			onDelete(deleteItem)
			setDeleteItem(null)
		}
	}

	// #### DRAG & DROP
	const onMoveRef = useRef(onMove)
	onMoveRef.current = onMove

	useEffect(() => {
		if (dragTabIndex != null) document.addEventListener('dragend', onDragEnd)
		return () => document.removeEventListener('dragend', onDragEnd)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dragTabIndex])

	const onDragStart = useCallback((e, dragTabIndex) => {
		setDragTabIndex(dragTabIndex)
	}, [])

	const onDragEnd = useCallback((e) => {
		setDragTabIndex(null)
	}, [])

	const onDrop = useCallback((e, dragTabIndex, dropTabIndex) => {
		if (dragTabIndex === dropTabIndex) return
		onMoveRef.current(dragTabIndex, dropTabIndex)
		onDragLeave(e)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const onDragEnter = useCallback((e, property) => {
		if (e.preventDefault) e.preventDefault()
		if (e.stopPropagation) e.stopPropagation()
		e.target.style[property] = '2px solid black'
	}, [])

	const onDragLeave = useCallback((e) => {
		if (e.preventDefault) e.preventDefault()
		if (e.stopPropagation) e.stopPropagation()
		e.target.style.borderLeft = '0px'
		e.target.style.borderRight = '0px'
	}, [])

	const onDragOver = useCallback((e) => {
		if (e.preventDefault) e.preventDefault()
		if (e.stopPropagation) e.stopPropagation()
	}, [])

	return (
		<div className="flex flex-row items-end justify-between bg-bgGray text-textGray text-sm pt-2">
			<div className="flex flex-row items-end">
				{tabs?.map((tab, index) => {
					const isActive = activeTab === index
					return (
						<div key={tab.id} className="flex flex-row relative">
							<div
								className={clsx(
									'm-w-[100px] flex flex-row items-center justify-center px-2 py-1 mx-1 rounded-tl-md rounded-tr-md relative group',
									isActive
										? 'border border-borderGray border-b-transparent bg-white'
										: 'border border-transparent cursor-pointer hover:border-borderGray hover:border-b-transparent hover:bg-borderGray'
								)}
								onClick={() => onSelect(index)}
								draggable={true}
								onDragStart={(e) => onDragStart(e, index)}
							>
								<IntegratedTextInput
									canGrow
									minWidth={60}
									value={tab.name}
									setValue={(value) => onRenameTab(value, index)}
									className="bg-transparent"
									isDisabled={activeTab !== index || !isDesigning}
								/>
								{showInfo && <span className="mr-2">({tab.info})</span>}
								{isDesigning && (
									<div className="absolute right-1 top-0 bottom-0 hidden group-hover:flex items-center">
										<IconButton
											size="small"
											onClick={(e) => onOpenDeleteModal(e, index)}
											className={clsx('text-textGray cursor-pointer self-center mx-1', isActive ? 'group-hover:bg-white hover:!bg-bgGray' : 'group-hover:bg-borderGray hover:!bg-bgGray')}
										>
											<CloseIcon fontSize="small" className="text-sm" />
										</IconButton>
									</div>
								)}
							</div>
							<span className={clsx('border-r my-2', index < activeTab - 1 || index > activeTab ? 'border-borderGray' : 'border-transparent')} />
							<div
								onDrop={(e) => onDrop(e, dragTabIndex, index)}
								onDragEnter={(e) => onDragEnter(e, 'borderLeft')}
								onDragLeave={(e) => onDragLeave(e)}
								onDragOver={(e) => onDragOver(e)}
								className={clsx('z-10 absolute top-0 left-0 bottom-0 right-1/2 bg-transparent')}
								style={{ display: dragTabIndex != null && dragTabIndex !== index ? 'block' : 'none' }}
							/>
							<div
								onDrop={(e) => onDrop(e, dragTabIndex, index + 1)}
								onDragEnter={(e) => onDragEnter(e, 'borderRight')}
								onDragLeave={(e) => onDragLeave(e)}
								onDragOver={(e) => onDragOver(e)}
								className={clsx('z-10 absolute top-0 left-1/2 bottom-0 right-0 bg-transparent')}
								style={{ display: dragTabIndex != null && dragTabIndex !== index ? 'block' : 'none' }}
							/>
						</div>
					)
				})}

				{isDesigning && (
					<IconButton size="small" onClick={onCreate} className="text-textGray cursor-pointer self-center mx-1 hover:bg-borderGray">
						<AddIcon fontSize="small" />
					</IconButton>
				)}

				<ActionConfirm open={deleteItem != null} content={t('common:messages.deleteConfirm')} onClose={() => setDeleteItem(null)} onConfirm={onDeleteTab} />
			</div>
			{rightContent}
		</div>
	)
}
