import { put, call, takeEvery, select, spawn } from 'redux-saga/effects'
import * as sagaAction from 'model/saga-actions/modelEngineActions'
import * as sagaMiddlewareAction from 'model/saga-actions/engineMiddlewareActions'
import * as globalSagaAction from 'common/saga-actions/globalActions'
import * as authApi from 'common/api/authApi'
import * as sockeAction from 'model/saga-actions/socketActions'
import { fromTimestampToDate } from 'common/utils/dates'
import { throwError } from 'common/config/errors'

const FILE_NAME = 'modelEngineSagas'

const getModels = (state) => state.model.model

function* loadCalculationSocketReq() {
	yield takeEvery(sagaAction.LOAD_ENGINE_SOCKET, loadCalculationSocket)
}

function* loadCalculationSocket(triggeredAction) {
	try {
		const { tid, teamId, aid } = triggeredAction
		const { token } = yield call(authApi.getAuthToken)
		const models = yield select(getModels)
		const modelProps = models[aid]?.data?.modelProps
		if (modelProps) yield put(sockeAction.wsEngineConnect({ token, tid, teamId, aid, modelProps }))
	} catch (err) {
		const content = throwError(err, FILE_NAME, loadCalculationSocket.name)
		yield put(globalSagaAction.showMessage({ content }))
	}
}

// #### ENGINE
function* recomputeReq() {
	yield takeEvery(sagaAction.RECOMPUTE, recompute)
}

function* recompute(triggeredAction) {
	try {
		const { tid, teamId, aid } = triggeredAction
		const models = yield select(getModels)
		const modelProps = models[aid]?.data?.modelProps
		const mask = 'yyyy-MM-dd HH:mm:ss'
		const newModelProps = { ...modelProps }
		const startDate = fromTimestampToDate(modelProps['startDate']).toFormat(mask)
		const endDate = fromTimestampToDate(modelProps['endDate']).toFormat(mask)
		newModelProps['startDate'] = startDate
		newModelProps['endDate'] = endDate
		const fixedTime = modelProps?.advanced['fixedTime'] !== null ? fromTimestampToDate(modelProps?.advanced['fixedTime']).toFormat(mask) : null
		const advanced = { ...newModelProps.advanced }
		advanced['fixedTime'] = fixedTime
		newModelProps['advanced'] = advanced
		yield put(sagaMiddlewareAction.recomputeModel({ tenantId: tid, teamId, modelId: aid, modelProps: newModelProps }))
	} catch (err) {
		const content = throwError(err, FILE_NAME, recompute.name)
		yield put(globalSagaAction.showMessage({ content }))
	}
}

// #### GRID ROWS MANAGEMENT

export default function* root() {
	// #### SOCKET MANAGEMENT
	yield spawn(loadCalculationSocketReq)
	yield spawn(recomputeReq)
}
