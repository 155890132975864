import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Layout from 'common/screens/layout/Layout'
import TeamMenu from 'common/screens/team/TeamMenu'
import { BILLING_CURRENCIES, BILLING_FREQUENCIES, PLANS } from 'common/constants/plans'
import { Button, Chip } from '@mui/material'
import Plans from 'common/screens/billing/Plans'
import { cancelBillableUsers, customerPortal, loadBillableUsers } from 'common/saga-actions/billingActions'
import CheckIcon from '@mui/icons-material/Done'
import { LoadingButton } from '@mui/lab'
import Addons from 'common/screens/billing/Addons'
import Limits from 'common/screens/billing/Limits'
import BillableUsers from 'common/screens/billing/BillableUsers'
import GenericBar from '../layout/GenericBar'

export default function Billing() {
	const { t } = useTranslation('common')
	const dispatch = useDispatch()

	// #### REDUX
	const tid = useSelector((state) => state.auth.tid)
	const teamId = useSelector((state) => state.team.teamId)
	const limits = useSelector((state) => state.team?.team?.limits)
	const customer = useSelector((state) => state.billing.customer?.data)
	const frequency = customer?.frequency
	const currency = customer?.currency
	const planKey = useSelector((state) => state.team.team?.plan)
	const cid = useSelector((state) => state.team.team?.customerId)
	const plan = planKey ? PLANS[planKey] : null
	const isFreePlan = planKey === PLANS.free.key
	const loadingPortal = useSelector((state) => state.global.loading?.customerPortal)

	// #### STATE
	const [isPlansOpen, setIsPlansOpen] = useState(false)

	// #### EFFECTS
	// Load billable users
	useEffect(() => {
		const lTid = tid
		const lTeamId = teamId
		if (lTid && lTeamId) dispatch(loadBillableUsers({ tid, teamId }))
		return () => {
			if (lTid && lTeamId) dispatch(cancelBillableUsers())
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [teamId])

	// #### FUNCTIONS
	function onChangePlan() {
		dispatch(customerPortal({ tid, teamId, cid }))
	}

	return (
		<Layout menuContent={TeamMenu} barContent={GenericBar}>
			{/* <Breadcrumbs /> */}
			<div className="mx-4 my-6 mb-8 text-3xl">{t('common:team.billing')}</div>

			<div className="mx-4 mb-4">
				<div className="mb-4 text-xs text-textGray uppercase">{t('common:billing.yourPlan')}</div>

				<div className="paper p-4 max-w-[700px]">
					<div className="mb-4 flex flex-row items-center">
						<div className="text-xl">
							{plan && t(plan.label)}&nbsp;{t('common:plans.plan')}
						</div>
						{!isFreePlan && frequency && <Chip size="small" label={`Billed ${t(BILLING_FREQUENCIES[frequency].freqLabel)}`} className="ml-4 text-xs bg-bgGray" />}
					</div>

					<div className="flex flex-row items-center">
						<CheckIcon fontSize="small" className="text-textGray mr-2 w-[15px] h-[15px]" />
						{!isFreePlan ? (
							<span className="text-sm">{`${limits?.users} ${t('common:plans.seats')}`}</span>
						) : (
							isFreePlan && <span className="text-sm">{`${limits?.users} ${t('common:plans.seats')}`}</span>
						)}
					</div>

					<div className="flex flex-row items-center">
						<CheckIcon fontSize="small" className="text-textGray mr-2 w-[15px] h-[15px]" />
						<span className="text-sm">{`${limits?.connectors} ${t('common:addons.connectors')}`}</span>
					</div>

					{/* STRIPE */}
					{/* <div className="flex flex-row items-center mt-4">
						{isFreePlan && (
							<Button variant="contained" className="buttonContainedContrast mr-4" size="small" onClick={() => setIsPlansOpen(true)}>
								{t('common:billing.upgrade')}
							</Button>
						)}
						{(!isFreePlan || customer?.connector) && (
							<LoadingButton variant="outlined" className="buttonOutlinedGray" size="small" onClick={onChangePlan} loading={loadingPortal}>
								{t('common:billing.manageBilling')}
							</LoadingButton>
						)}
					</div> */}
				</div>

				{/* ADDONS */}
				{/* {!customer?.connector && (
					<>
						<div className="mt-8 mb-4 text-xs text-textGray uppercase">{t('common:billing.addons')}</div>
						<Addons currency={currency} frequency={frequency} />
					</>
				)} */}

				<div className="mt-8 mb-4 text-xs text-textGray uppercase">{t('common:billing.users')}</div>
				<BillableUsers />

				<div className="mt-8 mb-4 text-xs text-textGray uppercase">{t('common:billing.limits')}</div>
				<Limits />
			</div>
			<Plans isOpen={isPlansOpen} onClose={() => setIsPlansOpen(false)} />
		</Layout>
	)
}
