import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import reducers from 'common/store/reducers'
import sagas from 'common/store/sagas'
import VizMiddleware from 'common/middlewares/VizMiddleware'
import EngineMiddleware from 'common/middlewares/EngineMiddleware'

const saga = createSagaMiddleware()
const middlewares = [saga]

if (process.env.NODE_ENV === 'development') {
	const { logger } = require('redux-logger')
	middlewares.push(logger)
}

middlewares.push(VizMiddleware)
middlewares.push(EngineMiddleware)

const store = configureStore({
	reducer: reducers,
	middleware: middlewares
})
saga.run(sagas)
export default store
