import { Button, Popover, TextField, MenuItem, IconButton, ListItemIcon, ListItemText, Alert } from '@mui/material'
import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close'
import clsx from 'clsx'
import { validateNotEmpty } from 'common/utils/validate'
import { setVariableCategories } from 'model/saga-actions/modelActions'
import ModelBreakdown from './ModelBreakdown'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { DATA_TYPES } from 'common/constants/dataTypes'
import { cancelSelectedTable } from 'table/store/tableReducer'
import { loadSelectedTable } from 'table/saga-actions/tableActions'
import TableTypeRefVariable from 'table/screens/type/TableTypeRefVariable'
import ErrorIcon from '@mui/icons-material/ErrorOutlineOutlined'
import { useAssetPermits } from 'common/hooks/useAssetPermits'
import { LoadingButton } from '@mui/lab'

export default function ModelVarCategories({ aid, position, onClose, varId, variable }) {
	const dispatch = useDispatch()
	const { t } = useTranslation(['common', 'model'])
	const popoverRef = useRef()
	const loadKey = `variableBreakdown#${aid}`
	const permits = useAssetPermits(aid)

	// #### REDUX
	const tid = useSelector((state) => state.auth.tid)
	const teamId = useSelector((state) => state.asset.asset[aid]?.data?.teamId)
	const globalCategories = useSelector((state) => state.team.teamCategories?.result)
	const variables = useSelector((state) => state.table.selected[loadKey]?.data?.variables)
	const sourceProps = variable?.sourceProps
	const isConnected = variable?.sourceProps?.selectTable != null && variable?.sourceProps?.selectTable !== ''
	const isSaving = useSelector((state) => state.global.loading?.setVariableCategories)

	// #### POPOVER CONTROL
	const isPopoverOpen = Boolean(position)

	// #### DEFAULT VALUES
	const defaultForm = {
		selectCategory: '',
		selectVariable: '',
		categories: []
	}

	const defaultValidation = {
		selectCategory: { valid: true, error: '' },
		selectVariable: { valid: true, error: '' },
		categories: { valid: true, error: '' }
	}

	// #### STATE
	const [form, setForm] = useState(defaultForm)
	const [validation, setValidation] = useState(defaultValidation)
	const [isCategoriesOpen, setIsCategoriesOpen] = useState(null)
	const [isSavingCopy, setIsSavingCopy] = useState(false)

	// #### EFFECTS
	// Close modal after saving is complete
	useEffect(() => {
		const nextIsSaving = isSaving || false
		const prevIsSaving = isSavingCopy
		if (prevIsSaving && !nextIsSaving) onClose()
		setIsSavingCopy(nextIsSaving)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSaving])

	useEffect(() => {
		if (variable?.categories) setForm({ selectCategory: '', categories: variable?.categories })
		else setForm(defaultForm)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [varId])

	// Load table (only for connected variables)
	useEffect(() => {
		const lKey = loadKey
		if (isConnected) dispatch(loadSelectedTable({ key: lKey, tid, aid: sourceProps.selectTable }))
		return () => dispatch(cancelSelectedTable({ key: lKey }))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isConnected])

	// #### FUNCTIONS
	function onChange(key, value) {
		if (key === 'selectCategory' && value === 'new') return
		setForm({ ...form, [key]: value })
	}

	function onSave() {
		dispatch(setVariableCategories({ tid, teamId, aid, vid: varId, categories: form.categories, isConnected, permits }))
		onClose()
	}

	function onAdd() {
		const selectCategory = JSON.parse(form.selectCategory)
		let catValid = validateNotEmpty(selectCategory.id)
		if (catValid.valid) catValid = form.categories.findIndex((el) => el.id === selectCategory.id) >= 0 ? { valid: false, error: 'common:error.used' } : catValid
		let varValid = isConnected ? validateNotEmpty(form.selectVariable) : { valid: true, error: '' }
		if (isConnected && varValid.valid) varValid = form.categories.findIndex((el) => el.variable === form.selectVariable) >= 0 ? { valid: false, error: 'common:error.used' } : varValid
		setValidation({ ...validation, selectCategory: catValid, selectVariable: varValid })
		if (catValid.valid && varValid.valid) {
			var newCategories = form.categories ? [...form.categories] : []
			const newCategory = isConnected ? { ...selectCategory, variable: form.selectVariable } : selectCategory
			newCategories.push(newCategory)
			setForm({ ...form, categories: newCategories, selectCategory: {}, selectVariable: '' })
		}
	}

	function onDelete(index) {
		var newCategories = [...form.categories]
		newCategories.splice(index, 1)
		setForm({ ...form, categories: newCategories })
	}

	return (
		<Popover
			open={isPopoverOpen}
			anchorReference="anchorPosition"
			anchorPosition={position ? { top: position.y, left: position.x } : null}
			onClose={onClose}
			anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
			transformOrigin={{ vertical: 'top', horizontal: 'left' }}
			classes={{ paper: 'flex w-[400px] shadow-md' }}
		>
			{isPopoverOpen && (
				<div className="flex-1 flex flex-col pb-4 max-w-full" ref={popoverRef}>
					<div className="flex flex-row justify-between items-center border-b border-b-borderGray p-2 pl-4">
						<span className="text-md font-medium text-textGray">{t('model:categories.varBreakdown')}</span>
						<IconButton size="small" className="text-textGray" onClick={onClose}>
							<CloseIcon fontSize="small" />
						</IconButton>
					</div>

					{isConnected && (
						<Alert className="mx-4 py-0 text-xs mt-4 " classes={{ message: 'flex flex-row items-center' }} severity="info">
							{t('model:categories.connectedInfo')}
						</Alert>
					)}

					<div className="px-4 mt-4 text-sm flex flex-row items-center">
							<TextField
								fullWidth
								select
								label={t('model:categories.category')}
								variant="outlined"
								size="small"
								margin="none"
								value={form.selectCategory}
								onChange={(e) => onChange('selectCategory', e.target.value)}
								inputProps={{ className: 'text-sm' }}
								InputLabelProps={{ className: 'text-sm' }}
								error={validation?.selectCategory && !validation?.selectCategory?.valid}
								helperText={!validation?.selectCategory?.valid && t(validation?.selectCategory.error, { field: t('model:categories.category') })}
								sx={{ placeholder: 'text-sm' }}
								className="flex-1 shrink-0"
							>
								{globalCategories &&
									Object.entries(globalCategories)?.map((category) => (
										<MenuItem key={`selectCategory#${category[0]}`} value={JSON.stringify({id: category[0], name: category[1].name})} className="text-sm">
											{category[1].name}
										</MenuItem>
									))}
								<MenuItem key={`selectCategoryNew`} value="new" className="text-sm" onClick={(e) => setIsCategoriesOpen(popoverRef?.current)}>
									<ListItemText>
										<span className="text-sm">{t('model:categories.create')}</span>
									</ListItemText>
									<ListItemIcon>
										<ChevronRightIcon fontSize="small" />
									</ListItemIcon>
								</MenuItem>
							</TextField>
						{isConnected && (
							<div className="flex-1 shrink-0 ml-1">
								<TableTypeRefVariable
									aid={aid}
									variables={variables}
									types={Object.keys(DATA_TYPES)}
									onChange={(value) => onChange('selectVariable', value)}
									value={form.selectVariable}
									validation={validation.selectVariable}
									fieldName={t('model:categories.sourceField')}
									showLabel
								/>
							</div>
						)}
						<Button size="small" className="buttonText shrink-0 ml-2 w-[40px] min-w-0" onClick={onAdd}>
							{t('common:buttons.add')}
						</Button>
					</div>

					{form.categories?.map((cat, index) => {
						const category = globalCategories && globalCategories[cat.id]
						const variable = (isConnected && variables && variables[cat.variable]) || { isError: true, label: t('common:error.value_deleted') }
						return (
							<div key={`varCat#${cat.id}`} className={clsx('flex flex-row items-center bg-bgGray p-2 mx-4 rounded', index > 0 ? 'mt-2' : 'mt-4')}>
								<div className="flex-1 truncate text-sm">{category?.name}</div>
								{isConnected && (
									<div className={clsx('flex-1 flex flex-row items-center text-sm truncate', variable?.isError && 'text-red-500')}>
										{variable?.isError && <ErrorIcon fontSize="small" className="text-red-500 mr-2" />}
										{variable?.label}
									</div>
								)}
								<div className="w-[40px] text-center">
									<CloseIcon fontSize="small" className="text-textGray ml-2 cursor-pointer w-[15px] h-[15px]" onClick={() => onDelete(index)} />
								</div>
							</div>
						)
					})}

					<LoadingButton onClick={onSave} size="small" className="buttonContainedContrast flex-1 mx-4 mt-4" loading={isSaving} classes={{ loadingIndicator: 'text-white' }}>
						{t('common:buttons.save')}
					</LoadingButton>
					<ModelBreakdown aid={aid} anchor={isCategoriesOpen} onClose={() => setIsCategoriesOpen(null)} vertical="top" horizontal="right" />
				</div>
			)}
		</Popover>
	)
}
