export function isEmpty(str) {
	return !str || 0 === str.length
}

export function returnObject(valid, error) {
	return {
		valid: valid,
		error: error
	}
}

export function validateNotEmpty(field) {
	if (isEmpty(field)) return returnObject(false, 'error.required')
	return returnObject(true, '')
}

export function validateEmail(email) {
	// eslint-disable-next-line
	var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/

	if (isEmpty(email)) return returnObject(false, 'error.required')
	if (filter.test(email)) return returnObject(true, '')
	return returnObject(false, 'error.valid')
}

export function validateUrl(url) {
	const fullUrl = url.startsWith('http') ? url : 'http://' + url
	let urlObj
	try {
		urlObj = new URL(fullUrl)
	} catch (e) {
		return returnObject(false, 'error.valid')
	}
	const isValid = urlObj.protocol === 'http:' || urlObj.protocol === 'https:'
	return isValid ? { ...returnObject(true, ''), url: urlObj.href } : returnObject(false, 'error.valid')
}

export function validatePassword(password) {
	if (isEmpty(password)) return returnObject(false, 'error.required')
	if (password.length > 5) return returnObject(true, '')
	return returnObject(false, 'error.length')
}

export function validateInteger(valueStr) {
	if (isEmpty(valueStr)) returnObject(false, 'error.required')
	const num = Number(valueStr)
	if (isNaN(num)) returnObject(false, 'error.integer')
	const n = Math.floor(num)
	const valid = n !== Infinity && String(n) === valueStr && n >= 0
	return valid ? returnObject(true, '') : returnObject(false, 'error.integer')
}
