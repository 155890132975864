import { Checkbox, CircularProgress } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { updateUser } from 'common/saga-actions/userActions'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

export default function AccountPrivacy() {
	const dispatch = useDispatch()
	const { t } = useTranslation('common')

	const tid = useSelector((state) => state.auth.tid)
	const uid = useSelector((state) => state.auth.uid)
	const comms = useSelector((state) => state.user.user?.data?.comms)
	const isLoading = useSelector((state) => state.global.loading?.updateUser)

	// #### DEFAULT VALUES
	const defaultForm = {
		comms: 'false'
	}

	// #### STATE
	const [form, setForm] = useState(defaultForm)

	// #### EFFECTS
	useEffect(() => {
		if (comms) setForm({ comms })
		else setForm({ comms: false })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [comms])

	// #### FUNCTIONS
	function onChange(key, text) {
		setForm({ ...form, [key]: text })
	}

	function onSave() {
		dispatch(updateUser({ tid, uid, content: form }))
	}

	return (
		<div className="flex flex-col">
			<span className="text-sm font-medium mb-4">{t('common:account.comms')}</span>

			<div className="flex flex-row items-start mb-4">
				<Checkbox value="comms" onChange={(event) => onChange('comms', event.target.checked)} checked={form.comms} className="p-0 pr-4" classes={{ checked: '!text-contrast' }} />
				<span className="text-sm">{t('common:account.newsletter')}</span>
			</div>
			<div>
				<LoadingButton loading={isLoading} size="small" variant="contained" className="buttonContainedContrast" onClick={onSave} loadingIndicator={<CircularProgress size={20} className="text-white" />}>
					{t('common:buttons.save')}
				</LoadingButton>
			</div>
		</div>
	)
}
