import { useTranslation } from 'react-i18next'
import Layout from 'common/screens/layout/Layout'
import TeamMenu from 'common/screens/team/TeamMenu'
import Breadcrumbs from 'common/screens/general/Breadcrumbs'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useLocation, matchPath } from 'react-router-dom'
import { Avatar, Button, CircularProgress, MenuItem, TextField } from '@mui/material'
import useNavigate from 'common/hooks/useNavigate'
import { validateNotEmpty } from 'common/utils/validate'
import { createCommunityAsset, updateCommunityAsset } from 'common/saga-actions/communityActions'
import { COMMUNITY, PRODUCTS } from 'common/constants/products'
import { CloseIcon, LinkedInIcon, TwitterIcon } from 'common/icons'
import Autocomplete from 'common/components/Autocomplete'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import CommunityImages from './CommunityImages'
import { LoadingButton } from '@mui/lab'
import { showMessage } from 'common/saga-actions/globalActions'
import { COMMUNITY_CATEGORIES } from 'common/constants/community'
import { sortArrayOfObjects } from 'common/utils/arrays'
import GenericBar from '../layout/GenericBar'

export default function CommunityAsset() {
	const { t } = useTranslation('common')
	const dispatch = useDispatch()
	const { navigate } = useNavigate()
	let { teamId, aid } = useParams()
	const { pathname } = useLocation()
	const isNew = aid == null
	const assetType = matchPath('/team/:teamId/community/template/*', pathname) ? COMMUNITY.template.key : matchPath('/team/:teamId/community/data/*', pathname) ? COMMUNITY.data.key : null

	// #### REDUX
	const tid = useSelector((state) => state.auth.tid)
	const communityAsset = useSelector((state) => (aid ? state.community.assets?.result[aid] : null))
	const user = useSelector((state) => state.user.user?.data)
	const teamAssets = useSelector((state) => state.asset.assets?.result)
	const isSaving = useSelector((state) => state.community.isSaving)

	// #### DEFAULT VALUES
	const defaultForm = {
		name: '',
		shortDescription: '',
		longDescription: '',
		category: '',
		assets: []
	}

	const defaultValidation = {
		name: { valid: true, error: '' },
		shortDescription: { valid: true, error: '' },
		longDescription: { valid: true, error: '' },
		category: { valid: true, error: '' },
		assets: { valid: true, error: '' },
		images: { valid: true, error: '' }
	}

	// #### STATE
	const [form, setForm] = useState(defaultForm)
	const [validation, setValidation] = useState(defaultValidation)
	const [selectedAsset, setSelectedAsset] = useState(null)
	const [images, setImages] = useState([])
	const [prevIsSaving, setPrevIsSaving] = useState(0)

	// #### EFFECTS
	// Initialize form
	useEffect(() => {
		if (communityAsset) {
			setForm({
				name: communityAsset.name,
				shortDescription: communityAsset.shortDescription,
				longDescription: communityAsset.longDescription,
				category: communityAsset.category || '',
				assets: communityAsset.assets
			})
			const newImages = communityAsset.images?.map((id) => ({ id, url: communityAsset.imagesURL ? communityAsset.imagesURL[id] : null }))
			setImages(newImages || [])
		} else setForm(defaultForm)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [communityAsset])

	// Redirect after saving is complete
	useEffect(() => {
		if (isSaving.items <= 0 && prevIsSaving > 0 && !isSaving.isError && isNew) goBack()
		else if (isSaving.items <= 0 && prevIsSaving > 0 && !isSaving.isError) dispatch(showMessage({ content: t('common:success.saved'), messageType: 'success' }))
		setPrevIsSaving(isSaving.items)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSaving])

	// #### FUNCTIONS
	function onChange(key, value) {
		setForm({ ...form, [key]: value })
	}

	function onSave() {
		// Remove deleted assets
		const assets = []
		form.assets?.forEach((aid) => {
			if (teamAssets[aid]) assets.push(aid)
		})
		// Validate
		const nameValid = validateNotEmpty(form.name)
		const shortDescValid = validateNotEmpty(form.shortDescription)
		const longDescValid = validateNotEmpty(form.longDescription)
		const categoryValid = validateNotEmpty(form.category)
		const assetsValid = assets?.length > 0 ? { valid: true, error: '' } : { valid: false, error: 'common:error.empty_list' }
		const imagesValid = images?.length > 0 ? { valid: true, error: '' } : { valid: false, error: 'common:error.empty_list' }
		setValidation({ ...validation, name: nameValid, shortDescription: shortDescValid, longDescription: longDescValid, category: categoryValid, assets: assetsValid, images: imagesValid })
		// Save
		if (nameValid.valid && shortDescValid.valid && longDescValid.valid && categoryValid.valid && assetsValid.valid && imagesValid.valid) {
			const author = { uid: user?.uid, name: user?.name, surname: user?.surname, bio: user?.bio, linkedIn: user?.linkedIn, twitter: user?.twitter }
			const content = { ...form, author, type: assetType, assets }
			if (isNew) dispatch(createCommunityAsset({ tid, teamId, assetType, content, images }))
			else dispatch(updateCommunityAsset({ tid, teamId, aid, content, images }))
		}
	}

	function goBack() {
		navigate(`/team/${teamId}/community`)
	}

	function onAddAsset() {
		if (!selectedAsset) return
		const aid = selectedAsset[0]
		const found = form.assets?.indexOf(aid)
		if (found >= 0) return
		var newAssets = form.assets ? [...form.assets] : []
		newAssets.push(aid)
		onChange('assets', newAssets)
		setSelectedAsset(null)
	}

	function onDeleteAsset(index) {
		var newAssets = [...form.assets]
		newAssets.splice(index, 1)
		onChange('assets', newAssets)
	}

	return (
		<Layout menuContent={TeamMenu} barContent={GenericBar}>
			<Breadcrumbs />

			<div className="flex flex-row items-center justify-between m-4 border-b border-b-borderGray pb-4">
				<span className="text-3xl">{isNew ? t('common:buttons.new') : communityAsset?.name}</span>
				<div className="flex flex-row items-center">
					<Button variant="outlined" size="small" className="buttonOutlinedGray" onClick={goBack} disabled={isSaving.items > 0}>
						{t('common:buttons.cancel')}
					</Button>
					<LoadingButton
						loading={isSaving.items > 0}
						variant="contained"
						size="small"
						className="ml-4 buttonContainedContrast text-barTextStrong"
						onClick={onSave}
						loadingIndicator={<CircularProgress size={20} className="text-white" />}
					>
						{t('common:buttons.save')}
					</LoadingButton>
				</div>
			</div>

			<div className="flex flex-col mx-4 mb-4 max-w-[600px]">
				<span className="font-medium text-sm text-textGray">{t('common:community.name')}</span>
				<span className="text-xs text-textGray">{t('common:error.maxChars', { num: 80 })}</span>
				<TextField
					fullWidth
					variant="outlined"
					margin="dense"
					size="small"
					value={form.name}
					onChange={(event) => onChange('name', event.target.value)}
					inputProps={{ className: 'text-sm', maxLength: 80 }}
					InputLabelProps={{ className: 'text-sm' }}
					sx={{ placeholder: 'text-sm' }}
					error={!validation.name.valid}
					helperText={
						!validation.name.valid &&
						t('common:' + validation.name.error, {
							field: t('common:community.name')
						})
					}
				/>

				<span className="mt-4 font-medium text-sm text-textGray">{t('common:community.shortDescription')}</span>
				<span className="text-xs text-textGray">{t('common:error.maxChars', { num: 280 })}</span>
				<TextField
					fullWidth
					variant="outlined"
					margin="dense"
					size="small"
					value={form.shortDescription}
					onChange={(event) => onChange('shortDescription', event.target.value)}
					inputProps={{ className: 'text-sm', maxLength: 280 }}
					InputLabelProps={{ className: 'text-sm' }}
					sx={{ placeholder: 'text-sm' }}
					error={!validation.shortDescription.valid}
					helperText={
						!validation.shortDescription.valid &&
						t('common:' + validation.shortDescription.error, {
							field: t('common:community.shortDescription')
						})
					}
				/>

				<span className="mt-4 font-medium text-sm text-textGray">{t('common:community.longDescription')}</span>
				<span className="text-xs text-textGray">{t('common:community.markdownAccepted')}</span>
				<TextField
					fullWidth
					variant="outlined"
					margin="dense"
					size="small"
					value={form.longDescription}
					onChange={(event) => onChange('longDescription', event.target.value)}
					inputProps={{ className: 'text-sm' }}
					InputLabelProps={{ className: 'text-sm' }}
					sx={{ placeholder: 'text-sm' }}
					multiline
					rows={6}
					error={!validation.longDescription.valid}
					helperText={
						!validation.longDescription.valid &&
						t('common:' + validation.longDescription.error, {
							field: t('common:community.longDescription')
						})
					}
				/>

				<span className="mt-4 font-medium text-sm text-textGray">{t('common:community.category')}</span>
				<TextField
					select
					fullWidth
					variant="outlined"
					margin="dense"
					size="small"
					value={form.category}
					onChange={(event) => onChange('category', event.target.value)}
					inputProps={{ className: 'text-sm' }}
					InputLabelProps={{ className: 'text-sm' }}
					sx={{ placeholder: 'text-sm' }}
					error={!validation.category.valid}
					helperText={
						!validation.category.valid &&
						t('common:' + validation.category.error, {
							field: t('common:community.category')
						})
					}
				>
					{sortArrayOfObjects(Object.values(COMMUNITY_CATEGORIES), (el) => el.label, true, true).map((el) => (
						<MenuItem key={el.key} value={el.key} className="text-sm">
							{el.label}
						</MenuItem>
					))}
				</TextField>

				<span className="mt-4 font-medium text-sm text-textGray">{t('common:community.screenshots')}</span>
				<span className="text-xs text-textGray mb-2">{t('common:community.screenshotsDesc')}</span>
				<CommunityImages images={images} setImages={setImages} validation={validation.images} />

				<span className="mt-4 font-medium text-sm text-textGray">{t('common:community.author')}</span>
				<span className="text-xs text-textGray">{t('common:community.authorDesc')}</span>
				<div className="text-sm mt-2 border border-borderForm rounded p-2 text-textGray flex flex-col">
					<span className="font-medium">
						{user?.name}&nbsp;{user?.surname}
					</span>
					{user?.bio && <span className="text-xs">{user?.bio}</span>}
					<div className="flex flex-row items-center">
						{user?.linkedIn && (
							<a target="_blank" rel="noreferrer" href={user.linkedIn}>
								<LinkedInIcon className="w-[15px] h-[15x] mr-3 mt-2" />
							</a>
						)}
						{user?.twitter && (
							<a target="_blank" rel="noreferrer" href={user.twitter}>
								<TwitterIcon className="w-[15px] h-[15x] mt-2" />
							</a>
						)}
					</div>
				</div>

				<span className="mt-4 font-medium text-sm text-textGray">{t('common:community.content')}</span>
				<span className="text-xs text-textGray">{t('common:community.contentDesc')}</span>
				<div className="flex flex-row items-center">
					<InfoIcon className="text-textGray w-[13px] h-[13px] mr-1" />
					<span className="text-xxs text-textGray">{t('common:community.contentRelationships')}</span>
				</div>

				{teamAssets && (
					<div className="flex flex-row items-center mt-2">
						<Autocomplete
							selected={selectedAsset}
							collection={Object.entries(teamAssets).filter((el) => [PRODUCTS.model.key, PRODUCTS.table.key].includes(el[1].type))}
							getOptionId={(option) => option[0]}
							getOptionLabel={(option) => option[1].name}
							onSelect={(value) => setSelectedAsset(value)}
							fieldName={t('common:community.content')}
						/>
						<Button size="small" className="buttonText ml-2" onClick={onAddAsset}>
							{t('common:buttons.add')}
						</Button>
					</div>
				)}

				{!validation.assets?.valid && <div className="text-red-500 ml-4 mt-1 text-xs">{t(validation.assets.error)}</div>}

				{form.assets?.map((aid, index) => {
					const asset = teamAssets ? teamAssets[aid] : null
					const product = asset ? PRODUCTS[asset.type] : null
					const name = asset?.name || t('common:community.deletedAsset')

					return (
						<div key={`communityAsset#${aid}`} className="flex flex-row items-center justify-between mt-2 bg-bgGray p-2 rounded">
							<div className="flex flex-row items-center">
								{product && (
									<Avatar className={`h-[20px] w-[20px] mr-2 ${product.theme} bg-[var(--bar-bg)]`} variant="rounded">
										{React.createElement(product.icon, { className: 'text-[13px]' })}
									</Avatar>
								)}
								<span className="text-sm">{name}</span>
							</div>
							<CloseIcon fontSize="small" className="text-textGray cursor-pointer w-[15px] h-[15px]" onClick={() => onDeleteAsset(index)} />
						</div>
					)
				})}
			</div>
		</Layout>
	)
}
