import { backend } from 'common/config/backend'
import { throwAPIError } from 'common/config/errors'

const FILE_NAME = 'spaceApi'

export async function createSpace(token, tid, uid, teamId, content) {
	const params = { tenantId: tid, userId: uid, teamId, content }
	const headers = { headers: { Authorization: 'Bearer ' + token } }
	return backend
		.post('/spaces', params, headers)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, createSpace.name)
			return { data: resp.data?.data }
		})
		.catch((error) => {
			throwAPIError(error, error.response.status, FILE_NAME, createSpace.name)
		})
}

export async function updateSpace(token, tenantId, userId, teamId, spaceId, content) {
	const params = { tenantId, userId, teamId, spaceId, content }
	const headers = { headers: { Authorization: 'Bearer ' + token } }
	return backend
		.patch('/spaces', params, headers)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, updateSpace.name)
			return { data: resp.data?.data }
		})
		.catch((error) => {
			throwAPIError(error, error.response.status, FILE_NAME, updateSpace.name)
		})
}

export async function deleteSpace(token, tenantId, teamId, spaceId) {
	const params = { data: { tenantId, teamId, spaceId }, headers: { Authorization: 'Bearer ' + token } }
	return backend
		.delete('/spaces', params)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, deleteSpace.name)
			return { data: resp.data?.data }
		})
		.catch((error) => {
			throwAPIError(error, error.response.status, FILE_NAME, deleteSpace.name)
		})
}
