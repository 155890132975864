// #### ROW MANAGEMENT
export const DELETE_ROWS = 'vizMiddlewareSaga/DELETE_ROWS'
export const UPDATE_ROW_DATA = 'vizMiddlewareSaga/UPDATE_ROW_DATA'
export const GET_ROW_DATA = 'vizMiddlewareSaga/GET_ROW_DATA'
export const UPDATE_CHART_DATA = 'vizMiddlewareSaga/UPDATE_CHART_DATA'
export const GET_CHART_DATA = 'vizMiddlewareSaga/GET_CHART_DATA'

// #### ROW MANAGEMENT
export const deleteRows = ({ tid, teamId, aid, deletes }) => {
	return { type: DELETE_ROWS, tid, teamId, aid, deletes }
}

export const updateRowData = ({ tenantId, teamId, modelId, viewId, rowId, page, isOpen, breakdown, hideZeros, hideNulls, modelProps, varProps, filter, sort, itemsPerPage, dates, updateCharts = false }) => {
	return { type: UPDATE_ROW_DATA, tenantId, teamId, modelId, viewId, rowId, page, isOpen, breakdown, hideZeros, hideNulls, modelProps, varProps, filter, sort, itemsPerPage, dates, updateCharts }
}

export const getRowData = ({ tid, teamId, aid, viewId, config }) => {
	return { type: GET_ROW_DATA, tid, teamId, aid, viewId, config }
}

export const getChartData = ({ tid, teamId, aid, cid, viewId, config }) => {
	return { type: GET_CHART_DATA, tid, teamId, aid, cid, viewId, config }
}

export const updateChartData = ({ tid, teamId, aid, cid, viewId, vid, page, breakdown, modelProps, varProps, filter, sort, dates }) => {
	return { type: UPDATE_CHART_DATA, tid, teamId, aid, cid, viewId, vid, page, breakdown, modelProps, varProps, filter, sort, dates }
}
