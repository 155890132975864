import React from 'react'
import LoadingBody from 'common/components/loading/LoadingBody'

export default function Loading() {
	return (
		<div className="w-full h-full flex flex-col items-center justify-center relative">
			<div className="absolute top-0 inset-x-0 h-topbar bg-barBg" />
			<LoadingBody />
		</div>
	)
}
