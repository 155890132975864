import { Link } from '@mui/material'
import { useTranslation } from 'react-i18next'

export default function Disclaimer({ isSignUp }) {
	const { t } = useTranslation('common')

	return (
		<p className="mb-4 text-xs text-gray-500">
			{isSignUp ? t('common:signup.disclaimerStartSingup') : t('common:signup.disclaimerStartSingin')}{' '}
			<Link href="https://singularly.com/terms-of-service" target="_blank" rel="noopener" className="text-gray-500 no-underline hover:underline">
				{t('common:signup.disclaimerTerms')}
			</Link>{' '}
			{t('common:signup.disclaimerAnd')}{' '}
			<Link href="https://singularly.com/privacy-policy" target="_blank" rel="noopener" className="text-gray-500 no-underline hover:underline">
				{t('common:signup.disclaimerPolicy')}
			</Link>
			,&nbsp;{t('common:signup.disclaimerEnd')}
		</p>
	)
}
