import { backend } from 'common/config/backend'
import { throwAPIError } from 'common/config/errors'

const FILE_NAME = 'userApi'

export async function updateUser(token, tenantId, userId, content) {
	const params = { tenantId, userId, content }
	const headers = { headers: { Authorization: 'Bearer ' + token } }
	return backend
		.patch('/users', params, headers)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, updateUser.name)
			return { data: resp.data }
		})
		.catch((error) => {
			throwAPIError(error, error.response.status, FILE_NAME, updateUser.name)
		})
}

export async function deleteUser(token, tenantId, userId) {
	const params = { data: { tenantId, userId }, headers: { Authorization: 'Bearer ' + token } }
	return backend
		.delete('/users', params)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, deleteUser.name)
			return { data: resp.data }
		})
		.catch((error) => {
			throwAPIError(error, error.response.status, FILE_NAME, deleteUser.name)
		})
}
