import { useSelector, useDispatch } from 'react-redux'
import { inviteUser, updateUser, deleteUser, updateAsset } from 'common/saga-actions/assetActions'
import Share from 'common/components/Share'
import { PRODUCTS } from 'common/constants/products'
import { useTranslation } from 'react-i18next'

export default function AssetShare({ isOpen, onClose, roles }) {
	const { t } = useTranslation('common')
	const dispatch = useDispatch()

	const tid = useSelector((state) => state.auth.tid)
	const uid = useSelector((state) => state.auth.uid)
	const aid = useSelector((state) => state.asset.aid)
	const name = useSelector((state) => state.asset.asset[aid]?.data?.name)
	const teamId = useSelector((state) => state.asset.asset[aid]?.data?.teamId)
	const type = useSelector((state) => state.asset.asset[aid]?.data?.type)
	const publicAccess = useSelector((state) => state.asset.asset[aid]?.data?.publicAccess)
	const users = useSelector((state) => state.asset.assetUsers[aid]?.result)
	const loadingInvite = useSelector((state) => state.global.loading?.inviteAssetUser)
	const loadingUpdate = useSelector((state) => state.global.loading?.updateAssetUser)
	const loadingGeneralUpdate = useSelector((state) => state.global.loading?.shareAssetGeneral)
	const teamName = useSelector((state) => state.team.team?.name)

	function onInviteUser({ email, role }) {
		dispatch(inviteUser({ tid, teamId, uid, aid, email, role }))
	}

	function onUpdateUser({ userId, role }) {
		dispatch(updateUser({ tid, teamId, aid, userId, role }))
	}

	function onDeleteUser({ userId }) {
		dispatch(deleteUser({ tid, teamId, aid, userId }))
	}

	function onGeneralUpdate({ isPublic, role }) {
		dispatch(updateAsset({ tid, aid, content: { publicAccess: { isPublic, role } }, loadingKey: 'shareAssetGeneral' }))
	}

	return (
		<Share
			isOpen={isOpen}
			onClose={onClose}
			name={name}
			product={type ? t(PRODUCTS[type]?.name) : ''}
			users={users}
			teamName={teamName}
			loadingInvite={loadingInvite}
			loadingUpdate={loadingUpdate}
			loadingGeneralUpdate={loadingGeneralUpdate && loadingGeneralUpdate[aid]}
			onInviteUser={onInviteUser}
			onUpdateUser={onUpdateUser}
			onDeleteUser={onDeleteUser}
			isEmbedCodeAllowed={true}
			isPublicAccessAllowed={true}
			publicAccess={publicAccess}
			onGeneralUpdate={onGeneralUpdate}
			roles={roles}
		/>
	)
}
