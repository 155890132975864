import { useCallback, useRef } from 'react'

// Cache bounding rect in a ref and only recompute every <delay>ms
export function useBoundingClientRect(ref, delay = 200) {
	const boundingRect = useRef(null)

	//initialize throttlePause variable outside throttle function
	let throttlePause

	const throttle = useCallback(
		(callback, delay) => {
			//don't run the function if throttlePause is true
			if (throttlePause) return

			//set throttlePause to true after the if condition. This allows the function to be run once
			throttlePause = true

			//setTimeout runs the callback within the specified time
			setTimeout(() => {
				callback()

				//throttlePause is set to false once the function has been called, allowing the throttle function to loop
				throttlePause = false
			}, delay)
		},
		[delay]
	)

	return useCallback(
		(force = false) => {
			if (force || !boundingRect.current) {
				boundingRect.current = ref.current?.getBoundingClientRect() || null
			} else {
				throttle(() => {
					boundingRect.current = ref.current?.getBoundingClientRect() || null
				}, delay)
			}

			return boundingRect.current
		},
		[ref]
	)
}
