import { IconButton, Popover, Accordion, AccordionSummary, AccordionDetails, Button, TextField, FormControlLabel, Switch } from '@mui/material'
import { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { ExpandMore } from '@mui/icons-material'
import { validateInteger } from 'common/utils/validate'
import { useDispatch, useSelector } from 'react-redux'
import { MODEL_PARAMS } from 'model/constants/modelParameters'
import { isEqual } from 'lodash'
import { recompute } from 'model/saga-actions/modelEngineActions'
import { setSimulation } from 'model/saga-actions/engineMiddlewareActions'
import { useTranslation } from 'react-i18next'
import { updateTeam } from 'common/saga-actions/teamActions'
import { updateModel } from 'model/saga-actions/modelActions'

export default function ModelEngine({ aid, anchor, onClose }) {
	const dispatch = useDispatch()
	const { t } = useTranslation(['common', 'model'])

	// #### REDUX
	const tid = useSelector((state) => state.auth.tid)
	const teamId = useSelector((state) => state.asset.asset[aid]?.data?.teamId)
	const simulation = useSelector((state) => state.team.team?.simulation)
	const disableEngine = useSelector((state) => state.model.model[aid]?.data?.disableEngine)
	const confidence = useSelector((state) => state.model.model[aid]?.data?.confidence)
	const simulationLimit = useSelector((state) => state.team.team?.limits?.simulations)

	// #### POPOVER CONTROL
	const isPopoverOpen = Boolean(anchor)

	// #### DEFAULT VALUES
	const defaultForm = {
		confidence: MODEL_PARAMS.CONFIDENCE_LEVEL_AVG + '',
		numSimulations: MODEL_PARAMS.NUM_SIMULATIONS_MIN + '',
		disableEngine: true,
		displayIntervals: false
	}

	const defaultValidation = {
		confidence: { valid: true, error: '' },
		numSimulations: { valid: true, error: '' }
	}

	// #### STATE
	const [form, setForm] = useState(defaultForm)
	const [changeSimulations, setChangeSimulations] = useState(false)
	const [changeConfidence, setChangeConfidence] = useState(false)
	const [validation, setValidation] = useState(defaultValidation)
	const [openPanel, setOpenPanel] = useState('calculation')

	// #### EFFECTS
	useEffect(() => {
		setForm({
			confidence: confidence?.confidence ? confidence?.confidence + '' : defaultForm.confidence,
			numSimulations: simulation?.numSimulations ? simulation?.numSimulations + '' : defaultForm.numSimulations,
			displayIntervals: confidence?.displayIntervals || false,
			disableEngine: disableEngine || false
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [simulation, confidence])

	// #### FUNCTIONS
	function togglePanel(key) {
		if (openPanel === key) setOpenPanel(null)
		else setOpenPanel(key)
	}

	function onChange(key, value) {
		if(key === 'numSimulations') setChangeSimulations(true)
		if(key === 'confidence' || key === 'displayIntervals') setChangeConfidence(true)
		setForm({ ...form, [key]: value })
	}

	function onCompute() {
		dispatch(recompute({ tid, teamId, aid }))
		onClose()
	}

	function onDisableEngine(checked) {
		dispatch(updateModel({ tid, aid, content: { "disableEngine": checked } }))
	}

	function onSaveSimulation() {
		var confidenceValid = validateInteger(form.confidence)
		if (confidenceValid.valid) {
			const confidenceInt = parseInt(form.confidence)
			confidenceValid =
				confidenceInt < MODEL_PARAMS.CONFIDENCE_LEVEL_MIN || confidenceInt > MODEL_PARAMS.CONFIDENCE_LEVEL_MAX
					? { valid: false, error: t('common:error.range', { range1: MODEL_PARAMS.CONFIDENCE_LEVEL_MIN, range2: MODEL_PARAMS.CONFIDENCE_LEVEL_MAX }) }
					: { valid: true, error: '' }
		}
		var simulationsValid = validateInteger(form.numSimulations)
		if (simulationsValid.valid) {
			const simulationsInt = parseInt(form.numSimulations)
			simulationsValid =
				simulationsInt < MODEL_PARAMS.NUM_SIMULATIONS_MIN || simulationsInt > MODEL_PARAMS.NUM_SIMULATIONS_MAX
					? { valid: false, error: t('common:error.range', { range1: MODEL_PARAMS.NUM_SIMULATIONS_MIN, range2: MODEL_PARAMS.NUM_SIMULATIONS_MAX }) }
					: simulationsInt > simulationLimit
					? { valid: false, error: t('common:error.limits', { field: t('common:limit.simulations') }) }
					: { valid: true, error: '' }
		}

		setValidation({ ...validation, confidence: confidenceValid, numSimulations: simulationsValid })
		if (confidenceValid.valid && simulationsValid.valid) {
			if(changeSimulations) {
				const newSimulations = parseInt(form.numSimulations)
				const newSimulation = { numSimulations: newSimulations }
				if (!isEqual(simulation, newSimulation)) {
					dispatch(updateTeam({ teamId, content: { 'simulation': newSimulation } }))
					dispatch(setSimulation({ tid, aid, simulation: newSimulation }))
				}
			}
			if(changeConfidence) {
				const newConfidence = parseInt(form.confidence)
				const newDisplayIntervals = form.displayIntervals
				const newConfidences = { confidence: newConfidence, displayIntervals: newDisplayIntervals }
				if (!isEqual(confidence, newConfidences)) {
					dispatch(updateModel({ tid, aid, content: { 'confidence': newConfidences } }))
				}
			}
			onClose()
		}
		
	}

	return (
		<Popover open={isPopoverOpen} anchorEl={anchor} onClose={onClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} classes={{ paper: 'flex w-[400px] flex shadow-md' }}>
			<div className="flex-1 flex flex-col pb-4">
				<div className="flex flex-row justify-between items-center border-b border-b-borderGray p-2 pl-4">
					<span className="text-md font-medium text-textGray">{t('model:engine.settings')}</span>
					<IconButton size="small" className="text-textGray" onClick={onClose}>
						<CloseIcon fontSize="small" />
					</IconButton>
				</div>

				<div className="flex-1 px-4 flex flex-col mt-4">
					<Accordion expanded={openPanel === 'calculation'} onChange={() => togglePanel('calculation')}>
						<AccordionSummary className="text-sm m-0 min-h-0" classes={{ content: 'my-2 flex flex-col' }} expandIcon={<ExpandMore fontSize="small" />}>
							{t('model:engine.calculation')}
							<div className="text-xs text-textGray">{t('model:engine.calculationDesc')}</div>
						</AccordionSummary>
						<AccordionDetails>
							<FormControlLabel
								control={<Switch checked={form.disableEngine} onChange={(e) => onDisableEngine(e.target.checked)} color="primary" size="small" />}
								label={t('model:engine.disableEngine')}
								classes={{ root: 'mt-2 mb-2 ml-[0.5px]', label: 'ml-2 text-sm' }}
							/>
							<Button fullWidth onClick={onCompute} size="small" className="buttonContainedContrast normal-case">
								{t('model:engine.recompute')}
							</Button>
						</AccordionDetails>
					</Accordion>
					<Accordion expanded={openPanel === 'simulation'} onChange={() => togglePanel('simulation')}>
						<AccordionSummary className="text-sm m-0 min-h-0" classes={{ content: 'my-2 flex flex-col' }} expandIcon={<ExpandMore fontSize="small" />}>
							{t('model:engine.simulation')}
							<div className="text-xs text-textGray">{t('model:engine.simulationDesc')}</div>
						</AccordionSummary>
						<AccordionDetails>
							<TextField
								type="number"
								min="1"
								max="100"
								step="1"
								label={t('model:engine.confidence')}
								fullWidth
								variant="outlined"
								margin="dense"
								size="small"
								value={form.confidence}
								onChange={(event) => onChange('confidence', event.target.value)}
								error={!validation.confidence.valid}
								helperText={!validation.confidence.valid && t(validation.confidence.error, { field: t('model:engine.confidence') })}
								inputProps={{ className: 'text-sm', min: 0, max: 100 }}
							/>
							<TextField
								type="number"
								min="1"
								max="100"
								step="1"
								label={t('model:engine.numSimulations')}
								fullWidth
								variant="outlined"
								margin="dense"
								size="small"
								value={form.numSimulations}
								onChange={(event) => onChange('numSimulations', event.target.value)}
								error={!validation.numSimulations.valid}
								helperText={!validation.numSimulations.valid && t(validation.numSimulations.error, { field: t('model:engine.numSimulations') })}
								inputProps={{ className: 'text-sm', min: 0, max: 10000 }}
								className="mt-4"
							/>
							<FormControlLabel
								control={<Switch checked={form.displayIntervals} onChange={(e) => onChange('displayIntervals', e.target.checked)} color="primary" size="small" />}
								label={t('model:engine.displayIntervals')}
								classes={{ root: 'mt-4 ml-[0.5px]', label: 'ml-2 text-sm' }}
							/>
							<Button fullWidth onClick={onSaveSimulation} size="small" className="buttonContainedContrast normal-case mt-4">
								{t('common:buttons.save')}
							</Button>
						</AccordionDetails>
					</Accordion>
				</div>
			</div>
		</Popover>
	)
}
