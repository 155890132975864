import { backend } from 'common/config/backend'
import { throwAPIError } from 'common/config/errors'

const FILE_NAME = 'connectorApi'

export async function enableConnector(token, tenantId, teamId, connectorId) {
	const params = { tenantId, teamId, connectorId }
	const headers = { headers: { Authorization: 'Bearer ' + token } }
	return backend
		.post('/connectors/enable', params, headers)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, createConnector.name)
			return { data: resp.data?.data }
		})
		.catch((error) => {
			throwAPIError(error, error.response.status, FILE_NAME, createConnector.name)
		})
}

export async function disableConnector(token, tenantId, teamId, connectorId) {
	const params = { tenantId, teamId, connectorId }
	const headers = { headers: { Authorization: 'Bearer ' + token } }
	return backend
		.post('/connectors/disable', params, headers)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, createConnector.name)
			return { data: resp.data?.data }
		})
		.catch((error) => {
			throwAPIError(error, error.response.status, FILE_NAME, createConnector.name)
		})
}

export async function createConnector(token, tenantId, teamId, userId, config) {
	const params = { tenantId, teamId, userId, config }
	const headers = { headers: { Authorization: 'Bearer ' + token } }
	return backend
		.post('/connectors', params, headers)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, createConnector.name)
			return { data: resp.data?.data }
		})
		.catch((error) => {
			throwAPIError(error, error.response.status, FILE_NAME, createConnector.name)
		})
}

export async function updateConnector(token, tenantId, teamId, connectorId, userId, config) {
	const params = { tenantId, teamId, connectorId, userId, config }
	const headers = { headers: { Authorization: 'Bearer ' + token } }
	return backend
		.patch('/connectors', params, headers)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, updateConnector.name)
			return { data: resp.data?.data }
		})
		.catch((error) => {
			throwAPIError(error, error.response.status, FILE_NAME, updateConnector.name)
		})
}

export async function remove(token, tenantId, teamId, userId, connectorId) {
	let config = {
		data: { tenantId, teamId, userId, connectorId },
		headers: { Authorization: 'Bearer ' + token }
	}
	return backend
		.delete('/connectors', config)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, remove.name)
			return resp.data
		})
		.catch((error) => {
			throwAPIError(error, error.response.status, FILE_NAME, remove.name)
		})
}

export async function sync(token, teamId, connectionId) {
	let headers = { headers: { Authorization: 'Bearer ' + token } }
	const params = { connectionId, teamId }
	return backend
		.post('/connectors/sync', params, headers)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, sync.name)
			return resp.data
		})
		.catch((error) => {
			throwAPIError(error, error.response.status, FILE_NAME, sync.name)
		})
}

export async function jobs(token, teamId, configId) {
	let headers = { headers: { Authorization: 'Bearer ' + token } }
	const params = { teamId, configId }
	return backend
		.post('/connectors/jobs', params, headers)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, jobs.name)
			return resp.data
		})
		.catch((error) => {
			throwAPIError(error, error.response.status, FILE_NAME, jobs.name)
		})
}

export async function refreshSchema(token, tenantId, teamId, connectorId, stream) {
	let headers = { headers: { Authorization: 'Bearer ' + token } }
	const params = { tenantId, teamId, connectorId, stream }
	return backend
		.post('/connectors/refresh', params, headers)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, sync.name)
			return resp.data
		})
		.catch((error) => {
			throwAPIError(error, error.response.status, FILE_NAME, sync.name)
		})
}