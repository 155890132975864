import { backend } from 'common/config/backend'
import { throwAPIError } from 'common/config/errors'

const FILE_NAME = 'teamApi'

export async function createTeam(token, tenantId, uid, content) {
	const params = { tenantId, userId: uid, content }
	const headers = { headers: { Authorization: 'Bearer ' + token } }
	return backend
		.post('/teams', params, headers)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, createTeam.name)
			return { data: resp.data?.data }
		})
		.catch((error) => {
			throwAPIError(error, error.response.status, FILE_NAME, createTeam.name)
		})
}

export async function updateTeam(token, tenantId, teamId, content) {
	const params = { tenantId, teamId, content }
	const headers = { headers: { Authorization: 'Bearer ' + token } }
	return backend
		.patch('/teams', params, headers)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, updateTeam.name)
			return resp.data
		})
		.catch((error) => {
			throwAPIError(error, error.response.status, FILE_NAME, updateTeam.name)
		})
}

export async function deleteTeam(token, tenantId, teamId) {
	const params = { data: { tenantId, teamId }, headers: { Authorization: 'Bearer ' + token } }
	return backend
		.delete('/teams', params)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, deleteTeam.name)
			return resp.data
		})
		.catch((error) => {
			throwAPIError(error, error.response.status, FILE_NAME, deleteTeam.name)
		})
}

export async function generateApiKey(token, tenantId, teamId) {
	const params = { tenantId, teamId }
	const headers = { headers: { Authorization: 'Bearer ' + token } }
	return backend
		.post('/teams/apiKey', params, headers)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, createTeam.name)
			return { data: resp.data?.data }
		})
		.catch((error) => {
			throwAPIError(error, error.response.status, FILE_NAME, createTeam.name)
		})
}

export async function deleteApiKey(token, tenantId, teamId, apiId) {
	const params = { data: { tenantId, teamId, apiId }, headers: { Authorization: 'Bearer ' + token } }
	return backend
		.delete('/teams/apiKey', params)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, createTeam.name)
			return { data: resp.data?.data }
		})
		.catch((error) => {
			throwAPIError(error, error.response.status, FILE_NAME, createTeam.name)
		})
}

export async function inviteUser(token, tenantId, teamId, userId, email, role) {
	const params = { tenantId, teamId, userId, email, role }
	const headers = { headers: { Authorization: 'Bearer ' + token } }
	return backend
		.post('/teams/user', params, headers)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, inviteUser.name)
			return { data: resp.data?.data }
		})
		.catch((error) => {
			throwAPIError(error, error.response.status, FILE_NAME, inviteUser.name)
		})
}

export async function updateUser(token, tenantId, teamId, uid, role) {
	const params = { tenantId, teamId, uid, role }
	const headers = { headers: { Authorization: 'Bearer ' + token } }
	return backend
		.patch('/teams/user', params, headers)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, updateUser.name)
			return { data: resp.data?.data }
		})
		.catch((error) => {
			throwAPIError(error, error.response.status, FILE_NAME, updateUser.name)
		})
}

export async function deleteUser(token, tenantId, teamId, userId) {
	const params = { data: { tenantId, teamId, uid: userId }, headers: { Authorization: 'Bearer ' + token } }
	return backend
		.delete('/teams/user', params)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, deleteUser.name)
			return resp.data
		})
		.catch((error) => {
			throwAPIError(error, error.response.status, FILE_NAME, deleteUser.name)
		})
}

export async function acceptInvite(token, tenantId, teamId, uid) {
	const params = { tenantId, teamId, userId: uid }
	const headers = { headers: { Authorization: 'Bearer ' + token } }
	return backend
		.post('/teams/user/accept', params, headers)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, acceptInvite.name)
			return resp.data
		})
		.catch((error) => {
			throwAPIError(error, error.response.status, FILE_NAME, acceptInvite.name)
		})
}

export async function rejectInvite(token, tenantId, teamId, uid) {
	const params = { tenantId, teamId, userId: uid }
	const headers = { headers: { Authorization: 'Bearer ' + token } }
	return backend
		.post('/teams/user/reject', params, headers)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, rejectInvite.name)
			return resp.data
		})
		.catch((error) => {
			throwAPIError(error, error.response.status, FILE_NAME, rejectInvite.name)
		})
}
