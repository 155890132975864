// #### SUBSCRIBE TO TEAMS
export const LOAD_TEAMS = 'teamSaga/LOAD_TEAMS'
export const CANCEL_TEAMS = 'teamSaga/CANCEL_TEAMS'
// #### MANAGE TEAMS
export const CREATE_TEAM = 'teamSaga/CREATE_TEAM'
export const UPDATE_TEAM = 'teamSaga/UPDATE_TEAM'
export const DELETE_TEAM = 'teamSaga/DELETE_TEAM'
export const GENERATE_API_KEY = 'teamSaga/GENERATE_API_KEY'
export const DELETE_API_KEY = 'teamSaga/DELETE_API_KEY'
// SUBSCRIBE TO USERS
export const LOAD_TEAM_USERS = 'teamSaga/LOAD_TEAM_USERS'
export const CANCEL_TEAM_USERS = 'teamSaga/CANCEL_TEAM_USERS'
// SUBSCRIBE TO CATEGORIES
export const LOAD_TEAM_CATEGORIES = 'teamSaga/LOAD_TEAM_CATEGORIES'
export const CANCEL_TEAM_CATEGORIES = 'teamSaga/CANCEL_TEAM_CATEGORIES'
// SUBSCRIBE TO RELATIOSHIPS
export const LOAD_TEAM_RELATIONSHIPS = 'teamSaga/LOAD_TEAM_RELATIONSHIPS'
export const CANCEL_TEAM_RELATIONSHIPS = 'teamSaga/CANCEL_TEAM_RELATIONSHIPS'
// #### MANAGE PERMITS
export const INVITE_USER = 'teamSaga/INVITE_USER'
export const UPDATE_USER = 'teamSaga/UPDATE_USER'
export const DELETE_USER = 'teamSaga/DELETE_USER'
export const ACCEPT_INVITE = 'teamSaga/ACCEPT_INVITE'
export const REJECT_INVITE = 'teamSaga/REJECT_INVITE'

// #### SUBSCRIBE TO TEAMS
export const loadTeams = ({ tid, uid }) => {
	return { type: LOAD_TEAMS, tid, uid }
}

export const cancelTeams = () => {
	return { type: CANCEL_TEAMS }
}

// #### MANAGE TEAMS
export const createTeam = ({ name }) => {
	return { type: CREATE_TEAM, name }
}

export const updateTeam = ({ teamId, content }) => {
	return { type: UPDATE_TEAM, teamId, content }
}

export const deleteTeam = ({ tid, teamId }) => {
	return { type: DELETE_TEAM, tid, teamId }
}

export const generateApiKey = ({ tid, teamId }) => {
	return { type: GENERATE_API_KEY, tid, teamId }
}

export const deleteApiKey = ({ tid, teamId, apiId }) => {
	return { type: DELETE_API_KEY, tid, teamId, apiId }
}

// SUBSCRIBE TO USERS
export const loadTeamUsers = ({ tid, teamId }) => {
	return { type: LOAD_TEAM_USERS, tid, teamId }
}

export const cancelTeamUsers = () => {
	return { type: CANCEL_TEAM_USERS }
}

// SUBSCRIBE TO CATEGORIES
export const loadTeamCategories = ({ tid, teamId }) => {
	return { type: LOAD_TEAM_CATEGORIES, tid, teamId }
}

export const cancelTeamCategories = () => {
	return { type: CANCEL_TEAM_CATEGORIES }
}

// SUBSCRIBE TO CATEGORIES
export const loadTeamRelationship = ({ tid, teamId }) => {
	return { type: LOAD_TEAM_RELATIONSHIPS, tid, teamId }
}

export const cancelTeamRelationship = () => {
	return { type: CANCEL_TEAM_RELATIONSHIPS }
}

// #### MANAGE PERMITS
export const inviteUser = ({ tid, uid, teamId, email, role }) => {
	return { type: INVITE_USER, tid, uid, teamId, email, role }
}

export const updateUser = ({ tid, teamId, userId, role }) => {
	return { type: UPDATE_USER, tid, teamId, userId, role }
}

export const deleteUser = ({ tid, teamId, userId }) => {
	return { type: DELETE_USER, tid, teamId, userId }
}

export const acceptInvite = ({ teamId }) => {
	return { type: ACCEPT_INVITE, teamId }
}

export const rejectInvite = ({ teamId }) => {
	return { type: REJECT_INVITE, teamId }
}
