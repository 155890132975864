import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { resetPassword } from 'common/saga-actions/authActions'
import LoadingButton from '@mui/lab/LoadingButton'
import { CircularProgress } from '@mui/material'
import Alert from 'common/components/alert/Alert'

export default function AccountResetPassword() {
	const dispatch = useDispatch()
	const { t } = useTranslation('common')

	const isLoading = useSelector((state) => state.global.loading?.resetPassword)
	const email = useSelector((state) => state.auth.email)

	function onReset() {
		dispatch(resetPassword({ email }))
	}

	return (
		<div className="flex flex-col">
			<div>
				<LoadingButton
					loading={isLoading}
					size="small"
					variant="contained"
					className="buttonContainedContrast"
					onClick={onReset}
					loadingIndicator={<CircularProgress size={20} className="text-white" />}
				>
					{t('common:account.reset')}
				</LoadingButton>
			</div>
			<span className="text-sm mt-4">{t('common:account.reset_info')}</span>
			<div className="w-full">
				<Alert messageKey="resetPassword" className="mt-4" />
			</div>
		</div>
	)
}
