import { BrowserRouter, Route, Routes, Navigate, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useApplyTheme } from 'common/hooks/useApplyTheme'
// General components
import Loading from 'common/components/loading/Loading'
import Initial from 'common/screens/team/Initial'
// Data loaders
import useAuthenticate from 'common/hooks/useAuthenticate'
import RootPathLoader from 'common/loaders/RootPathLoader'
import AssetPathLoader from 'common/loaders/AssetPathLoader'
// Authentication
import Login from 'common/screens/auth/Login'
import Signup from 'common/screens/auth/Signup'
import Authentication from 'common/screens/auth/Authentication'
// Logged in
import Asset from 'common/screens/asset/Asset'
import Model from 'model/screens/Model'
import DataSources from 'automation/screens/DataSources'
import Table from 'table/screens/Table'
import Billing from 'common/screens/billing/Billing'
import Community from 'common/screens/community/Community'
import CommunityPathLoader from 'common/loaders/CommunityPathLoader'
import CommunityAsset from 'common/screens/community/CommunityAsset'
import CommunityUse from 'common/screens/community/CommunityUse'
import Space from 'common/screens/space/Space'

export default function Router() {
	useAuthenticate()
	useApplyTheme()

	const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)
	const checkDone = useSelector((state) => state.auth.checkDone)

	if (!checkDone) return <Loading />
	return (
		<BrowserRouter>
			<Routes>
				<Route element={<RootPathLoader />}>
					{/** ENTRY **/}
					<Route exact path="/" element={isLoggedIn ? <Navigate to="/teams" replace /> : <Navigate to="/login" replace />} />
					{/** NON LOGGED-IN ROUTES **/}
					<Route exact path="/login" element={isLoggedIn ? <Navigate to="/teams" replace /> : <Login />} />
					<Route exact path="/signup" element={isLoggedIn ? <Navigate to="/teams" replace /> : <Signup />} />
					{/** LOGGED-IN ROUTES **/}
					<Route element={<Authentication />}>
						<Route exact path="/use/:aid" element={<CommunityUse />} />
					</Route>
					<Route exact path="/teams" element={isLoggedIn ? <Initial /> : <Navigate to="/login" replace />} />
					<Route path="/team/:teamId">
						<Route exact path="datasources" element={isLoggedIn ? <DataSources /> : <Navigate to="/login" replace />} />
						<Route exact path="billing" element={isLoggedIn ? <Billing /> : <Navigate to="/login" replace />} />
						<Route exact path="space/:sid" element={isLoggedIn ? <Space /> : <Navigate to="/login" replace />} />
						{/* <Route exact path="folder/:folderId" element={isLoggedIn ? <Team /> : <Navigate to="/login" replace />} /> */}
						<Route exact path="" element={isLoggedIn ? <Initial /> : <Navigate to="/login" replace />} />
						<Route element={<CommunityPathLoader />}>
							<Route exact path="community" element={isLoggedIn ? <Community /> : <Navigate to="/login" replace />} />
							<Route exact path="community/template/:aid" element={isLoggedIn ? <CommunityAsset /> : <Navigate to="/login" replace />} />
							<Route exact path="community/template" element={isLoggedIn ? <CommunityAsset /> : <Navigate to="/login" replace />} />
							<Route exact path="community/data/:aid" element={isLoggedIn ? <CommunityAsset /> : <Navigate to="/login" replace />} />
							<Route exact path="community/data" element={isLoggedIn ? <CommunityAsset /> : <Navigate to="/login" replace />} />
						</Route>
					</Route>

					<Route element={<AssetPathLoader />}>
						{/* MODEL */}
						<Route exact path="/model/:aid" element={<Asset />} />
						<Route exact path="/model/:aid/model/:aid2" element={<Asset />} />
						<Route exact path="/model/:aid/table/:aid2" element={<Asset />} />
						<Route
							exact
							path="/embed/model/:aid"
							element={
								<div className="h-full flex">
									<Model />
								</div>
							}
						/>
						{/* TABLE */}
						<Route exact path="/table/:aid" element={<Asset />} />
						<Route exact path="/table/:aid/model/:aid2" element={<Asset />} />
						<Route exact path="/table/:aid/table/:aid2" element={<Asset />} />
						<Route
							exact
							path="/embed/table/:aid"
							element={
								<div className="h-full flex">
									<Table />
								</div>
							}
						/>
					</Route>
				</Route>
			</Routes>
		</BrowserRouter>
	)
}
