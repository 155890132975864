import { useEffect, useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, IconButton, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'

export default function ActionConfirm({ open, onClose, onConfirm, title = null, content = null, confirmButtonLabel, isLoading = false }) {
	const { t } = useTranslation('common')
	const modalRef = useRef()

	const [isLoadingCopy, setIsLoadingCopy] = useState(false)

	// Close modal after loading is complete
	useEffect(() => {
		const prevIsLoading = isLoadingCopy
		if (prevIsLoading && !isLoading) onClose()
		setIsLoadingCopy(isLoading)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading])

	const onMouseDown = useCallback((e) => {
		if (e.preventDefault) e.preventDefault()
		if (e.stopPropagation) e.stopPropagation()
		const clickInside = modalRef.current.contains(e.target)
		if (!clickInside) onClose()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const onKeyDown = useCallback((e) => {
		if (e.key === 'Enter') {
			if (e.preventDefault) e.preventDefault()
			if (e.stopPropagation) e.stopPropagation()
			onConfirm()
		} else if (e.key === 'Escape') {
			if (e.preventDefault) e.preventDefault()
			if (e.stopPropagation) e.stopPropagation()
			onClose()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// If modal is open, attach an onMouseDown event to the cell to prevent clicks from propagating to parent components
	useEffect(() => {
		if (open) {
			document.addEventListener('mousedown', onMouseDown, true)
			document.addEventListener('keydown', onKeyDown, true)
		}
		return () => {
			document.removeEventListener('mousedown', onMouseDown, true)
			document.removeEventListener('keydown', onKeyDown, true)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open])

	function closeClick(e) {
		if (e.preventDefault) e.preventDefault()
		if (e.stopPropagation) e.stopPropagation()
		onClose()
	}

	function confirmClick(e) {
		if (e.preventDefault) e.preventDefault()
		if (e.stopPropagation) e.stopPropagation()
		onConfirm()
	}

	return (
		<div>
			<Dialog open={open} onClose={closeClick} maxWidth="xs" fullWidth PaperProps={{ ref: modalRef }}>
				<DialogTitle className="mb-2">
					<span className="text-lg">{title || t('common:messages.confirm')}</span>
					<IconButton size="small" className="absolute top-4 right-4 text-textGray" onClick={closeClick}>
						<CloseIcon />
					</IconButton>
				</DialogTitle>

				<DialogContent className="text-sm">{content}</DialogContent>

				<DialogActions>
					<Button onClick={closeClick} className="buttonText" size="small">
						{t('common:buttons.cancel')}
					</Button>
					<LoadingButton onClick={confirmClick} className="buttonTextPrimary" size="small" loading={isLoading} classes={{ loadingIndicator: 'text-primary' }}>
						{confirmButtonLabel || t('common:buttons.delete')}
					</LoadingButton>
				</DialogActions>
			</Dialog>
		</div>
	)
}
