import React, { useState, useEffect, memo, useRef, useContext, useCallback, useLayoutEffect } from 'react'
import { FUNCTIONS, CONSTANTS } from 'common/constants/formulas'
import { FormulaBarContext } from 'common/components/formula/FormulaBarContext'
import { EditorView, keymap } from '@codemirror/view'
import { EditorState, StateEffect } from '@codemirror/state'
import { StreamLanguage, bracketMatching } from '@codemirror/language'
import { defaultKeymap } from '@codemirror/commands'
import { spreadsheet } from '@codemirror/legacy-modes/mode/spreadsheet'
import { closeBrackets, autocompletion, snippetCompletion, completionStatus, acceptCompletion, closeCompletion } from '@codemirror/autocomplete'
import { identifyVariables } from 'common/components/formula/ExtensionVariables'
import { helper, closeHelper } from 'common/components/formula/ExtensionHelper'
import clsx from 'clsx'

export default memo(function FormulaCell({ rowIndex, colIndex, focus = true, cancel = false, autoSelect = false, autocompletePosition = null, autoFocus = false, isFixed = false, isDisabled = false }) {
	const cmRef = useRef()
	const { variables, attributes, saveFormula, getFormulaFunction } = useContext(FormulaBarContext)

	// #### STATE
	const [formula, setFormula] = useState(null)
	const [editor, setEditor] = useState(null)
	const [value, setValue] = useState('')
	const [currentFocus, setCurrentFocus] = useState({ is: false, cancel: false, autoSelect: false })
	const [prevFocus, setPrevFocus] = useState({ is: false, cancel: false, autoSelect: false })
	const [prevFocusCell, setPrevFocusCell] = useState(null)

	// #### FORMULA
	// Retrieve formula from cell
	const initializeFormula = useCallback(
		(rowIndex, colIndex) => {
			var newFormula = null
			if (rowIndex != null && colIndex != null && getFormulaFunction) newFormula = getFormulaFunction(rowIndex, colIndex)
			setFormula(newFormula)
			return getFormulaExpression(newFormula)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[getFormulaFunction]
	)

	const getFormulaExpression = useCallback((formula) => {
		const expression = formula?.canHaveFormula ? formula?.formula?.expression || '' : ''
		return expression
	}, [])

	// Initialize formula when cell changes
	useEffect(() => {
		const expression = initializeFormula(rowIndex, colIndex)
		setEditorValue(editor, expression, currentFocus.is)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rowIndex, colIndex])

	// #### CREATE THE EDITOR
	useLayoutEffect(() => {
		const expression = initializeFormula(rowIndex, colIndex)

		var newEditor = new EditorView({
			doc: expression,
			parent: cmRef.current,
			extensions: getExtensions()
		})
		if (autoFocus) focusOnEditor(newEditor, autoFocus, currentFocus.autoSelect)
		setEditor(newEditor)

		return () => {
			setEditor(null)
			newEditor?.destroy()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// When variables or attributes change, the editor must be reconfigured to get them
	useEffect(() => {
		if (!editor) return
		editor.dispatch({
			effects: StateEffect.reconfigure.of(getExtensions())
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [variables, attributes, isDisabled])

	// #### EDITOR LIFECYCLE MANAGEMENT
	// Update focus state
	useLayoutEffect(() => {
		// When focus is lost, we want to remember the cell being edited to submit an update
		if (focus) setPrevFocusCell({ rowIndex, colIndex })
		else if (prevFocus.is && !currentFocus.is) {
			setPrevFocusCell(null)
		}
		setPrevFocus(currentFocus)
		setCurrentFocus({ is: focus, cancel: cancel, autoSelect: autoSelect })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [focus])

	// Update editor when focus state changes
	useLayoutEffect(() => {
		// If focus is gained, refresh value and focus editor
		if (currentFocus.is && editor) {
			const expression = initializeFormula(rowIndex, colIndex)
			setEditorValue(editor, expression, true)
			focusOnEditor(editor, autoFocus, currentFocus.autoSelect)
		}
		// If focus is lost
		if (!currentFocus.is && prevFocus.is) {
			// Revert codemirror content to the original formula
			if (currentFocus.cancel) setEditorValue(editor, getFormulaExpression(formula), false)
			// Send new formula to be saved
			else saveFormula(value, prevFocusCell.rowIndex, prevFocusCell.colIndex)
		}
		if (!currentFocus.is) blurEditor(editor)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentFocus])

	// Update listener
	const updateListener = useCallback((update) => {
		if (update.docChanged) {
			const doc = update.state.doc
			const value = doc.toString()
			setValue(value)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// Focus on editor
	const focusOnEditor = useCallback((editor, autoFocus, autoSelect) => {
		if (!editor) return
		editor.focus()
		const length = editor.state?.doc.length || 0
		if (autoSelect) editor.dispatch({ selection: { anchor: 0, head: length } })
		else if (autoFocus) editor.dispatch({ selection: { anchor: length, head: length } })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// Blur editor
	const blurEditor = useCallback((editor) => {
		if (!editor) return
		editor.contentDOM.blur()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// Set editor's value
	const setEditorValue = useCallback((editor, value, focus) => {
		if (!editor) return
		if (value === editor.state?.doc) return
		const length = editor.state?.doc.length || 0
		editor.dispatch({ changes: { from: 0, to: length, insert: value } })
		if (!focus) blurEditor(editor)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// #### EDITOR CONFIGURATION
	// Configure autocomplete
	const myCompletions = useCallback(
		(context) => {
			let isFormula = context.matchBefore(/^=.*/)
			let initial = context.matchBefore(/^=/)
			let word = context.matchBefore(/[a-zA-Z][a-zA-Z\s]*/)
			if (!isFormula || (!initial && !word)) return null
			return {
				from: initial ? initial.to : word.from,
				options: [...FUNCTIONS.map((item) => snippetCompletion(item.snippet, { label: item.label, detail: item.detail, type: item.type })), ...CONSTANTS, ...variables, ...attributes]
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[variables, attributes]
	)

	// Autocomplete styles: https://github.com/codemirror/autocomplete/blob/main/src/theme.ts
	const myTheme = EditorView.theme(
		{
			'&': {},
			'.cm-content': {
				fontSize: 12,
				fontFamily: 'Roboto'
			},
			'.cm-tooltip': {
				border: 'none',
				backgroundColor: 'white'
			},
			'&.cm-editor': {
				'&.cm-focused': {
					outline: 'none'
				}
			},
			'.cm-tooltip.cm-tooltip-autocomplete': {
				'& > ul': {
					fontSize: 12,
					fontFamily: 'Roboto',
					whiteSpace: 'nowrap',
					overflow: 'hidden auto',
					maxWidth_fallback: '700px',
					maxWidth: 'min(700px, 95vw)',
					minWidth: '250px',
					maxHeight: '10em',
					border: 'none',
					background: 'white',
					borderRadius: '4px',
					boxShadow: '0 2px 6px 2px rgba(60, 64, 67, 0.15)',
					padding: '5px 0',
					'& > li': {
						overflowX: 'hidden',
						textOverflow: 'ellipsis',
						cursor: 'pointer',
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						padding: '3px 20px',
						height: '25px'
					}
				}
			},

			...(autocompletePosition && {
				'.cm-tooltip-autocomplete.cm-tooltip': {
					position: 'absolute !important',
					top: `${autocompletePosition.y}px !important`,
					left: `${autocompletePosition.x}px !important`,
					zIndex: '3 !important',
					'-webkit-transform': 'translate3d(0,0,0)'
				}
			}),

			'.cm-tooltip-autocomplete ul li[aria-selected]': {
				background: 'rgba(240, 240, 240, 1)',
				color: 'black'
			},
			'.cm-completionDetail': {
				textAlign: 'right',
				flex: 1,
				color: 'rgba(0, 0, 0, 0.5)',
				fontWeight: 400,
				marginLeft: '15px'
			},
			'.cm-completionIcon': {
				color: 'rgba(0, 0, 0, 0.4)',
				height: '15px',
				width: '15px',
				marginRight: '15px'
			},
			'.cm-completionIcon-attribute': {
				'&:after': { content: "'\\0023'", fontSize: '130%' }
			},
			'.cm-completionIcon-variable': {
				'&:after': { content: "'\\0023'", fontSize: '130%' }
			},
			'.cm-completionIcon-constant': {
				'&:after': { fontSize: '130%' }
			},
			'.cm-completionIcon-function': {
				'&:after': { fontSize: '130%' }
			},
			'.cm-completionMatchedText': {
				textDecoration: 'none',
				fontWeight: 600
			}
		},
		{ dark: false }
	)

	const onEditorBlur = useCallback((e, view) => {
		view.dispatch({
			effects: closeHelper.of(true)
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const getExtensions = useCallback(() => {
		const extensions = [
			// *** Language
			StreamLanguage.define(spreadsheet),
			// *** Default keymap, necessary for cursor movement to work properly with widgets
			keymap.of(defaultKeymap),
			// *** Activation of extensions / features
			bracketMatching(),
			closeBrackets(),
			autocompletion({ override: [myCompletions] }),
			// *** Custom plugin
			identifyVariables({ variables: [...variables, ...attributes], parent: cmRef.current, clickStopPropagation: !isFixed, isDisabled: isDisabled, isFixed: isFixed }),
			helper({ position: autocompletePosition }),
			// *** Only allow one single line
			EditorState.transactionFilter.of((tr) => {
				return tr.newDoc.lines > 1 ? [] : [tr]
			}),
			// *** Update listener
			EditorView.updateListener.of(updateListener),
			// *** Custom theme
			myTheme,
			// *** Read only mode
			EditorState.readOnly.of(isDisabled),
			EditorView.editable.of(!isDisabled),
			EditorView.domEventHandlers({ blur: onEditorBlur })
		]
		return extensions
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [variables, attributes, isDisabled])

	// Catch "Enter" & "Escape" events when autocomplete is open, as they must not be propagated to the grid
	const onKeyDown = useCallback(
		(e) => {
			if (!editor) return
			const status = completionStatus(editor.state)
			if (status !== 'active') return
			if (e.key === 'Enter' || e.key === 'Tab') {
				if (e.preventDefault) e.preventDefault()
				if (e.stopPropagation) e.stopPropagation()
				acceptCompletion(editor)
			} else if (e.key === 'Escape') {
				if (e.preventDefault) e.preventDefault()
				if (e.stopPropagation) e.stopPropagation()
				closeCompletion(editor)
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[editor]
	)

	useEffect(() => {
		if (currentFocus.is) {
			document.addEventListener('keydown', onKeyDown, true)
		}
		return () => {
			document.removeEventListener('keydown', onKeyDown, true)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentFocus])

	return <div id="cm-wrap-container" className={clsx('relative text-sm flex-1 w-full', !isFixed && 'px-[20px]')} ref={cmRef} />
})
