import clsx from 'clsx'
import { useFirstRender } from 'common/hooks/useFirstRender'
import { memo, useEffect, useLayoutEffect, useRef, useState } from 'react'

export default memo(function CreateTextCell({
	item = null,
	setItem,
	focus = false,
	cancel = false,
	typeProps = null,
	config: { alignRight = false, parseUserInput, formatBlurredInput, parsePastedValue, textWrap = false },
	className,
	style
}) {
	const inputRef = useRef()
	const firstRender = useFirstRender()

	const [currentFocus, setCurrentFocus] = useState({ is: false, cancel: false })
	const [prevFocus, setPrevFocus] = useState({ is: false, cancel: false })

	useLayoutEffect(() => {
		setPrevFocus(currentFocus)
		setCurrentFocus({ is: focus, cancel: cancel })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [focus])

	useLayoutEffect(() => {
		if (!inputRef.current) return
		if (currentFocus.is) {
			inputRef.current.focus()
			inputRef.current.select()
		} else if (!currentFocus.is && !firstRender && prevFocus.is) {
			if (!currentFocus.cancel) {
				const newValue = parseUserInput({ value: inputRef.current.value, typeProps })
				if (item !== newValue) setItem(newValue)
			}
			inputRef.current.blur()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentFocus])

	useEffect(() => {
		// On blur or when the data changes, format it for display
		if (!currentFocus.is && inputRef.current) inputRef.current.value = formatBlurredInput({ value: item, typeProps })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentFocus, item, typeProps])

	// ###########################
	// #### RENDER
	// ###########################

	return (
		<>
			{!textWrap ? (
				<input
					ref={inputRef}
					defaultValue={formatBlurredInput({ value: item, typeProps })} // We use an uncontrolled component for better performance
					className={clsx('text-sm block flex-1 border-0 min-w-0 self-stretch box-border bg-transparent outline-none w-full text-ellipsis overflow-hidden', alignRight && 'text-right', className)}
					tabIndex={-1} // Important to prevent any undesired "tabbing"
					style={{ pointerEvents: currentFocus.is ? 'auto' : 'none', ...(style || {}) }}
				/>
			) : (
				<span className={clsx('text-sm whitespace-normal break-normal', className)} style={style}>
					{typeof item !== 'object' ? formatBlurredInput({ value: item, typeProps }) : null}
				</span>
			)}
		</>
	)
})
