import { loadCalculationSocket } from 'model/saga-actions/modelEngineActions'
import { loadVisualizationSocket } from 'model/saga-actions/modelVizActions'
import { wsEngineDisconnect, wsVizDisconnect } from 'model/saga-actions/socketActions'
import { clearBreakdown, clearAllData } from 'model/store/modelDataReducer'
import * as modelSel from 'model/store/modelSelector'
import { useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { subscribeSelectedTable, unsubscribeSelectedTable } from 'table/saga-actions/tableActions'
import { useAssetPermits } from 'common/hooks/useAssetPermits'

export default function useModelDataLoader({ aid }) {
	const dispatch = useDispatch()
	const { canEdit } = useAssetPermits(aid)

	const tid = useSelector((state) => state.auth.tid)
	const teamId = useSelector((state) => state.asset.asset[aid]?.data?.teamId)
	const isDesigning = useSelector((state) => modelSel.selectModelIsDesigning(state, aid))
	const isVizReady = useSelector((state) => state.modelData.isVizReady[aid]) || false
	const isVariablesLoaded = useSelector((state) => modelSel.selectModelVarLoaded(state, aid))
	const isModelLoaded = useSelector((state) => modelSel.selectModelIsLoaded(state, aid))
	const selectModelCategoryTables = useMemo(() => modelSel.makeSelectModelCategoryTables({ aid }), [aid])
	const modelCategoryTables = useSelector((state) => selectModelCategoryTables(state))

	// Clean up data when leaving the model
	useEffect(() => {
		const lTid = tid
		const lTeamId = teamId
		const lAid = aid
		const lIsVariablesLoaded = isVariablesLoaded
		const lIsVizReady = isVizReady
		return () => {
			if (lTid && lTeamId && lAid && lIsVariablesLoaded && lIsVizReady) {
				dispatch(clearAllData({ aid: lAid }))
				dispatch(clearBreakdown({ id: lAid }))
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tid, teamId, aid, isVariablesLoaded, isVizReady])

	// Subscribe to model visualizations service
	useEffect(() => {
		const lTid = tid
		const lTeamId = teamId
		const lAid = aid
		if (lTid && lTeamId && lAid) dispatch(loadVisualizationSocket({ tid: lTid, teamId: lTeamId, aid: lAid }))
		return () => {
			if (lTid && lTeamId && lAid) dispatch(wsVizDisconnect({ aid: lAid }))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tid, teamId, aid])

	// Subscribe to calculation engine
	useEffect(() => {
		const lTid = tid
		const lAid = aid
		const lTeamId = teamId
		const lIsModelLoaded = isModelLoaded
		const lCanEdit = canEdit
		if (lTid && lAid && lTeamId && lIsModelLoaded && lCanEdit) dispatch(loadCalculationSocket({ tid: lTid, teamId: lTeamId, aid: lAid }))

		return () => {
			if (lTid && lAid && lTeamId && lIsModelLoaded && lCanEdit) dispatch(wsEngineDisconnect({ aid: lAid }))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tid, teamId, aid, isModelLoaded, canEdit])

	// Load categories
	useEffect(() => {
		const lIsDesigning = isDesigning
		const lTables = modelCategoryTables
		const subscribe = tid !== null && lIsDesigning && lTables?.length > 0
		if (subscribe) lTables.forEach((table) => dispatch(subscribeSelectedTable({ tid, aid: table })))
		return () => {
			if (subscribe) lTables.forEach((table) => dispatch(unsubscribeSelectedTable({ aid: table })))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tid, isDesigning, modelCategoryTables])
}
