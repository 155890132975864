import { MenuItem, TextField, FormControlLabel, Button, FormControl, FormLabel, RadioGroup, Radio, Divider } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import TimezoneSelect from 'react-timezone-select'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { FREQUENCY } from 'common/constants/frequencies'
import useLocale from 'common/hooks/useLocale'
import { fromTimestampToDate, getDateIntervals, getDatePeriodStart } from 'common/utils/dates'
import { DateTime } from 'luxon'
import { updateDates } from 'model/saga-actions/modelActions'
import { DATE_FORMATS } from 'common/constants/formats'
import ErrorIcon from '@mui/icons-material/ErrorOutlineOutlined'
import { cloneDeep } from 'lodash'

export default function ModelDatesCalculation({ aid, onClose }) {
	const dispatch = useDispatch()
	const { t } = useTranslation(['common', 'model'])

	// #### REDUX
	const tid = useSelector((state) => state.auth.tid)
	const teamId = useSelector((state) => state.asset.asset[aid]?.data?.teamId)
	const modelProps = useSelector((state) => state.model.model[aid]?.data?.modelProps)
	const asset = useSelector((state) => state.asset.asset[aid]?.data)

	const typeProps = modelProps?.typeProps
	const dateProps = typeProps?.dateFormat ? DATE_FORMATS[typeProps.dateFormat] : null
	const locale = useLocale(dateProps?.locale)

	// #### DEFAULT VALUES
	const defaultForm = {
		frequency: FREQUENCY.month.key,
		startDate: DateTime.now(),
		endDate: DateTime.now(),
		timezone: 'Etc/GMT',
		fixedTime: null,
		schedulerId: null
	}

	const defaultValidation = {
		frequency: { valid: true, error: '' },
		startDate: { valid: true, error: '' },
		endDate: { valid: true, error: '' },
		fixedTime: { valid: true, error: '' }
	}
	// #### STATE
	const [form, setForm] = useState(defaultForm)
	const [validation, setValidation] = useState(defaultValidation)
	const [clockTime, setClockTime] = useState('timezone')

	// #### EFFECTS
	useEffect(() => {
		if (modelProps) setForm(initialize())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [modelProps])

	function initialize() {
		const startDate = fromTimestampToDate(modelProps.startDate)
		const endDate = fromTimestampToDate(modelProps.endDate)
		const frequency = modelProps.frequency
		const timezone = modelProps?.advanced?.timezone || defaultForm.timezone
		const fixedTime = modelProps?.advanced !== undefined && modelProps?.advanced?.fixedTime !== null ? fromTimestampToDate(modelProps?.advanced?.fixedTime) : defaultForm.fixedTime
		if (modelProps?.advanced?.fixedTime && modelProps?.advanced?.fixedTime !== '') setClockTime('fixedTime')
		const schedulerId = modelProps?.advanced?.schedulerId || null
		return { startDate, endDate, frequency, timezone, fixedTime, schedulerId }
	}

	// #### FORM FUNCTIONS
	function onChange(key, value) {
		let newForm = { ...form, [key]: value }

		if (key === 'frequency') {
			newForm = { ...newForm, ...onAdjustDate('startDate', form.startDate, newForm) }
			newForm = { ...newForm, ...onAdjustDate('endDate', form.endDate, newForm) }
		}
		setForm(newForm)
	}

	function onAccept(key, date) {
		let newForm = { ...form, ...onAdjustDate(key, date, form) }
		setForm(newForm)
	}

	function onAdjustDate(key, date, form) {
		if (!date) return { [key]: null }
		const utcDate = DateTime.utc(date.year, date.month, date.day, 0, 0, 0, 0)
		var adjustedDate = getDatePeriodStart(utcDate, form.frequency, locale.locale).toUTC()

		let changes = { [key]: adjustedDate }
		return changes
	}

	function onChangeModelClockTime(value) {
		setClockTime(value)
		if (value === 'timezone') {
			var newForm = cloneDeep(form)
			newForm['fixedTime'] = null
			setForm(newForm)
		}
	}

	function onClockModelTime(value) {
		if (form['timezone'] === value.value) return
		var newForm = cloneDeep(form)
		newForm['timezone'] = value.value
		newForm['fixedTime'] = null
		setForm(newForm)
	}

	function onChangeFixedTime(key, date) {
		const utcDate = DateTime.utc(date.year, date.month, date.day, 0, 0, 0, 0)
		var adjustedDate = getDatePeriodStart(utcDate, 'day', locale.locale).toUTC()
		let newForm = { ...form, [key]: adjustedDate }
		newForm['timezone'] = null
		setForm(newForm)
	}

	function onSave() {
		const prev = initialize()
		// Identify if anything has changed
		if (
			prev.startDate?.toMillis() === form.startDate?.toMillis() &&
			prev.endDate?.toMillis() === form.endDate?.toMillis() &&
			prev.frequency === form.frequency &&
			prev.timezone === form.timezone &&
			prev.fixedTime === form.fixedTime
		) {
			onClose()
			return
		}

		// Validate data
		const newValidation = { ...validation }
		newValidation['startDate'] = form.startDate == null ? { valid: false, error: 'error.required' } : { valid: true, error: '' }
		newValidation['endDate'] = form.endDate == null ? { valid: false, error: 'error.required' } : { valid: true, error: '' }
		if (newValidation.startDate?.valid && newValidation.endDate?.valid)
			newValidation['startDate'] = form.startDate.toMillis() > form.endDate.toMillis() ? { valid: false, error: 'model:dates.errorStartGreaterThanEnd' } : { valid: true, error: '' }
		if (newValidation.startDate?.valid && newValidation.endDate?.valid) {
			// Check limits
			const dateIntervals = getDateIntervals(form.startDate, form.endDate, form.frequency)
			const numIntervals = dateIntervals?.length || 0
			newValidation['endDate'] = numIntervals > asset?.limits?.timesteps ? { valid: false, error: 'model:dates.errorTimestepsLimit' } : { valid: true, error: '' }
		}

		setValidation(newValidation)
		if (!newValidation.startDate.valid || !newValidation.endDate.valid) return

		// Save data
		const frequency = form.frequency
		const startDate = form.startDate.toJSDate()
		const endDate = form.endDate.toJSDate()
		const timezone = form.timezone
		const fixedTime = form.fixedTime !== null ? form.fixedTime.toJSDate() : null
		const schedulerId = form.schedulerId || null
		dispatch(updateDates({ tid, teamId, aid, frequency, startDate, endDate, timezone, fixedTime, schedulerId }))
		onClose()
	}

	return (
		<>
			<TextField
				select
				fullWidth
				variant="outlined"
				margin="none"
				size="small"
				value={form.frequency}
				onChange={(event) => onChange('frequency', event.target.value)}
				error={!validation.frequency.valid}
				helperText={!validation.frequency.valid && validation.frequency.error}
				inputProps={{ className: 'text-sm' }}
				InputLabelProps={{ className: 'text-sm' }}
				sx={{ placeholder: 'text-sm' }}
				label={t('model:dates.granularity')}
				className="mt-4"
			>
				{Object.keys(FREQUENCY).map((option) => (
					<MenuItem key={FREQUENCY[option].key} value={FREQUENCY[option].key} className="text-sm">
						{t(FREQUENCY[option].label)}
					</MenuItem>
				))}
			</TextField>

			{form.frequency !== modelProps.frequency && (
				<div className="flex flex-row items-center">
					<ErrorIcon className="text-[15px] mr-1 text-textGray" />
					<span className="text-xs">{t('model:dates.warningGranularity')}</span>
				</div>
			)}

			<LocalizationProvider dateAdapter={AdapterLuxon}>
				<DatePicker
					value={form.startDate}
					onChange={(value) => onChange('startDate', value)}
					inputFormat={locale.dateMask.mask}
					label={t('model:dates.dateFrom')}
					onAccept={(value) => onAccept('startDate', value)}
					renderInput={(params) => (
						<TextField
							{...params}
							margin="none"
							size="small"
							fullWidth
							inputProps={{ ...params.inputProps, placeholder: locale.dateMask.placeholder, className: 'text-sm' }}
							InputProps={{ ...params.InputProps, onBlur: () => onAccept('startDate', form.startDate) }}
							InputLabelProps={{ className: 'text-sm' }}
							sx={{ svg: { width: '18px', height: '18px' }, placeholder: 'text-sm' }}
							error={!validation.startDate.valid}
							helperText={!validation.startDate.valid && t(validation.startDate.error, { field: t('model:dates.dateFrom') })}
							className="mt-4"
						/>
					)}
				/>
				<DatePicker
					value={form.endDate}
					onChange={(value) => onChange('endDate', value)}
					inputFormat={locale.dateMask.mask}
					label={t('model:dates.dateTo')}
					onAccept={(value) => onAccept('endDate', value)}
					renderInput={(params) => (
						<TextField
							{...params}
							margin="none"
							size="small"
							fullWidth
							inputProps={{ ...params.inputProps, placeholder: locale.dateMask.placeholder, className: 'text-sm' }}
							InputProps={{ ...params.InputProps, onBlur: () => onAccept('endDate', form.endDate) }}
							InputLabelProps={{ className: 'text-sm' }}
							sx={{ svg: { width: '18px', height: '18px' }, placeholder: 'text-sm' }}
							error={!validation.endDate.valid}
							helperText={!validation.endDate.valid && t(validation.endDate.error, { field: t('model:dates.dateTo') })}
							className="mt-4"
						/>
					)}
				/>
			</LocalizationProvider>

			<Divider className="my-4" />

			<FormControl>
				<FormLabel className="font-medium text-sm text-textGray">{t('model:dates.modelTime')}</FormLabel>
				<RadioGroup value={clockTime} onChange={(e) => onChangeModelClockTime(e.target.value)}>
					<div>
						<FormControlLabel label={t('model:dates.timezone')} classes={{ root: '', label: 'ml-2 text-xs text-textGray' }} value="timezone" control={<Radio size="small" />} />
						<FormControlLabel label={t('model:dates.fixedTime')} classes={{ root: '', label: 'ml-2 text-xs text-textGray' }} value="fixedTime" control={<Radio size="small" />} />
					</div>
				</RadioGroup>
			</FormControl>
			{clockTime === 'timezone' && <TimezoneSelect value={form.timezone} onChange={(value) => onClockModelTime(value)} className="text-sm" />}
			{clockTime === 'fixedTime' && (
				<LocalizationProvider dateAdapter={AdapterLuxon}>
					<DatePicker
						value={form.fixedTime}
						onChange={(value) => onChangeFixedTime('fixedTime', value)}
						inputFormat={locale.dateMask.mask}
						renderInput={(params) => (
							<TextField
								{...params}
								margin="none"
								size="small"
								fullWidth
								inputProps={{ ...params.inputProps, placeholder: locale.dateMask.placeholder }}
								InputProps={{ ...params.InputProps, className: 'text-sm' }}
								InputLabelProps={{ className: 'text-sm' }}
								sx={{ svg: { width: '18px', height: '18px' }, placeholder: 'text-sm' }}
								error={!validation.fixedTime.valid}
								helperText={!validation.fixedTime.valid && t(validation.fixedTime.error, { field: t('model:dates.fixed') })}
							/>
						)}
					/>
				</LocalizationProvider>
			)}

			<Button onClick={onSave} size="small" className="mt-4 buttonContainedContrast">
				{t('common:buttons.save')}
			</Button>
		</>
	)
}
