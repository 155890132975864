import { select, put, takeEvery, spawn, call } from 'redux-saga/effects'
import * as api from 'common/api/viewApi'
import * as authApi from 'common/api/authApi'
import * as storeAction from 'common/store/viewReducer'
import * as sagaAction from 'common/saga-actions/viewActions'
import * as subscribeHelper from 'common/saga/helpers/subscribeHelper'
import * as crudHelper from 'common/saga/helpers/crudHelper'
import * as globalSagaAction from 'common/saga-actions/globalActions'
import * as viewSel from 'common/store/viewSelector'
import { throwError } from 'common/config/errors'

const FILE_NAME = 'viewSagas'
const getTid = (state) => state.auth.tid
const getUid = (state) => state.auth.uid

// #### PATH TO THE COLLECTION
function defaultPathPersonal(includeDocId) {
	const path = [
		{ collection: 'tenants', param: 'tid', value: null },
		{ collection: 'users', param: 'uid', value: null },
		{ collection: 'assets', param: 'aid', value: null },
		{ collection: 'views', param: includeDocId ? 'vid' : null, value: null }
	]
	return path
}

function defaultPathShared(includeDocId) {
	const path = [
		{ collection: 'tenants', param: 'tid', value: null },
		{ collection: 'assets', param: 'aid', value: null },
		{ collection: 'views', param: includeDocId ? 'vid' : null, value: null }
	]
	return path
}

// #### SUBSCRIBE TO VIEWS
function* loadPersonalViewsReq() {
	const queryConfig = subscribeHelper.queryConfig({ path: defaultPathPersonal(false), orderBy: [{ attribute: 'name', order: 'desc' }], returnType: 'map' })
	const sagaConfig = subscribeHelper.sagaConfig({
		loadAction: sagaAction.LOAD_PERSONAL_VIEWS,
		loadResponse: storeAction.loadPersonalViews,
		cancelAction: sagaAction.CANCEL_PERSONAL_VIEWS,
		cancelResponse: storeAction.cancelPersonalViews
	})
	yield spawn(subscribeHelper.subscribe, sagaConfig, queryConfig)
}

function* loadSharedViewsReq() {
	const queryConfig = subscribeHelper.queryConfig({ path: defaultPathShared(false), orderBy: [{ attribute: 'name', order: 'desc' }], returnType: 'map' })
	const sagaConfig = subscribeHelper.sagaConfig({
		loadAction: sagaAction.LOAD_SHARED_VIEWS,
		loadResponse: storeAction.loadSharedViews,
		cancelAction: sagaAction.CANCEL_SHARED_VIEWS,
		cancelResponse: storeAction.cancelSharedViews
	})
	yield spawn(subscribeHelper.subscribe, sagaConfig, queryConfig)
}

// #### UPDATE VIEWS
function* createPersonalViewReq() {
	const queryConfig = crudHelper.queryConfig({ path: defaultPathPersonal(true), returnType: 'doc' })
	const sagaConfig = crudHelper.sagaConfig({ loadAction: sagaAction.CREATE_PERSONAL_VIEW })
	const msgConfig = crudHelper.msgConfig({ fileName: FILE_NAME, functionName: createPersonalViewReq.name })
	yield spawn(crudHelper.create, sagaConfig, queryConfig, msgConfig)
}

function* updatePersonalViewReq() {
	const queryConfig = crudHelper.queryConfig({ path: defaultPathPersonal(true), returnType: 'doc' })
	const sagaConfig = crudHelper.sagaConfig({ loadAction: sagaAction.UPDATE_PERSONAL_VIEW })
	const msgConfig = crudHelper.msgConfig({ fileName: FILE_NAME, functionName: updatePersonalViewReq.name })
	yield spawn(crudHelper.update, sagaConfig, queryConfig, msgConfig)
}

function* deletePersonalViewReq() {
	const queryConfig = crudHelper.queryConfig({ path: defaultPathPersonal(true), returnType: 'doc' })
	const sagaConfig = crudHelper.sagaConfig({ loadAction: sagaAction.DELETE_PERSONAL_VIEW })
	const msgConfig = crudHelper.msgConfig({ fileName: FILE_NAME, functionName: deletePersonalViewReq.name })
	yield spawn(crudHelper.remove, sagaConfig, queryConfig, msgConfig)
}

function* createSharedViewReq() {
	const queryConfig = crudHelper.queryConfig({ path: defaultPathShared(true), returnType: 'doc' })
	const sagaConfig = crudHelper.sagaConfig({ loadAction: sagaAction.CREATE_SHARED_VIEW })
	const msgConfig = crudHelper.msgConfig({ fileName: FILE_NAME, functionName: createSharedViewReq.name })
	yield spawn(crudHelper.create, sagaConfig, queryConfig, msgConfig)
}

function* updateSharedViewReq() {
	const queryConfig = crudHelper.queryConfig({ path: defaultPathShared(true), returnType: 'doc' })
	const sagaConfig = crudHelper.sagaConfig({ loadAction: sagaAction.UPDATE_SHARED_VIEW })
	const msgConfig = crudHelper.msgConfig({ fileName: FILE_NAME, functionName: updateSharedViewReq.name })
	yield spawn(crudHelper.update, sagaConfig, queryConfig, msgConfig)
}

function* deleteSharedViewReq() {
	const queryConfig = crudHelper.queryConfig({ path: defaultPathShared(true), returnType: 'doc' })
	const sagaConfig = crudHelper.sagaConfig({ loadAction: sagaAction.DELETE_SHARED_VIEW })
	const msgConfig = crudHelper.msgConfig({ fileName: FILE_NAME, functionName: deleteSharedViewReq.name })
	yield spawn(crudHelper.remove, sagaConfig, queryConfig, msgConfig)
}

// SET CONTENT
function* setInViewReq() {
	yield takeEvery(sagaAction.SET_IN_VIEW, setInView)
}

function* setInView(triggeredAction) {
	try {
		const { aid, changes, permits } = triggeredAction
		const vid = yield select((state) => viewSel.selectViewId(state, aid))
		const type = yield select((state) => viewSel.selectViewType(state, aid))

		yield put(storeAction.setInView({ id: aid, changes }))

		const canSave = permits.canEdit //(type === 'personal' && permits.canEdit) || (type === 'shared' && permits.canCreate)
		if (vid && canSave) {
			const tid = yield select(getTid)
			const uid = yield select(getUid)
			let content = {}
			changes?.forEach((change) => (content = { ...content, [change.path]: change.value }))
			if (type === 'personal') yield put(sagaAction.updatePersonalView({ tid, uid, aid, vid, content }))
			if (type === 'shared') yield put(sagaAction.updateSharedView({ tid, aid, vid, content }))
		}
	} catch (err) {
		const content = throwError(err, FILE_NAME, setInView.name)
		yield put(globalSagaAction.showMessage({ content }))
	}
}

export default function* root() {
	// #### SUBSCRIBE TO VIEWS
	yield spawn(loadPersonalViewsReq)
	yield spawn(loadSharedViewsReq)
	// #### UPDATE VIEWS
	yield spawn(createPersonalViewReq)
	yield spawn(updatePersonalViewReq)
	yield spawn(deletePersonalViewReq)
	yield spawn(createSharedViewReq)
	yield spawn(updateSharedViewReq)
	yield spawn(deleteSharedViewReq)
	// SET CONTENT
	yield spawn(setInViewReq)
}
