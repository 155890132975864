import { FUNCTIONS } from 'common/constants/formulas'
import { MODEL_PARAMS } from 'model/constants/modelParameters'
import { EditorView, showPanel } from '@codemirror/view'
import { StateField, StateEffect } from '@codemirror/state'
import { renderHelpPanel } from 'common/components/formula/ExtensionHelperRender'

export const closeHelper = StateEffect.define()

export function helper({ position }) {
	const initialState = { isActive: false, activeElement: null, activeArgument: null }

	const helpPanelState = StateField.define({
		create: () => {
			return initialState
		},
		update(value, tr) {
			// If there is a close event, close the panel
			for (let e of tr.effects) if (e.is(closeHelper)) return initialState

			// Otherwise, identify if the panel must be open
			const selection = tr.selection || tr.startState.selection
			var cursor = selection?.main?.anchor
			var doc = tr.state?.doc
			const isFormula = doc.sliceString(0, 1) == '='
			if (!isFormula) return initialState
			return isHelperRequired(cursor, doc)
		},
		provide: (f) => showPanel.from(f, (on) => (on.isActive ? (view) => renderHelpPanel(view, on.activeElement, on.activeArgument) : null))
	})

	return [helpPanelState, [], helpTheme]
}

function isHelperRequired(cursor, doc) {
	var numArgument = 0
	var isInsideFunction = false
	var openingBracket = 0
	// Find opening bracket
	for (let i = cursor; i > 0; i--) {
		let char = doc.sliceString(i - 1, i)
		if (char == ')') break
		else if (char == '(') {
			isInsideFunction = true
			openingBracket = i
			break
		} else if (char == ';') numArgument++
	}
	if (!isInsideFunction) return { isActive: false, activeElement: null, activeArgument: null }

	// Find function name
	var functionNameStart = openingBracket - 1
	for (let i = openingBracket - 1; i > 0; i--) {
		let char = doc.sliceString(i - 1, i)
		if (!isAlpha(char)) break
		functionNameStart = i - 1
	}

	if (functionNameStart == openingBracket - 1) return { isActive: false, activeElement: null, activeArgument: null }

	// Check if function exists
	const functionName = doc.sliceString(functionNameStart, openingBracket - 1)
	const functionObj = FUNCTIONS.find((f) => f.label.toLowerCase() == functionName.toLowerCase())
	if (!functionObj) return { isActive: false, activeElement: null, activeArgument: null }

	return { isActive: true, activeElement: functionObj, activeArgument: numArgument }
}

function isAlpha(char) {
	const code = char.charCodeAt(0)
	if (
		!(code > 64 && code < 91) && // upper alpha (A-Z)
		!(code > 96 && code < 123) // lower alpha (a-z)
	)
		return false
	return true
}

const helpTheme = EditorView.baseTheme({
	'.cm-panels': {
		position: 'absolute !important',
		top: `${MODEL_PARAMS.DEFAULT_ROW_HEIGHT - 4}px !important`,
		left: `0px !important`,
		zIndex: '2 !important',
		fontSize: 12,
		fontFamily: 'Roboto',
		overflow: 'hidden auto',
		maxWidth_fallback: '700px',
		minHeight: '300px',
		width: '370px',
		border: 'none',
		background: 'white',
		borderRadius: '4px',
		boxShadow: '0 2px 6px 2px rgba(60, 64, 67, 0.15)'
	},
	'.cm-help-panel-row': {
		display: 'flex',
		flexDirection: 'row'
	},
	'.cm-help-panel-section': {
		display: 'flex',
		flexDirection: 'column',
		padding: '10px 20px'
	},
	'.cm-help-panel-separator': {
		borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
	},
	'.cm-help-panel-active-text': {
		fontWeight: 'bold'
	},
	'.cm-help-panel-highlight': {
		backgroundColor: 'rgba(240, 240, 240, 1)', // #e8f4ff
		borderRadius: '5px',
		padding: '0px 4px'
	},
	'.cm-help-panel-title-text': {
		color: 'rgba(0, 0, 0, 0.5)',
		fontWeight: 500,
		textTransform: 'uppercase',
		marginBottom: '5px'
	},
	'.cm-help-panel-list-item': {
		marginBottom: '5px'
	}
})
