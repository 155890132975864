import { IconButton, Tabs, Tab, Popover } from '@mui/material'
import { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import ChartSettingsVars from 'model/screens/charts/ChartSettingsVars'
import ChartSettingsGeneral from 'model/screens/charts/ChartSettingsGeneral'
import { t } from 'i18next'

export default function ChartSettings({ aid, chart, position, onClose }) {
	// #### STATE
	const [tab, setTab] = useState(0)

	// #### POPOVER CONTROL
	const isPopoverOpen = Boolean(position)

	return (
		<Popover
			open={isPopoverOpen}
			anchorReference="anchorPosition"
			anchorPosition={position ? { top: position.y, left: position.x } : null}
			onClose={onClose}
			anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
			classes={{ paper: 'flex w-[400px] flex shadow-md' }}
		>
			<div className="flex-1 flex flex-col">
				<div className="flex flex-row justify-between items-center border-b border-b-borderGray p-2 pl-4">
					<span className="text-md font-medium text-textGray">{t('model:chart.settings')}</span>
					<IconButton size="small" className="text-textGray" onClick={onClose}>
						<CloseIcon fontSize="small" />
					</IconButton>
				</div>

				<Tabs value={tab} variant="fullWidth" onChange={(e, value) => setTab(value)} orientation="horizontal" className="tabs shrink-0" classes={{ indicator: 'tabsIndicator' }}>
					<Tab label={t('model:chart.variables')} className="tab" classes={{ selected: 'tabSelected' }} />
					<Tab label={t('model:chart.general')} className="tab" classes={{ selected: 'tabSelected' }} />
				</Tabs>

				<div className="flex-1 p-4 flex flex-col">{tab === 0 ? <ChartSettingsVars aid={aid} chart={chart} /> : <ChartSettingsGeneral aid={aid} chart={chart} onClose={onClose} />}</div>
			</div>
		</Popover>
	)
}
