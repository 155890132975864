import React, { memo } from 'react'
import clsx from 'clsx'

export const GridShadow = memo(({ value }) => {
	const { columnRights, rowHeight, stickyCols, edges } = value

	const top = rowHeight
	const left = columnRights[stickyCols - 1] || 0

	return (
		<>
			<div
				className={clsx({
					'grid-scrollable-view': true,
					'grid-scrollable-view-t': !edges.top,
					'grid-scrollable-view-b': !edges.bottom
				})}
				style={{
					top: top,
					left: 0,
					height: `calc(100% - ${top}px)`,
					width: '100%'
				}}
			/>

			<div
				className={clsx({
					'grid-scrollable-view': true,
					'grid-scrollable-view-r': !edges.right,
					'grid-scrollable-view-l': !edges.left
				})}
				style={{
					top: 0,
					left: left,
					height: '100%',
					width: `calc(100% - ${left}px`
				}}
			/>
		</>
	)
})

GridShadow.displayName = 'GridShadow'
