import CloseIcon from '@mui/icons-material/Close'
import { Button, IconButton, TextField } from '@mui/material'
import { updateModel } from 'model/saga-actions/modelActions'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { TABLE_PARAMS } from 'table/constants/tableParameters'
import { updateTable } from 'table/saga-actions/tableActions'

export default function TableTypeList({ aid, varId, variable, isModelVersion = false }) {
	const dispatch = useDispatch()
	const { t } = useTranslation(['common', 'masterData'])
	const update = isModelVersion ? updateModel : updateTable

	// #### REDUX
	const tid = useSelector((state) => state.auth.tid)
	const itemList = variable?.typeProps?.itemList || []

	// #### STATE
	const [inputValue, setInputValue] = useState('')

	// #### FUNCTIONS
	function onAddItem() {
		if (!(inputValue?.length > 0)) return
		const newItem = inputValue.trim()
		if (itemList?.indexOf(newItem) >= 0) return
		const newItemList = [...itemList, newItem]
		dispatch(update({ tid, aid, content: { [`variables.${varId}.typeProps.itemList`]: newItemList } }))
		setInputValue('')
	}

	function onRemoveItem(index) {
		var newItemList = [...itemList]
		newItemList.splice(index, 1)
		dispatch(update({ tid, aid, content: { [`variables.${varId}.typeProps.itemList`]: newItemList } }))
	}

	function onKeyDown(e) {
		if (e.key === 'Enter') onAddItem()
	}

	return (
		<div className="mt-4">
			{itemList?.map((item, index) => (
				<div key={'varId#' + varId + '#list#' + index} className="flex flex-row items-center p-2 bg-bgGray mb-2 rounded">
					<span className="text-sm">{item}</span>
					<span className="flex-1 text-textGray text-right self-end">
						<IconButton className="mr-2" size="small" onClick={() => onRemoveItem(index)}>
							<CloseIcon style={{ fontSize: '15px' }} />
						</IconButton>
					</span>
				</div>
			))}
			{itemList?.length <= TABLE_PARAMS.LIST_MAX_LENGTH ? (
				<div className="flex flex-row items-center">
					<TextField
						variant="outlined"
						fullWidth
						size="small"
						placeholder={t('table:typeSettings.itemListNewItem')}
						autoFocus
						value={inputValue}
						onChange={(event) => setInputValue(event.target.value)}
						onKeyDown={onKeyDown}
						inputProps={{ className: 'text-sm' }}
					/>
					<Button variant="text" size="small" className="min-w-[40px] buttonText" onClick={onAddItem}>
						{t('common:add')}
					</Button>
				</div>
			) : (
				<div>{t('table:typeSettings.itemListMax')}</div>
			)}
		</div>
	)
}
