import { db } from 'common/config/firebase'
import { doc as dbDoc, collection as dbCollection, updateDoc, addDoc, setDoc, deleteDoc } from 'firebase/firestore'
import { throwAPIError } from 'common/config/errors'

export async function update({ path, content }, msgConfig) {
	// Document path
	const queryPath = path?.reduce((prev, el) => `${prev}/${el.collection}/${el.value}`, '')
	// Doc
	const doc = dbDoc(db, queryPath)
	// Execute
	return updateDoc(doc, content)
		.then(() => ({ success: true }))
		.catch((error) => {
			throwAPIError(error, null, msgConfig.fileName, msgConfig.functionName)
		})
}

export async function create({ path, content }, msgConfig) {
	// Document path
	const queryPath = path?.reduce((prev, el) => (el.value ? `${prev}/${el.collection}/${el.value}` : `${prev}/${el.collection}`), '')
	// Doc
	const col = dbCollection(db, queryPath)
	// Execute
	return addDoc(col, content)
		.then((docRef) => ({ id: docRef.id }))
		.catch((error) => {
			throwAPIError(error, null, msgConfig.fileName, msgConfig.functionName)
		})
}

export async function createWithId({ path, content }, msgConfig) {
	// Document path
	const queryPath = path?.reduce((prev, el) => `${prev}/${el.collection}/${el.value}`, '')
	// Doc
	const doc = dbDoc(db, queryPath)
	// Execute
	return setDoc(doc, content, { merge: true })
		.then(() => ({ id: content.id }))
		.catch((error) => {
			throwAPIError(error, null, msgConfig.fileName, msgConfig.functionName)
		})
}

export async function remove({ path, content }, msgConfig) {
	// Document path
	const queryPath = path?.reduce((prev, el) => `${prev}/${el.collection}/${el.value}`, '')
	// Doc
	const doc = dbDoc(db, queryPath)
	// Execute
	return deleteDoc(doc)
		.then(() => ({ success: true }))
		.catch((error) => {
			throwAPIError(error, null, msgConfig.fileName, msgConfig.functionName)
		})
}
