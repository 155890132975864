import { put, call, select, takeEvery, spawn } from 'redux-saga/effects'
import * as sagaAction from 'automation/saga-actions/connectorActions'
import * as api from 'automation/api/connectorApi'
import * as authApi from 'common/api/authApi'
import * as storeAction from 'automation/store/connectorReducer'
import * as subscribeHelper from 'common/saga/helpers/subscribeHelper'
import * as globalStoreAction from 'common/store/globalReducer'
import * as globalSagaAction from 'common/saga-actions/globalActions'
import { TABLE_NEW_VIEW, TABLE_PARAMS } from 'table/constants/tableParameters'
import i18n from 'common/config/i18n'
import { generateKeywords } from 'common/saga/helpers/utils'
import { PRODUCTS } from 'common/constants/products'
import { createAsset } from 'common/api/assetApi'
import { throwError } from 'common/config/errors'

const FILE_NAME = 'connectorSagas'

// access store from sagas
const getUid = (state) => state.auth.uid

// PATH TO THE COLLECTION
function defaultPath(includeDocId) {
	const path = [
		{ collection: 'tenants', param: 'tid', value: null },
		{ collection: 'teams', param: 'teamId', value: null },
		{ collection: 'connectors', param: includeDocId ? 'cid' : null, value: null }
	]
	return path
}

// #### SUBSCRIBE TO COLLECTION
function* loadConnectorsReq() {
	const queryConfig = subscribeHelper.queryConfig({ path: defaultPath(true), orderBy: [{ attribute: 'name', order: 'asc' }], returnType: 'map' })
	const sagaConfig = subscribeHelper.sagaConfig({
		loadAction: sagaAction.LOAD_CONNECTORS,
		loadResponse: storeAction.loadConnectors,
		cancelAction: sagaAction.CANCEL_CONNECTORS,
		cancelResponse: storeAction.cancelConnectors
	})
	yield spawn(subscribeHelper.subscribe, sagaConfig, queryConfig)
}

// #### MANAGE CONNECTIONS
function* enableConnectorReq() {
	yield takeEvery(sagaAction.ENABLE_CONNECTOR, enableConnector)
}

function* enableConnector(triggeredAction) {
	const { tid, teamId, connectorId } = triggeredAction
	try {
		yield put(storeAction.enablingConnector(true))
		const { token } = yield call(authApi.getAuthToken)
		yield call(api.enableConnector, token, tid, teamId, connectorId)
	} catch (err) {
		const content = throwError(err, FILE_NAME, enableConnector.name)
		yield put(globalSagaAction.showMessage({ content }))
		yield put(storeAction.enablingStatus('error'))
	} finally {
		yield put(storeAction.enablingConnector(false))
	}
}

function* disableConnectorReq() {
	yield takeEvery(sagaAction.DISABLE_CONNECTOR, disableConnector)
}

function* disableConnector(triggeredAction) {
	const { tid, teamId, connectorId } = triggeredAction
	try {
		yield put(storeAction.enablingConnector(true))
		const { token } = yield call(authApi.getAuthToken)
		yield call(api.disableConnector, token, tid, teamId, connectorId)
	} catch (err) {
		const content = throwError(err, FILE_NAME, disableConnector.name)
		yield put(globalSagaAction.showMessage({ content }))
		yield put(storeAction.enablingStatus('error'))
	} finally {
		yield put(storeAction.enablingConnector(false))
	}
}

function* createConnectorReq() {
	yield takeEvery(sagaAction.CREATE_CONNECTOR, createConnector)
}

function* createConnector(triggeredAction) {
	const { tid, teamId, sid, uid, connection } = triggeredAction
	try {
		const { token } = yield call(authApi.getAuthToken)
		yield put(storeAction.connectorCreating(true))
		
		const newConnection = { ...connection, uid: uid, destinationProps: {...connection.destinationProps} }
		newConnection['sourceProps']['scheduler'] = connection.sourceProps?.freq === 'manual' ? null : connection.sourceProps?.scheduler
		
		if (connection.destinationProps.selectTable === '') {
			const defaults = {
				variables: {},
				tabs: [{ id: TABLE_PARAMS.HEAD_TAB_ID, name: i18n.t('table:defaults.mainTab'), variables: [] }]
			}
			connection.sourceProps.schema.forEach((col) => {
				defaults['variables'][col['id']] = { ...col, tabId: TABLE_PARAMS.HEAD_TAB_ID }
				defaults.tabs[0].variables.push(col['id'])
			})
			const keywords = generateKeywords(connection.destinationProps.tableName)
			const newContent = { 
				type: PRODUCTS.table.key, 
				sid: sid,
				name: connection.destinationProps.tableName, 
				isConnected: true,
				keywords 
			}
			const { data } = yield call(createAsset, token, tid, uid, teamId, newContent, defaults, TABLE_NEW_VIEW)
			newConnection['destinationProps']['selectTable'] = data?.assetId
		}
		
		yield call(api.createConnector, token, tid, teamId, uid, newConnection)
		yield put(storeAction.connectorStatus('success'))
	} catch (err) {
		const content = throwError(err, FILE_NAME, createConnector.name)
		yield put(globalSagaAction.showMessage({ content }))
		yield put(storeAction.connectorStatus('error'))
	} finally {
		yield put(storeAction.connectorCreating(false))
	}
}

function* updateConnectorReq() {
	yield takeEvery(sagaAction.UPDATE_CONNECTOR, updateConnector)
}

function* updateConnector(triggeredAction) {
	const { tid, teamId, cid, uid, connection } = triggeredAction
	try {
		yield put(storeAction.connectorCreating(true))
		const newConnection = { sourceProps: connection.sourceProps, schema: connection.sourceProps.schema }
		newConnection['sourceProps']['scheduler'] = connection.sourceProps?.freq === 'manual' ? null : connection.sourceProps?.scheduler
		const { token } = yield call(authApi.getAuthToken)
		yield call(api.updateConnector, token, tid, teamId, cid, uid, newConnection)
		yield put(storeAction.connectorStatus('success'))
	} catch (err) {
		const content = throwError(err, FILE_NAME, updateConnector.name)
		yield put(globalSagaAction.showMessage({ content }))
		yield put(storeAction.connectorStatus('error'))
	} finally {
		yield put(storeAction.connectorCreating(false))
	}
}

function* deleteConnectorReq() {
	yield takeEvery(sagaAction.DELETE_CONNECTOR, deleteConnector)
}

function* deleteConnector(triggeredAction) {
	const { tid, teamId, cid } = triggeredAction
	try {
		yield put(globalStoreAction.loadingOn({ key: 'deleteConnector', item: cid }))
		const uid = yield select(getUid)
		const { token } = yield call(authApi.getAuthToken)
		yield call(api.remove, token, tid, teamId, uid, cid)
	} catch (err) {
		const content = throwError(err, FILE_NAME, deleteConnector.name)
		yield put(globalSagaAction.showMessage({ content }))
	} finally {
		yield put(globalStoreAction.loadingOff({ key: 'deleteConnector', item: cid }))
	}
}

function* loadJobsReq() {
	yield takeEvery(sagaAction.JOBS, loadJobs)
}

function* loadJobs(triggeredAction) {
	const { teamId, cid } = triggeredAction
	try {
		yield put(globalStoreAction.loadingOn({ key: 'loadJobs' }))
		const { token } = yield call(authApi.getAuthToken)
		const { data } = yield call(api.jobs, token, teamId, cid)
		yield put(storeAction.loadJobs(data?.jobs))
	} catch (err) {
		const content = throwError(err, FILE_NAME, loadJobs.name)
		yield put(globalSagaAction.showMessage({ content }))
	} finally {
		yield put(globalStoreAction.loadingOff({ key: 'loadJobs' }))
	}
}

function* syncReq() {
	yield takeEvery(sagaAction.SYNC, sync)
}

function* sync(triggeredAction) {
	const { teamId, cid } = triggeredAction
	try {
		yield put(globalStoreAction.loadingOn({ key: 'syncSource' }))
		const { token } = yield call(authApi.getAuthToken)
		yield call(api.sync, token, teamId, cid)
	} catch (err) {
		const content = throwError(err, FILE_NAME, sync.name)
		yield put(globalSagaAction.showMessage({ content }))
	} finally {
		yield put(globalStoreAction.loadingOff({ key: 'syncSource' }))
	}
}

function* refreshSchemaReq() {
	yield takeEvery(sagaAction.REFRESH_SCHEMA, refreshSchema)
}

function* refreshSchema(triggeredAction) {
	const { tid, teamId, cid, stream } = triggeredAction
	try {
		yield put(globalStoreAction.loadingOn({ key: 'refreshSchema' }))
		const { token } = yield call(authApi.getAuthToken)
		yield call(api.refreshSchema, token, tid, teamId, cid, stream)
	} catch (err) {
		const content = throwError(err, FILE_NAME, sync.name)
		yield put(globalSagaAction.showMessage({ content }))
	} finally {
		yield put(globalStoreAction.loadingOff({ key: 'refreshSchema' }))
	}
}

export default function* root() {
	// #### SUBSCRIBE TO COLLECTION
	yield spawn(loadConnectorsReq)

	// #### MANAGE DATASETS
	yield spawn(enableConnectorReq)
	yield spawn(disableConnectorReq)
	yield spawn(createConnectorReq)
	yield spawn(updateConnectorReq)
	yield spawn(deleteConnectorReq)
	yield spawn(loadJobsReq)
	yield spawn(syncReq)
	yield spawn(refreshSchemaReq)
}
