import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Layout from 'common/screens/layout/Layout'
import TeamMenu from 'common/screens/team/TeamMenu'
import Loading from 'common/components/loading/Loading'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import GenericBar from 'common/screens/layout/GenericBar'

export default function Initial() {
	const { t } = useTranslation('common')
	const navigate = useNavigate()
	let { teamId, sid } = useParams()

	const teamsLoaded = useSelector((state) => state.team.teams?.loaded)
	const teams = useSelector((state) => state.team.teams?.result)
	const storeTeamId = useSelector((state) => state.team.teamId)
	const spacesLoaded = useSelector((state) => state.space.spaces?.loaded)
	const spaces = useSelector((state) => state.space.spaces?.result)

	useEffect(() => {
		if (teamsLoaded && teams && !teamId) {
			Object.entries(teams)?.every((team) => {
				if (!team[1].isInvite) {
					navigate('/team/' + team[0], { replace: true })
					return false
				} else return true
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [teamsLoaded, teams])

	useEffect(() => {
		if (teamsLoaded && spacesLoaded && spaces && teamId && teamId === storeTeamId && !sid) {
			Object.entries(spaces)?.every((space) => {
				navigate(`/team/${teamId}/space/${space[0]}`, { replace: true })
				return false
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [spacesLoaded, spaces])

	if (!teamsLoaded || (teamId && !spacesLoaded)) return <Loading />
	return (
		<Layout menuContent={TeamMenu} barContent={GenericBar}>
			<div className="flex flex-col items-center pt-8 font-medium text-lg">{teamId ? t('common:space.noSpace') : t('common:team.noTeam')}</div>
		</Layout>
	)
}
