// #### ENGINE
export const RECOMPUTE = 'modelEngineSaga/RECOMPUTE'
// ### SOCKET MANAGEMENT
export const LOAD_ENGINE_SOCKET = 'modelEngineSaga/LOAD_ENGINE_SOCKET'

// ### ENGINE
export const recompute = ({ tid, teamId, aid }) => {
	return { type: RECOMPUTE, tid, teamId, aid }
}

// ### SOCKET MANAGEMENT
export const loadCalculationSocket = ({ tid, teamId, aid }) => {
	return { type: LOAD_ENGINE_SOCKET, tid, teamId, aid }
}