import { select, put, takeEvery, all, spawn } from 'redux-saga/effects'
import * as sagaAction from 'model/saga-actions/chartActions'
import * as storeAction from 'model/store/chartReducer'
import * as modelSagaAction from 'model/saga-actions/modelActions'
import * as crudHelper from 'common/saga/helpers/crudHelper'
import * as subscribeHelper from 'common/saga/helpers/subscribeHelper'
import * as globalSagaAction from 'common/saga-actions/globalActions'
import { generateKey } from 'common/utils/uuid'
import { MODEL_NEW_CHART, MODEL_NEW_CHART_VAR } from 'model/constants/modelParameters'
import { cloneDeep } from 'lodash'
import { throwError } from 'common/config/errors'

const FILE_NAME = 'chartSagas'

// access store from sagas
const getModels = (state) => state.model.model
const getTabIndex = (state) => state.model.tabIndex
const getVariables = (state) => state.model.variables
const getCharts = (state) => state.chart.charts

function defaultPath(includeDocId) {
	const path = [
		{ collection: 'tenants', param: 'tid', value: null },
		{ collection: 'models', param: 'aid', value: null },
		{ collection: 'charts', param: includeDocId ? 'cid' : null, value: null }
	]
	return path
}

// #### GENERAL CHART CRUD
function* loadChartsReq() {
	const queryConfig = subscribeHelper.queryConfig({ path: defaultPath(false), returnType: 'map' })
	const sagaConfig = subscribeHelper.sagaConfig({
		loadAction: sagaAction.LOAD_CHARTS,
		loadResponse: storeAction.loadCharts,
		cancelAction: sagaAction.CANCEL_CHARTS,
		cancelResponse: storeAction.cancelCharts
	})
	yield spawn(subscribeHelper.subscribe, sagaConfig, queryConfig)
}

function* createChartHelperReq() {
	const queryConfig = crudHelper.queryConfig({ path: defaultPath(true), returnType: 'doc' })
	const sagaConfig = crudHelper.sagaConfig({ loadAction: sagaAction.CREATE_CHART_HELPER })
	const msgConfig = crudHelper.msgConfig({ fileName: FILE_NAME, functionName: createChartHelperReq.name })
	yield spawn(crudHelper.create, sagaConfig, queryConfig, msgConfig)
}

function* updateChartReq() {
	const queryConfig = crudHelper.queryConfig({ path: defaultPath(true), returnType: 'doc' })
	const sagaConfig = crudHelper.sagaConfig({ loadAction: sagaAction.UPDATE_CHART })
	const msgConfig = crudHelper.msgConfig({ fileName: FILE_NAME, functionName: updateChartReq.name })
	yield spawn(crudHelper.update, sagaConfig, queryConfig, msgConfig)
}

function* deleteChartHelperReq() {
	const queryConfig = crudHelper.queryConfig({ path: defaultPath(true), returnType: 'doc' })
	const sagaConfig = crudHelper.sagaConfig({ loadAction: sagaAction.DELETE_CHART_HELPER })
	const msgConfig = crudHelper.msgConfig({ fileName: FILE_NAME, functionName: deleteChartHelperReq.name })
	yield spawn(crudHelper.remove, sagaConfig, queryConfig, msgConfig)
}

// #### MANAGE CHARTS
function* createChartReq() {
	yield takeEvery(sagaAction.CREATE_CHART, createChart)
}

function* createChart(triggeredAction) {
	try {
		const { tid, teamId, aid, varId, chartType } = triggeredAction
		const models = yield select(getModels)
		const model = models[aid].data
		const tabs = model.tabs
		const _tabIndex = yield select(getTabIndex)
		const tabIndex = _tabIndex[aid]
		const tab = tabs[tabIndex]
		const _variables = yield select(getVariables)
		const variables = _variables && _variables[aid]?.result
		const variable = variables && variables[varId]

		// Prep chart data
		const cid = generateKey(6)
		const chartVariable = { ...MODEL_NEW_CHART_VAR, id: varId, type: chartType, categories: variable.categories || [], categoryAggr: variable.varProps?.categoryAggr }
		const content = { ...MODEL_NEW_CHART, id: cid, tabId: tab.id, type: chartType, variables: [chartVariable] }

		// Add chart id to tab
		const newTab = { ...tab, charts: tab.charts ? [...tab.charts, cid] : [cid] }
		var newTabs = [...tabs]
		newTabs[tabIndex] = newTab

		// Update tabs
		yield put(modelSagaAction.updateModel({ tid, aid, content: { tabs: newTabs } }))
		// Create chart
		yield put(sagaAction.createChartHelper({ tid, aid, cid, content }))
	} catch (err) {
		const content = throwError(err, FILE_NAME, createChart.name)
		yield put(globalSagaAction.showMessage({ content }))
	}
}

function* deleteChartReq() {
	yield takeEvery(sagaAction.DELETE_CHART, deleteChart)
}

function* deleteChart(triggeredAction) {
	try {
		const { tid, aid, cid } = triggeredAction
		const models = yield select(getModels)
		const model = models[aid].data
		const tabs = model.tabs
		const _tabIndex = yield select(getTabIndex)
		const tabIndex = _tabIndex[aid]
		const tab = tabs[tabIndex]
		// Delete chart from tab
		const newTab = { ...tab, charts: tab.charts.filter((el) => el !== cid) }
		var newTabs = [...tabs]
		newTabs[tabIndex] = newTab
		yield put(modelSagaAction.updateModel({ tid, aid, content: { tabs: newTabs } }))
		// Delete chart
		yield put(sagaAction.deleteChartHelper({ tid, aid, cid }))
	} catch (err) {
		const content = throwError(err, FILE_NAME, deleteChart.name)
		yield put(globalSagaAction.showMessage({ content }))
	}
}

function* moveChartReq() {
	yield takeEvery(sagaAction.MOVE_CHART, moveChart)
}

function* moveChart(triggeredAction) {
	try {
		const { tid, aid, tabIndex, charts } = triggeredAction
		const models = yield select(getModels)
		const model = models[aid].data
		const tabs = model.tabs
		const tab = tabs[tabIndex]

		// Update chart order in tab
		const newTab = { ...tab, charts }
		var newTabs = [...tabs]
		newTabs[tabIndex] = newTab
		yield put(modelSagaAction.updateModel({ tid, aid, content: { tabs: newTabs } }))
	} catch (err) {
		const content = throwError(err, FILE_NAME, moveChart.name)
		yield put(globalSagaAction.showMessage({ content }))
	}
}

function* updateVariableReq() {
	yield takeEvery(sagaAction.UPDATE_VARIABLE, updateVariable)
}

function* updateVariable(triggeredAction) {
	try {
		const { tid, aid, vid, content } = triggeredAction
		const _charts = yield select(getCharts)
		const charts = _charts[aid]?.result
		if (!charts) return

		let chartsToUpdate = []
		Object.values(charts)?.forEach((chart) => {
			let updateChart = false
			let chartVariables = cloneDeep(chart.variables || [])
			chartVariables?.forEach((charVar, index) => {
				if (charVar.id === vid) {
					chartVariables[index] = { ...chartVariables[index], ...content }
					updateChart = true
				}
			})
			if (updateChart) chartsToUpdate.push({ cid: chart.id, variables: chartVariables })
		})
		yield all(chartsToUpdate.map(({ cid, variables }) => put(sagaAction.updateChart({ tid, aid, cid, content: { variables } }))))
	} catch (err) {
		const content = throwError(err, FILE_NAME, updateVariable.name)
		yield put(globalSagaAction.showMessage({ content }))
	}
}

export default function* root() {
	// #### GENERAL CHART CRUD
	yield spawn(loadChartsReq)
	yield spawn(createChartHelperReq)
	yield spawn(updateChartReq)
	yield spawn(deleteChartHelperReq)
	// #### MANAGE CHARTS
	yield spawn(createChartReq)
	yield spawn(deleteChartReq)
	yield spawn(moveChartReq)
	yield spawn(updateVariableReq)
}
