export const DATE_FORMATS = {
	local: { key: 'local', i18n: 'common:dateFormat.local', locale: undefined },
	us: { key: 'us', i18n: 'common:dateFormat.us', locale: 'en-US' },
	eu: { key: 'eu', i18n: 'common:dateFormat.eu', locale: 'en-GB' }
}

export const TIME_FORMATS = {
	h12: { key: 'h12', i18n: 'common:timeFormat.h12', mask: 'hh:mm a', placeholder: 'hh:mm am', hasAmpm: true, hasSeconds: false },
	h24: { key: 'h24', i18n: 'common:timeFormat.h24', mask: 'HH:mm', placeholder: 'hh:mm', hasAmpm: false, hasSeconds: false },
	ss: { key: 'ss', i18n: 'common:timeFormat.ss', mask: 'HH:mm:ss', placeholder: 'hh:mm:ss', hasAmpm: false, hasSeconds: true }
}

export const NUMBER_FORMATS = {
	local: { key: 'local', i18n: 'common:numberFormat.local', locale: undefined, options: { useGrouping: true } },
	decimalPoint: { key: 'decimalPoint', i18n: 'common:numberFormat.decimalPoint', locale: 'en-US', options: { useGrouping: true } },
	decimalComma: { key: 'decimalComma', i18n: 'common:numberFormat.decimalComma', locale: 'es-ES', options: { useGrouping: true } }
}
