import { TEAM_STATUS } from 'common/constants/limits'
import { t } from 'i18next'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import ErrorIcon from '@mui/icons-material/ErrorOutlineOutlined'

export function useBanner() {
	const teamStatus = useSelector((state) => state.team.team?.status)

	const teamPermits = useMemo(() => {
		if (teamStatus === TEAM_STATUS.warning.key || teamStatus === TEAM_STATUS.blocked.key) {
			const message = t(TEAM_STATUS[teamStatus].label)
			return { isVisible: true, icon: ErrorIcon, message, color: 'red-200' }
		}
		return { isVisible: false, icon: null, message: null, color: null }
	}, [teamStatus])

	return teamPermits
}
