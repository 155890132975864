import i18n from 'common/config/i18n'

export const BACKGROUND_COLORS = [
	{ key: 'white', name: i18n.t('common:colors.white'), code: '#ffffff' },
	{ key: 'blue', name: i18n.t('common:colors.blue'), code: '#eff6ff' },
	{ key: 'red', name: i18n.t('common:colors.red'), code: '#fef2f2' },
	{ key: 'orange', name: i18n.t('common:colors.orange'), code: '#fff7ed' },
	{ key: 'yellow', name: i18n.t('common:colors.yellow'), code: '#fffbeb' },
	{ key: 'green', name: i18n.t('common:colors.green'), code: '#f0fdf4' },
	{ key: 'purple', name: i18n.t('common:colors.purple'), code: '#faf5ff' },
	{ key: 'pink', name: i18n.t('common:colors.pink'), code: '#fdf2f8' },
	{ key: 'gray', name: i18n.t('common:colors.gray'), code: '#f9fafb' }
]

export const FONT_COLORS = [
	{ key: 'black', name: i18n.t('common:colors.black'), code: '#000000' },
	{ key: 'blue', name: i18n.t('common:colors.blue'), code: '#1d4ed8' },
	{ key: 'red', name: i18n.t('common:colors.red'), code: '#b91c1c' },
	{ key: 'orange', name: i18n.t('common:colors.orange'), code: '#c2410c' },
	{ key: 'yellow', name: i18n.t('common:colors.yellow'), code: '#a16207' },
	{ key: 'green', name: i18n.t('common:colors.green'), code: '#15803d' },
	{ key: 'purple', name: i18n.t('common:colors.purple'), code: '#7e22ce' },
	{ key: 'pink', name: i18n.t('common:colors.pink'), code: '#be185d' },
	{ key: 'gray', name: i18n.t('common:colors.gray'), code: '#6b7280' }
]

export const FONT_WEIGHTS = [
	{ key: 'light', name: i18n.t('common:weights.light'), code: '200' },
	{ key: 'normal', name: i18n.t('common:weights.normal'), code: '400' },
	{ key: 'bold', name: i18n.t('common:weights.bold'), code: '500' },	
	{ key: 'bolder', name: i18n.t('common:weights.bolder'), code: '800' },		
]