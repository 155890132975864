import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Menu, MenuItem, Button } from '@mui/material'
import { logout } from 'common/saga-actions/authActions'
import AccountModal from 'common/screens/account/AccountModal'
import BarButton from 'common/screens/layout/BarButton'
import { UserIcon } from 'common/icons/light/index'
import { useNavigate } from 'react-router-dom'

export default function YourAccountButton() {
	const dispatch = useDispatch()
	const { t } = useTranslation('common')
	const navigate = useNavigate()

	const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)

	const [menuAnchor, setMenuAnchor] = useState(null)
	const [accountOpen, setAccountOpen] = useState(false)

	function onLogout() {
		dispatch(logout())
		setMenuAnchor(null)
	}

	function onOpenAccount() {
		setMenuAnchor(null)
		setAccountOpen(true)
	}

	if (isLoggedIn)
		return (
			<>
				<BarButton icon={UserIcon} label={t('common:buttons.account')} onClick={(e) => setMenuAnchor(e.currentTarget)} />
				<Menu anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={() => setMenuAnchor(null)}>
					<MenuItem dense onClick={onOpenAccount}>
						{t('account.title')}
					</MenuItem>
					<MenuItem dense onClick={onLogout}>
						{t('login.logout')}
					</MenuItem>
				</Menu>
				<AccountModal isOpen={accountOpen} onClose={() => setAccountOpen(false)} />
			</>
		)
	else
		return (
			<Button variant="contained" size="small" className="mx-4 buttonContainedContrast" onClick={() => navigate('/')}>
				{t('common:login.title')}
			</Button>
		)
}
