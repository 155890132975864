import { createSlice } from '@reduxjs/toolkit'
import { TABLE_PARAMS } from 'table/constants/tableParameters'

export const tableSlice = createSlice({
	name: 'table',
	initialState: {
		// Data from firestore
		table: {},
		// Local data
		columns: {},
		tabColumns: {},
		tabIndex: {},
		isDesigning: {},
		// Table drop-down selection
		selected: {},
		subscribed: {}
	},
	reducers: {
		loadTable: (state, action) => {
			// Update in every load
			state.table[action.payload.id] = { loaded: true, ...action.payload }
			state.columns[action.payload.id] = calculateColumns({ table: action.payload.data })
			state.tabColumns[action.payload.id] = calculateTabColumns({ tabIndex: state.tabIndex[action.payload.id] || 0, table: action.payload.data })
			state.tabIndex[action.payload.id] = state.tabIndex[action.payload.id] || 0
		},
		cancelTable: (state, action) => {
			state.table[action.payload.id] = null
			state.isDesigning[action.payload.id] = null
		},
		selectTab: (state, action) => {
			state.tabColumns[action.payload.id] = calculateTabColumns({ tabIndex: action.payload.tabIndex, table: state.table[action.payload.id]?.data })
			state.tabIndex[action.payload.id] = action.payload.tabIndex
		},
		loadSelectedTable: (state, action) => {
			state.selected[action.payload.key] = { loaded: true, ...action.payload }
		},
		cancelSelectedTable: (state, action) => {
			state.selected[action.payload.key] = null
		},
		subscribeSelectedTable: (state, action) => {
			state.subscribed[action.payload.id] = { loaded: true, ...action.payload }
		},
		unsubscribeSelectedTable: (state, action) => {
			state.subscribed[action.payload.id] = null
		},
		toggleDesignMode: (state, action) => {
			state.isDesigning[action.payload.id] = state.isDesigning[action.payload.id] ? false : true
		}
	}
})

export const { loadTable, cancelTable, selectTab, loadSelectedTable, cancelSelectedTable, subscribeSelectedTable, unsubscribeSelectedTable, toggleDesignMode } = tableSlice.actions

export default tableSlice.reducer

function calculateColumns({ table }) {
	if (!table.variables) return []
	const tableVars = Object.values(table.variables)
	// // Add a column to create new columns
	const newColumn = [{ id: TABLE_PARAMS.NEW_COL_ID }]
	const columns = [...tableVars, ...newColumn]
	return columns
}

function calculateTabColumns({ tabIndex, table }) {
	if (!table.tabs) return []
	const tab = table.tabs[tabIndex]
	const tableVars = table.variables
	const variables = tab?.variables?.map((varId) => tableVars[varId]) || []
	// Always include the first column
	const firstColumn = tabIndex > 0 && table.tabs[0].variables ? [tableVars[table.tabs[0].variables[0]]] : []
	// Add a column to create new columns
	const newColumn = [{ id: TABLE_PARAMS.NEW_COL_ID }]
	const columns = [...firstColumn, ...variables, ...newColumn]
	return columns
}
