export function searchResults(state, payload) {
	return {
		isLoading: payload.isLoading,
		loaded: state?.loaded || !payload.isLoading,
		startAfter: payload.isLoading ? state?.startAfter : payload.startAfter,
		notFound: payload.isLoading ? state?.notFound : payload.notFound,
		nextPage: payload.isLoading ? state?.nextPage : payload.nextPage,
		result: payload.isLoading ? state?.result : payload.isInitial ? payload.result : [...state.result, ...payload.result]
	}
}

export function mergeChanges(state, payload, addNewItems = false) {
	if (!(state?.result?.length > 0) || payload.result == null || !(Object.keys(payload.result)?.length > 0)) return state

	let merge = [...state.result]
	Object.entries(payload.result).map((change) => {
		const index = merge.findIndex((el) => el.id === change[0])
		if (index >= 0) merge[index] = { ...merge[index], data: change[1] }
		else if (addNewItems) merge.splice(0, 0, { id: change[0], data: change[1] })
		return void 0
	})

	return { ...state, result: merge }
}
