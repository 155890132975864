import { createSlice } from '@reduxjs/toolkit'

export const communitySlice = createSlice({
	name: 'community',
	initialState: {
		// Community assets
		assets: null,
		// Asset for making a copy
		asset: null,
		// Control saving progress
		isSaving: { items: 0, isError: false }
	},
	reducers: {
		loadCommunityAssets: (state, action) => {
			state.assets = { loaded: true, ...action.payload }
		},
		cancelCommunityAssets: (state, action) => {
			state.assets = null
		},
		loadCommunityAsset: (state, action) => {
			state.asset = { loaded: true, ...action.payload }
		},
		cancelCommunityAsset: (state, action) => {
			state.asset = null
		},
		initIsSaving: (state, action) => {
			state.isSaving = { items: action.payload.items, isError: false }
		},
		reduceIsSaving: (state, action) => {
			state.isSaving.items = Math.max(state.isSaving.items - 1, 0)
		},
		cancelIsSaving: (state, action) => {
			state.isSaving = { items: 0, isError: true }
		}
	}
})

export const { loadCommunityAssets, cancelCommunityAssets, loadCommunityAsset, cancelCommunityAsset, initIsSaving, reduceIsSaving, cancelIsSaving } = communitySlice.actions

export default communitySlice.reducer
