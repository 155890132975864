import { createSlice } from '@reduxjs/toolkit'
import { searchResults } from 'common/store/helpers/searchHelper'

export const assetSlice = createSlice({
	name: 'asset',
	initialState: {
		// Team assets
		assets: null,
		// Asset visualization
		aid: null, // identifier of the main-window asset
		asset: {},
		assetUsers: {},
		// Asset search & drop-down selection
		search: {},
		selected: {}
	},
	reducers: {
		loadAssets: (state, action) => {
			state.assets = { loaded: true, ...action.payload }
		},
		cancelAssets: (state, action) => {
			state.assets = null
		},
		setAid: (state, action) => {
			state.aid = action.payload
		},
		cleanAid: (state, action) => {
			state.aid = null
		},
		loadAsset: (state, action) => {
			state.asset[action.payload.id] = { loaded: true, ...action.payload }
		},
		cancelAsset: (state, action) => {
			state.asset[action.payload.id] = null
		},
		loadAssetUsers: (state, action) => {
			state.assetUsers[action.payload.id] = { loaded: true, ...action.payload }
		},
		cancelAssetUsers: (state, action) => {
			state.assetUsers[action.payload.id] = null
		},
		searchAssets: (state, action) => {
			state.search[action.payload.key] = searchResults(state.search[action.payload.key], action.payload)
		},
		cancelSearchAssets: (state, action) => {
			state.search[action.payload.key] = null
		},
		loadSelectedAsset: (state, action) => {
			state.selected[action.payload.key] = { loaded: true, ...action.payload }
		},
		cancelSelectedAsset: (state, action) => {
			state.selected[action.payload.key] = null
		}
	}
})

export const {
	loadAssets,
	cancelAssets,
	setAid,
	cleanAid,
	loadAsset,
	cancelAsset,
	loadAssetUsers,
	cancelAssetUsers,
	searchAssets,
	cancelSearchAssets,
	loadSelectedAsset,
	cancelSelectedAsset
} = assetSlice.actions

export default assetSlice.reducer
