import React from 'react'
import { LinearProgress } from '@mui/material'
import { LogoIcon } from 'common/icons/index'

export default function LoadingBody({ message }) {
	return (
		<div className="h-full w-full flex flex-col items-center justify-center text-barBg bg-bgGray">
			<LogoIcon className="w-[60px] mb-8" />
			<LinearProgress className="w-[200px]" color="inherit" />
			{message && <div className="mt-8 text-lg font-light text-textGray">{message}</div>}
		</div>
	)
}
