export default function WindowSplitRightIcon({ ...props }) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill="currentColor" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10 0C4.47715 0 0 4.47715 0 10V40C0 45.5228 4.47715 50 10 50H40C45.5228 50 50 45.5228 50 40V10C50 4.47715 45.5228 0 40 0H10ZM10 5C7.23858 5 5 7.23858 5 10V40C5 42.7614 7.23858 45 10 45H40C42.7614 45 45 42.7614 45 40V10C45 7.23858 42.7614 5 40 5H10Z"
			/>
			<path fillRule="evenodd" clipRule="evenodd" d="M37 10H25V40H37C38.6569 40 40 38.6569 40 37V13C40 11.3431 38.6569 10 37 10Z" />
		</svg>
	)
}
