import { createSlice } from '@reduxjs/toolkit'

export const sourceSlice = createSlice({
	name: 'source',
	initialState: {
		loading: {},
		status: null,
		source: null,
		sources: null,
		discover: null
	},
	reducers: {
		sourceStatus: (state, action) => {
			state.status = action.payload
		},
		loadSource: (state, action) => {
			state.source = { ...action.payload }
		},
		cancelSource: (state, action) => {
			state.source = null
		},
		loadDiscoverSource: (state, action) => {
			state.discover = { ...action.payload }
		},
		cancelDiscoverSource: (state, action) => {
			state.discover = null
		},
		loadSources: (state, action) => {
			state.sources = { loaded: true, ...action.payload }
		},
		cancelSources: (state, action) => {
			state.sources = null
		},

	}
})

export const { loadSource, cancelSource, sourceStatus, loadDiscoverSource, cancelDiscoverSource, loadSources, cancelSources } = sourceSlice.actions

export default sourceSlice.reducer