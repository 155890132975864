import { put, call, takeEvery, select } from 'redux-saga/effects'
import * as api from 'common/api/helpers/crudApi'
import { generateKeywords, replaceParam } from 'common/saga/helpers/utils'
import * as globalStoreAction from 'common/store/globalReducer'
import * as globalSagaAction from 'common/saga-actions/globalActions'
import { throwError } from 'common/config/errors'

const getUid = (state) => state.auth.uid

// EXTERNAL
export const queryConfig = ({ path, generateKeywords }) => {
	return {
		path, // path to document or collection
		generateKeywords
	}
}

export const sagaConfig = ({ loadAction, loadResponse }) => {
	return {
		loadAction, // load action
		loadResponse // reponse to load
	}
}

export const msgConfig = ({ fileName, functionName }) => {
	return {
		fileName,
		functionName
	}
}

export function* create(sagaConfig, queryConfig, msgConfig) {
	yield takeEvery(sagaConfig.loadAction, createInit, sagaConfig, queryConfig, msgConfig)
}

export function* update(sagaConfig, queryConfig, msgConfig) {
	yield takeEvery(sagaConfig.loadAction, updateInit, sagaConfig, queryConfig, msgConfig)
}

export function* remove(sagaConfig, queryConfig, msgConfig) {
	yield takeEvery(sagaConfig.loadAction, removeInit, sagaConfig, queryConfig, msgConfig)
}

// INTERNAL
function* createInit(sagaConfig, queryConfig, msgConfig, triggeredAction) {
	const id = triggeredAction.id

	try {
		// Loading on
		if (triggeredAction.loadingKey) yield put(globalStoreAction.loadingOn({ key: triggeredAction.loadingKey, item: id }))

		// Add user and timestamps to the document
		const uid = yield select(getUid)
		const timestamp = new Date()
		var content = { ...triggeredAction.content, updatedAt: timestamp, updatedBy: uid, createdAt: timestamp, createdBy: uid }

		// Add search keywords to the document (if requested)
		if (queryConfig.generateKeywords) {
			const attribute = queryConfig.generateKeywords
			const keywords = generateKeywords(content[attribute])
			content = { ...content, keywords: keywords }
		}

		// Introduce values into parameters
		var newQueryConfig = {
			...queryConfig,
			path: replaceParam(queryConfig.path, triggeredAction),
			content
		}
		// Execute query
		let createdId
		if (id != null && id !== '') createdId = (yield call(api.createWithId, newQueryConfig, msgConfig)).id
		else createdId = (yield call(api.create, newQueryConfig, msgConfig)).id

		// Return result
		if (sagaConfig.loadResponse) yield put(sagaConfig.loadResponse({ ...content, id: createdId }))
	} catch (err) {
		const content = throwError(err, msgConfig.fileName, msgConfig.functionName)
		yield put(globalSagaAction.showMessage({ content }))
	} finally {
		// Loading off
		if (triggeredAction.loadingKey) yield put(globalStoreAction.loadingOff({ key: triggeredAction.loadingKey, item: id }))
	}
}

function* updateInit(sagaConfig, queryConfig, msgConfig, triggeredAction) {
	const id = triggeredAction.id

	try {
		// Loading on
		if (triggeredAction.loadingKey) yield put(globalStoreAction.loadingOn({ key: triggeredAction.loadingKey, item: id }))

		// Add user and timestamps to the document
		const uid = yield select(getUid)
		const timestamp = new Date()
		var content = { ...triggeredAction.content, updatedAt: timestamp, updatedBy: uid }

		// Introduce values into parameters
		var newQueryConfig = {
			...queryConfig,
			path: replaceParam(queryConfig.path, triggeredAction),
			content
		}

		// Execute query
		const result = yield call(api.update, newQueryConfig, msgConfig)

		// Return result
		if (sagaConfig.loadResponse) yield put(sagaConfig.loadResponse({ ...result, id, content }))
	} catch (err) {
		const content = throwError(err, msgConfig.fileName, msgConfig.functionName)
		yield put(globalSagaAction.showMessage({ content }))
	} finally {
		// Loading off
		if (triggeredAction.loadingKey) yield put(globalStoreAction.loadingOff({ key: triggeredAction.loadingKey, item: id }))
	}
}

function* removeInit(sagaConfig, queryConfig, msgConfig, triggeredAction) {
	const id = triggeredAction.id

	try {
		// Loading on
		if (triggeredAction.loadingKey) yield put(globalStoreAction.loadingOn({ key: triggeredAction.loadingKey, item: id }))

		// Introduce values into parameters
		var newQueryConfig = {
			...queryConfig,
			path: replaceParam(queryConfig.path, triggeredAction)
		}

		// Execute query
		yield call(api.remove, newQueryConfig, msgConfig)

		// Return result
		if (sagaConfig.loadResponse) yield put(sagaConfig.loadResponse({ id }))
	} catch (err) {
		const content = throwError(err, msgConfig.fileName, msgConfig.functionName)
		yield put(globalSagaAction.showMessage({ content }))
	} finally {
		// Loading off
		if (triggeredAction.loadingKey) yield put(globalStoreAction.loadingOff({ key: triggeredAction.loadingKey, item: id }))
	}
}
