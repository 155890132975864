import React, { useCallback, useRef, useEffect, useState } from 'react'
import RightArrowIcon from '@mui/icons-material/ChevronRight'
import { Tooltip } from '@mui/material'
import clsx from 'clsx'

const ROW_HEIGHT = 30
const ROW_WIDTH = 200

function Menu({ items, x, y, level, parentIndex = 0, close, isVisible = true }) {
	const left = Math.min(x, window.innerWidth - (ROW_WIDTH + 20))
	const top = Math.min(y, window.innerHeight - (ROW_HEIGHT * items.length + 20))

	const [selected, setSelected] = useState(null)

	useEffect(() => {
		if (!isVisible) setSelected(null)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isVisible])

	function onClick(e, action) {
		if (!action) return
		if (e.preventDefault) e.preventDefault()
		if (e.stopPropagation) e.stopPropagation()
		action()
		close()
	}

	return (
		<div
			className={clsx('fixed bg-white z-50 paper w-[200px] py-[5px]', isVisible ? 'block' : 'hidden')}
			style={{
				left: `${left}px`,
				top: `${top}px`,
				height: `${ROW_HEIGHT * items.length + 10}px`
			}}
		>
			{items?.map((item, index) => (
				<Tooltip key={'cm' + level + '#' + parentIndex + '#' + index} title={item.info || ''} open={item.info ? undefined : false} placement="right">
					<div
						onClick={(e) => onClick(e, item.action)}
						className={clsx(
							item.isTitle && 'border-b-1 border-borderGray',
							!item.isTitle && 'px-4 whitespace-nowrap flex flex-row items-center justify-between contextMenuParent',
							!item.isTitle && selected === index ? 'bg-bgGray' : 'bg-white'
						)}
						style={{ height: ROW_HEIGHT + 'px', cursor: item.isTitle ? 'default' : 'pointer' }}
						onMouseOver={() => setSelected(index)}
					>
						<span>
							{item.isActive ? <span className="mr-[10px] h-[7px] w-[7px] bg-textGray inline-block rounded-full" /> : item.isSelectable && <span className="mr-[17px]" />}
							{item.icon &&
								React.createElement(item.icon, {
									className: 'text-textGray w-[15px] h-[15px] mr-[10px] align-middle inline-block'
								})}
							<span className="text-sm">{item.name}</span>
						</span>
						{item.items && <RightArrowIcon className="text-base text-textGray" />}
						{item.items && (
							<Menu items={item.items} x={left + ROW_WIDTH} y={top + index * ROW_HEIGHT} level={level + 1} parentIndex={index + parentIndex} close={close} isVisible={selected === index} />
						)}
					</div>
				</Tooltip>
			))}
		</div>
	)
}

export default function ContextMenu({ x, y, items, close }) {
	const containerRef = useRef(null)

	const onClickOutside = useCallback(
		(e) => {
			const clickInside = containerRef.current?.contains(e.target)
			if (!clickInside) close()
		},
		[close]
	)

	useEffect(() => {
		document.addEventListener('mousedown', onClickOutside)
		return () => {
			document.removeEventListener('mousedown', onClickOutside)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div ref={containerRef}>
			<Menu items={items} x={x} y={y} level={0} close={close} />
		</div>
	)
}
