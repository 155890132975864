import { backend } from 'common/config/backend'
import { throwAPIError } from 'common/config/errors'

const FILE_NAME = 'billingApi'

export async function checkoutPlan(token, tid, teamId, customerId, email, planId, priceId, currency, frequency, units) {
	const params = { tenantId: tid, teamId, customerId, email, planId, priceId, currency, frequency, units }
	const headers = { headers: { Authorization: 'Bearer ' + token } }
	return backend
		.post('/create-checkout-plan', params, headers)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, checkoutPlan.name)
			return { url: resp.data.url }
		})
		.catch((error) => {
			throwAPIError(error, error.response.status, FILE_NAME, checkoutPlan.name)
		})
}

export async function checkoutAddon(token, tid, teamId, customerId, email, addonId, priceId, currency, frequency, units) {
	const params = { tenantId: tid, teamId, customerId, email, addonId, priceId, currency, frequency, units }
	const headers = { headers: { Authorization: 'Bearer ' + token } }
	return backend
		.post('/create-checkout-addon', params, headers)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, checkoutAddon.name)
			return { url: resp.data.url }
		})
		.catch((error) => {
			throwAPIError(error, error.response.status, FILE_NAME, checkoutAddon.name)
		})
}

export async function customerPortal(token, tid, teamId, customerId) {
	const params = { tenantId: tid, teamId, customerId }
	const headers = { headers: { Authorization: 'Bearer ' + token } }
	return backend
		.post('/create-portal-session', params, headers)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, customerPortal.name)
			return { url: resp.data.url }
		})
		.catch((error) => {
			throwAPIError(error, error.response.status, FILE_NAME, customerPortal.name)
		})
}
