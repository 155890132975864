import CreateTextCell from 'common/components/grid/cells/CreateTextCell'
import { validateEmail } from 'common/utils/validate'

export const cellEmail = {
	component: CreateTextCell,
	config: {
		alignRight: false,
		formatBlurredInput: ({ value, typeProps }) => (value ? value : ''),
		formatCopyValue: ({ value, typeProps }) => (value ? value : ''),
		parseUserInput: ({ value, typeProps }) => {
			if (!value || value.length < 1) return null
			const newValue = value.trim()
			const validation = validateEmail(newValue)
			return validation.valid ? newValue : null
		},
		parsePastedValue: ({ value, typeProps, localeFallback }) => {
			if (!value || value.length < 1) return null
			const newValue = value.trim()
			const validation = validateEmail(newValue)
			return validation.valid ? newValue : null
		}
	}
}
