import { SnowflakeIcon, BigqueryIcon, CsvIcon, SAPIcon, MSSQLIcon } from 'automation/icons'
import MySQLIcon from 'automation/icons/mysql'
import { t } from 'i18next'

export const DATASOURCE_TIERS = {
	free: { key: 'free' },
	premium: { key: 'premium' }
}

export const DATE_FORMATS = [
	{
		'py': '%d/%m/%Y', 
		'js': 'dd/MM/yyyy'
	}, {
		'py': '%m/%d/%Y', 
		'js': 'MM/dd/yyyy'
	},{
		'py': '%d-%m-%Y', 
		'js': 'dd-MM-yyyy'
	}, {
		'py': '%m-%d-%Y', 
		'js': 'MM-dd-yyyy'
	}, {
		'py': '%d/%m/%Y %H:%M:%S',
		'js': 'dd/MM/yyyy hh:mm:ss'
	}, {
		'py': '%m/%d/%Y %H:%M:%S',
		'js': 'MM/dd/yyyy hh:mm:ss'
	}, {
		'py': '%d-%m-%Y %H:%M:%S',
		'js': 'dd-MM-yyyy hh:mm:ss'
	}, {
		'py': '%m-%d-%Y %H:%M:%S',
		'js': 'MM-dd-yyyy hh:mm:ss'
	}
]

export const FILES = {
	csv: {
		key: 'csv',
		name: 'automation:files.csv',
		icon: CsvIcon,
		header: 'automation:create.newFile',
		desc: 'automation:csvDesc.csvDesc'
	}
}

export const CONNECTORS = {
	bigquery: {
		key: 'bigquery',
		name: t('automation:connectors.bigquery'),
		manufacturer: 'Google',
		icon: BigqueryIcon,
		header: 'automation:create.newConnector',
		desc: t('automation:connector.desc', { connector: 'BigQuery' }),
		newConnection: t('automation:connector.newDataConnection', { connector: 'BigQuery' }),
		defaultConfig: {
			name: '',
			project_id: '',
			credentials_json: ''
		},
		defaultValidationConfig: {
			name: { valid: true, error: '' },
			project_id: { valid: true, error: '' },
			credentials_json: { valid: true, error: '' }
		},
		config: [
			{
				name: 'name',
				label: t('automation:configs.name'),
				desc: t('automation:bigqueryDesc.sourceName'),
				multiline: false,
				type: 'text',
				require: true
			},
			{
				name: 'project_id',
				label: t('automation:configs.projectId'),
				desc: t('automation:bigqueryDesc.projectId'),
				multiline: false,
				type: 'text',
				require: true
			},
			{
				name: 'datasetId',
				label: t('automation:configs.datasetId'),
				desc: t('automation:bigqueryDesc.datasetId'),
				multiline: false,
				type: 'text',
				require: false
			},
			{
				name: 'credentials_json',
				label: t('automation:configs.credentials'),
				desc: t('automation:bigqueryDesc.credentials'),
				multiline: true,
				type: 'text',
				require: true
			}
		]
	},
	snowflake: {
		key: 'snowflake',
		name: t('automation:connectors.snowflake'),
		icon: SnowflakeIcon,
		desc: t('automation:connector.desc', { connector: 'Snowflake' }),
		newConnection: t('automation:connector.newDataConnection', { connector: 'Snowflake' }),
		defaultConfig: {
			name: '',
			host: '',
			role: '',
			warehouse: '',
			database: '',
			schema: '',
			username: '',
			password: ''
		},
		defaultValidationConfig: {
			name: { valid: true, error: '' },
			host: { valid: true, error: '' },
			role: { valid: true, error: '' },
			warehouse: { valid: true, error: '' },
			database: { valid: true, error: '' },
			schema: { valid: true, error: '' },
			username: { valid: true, error: '' },
			password: { valid: true, error: '' }
		},
		config: [
			{
				name: 'name',
				label: t('automation:configs.name'),
				desc: t('automation:snowflakeDesc.name'),
				multiline: false,
				type: 'text',
				require: true
			},
			{
				name: 'host',
				label: t('automation:configs.host'),
				desc: t('automation:snowflakeDesc.host'),
				multiline: false,
				type: 'text',
				require: true
			},
			{
				name: 'username',
				label: t('automation:configs.username'),
				desc: t('automation:snowflakeDesc.username'),
				multiline: false,
				type: 'text',
				require: true
			},
			{
				name: 'password',
				label: t('automation:configs.password'),
				desc: t('automation:snowflakeDesc.password'),
				multiline: false,
				type: 'password',
				require: true
			},
			{
				name: 'role',
				label: t('automation:configs.role'),
				desc: t('automation:snowflakeDesc.role'),
				multiline: false,
				type: 'text',
				require: true
			},
			{
				name: 'warehouse',
				label: t('automation:configs.warehouse'),
				desc: t('automation:snowflakeDesc.warehouse'),
				multiline: false,
				type: 'text',
				require: true
			},
			{
				name: 'database',
				label: t('automation:configs.database'),
				desc: t('automation:snowflakeDesc.database'),
				multiline: false,
				type: 'text',
				require: true
			},
			{
				name: 'schema',
				label: t('automation:configs.schema'),
				desc: t('automation:snowflakeDesc.schema'),
				multiline: false,
				type: 'text',
				require: true
			}
		]
	},
	sqlserver: {
		key: 'sqlserver',
		name: t('automation:connectors.sqlserver'),
		icon: MSSQLIcon,
		desc: t('automation:connector.desc', { connector: 'MSSQL' }),
		newConnection: t('automation:connector.newDataConnection', { connector: 'MSSQL' }),
		defaultConfig: {
			name: '',
			host: '',
			username: '',
			password: '',
			database: '',
			port: '',
			schemas: ["dbo"],
			replication_method: {
				method: "STANDARD"
			},
			tunnel_method: {
				tunnel_method: "NO_TUNNEL"
			},
			ssl_method: {
				ssl_method: "unencrypted"
			}
		},
		defaultValidationConfig: {
			name: { valid: true, error: '' },
			host: { valid: true, error: '' },
			port: { valid: true, error: '' },
			username: { valid: true, error: '' },
			password: { valid: true, error: '' },
			database: { valid: true, error: '' }
		},
		config: [
			{
				name: 'name',
				label: t('automation:configs.name'),
				desc: t('automation:snowflakeDesc.name'),
				multiline: false,
				type: 'text',
				require: true
			},
			{
				name: 'host',
				label: t('automation:configs.host'),
				desc: t('automation:sqlserverDesc.host'),
				multiline: false,
				type: 'text',
				require: true
			},
			{
				name: 'port',
				label: t('automation:configs.port'),
				desc: t('automation:sqlserverDesc.port'),
				multiline: false,
				type: 'number',
				require: true
			},
			{
				name: 'username',
				label: t('automation:configs.username'),
				desc: t('automation:sqlserverDesc.username'),
				multiline: false,
				type: 'text',
				require: true
			},
			{
				name: 'password',
				label: t('automation:configs.password'),
				desc: t('automation:sqlserverDesc.password'),
				multiline: false,
				type: 'password',
				require: true
			},
			{
				name: 'database',
				label: t('automation:configs.database'),
				desc: t('automation:sqlserverDesc.database'),
				multiline: false,
				type: 'text',
				require: true
			}
		]
	},
	mysql: {
		key: 'mysql',
		name: t('automation:connectors.mysql'),
		icon: MySQLIcon,
		desc: t('automation:connector.desc', { connector: 'MySQL' }),
		newConnection: t('automation:connector.newDataConnection', { connector: 'MySQL' }),
		defaultConfig: {
			name: '',
			host: '',
			username: '',
			password: '',
			database: '',
			port: 3306,
			replication_method: {
				method: "STANDARD"
			},
			tunnel_method: {
				tunnel_method: "NO_TUNNEL"
			},
			ssl_mode: {
				mode: "preferred"
			}
		},
		defaultValidationConfig: {
			name: { valid: true, error: '' },
			host: { valid: true, error: '' },
			username: { valid: true, error: '' },
			password: { valid: true, error: '' },
			database: { valid: true, error: '' }
		},
		config: [
			{
				name: 'name',
				label: t('automation:configs.name'),
				desc: t('automation:mysqlDesc.name'),
				multiline: false,
				type: 'text',
				require: true
			},
			{
				name: 'host',
				label: t('automation:configs.host'),
				desc: t('automation:mysqlDesc.host'),
				multiline: false,
				type: 'text',
				require: true
			},
			{
				name: 'username',
				label: t('automation:configs.username'),
				desc: t('automation:mysqlDesc.username'),
				multiline: false,
				type: 'text',
				require: true
			},
			{
				name: 'password',
				label: t('automation:configs.password'),
				desc: t('automation:mysqlDesc.password'),
				multiline: false,
				type: 'password',
				require: true
			},
			{
				name: 'database',
				label: t('automation:configs.database'),
				desc: t('automation:mysqlDesc.database'),
				multiline: false,
				type: 'text',
				require: true
			}
		]
	},
	// sapbone: {
	// 	key: 'sapbone',
	// 	name: t('automation:connectors.sapbone'),
	// 	manufacturer: 'ODBC',
	// 	icon: SAPIcon,
	// 	desc: t('automation:connector.desc', { connector: 'SAP Business One' }),
	// 	newConnection: t('automation:connector.newDataConnection', { connector: 'SAP Business One' }),
	// 	defaultConfig: {
	// 		name: '',
	// 		host: '',
	// 		role: '',
	// 		warehouse: '',
	// 		database: '',
	// 		schema: '',
	// 		username: '',
	// 		password: ''
	// 	},
	// 	defaultValidationConfig: {
	// 		name: { valid: true, error: '' },
	// 		host: { valid: true, error: '' },
	// 		role: { valid: true, error: '' },
	// 		warehouse: { valid: true, error: '' },
	// 		database: { valid: true, error: '' },
	// 		schema: { valid: true, error: '' },
	// 		username: { valid: true, error: '' },
	// 		password: { valid: true, error: '' }
	// 	},
	// 	config: [
	// 		{
	// 			name: 'name',
	// 			label: t('automation:configs.name'),
	// 			desc: t('automation:sapBoneDesc.name'),
	// 			multiline: false,
	// 			type: 'text',
	// 			require: true
	// 		},
	// 		{
	// 			name: 'host',
	// 			label: t('automation:configs.host'),
	// 			desc: t('automation:sapBoneDesc.host'),
	// 			multiline: false,
	// 			type: 'text',
	// 			require: true
	// 		},
	// 		{
	// 			name: 'username',
	// 			label: t('automation:configs.username'),
	// 			desc: t('automation:sapBoneDesc.username'),
	// 			multiline: false,
	// 			type: 'text',
	// 			require: true
	// 		},
	// 		{
	// 			name: 'password',
	// 			label: t('automation:configs.password'),
	// 			desc: t('automation:sapBoneDesc.password'),
	// 			multiline: false,
	// 			type: 'password',
	// 			require: true
	// 		},
	// 		{
	// 			name: 'role',
	// 			label: t('automation:configs.role'),
	// 			desc: t('automation:sapBoneDesc.role'),
	// 			multiline: false,
	// 			type: 'text',
	// 			require: true
	// 		},
	// 		{
	// 			name: 'database',
	// 			label: t('automation:configs.database'),
	// 			desc: t('automation:sapBoneDesc.database'),
	// 			multiline: false,
	// 			type: 'text',
	// 			require: true
	// 		},
	// 		{
	// 			name: 'schema',
	// 			label: t('automation:configs.schema'),
	// 			desc: t('automation:sapBoneDesc.schema'),
	// 			multiline: false,
	// 			type: 'text',
	// 			require: true
	// 		}
	// 	]
		
	// },
}
