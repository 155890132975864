import { createSlice } from '@reduxjs/toolkit'

export const chartSlice = createSlice({
	name: 'chart',
	initialState: {
		// Data from firestore
		charts: {},
		// Derived data. In store to optimize generation (only on chart loads)
		chartsByVar: {}
	},
	reducers: {
		loadCharts(state, action) {
			const aid = action.payload.id
			state.charts[aid] = { loaded: true, ...action.payload }
			state.chartsByVar[aid] = {}

			const charts = action.payload.result
			charts &&
				Object.values(charts)?.forEach((chart) => {
					chart.variables?.forEach((variable) => {
						state.chartsByVar[aid][variable.id] = state.chartsByVar[aid][variable.id] || []
						state.chartsByVar[aid][variable.id].push(chart.id)
					})
				})
		},
		cancelCharts: (state, action) => {
			state.charts[action.payload.id] = null
			state.chartsByVar[action.payload.id] = null
		}
	}
})

export const { loadCharts, cancelCharts } = chartSlice.actions

export default chartSlice.reducer
