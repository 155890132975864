import { cloneDeep } from 'lodash'

// Helper function to structure the formulas of a row over time
export const updateRowFormulas = (expression, expressions) => {
	const start = expression.start
	const end = expression.end

	// Decide if previous expressions are still valid
	var newExpressions = []
	expressions?.forEach((expr) => {
		// Full overlap, skip
		if (start <= expr.start && end >= expr.end) return
		// No overlap, maintain
		else if (start >= expr.end || end <= expr.start) newExpressions.push(expr)
		// Partial overlap, split
		else {
			// Shorten previous
			if (start > expr.start) newExpressions.push({ ...expr, end: start })
			// Shorten next
			if (end < expr.end) newExpressions.push({ ...expr, start: end })
		}
	})

	// Add new expression if it's not empty
	if (expression.expression !== '' && expression.expression != null) newExpressions.push(expression)

	// Sort expressions
	newExpressions = newExpressions.sort((a, b) => (a.start > b.start ? 1 : b.start > a.start ? -1 : 0))

	// Merge sequential expressions with equal formula
	newExpressions = mergeSequentialExpressions(newExpressions)

	return newExpressions
}

export const mergeSequentialExpressions = (expressions) => {
	let newExpressions = cloneDeep(expressions)

	// Merge sequential expressions with equal formula
	for (let i = 0; i < newExpressions.length; i++) {
		let equal = true
		let j = i + 1
		while (equal && j < newExpressions.length) {
			if (newExpressions[i].expression !== newExpressions[j].expression || newExpressions[j - 1].end !== newExpressions[j].start) equal = false
			else j += 1
		}
		j -= 1
		if (j !== i) {
			const expression = newExpressions[i].expression
			const start = newExpressions[i].start
			const end = newExpressions[j].end
			newExpressions.splice(i, j - i + 1, { expression, start, end })
		}
	}

	return newExpressions
}
