export default function BigqueryIcon({ ...props }) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66 58" fill="currentColor" {...props}>
			<path
				d="M14.98 54.973L1.058 30.859C0.314 29.571 0.314 27.983 1.058 26.695L14.98 2.582C15.723 1.295 17.095 0.502 18.58 0.5H46.437C47.917 0.507 49.282 1.3 50.022 2.582L63.942 26.695C64.686 27.983 64.686 29.571 63.942 30.859L50.02 54.973C49.277 56.26 47.905 57.053 46.42 57.055H18.57C17.087 57.05 15.72 56.257 14.977 54.973H14.98Z"
				fill="#4386FA"
			/>
			<path
				opacity="0.1"
				d="M41.1972 21.0117C41.1972 21.0117 45.0672 30.2947 39.7912 35.5567C34.5152 40.8187 24.9082 37.4507 24.9082 37.4507L44.4502 57.0467H46.4342C47.9202 57.0447 49.2922 56.2507 50.0342 54.9647L59.2502 39.0067L41.1972 21.0117Z"
				fill="black"
			/>
			<path
				d="M45.7668 40.0073L41.5008 35.7303C41.4544 35.6827 41.4011 35.6422 41.3428 35.6103C45.1098 30.7583 44.4538 23.8123 39.8438 19.7513C37.623 17.7938 34.7394 16.7568 31.7806 16.8517C28.8218 16.9466 26.0105 18.1663 23.9198 20.2621C21.8291 22.3579 20.6162 25.1721 20.5285 28.1312C20.4408 31.0902 21.4849 33.9713 23.4478 36.1873C25.4097 38.4035 28.1427 39.7883 31.0901 40.0596C34.0374 40.3309 36.9774 39.4682 39.3108 37.6473C39.3418 37.7023 39.3798 37.7533 39.4238 37.7973L43.7008 42.0743C43.8264 42.1998 43.9967 42.2703 44.1743 42.2703C44.3519 42.2703 44.5222 42.1998 44.6478 42.0743L45.7678 40.9543C45.8933 40.8287 45.9638 40.6584 45.9638 40.4808C45.9638 40.3032 45.8933 40.1329 45.7678 40.0073H45.7668ZM32.1408 37.2413C30.4102 37.2414 28.7184 36.7283 27.2794 35.7669C25.8404 34.8056 24.7187 33.439 24.0564 31.8402C23.394 30.2413 23.2207 28.482 23.5582 26.7846C23.8958 25.0872 24.7291 23.5281 25.9528 22.3043C27.1766 21.0806 28.7357 20.2473 30.4331 19.9097C32.1305 19.5722 33.8898 19.7455 35.4887 20.4079C37.0875 21.0702 38.4541 22.1919 39.4154 23.6309C40.3768 25.0699 40.8899 26.7617 40.8898 28.4923C40.8895 30.8126 39.9677 33.0378 38.327 34.6785C36.6863 36.3192 34.4611 37.241 32.1408 37.2413V37.2413ZM26.5478 28.0253V31.6413C27.1048 32.6243 27.9108 33.4443 28.8858 34.0163V28.0033L26.5478 28.0253ZM30.9228 25.0273V34.7993C31.6956 34.9417 32.488 34.9417 33.2608 34.7993V25.0273H30.9228ZM37.6868 31.6333V29.4913H35.3508V33.9913C36.3244 33.4208 37.1307 32.6041 37.6888 31.6233L37.6868 31.6333Z"
				fill="white"
			/>
		</svg>
	)
}
