import { createSlice } from '@reduxjs/toolkit'
import { searchResults } from 'common/store/helpers/searchHelper'
import { TABLE_PARAMS } from 'table/constants/tableParameters'

export const tableDataSlice = createSlice({
	name: 'tableData',
	initialState: {
		data: {},
		totalRows: {},
		isLoaded: {},
		search: {},
		fileTransmit: {}
	},
	reducers: {
		loadData: (state, action) => {
			state.data[action.payload.id] = action.payload.isInitial ? action.payload.data : { ...state.data[action.payload.id], ...action.payload.data }
			state.totalRows[action.payload.id] = action.payload.isInitial ? Math.min(action.payload.totalRows, TABLE_PARAMS.GRID_MAX_ITEMS) : state.totalRows[action.payload.id]
			state.isLoaded[action.payload.id] = true
		},
		clearData: (state, action) => {
			state.data[action.payload.id] = clearDataHelper(state.data[action.payload.id], action.payload)
		},
		clearAllData: (state, action) => {
			state.data[action.payload.id] = null
			state.totalRows[action.payload.id] = null
			state.isLoaded[action.payload.id] = null
		},
		addRows: (state, action) => {
			state.data[action.payload.id] = addRowsHelper(state.data[action.payload.id], state.totalRows[action.payload.id], action.payload)
			state.totalRows[action.payload.id] = (state.totalRows[action.payload.id] || 0) + action.payload.data?.length
		},
		updateRows: (state, action) => {
			state.data[action.payload.id] = updateRowsHelper(state.data[action.payload.id], action.payload)
		},
		rollbackChange: (state, action) => {
			state.data = action.payload.data
			state.totalRows = action.payload.totalRows
		},
		deleteRow: (state, action) => {
			state.data[action.payload.id] = deleteRowHelper(state.data[action.payload.id], action.payload)
			state.totalRows[action.payload.id] = state.totalRows[action.payload.id] - 1
		},
		search: (state, action) => {
			state.search[action.payload.key] = searchResults(state.search[action.payload.key], action.payload)
		},
		cancelSearch: (state, action) => {
			state.search[action.payload.key] = null
		},
		fileTransmit: (state, action) => {
			state.fileTransmit[action.payload.key] = { ...action.payload }
		}
	}
})

export const { loadData, clearData, clearAllData, addRows, updateRows, rollbackChange, deleteRow, search, cancelSearch, fileTransmit } = tableDataSlice.actions

export default tableDataSlice.reducer

const clearDataHelper = (stateData, payload) => {
	var data = { ...stateData }
	const limit = payload.limit
	payload.pages.map((page) => {
		for (let i = page * limit; i < (page + 1) * limit; i++) delete data[i]
		return void 0
	})
	return data
}

const deleteRowHelper = (stateData, payload) => {
	var data = {}
	const indexToDelete = payload.rowIndex
	Object.keys(stateData).map((key) => {
		if (key < indexToDelete) data[key] = stateData[key]
		else if (key > indexToDelete) data[key - 1] = stateData[key]
		return void 0
	})
	return data
}

const addRowsHelper = (stateData, stateTotalRows, payload) => {
	var newData = stateData ? { ...stateData } : {}
	payload.data?.map((el, index) => (newData[stateTotalRows + index] = el))
	return newData
}

const updateRowsHelper = (stateData, payload) => {
	var data = { ...stateData }
	Object.entries(payload.data)?.map((el) => (data[el[0]] = { ...data[el[0]], ...el[1] }))
	return data
}
