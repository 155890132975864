import { useDispatch, useSelector } from 'react-redux'
import { t } from 'i18next'
import { useState } from 'react'
import { Avatar, CircularProgress, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useNavigate } from 'react-router-dom'
import ActionConfirm from 'common/components/ActionConfirm'
import { acceptInvite, deleteTeam, rejectInvite } from 'common/saga-actions/teamActions'
import NewTeam from 'common/screens/team/NewTeam'
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined'
import AddIcon from '@mui/icons-material/Add'
import BillIcon from '@mui/icons-material/Toll'
import { Settings } from '@mui/icons-material'
import { useTeamPermits } from 'common/hooks/useTeamPermits'
import { PLANS } from 'common/constants/plans'
import UsersIcon from '@mui/icons-material/PeopleOutlined'
import TeamShare from 'common/screens/team/TeamShare'
import AcceptIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import RejectIcon from '@mui/icons-material/CancelOutlined'
import clsx from 'clsx'

export default function TeamButton() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { canAdmin, canView } = useTeamPermits()

	// #### STORE
	const tid = useSelector((state) => state.auth.tid)
	const teamId = useSelector((state) => state.team.teamId)
	const team = useSelector((state) => state.team.team)
	const teams = useSelector((state) => state.team.teams?.result)
	const teamsNotFound = useSelector((state) => state.team.team?.notFound)
	const plan = team?.plan ? t(PLANS[team.plan].label) : null
	const isDeleting = useSelector((state) => state.global.loading?.deleteTeam)
	const isUpdating = useSelector((state) => state.global.loading?.updateTeam)
	const isLoading = (teamId && isDeleting && isDeleting[teamId]) || (teamId && isUpdating && isUpdating[teamId]) || false
	const isInviteLoading = useSelector((state) => state.global.loading.manageTeamInvite)

	// #### STATE
	const [menuAnchor, setMenuAnchor] = useState(null)
	const [openDelete, setOpenDelete] = useState(false)
	const [openCreate, setOpenCreate] = useState({ isOpen: false, teamId: null })
	const [openShare, setOpenShare] = useState(false)

	// #### FUNCTIONS
	function onTeamClick(tId) {
		setMenuAnchor(null)
		navigate('/team/' + tId)
	}

	function onDeleteRequest() {
		setMenuAnchor(null)
		setOpenDelete(true)
	}

	function onDeleteConfirm() {
		dispatch(deleteTeam({ tid, teamId }))
		setOpenDelete(false)
	}

	function onCreateRequest() {
		setMenuAnchor(null)
		setOpenCreate({ isOpen: true, teamId: null })
	}

	function onRenameRequest() {
		setMenuAnchor(null)
		setOpenCreate({ isOpen: true, teamId })
	}

	function goToBilling() {
		navigate(`/team/${teamId}/billing`)
	}

	function onOpenShare() {
		setMenuAnchor(null)
		setOpenShare(true)
	}

	function onAcceptInvite(teamId) {
		dispatch(acceptInvite({ teamId }))
	}

	function onRejectInvite(teamId) {
		dispatch(rejectInvite({ teamId }))
	}

	return (
		<>
			<div
				className="flex flex-row items-center justify-between text-white rounded px-2 hover:bg-barBorderLight cursor-pointer"
				onClick={(e) => (teamsNotFound ? onCreateRequest() : setMenuAnchor(e.currentTarget))}
			>
				<div className="flex flex-col overflow-hidden">
					<span className="text-sm font-medium truncate">{teamsNotFound ? t('common:team.create') : !team ? t('common:team.private') : team?.name}</span>
					<span className="text-xs text-barTextLight">{!team ? t('common:team.guest') : plan}</span>
				</div>
				{isLoading ? <CircularProgress size={15} className="text-textGray ml-2 shrink-0" /> : <ExpandMoreIcon fontSize="small" className="ml-2 shrink-0" />}
			</div>
			<Menu anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={() => setMenuAnchor(null)}>
				{teams &&
					Object.keys(teams)?.map((tId) => {
						const team = teams[tId]
						const isActive = teamId === tId
						const isInvite = team?.isInvite
						const isCurrentInviteLoading = (isInviteLoading && isInviteLoading[tId]) || false
						return (
							<MenuItem
								key={tId}
								dense
								onClick={!isInvite ? () => onTeamClick(tId) : undefined}
								selected={isActive}
								className={clsx('flex flex-row items-center group/invite', isInvite && 'cursor-default')}
							>
								<ListItemIcon>
									<Avatar variant="rounded" className="text-xs w-[20px] h-[20px] uppercase bg-barBg">
										{team.name[0]}
									</Avatar>
								</ListItemIcon>
								<ListItemText>{team.name}</ListItemText>
								{isInvite && !isCurrentInviteLoading && (
									<>
										<div className="ml-4 text-textGray text-xxs uppercase inline group-hover/invite:hidden">{t('common:team.invite')}</div>
										<Tooltip title={t('common:team.rejectInvite')} placement="bottom">
											<IconButton size="small" className="text-red-600 hidden group-hover/invite:flex w-[24px] h-[24px]" onClick={() => onRejectInvite(tId)}>
												<RejectIcon fontSize="w-[16px] h-[16px]" />
											</IconButton>
										</Tooltip>
										<Tooltip title={t('common:team.acceptInvite')} placement="bottom">
											<IconButton size="small" className="text-green-600 hidden group-hover/invite:flex w-[24px] h-[24px]" onClick={() => onAcceptInvite(tId)}>
												<AcceptIcon fontSize="w-[16px] h-[16px]" />
											</IconButton>
										</Tooltip>
									</>
								)}
								{isInvite && isCurrentInviteLoading && <CircularProgress size={15} className="text-textGray" />}
							</MenuItem>
						)
					})}

				<Divider />
				<MenuItem dense onClick={onCreateRequest}>
					<ListItemIcon>
						<AddIcon className="text-textGray" />
					</ListItemIcon>
					<ListItemText>{t('common:team.create')}</ListItemText>
				</MenuItem>

				{canAdmin && <Divider />}

				{canAdmin && (
					<MenuItem dense onClick={onOpenShare}>
						<ListItemIcon>
							<UsersIcon className="text-textGray" />
						</ListItemIcon>
						<ListItemText>{t('common:team.users')}</ListItemText>
					</MenuItem>
				)}

				{canAdmin && (
					<MenuItem dense onClick={goToBilling}>
						<ListItemIcon>
							<BillIcon className="text-textGray" />
						</ListItemIcon>
						<ListItemText>{t('common:team.billing')}</ListItemText>
					</MenuItem>
				)}
				{canAdmin && (
					<MenuItem dense onClick={onRenameRequest}>
						<ListItemIcon>
							<Settings className="text-textGray" />
						</ListItemIcon>
						<ListItemText>{t('common:team.settings')}</ListItemText>
					</MenuItem>
				)}

				{canAdmin && (
					<MenuItem dense onClick={onDeleteRequest}>
						<ListItemIcon>
							<DeleteIcon className="text-textGray" />
						</ListItemIcon>
						<ListItemText>{t('common:team.delete')}</ListItemText>
					</MenuItem>
				)}
			</Menu>
			<ActionConfirm
				open={Boolean(openDelete)}
				title={t('common:team.deleteConfirm', { name: team?.name || '' })}
				content={t('common:team.deleteInfo')}
				onClose={() => setOpenDelete(false)}
				onConfirm={onDeleteConfirm}
			/>
			<NewTeam tid={tid} teamId={openCreate.teamId} team={openCreate.teamId ? team : null} isOpen={openCreate.isOpen} onClose={() => setOpenCreate({ isOpen: false, teamId: openCreate.teamId })} />
			<TeamShare isOpen={openShare} onClose={() => setOpenShare(false)} />
		</>
	)
}
