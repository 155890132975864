import CreateDateCell from 'common/components/grid/cells/CreateDateCell'
import { formatDate, parseDate, pasteDate } from 'common/components/grid/cells/CellDate'

export const cellTime = {
	component: CreateDateCell,
	config: {
		alignRight: false,
		formatBlurredInput: formatDate,
		formatCopyValue: formatDate,
		parseUserInput: parseDate,
		parsePastedValue: pasteDate
	}
}
