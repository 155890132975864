import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close'
import { Button, Chip, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, InputAdornment, MenuItem, OutlinedInput, TextField } from '@mui/material'
import { BILLING_CURRENCIES, BILLING_FREQUENCIES, CONTACT_EMAIL, PLANS } from 'common/constants/plans'
import AwesomeIcon from '@mui/icons-material/AutoAwesome'
import CheckIcon from '@mui/icons-material/Done'
import { checkoutPlan } from 'common/saga-actions/billingActions'
import { LoadingButton } from '@mui/lab'
import PlanConfig from './PlanConfig'

export default function Plans({ isOpen, onClose }) {
	const { t } = useTranslation('common')
	const dispatch = useDispatch()

	// #### REDUX
	const tid = useSelector((state) => state.auth.tid)
	const teamId = useSelector((state) => state.team.teamId)
	const customerId = useSelector((state) => state.team.team?.customerId)
	const loading = useSelector((state) => state.global.loading?.checkout)

	// #### STATE
	const [billingFreq, setBillingFreq] = useState(BILLING_FREQUENCIES.year.key)
	const [billingCurrency, setBillingCurrency] = useState(BILLING_CURRENCIES.usd.key)
	const [billingQuantity, setBillingQuantity] = useState(1)
	const [customQuantity, setCustomQuantity] = useState(false)
	const [quantityError, setQuantityError] = useState(false)

	// #### EFFECTS
	useEffect(() => {
		setBillingFreq(BILLING_FREQUENCIES.year.key)
		setBillingCurrency(BILLING_CURRENCIES.usd.key)
		setBillingQuantity(1)
		setCustomQuantity(false)
		setQuantityError(false)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen])

	// #### FUNCTIONS
	function onChangeFrequency(e, value) {
		if (value) setBillingFreq(value)
	}

	function onChangeCurrency(value) {
		if (value) setBillingCurrency(value)
	}

	function sendEmail(e) {
		window.location.href = `mailto:${CONTACT_EMAIL}`
		e.preventDefault()
	}

	function onCheckout(planId) {
		const plan = PLANS[planId]
		const frequency = billingFreq
		const currency = billingCurrency
		const units = parseInt(billingQuantity)
		const priceId = plan.prices[frequency].priceId
		if (isNaN(units)) setQuantityError(true)
		else {
			setQuantityError(false)
			dispatch(checkoutPlan({ tid, teamId, customerId, planId, priceId, currency, frequency, units }))
		}
	}

	function onChangeQuantity(value) {
		if (value === 'more') {
			setBillingQuantity('')
			setCustomQuantity(true)
		} else setBillingQuantity(value)
	}

	return (
		<Dialog onClose={onClose} open={isOpen} maxWidth="md" fullWidth={true}>
			<DialogTitle className="mb-2">
				<span className="text-xl">{t('common:choosePlan.title')}&nbsp;</span>
				<IconButton size="small" className="absolute top-4 right-4 text-textGray" onClick={onClose}>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<div className="flex flex-col items-center">
					<PlanConfig currency={billingCurrency} frequency={billingFreq} onChangeCurrency={onChangeCurrency} onChangeFrequency={onChangeFrequency} className="mb-8" />

					<div className="grid grid-cols-1 md:grid-cols-3 gap-4 w-full">
						{Object.values(PLANS).map((plan, index) => (
							<div key={`plans#${index}`} className="rounded p-4 min-w-[200px] paper">
								<div className="text-xl flex flex-row items-center justify-between">
									<span>{t(plan.label)}</span>
									{plan.tag && <Chip size="small" label={t(plan.tag)} className="ml-4 text-xxs h-[18px] bg-primaryLight" />}
								</div>
								<div className="h-[90px] flex flex-col justify-center">
									{plan.key === PLANS.business.key ? (
										<AwesomeIcon className="text-gray-300 w-[50px] h-[50px]" />
									) : (
										<>
											<div className="flex flex-row flex-nowrap items-center whitespace-nowrap mb-4 mt-4">
												<span className="text-xl font-medium">
													{BILLING_CURRENCIES[billingCurrency].symbol}
													{plan.prices ? plan.prices[billingFreq][billingCurrency] : ''}
												</span>
												<span className="text-xs">
													&nbsp;{t(plan.unit)}/{t('common:billingFreq.month')}
												</span>
											</div>
											{!customQuantity || !plan.seatSelector?.isEnabled ? (
												<TextField
													select
													fullWidth
													variant="outlined"
													size="small"
													value={plan.seatSelector?.fixedSeats || billingQuantity}
													onChange={(event) => onChangeQuantity(event.target.value)}
													inputProps={{ className: 'text-sm' }}
													disabled={!plan.seatSelector?.isEnabled}
												>
													{[...Array(10).keys()].map((el) => {
														const num = el + 1
														return (
															<MenuItem key={`seats#${num}`} value={num} className="text-sm">
																{`${num} ${num === 1 ? t('common:plans.seat') : t('common:plans.seats')}`}
															</MenuItem>
														)
													})}
													<MenuItem value="more" className="text-sm">
														{t('common:choosePlan.moreSeats')}
													</MenuItem>
												</TextField>
											) : (
												<OutlinedInput
													autoFocus
													fullWidth
													size="small"
													value={plan.seatSelector?.fixedSeats || billingQuantity}
													onChange={(event) => onChangeQuantity(event.target.value)}
													inputProps={{ className: 'text-sm' }}
													endAdornment={
														<InputAdornment position="end">
															<span className="text-sm">{t('common:plans.seats')}</span>
														</InputAdornment>
													}
													error={quantityError}
												/>
											)}
										</>
									)}
								</div>

								{plan.key === PLANS.free.key ? (
									<Button size="small" fullWidth disabled variant="contained" className="normal-case mt-4">
										{t('common:choosePlan.current')}
									</Button>
								) : plan.key === PLANS.business.key ? (
									<Button size="small" fullWidth className="buttonContainedContrast normal-case mt-4" onClick={sendEmail}>
										{t('common:choosePlan.contact')}
									</Button>
								) : (
									<LoadingButton
										loading={loading}
										size="small"
										fullWidth
										className="buttonContainedContrast normal-case mt-4"
										onClick={() => onCheckout(plan.key)}
										loadingIndicator={<CircularProgress size={15} className="text-white" />}
									>
										{`${t('common:choosePlan.upgrade')} ${t(plan.label)}`}
									</LoadingButton>
								)}

								<div className="mt-8">
									{plan.features.map((feature, featureIndex) => (
										<div key={`planFeature${featureIndex}`} className="flex flex-row items-center mt-1">
											<CheckIcon fontSize="small" className="text-textGray mr-2 w-[15px] h-[15px]" />
											<span className="text-sm">{feature}</span>
										</div>
									))}
								</div>
							</div>
						))}
					</div>
					<div className="text-sm mt-6 mb-4 hover:underline">
						<a target="_blank" rel="noreferrer" href="https://singularly.com/pricing">
							{t('common:choosePlan.compare')}
						</a>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	)
}
