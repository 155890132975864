import { IconButton, Tabs, Tab, Popover } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close'
import ModelDatesCalculation from 'model/screens/dates/ModelDatesCalculation'
import ModelDatesFormat from 'model/screens/dates/ModelDatesFormat'
import { useSelector } from 'react-redux'
import * as modelSel from 'model/store/modelSelector'
import * as modelDataSel from 'model/store/modelDataSelector'
import ModelDatesVisualization from 'model/screens/dates/ModelDatesVisualization'

export default function ModelDates({ aid, anchor, onClose }) {
	const { t } = useTranslation(['common', 'model'])

	const isDesigning = useSelector((state) => modelSel.selectModelIsDesigning(state, aid))
	const isEngineReady = useSelector((state) => modelDataSel.selectModelIsEngineReady(state, aid))

	// #### STATE
	const [tab, setTab] = useState(0)

	// #### POPOVER CONTROL
	const isPopoverOpen = Boolean(anchor)

	return (
		<Popover open={isPopoverOpen} anchorEl={anchor} onClose={onClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} classes={{ paper: 'flex w-[400px] flex shadow-md' }}>
			<div className="flex-1 flex flex-col pb-4">
				<div className="flex flex-row justify-between items-center border-b border-b-borderGray p-2 pl-4">
					<span className="text-md font-medium text-textGray">{t('model:dates.title')}</span>
					<IconButton size="small" className="text-textGray" onClick={onClose}>
						<CloseIcon fontSize="small" />
					</IconButton>
				</div>

				{isDesigning && isEngineReady && (
					<Tabs value={tab} variant="fullWidth" onChange={(e, value) => setTab(value)} orientation="horizontal" className="tabs shrink-0" classes={{ indicator: 'tabsIndicator' }}>
						<Tab label={t('model:dates.visualization')} className="tab" classes={{ selected: 'tabSelected' }} />
						<Tab label={t('model:dates.calculation')} className="tab" classes={{ selected: 'tabSelected' }} />
						<Tab label={t('model:dates.format')} className="tab" classes={{ selected: 'tabSelected' }} />
					</Tabs>
				)}

				<div className="px-4 flex flex-col">
					{(tab === 0 || !isDesigning || !isEngineReady) && <ModelDatesVisualization aid={aid} onClose={onClose} isDesigning={isDesigning} />}
					{tab === 1 && isDesigning && isEngineReady && <ModelDatesCalculation aid={aid} onClose={onClose} />}
					{tab === 2 && isDesigning && isEngineReady && <ModelDatesFormat aid={aid} />}
				</div>
			</div>
		</Popover>
	)
}
