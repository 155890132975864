import { backend } from 'common/config/backend'
import { throwAPIError } from 'common/config/errors'

const FILE_NAME = 'sourceApi'

export async function create(token, tenantId, teamId, userId, source, config) {
	let headers = { headers: { Authorization: 'Bearer ' + token } }
	const params = { tenantId, teamId, userId, type: source, name: config.name, configuration: config }
	return backend
		.post('/connectors/sources', params, headers)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, create.name)
			return resp.data
		})
		.catch((error) => {
			throwAPIError(error, error.response.status, FILE_NAME, create.name)
		})
}

export async function check(token, tid, teamId, sourceId) {
	let headers = { headers: { Authorization: 'Bearer ' + token } }
	const params = { tenantId: tid, sourceId, teamId }
	return backend
		.post('/connectors/sources/check_connection', params, headers)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, check.name)
			return resp.data
		})
		.catch((error) => {
			throwAPIError(error, error.response.status, FILE_NAME, check.name)
		})
}

export async function discover(token, teamId, sourceId) {
	let headers = { headers: { Authorization: 'Bearer ' + token } }
	const params = { teamId, sourceId }
	return backend
		.post('/connectors/sources/discover_schema', params, headers)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, discover.name)
			return resp.data
		})
		.catch((error) => {
			throwAPIError(error, error.response.status, FILE_NAME, discover.name)
		})
}

export async function update(token, tenantId, teamId, dataSource, sourceId, config) {
	let headers = { headers: { Authorization: 'Bearer ' + token } }
	const params = { tenantId, teamId, name: config.name, sourceId, type: dataSource, configuration: config }
	return backend
		.patch('/connectors/sources', params, headers)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, update.name)
			return resp.data
		})
		.catch((error) => {
			throwAPIError(error, error.response.status, FILE_NAME, update.name)
		})
}

export async function remove(token, tenantId, teamId, sourceId) {
	let config = { data: { tenantId, teamId, sourceId }, headers: { Authorization: 'Bearer ' + token } }
	return backend
		.delete('/connectors/sources', config)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, remove.name)
			return resp.data
		})
		.catch((error) => {
			throwAPIError(error, error.response.status, FILE_NAME, remove.name)
		})
}

export async function refresh(token, tenantId, teamId, userId, sourceId) {
	let headers = { headers: { Authorization: 'Bearer ' + token } }
	const params = { tenantId, teamId, userId, sourceId }
	return backend
		.post('/connectors/sources/refresh', params, headers)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, create.name)
			return resp.data
		})
		.catch((error) => {
			throwAPIError(error, error.response.status, FILE_NAME, create.name)
		})
}