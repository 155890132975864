import { useTranslation } from 'react-i18next'
import { Dialog, DialogTitle, DialogContent, Divider,CircularProgress, IconButton, MenuItem, Menu } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import MoreIcon from '@mui/icons-material/MoreVert'
import { CONNECTORS } from 'automation/constants/datasources'
import ActionConfirm from 'common/components/ActionConfirm'
import { deleteSource } from 'automation/saga-actions/sourceActions'
import CloseIcon from '@mui/icons-material/Close'

export default function Source({ isOpen, onClose, connector }) {
	const { t } = useTranslation(['common', 'automation'])
	const dispatch = useDispatch()
	let { teamId } = useParams()
	const tenantId = useSelector((state) => state.auth.tid)
	const sources = useSelector((state) => state.source.sources?.result)
    const isDeleting = useSelector((state) => state.global.loading?.deleteSource)
    
    const [manageSources, setManageSources] = useState([])
    const [sourceMenu, setSourceMenu] = useState({ anchor: null, sourceId: null })
	const [isDeleteOpen, setIsDeleteOpen] = useState(null)

	useEffect(() => {
        if(sources)
            setManageSources(Object.values(sources).filter(source => source.sourceName.toLowerCase() === connector))
	}, [sources])


    function onOpenSourceMenu(e, sourceId) {
		if (e.preventDefault) e.preventDefault()
		if (e.stopPropagation) e.stopPropagation()
		setSourceMenu({ anchor: e.currentTarget, sourceId })
	}

	function onDeleteOpen() {
		setIsDeleteOpen(sourceMenu.sourceId)
		setSourceMenu(null)
	}

    function onDelete() {
		dispatch(deleteSource({ tenantId, teamId, sourceId: isDeleteOpen }))
        setIsDeleteOpen(null)
	}

	return (
		<Dialog onClose={onClose} open={isOpen} maxWidth="md" fullWidth={true} PaperProps={{style: {
			minHeight: '90%',
			maxHeight: '90%',
		  }}}>
			<DialogTitle className='text-sm '>
				<span>{t(`automation:create.manage`)}&nbsp;</span>
                <IconButton size="small" className="absolute top-4 right-4 text-textGray" onClick={onClose}>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<Divider />
			<DialogContent>
				{manageSources.map(source => {
                    const connection = connector && CONNECTORS[connector]
                    const isLoading = (isDeleting && isDeleting[source.sourceId]) || false
                    return (
                        <div key={source.sourceId} className="col-span-full paper cursor-pointer hover:bg-bgGray m-2 p-4 flex flex-row items-center justify-between ">
                            <div className="flex flex-row items-center flex-1">
                                {connection && React.createElement(connection.icon, { width: '25px', height: '25px', className: 'text-[20px] mr-4' })}
                                <div>
                                    <div className="text-sm font-medium truncate">{source.name}</div>
                                    <div className="text-xs text-textGray leading-none truncate">{connection && t(connection.name)}</div>
                                </div>
                            </div>
                            {isLoading ? (
                                <CircularProgress size={20} className="text-textGray mr-2" />
                            ) : (
                                <IconButton size="small" onClick={(e) => onOpenSourceMenu(e, source.sourceId)}>
                                    <MoreIcon className="text-xl" />
                                </IconButton>
                            )}
                        </div>
                    )
                    
                })}
                <Menu anchorEl={sourceMenu?.anchor} keepMounted open={Boolean(sourceMenu?.anchor)} onClose={() => setSourceMenu(null)}>
                    <MenuItem dense onClick={onDeleteOpen}>
                        {t('common:buttons.delete')}
                    </MenuItem>
                </Menu>
                <ActionConfirm open={Boolean(isDeleteOpen)} content={t('automation:messages.deleteSource')} onClose={() => setIsDeleteOpen(null)} onConfirm={onDelete} />
			</DialogContent>
		</Dialog>
	)
}
