export const LOCALES = {
	'ar-DZ': 'locale.ar-DZ',
	'es-AR': 'locale.es-AR',
	'en-AU': 'locale.en-AU',
	'de-AT': 'locale.de-AT',
	'ar-BH': 'locale.ar-BH',
	'bn-BD': 'locale.bn-BD',
	'be-BY': 'locale.be-BY',
	'fr-BE': 'locale.fr-BE',
	'es-BO': 'locale.es-BO',
	'sr-BA': 'locale.sr-BA',
	'pt-BR': 'locale.pt-BR',
	'bg-BG': 'locale.bg-BG',
	'en-CA': 'locale.en-CA',
	'es-CL': 'locale.es-CL',
	'zh-CN': 'locale.zh-CN',
	'es-CO': 'locale.es-CO',
	'es-CR': 'locale.es-CR',
	'hr-HR': 'locale.hr-HR',
	'el-CY': 'locale.el-CY',
	'cs-CZ': 'locale.cs-CZ',
	'da-DK': 'locale.da-DK',
	'es-DO': 'locale.es-DO',
	'es-EC': 'locale.es-EC',
	'ar-EG': 'locale.ar-EG',
	'es-SV': 'locale.es-SV',
	'et-EE': 'locale.et-EE',
	'fi-FI': 'locale.fi-FI',
	'fr-FR': 'locale.fr-FR',
	'de-DE': 'locale.de-DE',
	'el-GR': 'locale.el-GR',
	'es-GT': 'locale.es-GT',
	'es-HN': 'locale.es-HN',
	'zh-HK': 'locale.zh-HK',
	'hu-HU': 'locale.hu-HU',
	'is-IS': 'locale.is-IS',
	'hi-IN': 'locale.hi-IN',
	'in-ID': 'locale.in-ID',
	'ar-IQ': 'locale.ar-IQ',
	'en-IE': 'locale.en-IE',
	'iw-IL': 'locale.iw-IL',
	'it-IT': 'locale.it-IT',
	'ja-JP': 'locale.ja-JP',
	'ar-JO': 'locale.ar-JO',
	'ar-KW': 'locale.ar-KW',
	'lv-LV': 'locale.lv-LV',
	'ar-LB': 'locale.ar-LB',
	'ar-LY': 'locale.ar-LY',
	'lt-LT': 'locale.lt-LT',
	'fr-LU': 'locale.fr-LU',
	'mk-MK': 'locale.mk-MK',
	'ms-MY': 'locale.ms-MY',
	'mt-MT': 'locale.mt-MT',
	'es-MX': 'locale.es-MX',
	'sr-ME': 'locale.sr-ME',
	'ar-MA': 'locale.ar-MA',
	'nl-NL': 'locale.nl-NL',
	'en-NZ': 'locale.en-NZ',
	'es-NI': 'locale.es-NI',
	'no-NO': 'locale.no-NO',
	'ar-OM': 'locale.ar-OM',
	'es-PA': 'locale.es-PA',
	'es-PY': 'locale.es-PY',
	'es-PE': 'locale.es-PE',
	'en-PH': 'locale.en-PH',
	'pl-PL': 'locale.pl-PL',
	'pt-PT': 'locale.pt-PT',
	'es-PR': 'locale.es-PR',
	'ar-QA': 'locale.ar-QA',
	'ro-RO': 'locale.ro-RO',
	'ru-RU': 'locale.ru-RU',
	'ar-SA': 'locale.ar-SA',
	'sr-RS': 'locale.sr-RS',
	'en-SG': 'locale.en-SG',
	'sk-SK': 'locale.sk-SK',
	'sl-SI': 'locale.sl-SI',
	'en-ZA': 'locale.en-ZA',
	'ko-KR': 'locale.ko-KR',
	'es-ES': 'locale.es-ES',
	'ar-SD': 'locale.ar-SD',
	'sv-SE': 'locale.sv-SE',
	'fr-CH': 'locale.fr-CH',
	'ar-SY': 'locale.ar-SY',
	'zh-TW': 'locale.zh-TW',
	'th-TH': 'locale.th-TH',
	'ar-TN': 'locale.ar-TN',
	'tr-TR': 'locale.tr-TR',
	'uk-UA': 'locale.uk-UA',
	'ar-AE': 'locale.ar-AE',
	'en-GB': 'locale.en-GB',
	'en-US': 'locale.en-US',
	'es-UY': 'locale.es-UY',
	'es-VE': 'locale.es-VE',
	'vi-VN': 'locale.vi-VN',
	'ar-YE': 'locale.ar-YE'
}

export const LOCALE_DATE_MASK = {
	'sq-AL': { mask: 'dd.MM.yyyy', maskMonth: 'MM.yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd.mm.yyyy' },
	'ar-DZ': { mask: 'dd-MM-yyyy', maskMonth: 'MM-yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd-mm-yyyy' },
	'es-AR': { mask: 'dd/MM/yyyy', maskMonth: 'MM/yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd/mm/yyyy' },
	'en-AU': { mask: 'dd/MM/yyyy', maskMonth: 'MM/yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd/mm/yyyy' },
	'de-AT': { mask: 'dd.MM.yyyy', maskMonth: 'MM.yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd.mm.yyyy' },
	'ar-BH': { mask: 'dd/MM/yyyy', maskMonth: 'MM/yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd/mm/yyyy' },
	'bn-BD': { mask: 'dd/MM/yyyy', maskMonth: 'MM/yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd/mm/yyyy' },
	'be-BY': { mask: 'dd.MM.yyyy', maskMonth: 'MM.yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd.mm.yyyy' },
	'fr-BE': { mask: 'dd-MM-yyyy', maskMonth: 'MM-yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd-mm-yyyy' },
	'es-BO': { mask: 'dd/MM/yyyy', maskMonth: 'MM/yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd/mm/yyyy' },
	'sr-BA': { mask: 'dd.MM.yyyy', maskMonth: 'MM.yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd.mm.yyyy' },
	'pt-BR': { mask: 'dd/MM/yyyy', maskMonth: 'MM/yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd/mm/yyyy' },
	'bg-BG': { mask: 'dd.MM.yyyy', maskMonth: 'MM.yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd.mm.yyyy' },
	'en-CA': { mask: 'yyyy-MM-dd', maskMonth: 'yyyy-MM', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'yyyy-mm-dd' },
	'es-CL': { mask: 'dd-MM-yyyy', maskMonth: 'MM-yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd-mm-yyyy' },
	'zh-CN': { mask: 'yyyy/MM/dd', maskMonth: 'yyyy/MM', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'yyyy/mm/dd' },
	'es-CO': { mask: 'dd/MM/yyyy', maskMonth: 'MM/yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd/mm/yyyy' },
	'es-CR': { mask: 'dd/MM/yyyy', maskMonth: 'MM/yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd/mm/yyyy' },
	'hr-HR': { mask: 'dd.MM.yyyy', maskMonth: 'MM.yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd.mm.yyyy' },
	'el-CY': { mask: 'dd/MM/yyyy', maskMonth: 'MM/yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd/mm/yyyy' },
	'cs-CZ': { mask: 'dd.MM.yyyy', maskMonth: 'MM.yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd.mm.yyyy' },
	'da-DK': { mask: 'dd-MM-yyyy', maskMonth: 'MM-yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd-mm-yyyy' },
	'es-DO': { mask: 'dd/MM/yyyy', maskMonth: 'MM/yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd/mm/yyyy' },
	'es-EC': { mask: 'dd/MM/yyyy', maskMonth: 'MM/yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd/mm/yyyy' },
	'ar-EG': { mask: 'dd/MM/yyyy', maskMonth: 'MM/yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd/mm/yyyy' },
	'es-SV': { mask: 'dd/MM/yyyy', maskMonth: 'MM/yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd/mm/yyyy' },
	'et-EE': { mask: 'dd.MM.yyyy', maskMonth: 'MM.yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd.mm.yyyy' },
	'fi-FI': { mask: 'dd.MM.yyyy', maskMonth: 'MM.yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd.mm.yyyy' },
	'fr-FR': { mask: 'dd/MM/yyyy', maskMonth: 'MM/yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd/mm/yyyy' },
	'de-DE': { mask: 'dd.MM.yyyy', maskMonth: 'MM.yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd.mm.yyyy' },
	'el-GR': { mask: 'dd/MM/yyyy', maskMonth: 'MM/yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd/mm/yyyy' },
	'es-GT': { mask: 'dd/MM/yyyy', maskMonth: 'MM/yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd/mm/yyyy' },
	'es-HN': { mask: 'dd/MM/yyyy', maskMonth: 'MM/yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd/mm/yyyy' },
	'zh-HK': { mask: 'dd/MM/yyyy', maskMonth: 'MM/yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd/mm/yyyy' },
	'hu-HU': { mask: 'yyyy.MM.dd', maskMonth: 'yyyy.MM', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'yyyy.mm.dd' },
	'is-IS': { mask: 'dd.MM.yyyy', maskMonth: 'MM.yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd.mm.yyyy' },
	'hi-IN': { mask: 'dd-MM-yyyy', maskMonth: 'MM-yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd-mm-yyyy' },
	'in-ID': { mask: 'dd/MM/yyyy', maskMonth: 'MM/yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd/mm/yyyy' },
	'ar-IQ': { mask: 'dd/MM/yyyy', maskMonth: 'MM/yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd/mm/yyyy' },
	'en-IE': { mask: 'dd/MM/yyyy', maskMonth: 'MM/yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd/mm/yyyy' },
	'iw-IL': { mask: 'dd/MM/yyyy', maskMonth: 'MM/yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd/mm/yyyy' },
	'it-IT': { mask: 'dd/MM/yyyy', maskMonth: 'MM/yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd/mm/yyyy' },
	'ja-JP': { mask: 'yyyy/MM/dd', maskMonth: 'yyyy/MM', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'yyyy/mm/dd' },
	'ar-JO': { mask: 'dd/MM/yyyy', maskMonth: 'MM/yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd/mm/yyyy' },
	'ar-KW': { mask: 'dd/MM/yyyy', maskMonth: 'MM/yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd/mm/yyyy' },
	'lv-LV': { mask: 'dd.MM.yyyy', maskMonth: 'MM.yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd.mm.yyyy' },
	'ar-LB': { mask: 'dd/MM/yyyy', maskMonth: 'MM/yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd/mm/yyyy' },
	'ar-LY': { mask: 'dd/MM/yyyy', maskMonth: 'MM/yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd/mm/yyyy' },
	'lt-LT': { mask: 'yyyy-MM-dd', maskMonth: 'yyyy-MM', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'yyyy-mm-dd' },
	'fr-LU': { mask: 'dd/MM/yyyy', maskMonth: 'MM/yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd/mm/yyyy' },
	'mk-MK': { mask: 'dd.MM.yyyy', maskMonth: 'MM.yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd.mm.yyyy' },
	'ms-MY': { mask: 'dd/MM/yyyy', maskMonth: 'MM/yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd/mm/yyyy' },
	'mt-MT': { mask: 'dd/MM/yyyy', maskMonth: 'MM/yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd/mm/yyyy' },
	'es-MX': { mask: 'dd/MM/yyyy', maskMonth: 'MM/yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd/mm/yyyy' },
	'sr-ME': { mask: 'dd/MM/yyyy', maskMonth: 'MM/yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd/mm/yyyy' },
	'ar-MA': { mask: 'dd-MM-yyyy', maskMonth: 'MM-yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd-mm-yyyy' },
	'nl-NL': { mask: 'dd-MM-yyyy', maskMonth: 'MM-yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd-mm-yyyy' },
	'en-NZ': { mask: 'dd/MM/yyyy', maskMonth: 'MM/yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd/mm/yyyy' },
	'es-NI': { mask: 'dd/MM/yyyy', maskMonth: 'MM/yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd/mm/yyyy' },
	'no-NO': { mask: 'dd/MM/yyyy', maskMonth: 'MM/yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd/mm/yyyy' },
	'ar-OM': { mask: 'dd/MM/yyyy', maskMonth: 'MM/yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd/mm/yyyy' },
	'es-PA': { mask: 'MM/dd/yyyy', maskMonth: 'MM/yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'mm/dd/yyyy' },
	'es-PY': { mask: 'dd/MM/yyyy', maskMonth: 'MM/yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd/mm/yyyy' },
	'es-PE': { mask: 'dd/MM/yyyy', maskMonth: 'MM/yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd/mm/yyyy' },
	'en-PH': { mask: 'dd/MM/yyyy', maskMonth: 'MM/yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd/mm/yyyy' },
	'pl-PL': { mask: 'dd.MM.yyyy', maskMonth: 'MM.yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd.mm.yyyy' },
	'pt-PT': { mask: 'dd/MM/yyyy', maskMonth: 'MM/yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd/mm/yyyy' },
	'es-PR': { mask: 'MM/dd/yyyy', maskMonth: 'MM/yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'mm/dd/yyyy' },
	'ar-QA': { mask: 'dd/MM/yyyy', maskMonth: 'MM/yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd/mm/yyyy' },
	'ro-RO': { mask: 'dd.MM.yyyy', maskMonth: 'MM.yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd.mm.yyyy' },
	'ru-RU': { mask: 'dd.MM.yyyy', maskMonth: 'MM.yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd.mm.yyyy' },
	'ar-SA': { mask: 'dd/MM/yyyy', maskMonth: 'MM/yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd/mm/yyyy' },
	'sr-RS': { mask: 'dd.MM.yyyy', maskMonth: 'MM.yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd.mm.yyyy' },
	'en-SG': { mask: 'dd/MM/yyyy', maskMonth: 'MM/yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd/mm/yyyy' },
	'sk-SK': { mask: 'dd.MM.yyyy', maskMonth: 'MM.yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd.mm.yyyy' },
	'sl-SI': { mask: 'dd.MM.yyyy', maskMonth: 'MM.yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd.mm.yyyy' },
	'en-ZA': { mask: 'yyyy/MM/dd', maskMonth: 'yyyy/MM', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'yyyy/mm/dd' },
	'ko-KR': { mask: 'yyyy-MM-dd', maskMonth: 'yyyy-MM', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'yyyy-mm-dd' },
	'es-ES': { mask: 'dd/MM/yyyy', maskMonth: 'MM/yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd/mm/yyyy' },
	'ar-SD': { mask: 'dd/MM/yyyy', maskMonth: 'MM/yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd/mm/yyyy' },
	'sv-SE': { mask: 'yyyy-MM-dd', maskMonth: 'yyyy-MM', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'yyyy-mm-dd' },
	'fr-CH': { mask: 'dd.MM.yyyy', maskMonth: 'MM.yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd.mm.yyyy' },
	'ar-SY': { mask: 'dd/MM/yyyy', maskMonth: 'MM/yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd/mm/yyyy' },
	'zh-TW': { mask: 'yyyy/MM/dd', maskMonth: 'yyyy/MM', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'yyyy/mm/dd' },
	'th-TH': { mask: 'dd/MM/yyyy', maskMonth: 'MM/yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd/mm/yyyy' },
	'ar-TN': { mask: 'dd-MM-yyyy', maskMonth: 'MM-yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd-mm-yyyy' },
	'tr-TR': { mask: 'dd.MM.yyyy', maskMonth: 'MM.yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd.mm.yyyy' },
	'uk-UA': { mask: 'dd.MM.yyyy', maskMonth: 'MM.yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd.mm.yyyy' },
	'ar-AE': { mask: 'dd/MM/yyyy', maskMonth: 'MM/yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd/mm/yyyy' },
	'en-GB': { mask: 'dd/MM/yyyy', maskMonth: 'MM/yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd/mm/yyyy' },
	'en-US': { mask: 'MM/dd/yyyy', maskMonth: 'MM/yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'mm/dd/yyyy' },
	'es-UY': { mask: 'dd/MM/yyyy', maskMonth: 'MM/yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd/mm/yyyy' },
	'es-VE': { mask: 'dd/MM/yyyy', maskMonth: 'MM/yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd/mm/yyyy' },
	'vi-VN': { mask: 'dd/MM/yyyy', maskMonth: 'MM/yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd/mm/yyyy' },
	'ar-YE': { mask: 'dd/MM/yyyy', maskMonth: 'MM/yyyy', maskYear: 'yyyy', friendly: 'd MMMM yyyy', friendlyMonth: 'MMMM yyyy', placeholder: 'dd/mm/yyyy' }
}

// 1 is Monday
export const FIRST_WEEKDAY = {
	'sq-AL': 1,
	'ar-DZ': 1,
	'es-AR': 1,
	'en-AU': 1,
	'de-AT': 1,
	'ar-BH': 1,
	'bn-BD': 1,
	'be-BY': 1,
	'fr-BE': 1,
	'es-BO': 1,
	'sr-BA': 1,
	'pt-BR': 1,
	'bg-BG': 1,
	'en-CA': 1,
	'es-CL': 1,
	'zh-CN': 1,
	'es-CO': 1,
	'es-CR': 1,
	'hr-HR': 1,
	'el-CY': 1,
	'cs-CZ': 1,
	'da-DK': 1,
	'es-DO': 1,
	'es-EC': 1,
	'ar-EG': 1,
	'es-SV': 1,
	'et-EE': 1,
	'fi-FI': 1,
	'fr-FR': 1,
	'de-DE': 1,
	'el-GR': 1,
	'es-GT': 1,
	'es-HN': 1,
	'zh-HK': 1,
	'hu-HU': 1,
	'is-IS': 1,
	'hi-IN': 1,
	'in-ID': 1,
	'ar-IQ': 1,
	'en-IE': 1,
	'iw-IL': 1,
	'it-IT': 1,
	'ja-JP': 1,
	'ar-JO': 1,
	'ar-KW': 1,
	'lv-LV': 1,
	'ar-LB': 1,
	'ar-LY': 1,
	'lt-LT': 1,
	'fr-LU': 1,
	'mk-MK': 1,
	'ms-MY': 1,
	'mt-MT': 1,
	'es-MX': 1,
	'sr-ME': 1,
	'ar-MA': 1,
	'nl-NL': 1,
	'en-NZ': 1,
	'es-NI': 1,
	'no-NO': 1,
	'ar-OM': 1,
	'es-PA': 1,
	'es-PY': 1,
	'es-PE': 1,
	'en-PH': 1,
	'pl-PL': 1,
	'pt-PT': 1,
	'es-PR': 1,
	'ar-QA': 1,
	'ro-RO': 1,
	'ru-RU': 1,
	'ar-SA': 1,
	'sr-RS': 1,
	'en-SG': 1,
	'sk-SK': 1,
	'sl-SI': 1,
	'en-ZA': 1,
	'ko-KR': 1,
	'es-ES': 1,
	'ar-SD': 1,
	'sv-SE': 1,
	'fr-CH': 1,
	'ar-SY': 1,
	'zh-TW': 1,
	'th-TH': 1,
	'ar-TN': 1,
	'tr-TR': 1,
	'uk-UA': 1,
	'ar-AE': 1,
	'en-GB': 1,
	'en-US': 1,
	'es-UY': 1,
	'es-VE': 1,
	'vi-VN': 1,
	'ar-YE': 1
}

// The following list needs to be revised, as it seems to have errors (e.g., es-ES)
// export const FIRST_WEEKDAY = {
// 	'sq-AL': 6,
// 	'ar-DZ': 6,
// 	'es-AR': 1,
// 	'en-AU': 0,
// 	'de-AT': 1,
// 	'ar-BH': 6,
// 	'bn-BD': 0,
// 	'be-BY': 0,
// 	'fr-BE': 1,
// 	'es-BO': 1,
// 	'sr-BA': 0,
// 	'pt-BR': 0,
// 	'bg-BG': 0,
// 	'en-CA': 0,
// 	'es-CL': 1,
// 	'zh-CN': 0,
// 	'es-CO': 1,
// 	'es-CR': 1,
// 	'hr-HR': 1,
// 	'el-CY': 1,
// 	'cs-CZ': 1,
// 	'da-DK': 1,
// 	'es-DO': 1,
// 	'es-EC': 1,
// 	'ar-EG': 0,
// 	'es-SV': 1,
// 	'et-EE': 1,
// 	'fi-FI': 1,
// 	'fr-FR': 1,
// 	'de-DE': 1,
// 	'el-GR': 1,
// 	'es-GT': 1,
// 	'es-HN': 1,
// 	'zh-HK': 0,
// 	'hu-HU': 1,
// 	'is-IS': 1,
// 	'hi-IN': 0,
// 	'in-ID': 0,
// 	'ar-IQ': 0,
// 	'en-IE': 1,
// 	'iw-IL': 0,
// 	'it-IT': 1,
// 	'ja-JP': 0,
// 	'ar-JO': 6,
// 	'ar-KW': 6,
// 	'lv-LV': 0,
// 	'ar-LB': 6,
// 	'ar-LY': 6,
// 	'lt-LT': 1,
// 	'fr-LU': 1,
// 	'mk-MK': 0,
// 	'ms-MY': 0,
// 	'mt-MT': 0,
// 	'es-MX': 1,
// 	'sr-ME': 1,
// 	'ar-MA': 6,
// 	'nl-NL': 1,
// 	'en-NZ': 0,
// 	'es-NI': 1,
// 	'no-NO': 1,
// 	'ar-OM': 6,
// 	'es-PA': 1,
// 	'es-PY': 1,
// 	'es-PE': 1,
// 	'en-PH': 0,
// 	'pl-PL': 1,
// 	'pt-PT': 1,
// 	'es-PR': 1,
// 	'ar-QA': 6,
// 	'ro-RO': 1,
// 	'ru-RU': 1,
// 	'ar-SA': 6,
// 	'sr-RS': 0,
// 	'en-SG': 0,
// 	'sk-SK': 1,
// 	'sl-SI': 1,
// 	'en-ZA': 0,
// 	'ko-KR': 0,
// 	'es-ES': 0,
// 	'ar-SD': 6,
// 	'sv-SE': 1,
// 	'fr-CH': 1,
// 	'ar-SY': 6,
// 	'zh-TW': 0,
// 	'th-TH': 0,
// 	'ar-TN': 6,
// 	'tr-TR': 1,
// 	'uk-UA': 1,
// 	'ar-AE': 6,
// 	'en-GB': 1,
// 	'en-US': 0,
// 	'es-UY': 1,
// 	'es-VE': 1,
// 	'vi-VN': 0,
// 	'ar-YE': 6
// }
