// Font
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
// Material UI framework
import { StyledEngineProvider } from '@mui/material/styles'
// Redux libraries and actions
import { Provider } from 'react-redux'
import store from 'common/store/store'
// Navigation
import Router from 'common/navigation/Router'
// Localization
import 'common/config/i18n'
import AlertDialog from 'common/components/alert/AlertDialog'
// SEO headers
import { Helmet } from 'react-helmet'
import { DOMAIN_URL } from 'common/config/domain'

function App() {
	return (
		<Provider store={store}>
			<StyledEngineProvider injectFirst>
				<Helmet>
					<title>Singularly App</title>
					<link rel="canonical" href={`${DOMAIN_URL}`} />
				</Helmet>
				<Router />
				<AlertDialog messageKey="global" />
			</StyledEngineProvider>
		</Provider>
	)
}

export default App
