import { select, put, takeEvery, all, spawn, call } from 'redux-saga/effects'
import * as sagaAction from 'model/saga-actions/modelCategoryActions'
import * as engineMiddlewareActions from 'model/saga-actions/engineMiddlewareActions'
import * as tableSagaAction from 'table/saga-actions/tableActions'
import * as globalSagaAction from 'common/saga-actions/globalActions'
import * as loadHelper from 'common/saga/helpers/loadHelper'
import { deleteField } from 'firebase/firestore'
import { generateKey } from 'common/utils/uuid'
import { throwError } from 'common/config/errors'
import { defaultPath, defaultPathVariables } from 'model/saga/modelSagas'
import { clearVariableBreakdown } from 'model/store/modelDataReducer'

const FILE_NAME = 'modelCategorySagas'

const getVariables = (state) => state.model.variables
const getRelationships = (state) => state.team.teamRelationships?.result
const getCategories = (state) => state.team.teamCategories?.result

function* createCategoryReq() {
	yield takeEvery(sagaAction.CREATE_CATEGORY, createCategory)
}

function* createCategory(triggeredAction) {
	const { tid, teamId, aid, categoryId, content, isNew } = triggeredAction

	try {
		const categories = yield select(getCategories)
		const category = isNew ? null : categories[categoryId]
		const prevTable = category?.selectTable || null
		const newTable = content.selectTable

		// Prepare data
		var newCategories = {}
		const key = isNew ? generateKey(6) : categoryId
		newCategories[key] = content

		// Update backend
		if(isNew) yield put(engineMiddlewareActions.createCategory({ tid, teamId, aid, id: key, categoryId, messageKey: `modelCategory#${aid}#${categoryId}`, catProps: newCategories[key], prevTable, newTable }))
		else yield put(engineMiddlewareActions.updateCategory({ tid, teamId, aid, id: key, categoryId, messageKey: `modelCategory#${aid}#${categoryId}`, catProps: newCategories[key], prevTable, newTable }))
	} catch (err) {
		const content = throwError(err, FILE_NAME, createCategory.name)
		yield put(globalSagaAction.showMessage({ content }))
	}
}

function* deleteCategoryReq() {
	yield takeEvery(sagaAction.DELETE_CATEGORY, deleteCategory)
}

function* deleteCategory(triggeredAction) {
	const { tid, teamId, aid, categoryId, selectTable } = triggeredAction
	try {
		const _variables = yield select(getVariables)
		const variables = _variables && _variables[aid]?.result
		
		// Delete category from variables
		var varsToUpdate = []
		if(variables)
			for(const variable of Object.entries(variables)) {
				const found = variable[1]?.categories?.findIndex((el) => el.id === categoryId)
				if (found >= 0) {
					const varCategories = variable[1]?.categories?.filter((el) => el.id !== categoryId)
					if(varCategories.length === 0) varsToUpdate.push(variable[0])
				}
			}
		// Update backend
		yield all(varsToUpdate.map((variable) => put(clearVariableBreakdown({ id: aid, variableId: variable }))))
		yield put(engineMiddlewareActions.removeCategory({ tid, teamId, aid, id: categoryId, selectTable }))
	} catch (err) {
		const content = throwError(err, FILE_NAME, deleteCategory.name)
		yield put(globalSagaAction.showMessage({ content }))
	}
}

function* createRelationshipReq() {
	yield takeEvery(sagaAction.CREATE_RELATIONSHIP, createRelationship)
}

function* createRelationship(triggeredAction) {
	const { tid, aid, relationId, content, isNew } = triggeredAction

	try {
		const relationships = yield select(getRelationships)
		const relationship = isNew ? null : relationships[relationId]
		const prevTable = relationship?.selectTable || null
		const newTable = content.selectTable

		// Prepare data
		var newRelationships = {}
		const key = isNew ? generateKey(6) : relationId
		newRelationships[key] = content

		// Update backend
		if(isNew) yield put(engineMiddlewareActions.createRelationship({ tid, aid, id: key, messageKey: `modelRelationship#${aid}#${relationId}`, catProps: newRelationships[key], prevTable, newTable, relationId }))
		else yield put(engineMiddlewareActions.updateRelationship({ tid, aid, id: key, messageKey: `modelRelationship#${aid}#${relationId}`, catProps: newRelationships[key], selectTable: prevTable, relationId }))
	} catch (err) {
		const content = throwError(err, FILE_NAME, createRelationship.name)
		yield put(globalSagaAction.showMessage({ content }))
	}
}

function* deleteRelationshipReq() {
	yield takeEvery(sagaAction.DELETE_RELATIONSHIP, deleteRelationship)
}

function* deleteRelationship(triggeredAction) {
	const { tid, aid, relationId, selectTable } = triggeredAction

	try {
		const relationships = yield select(getRelationships)
		// Delete relationship
		var newRelationships = { ...relationships }
		delete newRelationships[relationId]

		// Update backend
		yield put(engineMiddlewareActions.removeRelationship({ tid, aid, id: relationId, selectTable }))
	} catch (err) {
		const content = throwError(err, FILE_NAME, deleteRelationship.name)
		yield put(globalSagaAction.showMessage({ content }))
	}
}

export function* disconnectAllTables(triggeredAction) {
	const { tid, aid } = triggeredAction
	const queryConfigModel = loadHelper.queryConfig({ path: defaultPath(true) })
	const queryConfigVariables = loadHelper.queryConfig({ path: defaultPathVariables(false), returnType: 'map' })
	// Load model
	const model = yield call(loadHelper.loadSynchronous, queryConfigModel, { tid, aid })
	const _variables = yield call(loadHelper.loadSynchronous, queryConfigVariables, { tid, aid })
	const variables = _variables?.result
	const categories = model?.data?.catProps?.categories
	const relationships = model?.data?.catProps?.relationships
	// Identfy tables to disconnect
	const tablesToDisconnect = {}

	categories &&
		Object.values(categories)?.forEach((el) => {
			if (el?.selectTable) tablesToDisconnect[el.selectTable] = true
		})

	relationships &&
		Object.values(relationships)?.forEach((el) => {
			if (el?.selectTable) tablesToDisconnect[el.selectTable] = true
		})

	variables &&
		Object.values(variables)?.forEach((el) => {
			if (el?.sourceProps?.selectTable) tablesToDisconnect[el.sourceProps.selectTable] = true
		})

	// Disconnect tables
	const disconnectIDs = Object.keys(tablesToDisconnect)
	yield all(disconnectIDs.map((id) => put(tableSagaAction.updateTable({ tid, aid: id, content: { [`connectedModels.${aid}`]: deleteField() } }))))
}

export default function* root() {
	// #### MANAGE CHART
	yield spawn(createCategoryReq)
	yield spawn(deleteCategoryReq)
	yield spawn(createRelationshipReq)
	yield spawn(deleteRelationshipReq)
}
