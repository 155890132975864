// #### SUBSCRIBE TO COLLECTION
export const LOAD_CONNECTORS = 'connectorSaga/LOAD_CONNECTORS'
export const CANCEL_CONNECTORS = 'connectorSaga/CANCEL_CONNECTORS'
// #### MANAGE DATASETS
export const ENABLE_CONNECTOR = 'connectorSaga/ENABLE_CONNECTOR'
export const DISABLE_CONNECTOR = 'connectorSaga/DISABLE_CONNECTOR'
export const CREATE_CONNECTOR = 'connectorSaga/CREATE_CONNECTOR'
export const UPDATE_CONNECTOR = 'connectorSaga/UPDATE_CONNECTOR'
export const DELETE_CONNECTOR = 'connectorSaga/DELETE_CONNECTOR'
export const JOBS = 'connectorSaga/JOBS'
export const SYNC = 'connectorSaga/SYNC'
export const REFRESH_SCHEMA = 'connectorSaga/REFRESH_SCHEMA'

// #### SUBSCRIBE TO COLLECTION
export const loadConnectors = ({tid, teamId}) => {
	return { type: LOAD_CONNECTORS, tid, teamId }
}
export const cancelConnectors = () => {
	return { type: CANCEL_CONNECTORS }
}

// #### MANAGE CONNECTORS
export const enableConnector = ({ tid, teamId, connectorId }) => {
	return { type: ENABLE_CONNECTOR, tid, teamId, connectorId }
}
export const disableConnector = ({ tid, teamId, connectorId }) => {
	return { type: DISABLE_CONNECTOR, tid, teamId, connectorId }
}
export const createConnector = ({ tid, teamId, sid, uid, connection }) => {
	return { type: CREATE_CONNECTOR, tid, teamId, sid, uid, connection }
}
export const updateConnector = ({ tid, teamId, cid, uid, connection }) => {
	return { type: UPDATE_CONNECTOR, tid, teamId, cid, uid, connection }
}
export const deleteConnector = ({ tid, teamId, cid }) => {
	return { type: DELETE_CONNECTOR, tid, teamId, cid }
}
export const loadJobs = ({teamId, cid}) => {
	return { type: JOBS, teamId, cid }
}
export const sync = ({teamId, cid}) => {
	return { type: SYNC, teamId, cid }
}
export const refreshSchema = ({ tid, teamId, cid, stream }) => {
	return { type: REFRESH_SCHEMA, tid, teamId, cid, stream }
}
