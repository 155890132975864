import { put, call, takeLatest } from 'redux-saga/effects'
import * as api from 'common/api/helpers/searchApi'
import { replaceParam } from 'common/saga/helpers/utils'
import * as globalSagaAction from 'common/saga-actions/globalActions'
import { throwError } from 'common/config/errors'

// EXTERNAL
export const queryConfig = ({ path, where, orderBy, limit, searchAfter, returnType }) => {
	return {
		path, // path to document or collection
		where, // query filters
		orderBy, // order criteria
		limit, // query limit
		searchAfter, // last doc retrieved (for pagination)
		returnType // map or list
	}
}

export const sagaConfig = ({ loadAction, loadResponse }) => {
	return {
		loadAction, // load action
		loadResponse // reponse to load
	}
}

export const msgConfig = ({ fileName, functionName }) => {
	return {
		fileName,
		functionName
	}
}

export function* search(sagaConfig, queryConfig, msgConfig) {
	yield takeLatest(sagaConfig.loadAction, searchInit, sagaConfig, queryConfig, msgConfig)
}

// INTERNAL
function* searchInit(sagaConfig, queryConfig, msgConfig, triggeredAction) {
	try {
		yield put(sagaConfig.loadResponse({ key: triggeredAction.key, isLoading: true }))

		// Merge global configuration with screen request
		let orderBy = []
		if (queryConfig.orderBy) orderBy = [...orderBy, ...queryConfig.orderBy]
		if (triggeredAction.orderBy) orderBy = [...orderBy, ...triggeredAction.orderBy]

		let where = []
		if (queryConfig.where) where = [...where, ...queryConfig.where]
		if (triggeredAction.where) where = [...where, ...triggeredAction.where]

		// Introduce values into parameters
		var newQueryConfig = {
			...queryConfig,
			path: replaceParam(queryConfig.path, triggeredAction),
			where: replaceParam(where, triggeredAction, true),
			orderBy: orderBy,
			startAfter: triggeredAction.startAfter
		}
		// Execute query
		const result = yield call(api.search, newQueryConfig, msgConfig)
		const isInitial = triggeredAction.startAfter == null
		yield put(sagaConfig.loadResponse({ ...result, isInitial, key: triggeredAction.key, isLoading: false }))
	} catch (err) {
		const content = throwError(err, msgConfig.fileName, msgConfig.functionName)
		yield put(globalSagaAction.showMessage({ content }))
		yield put(sagaConfig.loadResponse({ key: triggeredAction.key, isLoading: false }))
	}
}
