import { CircularProgress, TextField, MenuItem } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { updateUser } from 'common/saga-actions/userActions'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { validateNotEmpty } from 'common/utils/validate'
import { LOCALES } from 'common/constants/locales'

export default function AccountProfile() {
	const dispatch = useDispatch()
	const { t } = useTranslation('common')

	const tid = useSelector((state) => state.auth.tid)
	const uid = useSelector((state) => state.auth.uid)
	const user = useSelector((state) => state.user.user?.data)
	const isLoading = useSelector((state) => state.global.loading?.updateUser)

	// #### DEFAULT VALUES
	const defaultForm = {
		name: '',
		surname: '',
		locale: '',
		bio: '',
		linkedIn: '',
		twitter: ''
	}

	const defaultValidation = {
		name: { valid: true, error: '' },
		surname: { valid: true, error: '' },
		locale: { valid: true, error: '' }
	}

	// #### STATE
	const [form, setForm] = useState(defaultForm)
	const [validation, setValidation] = useState(defaultValidation)

	// #### EFFECTS
	useEffect(() => {
		if (user) setForm({ name: user.name, surname: user.surname, locale: user.locale, bio: user.bio, linkedIn: user.linkedIn, twitter: user.twitter })
		else setForm(defaultForm)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user])

	// #### FUNCTIONS
	function onChange(key, text) {
		setForm({ ...form, [key]: text })
	}

	function onSave() {
		const nameValid = validateNotEmpty(form.name)
		const surnameValid = validateNotEmpty(form.surname)
		const localeValid = validateNotEmpty(form.locale)

		setValidation({
			...validation,
			name: nameValid,
			surname: surnameValid,
			locale: localeValid
		})

		if (nameValid.valid && surnameValid.valid && localeValid.valid) dispatch(updateUser({ tid, uid, content: form }))
	}

	return (
		<div className="flex flex-col grow">
			<span className="font-medium text-sm text-textGray">{t('common:login.email')}</span>
			<TextField
				fullWidth
				variant="outlined"
				margin="dense"
				size="small"
				// label={t('common:login.email')}
				inputProps={{ className: 'text-sm' }}
				value={user?.email}
				onChange={(event) => void 0}
				disabled={true}
			/>

			<span className="mt-2 font-medium text-sm text-textGray">{t('common:signup.first_name')}</span>
			<TextField
				fullWidth
				variant="outlined"
				margin="dense"
				size="small"
				// label={t('common:signup.first_name')}
				inputProps={{ className: 'text-sm', maxLength: 80 }}
				value={form.name}
				onChange={(event) => onChange('name', event.target.value)}
				error={!validation.name.valid}
				helperText={
					!validation.name.valid &&
					t('common:' + validation.name.error, {
						field: t('common:signup.first_name')
					})
				}
			/>

			<span className="mt-2 font-medium text-sm text-textGray">{t('common:signup.last_name')}</span>
			<TextField
				fullWidth
				variant="outlined"
				margin="dense"
				size="small"
				// label={t('common:signup.last_name')}
				inputProps={{ className: 'text-sm', maxLength: 80 }}
				value={form.surname}
				onChange={(event) => onChange('surname', event.target.value)}
				error={!validation.surname.valid}
				helperText={
					!validation.surname.valid &&
					t('common:' + validation.surname.error, {
						field: t('common:signup.last_name')
					})
				}
			/>

			<span className="mt-2 font-medium text-sm text-textGray">{t('common:account.bio')}</span>
			<span className="text-xs text-textGray">
				{t('common:account.bioDesc')}.&nbsp;{t('common:error.maxChars', { num: 200 })}
			</span>
			<TextField
				fullWidth
				variant="outlined"
				margin="dense"
				size="small"
				// label={t('common:signup.last_name')}
				inputProps={{ className: 'text-sm', maxLength: 200 }}
				value={form.bio}
				onChange={(event) => onChange('bio', event.target.value)}
			/>

			<span className="mt-2 font-medium text-sm text-textGray">{t('common:signup.locale')}</span>
			<TextField
				select
				fullWidth
				variant="outlined"
				margin="dense"
				size="small"
				// label={t('common:signup.locale')}
				inputProps={{ className: 'text-sm' }}
				value={form.locale}
				onChange={(event) => onChange('locale', event.target.value)}
				error={!validation.locale.valid}
				helperText={
					!validation.locale.valid &&
					t('common:' + validation.locale.error, {
						field: t('common:signup.locale')
					})
				}
			>
				{Object.keys(LOCALES).map((key) => (
					<MenuItem key={key} value={key}>
						{t('common:' + LOCALES[key])}
					</MenuItem>
				))}
			</TextField>

			<span className="mt-2 font-medium text-sm text-textGray">{t('common:account.linkedIn')}</span>
			<TextField
				fullWidth
				variant="outlined"
				margin="dense"
				size="small"
				inputProps={{ className: 'text-sm' }}
				value={form.linkedIn}
				onChange={(event) => onChange('linkedIn', event.target.value)}
				placeholder="https://"
			/>

			<span className="mt-2 font-medium text-sm text-textGray">{t('common:account.twitter')}</span>
			<TextField
				fullWidth
				variant="outlined"
				margin="dense"
				size="small"
				inputProps={{ className: 'text-sm' }}
				value={form.twitter}
				onChange={(event) => onChange('twitter', event.target.value)}
				placeholder="https://"
			/>

			<div className="mt-4">
				<LoadingButton loading={isLoading} size="small" variant="contained" className="buttonContainedContrast" onClick={onSave} loadingIndicator={<CircularProgress size={20} className="text-white" />}>
					{t('common:buttons.save')}
				</LoadingButton>
			</div>
		</div>
	)
}
