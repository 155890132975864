import { createSlice } from '@reduxjs/toolkit'
import { DEFAULT_THEME } from 'common/constants/themes'

export const globalSlice = createSlice({
	name: 'global',
	initialState: {
		loading: {},
		message: {},
		downloadUrl: {userId: null, signedUrl: null},
		theme: DEFAULT_THEME,
		isMenuOpen: true,
		elementSize: { menu: 250, splitScreen: '50%' },
		systemMessages: {}
	},
	reducers: {
		loadingOn: (state, action) => {
			state.loading[action.payload.key] = action.payload.item ? { ...state.loading[action.payload.key], [action.payload.item]: true } : true
		},
		loadingOff: (state, action) => {
			state.loading[action.payload.key] = action.payload.item ? { ...state.loading[action.payload.key], [action.payload.item]: false } : false
		},
		showMessage: (state, action) => {
			state.message[action.payload.key] = action.payload.item
				? { ...state.message[action.payload.key], [action.payload.item]: { isVisible: true, content: action.payload.content, type: action.payload.messageType } }
				: { isVisible: true, content: action.payload.content, type: action.payload.messageType }
		},
		downloadUrl: (state, action) => {
			state.downloadUrl = {userId: action.payload.userId, signedUrl: action.payload.signedUrl}
		},
		closeMessage: (state, action) => {
			state.message[action.payload.key] = action.payload.item ? { ...state.message[action.payload.key], [action.payload.item]: null } : null
		},
		setTheme: (state, action) => {
			state.theme = action.payload.theme || DEFAULT_THEME
		},
		toggleMenu: (state, action) => {
			state.isMenuOpen = !state.isMenuOpen
		},
		closeMenu: (state, action) => {
			state.isMenuOpen = false
		},
		setElementSize: (state, action) => {
			state.elementSize[action.payload.key] = action.payload.value
		},
		pushSystemMessage: (state, action) => {
			state.systemMessages[action.payload.key] = state.systemMessages[action.payload.key] ? [...state.systemMessages[action.payload.key], action.payload.value] : [action.payload.value]
		},
		popSystemMessage: (state, action) => {
			state.systemMessages[action.payload.key] = state.systemMessages[action.payload.key] ? state.systemMessages[action.payload.key].slice(1) : null
		}
	}
})

export const { loadingOn, loadingOff, showMessage, downloadUrl, closeMessage, setTheme, toggleMenu, closeMenu, setElementSize, pushSystemMessage, popSystemMessage } = globalSlice.actions

// SYSTEM MESSAGES
export const SYSMSG_MODEL_SCROLL_TO_TODAY = 'systemMessage/model/SCROLL_TO_TODAY'

export default globalSlice.reducer
