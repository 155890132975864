import { Autocomplete as AutocompleteLib, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export default function Autocomplete({
	selected, // item (object) that has been selected in the autocomplete previously
	collection, // list to be displayed in the autocomplete
	excludeId, // id that should be excluded from the autocomplete results
	getOptionId, // function that returns the id of the autocomplete option
	getOptionLabel, // function that returns the label of the autocomplete option
	onSelect, // function that is called when an autocomplete option is selected
	validation = { valid: true, error: '' }, // object with validation errors
	fieldName, // name of the field
	showLabel = false, // whether to show the label
	placeholder,
	disabled = false
}) {
	const { t } = useTranslation(['common'])

	// #### STATE
	const [selectedItem, setSelectedItem] = useState(null)
	const [searchTerm, setSearchTerm] = useState('')
	const [searchResults, setSearchResults] = useState([])

	// #### EFFECTS
	// Initialitze selected item
	useEffect(() => {
		setSelectedItem(selected)
	}, [selected])

	// Initialize search results
	useEffect(() => {
		var newResults = collection?.filter((item) => getOptionId(item) !== excludeId) || []
		setSearchResults(newResults)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [collection, selected])

	// #### FUNCTIONS
	function select(e, item) {
		setSelectedItem(item)
		onSelect(item)
	}

	// ###########################
	// #### RENDER
	// ###########################
	return (
		<AutocompleteLib
			options={searchResults}
			getOptionLabel={getOptionLabel}
			disabled={disabled}
			isOptionEqualToValue={(option, value) => getOptionId(option) === getOptionId(value)}
			value={selectedItem || null}
			inputValue={searchTerm}
			onChange={select}
			onInputChange={(e, value) => setSearchTerm(value)}
			size="small"
			renderInput={(params) => (
				<TextField
					{...params}
					label={showLabel ? fieldName : null}
					placeholder={placeholder}
					variant="outlined"
					size="small"
					margin="none"
					fullWidth
					InputProps={{ ...params.InputProps, className: 'text-sm' }}
					InputLabelProps={{ className: 'text-sm' }}
					sx={{ placeholder: 'text-sm' }}
					error={validation && !validation.valid}
					helperText={
						validation &&
						!validation.valid &&
						validation.error &&
						t(validation.error, {
							field: fieldName
						})
					}
				/>
			)}
			renderOption={(props, option, { selected }) => (
				<li {...props} key={getOptionId(option)}>
					{getOptionLabel(option)}
				</li>
			)}
			classes={{ root: 'flex-1', listbox: 'text-sm', noOptions: 'text-sm' }}
			openOnFocus
		/>
	)
}
