import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AddIcon from '@mui/icons-material/Add'
import { IconButton, Collapse, Avatar, CircularProgress, Menu, MenuItem, Tooltip, ListItemIcon, ListItemText } from '@mui/material'
import { useParams, matchPath, useLocation } from 'react-router-dom'
import useNavigate from 'common/hooks/useNavigate'
import clsx from 'clsx'
// import Upgrade from 'common/screens/billing/Upgrade'
import SettingsIcon from '@mui/icons-material/MoreVert'
import { PRODUCTS } from 'common/constants/products'
import { SECTIONS } from 'common/constants/products'
import NewSpace from 'common/screens/space/NewSpace'
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined'
import EditIcon from '@mui/icons-material/EditOutlined'
import ActionConfirm from 'common/components/ActionConfirm'
import { deleteSpace } from 'common/saga-actions/spaceActions'
import { t } from 'i18next'
import { createAsset, deleteAsset } from 'common/saga-actions/assetActions'
import * as assetSel from 'common/store/assetSelector'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { setSpaceOpen } from 'common/store/spaceReducer'
import { WindowSplitRightIcon } from 'common/icons'
import { useSplitWindow } from 'common/hooks/useSplitWindow'
import { useTeamPermits } from 'common/hooks/useTeamPermits'

export default function TeamMenu() {
	const dispatch = useDispatch()
	const { navigate } = useNavigate()
	let { sid } = useParams()
	const { pathname } = useLocation()
	const { canCreate } = useTeamPermits()

	// #### REDUX
	const tid = useSelector((state) => state.auth.tid)
	const uid = useSelector((state) => state.auth.uid)
	const teamId = useSelector((state) => state.team.teamId)
	const team = useSelector((state) => state.team.team)
	const teamNotFound = useSelector((state) => state.team.teams?.notFound)
	//Space
	const spaces = useSelector((state) => state.space.spaces?.result)
	const spaceOpen = useSelector((state) => state.space.spaceOpen)
	const isDeletingSpace = useSelector((state) => state.global.loading?.deleteSpace)
	// Assets
	const assets = useSelector((state) => state.asset.assets?.result)
	const isAssetCreating = useSelector((state) => state.global.loading?.newAsset)
	const isAssetDeleting = useSelector((state) => state.global.loading?.deleteAsset)
	const isAssetUpdating = useSelector((state) => state.global.loading?.updateAsset)
	const assetsByParent = useSelector((state) => assetSel.selectAssetsByParent(state))

	// #### STATE
	// Menu
	const [communityMenu, setCommunityMenu] = useState(null)
	// Space
	const [openSpaceCreate, setOpenSpaceCreate] = useState({ isOpen: false, sid: null })
	const [openSpaceMenu, setOpenSpaceMenu] = useState({ anchor: null, sid: null })
	const [openSpaceDelete, setOpenSpaceDelete] = useState({ isOpen: false, sid: null })
	// Asset
	const [openAssetCreate, setOpenAssetCreate] = useState({ isOpen: false, sid: null })
	const [openAssetUpdate, setOpenAssetUpdate] = useState({ isOpen: false, aid: null })
	const [openAssetMenu, setOpenAssetMenu] = useState({ anchor: null, aid: null, sid: null })
	const [openAssetDelete, setOpenAssetDelete] = useState({ isOpen: false, aid: null, sid: null })

	// #### EFFECTS
	useEffect(() => {
		if (spaces && (!spaceOpen || !spaces[spaceOpen])) {
			// Choose the first space if none was selected or the selected one has been deleted
			const keys = Object.keys(spaces)
			const firstKey = keys?.length > 0 ? keys[0] : null
			dispatch(setSpaceOpen(firstKey))
		} else if (!spaces && spaceOpen) {
			// Space has been delete, nothing else to select
			dispatch(setSpaceOpen(null))
		}
	}, [spaces])

	// #### FUNCTIONS
	// Space
	function onSpaceClick(sid) {
		dispatch(setSpaceOpen(sid))
		navigate(`/team/${teamId}/space/${sid}`)
	}

	function onSpaceOpenClick(e, sid) {
		if (e.preventDefault) e.preventDefault()
		if (e.stopPropagation) e.stopPropagation()
		if (spaceOpen === sid) dispatch(setSpaceOpen(null))
		else dispatch(setSpaceOpen(sid))
	}

	function onSpaceOpenMenu(e, sid) {
		if (e.preventDefault) e.preventDefault()
		if (e.stopPropagation) e.stopPropagation()
		setOpenSpaceMenu({ anchor: e.currentTarget, sid })
	}

	function onSpaceOpenCreate(e, sid) {
		if (e.preventDefault) e.preventDefault()
		if (e.stopPropagation) e.stopPropagation()
		setOpenAssetCreate({ anchor: e.currentTarget, sid })
	}

	function onSpaceDeleteRequest() {
		const sid = openSpaceMenu.sid
		setOpenSpaceMenu({ anchor: null, sid: null })
		setOpenSpaceDelete({ isOpen: true, sid })
	}

	function onSpaceDeleteConfirm() {
		const sid = openSpaceDelete.sid
		dispatch(deleteSpace({ sid }))
		setOpenSpaceDelete({ isOpen: false, sid: null })
	}

	function onSpaceSettingsRequest() {
		const sid = openSpaceMenu.sid
		setOpenSpaceMenu({ anchor: null, sid: null })
		setOpenSpaceCreate({ isOpen: true, sid })
	}

	// Asset
	function onAssetCreate(type) {
		const sid = openAssetCreate.sid
		dispatch(createAsset({ tid, uid, teamId, assetType: type, content: { type, name: t('common:assets.untitled'), sid } }))
		setOpenAssetCreate({ anchor: null, sid: null })
		dispatch(setSpaceOpen(sid))
	}

	function onAssetDeleteRequest() {
		const aid = openAssetMenu.aid
		const sid = openAssetMenu.sid
		setOpenAssetMenu({ anchor: null, aid: null, sid: null })
		setOpenAssetDelete({ isOpen: true, aid, sid })
	}

	function onAssetDeleteConfirm() {
		const aid = openAssetDelete.aid
		const sid = openAssetDelete.sid
		const type = assets[aid]?.type
		dispatch(deleteAsset({ tid, aid, assetType: type }))
		setOpenAssetDelete({ isOpen: false, aid: null, sid: null })
		navigate(`/team/${teamId}/space/${sid}`)
	}

	// General Menu
	function onDataSourcesClick() {
		navigate(`/team/${teamId}/datasources`)
	}

	function onCommunityShareClick() {
		setCommunityMenu(null)
		navigate(`/team/${teamId}/community`)
	}

	if (!team || teamNotFound) return void 0
	return (
		<>
			{/* <Upgrade className="mt-4 mx-4" /> */}

			<div
				className={clsx('flex flex-row items-center px-4 py-1 mt-6 hover:bg-bgGray cursor-pointer', matchPath('/team/:teamId/datasources/*', pathname) && 'bg-bgGray hover:bg-borderGray')}
				onClick={onDataSourcesClick}
			>
				{React.createElement(PRODUCTS.datasource.icon, { className: 'w-[23px] h-[23px] text-textGray' })}
				<div className="flex-1 ml-4 truncate text-sm font-medium text-textGray">{t(PRODUCTS.datasource.namePlural)}</div>
			</div>

			<div
				className={clsx('flex flex-row items-center px-4 py-1 hover:bg-bgGray cursor-pointer', matchPath('/team/:teamId/community/*', pathname) && 'bg-bgGray hover:bg-borderGray')}
				onClick={(e) => setCommunityMenu(e.currentTarget)}
			>
				{React.createElement(SECTIONS.community.icon, { className: 'w-[23px] h-[23px] text-textGray' })}
				<div className="flex-1 ml-4 truncate text-sm font-medium text-textGray">{t(SECTIONS.community.namePlural)}</div>
			</div>

			<div className="group/menu">
				<div className="flex flex-row justify-between items-center px-4 mt-6 mb-2">
					<span className="text-xs text-textGray font-bold uppercase">{t('common:space.spaces')}</span>
					{canCreate && (
						<div className="invisible group-hover/menu:visible">
							<Tooltip title={t('common:space.create')} placement="right">
								<IconButton size="small" onClick={() => setOpenSpaceCreate({ isOpen: true, sid: null })}>
									<AddIcon fontSize="inherit" />
								</IconButton>
							</Tooltip>
						</div>
					)}
				</div>

				{spaces &&
					Object.keys(spaces)?.map((lSid) => {
						const space = spaces[lSid]
						const isLoading = (isDeletingSpace && isDeletingSpace[lSid]) || (isAssetCreating && isAssetCreating[lSid]) || false
						return (
							<React.Fragment key={lSid}>
								<div
									className={clsx('flex flex-row items-center h-[35px] py-1 pl-2 pr-4 hover:bg-bgGray group/space cursor-pointer relative', lSid === sid && 'bg-bgGray hover:bg-borderGray')}
									onClick={() => onSpaceClick(lSid)}
								>
									<IconButton size="small" onClick={(e) => onSpaceOpenClick(e, lSid)}>
										{spaceOpen === lSid ? <ExpandMoreIcon fontSize="inherit" /> : <ChevronRightIcon fontSize="inherit" />}
									</IconButton>
									<Avatar variant="rounded" className={`text-xs w-[20px] h-[20px] my-1 uppercase ${space.theme} bg-[var(--bar-bg)]`}>
										{space.name[0]}
									</Avatar>
									<div className="flex-1 ml-4 truncate text-sm font-medium text-textGray">{space.name}</div>
									{isLoading ? (
										<CircularProgress size={15} className="text-textGray mr-2" />
									) : (
										canCreate && (
											<div className="flex flex-row items-center invisible group-hover/space:visible bg-inherit absolute right-4">
												<Tooltip title={t('common:space.settings')} placement="top">
													<IconButton size="small" onClick={(e) => onSpaceOpenMenu(e, lSid)}>
														<SettingsIcon fontSize="inherit" />
													</IconButton>
												</Tooltip>
												<Tooltip title={t('common:space.add')} placement="right">
													<IconButton size="small" onClick={(e) => onSpaceOpenCreate(e, lSid)}>
														<AddIcon fontSize="inherit" />
													</IconButton>
												</Tooltip>
											</div>
										)
									)}
								</div>
								<Collapse in={spaceOpen === lSid}>
									<AssetItems
										assetsByParent={assetsByParent}
										parentId={lSid}
										setOpenAssetMenu={setOpenAssetMenu}
										isAssetUpdating={isAssetUpdating}
										isAssetDeleting={isAssetDeleting}
										canCreate={canCreate}
									/>
								</Collapse>
							</React.Fragment>
						)
					})}
			</div>

			{/* Community menu */}
			<Menu anchorEl={communityMenu} open={Boolean(communityMenu)} onClose={() => setCommunityMenu(null)}>
				<MenuItem dense>
					<a target="_blank" rel="noreferrer" href="https://singularly.com/templates">
						{t('common:community.exploreTemplates')}
					</a>
				</MenuItem>
				<MenuItem dense onClick={onCommunityShareClick}>
					{t('common:community.shareWithCommunity')}
				</MenuItem>
			</Menu>

			{/* Space create modal */}
			<NewSpace
				isOpen={openSpaceCreate.isOpen}
				onClose={() => setOpenSpaceCreate({ isOpen: false, sid: openSpaceCreate.sid })}
				sid={openSpaceCreate.sid}
				space={openSpaceCreate.sid ? spaces[openSpaceCreate.sid] : null}
			/>
			{/* Space menu */}
			<Menu anchorEl={openSpaceMenu.anchor} open={Boolean(openSpaceMenu.anchor)} onClose={() => setOpenSpaceMenu({ anchor: null, sid: null })}>
				<MenuItem dense onClick={onSpaceSettingsRequest}>
					<ListItemIcon>
						<EditIcon className="text-textGray" />
					</ListItemIcon>
					<ListItemText>{t('common:space.settings')}</ListItemText>
				</MenuItem>
				<MenuItem dense onClick={onSpaceDeleteRequest}>
					<ListItemIcon>
						<DeleteIcon className="text-textGray" />
					</ListItemIcon>
					<ListItemText>{t('common:space.delete')}</ListItemText>
				</MenuItem>
			</Menu>
			{/* Space delete modal */}
			<ActionConfirm
				open={openSpaceDelete.isOpen}
				title={t('common:space.deleteConfirm', { name: (spaces && openSpaceDelete.sid && spaces[openSpaceDelete.sid]?.name) || '' })}
				content={t('common:space.deleteInfo')}
				onClose={() => setOpenSpaceDelete({ isOpen: false, sid: null })}
				onConfirm={onSpaceDeleteConfirm}
			/>
			{/* Asset create menu */}
			<Menu anchorEl={openAssetCreate.anchor} open={Boolean(openAssetCreate.anchor)} onClose={() => setOpenAssetCreate({ anchor: null, sid: null })}>
				{[PRODUCTS.table, PRODUCTS.model].map((product) => (
					<MenuItem key={`createAsset#${product.key}`} dense onClick={(e) => onAssetCreate(product.key)}>
						<ListItemIcon>
							<Avatar className={`h-[30px] w-[30px] mr-4 ${product.theme} bg-[var(--bar-bg)]`} variant="rounded">
								{React.createElement(product.icon, { className: 'w-[20px] h-[20px]' })}
							</Avatar>
						</ListItemIcon>
						<ListItemText className="flex flex-col">
							<div className="font-medium">
								<span>{t('common:assets.new')}&nbsp;</span>
								<span className="lowercase">{t(product.name)}</span>
							</div>
							<div className="text-xs text-textGray">{t(product.description)}</div>
						</ListItemText>
					</MenuItem>
				))}
			</Menu>
			{/* Asset menu */}
			<Menu anchorEl={openAssetMenu.anchor} open={Boolean(openAssetMenu.anchor)} onClose={() => setOpenAssetMenu({ anchor: null, aid: null, sid: null })}>
				<MenuItem dense onClick={onAssetDeleteRequest}>
					<ListItemIcon>
						<DeleteIcon className="text-textGray" />
					</ListItemIcon>
					<ListItemText>{t('common:asset.delete', { type: assets && assets[openAssetMenu.aid]?.type })}</ListItemText>
				</MenuItem>
			</Menu>
			{/* Asset delete modal */}
			<ActionConfirm
				open={openAssetDelete.isOpen}
				title={t('common:asset.deleteConfirm', { name: (assets && openAssetDelete.aid && assets[openAssetDelete.aid]?.name) || '' })}
				content={t('common:asset.deleteInfo')}
				onClose={() => setOpenAssetDelete({ isOpen: false, aid: null, sid: null })}
				onConfirm={onAssetDeleteConfirm}
			/>
		</>
	)
}

function AssetItems({ assetsByParent, parentId, setOpenAssetMenu, isAssetUpdating, isAssetDeleting, canCreate }) {
	const { navigate } = useNavigate()
	const { id: urlAid, type: urlType } = useSplitWindow(0)

	// Page
	function onAssetClick(aid, type) {
		navigate(`/${type}/${aid}`)
	}

	function onSecondaryAssetClick(e, secId, secType) {
		if (e.preventDefault) e.preventDefault()
		if (e.stopPropagation) e.stopPropagation()
		navigate(`/${urlType}/${urlAid}/${secType}/${secId}`)
	}

	function onPageMenuClick(e, aid) {
		if (e.preventDefault) e.preventDefault()
		if (e.stopPropagation) e.stopPropagation()
		setOpenAssetMenu({ anchor: e.currentTarget, aid, sid: parentId })
	}

	return (
		<>
			{assetsByParent &&
				assetsByParent[parentId]?.map((asset) => {
					const aid = asset?.aid
					const type = asset?.type
					const product = type ? PRODUCTS[type] : null
					const isLoading = (isAssetDeleting && isAssetDeleting[aid]) || (isAssetUpdating && isAssetUpdating[aid]) || false
					const isOpen = aid === urlAid
					return (
						<div
							key={aid}
							className={clsx('flex flex-row items-center h-[35px] pl-12 pr-4 py-1 hover:bg-bgGray group/asset cursor-pointer relative', isOpen && 'bg-bgGray hover:bg-borderGray')}
							onClick={() => onAssetClick(aid, type)}
						>
							<Avatar variant="rounded" className={`text-xs w-[20px] h-[20px] uppercase ${product.theme} bg-[var(--bar-bg)]`}>
								{React.createElement(product.icon, { className: 'text-[14px]' })}
							</Avatar>

							<div className="flex-1 ml-4 truncate text-sm font-medium text-textGray">{asset?.name}</div>
							{isLoading ? (
								<CircularProgress size={15} className="text-textGray mr-2" />
							) : (
								canCreate && (
									<div className="flex flex-row items-center invisible group-hover/asset:visible bg-inherit absolute right-4">
										<Tooltip title={t('common:asset.settings', { type })} placement="top" classes={{ tooltip: 'first-letter:capitalize' }}>
											<IconButton size="small" onClick={(e) => onPageMenuClick(e, aid)}>
												<SettingsIcon fontSize="inherit" />
											</IconButton>
										</Tooltip>
										{urlAid && !isOpen && (
											<Tooltip title={t('common:asset.split', { type })} placement="right" classes={{ tooltip: 'first-letter:capitalize' }}>
												<IconButton size="small" onClick={(e) => onSecondaryAssetClick(e, aid, type)}>
													<WindowSplitRightIcon className="w-[15px] h-[15px] inherit" />
												</IconButton>
											</Tooltip>
										)}
									</div>
								)
							)}
						</div>
					)
				})}
		</>
	)
}
