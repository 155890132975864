import { LOCALES, LOCALE_DATE_MASK } from 'common/constants/locales'
import { useSelector } from 'react-redux'

export default function useLocale(customLocale) {
	const userLocale = useSelector((state) => state.user.user?.data.locale)
	const browserLocale = navigator.language || navigator.userLanguage

	const locale = customLocale ? customLocale : userLocale ? userLocale : browserLocale && LOCALES[browserLocale] ? browserLocale : 'en-US'
	const dateMask = LOCALE_DATE_MASK[locale]

	return { locale, dateMask }
}
