import { MenuItem, TextField, FormControlLabel, Switch } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import useLocale from 'common/hooks/useLocale'
import { DateTime } from 'luxon'
import { updateModel } from 'model/saga-actions/modelActions'
import { DATE_FORMATS, TIME_FORMATS } from 'common/constants/formats'
import { LOCALE_DATE_MASK } from 'common/constants/locales'

export default function ModelDatesFormat({ aid }) {
	const dispatch = useDispatch()
	const { t } = useTranslation(['common', 'model'])

	// #### REDUX
	const tid = useSelector((state) => state.auth.tid)
	const typeProps = useSelector((state) => state.model.model[aid]?.data?.modelProps?.typeProps)

	// #### DATE TYPE VARIABLES
	const dateProps = typeProps?.dateFormat ? DATE_FORMATS[typeProps.dateFormat] : null
	const defaultLocale = useLocale()

	// #### FUNCTIONS
	function onChange(key, value) {
		let content = {}
		if (key === 'highlightPeriods') content = { ['modelProps.typeProps.highlightPeriodsNum']: 0, [`modelProps.typeProps.${key}`]: value }
		else if (key === 'highlightPeriodsNum' && parseInt(value) >= 0) content = { [`modelProps.typeProps.${key}`]: parseInt(value) }
		else content = { [`modelProps.typeProps.${key}`]: value }
		dispatch(updateModel({ tid, aid, content }))
	}

	return (
		<>
			<div className="font-medium text-sm text-textGray mt-4 mb-2">{t('model:dates.dateFormat')}</div>
			<TextField
				select
				fullWidth
				variant="outlined"
				margin="dense"
				size="small"
				value={typeProps?.dateFormat}
				onChange={(e) => onChange('dateFormat', e.target.value)}
				inputProps={{ className: 'text-sm' }}
			>
				{Object.keys(DATE_FORMATS).map((key) => {
					const dateFormat = DATE_FORMATS[key]
					const locale = dateFormat.locale || defaultLocale.locale
					const mask = LOCALE_DATE_MASK[locale].mask
					const dateStr = DateTime.now().setLocale(locale).toFormat(mask)
					return (
						<MenuItem key={key} value={key} className="text-sm">
							{t(dateFormat.i18n, { date: dateStr })}
						</MenuItem>
					)
				})}
			</TextField>
			<FormControlLabel
				control={<Switch checked={typeProps?.friendlyDate} onChange={(e) => onChange('friendlyDate', e.target.checked)} color="primary" size="small" />}
				label={t('table:typeSettings.friendlyDate', {
					date: DateTime.now()
						.setLocale(dateProps?.locale || defaultLocale.locale)
						.toFormat('dd MMMM yyyy')
				})}
				classes={{ root: 'mt-4', label: 'ml-2 text-sm font-medium text-textGray' }}
			/>
			<FormControlLabel
				control={<Switch checked={typeProps?.includeWeekNumber} onChange={(e) => onChange('includeWeekNumber', e.target.checked)} color="primary" size="small" />}
				label={t('table:typeSettings.includeWeekNumber')}
				classes={{ root: 'mt-4', label: 'ml-2 text-sm font-medium text-textGray' }}
			/>
			{typeProps?.includeTime && (
				<>
					<div className="font-medium text-sm text-textGray mt-4 mb-2">{t('model:dates.timeFormat')}</div>
					<TextField
						select
						fullWidth
						variant="outlined"
						margin="dense"
						size="small"
						value={typeProps?.timeFormat}
						onChange={(event) => onChange('timeFormat', event.target.value)}
						inputProps={{ className: 'text-sm' }}
					>
						{Object.keys(TIME_FORMATS).map((key) => {
							const timeFormat = TIME_FORMATS[key]
							const locale = defaultLocale.locale
							const mask = timeFormat.mask
							const timeStr = DateTime.now().setLocale(locale).toFormat(mask)
							return (
								<MenuItem key={key} value={key} className="text-sm">
									{t(TIME_FORMATS[key].i18n, { time: timeStr })}
								</MenuItem>
							)
						})}
					</TextField>
				</>
			)}
			<FormControlLabel
				control={<Switch checked={typeProps?.highlightPeriods} onChange={(e) => onChange('highlightPeriods', e.target.checked)} color="primary" size="small" />}
				label={t('table:typeSettings.highlightPeriods')}
				classes={{ root: 'mt-4', label: 'ml-2 text-sm font-medium text-textGray' }}
			/>

			{typeProps?.highlightPeriods && (
				<>
					<div className="font-medium text-sm text-textGray mt-4 mb-2">{t('table:typeSettings.numberPeriods')}</div>
					<TextField
						fullWidth
						type="number"
						variant="outlined"
						margin="dense"
						size="small"
						value={typeProps?.highlightPeriodsNum}
						onChange={(e) => onChange('highlightPeriodsNum', e.target.value)}
						inputProps={{ className: 'text-sm' }}
					/>
				</>
			)}
		</>
	)
}
