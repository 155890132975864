import { forwardRef, memo } from 'react'
import FormulaCell from 'common/components/formula/FormulaCell'
import { FormulaIcon } from 'common/icons/index'
import clsx from 'clsx'

export default memo(
	forwardRef(({ isDisabled, focus, defaultRowHeight, ...props }, ref) => {
		return (
			<div ref={ref} className={clsx('relative flex items-center pl-8 pr-4 border-t border-borderGray z-10', focus && 'shadow-md')} style={{ height: defaultRowHeight }}>
				<FormulaCell {...props} isDisabled={isDisabled} focus={focus} />
				{!isDisabled && (
					<div className="absolute left-4 top-0 bottom-0 flex items-center ">
						<FormulaIcon className="text-textGray w-[15px] h-[15px]" />
					</div>
				)}
			</div>
		)
	})
)
