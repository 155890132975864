import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Layout from 'common/screens/layout/Layout'
import TeamMenu from 'common/screens/team/TeamMenu'
import AddIcon from '@mui/icons-material/Add'
import Connectors from 'automation/screens/Connectors'
import { FILES, CONNECTORS } from 'automation/constants/datasources'
import NewConnector from 'automation/screens/new/NewConnector'
import { Tabs, Tab } from '@mui/material'
import FileUploads from 'automation/screens/FileUploads'
import NewFile from 'automation/screens/new/NewFile'
import GenericBar from 'common/screens/layout/GenericBar'
import { useTeamPermits } from 'common/hooks/useTeamPermits'
import { PRODUCTS } from 'common/constants/products'

export default function DataSources() {
	const { t } = useTranslation(['common', 'automation'])
	const { canAdmin, canCreate } = useTeamPermits()

	// #### STATE
	const [createModal, setCreateModal] = useState({ isOpen: false, connector: null })
	const [tab, setTab] = useState(0)

	// #### FUNCTIONS
	function onCreateConnector(connector) {
		setCreateModal({ isOpen: true, connector })
	}

	return (
		<Layout menuContent={TeamMenu} barContent={GenericBar}>
			{/* <Breadcrumbs /> */}

			<div className="mx-4 my-6 mb-8 text-3xl">{t(PRODUCTS.datasource.namePlural)}</div>

			{/* Create new connector */}
			{(canAdmin || canCreate) && (
				<div className="mx-4 mb-8 grid grid-cols-smallCards gap-x-[32px] gap-y-[16px] items-end">
					{(canAdmin || canCreate) &&
						Object.values({ ...FILES }).map((connector) => {
							return (
								<div key={connector.key}>
									<div className="mb-2 text-xs text-textGray uppercase">{connector.header && t(connector.header)}</div>
									<div className="h-[50px] flex flex-row items-center paper cursor-pointer p-2 hover:bg-bgGray overflow-hidden relative group" onClick={() => onCreateConnector(connector.key)}>
										{React.createElement(connector.icon, { width: '30px', height: '30px', className: 'mr-3 text-[20px]' })}

										<div>
											<div className="text-sm font-medium truncate">{t(connector.name)}</div>
											{connector.manufacturer && <div className="text-xs text-textGray leading-none truncate">{connector.manufacturer}</div>}
										</div>
										<AddIcon size="small" className="text-textGray bg-bgGray absolute right-0 mr-2 hidden group-hover:block" />
									</div>
								</div>
							)
						})}

					{canAdmin &&
						Object.values({ ...CONNECTORS }).map((connector) => {
							return (
								<div key={connector.key}>
									<div className="mb-2 text-xs text-textGray uppercase">{connector.header && t(connector.header)}</div>
									<div className="h-[50px] flex flex-row items-center paper cursor-pointer p-2 hover:bg-bgGray overflow-hidden relative group" onClick={() => onCreateConnector(connector.key)}>
										{React.createElement(connector.icon, { width: '30px', height: '30px', className: 'mr-3 text-[20px]' })}

										<div>
											<div className="text-sm font-medium truncate">{t(connector.name)}</div>
											{connector.manufacturer && <div className="text-xs text-textGray leading-none truncate">{connector.manufacturer}</div>}
										</div>
										<AddIcon size="small" className="text-textGray bg-bgGray absolute right-0 mr-2 hidden group-hover:block" />
									</div>
								</div>
							)
						})}

					{/* {Object.values({ ...FILES, ...CONNECTORS }).map((connector) => {
						return (
							<div key={connector.key}>
								<div className="mb-2 text-xs text-textGray uppercase">{connector.header && t(connector.header)}</div>
								<div className="h-[50px] flex flex-row items-center paper cursor-pointer p-2 hover:bg-bgGray overflow-hidden relative group" onClick={() => onCreateConnector(connector.key)}>
									{React.createElement(connector.icon, { width: '30px', height: '30px', className: 'mr-3 text-[20px]' })}

									<div>
										<div className="text-sm font-medium truncate">{t(connector.name)}</div>
										{connector.manufacturer && <div className="text-xs text-textGray leading-none truncate">{connector.manufacturer}</div>}
									</div>
									<AddIcon size="small" className="text-textGray bg-bgGray absolute right-0 mr-2 hidden group-hover:block" />
								</div>
							</div>
						)
					})} */}
				</div>
			)}

			{/* Connectors */}
			<div>
				<Tabs value={tab} onChange={(e, value) => setTab(value)} className="tabs mx-4 mb-8" classes={{ indicator: 'tabsIndicator' }}>
					<Tab label={t('automation:datasources.fileUploads')} className="tab" classes={{ selected: 'tabSelected' }} />
					<Tab label={t('automation:datasources.connectors')} className="tab" classes={{ selected: 'tabSelected' }} />
				</Tabs>
				{tab === 0 ? <FileUploads /> : <Connectors />}
				{createModal?.connector === 'csv' && <NewFile isOpen={createModal?.isOpen || false} onClose={() => setCreateModal({ isOpen: false, connector: null })} />}

				{createModal?.connector !== null && createModal?.connector !== 'csv' && (
					<NewConnector isOpen={createModal?.isOpen || false} onClose={() => setCreateModal({ isOpen: false, connector: null })} connector={createModal?.connector} />
				)}
			</div>
		</Layout>
	)
}
