import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { CircularProgress, MenuItem, TextField, IconButton, Menu, Avatar } from '@mui/material'
import { sortArrayOfObjects, filterArrayOfObjects } from 'common/utils/arrays'
import MoreIcon from '@mui/icons-material/MoreVert'
import ActionConfirm from 'common/components/ActionConfirm'
import { CONNECTORS } from 'automation/constants/datasources'
import { cancelConnectors, loadConnectors, deleteConnector, sync } from 'automation/saga-actions/connectorActions'
import NewConnector from './new/NewConnector'
import Logs from './Logs'
import { DateTime } from 'luxon'
import useLocale from 'common/hooks/useLocale'
import { useTeamPermits } from 'common/hooks/useTeamPermits'

export default function Connectors() {
	const { t } = useTranslation('common')
	const dispatch = useDispatch()
	const { locale } = useLocale()
	let { teamId } = useParams()
	const { canAdmin } = useTeamPermits()

	// #### REDUX
	const tid = useSelector((state) => state.auth.tid)
	const teamUsers = useSelector((state) => state.team.teamUsers?.result)
	const isDeleting = useSelector((state) => state.global.loading?.deleteConnector)
	const connectors = useSelector((state) => state.connector.connectors?.result)
	const isLoaded = useSelector((state) => state.connector.connectors?.loaded)
	const assets = useSelector((state) => state.asset.assets?.result)

	// #### STATE
	const [filter, setFilter] = useState('all')
	const [sort, setSort] = useState({ select: 'updatedDesc', by: 'updatedAt', order: 'desc' })
	const [connectorMenu, setConnectorMenu] = useState({ anchor: null, cid: null })
	const [isDeleteOpen, setIsDeleteOpen] = useState(null)
	const [isSyncOpen, setIsSyncOpen] = useState(null)
	const [isLogsOpen, setIsLogsOpen] = useState(false)
	const [createModal, setCreateModal] = useState({ isOpen: false, type: null })
	const [connector, setConnector] = useState(null)
	const [connectionId, setConnectionId] = useState(null)

	// #### EFFECTS
	// Load connectors
	useEffect(() => {
		dispatch(loadConnectors({ tid, teamId }))
		return () => {
			dispatch(cancelConnectors())
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// #### FUNCTIONS
	function onOpenConnector(connector) {
		if (!canAdmin) return
		setConnector(connector)
		setCreateModal({ isOpen: true, type: connector.connector })
	}

	function onCloseConnector() {
		setCreateModal({ isOpen: false, type: null })
		setConnector(null)
	}

	function onSort(value) {
		if (value === 'nameAsc') setSort({ select: value, by: 'name', order: 'asc' })
		else if (value === 'nameDesc') setSort({ select: value, by: 'name', order: 'desc' })
		else if (value === 'updatedAsc') setSort({ select: value, by: 'updatedAt', order: 'asc' })
		else if (value === 'updatedDesc') setSort({ select: value, by: 'updatedAt', order: 'desc' })
	}

	function onOpenConnectorMenu(e, cid) {
		if (e.preventDefault) e.preventDefault()
		if (e.stopPropagation) e.stopPropagation()
		setConnectorMenu({ anchor: e.currentTarget, cid })
	}

	function onDeleteOpen() {
		setIsDeleteOpen(connectorMenu.cid)
		setConnectorMenu(null)
	}

	function onDeleteConnector() {
		dispatch(deleteConnector({ tid, teamId, cid: isDeleteOpen }))
		setIsDeleteOpen(null)
	}

	function onSyncOpen() {
		setIsSyncOpen(connectors[connectorMenu?.cid]?.connectorProps?.connection?.connectionId)
		setConnectorMenu(null)
	}

	function onSyncConnector() {
		dispatch(sync({ tid, teamId, cid: isSyncOpen }))
		setIsDeleteOpen(null)
		setIsSyncOpen(null)
	}

	function onLogsOpen() {
		setIsLogsOpen(true)
		setConnectionId(connectors[connectorMenu?.cid]?.connectorProps?.connection?.connectionId)
	}
	function onLogsClose() {
		setIsLogsOpen(false)
		setConnectorMenu(null)
		setConnectionId(null)
	}

	return (
		<>
			{/* Filter and sort assets */}
			<div className="m-4 flex flex-row items-center justify-between">
				<div className="flex flex-row items-center">
					<span className="text-sm text-textGray font-light">{t('common:assets.filter')}:&nbsp;&nbsp;</span>
					<TextField size="small" select variant="standard" value={filter} InputProps={{ disableUnderline: true, classes: { input: 'p-0 text-sm' } }} onChange={(e) => setFilter(e.target.value)}>
						<MenuItem value="all" className="text-sm">
							{t('common:assets.all')}
						</MenuItem>
						{Object.values(CONNECTORS).map((connector) => (
							<MenuItem key={`filter#${connector.key}`} value={connector.key} className="text-sm">
								{t(connector.name)}
							</MenuItem>
						))}
					</TextField>
				</div>
				<div className="flex flex-row items-center">
					<span className="text-sm text-textGray font-light">{t('common:assets.sort')}:&nbsp;&nbsp;</span>
					<TextField size="small" select variant="standard" value={sort.select} InputProps={{ disableUnderline: true, classes: { input: 'p-0 text-sm' } }} onChange={(e) => onSort(e.target.value)}>
						<MenuItem value="nameAsc" className="text-sm">
							{t('common:sort.nameAsc')}
						</MenuItem>
						<MenuItem value="nameDesc" className="text-sm">
							{t('common:sort.nameDesc')}
						</MenuItem>
						<MenuItem value="updatedAsc" className="text-sm">
							{t('common:sort.updatedAsc')}
						</MenuItem>
						<MenuItem value="updatedDesc" className="text-sm">
							{t('common:sort.updatedDesc')}
						</MenuItem>
					</TextField>
				</div>
			</div>
			{/* List connectors */}
			<div className="m-4 mt-4 grid gap-[32px] grid-cols-smallCards">
				{!isLoaded &&
					[...Array(8)].map((el, i) => (
						<div key={`skeletton#${i}`} className="paper animate-pulse col-span-full p-4">
							<Avatar className="h-[30px] w-[30px] mr-4 bg-bgGray" variant="rounded">
								&nbsp;
							</Avatar>
						</div>
					))}
				{connectors &&
					sortArrayOfObjects(
						filterArrayOfObjects(Object.keys(connectors), filter === 'all' ? '' : filter, (cid) => connectors[cid].type || ''),
						(cid) => connectors[cid][sort.by] || '',
						sort.order === 'asc' ? true : false,
						sort.by === 'name' ? true : false
					).map((cid) => {
						const connector = connectors[cid]
						const connectorDef = connector.connector ? CONNECTORS[connector.connector] : null
						const sync = connector.updatedAt ? DateTime.fromSeconds(connector.updatedAt.seconds).setLocale(locale).toFormat('f') : null
						const updatedBy = connector.updatedBy && teamUsers && teamUsers[connector.updatedBy]
						const updatedByStr = updatedBy ? `${updatedBy.name} ${updatedBy.surname}` : null
						const isConDeleting = (isDeleting && isDeleting[cid]) || false
						const assetName = assets && assets[connector.destinationProps.selectTable]?.name
						return (
							<div key={cid} className="col-span-full paper cursor-pointer hover:bg-bgGray p-4 flex flex-row items-center justify-between" onClick={() => onOpenConnector(connector)}>
								<div className="flex flex-row items-center flex-1">
									{React.createElement(connectorDef.icon, { width: '30px', height: '30px', className: 'text-[20px] mr-4' })}
									<div>
										<div className="text-sm font-medium truncate">{connector.name}</div>
										<div className="text-xs text-textGray leading-none truncate">
											{connectorDef.manufacturer && connectorDef.manufacturer + ' '}
											{t(connectorDef.name)}
											&nbsp;&gt;&nbsp;
											{assetName}
										</div>
									</div>
								</div>
								<div className="flex">
									<div className="flex-1">
										<div className="text-xs truncate">{sync && t('common:assets.sync', { date: sync })}</div>
										<div className="text-xs text-textGray truncate">{updatedByStr && t('common:assets.by', { person: updatedByStr })}</div>
									</div>
								</div>

								{isConDeleting ? (
									<CircularProgress size={20} className="text-textGray mr-2" />
								) : (
									canAdmin && (
										<IconButton size="small" onClick={(e) => onOpenConnectorMenu(e, cid)}>
											<MoreIcon className="text-xl" />
										</IconButton>
									)
								)}
							</div>
						)
					})}
			</div>
			<Menu anchorEl={connectorMenu?.anchor} keepMounted open={Boolean(connectorMenu?.anchor)} onClose={() => setConnectorMenu(null)}>
				<MenuItem dense onClick={onSyncOpen}>
					{t('common:buttons.sync')}
				</MenuItem>
				<MenuItem dense onClick={onLogsOpen}>
					{t('common:buttons.logs')}
				</MenuItem>
				<MenuItem dense onClick={onDeleteOpen}>
					{t('common:buttons.delete')}
				</MenuItem>
			</Menu>
			{isLogsOpen && <Logs connectionId={connectionId} isOpen={isLogsOpen} onClose={() => onLogsClose()} />}
			<ActionConfirm open={Boolean(isSyncOpen)} content={t('automation:messages.sync')} onClose={() => setIsSyncOpen(null)} onConfirm={onSyncConnector} confirmButtonLabel={t('common:buttons.sync')} />
			<ActionConfirm open={Boolean(isDeleteOpen)} content={t('common:messages.deleteConfirm')} onClose={() => setIsDeleteOpen(null)} onConfirm={onDeleteConnector} />
			{createModal?.isOpen && <NewConnector connection={connector} isOpen={createModal?.isOpen || false} onClose={() => onCloseConnector()} connector={createModal?.type} />}
		</>
	)
}
