import i18n from 'common/config/i18n'

// he metido offsets para formulas. Tienes time t (-t, +2t, t+3, ...), month m (-m, m+3, ...), y year y (-2y, y+2, ...)
// freq	valid offsets   invalid offsets
// 	day	    t, m, y
// 	week	t, y		    m
// 	month	t, m, y
// 	year	t, y		    m

export const FUNC_VAR_TYPES = {
	function: { key: 'function' },
	constant: { key: 'constant' },
	variable: { key: 'variable' },
	attribute: { key: 'attribute' }
}

export const DELETED_VARIABLE = { id: 'deleted', label: i18n.t('formula:general.deletedVar') }
export const DELETED_ATTRIBUTE = { id: 'deleted', label: i18n.t('formula:general.deletedAttr') }
export const FORMULA_VARIABLE = { detail: i18n.t('formula:general.variable'), type: FUNC_VAR_TYPES.variable.key }
export const FORMULA_ATTRIBUTE = { detail: i18n.t('formula:general.attribute'), type: FUNC_VAR_TYPES.attribute.key }

export const FUNCTIONS = [
	{
		key: 'abs',
		label: 'abs',
		snippet: 'abs(#{1})',
		detail: i18n.t('formula:function.abs'),
		example: 'abs(-10)',
		type: FUNC_VAR_TYPES.function.key,
		args: [{ label: 'value', detail: i18n.t('formula:functionArgs.abs') }]
	},
	{
		key: 'exp',
		label: 'exp',
		snippet: 'exp(#{1})',
		detail: i18n.t('formula:function.exp'),
		example: 'exp(10)',
		type: FUNC_VAR_TYPES.function.key,
		args: [{ label: 'exponent', detail: i18n.t('formula:functionArgs.exp') }]
	},
	{
		key: 'mod',
		label: 'mod',
		snippet: 'mod(#{1}; #{2})',
		detail: i18n.t('formula:function.mod'),
		example: 'mod(5; 2)',
		type: FUNC_VAR_TYPES.function.key,
		args: [
			{ label: 'dividend', detail: i18n.t('formula:functionArgs.mod1') },
			{ label: 'divisor', detail: i18n.t('formula:functionArgs.mod2') }
		]
	},
	{
		key: 'power',
		label: 'power',
		snippet: 'power(#{1}; #{2})',
		detail: i18n.t('formula:function.power'),
		example: 'power(10; 2)',
		type: FUNC_VAR_TYPES.function.key,
		args: [
			{ label: 'base', detail: i18n.t('formula:functionArgs.power1') },
			{ label: 'exponent', detail: i18n.t('formula:functionArgs.power2') }
		]
	},
	{
		key: 'log',
		label: 'log',
		snippet: 'log(#{1}; #{2})',
		detail: i18n.t('formula:function.log'),
		example: 'log(2; 10)',
		type: FUNC_VAR_TYPES.function.key,
		args: [
			{ label: 'value', detail: i18n.t('formula:functionArgs.log1') },
			{ label: 'base', detail: i18n.t('formula:functionArgs.log2'), isOptional: true }
		]
	},
	{
		key: 'ln',
		label: 'ln',
		snippet: 'ln(#{1})',
		detail: i18n.t('formula:function.ln'),
		example: 'ln(10)',
		type: FUNC_VAR_TYPES.function.key,
		args: [{ label: 'value', detail: i18n.t('formula:functionArgs.ln') }]
	},
	{
		key: 'round',
		label: 'round',
		snippet: 'round(#{1}; #{2})',
		detail: i18n.t('formula:function.round'),
		example: 'round(3.14; 1)',
		type: FUNC_VAR_TYPES.function.key,
		args: [
			{ label: 'value', detail: i18n.t('formula:functionArgs.round1') },
			{ label: 'decimal_places', detail: i18n.t('formula:functionArgs.round2'), isOptional: true }
		]
	},
	{
		key: 'ceil',
		label: 'ceil',
		snippet: 'ceil(#{1})',
		detail: i18n.t('formula:function.ceil'),
		example: 'ceil(3.14)',
		type: FUNC_VAR_TYPES.function.key,
		args: [
			{ label: 'value', detail: i18n.t('formula:functionArgs.ceil') },
			{ label: 'multiple', detail: i18n.t('formula:functionArgs.multiple'), isOptional: true }
		]
	},
	{
		key: 'floor',
		label: 'floor',
		snippet: 'floor(#{1})',
		detail: i18n.t('formula:function.floor'),
		example: 'floor(3.14)',
		type: FUNC_VAR_TYPES.function.key,
		args: [
			{ label: 'value', detail: i18n.t('formula:functionArgs.floor') },
			{ label: 'multiple', detail: i18n.t('formula:functionArgs.multiple'), isOptional: true }
		]
	},
	{
		key: 'mRound',
		label: 'mRound',
		snippet: 'mRound(#{1}, #{2})',
		detail: i18n.t('formula:function.mRound'),
		example: 'mRound(3.14, 2)',
		type: FUNC_VAR_TYPES.function.key,
		args: [
			{ label: 'value', detail: i18n.t('formula:functionArgs.mRound') },
			{ label: 'multiple', detail: i18n.t('formula:functionArgs.multiple')}
		]
	},
	{
		key: 'min',
		label: 'min',
		snippet: 'min(#{1}; #{2})',
		detail: i18n.t('formula:function.min'),
		example: 'min(Sales; 100)',
		type: FUNC_VAR_TYPES.function.key,
		args: [
			{ label: 'value1', detail: i18n.t('formula:functionArgs.min1') },
			{ label: 'value2', detail: i18n.t('formula:functionArgs.min2'), isUnbounded: true, isOptional: true }
		]
	},
	{
		key: 'max',
		label: 'max',
		snippet: 'max(#{1}; #{2})',
		detail: i18n.t('formula:function.max'),
		example: 'max(Sales; 100)',
		type: FUNC_VAR_TYPES.function.key,
		args: [
			{ label: 'value1', detail: i18n.t('formula:functionArgs.max1') },
			{ label: 'value2', detail: i18n.t('formula:functionArgs.max2'), isUnbounded: true, isOptional: true }
		]
	},
	{
		key: 'sum',
		label: 'sum',
		snippet: 'sum(#{1}; #{2})',
		detail: i18n.t('formula:function.sum'),
		example: 'sum(Sales; 100)',
		type: FUNC_VAR_TYPES.function.key,
		args: [
			{ label: 'value1', detail: i18n.t('formula:functionArgs.sum1') },
			{ label: 'value2', detail: i18n.t('formula:functionArgs.sum2'), isUnbounded: true, isOptional: true }
		]
	},
	{
		key: 'avg',
		label: 'avg',
		snippet: 'avg(#{1}; #{2})',
		detail: i18n.t('formula:function.avg'),
		example: 'avg(Sales; 100)',
		type: FUNC_VAR_TYPES.function.key,
		args: [
			{ label: 'value1', detail: i18n.t('formula:functionArgs.avg1') },
			{ label: 'value2', detail: i18n.t('formula:functionArgs.avg2'), isUnbounded: true, isOptional: true }
		]
	},
	{
		key: 'std',
		label: 'std',
		snippet: 'std(#{1})',
		detail: i18n.t('formula:function.std'),
		example: 'std(Sales)',
		type: FUNC_VAR_TYPES.function.key,
		args: [{ label: 'variable', detail: i18n.t('formula:functionArgs.std') }]
	},
	{
		key: 'sqrt',
		label: 'sqrt',
		snippet: 'sqrt(#{1})',
		detail: i18n.t('formula:function.sqrt'),
		example: 'sqrt(25)',
		type: FUNC_VAR_TYPES.function.key,
		args: [{ label: 'value', detail: i18n.t('formula:functionArgs.sqrt') }]
	},
	{
		key: 'forwardFill',
		label: 'forwardFill',
		snippet: 'forwardFill(#{1})',
		detail: i18n.t('formula:function.forwardFill'),
		example: 'forwardFill(Sales)',
		type: FUNC_VAR_TYPES.function.key,
		args: [{ label: 'variable', detail: i18n.t('formula:functionArgs.fill') }]
	},
	{
		key: 'backFill',
		label: 'backFill',
		snippet: 'backFill(#{1})',
		detail: i18n.t('formula:function.backFill'),
		example: 'backFill(Sales)',
		type: FUNC_VAR_TYPES.function.key,
		args: [{ label: 'variable', detail: i18n.t('formula:functionArgs.fill') }]
	},
	{
		key: 'timeShiftSum',
		label: 'timeShiftSum',
		snippet: 'timeShiftSum(#{1}; #{2})',
		detail: i18n.t('formula:function.timeShiftSum'),
		example: 'timeShiftSum(Sales)',
		type: FUNC_VAR_TYPES.function.key,
		args: [
			{ label: 'variable', detail: i18n.t('formula:functionArgs.timeShift1') },
			{ label: 'variable', detail: i18n.t('formula:functionArgs.timeShift2') }
		]
	},
	{
		key: 'timeShiftMin',
		label: 'timeShiftMin',
		snippet: 'timeShiftMin(#{1}; #{2})',
		detail: i18n.t('formula:function.timeShiftMin'),
		example: 'timeShiftMin(Sales)',
		type: FUNC_VAR_TYPES.function.key,
		args: [
			{ label: 'variable', detail: i18n.t('formula:functionArgs.timeShift1') },
			{ label: 'variable', detail: i18n.t('formula:functionArgs.timeShift2') }
		]
	},
	{
		key: 'timeShiftMax',
		label: 'timeShiftMax',
		snippet: 'timeShiftMax(#{1}; #{2})',
		detail: i18n.t('formula:function.timeShiftMax'),
		example: 'timeShiftMax(Sales)',
		type: FUNC_VAR_TYPES.function.key,
		args: [
			{ label: 'variable', detail: i18n.t('formula:functionArgs.timeShift1') },
			{ label: 'variable', detail: i18n.t('formula:functionArgs.timeShift2') }
		]
	},
	{
		key: 'simPercentile',
		label: 'simPercentile',
		snippet: 'simPercentile(#{1}; #{2})',
		detail: i18n.t('formula:function.simPercentile'),
		example: 'simPercentile(Sales)',
		type: FUNC_VAR_TYPES.function.key,
		args: [
			{ label: 'variable', detail: i18n.t('formula:functionArgs.simPercentile1') },
			{ label: 'value', detail: i18n.t('formula:functionArgs.simPercentile2') }
		]
	},
	{
		key: 'simAverage',
		label: 'simAverage',
		snippet: 'simAverage(#{1})',
		detail: i18n.t('formula:function.simAverage'),
		example: 'simAverage(Sales)',
		type: FUNC_VAR_TYPES.function.key,
		args: [{ label: 'variable', detail: i18n.t('formula:functionArgs.simAverage') }]
	},
	{
		key: 'sumCategories',
		label: 'sumCategories',
		snippet: 'sumCategories(#{1})',
		detail: i18n.t('formula:function.sumCategories'),
		example: 'sumCategories(Sales)',
		type: FUNC_VAR_TYPES.function.key,
		args: [{ label: 'variable', detail: i18n.t('formula:functionArgs.sumCategories') }]
	},
	{
		key: 'forecastETS',
		label: 'forecastETS',
		snippet: 'forecastETS(#{1}; #{2})',
		detail: i18n.t('formula:function.forecastETS'),
		example: 'forecastETS(Sales; 12)',
		type: FUNC_VAR_TYPES.function.key,
		args: [
			{ label: 'variable', detail: i18n.t('formula:functionArgs.forecast1') },
			{ label: 'value', detail: i18n.t('formula:functionArgs.forecast2') },
			{ label: 'value', detail: i18n.t('formula:functionArgs.forecast3'), isOptional: true },
			{ label: 'boolean', detail: i18n.t('formula:functionArgs.forecast4'), isOptional: true },
			{ label: 'boolean', detail: i18n.t('formula:functionArgs.forecast5'), isOptional: true }
		]
	},
	{
		key: 'uniform',
		label: 'uniform',
		snippet: 'uniform(#{1}; #{2})',
		detail: i18n.t('formula:function.uniform'),
		example: 'uniform(5; 10)',
		type: FUNC_VAR_TYPES.function.key,
		args: [
			{ label: 'value', detail: i18n.t('formula:functionArgs.uniform1') },
			{ label: 'value', detail: i18n.t('formula:functionArgs.uniform2') }
		]
	},
	{
		key: 'normal',
		label: 'normal',
		snippet: 'normal(#{1}; #{2})',
		detail: i18n.t('formula:function.normal'),
		example: 'normal(5; 10)',
		type: FUNC_VAR_TYPES.function.key,
		args: [
			{ label: 'value', detail: i18n.t('formula:functionArgs.normal1') },
			{ label: 'value', detail: i18n.t('formula:functionArgs.normal2') }
		]
	},
	{
		key: 'uniformDiscrete',
		label: 'uniformDiscrete',
		snippet: 'uniformDiscrete(#{1}; #{2})',
		detail: i18n.t('formula:function.uniformDiscrete'),
		example: 'uniformDiscrete(5; 10)',
		type: FUNC_VAR_TYPES.function.key,
		args: [
			{ label: 'value', detail: i18n.t('formula:functionArgs.uniform1') },
			{ label: 'value', detail: i18n.t('formula:functionArgs.uniform2') }
		]
	},
	{
		key: 'PMT',
		label: 'PMT',
		snippet: 'PMT(#{1}; #{2}; #{3})',
		detail: i18n.t('formula:function.pmt'),
		example: 'pmt(0.05; 12; 1000)',
		type: FUNC_VAR_TYPES.function.key,
		args: [
			{ label: 'interestRate', detail: i18n.t('formula:functionArgs.pmt1') },
			{ label: 'numPayments', detail: i18n.t('formula:functionArgs.pmt2') },
			{ label: 'principal', detail: i18n.t('formula:functionArgs.pmt3') }
		]
	},
	{
		key: 'if',
		label: 'if',
		snippet: 'if(#{1}; #{2}; #{3})',
		detail: i18n.t('formula:function.if'),
		example: 'if(Sales < 100; Stock + 100; Stock)',
		type: FUNC_VAR_TYPES.function.key,
		args: [
			{ label: 'logical_expression', detail: i18n.t('formula:functionArgs.if1') },
			{ label: 'value_if_true', detail: i18n.t('formula:functionArgs.if2') },
			{ label: 'value_if_false', detail: i18n.t('formula:functionArgs.if3') }
		]
	},
	{
		key: 'and',
		label: 'and',
		snippet: 'and(#{1}; #{2})',
		detail: i18n.t('formula:function.and'),
		example: 'and(Sales > 100; Stock < 100)',
		type: FUNC_VAR_TYPES.function.key,
		args: [
			{ label: 'logical_expression1', detail: i18n.t('formula:functionArgs.and1') },
			{ label: 'logical_expression2', detail: i18n.t('formula:functionArgs.and2'), isUnbounded: true, isOptional: true }
		]
	},
	{
		key: 'or',
		label: 'or',
		snippet: 'or(#{1}; #{2})',
		detail: i18n.t('formula:function.or'),
		example: 'or(Stock < 100; Stock > 500)',
		type: FUNC_VAR_TYPES.function.key,
		args: [
			{ label: 'logical_expression1', detail: i18n.t('formula:functionArgs.or1') },
			{ label: 'logical_expression2', detail: i18n.t('formula:functionArgs.or2'), isUnbounded: true, isOptional: true }
		]
	},
	{
		key: 'not',
		label: 'not',
		snippet: 'not(#{1})',
		detail: i18n.t('formula:function.not'),
		example: 'not(isEmpty(Sales))',
		type: FUNC_VAR_TYPES.function.key,
		args: [{ label: 'logical_expression', detail: i18n.t('formula:functionArgs.not') }]
	},
	{
		key: 'isNan',
		label: 'isNan',
		snippet: 'isNan(#{1})',
		detail: i18n.t('formula:function.isNan'),
		example: 'isNan(Forecast)',
		type: FUNC_VAR_TYPES.function.key,
		args: [{ label: 'variable', detail: i18n.t('formula:functionArgs.isNan') }]
	},
	{
		key: 'isNull',
		label: 'isNull',
		snippet: 'isNull(#{1})',
		detail: i18n.t('formula:function.isNull'),
		example: 'isNull(Forecast)',
		type: FUNC_VAR_TYPES.function.key,
		args: [{ label: 'variable', detail: i18n.t('formula:functionArgs.isNull') }]
	},
	{
		key: 'isFinite',
		label: 'isFinite',
		snippet: 'isFinite(#{1})',
		detail: i18n.t('formula:function.isFinite'),
		example: 'isFinite(Forecast)',
		type: FUNC_VAR_TYPES.function.key,
		args: [{ label: 'variable', detail: i18n.t('formula:functionArgs.isFinite') }]
	},
	{
		key: 'isNow',
		label: 'isNow',
		snippet: 'isNow',
		detail: i18n.t('formula:function.isNow'),
		example: 'if(isNow; 1; 0)',
		type: FUNC_VAR_TYPES.function.key,
		args: []
	},
	{
		key: 'isFuture',
		label: 'isFuture',
		snippet: 'isFuture',
		detail: i18n.t('formula:function.isFuture'),
		example: 'if(isFuture; 1; 0)',
		type: FUNC_VAR_TYPES.function.key,
		args: []
	},
	{
		key: 'isPast',
		label: 'isPast',
		snippet: 'isPast',
		detail: i18n.t('formula:function.isPast'),
		example: 'if(isPast; 1; 0)',
		type: FUNC_VAR_TYPES.function.key,
		args: []
	},
	{
		key: 'coalesce',
		label: 'coalesce',
		snippet: 'coalesce(#{1}; #{2})',
		detail: i18n.t('formula:function.coalesce'),
		example: 'coalesce(Sales; Forecast)',
		type: FUNC_VAR_TYPES.function.key,
		args: [
			{ label: 'variable', detail: i18n.t('formula:functionArgs.coalesce') },
			{ label: 'variable', detail: i18n.t('formula:functionArgs.coalesce'), isUnbounded: true }
		]
	},
	{
		key: 'normsinv',
		label: 'normsinv',
		snippet: 'normsinv(#{1})',
		detail: i18n.t('formula:function.normsinv'),
		example: 'normsinv(0.95)',
		type: FUNC_VAR_TYPES.function.key,
		args: [{ label: 'value', detail: i18n.t('formula:functionArgs.normsinv') }]
	},
	{
		key: 'ifError',
		label: 'ifError',
		snippet: 'ifError(#{1}; #{2})',
		detail: i18n.t('formula:function.ifError'),
		example: 'ifError(1/0; 3)',
		type: FUNC_VAR_TYPES.function.key,
		args: [
			{ label: 'variable', detail: i18n.t('formula:functionArgs.ifError1') },
			{ label: 'variable', detail: i18n.t('formula:functionArgs.ifError2') }
		]
	},
	{
		key: 'freeze',
		label: 'freeze',
		snippet: 'freeze(#{1}; #{2})',
		detail: i18n.t('formula:function.freeze'),
		example: 'freeze(Sales; Completed)',
		type: FUNC_VAR_TYPES.function.key,
		args: [
			{ label: 'variable', detail: i18n.t('formula:functionArgs.freeze1') },
			{ label: 'variable', detail: i18n.t('formula:functionArgs.freeze2') }
		]
	},
	{
		key: 'allocateByCost',
		label: 'allocateByCost',
		snippet: 'allocateByCost(#{1}; #{2}; #{3}; #{4})',
		detail: i18n.t('formula:function.allocateByCost'),
		example: 'allocateByCost(SKU; Items; Cost; Capacity)',
		type: FUNC_VAR_TYPES.function.key,
		args: [
			{ label: 'category', detail: i18n.t('formula:functionArgs.allocateByCost1') },
			{ label: 'variable', detail: i18n.t('formula:functionArgs.allocateByCost2') },
			{ label: 'variable', detail: i18n.t('formula:functionArgs.allocateByCost3') },
			{ label: 'variable', detail: i18n.t('formula:functionArgs.allocateByCost4') },
			{ label: 'variable', detail: i18n.t('formula:functionArgs.allocateByCost5'), isOptional: true }
		]
	}
]

export const CONSTANTS = [
	{ key: 'year', label: 'year', detail: i18n.t('formula:constant.year'), type: FUNC_VAR_TYPES.constant.key },
	{ key: 'month', label: 'month', detail: i18n.t('formula:constant.month'), type: FUNC_VAR_TYPES.constant.key },
	{ key: 'week', label: 'week', detail: i18n.t('formula:constant.week'), type: FUNC_VAR_TYPES.constant.key },
	{ key: 'day', label: 'day', detail: i18n.t('formula:constant.day'), type: FUNC_VAR_TYPES.constant.key },
	{ key: 'quarter', label: 'quarter', detail: i18n.t('formula:constant.quarter'), type: FUNC_VAR_TYPES.constant.key },
	{ key: 'daysInMonth', label: 'daysInMonth', detail: i18n.t('formula:constant.daysInMonth'), type: FUNC_VAR_TYPES.constant.key },
	{ key: 'daysInYear', label: 'daysInYear', detail: i18n.t('formula:constant.daysInYear'), type: FUNC_VAR_TYPES.constant.key },
	{ key: 'dayOfWeek', label: 'dayOfWeek', detail: i18n.t('formula:constant.dayOfWeek'), type: FUNC_VAR_TYPES.constant.key },
	{ key: 'dayOfYear', label: 'dayOfYear', detail: i18n.t('formula:constant.dayOfYear'), type: FUNC_VAR_TYPES.constant.key },
	{ key: 'daysFromNow', label: 'daysFromNow', detail: i18n.t('formula:constant.daysFromNow'), type: FUNC_VAR_TYPES.constant.key },
	{ key: 'weeksFromNow', label: 'weeksFromNow', detail: i18n.t('formula:constant.weeksFromNow'), type: FUNC_VAR_TYPES.constant.key },
	{ key: 'monthsFromNow', label: 'monthsFromNow', detail: i18n.t('formula:constant.monthsFromNow'), type: FUNC_VAR_TYPES.constant.key },
	{ key: 'yearsFromNow', label: 'yearsFromNow', detail: i18n.t('formula:constant.yearsFromNow'), type: FUNC_VAR_TYPES.constant.key }
]

export const TIME_EXPRESSIONS = [
	{ key: 'Current', label: i18n.t('formula:timeExpr.current'), apply: '', detail: i18n.t('formula:timeExprDesc.current') },
	{ key: 'Previous', label: i18n.t('formula:timeExpr.previous'), apply: 't-1', detail: i18n.t('formula:timeExprDesc.previous') },
	{ key: 'Next', label: i18n.t('formula:timeExpr.next'), apply: 't+1', detail: i18n.t('formula:timeExprDesc.next') },
	{ key: 'Prev. month', label: i18n.t('formula:timeExpr.prevMonth'), apply: 'm-1', detail: i18n.t('formula:timeExprDesc.prevMonth') },
	{ key: 'Prev. year', label: i18n.t('formula:timeExpr.prevYear'), apply: 'y-1', detail: i18n.t('formula:timeExprDesc.prevYear') },
	{ key: 'Start', label: i18n.t('formula:timeExpr.start'), apply: 'start', detail: i18n.t('formula:timeExprDesc.start') },
	{ key: 'End', label: i18n.t('formula:timeExpr.end'), apply: 'end', detail: i18n.t('formula:timeExprDesc.end') }
	// { key: 'WTD', label: 'WTD', apply: 'wtd', detail: 'Aggregate week to date' },
	// { key: 'MTD', label: 'MTD', apply: 'mtd', detail: 'Aggregate month to date' },
	// { key: 'YTD', label: 'YTD', apply: 'ytd', detail: 'Aggregate year to date' },
	// { key: 'All', label: 'All', apply: 'all', detail: 'Aggregate all time steps' }
]

export const TIME_EXAMPLES = [
	{ label: 't-5', text: i18n.t('formula:timeExprExample.tm5') },
	{ label: 'm-3', text: i18n.t('formula:timeExprExample.mm3') },
	{ label: 'y-2', text: i18n.t('formula:timeExprExample.ym2') }
]

// export const RANGE_KEYWORDS = [
// 	{ key: 't', detail: i18n.t('formula:rangeKeywords.t') },
// 	{ key: 'start', detail: i18n.t('formula:rangeKeywords.start') },
// 	{ key: 'weekStart', detail: i18n.t('formula:rangeKeywords.weekStart') },
// 	{ key: 'monthStart', detail: i18n.t('formula:rangeKeywords.monthStart') },
// 	{ key: 'yearStart', detail: i18n.t('formula:rangeKeywords.yearStart') }
// ]

export const RANGE_EXAMPLES = [
	{ label: 't-3:t-1', text: i18n.t('formula:rangeExample.T3ToT1') },
	{ label: 't+1:t+5', text: i18n.t('formula:rangeExample.T1ToT5') }
]

export const ABSOLUTE_EXAMPLES = [
	{ label: '2022010100', text: i18n.t('formula:absoluteExample.2022010100') },
	{ label: '2022123100', text: i18n.t('formula:absoluteExample.2022123100') }
]
