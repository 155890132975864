import { useTranslation } from 'react-i18next'
import { Divider, CircularProgress, TextField, InputAdornment, MenuItem } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { cancelDiscoverSource, cancelSources, cancelSource} from 'automation/store/sourceReducer'
import { LoadingButton } from '@mui/lab'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { Add, Edit } from '@mui/icons-material'
import { CONNECTORS } from 'automation/constants/datasources'
import { createSource, discover, loadSources } from 'automation/saga-actions/sourceActions'
import Config from 'automation/screens/Config'
import { connectorStatus } from 'automation/store/connectorReducer'
import Source from '../Source'
import { cloneDeep } from 'lodash'

export default function NewInConnector({ connector, connection, validation, onClose, onChange, form }) {
	const { t } = useTranslation(['common', 'automation'])
	const dispatch = useDispatch()
	let { teamId } = useParams()
	const tid = useSelector((state) => state.auth.tid)
	const uid = useSelector((state) => state.auth.uid)
	const isCreating = useSelector((state) => state.connector.creating)
	const connStatus = useSelector((state) => state.connector.status)
	const isCreatingSource = useSelector((state) => state.global.loading?.newSource)
	const source = useSelector((state) => state.source.source)
	const sources = useSelector((state) => state.source.sources?.result)
	const status = useSelector((state) => state.source.status)
    const discoverData = useSelector((state) => state.source.discover)
	const isDiscovering = useSelector((state) => state.global.loading?.discover)
	const isUpdate = connection ? true : false
	
	const timeUnitValues = ['days', 'weeks', 'months']
    
    const [config, setConfig] = useState()
	const [validationConfig, setValidationConfig] = useState()
	const [selectedSource, setSelectedSource] = useState("")
	const [openConfig, setOpenConfig] = useState(false)
	const [currentSources, setCurrentSources] = useState([])
	const [isOpenConfig, setIsOpenConfig] = useState(false)

	const [selectedTable, setSelectedTable] = useState('')
	const [showCursor, setShowCursor] = useState(false)
	const [showScheduler, setShowScheduler] = useState(false)
	
	useEffect(() => {
        dispatch(loadSources({ tid, teamId }))
        return () => {
            dispatch(cancelSources())
			dispatch(cancelSource())
			dispatch(cancelDiscoverSource())
			dispatch(connectorStatus(null))
        }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (connector && !isCreatingSource) {
			setConfig(CONNECTORS[connector].defaultConfig)
			setValidationConfig(CONNECTORS[connector].defaultValidationConfig)
		}
		if (sources && connector) {
            setCurrentSources(Object.entries(sources).map(source => {
                if(source[1].sourceName.toLowerCase() === connector)
                    return {key: source[1].sourceId, label: source[1].name}
                return null
            }))
        }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [connector, sources])

	useEffect(() => {
		if (!isCreatingSource && status === 'succeeded') {
            dispatch(discover({teamId, sourceId: source?.sourceId}))
            setOpenConfig(false)
            setSelectedSource(source?.sourceId)
            onChange('sourceProps', {...form.sourceProps, sourceId: source?.sourceId})
        }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isCreatingSource])
	
	useEffect(() => {
		if (!isCreating && connStatus === 'success') onClose()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isCreating])
	
	useEffect(() => {
		if(form && connection) {
			setSelectedTable(connection.sourceProps?.table)
			if(connection.sourceProps?.freq === 'automatic') {
				setShowScheduler(true)
			}
			if(connection.sourceProps?.mode === 'incremental') {
				setShowCursor(true)
			}
			const sourceId = connection.sourceProps?.sourceId
			setSelectedSource(sourceId)
			dispatch(discover({teamId, sourceId}))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form, connection])

	function onConfig(key, value) {
        setConfig({...config, [key]: value})
    }

	function onSource(source) {
        if(source === 'new') {
			setOpenConfig(true)
			setIsOpenConfig(false)
        } else if(source === 'edit') {
			setOpenConfig(false)
			setIsOpenConfig(true)
        } else {
            dispatch(discover({teamId, sourceId: source}))
			onChange('sourceProps', {...form.sourceProps, sourceId: source})
			setOpenConfig(false)
			setIsOpenConfig(false)
        }
		setSelectedSource(source)
    }

	function onTestConnection() {
		dispatch(createSource({tid, teamId, uid, source: connector, config}))
	}

	function onSelectSourceTable(event) {
		const namespace = event.target.value.split('|')[0]
		const name = event.target.value.split('|')[1]
		const table = discoverData?.catalog?.streams.filter(table => table.stream.namespace === namespace && table.stream.name === name)[0]
		onChange('sourceProps', {...form.sourceProps, table: table?.stream, stream: event.target.value})
		setSelectedTable(table?.stream)
		onChange('fields', table?.stream.jsonSchema.properties)
	}

	function onSelectMode(event) {
		if (event.target.value === 'incremental') {
			setShowCursor(true)
			onChange('sourceProps', {...form.sourceProps, strategy: 'append_dedup', mode: event.target.value})
		} else {
			setShowCursor(false)
			onChange('sourceProps', {...form.sourceProps, mode: event.target.value})
		}
	}

	function onSelectCursor(event) {
		onChange('sourceProps', {...form.sourceProps, cursor: event.target.value})
	}

	function onSelectPrimaryKey(event) {
		const schema = cloneDeep(form.sourceProps.schema)
		const index = schema.findIndex(item => item.attr === event.target.value)
		const newSchema = schema[index]
		newSchema['primaryKey'] = true
		schema[index] = newSchema
		onChange('sourceProps', {...form.sourceProps, primaryKey: event.target.value, schema: schema})

	}

	function onSelectFreq(event) {
		onChange('sourceProps', {...form.sourceProps, freq: event.target.value})
		if (event.target.value === 'automatic') {
			setShowScheduler(true)
		} else setShowScheduler(false)
	}

	function onSelectUnits(event) {
		onChange('sourceProps', {...form.sourceProps, scheduler: {units: parseInt(event.target.value), timeUnit: form.sourceProps.scheduler?.timeUnit }})
	}

	function onSelectTimeUnit(event) {
		onChange('sourceProps', {...form.sourceProps, scheduler: { units: parseInt(form.sourceProps.scheduler?.units), timeUnit: event.target.value }})
	}

	function onSelectTable(field, value) {
		onChange('destinationProps', {...form.destinationProps, [field]: value})
	}

	return (
		<>
			<div>
				<div className="text-sm font-medium mt-8">
					<div className="text-md text-textGray leading-none truncate">{t("automation:connector.config", {connector: connector && CONNECTORS[connector].name})}</div>
				</div>
				<div className="text-xs text-gray-400 leading-none truncate mt-1">{t("automation:connector.configDesc", {connector: connector && CONNECTORS[connector].name})}</div>
				
					<div className="flex flex-row items-center">
						<TextField
							variant="outlined"
							className='mt-4 text-sm'
							size="small"
							label={t('automation:configs.name')}
							disabled={isUpdate}
							value={form.name}
							InputProps={{
								className: "text-sm"
							}}
							InputLabelProps={{
								className: "text-sm"
							}}
							fullWidth
							onChange={(event) => onChange('name', event.target.value)}
							error={validation === null ? false : !validation.name.valid}
							helperText={
								validation !== null &&
								!validation.name.valid &&
								t(validation.name.error, {
									field: t('automation:configs.name')
								})
							}
						/>
					</div>
					<div className="flex flex-row items-center mt-4">
						<TextField
							select
							variant="outlined"
							className='text-sm'
							disabled={isUpdate}
							InputProps={{
								className: "text-sm",
								endAdornment: (
									<InputAdornment position="end">
										{isDiscovering && 
											<CircularProgress size={20} className="text-textGray mr-3" />}
									</InputAdornment>
								)
							}}
							InputLabelProps={{
								className: "text-sm"
							}}
							size="small"
							label={t('automation:connector.account', {connector: connector && CONNECTORS[connector].name})}
							value={selectedSource}
							fullWidth
							onChange={(event) => onSource(event.target.value)}
							error={validation === null ? false : !validation.sourceId.valid}
							helperText={
								validation !== null &&
								!validation.sourceId.valid &&
								t(validation.sourceId.error, {
									field: t('automation:connector.account', {connector: connector && CONNECTORS[connector].name})
								})
							}
						>
							<MenuItem className='text-sm' key="new" value="new" sx={{
								className: "text-sm"
							}}>
								<Add className='text-sm mr-2'/>
								{t('automation:connector.newDataConnection', {connector: connector && CONNECTORS[connector].name})}
							</MenuItem>
							<MenuItem className='text-sm' key="edit" value="edit" sx={{
								className: "text-sm"
							}}>
								<Edit className='text-sm mr-2'/>
								{t('automation:connector.editDataConnection', {connector: connector && CONNECTORS[connector].name})}
							</MenuItem>
							<Divider />
							{currentSources.map((source) => {
								if(source)
									return (
										<MenuItem className='text-sm' key={source.key} value={source.key} sx={{
											className: "text-sm"
										}}>
											{source.label}
										</MenuItem>
									)
									return null
							})}
							
						</TextField>
					</div>
					{discoverData !== null && (
						<>
							<div className="flex flex-row items-center">
								<TextField
									id="table"
									select
									disabled={isUpdate}
									label={t("automation:connector.table", {connector: connector && CONNECTORS[connector].name})}
									variant="outlined"
									className='text-sm mt-4'
									InputProps={{
										className: "text-sm"
									}}
									InputLabelProps={{
										className: "text-sm"
									}}
									size="small"
									fullWidth
									value={form.sourceProps.stream}
									onChange={onSelectSourceTable}
									error={validation === null ? false : !validation.table.valid}
									helperText={
										validation !== null &&
										!validation.table.valid &&
										t(validation.table.error, {
											field: t("automation:connector.table", {connector: connector && CONNECTORS[connector].name})
										})
									}
								>
									{discoverData?.catalog?.streams.map((stream, i) => (
										<MenuItem key={stream.stream.namespace + stream.stream.name} value={stream.stream.namespace + '|' + stream.stream.name}>
											{stream.stream.name}
										</MenuItem>
									))}
								</TextField>
							</div>
						</>
					)}
					{openConfig && (
						<>
							<Config connector={CONNECTORS[connector]} config={config} validation={validationConfig} onConfig={onConfig}/>
							<div className="flex flex-row justify-end">
								{(status === 'succeeded' ? (
									<div className='mt-1'>
										<CheckCircleIcon className="fill-green-700" />
										{t('automation:create.connectionOk')}
									</div>
								) : status === 'failed' ? (
									<div className='mt-1'>
										<HighlightOffIcon className="fill-red-700" />
										{t('automation:create.connectionError')}
									</div>
								) : null)}
								<LoadingButton
									loading={isCreatingSource || false}
									variant="outlined"
									className="text-md ml-2 mr-2 normal-case"
									onClick={onTestConnection}
									size="medium"
									loadingIndicator={<CircularProgress size={20} />}
								>
									{t('automation:configs.testConnection')}
								</LoadingButton>
							</div>
						</>
					)}
			</div>
			<div>
				<div className="text-sm font-medium mt-8">
					<div className="text-md text-textGray leading-none truncate">{t("automation:create.destinationTable")}</div>
				</div>
				<div className="text-xs text-gray-400 leading-none truncate mt-1">{t("automation:create.destinationTableDesc")}</div>
			</div>
			<div className="flex flex-row items-center">
				<TextField
					id="tableName"
					label={t('automation:create.tableName')}
					variant="outlined"
					disabled={isUpdate}
					className='text-sm mt-4 '
					InputProps={{
						className: "text-sm"
					}}
					InputLabelProps={{
						className: "text-sm"
					}}
					size="small"
					fullWidth
					onChange={(event) => onSelectTable('tableName', event.target.value)}
					value={form.destinationProps.tableName || ''}
					error={validation === null ? false : !validation.tableName.valid}
					helperText={
						validation !== null &&
						!validation.tableName.valid &&
						t(validation.tableName.error, {
							field: t('automation:create.tableName')
						})
					}
				/>
			</div>
			
			<div>
				<div className="text-sm font-medium mt-8">
					<div className="text-md text-textGray leading-none truncate m-1">{t("automation:create.syncAndScheduler")}</div>
				</div>
				<div className="text-xs text-gray-400 leading-none truncate m-1">{t("automation:create.syncAndSchedulerDesc")}</div>
			</div>
			<div className="flex flex-row items-center mt-2">
				<TextField 
					id="mode" 
					select 
					InputProps={{
						className: "text-sm"
					}}
					InputLabelProps={{
						className: "text-sm"
					}}
					label="Sync mode" 
					variant="outlined" 
					className='text-sm grid-50 mt-4 mr-2' 
					size="small" 
					fullWidth
					value={form.sourceProps.mode} 
					onChange={onSelectMode}
				>
					<MenuItem key="full_refresh" value="full_refresh">
						{t('automation:configs.fullRefresh')}
					</MenuItem>
					<MenuItem key="incremental" value="incremental">
						{t('automation:configs.incremental')}
					</MenuItem>
				</TextField>
				{showCursor && (
					<>
						<TextField 
							id="cursor" 
							select 
							InputProps={{
								className: "text-sm"
							}}
							InputLabelProps={{
								className: "text-sm"
							}}
							label="Cursor field" 
							variant="outlined" 
							className='text-sm mt-4 mr-2' 
							size="small" 
							fullWidth
							value={form.sourceProps.cursor} 
							onChange={onSelectCursor}
							error={validation === null ? false : !validation.cursor.valid}
							helperText={
								validation !== null &&
								!validation.cursor.valid &&
								t(validation.cursor.error, {
									field: t('automation:create.cursor')
								})
							}
						>
							{Object.keys(selectedTable.jsonSchema?.properties || {}).map((column, i) => (
								<MenuItem key={i} value={column}>
									{column}
								</MenuItem>
							))}
						</TextField>
					</>
				)}
				<TextField 
					id="primaryKey" 
					select 
					InputProps={{
						className: "text-sm"
					}}
					InputLabelProps={{
						className: "text-sm"
					}}
					label="Primary key" 
					variant="outlined" 
					className='text-sm mt-4' 
					size="small" 
					fullWidth
					value={form.sourceProps.primaryKey} 
					onChange={onSelectPrimaryKey}
					error={validation === null ? false : !validation.primaryKey.valid}
					helperText={
						validation !== null &&
						!validation.primaryKey.valid &&
						t(validation.primaryKey.error, {
							field: t('automation:create.primaryKey')
						})
					}
				>
					{Object.keys(selectedTable.jsonSchema?.properties || {}).map((column, i) => (
						<MenuItem key={i} value={column}>
							{column}
						</MenuItem>
					))}
				</TextField>
			</div>
			<div className="flex flex-row items-center mt-4">
				<TextField 
					id="freq" 
					select 
					label="Frequency" 
					InputProps={{
						className: "text-sm"
					}}
					InputLabelProps={{
						className: "text-sm"
					}}
					variant="outlined" 
					className='text-sm mt-4' 
					size="small" 
					fullWidth
					disabled={isUpdate}
					value={form.sourceProps.freq} 
					onChange={onSelectFreq}
				>
					<MenuItem key="manual" value="manual">
						{t('automation:configs.manual')}
					</MenuItem>
					<MenuItem key="automatic" value="automatic">
						{t('automation:configs.automatic')}
					</MenuItem>
				</TextField>
				{showScheduler && (
					<>
						<TextField 
							id="units" 
							label="Every" 
							variant="outlined" 
							className='text-sm mt-4 mr-2 ml-2' 
							disabled={isUpdate}
							InputProps={{
								className: "text-sm",
								inputProps: { min: 1 }
							}}
							InputLabelProps={{
								className: "text-sm"
							}}
							size="small" 
							value={form.sourceProps.scheduler?.units} 
							type="number" 
							fullWidth
							onChange={onSelectUnits} 
						/>
						<TextField 
							id="timeUnit" 
							select 
							label="Time unit" 
							variant="outlined" 
							className='text-sm mt-4' 
							disabled={isUpdate}
							InputProps={{
								className: "text-sm"
							}}
							InputLabelProps={{
								className: "text-sm"
							}}
							size="small" 
							fullWidth
							value={form.sourceProps.scheduler?.timeUnit} 
							onChange={onSelectTimeUnit}
						>
							{timeUnitValues.map((column, i) => (
								<MenuItem key={i} value={column}>
									{column}
								</MenuItem>
							))}
						</TextField>
					</>
				)}
			</div>
			{isOpenConfig && <Source isOpen={isOpenConfig} onClose={() => setIsOpenConfig(false)} sources={sources} connector={connector} />}
		</>
	)
}
