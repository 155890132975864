import { put, call, take, takeEvery, fork, cancel } from 'redux-saga/effects'
import * as api from 'common/api/helpers/subscribeApi'
import { replaceParam } from 'common/saga/helpers/utils'
import { error } from 'common/config/errors'

// EXTERNAL
export const queryConfig = ({ path, where, orderBy, returnType }) => {
	return {
		path, // path to document or collection
		where, // query filters
		orderBy, // order criteria
		returnType // doc, map or list
	}
}

export const sagaConfig = ({ loadAction, loadResponse, cancelAction, cancelResponse }) => {
	return {
		loadAction, // load action
		loadResponse, // reponse to load
		cancelAction, // cancel action
		cancelResponse // response to cancel
	}
}

// This method takes actions
export function* subscribe(sagaConfig, queryConfig) {
	yield takeEvery(sagaConfig.loadAction, subscribeInit, sagaConfig, queryConfig)
}

// This method does not take actions and requires an external function to do so
export function* subscribeWithoutActions(loadResponse, queryConfig, triggeredAction) {
	yield call(subscribeInit, { loadResponse }, queryConfig, triggeredAction)
}

// INTERNAL
function* subscribeInit(sagaConfig, queryConfig, triggeredAction) {
	try {
		// Introduce values into parameters
		var newQueryConfig = {
			...queryConfig,
			path: replaceParam(queryConfig.path, triggeredAction),
			where: replaceParam(queryConfig.where, triggeredAction)
		}

		// Check if only incremental updates are to be retrieved
		if (triggeredAction.updatedAfter) {
			newQueryConfig.where.push({
				attribute: 'updated',
				operator: '>=',
				param: 'updatedAfter',
				value: triggeredAction.updatedAfter
			})
		}

		// Create subscription
		const channel = queryConfig.returnType === 'doc' ? yield call(api.subscribeDoc, newQueryConfig) : yield call(api.subscribeCol, newQueryConfig)

		// listen for updates
		yield fork(subscribeTaker, channel, sagaConfig, queryConfig, triggeredAction)
		// listen for cancel request
		if (sagaConfig.cancelAction) {
			const cancelReq = yield take(sagaConfig.cancelAction)
			yield put(sagaConfig.cancelResponse(cancelReq))
			yield cancel()
		}
	} catch (err) {
		// we are not currently surfacing these errors to user
		error(err)
	}
}

function* subscribeTaker(channel, sagaConfig, queryConfig, triggeredAction) {
	try {
		while (true) {
			// take updates from channel
			const result = yield take(channel)
			const id = queryConfig.returnType === 'doc' && result.id ? result.id : triggeredAction.id
			// return results
			yield put(sagaConfig.loadResponse({ ...result, id }))
		}
	} catch (err) {
		// we are not currently surfacing these errors to user
		error(err)
	} finally {
		// clean after subscription is terminated
		channel.close()
	}
}
