import { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { Button, Collapse, Menu, MenuItem } from '@mui/material'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { DATA_TYPES } from 'common/constants/dataTypes'
import TableTypeRefVariable from 'table/screens/type/TableTypeRefVariable'

export default function AssetFilters({ aid, variables, isLoaded, onChange, filters, isAddOpen = true, onCloseAdd, saveOnBlur = false }) {
	const { t } = useTranslation(['common', 'table'])

	// #### STATE
	const [selectVariable, setSelectVariable] = useState('')
	const [menu, setMenu] = useState({ anchor: null, index: null })
	const [focusedInput, setFocusedInput] = useState(null)
	const [input, setInput] = useState(null)

	// #### FUNCTIONS
	function onAddFilter(e) {
		const variableId = selectVariable
		const variable = variableId && variableId !== '' && variables && variables[variableId]
		if (!variable) return
		const type = DATA_TYPES[variable.type]
		const operator = type.filters[0].key
		const value = type.filters[0].hasMany ? [] : ''
		var newFilters = filters ? [...filters] : []
		newFilters.push({ id: variable.id, operator: operator, value: value })
		onChange(newFilters)
		setSelectVariable('')
		if (onCloseAdd) onCloseAdd()
	}

	function onDeleteFilter(index) {
		// Update filter
		var newFilters = [...filters]
		newFilters.splice(index, 1)
		onChange(newFilters)
	}

	function onChangeOperator(index, operator, newHasValue, newHasMany, prevHasValue, prevHasMany) {
		if (filters[index].operator === operator) return
		// Update filter
		var newFilters = [...filters]
		newFilters[index] = { ...newFilters[index] }
		newFilters[index].operator = operator
		if (newHasValue !== prevHasValue || newHasMany !== prevHasMany) {
			const value = newHasMany ? [] : ''
			newFilters[index].value = value
		}
		onChange(newFilters)
		setMenu({ anchor: null, index: null })
	}

	function onChangeFilter(filterIndex, fieldIndex, hasMany, value) {
		// Update input
		const filter = filters[filterIndex]
		var nextValue
		if (!hasMany) nextValue = value
		else {
			nextValue = filter.value ? [...filter.value] : []
			const isNullValue = value == null || value === ''
			if (isNullValue && fieldIndex < nextValue.length) nextValue.splice(fieldIndex, 1)
			else if (!isNullValue && fieldIndex >= nextValue.length) nextValue.push(value)
			else if (!isNullValue) nextValue[fieldIndex] = value
		}

		// Update filter
		var newFilters = [...filters]
		newFilters[filterIndex] = { ...newFilters[filterIndex], value: nextValue }
		onChange(newFilters)
	}

	function onFocus(filterIndex, fieldIndex, hasMany) {
		const prevValue = hasMany ? filters[filterIndex]?.value[fieldIndex] : filters[filterIndex]?.value
		setInput(prevValue || '')
		setFocusedInput({ filterIndex, fieldIndex, hasMany })
	}

	function onBlur() {
		const hasMany = focusedInput?.hasMany
		const filterIndex = focusedInput?.filterIndex
		const fieldIndex = focusedInput?.fieldIndex
		const value = input
		onChangeFilter(filterIndex, fieldIndex, hasMany, value)
		setFocusedInput(null)
	}

	function onKeyDown(e) {
		if (e.key === 'Enter') e.target.blur()
	}

	return (
		<div className="bg-white flex flex-col">
			<Collapse in={isAddOpen} classes={{ wrapperInner: 'pt-2 flex flex-row items-center' }}>
				<TableTypeRefVariable
					aid={aid}
					variables={variables}
					types={Object.values(DATA_TYPES).map((el) => el.key)}
					onChange={(value) => setSelectVariable(value)}
					value={selectVariable}
					fieldName={t('table:search.filterField')}
					showLabel={true}
				/>
				<Button size="small" className="buttonText ml-2" onClick={onAddFilter}>
					{t('common:buttons.add')}
				</Button>
			</Collapse>
			{isLoaded &&
				filters?.map((item, index) => {
					const variable = variables ? variables[item.id] : null
					const type = variable?.type ? DATA_TYPES[variable.type] : null
					const filterDefs = type?.filters
					const filterDef = filterDefs?.find((f) => f.key === item.operator)
					const values = item.value
					return (
						<div key={`filter#${index}`} className={clsx('flex flex-col my-2 pt-4 bg-white', index !== 0 && 'border-t border-borderGray')}>
							<div className="flex flex-row items-center justify-between mb-2">
								<div className="text-sm flex flex-row items-center">{variable?.label}</div>
								<div className="flex flex-row items-center">
									<Button size="small" className="buttonTextPrimary text-sm h-[20px]" onClick={(e) => setMenu({ anchor: e.currentTarget, index })}>
										{t(filterDef?.label)}
									</Button>
									<Menu anchorEl={menu?.anchor} open={menu?.index === index} onClose={(e) => setMenu({ anchor: null, index: null })}>
										{filterDefs?.map((newFilter, oIndex) => (
											<MenuItem
												className="text-sm"
												key={`operator#${index}#${oIndex}`}
												onClick={() => onChangeOperator(index, newFilter.key, newFilter.hasValue, newFilter.hasMany, filterDef.hasValue, filterDef.hasMany)}
											>
												{t(newFilter.label)}
											</MenuItem>
										))}
									</Menu>
									<CloseIcon fontSize="small" className="text-textGray ml-2 cursor-pointer w-[15px] h-[15px]" onClick={() => onDeleteFilter(index)} />
								</div>
							</div>
							{filterDef?.hasValue && (
								<div className="flex flex-col">
									{new Array(filterDef.hasMany && values ? values.length + 1 : 1).fill(true).map((_, vIndex) => (
										<input
											key={`value#${index}#${vIndex}`}
											className={clsx(
												'border-0 rounded p-1 bg-bgGray text-sm shadow-none hover:shadow-outer1 focus:shadow-outer2 focus:shadow-primary focus:outline-none',
												vIndex > 0 && 'mt-2'
											)}
											placeholder={t('table:search.value')}
											value={focusedInput?.filterIndex === index && focusedInput?.fieldIndex === vIndex ? input : filterDef.hasMany && values ? values[vIndex] || '' : values}
											onChange={saveOnBlur ? (e) => setInput(e.target.value) : (e) => onChangeFilter(index, vIndex, filterDef.hasMany, e.target.value)}
											onFocus={saveOnBlur ? (e) => onFocus(index, vIndex, filterDef.hasMany) : undefined}
											onBlur={saveOnBlur ? (e) => onBlur() : undefined}
											onKeyDown={saveOnBlur && focusedInput?.filterIndex === index && focusedInput?.fieldIndex === vIndex ? (e) => onKeyDown(e, index, vIndex) : undefined}
										/>
									))}
								</div>
							)}
						</div>
					)
				})}
		</div>
	)
}
