// #### SUBSCRIBE TO CUSTOMER
export const LOAD_CUSTOMER = 'billingSaga/LOAD_CUSTOMER'
export const CANCEL_CUSTOMER = 'billingSaga/CANCEL_CUSTOMER'
// #### SUBSCRIBE TO BILLABLE USERS
export const LOAD_BILLABLE_USERS = 'billingSaga/LOAD_BILLABLE_USERS'
export const CANCEL_BILLABLE_USERS = 'billingSaga/CANCEL_BILLABLE_USERS'
// #### CHECKOUT
export const CHECKOUT_ADDON = 'billingSaga/CHECKOUT_ADDON'
export const CHECKOUT_PLAN = 'billingSaga/CHECKOUT_PLAN'
// #### CUSTOMER PORTAL
export const CUSTOMER_PORTAL = 'billingSagas/CUSTOMER_PORTAL'

// #### SUBSCRIBE TO CUSTOMER
export const loadCustomer = ({ tid, cid }) => {
	return { type: LOAD_CUSTOMER, tid, cid, id: cid }
}

export const cancelCustomer = () => {
	return { type: CANCEL_CUSTOMER }
}

// #### SUBSCRIBE TO BILLABLE USERS
export const loadBillableUsers = ({ tid, teamId }) => {
	return { type: LOAD_BILLABLE_USERS, tid, teamId }
}

export const cancelBillableUsers = () => {
	return { type: CANCEL_BILLABLE_USERS }
}

// #### CHECKOUT
export const checkoutAddOn = ({ tid, teamId, customerId, addonId, priceId, currency, frequency, units }) => {
	return { type: CHECKOUT_ADDON, tid, teamId, customerId, addonId, priceId, currency, frequency, units }
}

export const checkoutPlan = ({ tid, teamId, customerId, planId, priceId, currency, frequency, units }) => {
	return { type: CHECKOUT_PLAN, tid, teamId, customerId, planId, priceId, currency, frequency, units }
}

// #### CUSTOMER PORTAL
export const customerPortal = ({ tid, teamId, cid }) => {
	return { type: CUSTOMER_PORTAL, tid, teamId, cid }
}
