export default function SingularlyIcon({ ...props }) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 317 70" fill="currentColor" {...props}>
			<path d="M37.5586 18.3242H47.1059V52.8262H37.5586V18.3242Z" />
			<path d="M88.6026 31.4187V52.8259H78.9761V33.654C78.9761 27.9521 76.514 25.7961 72.6623 25.7961C67.9653 25.7961 65.5824 28.8769 65.5824 33.2682V52.8206H56.0352V18.3239H64.0396L64.6578 22.3295C66.8135 19.1588 70.9716 17.1719 75.5893 17.1719C83.7523 17.1719 88.6026 22.7152 88.6026 31.4187Z" />
			<path d="M164.913 18.3242V52.8262H156.988L156.381 48.7467C153.918 52.2132 150.04 53.8303 145.602 53.8303C137.286 53.8303 132.742 48.3609 132.742 40.1964V18.3242H142.369V38.6587C142.369 43.8163 144.91 45.5284 148.756 45.5284C152.915 45.5284 155.382 42.6009 155.382 38.2095V18.3401L164.913 18.3242Z" />
			<path d="M173.537 0H183.164V52.8442H173.537V0Z" />
			<path d="M227.816 18.3239V52.8259H218.655V48.4398C216.346 51.6739 211.786 53.8299 206.566 53.8299C196.94 53.8299 190.24 46.6642 190.24 35.81C190.24 24.5648 197.251 17.1719 207.412 17.1719C212.251 17.1719 216.14 18.7255 218.634 21.6531L219.886 18.3239H227.816ZM218.195 35.4243C218.195 29.5691 214.497 25.7168 209.108 25.7168C203.565 25.7168 200.02 29.7224 200.02 35.4243C200.02 41.1262 203.639 45.1265 209.002 45.1265C214.497 45.1265 218.195 41.2688 218.195 35.4243Z" />
			<path d="M257.699 18.3286V26.5723C250.386 25.8007 246.302 29.4206 246.302 36.0419V52.8306H236.691V18.3286H245.393L246.165 23.2589C247.221 19.6391 250.629 17.9481 254.555 17.9481C255.615 17.9293 256.673 18.0574 257.699 18.3286V18.3286Z" />
			<path d="M263.631 0H273.247V52.8442H263.631V0Z" />
			<path d="M317 18.326L301.049 58.7095C299.12 63.5606 295.887 69.569 286.572 69.569C284.36 69.5242 282.161 69.2299 280.015 68.6918L280.094 60.9184C281.694 61.228 283.316 61.4048 284.945 61.4469C290.26 61.4469 291.491 58.979 292.643 56.0515L293.335 54.128L278.541 18.3154H288.939L297.794 42.423L306.876 18.3154L317 18.326Z" />
			<path d="M29.4079 36.6236C28.102 35.2432 26.4652 34.2191 24.6528 33.6485C22.3881 32.9725 20.0764 32.4655 17.7367 32.1319C15.6501 31.8312 13.5988 31.3231 11.6131 30.6152C10.4296 30.129 9.85371 29.3364 9.85371 28.1844C9.85371 27.1275 10.3821 26.319 11.5339 25.6637C12.6857 25.0084 14.482 24.6385 16.8173 24.6385C20.2608 24.5918 23.6528 25.4774 26.6341 27.2015L26.9828 27.4023L30.4593 20.5325L30.1582 20.3529C28.2886 19.2984 26.265 18.5441 24.1614 18.1176C21.7786 17.5681 19.3419 17.2863 16.8966 17.2773C12.1415 17.2773 8.27923 18.3025 5.45784 20.3264C4.09959 21.2287 2.99092 22.4591 2.23452 23.9038C1.47812 25.3485 1.09845 26.9608 1.13067 28.5913C1.13067 31.2335 1.83337 33.2891 3.24407 34.7688C4.5742 36.1997 6.25262 37.2612 8.11544 37.8496C10.4369 38.5277 12.805 39.0345 15.2006 39.3662C17.2084 39.6267 19.1865 40.0797 21.1075 40.719C21.5848 40.8512 22.0039 41.1396 22.298 41.5382C22.592 41.9367 22.744 42.4223 22.7296 42.9173C22.7296 45.2266 20.3943 46.3998 15.7818 46.3998C13.6139 46.3904 11.4582 46.0755 9.37819 45.4644C7.41273 44.9331 5.54135 44.1006 3.83053 42.9966L3.47654 42.7641L0 49.6339L0.264174 49.8188C2.28353 51.1411 4.51336 52.1097 6.85797 52.683C9.59685 53.4334 12.4242 53.812 15.264 53.8085C20.2041 53.8085 24.172 52.7939 27.0567 50.7964C29.9415 48.7989 31.4685 46.0405 31.4685 42.6426C31.442 40.0691 30.7552 38.0451 29.4079 36.6236Z" />
			<path d="M42.3373 13.8516C40.9655 13.8829 39.6367 13.3721 38.6389 12.4301C38.1564 11.9974 37.7704 11.4679 37.5062 10.8761C37.242 10.2842 37.1055 9.64336 37.1055 8.99521C37.1055 8.34706 37.242 7.70619 37.5062 7.11436C37.7704 6.52253 38.1564 5.99301 38.6389 5.56034C39.6217 4.6204 40.9354 4.10585 42.295 4.12826C43.6667 4.09756 44.9954 4.60822 45.9935 5.54977C46.4716 5.9851 46.8536 6.51548 47.1149 7.10696C47.3763 7.69844 47.5113 8.33798 47.5113 8.98465C47.5113 9.63132 47.3763 10.2709 47.1149 10.8623C46.8536 11.4538 46.4716 11.9842 45.9935 12.4195C45.0109 13.3599 43.6971 13.8746 42.3373 13.8516V13.8516Z" />
			<path d="M128.801 18.3238H116.649C114.427 17.5294 112.081 17.1392 109.722 17.1718V17.1718C99.562 17.1718 94.3261 23.2542 94.3261 30.3406C94.3261 36.888 98.7906 42.4948 107.413 43.4301L98.6374 49.9775L101.718 54.8286C104.466 53.5581 107.466 52.9258 110.494 52.979C114.958 52.979 117.653 54.4428 117.653 57.4497C117.653 60.4565 114.805 62.454 110.108 62.454C105.797 62.454 102.869 60.7577 102.484 58.0627H93.5547C93.9404 65.1491 100.254 70.0002 110.42 70.0002C120.194 70.0002 127.432 65.0698 127.432 57.5237C127.432 51.1823 122.735 46.5902 114.498 46.5902C113.653 46.5902 112.802 46.6642 111.957 46.7434L120.411 40.3493C121.924 39.1628 123.141 37.6413 123.966 35.9046C124.791 34.1678 125.202 32.2631 125.166 30.3406V30.3406C125.176 28.3983 124.744 26.4792 123.903 24.7285H128.785L128.801 18.3238ZM109.701 36.1165C105.929 36.1165 103.54 33.6539 103.54 30.3406C103.54 27.0273 105.929 24.5647 109.701 24.5647C113.473 24.5647 115.862 27.0273 115.862 30.3406C115.862 33.6539 113.479 36.1165 109.701 36.1165V36.1165Z" />
		</svg>
	)
}