import { createSlice } from '@reduxjs/toolkit'

export const connectorSlice = createSlice({
	name: 'connector',
	initialState: {
		creating: false,
		status: null,
		connectors: null,
		jobs: null,
		enabling: false,
		enablingStatus: null
	},
	reducers: {
		enablingConnector: (state, action) => {
			state.enabling = action.payload
		},
		enablingStatus: (state, action) => {
			state.enablingStatus = action.payload
		},
		connectorCreating: (state, action) => {
			state.creating = action.payload
		},
		connectorStatus: (state, action) => {
			state.status = action.payload
		},
		loadConnectors: (state, action) => {
			state.connectors = { loaded: true, ...action.payload }
		},
		cancelConnectors: (state, action) => {
			state.connectors = null
		},
		loadJobs: (state, action) => {
			state.jobs = { ...action.payload }
		},
		cancelJobs: (state, action) => {
			state.jobs = null
		}
	}
})

export const { loadConnectors, cancelConnectors, connectorCreating, connectorStatus, loadJobs, cancelJobs, enablingConnector, enablingStatus } = connectorSlice.actions

export default connectorSlice.reducer
