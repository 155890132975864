export const ERROR_CODE = {
	communitySagas: {
		createCommunityAsset: 'COM-S01',
		updateCommunityAsset: 'COM-S02',
		deleteCommunityAsset: 'COM-S03',
		fileTransmitSuccess: 'COM-S04',
		copyCommunityAsset: 'COM-S05',
		// Helpers
		updateCommunityAssetDirectReq: 'COM-X01'
	},
	communityApi: {
		createCommunityAsset: 'COM-A01',
		updateCommunityAsset: 'COM-A02',
		deleteCommunityAsset: 'COM-A03',
		uploadFile: 'COM-A04',
		deleteFile: 'COM-A05',
		copyCommunityAsset: 'COM-A06'
	},
	assetSagas: {
		createAsset: 'ASE-S01',
		updateAsset: 'ASE-S02',
		deleteAsset: 'ASE-S03',
		inviteUser: 'ASE-S04',
		updateUser: 'ASE-S05',
		deleteUser: 'ASE-S06',
		uploadScreenshot: 'ASE-S07',
		fileTransmitSuccess: 'ASE-S08',
		// Helpers
		searchAssetsReq: 'ASE-X01'
	},
	assetApi: {
		createAsset: 'ASE-A01',
		updateAsset: 'ASE-A02',
		deleteAsset: 'ASE-A03',
		inviteUser: 'ASE-A04',
		updateUser: 'ASE-A05',
		deleteUser: 'ASE-A06',
		uploadFile: 'ASE-A07',
		deleteFolder: 'ASE-A08'
	},
	tableSagas: {
		deleteTab: 'TAB-S01',
		addColumn: 'TAB-S02',
		deleteColumn: 'TAB-S03',
		moveColumn: 'TAB-S04',
		changeColumnTab: 'TAB-S05',
		changeType: 'TAB-S06',
		subscribeSelectedTable: 'TAB-S07',
		unsubscribeSelectedTable: 'TAB-S08',
		// Helpers
		updateTableReq: 'TAB-X01'
	},
	tableApi: {
		createColumn: 'TAB-A01',
		updateColumn: 'TAB-A02',
		deleteColumn: 'TAB-A03'
	},
	tableDataSagas: {
		loadData: 'TDA-S01',
		addRows: 'TDA-S02',
		updateRows: 'TDA-S03',
		deleteRow: 'TDA-S04',
		searchColumn: 'TDA-S05',
		uploadFile: 'TDA-S06',
		fileTransmitSuccess: 'TDA-S07',
		deleteFile: 'TDA-S08'
	},
	tableDataApi: {
		search: 'TDA-A01',
		getData: 'TDA-A02',
		createRows: 'TDA-A03',
		updateRows: 'TDA-A04',
		deleteRow: 'TDA-A05',
		uploadFile: 'TDA-A06',
		deleteFile: 'TDA-A07'
	},
	teamSagas: {
		createTeam: 'TEA-S01',
		updateTeam: 'TEA-S02',
		deleteTeam: 'TEA-S03',
		inviteUser: 'TEA-S04',
		updateUser: 'TEA-S05',
		deleteUser: 'TEA-S06',
		acceptInvite: 'TEA-S07',
		rejectInvite: 'TEA-S08'
	},
	teamApi: {
		createTeam: 'TEA-A01',
		updateTeam: 'TEA-A02',
		deleteTeam: 'TEA-A03',
		inviteUser: 'TEA-A04',
		updateUser: 'TEA-A05',
		deleteUser: 'TEA-A06',
		acceptInvite: 'TEA-A07',
		rejectInvite: 'TEA-A08'
	},
	modelCategorySagas: {
		createCategory: 'MCT-S01',
		deleteCategory: 'MCT-S02',
		createRelationship: 'MCT-S03',
		deleteRelationship: 'MCT-S04',
		connectVariable: 'MCT-S05',
		disconnectVariable: 'MCT-S06',
		connectTable: 'MCT-S07',
		disconnectTable: 'MCT-S08'
	},
	fileSagas: {
		extractFileHeaders: 'FIL-S01',
		uploadFile: 'FIL-S02',
		deleteFile: 'FIL-S03',
		// Helpers
		searchFilesReq: 'FIL-X01'
	},
	fileApi: {
		readFirstLines: 'FIL-A01',
		uploadFile: 'FIL-A02',
		deleteFile: 'FIL-A03'
	},
	connectorSagas: {
		createConnector: 'CON-S01',
		updateConnector: 'CON-S02',
		deleteConnector: 'CON-S03',
		loadJobs: 'CON-S04',
		sync: 'CON-S05'
	},
	connectorApi: {
		createConnector: 'CON-A01',
		updateConnector: 'CON-A02',
		remove: 'CON-A03',
		sync: 'CON-A04',
		jobs: 'CON-A05'
	},
	sourceSagas: {
		create: 'SOU-S01',
		check: 'SOU-S02',
		update: 'SOU-S03',
		discover: 'SOU-S04',
		remove: 'SOU-S05'
	},
	sourceApi: {
		create: 'SOU-A01',
		check: 'SOU-A02',
		discover: 'SOU-A03',
		update: 'SOU-A04',
		remove: 'SOU-A05'
	},
	authSagas: {
		authenticate: 'AUT-S01',
		logout: 'AUT-S02',
		signInWithGoogle: 'AUT-S03'
	},
	authApi: {
		signInWithGoogle: 'AUT-A01'
	},
	billingSagas: {
		checkoutPlan: 'BIL-S01',
		checkoutAddon: 'BIL-S02',
		customerPortal: 'BIL-S03'
	},
	billingApi: {
		checkoutPlan: 'BIL-A01',
		checkoutAddon: 'BIL-A02',
		customerPortal: 'BIL-A03'
	},
	userSagas: {
		updateUser: 'USR-S01',
		deleteUser: 'USR-S02'
	},
	userApi: {
		updateUser: 'USR-A01',
		deleteUser: 'USR-A02'
	},
	modelSagas: {
		updateDates: 'MOD-S01',
		deleteTab: 'MOD-S02',
		changeGroupTab: 'MOD-S03',
		setVariableCategories: 'MOD-S04',
		setVariableCategoryAggr: 'MOD-S05',
		calculateRows: 'MOD-S06',
		addRow: 'MOD-S07',
		deleteRow: 'MOD-S08',
		toggleRow: 'MOD-S09',
		moveRow: 'MOD-S10',
		exportVariable: 'MOD-S11',
		// Helpers
		updateModelReq: 'MOD-X01',
		createVariableReq: 'MOD-X02',
		updateVariableReq: 'MOD-X03',
		deleteVariableReq: 'MOD-X04'
	},
	modelApi: {
		createScheduler: 'MOD-A01',
		updateScheduler: 'MOD-A02',
		deleteScheduler: 'MOD-A03',
		exportVariable: 'MOD-A04'
	},
	chartSagas: {
		createChart: 'CHT-S01',
		deleteChart: 'CHT-S02',
		moveChart: 'CHT-S03',
		updateVariable: 'CHT-S04',
		setVariableCategories: 'CHT-S05',
		// Helpers
		createChartHelperReq: 'CHT-X01',
		updateChartReq: 'CHT-X02',
		deleteChartHelperReq: 'CHT-X03'
	},
	modelEngineSagas: {
		loadCalculationSocket: 'MEN-S03',
		recompute: 'MEN-S04'
	},
	viewSagas: {
		setInView: 'SVI-S01',
		// Helpers
		createPersonalViewReq: 'SVI-X01',
		updatePersonalViewReq: 'SVI-X02',
		deletePersonalViewReq: 'SVI-X03',
		createSharedViewReq: 'SVI-X04',
		updateSharedViewReq: 'SVI-X05',
		deleteSharedViewReq: 'SVI-X06'
	},
	modelVizSagas: {
		loadVizSocket: 'MVI-S01',
		updateRowData: 'MVI-S02',
		updateChartData: 'MVI-S03'
	}
}
