// Replace parameters by values
export function replaceParam(configElem, action, excludeEmptyParams = false) {
	if (configElem == null) return null

	var newConfigElem = []
	configElem.map((element) => {
		if(element.value != null) newConfigElem.push(element) 
		else if (!excludeEmptyParams || (element.param != null && action[element.param] != null)) newConfigElem.push({ ...element, value: action[element.param] })
		return null
	})
	return newConfigElem
}

// Generate search keywords when saving a new document
export function generateKeywords(literal) {
	var wordArr = literal.toLowerCase().split(' ')
	wordArr.push(literal)
	var searchableKeywords = []
	var alreadyThere = {}
	let prevKey = ''

	for (const word of wordArr) {
		const charArr = word.toLowerCase().split('')

		for (const char of charArr) {
			const keyword = prevKey + char

			if (!alreadyThere[keyword]) {
				searchableKeywords.push(keyword)
				alreadyThere[keyword] = true
			}
			prevKey = keyword
		}
		prevKey = ''
	}

	return searchableKeywords
}
