import { Popover, ToggleButtonGroup, ToggleButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { WindowSplitRightIcon, WindowSplitBottomIcon } from 'common/icons'

export default function PanelButton({ anchor, value, onClose, onChange }) {
	return (
		<Popover open={Boolean(anchor)} anchorEl={anchor} onClose={onClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
			<ToggleButtonGroup value={value} exclusive onChange={onChange} size="small">
				<ToggleButton value="horizontal">
					<WindowSplitRightIcon className="text-textGray w-[20px] h-[20px]" />
				</ToggleButton>
				<ToggleButton value="vertical">
					<WindowSplitBottomIcon className="text-textGray w-[20px] h-[20px]" />
				</ToggleButton>
				<ToggleButton value="none">
					<CloseIcon className="text-textGray w-[20px] h-[20px]" />
				</ToggleButton>
			</ToggleButtonGroup>
		</Popover>
	)
}
