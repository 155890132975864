// #### SUBSCRIBE TO MODEL
export const LOAD_MODEL = 'modelSaga/LOAD_MODEL'
export const CANCEL_MODEL = 'modelSaga/CANCEL_MODEL'
export const LOAD_VARIABLES = 'modelSaga/LOAD_VARIABLES'
export const CANCEL_VARIABLES = 'modelSaga/CANCEL_VARIABLES'
// #### MANAGE MODEL
export const UPDATE_MODEL = 'modelSaga/UPDATE_MODEL'
export const UPDATE_DATES = 'modelSaga/UPDATE_DATES'
export const DELETE_TAB = 'modelSaga/DELETE_TAB'
export const CHANGE_GROUP_TAB = 'modelSaga/CHANGE_GROUP_TAB'
// #### LOAD & MANAGE VARIABLES
export const CREATE_VARIABLE = 'modelSaga/CREATE_VARIABLE'
export const UPDATE_VARIABLE = 'modelSaga/UPDATE_VARIABLE'
export const DELETE_VARIABLE = 'modelSaga/DELETE_VARIABLE'
export const SET_VARIABLE_CATEGORIES = 'modelSaga/SET_VARIABLE_CATEGORIES'
export const SET_VARIABLE_PUBLIC = 'modelSaga/SET_VARIABLE_PUBLIC'
export const SET_VARIABLE_CATEGORY_AGGR = 'modelSaga/SET_VARIABLE_CATEGORY_AGGR'
export const PUBLISH_VARIABLES = 'modelSaga/PUBLISH_VARIABLES'
export const SEARCH_PUBLISHED_VARIABLES = 'modelSaga/SEARCH_PUBLISHED_VARIABLES'
export const LOAD_SELECTED_VARIABLE = 'modelSaga/LOAD_SELECTED_VARIABLE'
export const EXPORT_VARIABLE = 'modelSaga/EXPORT_VARIABLE'
export const NEXT_PAGE = 'modelSaga/NEXT_PAGE'
// #### ROW AND COLUMN OPERATIONS
export const ADD_ROW = 'modelSaga/ADD_ROW'
export const DELETE_ROW = 'modelSaga/DELETE_ROW'
export const MOVE_ROW = 'modelSaga/MOVE_ROW'

// #### SUBSCRIBE TO MODEL
export const loadModel = ({ tid, aid }) => {
	return { type: LOAD_MODEL, tid, aid, id: aid }
}

export const cancelModel = ({ id }) => {
	return { type: CANCEL_MODEL, id }
}

export const loadVariables = ({ tid, aid }) => {
	return { type: LOAD_VARIABLES, tid, aid, id: aid }
}

export const cancelVariables = ({ id }) => {
	return { type: CANCEL_VARIABLES, id }
}

// #### MANAGE MODEL
export const updateModel = ({ tid, aid, content }) => {
	return { type: UPDATE_MODEL, tid, aid, content }
}

export const updateDates = ({ tid, teamId, aid, frequency, startDate, endDate, timezone, fixedTime, schedulerId }) => {
	return { type: UPDATE_DATES, tid, teamId, aid, frequency, startDate, endDate, timezone, fixedTime, schedulerId }
}

export const deleteTab = ({ tid, teamId, aid, tabIndex }) => {
	return { type: DELETE_TAB, tid, teamId, aid, tabIndex }
}

export const changeGroupTab = ({ tid, aid, groupId, tabIndex }) => {
	return { type: CHANGE_GROUP_TAB, tid, aid, groupId, tabIndex }
}

// #### MANAGE VARIABLES
export const createVariable = ({ tid, aid, vid, content }) => {
	return { type: CREATE_VARIABLE, tid, aid, vid, id: vid, content }
}

export const updateVariable = ({ tid, aid, vid, content }) => {
	return { type: UPDATE_VARIABLE, tid, aid, vid, id: vid, content }
}

export const deleteVariable = ({ tid, aid, vid }) => {
	return { type: DELETE_VARIABLE, tid, aid, vid, id: vid }
}

export const setVariableCategories = ({ tid, aid, vid, categories, isConnected, permits }) => {
	return { type: SET_VARIABLE_CATEGORIES, tid, aid, vid, categories, isConnected, permits }
}

export const setVariablePublic = ({ tid, teamId, aid, vid, updates }) => {
	return { type: SET_VARIABLE_PUBLIC, tid, teamId, aid, vid, updates }
}

export const setVariableCategoryAggr = ({ tid, aid, vid, categoryAggregation }) => {
	return { type: SET_VARIABLE_CATEGORY_AGGR, tid, aid, vid, categoryAggregation }
}

export const publishVariables = ({ tid, teamId, vid, content }) => {
	return { type: PUBLISH_VARIABLES, tid, teamId, vid, content }
}

export const searchPublishedVariables = ({ key, tid, teamId, uid, searchTerm, startAfter, where }) => {
	return { type: SEARCH_PUBLISHED_VARIABLES, key, tid, teamId, uid, searchTerm: searchTerm?.toLowerCase(), startAfter, where }
}

export const loadSelectedVariable = ({ key, tid, teamId, id }) => {
	return { type: LOAD_SELECTED_VARIABLE, key, tid, teamId, id }
}

export const exportVariable = ({ tid, teamId, aid, vid }) => {
	return { type: EXPORT_VARIABLE, tid, teamId, aid, vid }
}

export const nextPage = ({ aid, vid, page, permits }) => {
	return { type: NEXT_PAGE, aid, vid, page, permits }
}

// #### ROW AND COLUMN OPERATIONS
export const addRow = ({ tid, teamId, aid, groupId, isGroup }) => {
	return { type: ADD_ROW, tid, teamId, aid, groupId, isGroup }
}

export const deleteRow = ({ tid, teamId, aid, rowId, isGroup }) => {
	return { type: DELETE_ROW, tid, teamId, aid, rowId, isGroup }
}

export const moveRow = ({ tid, aid, dragIndex, dropIndex, rows }) => {
	return { type: MOVE_ROW, tid, aid, dragIndex, dropIndex, rows }
}
