import { t } from 'i18next'

export const COMMUNITY_CATEGORIES = {
	'demand-planning': { key: 'demand-planning', label: t('common:communityCategory.demandPlanning') },
	finance: { key: 'finance', label: t('common:communityCategory.finance') },
	'human-resources': { key: 'human-resources', label: t('common:communityCategory.humanResources') },
	'marketing-sales': { key: 'marketing-sales', label: t('common:communityCategory.marketingSales') },
	'personal-finance': { key: 'personal-finance', label: t('common:communityCategory.personalFinance') },
	'product-management': { key: 'product-management', label: t('common:communityCategory.productManagement') },
	'supply-chain': { key: 'supply-chain', label: t('common:communityCategory.supplyChain') }
}
