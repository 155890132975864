import { backend } from 'common/config/backend'
import { storage } from 'common/config/firebase'
import { ref as storageRef, getDownloadURL, deleteObject, uploadBytesResumable } from 'firebase/storage'
import store from 'common/store/store'
import { fileTransmitSuccess } from 'table/saga-actions/tableDataActions'
import { fileTransmit } from 'table/store/tableDataReducer'
import { t } from 'i18next'
import { throwAPIError } from 'common/config/errors'
import fileDownload from "js-file-download";


const FILE_NAME = 'tableDataApi'

export async function search(token, tid, teamId, table, column, search, page, maxNumResults) {
	let config = { headers: { Authorization: 'Bearer ' + token } }
	let body = { tenantId: tid, schema: teamId, table, field: column, search: { col: column, term: search }, limit: maxNumResults, page }
	return backend
		.post('/tables', body, config)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, search.name)
			return resp.data
		})
		.catch((error) => {
			throwAPIError(error, error.response.status, FILE_NAME, search.name)
		})
}

export async function getData(token, tid, teamId, aid, sort, filter, page, limit, includeCount) {
	let config = { headers: { Authorization: 'Bearer ' + token } }
	let body = { tenantId: tid, schema: teamId, table: aid, order: sort || [], filter: filter || [], limit, page: page + 1, includeCount }
	return backend
		.post('/tables', body, config)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, getData.name)
			return resp.data
		})
		.catch((error) => {
			throwAPIError(error, error.response.status, FILE_NAME, getData.name)
		})
}

export async function downloadCSV(token, tid, teamId, tableId, sort, filter) {
	let config = { headers: { Authorization: 'Bearer ' + token } }
	let body = { tenantId: tid, teamId, tableId, sort, filter }
	return backend
		.post('/tables/exports', body, config)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, getData.name)
			backend.get(resp.data.data, {
				responseType: 'blob',
			  })
			  .then((res) => {
				return fileDownload(res.data, tableId)
			})
		})
		.catch((error) => {
			throwAPIError(error, error.response.status, FILE_NAME, getData.name)
		})
}

export async function clearData(token, tid, teamId, aid) {
	let config = {
		data: { tenantId: tid, schema: teamId, table: aid },
		headers: { Authorization: 'Bearer ' + token }
	}
	return backend
		.delete('/tables', config)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, getData.name)
			return resp.data
		})
		.catch((error) => {
			throwAPIError(error, error.response.status, FILE_NAME, getData.name)
		})
}

export async function createRows(token, tid, teamId, aid, rows) {
	let config = { headers: { Authorization: 'Bearer ' + token } }
	let body = { tenantId: tid, schema: teamId, table: aid, rows: rows }
	return backend
		.post('/tables/rows', body, config)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, createRows.name)
			return { data: resp.data?.data }
		})
		.catch((error) => {
			throwAPIError(error, error.response.status, FILE_NAME, createRows.name)
		})
}

export async function updateRows(token, tid, teamId, aid, rows) {
	let config = { headers: { Authorization: 'Bearer ' + token } }
	let body = { tenantId: tid, schema: teamId, table: aid, rows }
	return backend
		.patch('/tables/rows', body, config)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, updateRows.name)
			return resp.data
		})
		.catch((error) => {
			throwAPIError(error, error.response.status, FILE_NAME, updateRows.name)
		})
}

export async function deleteRow(token, tid, teamId, aid, rowId) {
	let config = {
		data: { tenantId: tid, schema: teamId, table: aid, rowId },
		headers: { Authorization: 'Bearer ' + token }
	}
	return backend
		.delete('/tables/rows', config)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, deleteRow.name)
			return resp.data
		})
		.catch((error) => {
			throwAPIError(error, error.response.status, FILE_NAME, deleteRow.name)
		})
}

export async function uploadFile(tid, teamId, aid, uid, rowId, colId, rowIndex, file, fileId, filePath, fileName, fileSize, fileType) {
	const metadata = { contentType: fileType, customMetadata: { uid } }
	const fileRef = storageRef(storage, filePath)
	const uploadTask = uploadBytesResumable(fileRef, file, metadata)
	uploadTask.on(
		'state_changed',
		(snapshot) => {
			const progress = snapshot.totalBytes === 0 ? 0 : (snapshot.bytesTransferred / snapshot.totalBytes) * 100
			store.dispatch(fileTransmit({ key: aid, loading: true, progress, isError: false, error: null }))
		},
		(error) => store.dispatch(fileTransmit({ key: aid, loading: false, progress: 0, isError: true, error: t('table:file.uploadError') })),
		() =>
			getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl) =>
				store.dispatch(fileTransmitSuccess({ tid, teamId, aid, rowId, colId, rowIndex, fileId, fileName, fileSize, fileType, filePath, fileFullPath: downloadUrl }))
			)
	)
}

export async function deleteFile(filePath) {
	const fileRef = storageRef(storage, filePath)
	return deleteObject(fileRef)
		.then(() => true)
		.catch((error) => throwAPIError(error, null, FILE_NAME, deleteFile.name))
}
