import { useRef, useCallback } from 'react'

const DEFAULT_OPTIONS = { threshold: 0 }

const useLazyLoading = (fetchMore, isFetchingMore, noMoreToFetch, options = DEFAULT_OPTIONS) => {
	const observer = useRef()

	const triggerPaginationOnIntersection = useCallback(
		(elementNode) => {
			if (isFetchingMore) return
			//Removes the previously observed DOM node before observing another
			if (observer.current) {
				observer.current.disconnect()
			}
			if (noMoreToFetch) return
			observer.current = new IntersectionObserver(([entry]) => {
				if (entry.isIntersecting) {
					fetchMore()
				}
			}, options)
			if (elementNode) {
				observer.current.observe(elementNode)
			}
		},
		[isFetchingMore, fetchMore, noMoreToFetch]
	)

	return triggerPaginationOnIntersection
}

export default useLazyLoading
