import React, { useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import useNavigate from 'common/hooks/useNavigate'
import { useTranslation } from 'react-i18next'
import IntegratedTextInput from 'common/components/input/IntegratedTextInput'
import { updateAsset, deleteAsset } from 'common/saga-actions/assetActions'
import { CloseIcon, SettingsIcon, SearchIcon, CardIcon, DesignIcon } from 'common/icons/light/index'
import { Menu, MenuItem, Button } from '@mui/material'
import ActionConfirm from 'common/components/ActionConfirm'
import AssetShare from 'common/screens/asset/AssetShare'
import AssetSearch from 'common/screens/asset/AssetSearch'
import { useSplitWindow } from 'common/hooks/useSplitWindow'
import BarButton from 'common/screens/layout/BarButton'
import { useAssetPermits } from 'common/hooks/useAssetPermits'
import { TABLE_ROLES } from 'common/constants/roles'
import AccountButton from 'common/screens/account/AccountButton'
import { setInView } from 'common/saga-actions/viewActions'
import { TABLE_PARAMS } from 'table/constants/tableParameters'
import * as viewSel from 'common/store/viewSelector'
import * as tableSel from 'table/store/tableSelector'
import { PRODUCTS } from 'common/constants/products'
import { clearData, downloadCSV } from 'table/saga-actions/tableDataActions'
import { toggleDesignMode } from 'table/store/tableReducer'

export default function TableBar({ windowIndex = 0, reduced = false }) {
	const { t } = useTranslation(['common', 'model'])
	const dispatch = useDispatch()
	const { navigate } = useNavigate()
	const { id: aid } = useSplitWindow(windowIndex)
	const { id: mainId, type: mainType } = useSplitWindow(0)
	const permits = useAssetPermits(aid)
	const { canCreate } = permits

	const tid = useSelector((state) => state.auth.tid)
	const name = useSelector((state) => state.asset.asset[aid]?.data?.name)
	const teamId = useSelector((state) => state.team.teamId)
	const sid = useSelector((state) => state.space.sid)
	const searchPanel = useSelector((state) => viewSel.selectViewSearchPanel(state, aid))
	const cardPanel = useSelector((state) => viewSel.selectViewCardPanel(state, aid))
	const isDesigning = useSelector((state) => tableSel.selectTableIsDesigning(state, aid))

	const selectTableFilter = useMemo(() => viewSel.makeSelectTableFilter({ aid }), [aid])
	const filter = useSelector((state) => selectTableFilter(state))
	const selectTableSort = useMemo(() => viewSel.makeSelectTableSort({ aid }), [aid])
	const sort = useSelector((state) => selectTableSort(state))

	const [menuAnchor, setMenuAnchor] = useState(null)
	const [isDeleteOpen, setIsDeleteOpen] = useState(false)
	const [isClearDataOpen, setIsClearDataOpen] = useState(false)
	const [isShareOpen, setIsShareOpen] = useState(false)

	// #### FUNCTIONS
	function onUpdateAsset(value) {
		if (value?.length > 0) dispatch(updateAsset({ tid, aid, content: { name: value } }))
	}

	function onDeleteAsset() {
		dispatch(deleteAsset({ tid, aid, assetType: PRODUCTS.table.key }))
		setIsDeleteOpen(false)
		navigate(`/team/${teamId}/space/${sid}`)
	}

	function onClearData() {
		dispatch(clearData({ tid, teamId, aid }))
		setIsClearDataOpen(false)
	}

	function onCloseRightPanel() {
		navigate(`/${mainType}/${mainId}`)
	}

	function onToggleSearch() {
		const isOpen = !searchPanel?.isOpen || false
		const direction = isOpen ? 'horizontal' : searchPanel?.direction || null
		const position = isOpen ? 'left' : searchPanel?.position || null
		const size = TABLE_PARAMS.SEARCH_PANEL_SIZE
		dispatch(setInView({ aid, changes: [{ path: 'searchPanel', value: { isOpen, direction, position, size } }], permits }))
	}

	function onToggleCard() {
		const isOpen = !cardPanel?.isOpen || false
		const direction = isOpen ? 'horizontal' : cardPanel?.direction || null
		const position = isOpen ? 'right' : cardPanel?.position || null
		const size = TABLE_PARAMS.CARD_PANEL_SIZE
		dispatch(setInView({ aid, changes: [{ path: 'cardPanel', value: { isOpen, direction, position, size } }], permits }))
	}

	function onDownloadCSV() {
		dispatch(downloadCSV({ tid, teamId, aid, filter, sort }))
	}

	return (
		<>
			<div className="mx-4 flex flex-row items-center justify-between flex-1 h-full">
				<div className="flex flex-row items-center h-full">
					<div>
						<IntegratedTextInput
							canGrow
							minWidth={20}
							value={name}
							setValue={onUpdateAsset}
							className="text-lg text-barTextStrong bg-barBg hover:shadow-barBorderStrong focus:shadow-barActive mr-2"
							isDisabled={!canCreate}
						/>
					</div>
					{canCreate && <BarButton icon={DesignIcon} className="design-button" label={t('table:menu.design')} isActive={isDesigning} onClick={() => dispatch(toggleDesignMode({ id: aid }))} />}
					<BarButton icon={SearchIcon} label={t('table:menu.search')} isActive={searchPanel?.isOpen} onClick={onToggleSearch} />
					<BarButton icon={CardIcon} label={t('table:menu.card')} isActive={cardPanel?.isOpen} onClick={onToggleCard} />
				</div>
				<div className="flex flex-row items-center text-barTextStrong h-full">
					{!reduced && (
						<>
							<AssetSearch />
							<div className="mr-4" />
							{canCreate && (
								<>
									<Button variant="contained" size="small" className="mr-4 buttonContainedContrast" onClick={() => setIsShareOpen(true)}>
										{t('common:buttons.share')}
									</Button>
									<BarButton icon={SettingsIcon} label={t('common:buttons.settings')} onClick={(e) => setMenuAnchor(e.currentTarget)} />
								</>
							)}
							<AccountButton />
						</>
					)}
					{reduced && windowIndex === 1 && <BarButton icon={CloseIcon} label={t('common:buttons.close')} onClick={onCloseRightPanel} />}
				</div>
			</div>
			<Menu anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={() => setMenuAnchor(null)}>
				<MenuItem dense onClick={() => onDownloadCSV()}>
					{t('table:table.downloadCSV')}
				</MenuItem>
				<MenuItem dense onClick={() => setIsClearDataOpen(true)}>
					{t('table:table.clearData')}
				</MenuItem>
				<MenuItem dense onClick={() => setIsDeleteOpen(true)}>
					{t('table:table.delete')}
				</MenuItem>
			</Menu>
			<ActionConfirm open={isDeleteOpen} content={t('common:messages.deleteConfirm')} onClose={() => setIsDeleteOpen(false)} onConfirm={onDeleteAsset} />
			<ActionConfirm open={isClearDataOpen} content={t('table:messages.deleteDataConfirm')} onClose={() => setIsClearDataOpen(false)} onConfirm={onClearData} />
			<AssetShare isOpen={isShareOpen} onClose={() => setIsShareOpen(false)} roles={TABLE_ROLES} />
		</>
	)
}
