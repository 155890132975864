import CreateCheckCell from 'common/components/grid/cells/CreateCheckCell'

// Those values are used when pasting values, all those values will be considered false, any other true
const FALSY = ['', 'false', 'no', 'off', 'disabled', '0', 'n', 'f', 'unchecked', 'undefined', 'null', 'wrong', 'negative']

export const cellCheckbox = {
	component: CreateCheckCell,
	config: {
		alignRight: false,
		formatBlurredInput: ({ value, typeProps }) => value,
		formatCopyValue: ({ value, typeProps }) => value,
		parseUserInput: ({ value, typeProps }) => value,
		parsePastedValue: ({ value, typeProps, localeFallback }) => (value && !FALSY.includes(value.toLowerCase()) ? 1 : 0)
	}
}
