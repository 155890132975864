import { createSlice } from '@reduxjs/toolkit'

export const navigationSlice = createSlice({
	name: 'navigation',
	initialState: {
		previous: 0,
		next: 0,
		canGoBack: false,
		canGoForward: false
	},
	reducers: {
		goBack: (state, action) => {
			state.canGoBack = state.previous - 1 > 0
			state.canGoForward = true
			state.previous -= 1
			state.next += 1
		},
		goForward: (state, action) => {
			state.canGoBack = true
			state.canGoForward = state.next - 1 > 0
			state.previous += 1
			state.next -= 1
		},
		push: (state, action) => {
			state.canGoBack = true
			state.canGoForward = false
			state.previous += 1
			state.next = 0
		},
		replace: (state, action) => {
			// do nothing
		}
	}
})

export const { goBack, goForward, push, replace } = navigationSlice.actions

export default navigationSlice.reducer
