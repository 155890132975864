import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { loadAsset, cancelAsset, loadAssetUsers, cancelAssetUsers } from 'common/saga-actions/assetActions'
import { PRODUCTS } from 'common/constants/products'
import { cancelTable, loadTable } from 'table/saga-actions/tableActions'
import { cancelModel, cancelVariables, loadModel, loadVariables } from 'model/saga-actions/modelActions'
import { cancelCharts, loadCharts } from 'model/saga-actions/chartActions'
import { loadSharedViews, loadPersonalViews, cancelPersonalViews, cancelSharedViews } from 'common/saga-actions/viewActions'
import { cancelCurrentView } from 'common/store/viewReducer'
import { clearCharts } from 'model/store/modelDataReducer'

export default function useAssetLoader({ aid, type }) {
	const dispatch = useDispatch()

	const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)
	const tid = useSelector((state) => state.auth.tid)
	const uid = useSelector((state) => state.auth.uid)

	// #### EFFECTS
	// Load asset
	useEffect(() => {
		const lTid = tid
		const id = aid
		if (lTid && id) {
			dispatch(loadAsset({ tid, aid }))
			dispatch(loadSharedViews({ tid, aid }))
		}
		return () => {
			if (lTid && id) {
				dispatch(cancelAsset({ id }))
				dispatch(cancelSharedViews({ id }))
				dispatch(cancelCurrentView({ id }))
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tid, aid])

	// Load logged-in asset info
	useEffect(() => {
		const lIsLoggedIn = isLoggedIn
		const lTid = tid
		const id = aid
		if (lIsLoggedIn && lTid && id) {
			dispatch(loadAssetUsers({ tid, aid }))
			dispatch(loadPersonalViews({ tid, uid, aid, vid: aid }))
		}
		return () => {
			if (lIsLoggedIn && lTid && id) {
				dispatch(cancelAssetUsers({ id }))
				dispatch(cancelPersonalViews({ id }))
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoggedIn, tid, aid])

	// Load table-specific data
	useEffect(() => {
		const lTid = tid
		const id = aid
		const isTable = type === PRODUCTS.table.key
		if (lTid && id && isTable) dispatch(loadTable({ tid, aid }))
		return () => {
			if (lTid && id && isTable) dispatch(cancelTable({ id }))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tid, aid, type === PRODUCTS.table.key])

	// Load model-specific data
	useEffect(() => {
		const lTid = tid
		const id = aid
		const isModel = type === PRODUCTS.model.key

		if (lTid && id && isModel) {
			dispatch(loadModel({ tid, aid }))
			dispatch(loadVariables({ tid, aid }))
			dispatch(loadCharts({ tid, aid }))
		}
		return () => {
			if (lTid && id && isModel) {
				dispatch(cancelModel({ id }))
				dispatch(cancelVariables({ id }))
				dispatch(cancelCharts({ id }))
				dispatch(clearCharts({ id }))
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tid, aid, type === PRODUCTS.model.key])

	return null
}
