import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Avatar, LinearProgress } from '@mui/material'
import { LIMITS_TEAM, LIMITS_MODEL, LIMITS_TABLE } from 'common/constants/limits'
import { sortArrayOfObjects } from 'common/utils/arrays'
import HomeIcon from '@mui/icons-material/HomeOutlined'
import { PRODUCTS } from 'common/constants/products'

export default function Limits() {
	const { t } = useTranslation('common')

	// #### REDUX
	const teamName = useSelector((state) => state.team?.team?.name)
	const teamLimits = useSelector((state) => state.team?.team?.limits)
	const teamConsumption = useSelector((state) => state.team?.team?.consumption)
	const assets = useSelector((state) => state.asset.assets?.result)

	return (
		<div className="grid grid-cols-cards gap-[32px]">
			<div className="paper p-4">
				<div className="flex flex-row items-center mb-2">
					<Avatar className={`h-[20px] w-[20px] mr-2`} variant="rounded">
						<HomeIcon className="text-[15px]" />
					</Avatar>
					<div className="text-textGray text-xs font-medium">{teamName}</div>
				</div>
				{Object.values(LIMITS_TEAM).map((limit, index) => {
					var total = (teamLimits && teamLimits[limit.key]) || 0
					var progress
					if (limit.key === LIMITS_TEAM.categoryItems.key) {
						progress = (teamConsumption && teamConsumption[limit.key] && Math.max(...teamConsumption[limit.key]?.map((o) => Object.values(o)))) || 0
					} else {
						progress = (teamConsumption && teamConsumption[limit.key]) || 0
					}
					if (limit.key === LIMITS_TEAM.storage.key) {
						progress = (progress / 1000000).toFixed(2)
						total = total / 1000000
					}
					const percent = total === 0 ? 100 : (progress / total) * 100
					return (
						<div key={`teamLimit#${index}`} className="flex flex-row items-center">
							<div className="text-sm whitespace-nowrap flex-1 shrink-0">{`${progress} / ${total}${limit.unit ? ' ' + t(limit.unit) : ''} ${t(limit.label)}`}</div>
							<LinearProgress variant="determinate" value={percent} className="ml-4 flex-1 shrink-0 rounded h-[8px]" />
						</div>
					)
				})}
			</div>

			{assets &&
				sortArrayOfObjects(Object.entries(assets), (asset) => asset[1]?.name || '').map((entry) => {
					const assetId = entry[0]
					const asset = entry[1]
					const product = PRODUCTS[asset.type]
					const limitDef = asset.type === PRODUCTS.table.key ? LIMITS_TABLE : LIMITS_MODEL
					return (
						<div key={`assetLimits#${assetId}`} className="paper p-4">
							<div className="flex flex-row items-center mb-2">
								<Avatar className={`h-[20px] w-[20px] mr-2`} variant="rounded">
									{React.createElement(product.icon, { className: 'text-[13px]' })}
								</Avatar>
								<div className="text-textGray text-xs font-medium">{asset.name}</div>
							</div>
							{Object.values(limitDef).map((limit, index) => {
								const total = (asset.limits && asset.limits[limit.key]) || 0
								const progress = (asset.consumption && asset.consumption[limit.key]) || 0
								const percent = total === 0 ? 100 : (progress / total) * 100
								return (
									<div key={`assetLimit#${assetId}#${index}`} className="flex flex-row items-center">
										<div className="text-sm whitespace-nowrap flex-1 shrink-0">{`${progress} / ${total}${limit.unit ? ' ' + t(limit.unit) : ''} ${t(limit.label)}`}</div>
										<LinearProgress variant="determinate" value={percent} className="ml-4 flex-1 shrink-0 rounded h-[8px]" />
									</div>
								)
							})}
						</div>
					)
				})}
		</div>
	)
}
