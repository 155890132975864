import { createSlice } from '@reduxjs/toolkit'
import { isEqual } from 'lodash'

export const modelDataSlice = createSlice({
	name: 'modelData',
	initialState: {
		data: {},
		breakdown: {},
		breakdownCount: {}, // number of rows to display in breakdown - triggers row recompute
		errors: {},
		chartData: {},
		isEngineReady: {},
		isVizReady: {}
	},
	reducers: {
		loadData: (state, action) => {
			state.data[action.payload.aid] = state.data[action.payload.aid] ? { ...state.data[action.payload.aid], [action.payload.id]: action.payload } : { [action.payload.id]: action.payload }
		},
		clearData: (state, action) => {
			state.data[action.payload.aid][action.payload.id] = null
		},
		clearAllData: (state, action) => {
			state.data[action.payload.aid] = null
		},
		loadChart: (state, action) => {
			state.chartData[action.payload.aid] = state.chartData[action.payload.aid]
				? { ...state.chartData[action.payload.aid], [action.payload.chartId]: { ...state.chartData[action.payload.aid][action.payload.chartId], [action.payload.id]: action.payload } }
				: { [action.payload.chartId]: { [action.payload.id]: action.payload } }
		},
		clearCharts: (state, action) => {
			state.chartData[action.payload.id] = null
		},
		loadBreakdown: (state, action) => {
			state.breakdown[action.payload.aid] = state.breakdown[action.payload.aid] ? { ...state.breakdown[action.payload.aid], [action.payload.id]: action.payload } : { [action.payload.id]: action.payload }
			state.breakdownCount[action.payload.aid] = updateViewProps({
				prev: state.breakdownCount[action.payload.aid] || {},
				next: { ...(state.breakdownCount[action.payload.aid] || {}), [action.payload.id]: action.payload.values?.length || 0 }
			})
		},
		clearBreakdown: (state, action) => {
			state.breakdown[action.payload.id] = null
		},
		clearVariableBreakdown: (state, action) => {
			state.breakdownCount[action.payload.id][action.payload.variableId] = 0
			state.breakdown[action.payload.id][action.payload.variableId] = null
		},
		loadErrors: (state, action) => {
			state.errors[action.payload.aid] = { ...action.payload.errors }
		},
		clearErrors: (state, action) => {
			state.errors[action.payload.id] = null
		},
		setEngineReady: (state, action) => {
			state.isEngineReady[action.payload.id] = action.payload.isEngineReady
		},
		setVizReady: (state, action) => {
			state.isVizReady[action.payload.id] = action.payload.isVizReady
		}
	}
})

export const { loadData, clearData, clearAllData, loadChart, clearCharts, loadBreakdown, clearBreakdown, clearVariableBreakdown, loadErrors, clearErrors, setEngineReady, setVizReady } = modelDataSlice.actions

export default modelDataSlice.reducer

function updateViewProps({ prev, next, update = true }) {
	return isEqual(prev, next) || !update ? prev : next
}
