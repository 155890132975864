import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { FormControlLabel, MenuItem, Switch, TextField } from '@mui/material'
import { DateTime } from 'luxon'
import { LOCALE_DATE_MASK } from 'common/constants/locales'
import { DATA_TYPES } from 'common/constants/dataTypes'
import { DATE_FORMATS, NUMBER_FORMATS, TIME_FORMATS } from 'common/constants/formats'
import { MODEL_VAR_TYPES } from 'model/constants/modelTypes'
import useLocale from 'common/hooks/useLocale'
import { updateTable } from 'table/saga-actions/tableActions'
import { updateVariable } from 'model/saga-actions/modelActions'
import TableTypeList from 'table/screens/type/TableTypeList'

export default function TableTypeSettings({ aid, varId, variable, isModelVersion = false }) {
	const dispatch = useDispatch()
	const { t } = useTranslation(['common', 'table'])
	const VAR_TYPES = isModelVersion ? MODEL_VAR_TYPES : DATA_TYPES

	// #### REDUX
	const tid = useSelector((state) => state.auth.tid)
	const typePropsVal = variable?.typeProps
	const typePropsDef = variable ? VAR_TYPES[variable.type].typeProps : null
	const typeConstants = variable ? VAR_TYPES[variable.type].constants : null

	// #### DATE TYPE VARIABLES
	const dateProps = typePropsVal?.dateFormat ? DATE_FORMATS[typePropsVal.dateFormat] : null
	const defaultLocale = useLocale()

	// #### FUNCTIONS
	function onChange({ attribute, value }) {
		if (attribute === 'numOfDecimals' && value !== '' && (isNaN(value) || value < 0 || value > typeConstants.maxDecimals)) return

		if (isModelVersion) dispatch(updateVariable({ tid, aid, vid: varId, content: { [`typeProps.${attribute}`]: value } }))
		else dispatch(updateTable({ tid, aid, content: { [`variables.${varId}.typeProps.${attribute}`]: value } }))
	}

	function onUpdate(content) {
		if (!isModelVersion) dispatch(updateTable({ tid, aid, content }))
	}

	return (
		<div className="flex flex-col text-sm mb-4">
			{/* No settings message */}
			{(!typePropsDef || !(Object.keys(typePropsDef)?.length > 0)) && <span className="mt-4">{t('table:typeSettings.noSettings')}</span>}
			{/* Currency symbol */}
			{typePropsDef?.currencySymbol && (
				<>
					<span className="mt-4 font-medium">{t('table:typeSettings.currencySymbol')}</span>
					<TextField
						fullWidth
						variant="outlined"
						size="small"
						value={typePropsVal?.currencySymbol}
						onChange={(event) => onChange({ attribute: 'currencySymbol', value: event.target.value })}
						inputProps={{ className: 'text-sm' }}
					/>
				</>
			)}
			{/* Number format */}
			{typePropsDef?.numberFormat && (
				<>
					<span className="mt-4 font-medium text-textGray">{t('table:typeSettings.numberFormat')}</span>
					<TextField
						select
						fullWidth
						variant="outlined"
						size="small"
						value={typePropsVal?.numberFormat}
						onChange={(event) => onChange({ attribute: 'numberFormat', value: event.target.value })}
						inputProps={{ className: 'text-sm' }}
					>
						{Object.keys(NUMBER_FORMATS).map((key) => (
							<MenuItem key={key} value={key} className="text-sm">
								{t(NUMBER_FORMATS[key].i18n, {
									number: new Intl.NumberFormat(NUMBER_FORMATS[key].locale, NUMBER_FORMATS[key].options).format(1000.5)
								})}
							</MenuItem>
						))}
					</TextField>
				</>
			)}
			{/* Allow decimals */}
			{typePropsDef?.allowDecimals && (
				<FormControlLabel
					control={<Switch checked={typePropsVal?.allowDecimals} onChange={(e) => onChange({ attribute: 'allowDecimals', value: e.target.checked })} color="primary" size="small" />}
					label={t('table:typeSettings.allowDecimals')}
					classes={{ root: 'mt-4', label: 'ml-2 text-sm font-medium text-textGray' }}
				/>
			)}
			{/* Number of decimals */}
			{typePropsDef?.numOfDecimals && typePropsVal?.allowDecimals && (
				<>
					<span className="mt-4 font-medium text-textGray">{t('table:typeSettings.numOfDecimals')}</span>
					<TextField
						type="number"
						fullWidth
						variant="outlined"
						margin="dense"
						size="small"
						value={typePropsVal?.numOfDecimals}
						onChange={(event) => onChange({ attribute: 'numOfDecimals', value: event.target.value })}
						inputProps={{ step: 1, min: 0, max: typeConstants?.maxDecimals, className: 'text-sm' }}
					/>
				</>
			)}
			{/* Allow negative numbers */}
			{!isModelVersion && typePropsDef?.allowNegative && (
				<FormControlLabel
					control={<Switch checked={typePropsVal?.allowNegative} onChange={(e) => onChange({ attribute: 'allowNegative', value: e.target.checked })} color="primary" size="small" />}
					label={t('table:typeSettings.allowNegative')}
					classes={{ root: 'mt-4', label: 'ml-2 text-sm font-medium text-textGray' }}
				/>
			)}
			{/* Date format */}
			{typePropsDef?.dateFormat && (
				<>
					<span className="mt-4 font-medium text-textGray">{t('table:typeSettings.dateFormat')}</span>
					<TextField
						select
						fullWidth
						variant="outlined"
						size="small"
						value={typePropsVal?.dateFormat}
						onChange={(event) => onChange({ attribute: 'dateFormat', value: event.target.value })}
						inputProps={{ className: 'text-sm' }}
					>
						{Object.keys(DATE_FORMATS).map((key) => {
							const dateFormat = DATE_FORMATS[key]
							const locale = dateFormat.locale || defaultLocale.locale
							const mask = LOCALE_DATE_MASK[locale].mask
							const dateStr = DateTime.now().setLocale(locale).toFormat(mask)
							return (
								<MenuItem key={key} value={key} className="text-sm">
									{t(dateFormat.i18n, { date: dateStr })}
								</MenuItem>
							)
						})}
					</TextField>
				</>
			)}
			{/* Date friendly display */}
			{typePropsDef?.friendlyDate && (
				<FormControlLabel
					control={<Switch checked={typePropsVal?.friendlyDate} onChange={(e) => onChange({ attribute: 'friendlyDate', value: e.target.checked })} color="primary" size="small" />}
					label={t('table:typeSettings.friendlyDate', {
						date: DateTime.now()
							.setLocale(dateProps.locale || defaultLocale.locale)
							.toFormat('dd MMMM yyyy')
					})}
					classes={{ root: 'mt-4', label: 'ml-2 text-sm font-medium text-textGray' }}
				/>
			)}
			{/* Include time */}
			{typePropsDef?.includeTime && (
				<FormControlLabel
					control={<Switch checked={typePropsVal?.includeTime} onChange={(e) => onChange({ attribute: 'includeTime', value: e.target.checked })} color="primary" size="small" />}
					label={t('table:typeSettings.includeTime')}
					classes={{ root: 'mt-4', label: 'ml-2 text-sm font-medium text-textGray' }}
				/>
			)}
			{/* Time format */}
			{typePropsVal?.includeTime && typePropsDef?.timeFormat && (
				<>
					<span className="mt-4 font-medium text-textGray">{t('table:typeSettings.timeFormat')}</span>
					<TextField
						select
						fullWidth
						variant="outlined"
						size="small"
						value={typePropsVal?.timeFormat}
						onChange={(event) => onChange({ attribute: 'timeFormat', value: event.target.value })}
						inputProps={{ className: 'text-sm' }}
					>
						{Object.keys(TIME_FORMATS).map((key) => {
							const timeFormat = TIME_FORMATS[key]
							const locale = defaultLocale.locale
							const mask = timeFormat.mask
							const timeStr = DateTime.now().setLocale(locale).toFormat(mask)
							return (
								<MenuItem key={key} value={key} className="text-sm">
									{t(TIME_FORMATS[key].i18n, { time: timeStr })}
								</MenuItem>
							)
						})}
					</TextField>
				</>
			)}
			{/* Item list */}
			{typePropsDef?.itemList && (
				<>
					<span className="mt-4 font-medium text-textGray">{t('table:typeSettings.itemList')}</span>
					<TableTypeList aid={aid} varId={varId} variable={variable} isModelVersion={isModelVersion} />
				</>
			)}
		</div>
	)
}
