import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { setAid, cleanAid } from 'common/store/assetReducer'
import { useSplitWindow } from 'common/hooks/useSplitWindow'
import useAssetLoader from 'common/loaders/useAssetLoader'
import useViewManager from 'common/loaders/useViewManager'

export default function AssetPathLoader() {
	const dispatch = useDispatch()
	const { id: aid, type } = useSplitWindow(0)
	const { id: aid2, type: type2 } = useSplitWindow(1)

	useAssetLoader({ aid, type })
	useAssetLoader({ aid: aid2, type: type2 })
	useViewManager({ aid, type })
	useViewManager({ aid: aid2, type: type2 })

	const tid = useSelector((state) => state.auth.tid)

	// #### EFFECTS
	// Set main asset
	useEffect(() => {
		const lTid = tid
		const id = aid
		if (lTid && id) dispatch(setAid(aid))
		return () => {
			if (lTid && id) dispatch(cleanAid())
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tid, aid])

	return <Outlet />
}
