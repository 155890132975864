import { useDispatch, useSelector } from 'react-redux'
import SettingsIcon from '@mui/icons-material/SettingsOutlined'
import IntegratedTextInput from 'common/components/input/IntegratedTextInput'
import { memo, useMemo, useState } from 'react'
import IntegratedTextArea from 'common/components/input/IntegratedTextArea'
import { Menu, MenuItem, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { t } from 'i18next'
import ActionConfirm from 'common/components/ActionConfirm'
import { deleteChart, updateChart } from 'model/saga-actions/chartActions'
import ModelChartGraphic from 'model/screens/charts/ModelChartGraphic'
import ChartSettings from 'model/screens/charts/ChartSettings'
import { RightIcon, LeftIcon, UnfoldIcon } from 'common/icons/index'
import { ChartDrillDown } from 'model/screens/breakdown/ModelDrillDown'
import clsx from 'clsx'
import * as chartSel from 'model/store/chartSelector'
import * as modelDataSel from 'model/store/modelDataSelector'
import { setInView } from 'common/saga-actions/viewActions'
import { useAssetPermits } from 'common/hooks/useAssetPermits'
import { ChartVarDataLoader } from 'model/loaders/ChartVarDataLoader'
import * as viewSel from 'common/store/viewSelector'

export const ModelChart = memo(({ tid, teamId, aid, cid, isVizReady, isDesigning, variables, modelProps, filter, sort }) => {
	const dispatch = useDispatch()
	const permits = useAssetPermits(aid)

	// #### REDUX
	const chart = useSelector((state) => chartSel.selectChart(state, aid, cid))
	const chartData = useSelector((state) => modelDataSel.selectChartData(state, aid, cid))
	const selectChartVizDates = useMemo(() => viewSel.makeSelectChartVizDates({ aid, cid }), [aid, cid])
	const dates = useSelector((state) => selectChartVizDates(state))

	// #### STATE
	const [menuAnchor, setMenuAnchor] = useState(null)
	const [openDelete, setOpenDelete] = useState(false)
	const [openSettings, setOpenSettings] = useState(false)
	const [drillDown, setDrillDown] = useState({ position: null, varId: null }) // position: {x, y}

	// #### FUNCTIONS
	function onUpdateChart(attribute, value, canBeEmpty) {
		if (!canBeEmpty && (!value || value?.length < 1)) return
		const content = { [attribute]: value }
		dispatch(updateChart({ tid, aid, cid: chart.id, content }))
	}

	function onDeleteRequest() {
		setMenuAnchor(null)
		setOpenDelete(true)
	}

	function onDeleteConfirm() {
		dispatch(deleteChart({ tid, aid, cid: chart.id }))
		setOpenDelete(false)
	}

	function onDrillDown(vid, breakdown) {
		dispatch(
			setInView({
				aid,
				changes: [
					{ path: `charts.${cid}.variables.${vid}.breakdown`, value: breakdown },
					{ path: `charts.${cid}.variables.${vid}.page`, value: 0 }
				],
				permits
			})
		)
	}

	function onChangePage(vid, pageIncrease) {
		const prevPage = chartData[vid]?.page || 0
		const page = prevPage + pageIncrease
		if (page < 0) return
		dispatch(setInView({ aid, changes: [{ path: `charts.${cid}.variables.${vid}.page`, value: page }], permits }))
	}

	if (!chart) return
	return (
		<div className="group">
			<div className="flex flex-row items-center justify-between">
				<IntegratedTextInput
					value={chart.label}
					setValue={(value) => onUpdateChart('label', value, false)}
					className="flex-1 text-base hover:shadow-borderGray focus:shadow-textGray mb-2"
					isDisabled={!isDesigning}
				/>
				{isDesigning && (
					<SettingsIcon
						fontSize="small"
						onClick={(e) => setMenuAnchor({ anchor: e.currentTarget, position: { x: e.clientX, y: e.clientY } })}
						className="ml-4 cursor-pointer text-textGray hover:text-black hidden group-hover:block"
					/>
				)}
			</div>
			{(isDesigning || chart.description) && (
				<IntegratedTextArea
					value={chart.description}
					placeholder={t('model:defaults.addDescription')}
					setValue={(value) => onUpdateChart('description', value, true)}
					className="flex-1 text-sm hover:shadow-borderGray focus:shadow-textGray mb-2"
					isDisabled={!isDesigning}
				/>
			)}

			<div className="flex flex-row flex-wrap justify-end mb-1">
				{chart.variables.map((chartVar) => {
					const varId = chartVar.id
					if (!isVizReady) return void 0
					return (
						<ChartVarDataLoader key={`chartDataLoad#${cid}#${varId}`} tid={tid} teamId={teamId} aid={aid} cid={cid} vid={varId} modelProps={modelProps} filter={filter} sort={sort} dates={dates} />
					)
				})}

				{chart.variables?.map((chartVar) => {
					const varId = chartVar.id
					const variable = variables[varId]
					const data = chartData ? chartData[varId] : null
					const hasPrev = data?.page > 0
					const hasNext = data?.nextPageExists || false
					if (!(variable?.categories?.length > 0)) return void 0
					return (
						<div key={`chartBD#${cid}#${varId}`} className="px-2 nowrap ml-2 flex flex-row items-center">
							<span className="text-xs text-textGray">{variable.label}</span>
							<ToggleButtonGroup size="small" className="ml-2">
								<ToggleButton value="prev" key="prev" className="px-1 py-0" onClick={() => onChangePage(varId, -1)} disabled={!hasPrev}>
									<LeftIcon className={clsx('ml-1 w-[15px] h-[15px]', hasPrev ? 'text-textGray' : 'text-borderGray')} />
								</ToggleButton>
								<ToggleButton value="unfold" key="unfold" className="px-1 py-0" onClick={(e) => setDrillDown({ position: { x: e.clientX, y: e.clientY }, varId })}>
									<UnfoldIcon className="ml-1 w-[15px] h-[15px] text-textGray" />
								</ToggleButton>
								<ToggleButton value="next" key="next" className="px-1 py-0" onClick={() => onChangePage(varId, 1)} disabled={!hasNext}>
									<RightIcon className={clsx('ml-1 w-[15px] h-[15px] text-textGray', hasNext ? 'text-textGray' : 'text-borderGray')} />
								</ToggleButton>
							</ToggleButtonGroup>
						</div>
					)
				})}
			</div>
			<ModelChartGraphic aid={aid} chart={chart} dates={dates} />
			<ChartSettings aid={aid} chart={chart} position={openSettings} onClose={() => setOpenSettings(null)} />

			<Menu anchorEl={menuAnchor?.anchor} open={Boolean(menuAnchor)} onClose={() => setMenuAnchor(null)}>
				<MenuItem
					dense
					onClick={(e) => {
						setOpenSettings(menuAnchor?.position)
						setMenuAnchor(null)
					}}
				>
					{t('common:buttons.settings')}
				</MenuItem>
				<MenuItem dense onClick={onDeleteRequest}>
					{t('common:buttons.delete')}
				</MenuItem>
			</Menu>
			<ActionConfirm open={Boolean(openDelete)} title={t('model:messages.chartDeleteConfirm')} onClose={() => setOpenDelete(false)} onConfirm={onDeleteConfirm} />
			{drillDown.varId && <ChartDrillDown aid={aid} cid={cid} varId={drillDown.varId} position={drillDown.position} onSave={onDrillDown} onClose={() => setDrillDown({ position: null, varId: null })} />}
		</div>
	)
})
