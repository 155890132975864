import React, { useEffect, useState } from 'react'
import Autocomplete from 'common/components/Autocomplete'
import ErrorIcon from '@mui/icons-material/ErrorOutlineOutlined'
import { t } from 'i18next'

export default function TableTypeRefVariable({ aid, variables, types, onChange, value, validation, fieldName, showLabel = false, placeholder = '', disabled=false }) {
	// #### STATE
	const [collection, setCollection] = useState([])
	const [selected, setSelected] = useState(null)
	const [error, setError] = useState(null)

	// #### EFFECTS
	// Conver variables into array
	useEffect(() => {
		if (variables) {
			const newCollection = Object.values(variables)?.filter((item) => types.includes(item.type))
			const newSelected = newCollection.find((item) => item.id === value)
			if (value !== '' && value != null && !newSelected) setError(t('common:error.value_deleted_long'))
			else setError(null)
			setCollection(newCollection)
			setSelected(newSelected)
		} else {
			setCollection([])
			setSelected(null)
			setError(null)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [variables, value])

	// #### FUNCTIONS
	const getOptionId = (option) => option?.id || ''
	const getOptionLabel = (option) => option?.label || ''

	function onSelect(item) {
		const itemId = item?.id || null
		if (itemId === value) return
		setSelected(item)
		onChange(itemId)
	}

	// ###########################
	// #### RENDER
	// ###########################
	return (
		<>
			<Autocomplete
				selected={selected}
				collection={collection}
				excludeId={aid}
				disabled={disabled}
				getOptionId={getOptionId}
				getOptionLabel={getOptionLabel}
				onSelect={onSelect}
				validation={validation}
				fieldName={fieldName}
				showLabel={showLabel}
				placeholder={placeholder}
			/>
			{error && (
				<div className="flex flex-row items-center text-red-500 mt-1">
					<ErrorIcon fontSize="small" className="text-red-500 mr-2" />
					<span className="text-xs">{error}</span>
				</div>
			)}
		</>
	)
}
