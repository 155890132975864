import i18n from 'common/config/i18n'
import { CHART_AXIS, CHART_SHAPES, CHART_TYPES } from 'common/constants/charts'
import { DATA_TYPES } from 'common/constants/dataTypes'
import { DEFAULT_CHART_COLOR } from 'common/constants/themes'
import { addToDate, getDatePeriodStart } from 'common/utils/dates'
import { DateTime } from 'luxon'
import { FREQUENCY } from 'common/constants/frequencies'

export const PAGE_SIZE = 10
export const CHART_PAGE_SIZE = 10

export const MODEL_PARAMS = {
	DEFAULT_COLUMN_WIDTH: 120,
	DEFAULT_ROW_HEIGHT: 40,
	DEFAULT_FIRST_COLUMN_WIDTH: 250,
	HEAD_ROW_ID: '_head',
	HEAD_COL_ID: '_head',
	HEAD_TAB_ID: '_main',
	STATIC_COL_ID: '_static',
	STATIC_COL_LABEL: i18n.t('model:model.static'),
	COL_START_TIMESTAMP: 1000010100,
	COL_END_TIMESTAMP: 9999123000,
	CHART_HEIGHT: 300,
	CHART_PANEL_SIZE: 40,
	SEARCH_PANEL_SIZE: 20,
	CONFIDENCE_LEVEL_AVG: 95,
	CONFIDENCE_LEVEL_MIN: 1,
	CONFIDENCE_LEVEL_MAX: 99,
	NUM_SIMULATIONS_MIN: 1,
	NUM_SIMULATIONS_MAX: 10000
}

export const CATEGORY_AGGREGATION = [
	{ key: 'sum', label: i18n.t('model:categoryAggr.sum') },
	{ key: 'avg', label: i18n.t('model:categoryAggr.avg') },
	{ key: 'min', label: i18n.t('model:categoryAggr.min') },
	{ key: 'max', label: i18n.t('model:categoryAggr.max') },
	{ key: 'count', label: i18n.t('model:categoryAggr.count') }
	// { key: 'countDistinct', label: i18n.t('model:categoryAggr.countDistinct') }
]

const defaultPeriodStart = getDatePeriodStart(DateTime.utc(), FREQUENCY.month.key, null).toUTC()
const defaultPeriodEnd = addToDate(defaultPeriodStart, FREQUENCY.month.key, 12).toUTC()

export const MODEL_NEW = {
	tabs: [{ id: MODEL_PARAMS.HEAD_TAB_ID, name: i18n.t('model:defaults.sheet'), groups: ['_inputs', '_calcs', '_outputs'] }],
	groups: {
		_inputs: {
			id: '_inputs',
			label: i18n.t('model:defaults.inputs'),
			tabId: MODEL_PARAMS.HEAD_TAB_ID,
			variables: [],
			type: DATA_TYPES.text.key,
			typeProps: DATA_TYPES.text.defaultProps,
			viewProps: { isExpanded: true }
		},
		_calcs: {
			id: '_calcs',
			label: i18n.t('model:defaults.calculations'),
			tabId: MODEL_PARAMS.HEAD_TAB_ID,
			variables: [],
			type: DATA_TYPES.text.key,
			typeProps: DATA_TYPES.text.defaultProps,
			viewProps: { isExpanded: true }
		},
		_outputs: {
			id: '_outputs',
			label: i18n.t('model:defaults.outputs'),
			tabId: MODEL_PARAMS.HEAD_TAB_ID,
			variables: [],
			type: DATA_TYPES.text.key,
			typeProps: DATA_TYPES.text.defaultProps,
			viewProps: { isExpanded: true }
		}
	},
	modelProps: {
		frequency: FREQUENCY.month.key,
		startDate: defaultPeriodStart.toJSDate(),
		endDate: defaultPeriodEnd.toJSDate(),
		typeProps: { ...DATA_TYPES.date.defaultProps, frequency: FREQUENCY.month.key },
		advanced: {timezone: 'Etc/GMT', fixedTime: null}
	},
	confidence: {
		confidence: MODEL_PARAMS.CONFIDENCE_LEVEL_AVG,
		displayIntervals: false
	}
}

export const MODEL_NEW_VIEW = {
	name: i18n.t('common:views.defaultName'),
	colProps: { [MODEL_PARAMS.HEAD_COL_ID]: { width: MODEL_PARAMS.DEFAULT_FIRST_COLUMN_WIDTH } }
}

export const MODEL_NEW_GROUP = {
	label: i18n.t('model:defaults.newGroup'),
	variables: [],
	type: DATA_TYPES.text.key,
	typeProps: DATA_TYPES.text.defaultProps,
	viewProps: { isExpanded: true }
}

export const MODEL_NEW_VAR = {
	label: i18n.t('model:defaults.newVariable'),
	type: DATA_TYPES.num.key,
	typeProps: DATA_TYPES.num.defaultProps,
	varProps: {
		categoryAggr: CATEGORY_AGGREGATION[0].key
	}
}

export const MODEL_NEW_CHART = {
	type: CHART_TYPES.line.key,
	label: i18n.t('model:defaults.newChart'),
	generalProps: { hasStartDate: false, hasEndDate: false, hasFrequency: false, hasLegend: true, startDate: MODEL_PARAMS.COL_START_TIMESTAMP, endDate: MODEL_PARAMS.COL_END_TIMESTAMP }
}

export const MODEL_NEW_CHART_VAR = { type: CHART_TYPES.line.key, axis: CHART_AXIS.left.key, color: DEFAULT_CHART_COLOR, shape: CHART_SHAPES.straight.key }
