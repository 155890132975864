import { DATA_TYPES } from "common/constants/dataTypes"
import { generateFriendlyKey } from "common/utils/uuid"

// Initializes a schema based on the first row's value of the file
export function fileSchema(file) {
	let result = []
	let generatedKeys = {}

	file.headers?.map((option, index) => {
		const attribute = file.headers[index]

		// create a unique key for the column
		var columnKey = generateFriendlyKey(attribute, true)
		if (generatedKeys[columnKey]) columnKey = generateFriendlyKey(attribute, true)
		generatedKeys[columnKey] = true

		let newAttr = {
			attr: attribute,
			id: columnKey,
		}

		result.push(newAttr)
	})
	return result
}

export function connectorSchema(fields) {
	let result = []

	Object.keys(fields).map((attr, index) => {
		let newAttr = {
			attr: attr,
			id: attr,
			label: attr
		}
		const type = fields[attr]['type']
		switch (type) {
			case "string":
				newAttr["type"] = "text"
				newAttr["backendType"] = DATA_TYPES.text.backendType
				newAttr["typeProps"] = DATA_TYPES.text.typeProps
				break;
			case "number":
				newAttr["type"] = "num"
				newAttr["backendType"] = DATA_TYPES.num.backendType
				newAttr["defaultProps"] = DATA_TYPES.num.defaultProps
				newAttr["typeProps"] = DATA_TYPES.num.typeProps
				break;
			case "object":
				newAttr["type"] = "text"
				newAttr["backendType"] = DATA_TYPES.text.backendType
				newAttr["defaultProps"] = 'json'
				newAttr["typeProps"] = DATA_TYPES.text.typeProps
				break;
			case "array":
				newAttr["type"] = "text"
				newAttr["backendType"] = DATA_TYPES.text.backendType
				newAttr["defaultProps"] = 'array'
				newAttr["typeProps"] = DATA_TYPES.text.typeProps
				break;
			case "date":
				newAttr["type"] = "date"
				newAttr["backendType"] = DATA_TYPES.date.backendType
				newAttr["defaultProps"] = DATA_TYPES.date.defaultProps
				newAttr["typeProps"] = DATA_TYPES.date.typeProps
				break;
			case "boolean":
				newAttr["type"] = "boolean"
				newAttr["backendType"] = DATA_TYPES.boolean.backendType
				newAttr["defaultProps"] = DATA_TYPES.boolean.defaultProps
				newAttr["typeProps"] = DATA_TYPES.boolean.typeProps
				break;
			default:
				break;
		}
		result.push(newAttr)
	})
	return result
}