import { select, put, takeEvery, all, spawn, call } from 'redux-saga/effects'
import * as storeAction from 'model/store/modelReducer'
import * as authApi from 'common/api/authApi'
import * as sagaAction from 'model/saga-actions/modelActions'
import * as subscribeHelper from 'common/saga/helpers/subscribeHelper'
import * as loadHelper from 'common/saga/helpers/loadHelper'
import * as crudHelper from 'common/saga/helpers/crudHelper'
import * as engineMiddlewareActions from 'model/saga-actions/engineMiddlewareActions'
import * as chartSagaAction from 'model/saga-actions/chartActions'
import * as globalStoreAction from 'common/store/globalReducer'
import * as globalSagaAction from 'common/saga-actions/globalActions'
import * as viewAction from 'common/saga-actions/viewActions'
import * as searchHelper from 'common/saga/helpers/searchHelper'
import * as viewSel from 'common/store/viewSelector'
import * as modelDataSel from 'model/store/modelDataSelector'
import { generateKey } from 'common/utils/uuid'
import { arrayUnion } from 'firebase/firestore'
import { MODEL_NEW_GROUP, MODEL_NEW_VAR } from 'model/constants/modelParameters'
import { set, cloneDeep, isEqual } from 'lodash'
import { FREQUENCY } from 'common/constants/frequencies'
import { fromTimestampToDate } from 'common/utils/dates'
import { t } from 'i18next'
import { throwError } from 'common/config/errors'
import { clearVariableBreakdown } from 'model/store/modelDataReducer'
import * as api from 'model/api/ModelApi'
import { generateKeywords } from 'common/saga/helpers/utils'

const FILE_NAME = 'modelSagas'

const getTeam = (state) => state.team.teamId
const getUser = (state) => state.user.user
const getAsset = (state) => state.asset.asset
const getModels = (state) => state.model.model
const getTabIndex = (state) => state.model.tabIndex
const getVariables = (state) => state.model.variables
const getCharts = (state) => state.chart.charts

// #### PATH TO THE COLLECTION
export function defaultPath(includeDocId) {
	const path = [
		{ collection: 'tenants', param: 'tid', value: null },
		{ collection: 'models', param: includeDocId ? 'aid' : null, value: null }
	]
	return path
}

export function defaultPathVariables(includeDocId) {
	const path = [
		{ collection: 'tenants', param: 'tid', value: null },
		{ collection: 'models', param: 'aid', value: null },
		{ collection: 'variables', param: includeDocId ? 'vid' : null, value: null }
	]
	return path
}

export function defaultPathPublishedVariables(includeDocId) {
	const path = [
		{ collection: 'tenants', param: 'tid', value: null },
		{ collection: 'teams', param: 'teamId', value: null },
		{ collection: 'publishedVariables', param: includeDocId ? 'id' : null, value: null }
	]
	return path
}

// #### SUBSCRIBE TO MODEL
function* loadModelReq() {
	const queryConfig = subscribeHelper.queryConfig({ path: defaultPath(true), returnType: 'doc' })
	const sagaConfig = subscribeHelper.sagaConfig({ loadAction: sagaAction.LOAD_MODEL, loadResponse: storeAction.loadModel, cancelAction: sagaAction.CANCEL_MODEL, cancelResponse: storeAction.cancelModel })
	yield spawn(subscribeHelper.subscribe, sagaConfig, queryConfig)
}

function* loadVariablesReq() {
	const queryConfig = subscribeHelper.queryConfig({ path: defaultPathVariables(false), returnType: 'map' })
	const sagaConfig = subscribeHelper.sagaConfig({
		loadAction: sagaAction.LOAD_VARIABLES,
		loadResponse: storeAction.loadVariables,
		cancelAction: sagaAction.CANCEL_VARIABLES,
		cancelResponse: storeAction.cancelVariables
	})
	yield spawn(subscribeHelper.subscribe, sagaConfig, queryConfig)
}

function* loadSelectedVariableReq() {
	const queryConfig = loadHelper.queryConfig({ path: defaultPathPublishedVariables(true) })
	const sagaConfig = loadHelper.sagaConfig({ loadAction: sagaAction.LOAD_SELECTED_VARIABLE, loadResponse: storeAction.loadSelectedVariable })
	yield spawn(loadHelper.load, sagaConfig, queryConfig)
}

// #### SEARCH PUBLISHED VARIABLES
function* searchVariablesReq() {
	const queryConfig = searchHelper.queryConfig({
		path: defaultPathPublishedVariables(false),
		where: [{ attribute: 'keywords', operator: 'array-contains', param: 'searchTerm', value: null }],
		orderBy: [
			{ attribute: 'modelId', order: 'asc' },
			{ attribute: 'label', order: 'asc' }
		],
		returnType: 'list'
	})
	const sagaConfig = searchHelper.sagaConfig({
		loadAction: sagaAction.SEARCH_PUBLISHED_VARIABLES,
		loadResponse: storeAction.searchVariables
	})
	const msgConfig = searchHelper.msgConfig({ fileName: FILE_NAME, functionName: searchVariablesReq.name })
	yield spawn(searchHelper.search, sagaConfig, queryConfig, msgConfig)
}

// #### MANAGE MODEL
function* updateModelReq() {
	const queryConfig = crudHelper.queryConfig({ path: defaultPath(true), returnType: 'doc' })
	const sagaConfig = crudHelper.sagaConfig({ loadAction: sagaAction.UPDATE_MODEL })
	const msgConfig = crudHelper.msgConfig({ fileName: FILE_NAME, functionName: updateModelReq.name })
	yield spawn(crudHelper.update, sagaConfig, queryConfig, msgConfig)
}

function* updateDatesReq() {
	yield takeEvery(sagaAction.UPDATE_DATES, updateDates)
}

function* updateDates(triggeredAction) {
	try {
		const { tid, teamId, aid, frequency, startDate, endDate, timezone, fixedTime, schedulerId } = triggeredAction
		const { token } = yield call(authApi.getAuthToken)
		// Update charts if chart frequency and dates must be updated
		const _charts = yield select(getCharts)
		const charts = _charts[aid]?.result
		let chartsToUpdate = []
		if (charts) {
			Object.values(charts).forEach((chart) => {
				const oldProps = chart.generalProps
				var newProps = { ...oldProps }
				if (newProps.hasFrequency && FREQUENCY[newProps.frequency].index < FREQUENCY[frequency].index) newProps = { ...newProps, frequency: null, hasFrequency: false }
				if (newProps.hasStartDate) {
					const chartStartDate = fromTimestampToDate(newProps.startDate).toUTC().toJSDate()
					if (chartStartDate.getTime() < startDate.getTime()) newProps = { ...newProps, startDate: null, hasStartDate: false }
				}
				if (newProps.hasEndDate) {
					const chartEndDate = fromTimestampToDate(newProps.endDate).toUTC().toJSDate()
					if (chartEndDate.getTime() > endDate.getTime()) newProps = { ...newProps, endDate: null, hasEndDate: false }
				}
				if (!isEqual(oldProps, newProps)) chartsToUpdate.push({ cid: chart.id, content: { generalProps: newProps } })
			})
		}
		const updates = {
			'modelProps.frequency': frequency,
			'modelProps.startDate': startDate,
			'modelProps.endDate': endDate,
			'modelProps.typeProps.includeTime': frequency === FREQUENCY.hour?.key ? true : false,
			'modelProps.typeProps.frequency': frequency,
			'modelProps.advanced.timezone': timezone,
			'modelProps.advanced.fixedTime': fixedTime
		}
		yield put(sagaAction.updateModel({ tid, aid, content: updates }))
		yield all(chartsToUpdate.map((c) => put(chartSagaAction.updateChart({ tid, aid, cid: c.cid, content: c.content }))))
		const advanced = { timezone, fixedTime }
		const modelProps = { frequency, startDate, endDate, advanced }
		if (timezone && schedulerId === null) yield call(api.createScheduler, token, tid, teamId, aid, modelProps)
		else if (timezone === null && schedulerId !== null) yield call(api.deleteScheduler, token, tid, aid, schedulerId)
		else if (timezone && schedulerId) yield call(api.updateScheduler, token, tid, aid, schedulerId, modelProps)
		yield put(engineMiddlewareActions.updateDates({ tid, teamId, aid, modelProps }))
	} catch (err) {
		const content = throwError(err, FILE_NAME, updateDates.name)
		yield put(globalSagaAction.showMessage({ content }))
	}
}

function* deleteTabReq() {
	yield takeEvery(sagaAction.DELETE_TAB, deleteTab)
}

function* deleteTab(triggeredAction) {
	try {
		const { tid, teamId, aid, tabIndex } = triggeredAction
		const _activeTab = yield select(getTabIndex)
		const activeTab = _activeTab[aid]
		const models = yield select(getModels)
		const model = models[aid].data
		const tabs = model.tabs
		const groups = model.groups

		var newTabs = [...tabs]
		var tab = tabs[tabIndex]

		// Identify groups and variables
		var newGroups = groups ? { ...groups } : {}
		tab.groups?.forEach((groupId) => {
			delete newGroups[groupId]
		})

		// Delete tab
		newTabs.splice(tabIndex, 1)
		// Change tab if the tab to remove was active
		if (activeTab === tabIndex && activeTab > 0) yield put(storeAction.selectTab({ id: aid, tabIndex: activeTab - 1 }))

		// Delete groups
		yield all(tab.groups.map((rowId) => put(sagaAction.deleteRow({ tid, teamId, aid, rowId, isGroup: true }))))

		// Save tab and groups
		yield put(sagaAction.updateModel({ tid, aid, content: { tabs: newTabs, groups: newGroups } }))
	} catch (err) {
		const content = throwError(err, FILE_NAME, deleteTab.name)
		yield put(globalSagaAction.showMessage({ content }))
	}
}

function* changeGroupTabReq() {
	yield takeEvery(sagaAction.CHANGE_GROUP_TAB, changeGroupTab)
}

function* changeGroupTab(triggeredAction) {
	try {
		const { tid, aid, groupId, tabIndex: nextTabIndex } = triggeredAction

		const models = yield select(getModels)
		const model = models[aid].data
		const tabs = model.tabs
		const _prevTabIndex = yield select(getTabIndex)
		const prevTabIndex = _prevTabIndex[aid]
		if (nextTabIndex === prevTabIndex) return
		const varsToUpdate = model?.groups[groupId]?.variables

		var newTabs = cloneDeep(tabs)

		// Remove group from previous tab
		const prevTabGroups = newTabs[prevTabIndex].groups
		const newPrevTabGroups = prevTabGroups?.filter((el) => el !== groupId)
		set(newTabs, `[${prevTabIndex}].groups`, newPrevTabGroups)

		// Add group to new tab
		const nextTabId = newTabs[nextTabIndex].id
		const nextTabGroups = newTabs[nextTabIndex].groups
		const newNextTabGroups = nextTabGroups ? [...nextTabGroups, groupId] : [groupId]
		set(newTabs, `[${nextTabIndex}].groups`, newNextTabGroups)

		// Update model and variables
		yield put(sagaAction.updateModel({ tid, aid, content: { tabs: newTabs, [`groups.${groupId}.tabId`]: nextTabId } }))
		yield all(varsToUpdate.map((vid) => put(sagaAction.updateVariable({ tid, aid, vid, content: { tabId: nextTabId } }))))
	} catch (err) {
		const content = throwError(err, FILE_NAME, changeGroupTab.name)
		yield put(globalSagaAction.showMessage({ content }))
	}
}

// #### MANAGE VARIABLES
function* createVariableReq() {
	const queryConfig = crudHelper.queryConfig({ path: defaultPathVariables(true), returnType: 'doc' })
	const sagaConfig = crudHelper.sagaConfig({ loadAction: sagaAction.CREATE_VARIABLE })
	const msgConfig = crudHelper.msgConfig({ fileName: FILE_NAME, functionName: createVariableReq.name })
	yield spawn(crudHelper.create, sagaConfig, queryConfig, msgConfig)
}

function* updateVariableReq() {
	yield takeEvery(sagaAction.UPDATE_VARIABLE, updateVariable)
}

function* updateVariable(triggeredAction) {
	const { tid, aid, vid, content } = triggeredAction
	const teamId = yield select(getTeam)

	const _variables = yield select(getVariables)
	const variables = _variables && _variables[aid]?.result
	const variable = variables && variables[vid]
	const isPublish = variable.varProps?.isPublish || false
	yield call(api.updateVariable, tid, teamId, aid, vid, isPublish, content)
}
function* deleteVariableReq() {
	yield takeEvery(sagaAction.DELETE_VARIABLE, deleteVariable)
}
function* deleteVariable(triggeredAction) {
	const { tid, aid, vid } = triggeredAction
	const teamId = yield select(getTeam)

	const _variables = yield select(getVariables)
	const variables = _variables && _variables[aid]?.result
	const variable = variables && variables[vid]
	const isPublish = variable.varProps?.isPublish || false
	yield call(api.deleteVariable, tid, teamId, aid, vid, isPublish)
}

function* setVariablePublishReq() {
	yield takeEvery(sagaAction.SET_VARIABLE_PUBLIC, setVariablePublish)
}

function* setVariablePublish(triggeredAction) {
	const { tid, teamId, aid, vid, updates } = triggeredAction
	const _variables = yield select(getVariables)
	const variables = _variables && _variables[aid]?.result
	const variable = variables && variables[vid]
	const keywords = generateKeywords(variable?.label)
	const newVariable = Object.assign({ modelId: aid, keywords }, variable)
	yield call(api.setVariablePublic, tid, teamId, aid, vid, newVariable, updates)
}

function* setVariableCategoriesReq() {
	yield takeEvery(sagaAction.SET_VARIABLE_CATEGORIES, setVariableCategories)
}

function* setVariableCategories(triggeredAction) {
	try {
		const { tid, teamId, aid, vid, categories, isConnected, permits } = triggeredAction
		yield put(globalStoreAction.loadingOn({ key: 'setVariableCategories' }))
		const _variables = yield select(getVariables)
		const variables = _variables && _variables[aid]?.result
		const variable = variables && variables[vid]

		// Identify categories being removed and inform views
		var catsToDelete = []
		const nextCategoryIds = categories?.map((el) => el.id)
		variable.categories?.forEach((cat) => !nextCategoryIds?.includes(cat.id) && catsToDelete.push(cat.id))

		// Update variable
		yield put(sagaAction.updateVariable({ tid, aid, vid, content: { categories } }))
		const breakdown = categories?.map((el) => ({ catId: el.id, active: true }))
		yield put(viewAction.setInView({ aid, changes: [{ path: `variables.${vid}.breakdown`, value: breakdown }], permits }))

		if (!(categories?.length > 0)) {
			yield put(viewAction.setInView({ aid, changes: [{ path: `openVariables.${vid}`, value: false }], permits }))
			yield put(clearVariableBreakdown({ id: aid, variableId: vid }))
		}
		yield put(viewAction.setInView({ aid, changes: [{ path: `variables.${vid}.page`, value: 0 }], permits }))

		yield put(engineMiddlewareActions.updateRowLevel({ tid, aid, id: vid, isConnected, categories }))

		// Update charts
		yield put(chartSagaAction.updateVariable({ tid, aid, vid, content: { categories } }))
	} catch (err) {
		const content = throwError(err, FILE_NAME, setVariableCategories.name)
		yield put(globalSagaAction.showMessage({ content }))
	} finally {
		yield put(globalStoreAction.loadingOff({ key: 'setVariableCategories' }))
	}
}

function* setVariableCategoryAggrReq() {
	yield takeEvery(sagaAction.SET_VARIABLE_CATEGORY_AGGR, setVariableCategoryAggr)
}

function* setVariableCategoryAggr(triggeredAction) {
	try {
		const { tid, aid, vid, categoryAggregation } = triggeredAction
		yield put(sagaAction.updateVariable({ tid, aid, vid, content: { 'varProps.categoryAggr': categoryAggregation } }))
		yield put(chartSagaAction.updateVariable({ tid, aid, vid, content: { categoryAggr: categoryAggregation } }))
		yield put(engineMiddlewareActions.updateRowAggr({ tid, aid, id: vid, aggr: categoryAggregation }))
	} catch (err) {
		const content = throwError(err, FILE_NAME, setVariableCategoryAggr.name)
		yield put(globalSagaAction.showMessage({ content }))
	}
}

function* exportVariableReq() {
	yield takeEvery(sagaAction.EXPORT_VARIABLE, exportVariable)
}

function* exportVariable(triggeredAction) {
	try {
		const { tid, teamId, aid, vid } = triggeredAction
		const user = yield select(getUser)
		const userId = user?.id
		yield put(engineMiddlewareActions.exportVariable({ tid, teamId, userId, aid, vid }))
	} catch (err) {
		const content = throwError(err, FILE_NAME, exportVariable.name)
		yield put(globalSagaAction.showMessage({ content }))
	}
}

// #### ROW AND COLUMN OPERATIONS
function* addRowReq() {
	yield takeEvery(sagaAction.ADD_ROW, addRow)
}

function* addRow(triggeredAction) {
	try {
		const { tid, teamId, aid, groupId, isGroup } = triggeredAction

		const models = yield select(getModels)
		const model = models[aid].data
		const tabs = model.tabs
		const _tabIndex = yield select(getTabIndex)
		const tabIndex = _tabIndex[aid]
		const tab = tabs[tabIndex]

		var defaultContent = isGroup ? MODEL_NEW_GROUP : MODEL_NEW_VAR
		var newItem = { ...defaultContent, id: generateKey(6), tabId: tab.id }

		if (!isGroup) {
			// Check limits
			const _asset = yield select(getAsset)
			const asset = _asset[aid]?.data
			if (asset?.consumption?.variables >= asset?.limits?.variables) {
				yield put(globalSagaAction.showMessage({ content: t('common:error.limits'), messageType: 'error' }))
				return
			}

			// Update group
			newItem = { ...newItem, groupId }
			yield put(sagaAction.updateModel({ tid, aid, content: { [`groups.${groupId}.variables`]: arrayUnion(newItem.id) } }))
			yield put(sagaAction.createVariable({ tid, teamId, aid, vid: newItem.id, content: newItem }))
			yield put(engineMiddlewareActions.createRow({ tid, teamId, aid, id: newItem.id }))
			yield put(storeAction.toggleGroup({ id: aid, groupId, isOpen: true }))
		} else {
			// Update tabs
			var newTabs = cloneDeep(tabs)
			const tabGroups = newTabs[tabIndex].groups
			set(newTabs, `[${tabIndex}].groups`, tabGroups ? [...tabGroups, newItem.id] : [newItem.id])
			yield put(sagaAction.updateModel({ tid, aid, content: { tabs: newTabs, [`groups.${newItem.id}`]: newItem } }))
		}
	} catch (err) {
		const content = throwError(err, FILE_NAME, addRow.name)
		yield put(globalSagaAction.showMessage({ content }))
	}
}

function* deleteRowReq() {
	yield takeEvery(sagaAction.DELETE_ROW, deleteRow)
}

function* deleteRow(triggeredAction) {
	try {
		const { tid, teamId, aid, rowId, isGroup } = triggeredAction
		const models = yield select(getModels)
		const model = models[aid].data
		const _tabIndex = yield select(getTabIndex)
		const tabIndex = _tabIndex[aid]
		const tabs = model?.tabs
		const _variables = yield select(getVariables)
		const variables = _variables && _variables[aid]?.result
		const groups = model?.groups
		const groupId = isGroup ? rowId : variables[rowId].groupId
		const group = groups[groupId]
		const _charts = yield select(getCharts)
		const charts = _charts[aid]?.result

		// Identify variables to delete
		var varsToDelete = isGroup ? group.variables : [rowId]

		// Delete variables from charts
		var chartsToUpdate = []
		if (charts)
			Object.values(charts)?.forEach((chart) => {
				const variables = chart.variables?.filter((el) => !varsToDelete.includes(el.id))
				if (chart.variables?.length !== variables?.length) chartsToUpdate.push({ cid: chart.id, variables })
			})

		if (isGroup) {
			// Delete group from tab
			var newTabs = cloneDeep(tabs)
			newTabs[tabIndex].groups = newTabs[tabIndex].groups.filter((el) => el !== groupId)

			// Delete group
			var newGroups = { ...groups }
			delete newGroups[groupId]

			// Save model
			yield put(sagaAction.updateModel({ tid, aid, content: { tabs: newTabs, groups: newGroups } }))
		} else {
			// Delete variable from group
			const groupVars = [...group.variables]
			const varIndex = groupVars.indexOf(rowId)
			groupVars.splice(varIndex, 1)

			// Save model
			yield put(sagaAction.updateModel({ tid, aid, content: { [`groups.${groupId}.variables`]: groupVars } }))
		}

		// Delete variables
		yield all(varsToDelete.map((vid) => put(sagaAction.deleteVariable({ tid, aid, vid }))))
		yield put(engineMiddlewareActions.deleteRows({ tid, teamId, aid, deletes: varsToDelete }))
		// Update charts
		yield all(chartsToUpdate.map((c) => put(chartSagaAction.updateChart({ tid, aid, cid: c.cid, content: { variables: c.variables } }))))
	} catch (err) {
		const content = throwError(err, FILE_NAME, deleteRow.name)
		yield put(globalSagaAction.showMessage({ content }))
	}
}

function* moveRowReq() {
	yield takeEvery(sagaAction.MOVE_ROW, moveRow)
}

function* moveRow(triggeredAction) {
	try {
		const { tid, aid, dragIndex, dropIndex, rows } = triggeredAction
		const models = yield select(getModels)
		const model = models[aid].data
		const _tabIndex = yield select(getTabIndex)
		const tabIndex = _tabIndex[aid]
		const tabs = model?.tabs
		const tab = tabs[tabIndex]
		const groups = model?.groups
		const dragRow = rows[dragIndex]
		const dragId = dragRow.id
		const dropRow = rows[dropIndex]
		const dropId = dropRow.id
		const isDragGroup = dragRow.isGroup
		const _variables = yield select(getVariables)
		const variables = _variables && _variables[aid]?.result

		// Move group
		if (isDragGroup) {
			// Check if drop zone is ok for a group
			const nextDropRow = rows[dropIndex + 1]
			const dropAtTheStart = dropIndex === 0
			const dropAtTheEnd = dropIndex === rows.length - 1
			if (!(nextDropRow?.isGroup || dropAtTheStart || dropAtTheEnd)) return

			// Calculate drop index
			const dropGroupIndex = dropAtTheStart ? 0 : dropAtTheEnd ? tab.groups.length : tab.groups.indexOf(nextDropRow.id)

			// Get group
			const groupId = dragRow.id
			var dragGroupIndex = tab.groups.indexOf(groupId)

			// Copy tabs
			var newTabs = [...tabs]
			var newTab = { ...tab, groups: [...tab.groups] }
			newTabs[tabIndex] = newTab

			const shift = dragIndex <= dropIndex ? 1 : 0
			// Remove group from old position
			newTab.groups.splice(dragGroupIndex, 1)
			// Insert group into new position
			newTab.groups.splice(dropGroupIndex - shift, 0, groupId)

			// Save
			yield put(sagaAction.updateModel({ tid, aid, content: { tabs: newTabs } }))
		}

		// Move variable
		else {
			const dragVariable = variables[dragId]
			const dragGroupId = dragVariable.groupId
			const dragGroup = groups[dragGroupId]
			const dragGroupVarIndex = dragGroup.variables.indexOf(dragId)

			const dropGroupId = dropRow.isGroup ? dropRow.id : variables[dropId].groupId
			const dropGroup = groups[dropGroupId]
			const dropGroupVarIndex = dropRow.isGroup ? 0 : dropGroup.variables.indexOf(dropId) + 1

			const shift = dragGroupId === dropGroupId && dragGroupVarIndex < dropGroupVarIndex ? 1 : 0

			// Remove group from old position
			const dragGroupVars = [...dragGroup.variables]
			dragGroupVars.splice(dragGroupVarIndex, 1)

			// Insert group into new position
			const dropGroupVars = dragGroupId !== dropGroupId ? [...dropGroup.variables] : dragGroupVars
			dropGroupVars.splice(dropGroupVarIndex - shift, 0, dragId)

			// Save new variable list in groups
			if (dragGroupId !== dropGroupId) yield put(sagaAction.updateModel({ tid, aid, content: { [`groups.${dragGroupId}.variables`]: dragGroupVars } }))
			yield put(sagaAction.updateModel({ tid, aid, content: { [`groups.${dropGroupId}.variables`]: dropGroupVars } }))

			// Save variable
			yield put(sagaAction.updateVariable({ tid, aid, vid: dragRow.id, content: { groupId: dropGroupId } }))
		}
	} catch (err) {
		const content = throwError(err, FILE_NAME, moveRow.name)
		yield put(globalSagaAction.showMessage({ content }))
	}
}

function* nextPageReq() {
	yield takeEvery(sagaAction.NEXT_PAGE, nextPage)
}

function* nextPage(triggeredAction) {
	try {
		const { aid, vid, page, permits } = triggeredAction

		const hideZeros = yield select((state) => viewSel.selectViewVarHideZeros(state, aid, vid)) || false
		const hideNulls = yield select((state) => viewSel.selectViewVarHideNulls(state, aid, vid)) || false

		// If hideZeros or hideNulls is enables, we need to handle page independently
		if (hideZeros || hideNulls) yield put(viewAction.setInView({ aid, changes: [{ path: `variables.${vid}.page`, value: page }], permits }))
		// Else, we sync page across all variables with same breakdown
		else {
			const selectViewVarPage = viewSel.makeSelectViewVarPage({ aid, vid })
			const { key } = yield select((state) => selectViewVarPage(state))
			yield put(viewAction.setInView({ aid, changes: [{ path: `pagination.page.${key}`, value: page }], permits }))
		}
	} catch (err) {
		const content = throwError(err, FILE_NAME, deleteRow.name)
		yield put(globalSagaAction.showMessage({ content }))
	}
}

export default function* root() {
	// #### SUBSCRIBE TO MODEL
	yield spawn(loadModelReq)
	yield spawn(loadVariablesReq)
	// #### MANAGE MODEL
	yield spawn(updateModelReq)
	yield spawn(updateDatesReq)
	yield spawn(deleteTabReq)
	yield spawn(changeGroupTabReq)

	// #### MANAGE VARIABLES
	yield spawn(createVariableReq)
	yield spawn(updateVariableReq)
	yield spawn(deleteVariableReq)
	yield spawn(setVariableCategoriesReq)
	yield spawn(setVariableCategoryAggrReq)
	yield spawn(setVariablePublishReq)
	yield spawn(searchVariablesReq)
	yield spawn(loadSelectedVariableReq)
	yield spawn(exportVariableReq)
	yield spawn(nextPageReq)

	// #### ROW AND COLUMN OPERATIONS
	yield spawn(addRowReq)
	yield spawn(deleteRowReq)
	yield spawn(moveRowReq)
}
