import { useRef, useEffect, useCallback } from 'react'
import { useStateRef } from 'common/hooks/grid/useStateRef'

const DEFAULT_OPTIONS = { minItemsPerPage: 50, thresholdItems: 20, alwaysLoadFirstPage: true, alwaysLoadLastPage: false }

export const useLazyLoading = ({ fetch, clean, itemsPerPage: visibleItemsPerPage, totalItems, options = DEFAULT_OPTIONS }) => {
	// #### INTERNAL & REFS
	const [itemsPerPage, itemsPerPageRef, setItemsPerPage] = useStateRef(options.minItemsPerPage)

	// const isPageLoadingRef = useRef()
	const isPageLoadedRef = useRef({})
	const lastPageRef = useRef()

	// #### EFFECTS
	// Update itemsPerPage
	useEffect(() => {
		const newItemsPerPage = Math.ceil(Math.max(options.minItemsPerPage, visibleItemsPerPage))
		if (newItemsPerPage !== itemsPerPage) {
			setItemsPerPage(newItemsPerPage)
			isPageLoadedRef.current = {} // Invalidate cache if itemsPerPage changes
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [visibleItemsPerPage, options.minItemsPerPage])

	// Update lastPage
	useEffect(() => {
		if (!totalItems) return
		const prevLastPage = lastPageRef.current
		const lastPage = Math.floor(totalItems / itemsPerPage)
		lastPageRef.current = lastPage
		if (prevLastPage && prevLastPage !== lastPage) delete isPageLoadedRef.current[prevLastPage] // Invalidate last page
	}, [totalItems, itemsPerPage])

	const loadPages = useCallback(
		(pages, isInitial) => {
			pages.forEach((page) => {
				fetch({ page: page, limit: itemsPerPageRef.current, isInitial: isInitial })
			})
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[fetch]
	)

	const cleanPages = useCallback(
		(pages) => {
			clean({ pages: pages, limit: itemsPerPageRef.current })
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[clean]
	)

	const triggerPagination = useCallback(
		(startIndex, stopIndex, isInitial) => {
			if (isInitial) isPageLoadedRef.current = {}
			const prevLoadedPages = isPageLoadedRef.current

			// Items that are needed
			const startIndexNeeded = Math.max(0, startIndex - options.thresholdItems)
			const stopIndexNeeded = stopIndex + options.thresholdItems

			// Pages that are currently visible
			const startPageNeeded = Math.floor(startIndexNeeded / itemsPerPageRef.current)
			const endPageNeeded = Math.floor(stopIndexNeeded / itemsPerPageRef.current)

			// Pages that need to be loaded
			var pagesToLoad = []
			// -- First page
			if (options.alwaysLoadFirstPage && !isPageLoadedRef.current[0]) {
				isPageLoadedRef.current[0] = true
				pagesToLoad.push(0)
			}
			// -- Visible pages
			for (let i = startPageNeeded; i <= endPageNeeded; i++)
				if (!isPageLoadedRef.current[i]) {
					isPageLoadedRef.current[i] = true
					pagesToLoad.push(i)
				}
			// -- Last page
			const lastPage = lastPageRef.current
			if (options.alwaysLoadLastPage && lastPage && !isPageLoadedRef.current[lastPage] && (pagesToLoad.length === 0 || lastPage !== pagesToLoad[pagesToLoad.length - 1])) {
				isPageLoadedRef.current[lastPage] = true
				pagesToLoad.push(lastPage)
			}

			// Load pages
			if (pagesToLoad.length > 0) loadPages(pagesToLoad, isInitial)
			else return

			// Pages that need to be cleaned
			var pagesToClean = []
			Object.keys(prevLoadedPages)?.forEach((page) => {
				const pageNum = parseInt(page)
				if ((pageNum < startPageNeeded || pageNum > endPageNeeded) && (!options.alwaysLoadFirstPage || pageNum !== 0) && (!options.alwaysLoadLastPage || pageNum !== lastPage)) {
					delete isPageLoadedRef.current[page]
					pagesToClean.push(pageNum)
				}
			})
			if (pagesToClean.length > 0) cleanPages(pagesToClean)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[loadPages, cleanPages]
	)

	return triggerPagination
}
