import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { DATA_TYPES } from 'common/constants/dataTypes'
import TableTypeRefVariable from 'table/screens/type/TableTypeRefVariable'
import AddIcon from '@mui/icons-material/Add'
import ErrorIcon from '@mui/icons-material/ErrorOutlineOutlined'

export default function ModelTableFields({ aid, loadKey, onChange, fields: initFields }) {
	const { t } = useTranslation(['common', 'masterdata'])

	// #### REDUX
	const isLoaded = useSelector((state) => state.table.selected[loadKey]?.loaded) || false
	const variables = useSelector((state) => state.table.selected[loadKey]?.data?.variables)

	// #### STATE
	const [selectVariable, setSelectVariable] = useState('')
	const [fields, setFields] = useState([])

	// Initialize value inputs, only on first render
	useEffect(() => {
		setFields(initFields || [])
	}, [initFields])

	useEffect(() => {
		onChange(fields)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fields])

	// #### FUNCTIONS
	function onAddField(e) {
		const variableId = selectVariable
		const variable = variableId && variableId !== '' && variables && variables[variableId]
		if (!variable) return
		const found = fields?.indexOf(variableId)
		if (found >= 0) return
		var newFields = fields ? [...fields] : []
		newFields.push(variable.id)
		setFields(newFields)
		setSelectVariable('')
	}

	function onDeleteField(index) {
		var newFields = [...fields]
		newFields.splice(index, 1)
		setFields(newFields)
	}

	function onAddAll() {
		const fields = variables && Object.keys(variables)
		if (fields?.length > 0) setFields(fields)
	}

	function onRemoveAll() {
		setFields([])
	}

	return (
		<>
			<div className="flex flex-row items-center">
				<TableTypeRefVariable
					aid={aid}
					variables={variables}
					types={Object.values(DATA_TYPES).map((el) => el.key)}
					onChange={(value) => setSelectVariable(value)}
					value={selectVariable}
					fieldName={t('model:categories.field')}
					showLabel={true}
				/>
				<Button size="small" className="buttonText ml-2" onClick={onAddField}>
					{t('common:buttons.add')}
				</Button>
			</div>
			<div className="flex flex-row items-center justify-between mt-2">
				<Button size="small" className="buttonText normal-case" onClick={onAddAll} startIcon={<AddIcon fontSize="small" />}>
					{t('common:buttons.addAll')}
				</Button>
				<Button size="small" className="buttonText normal-case" onClick={onRemoveAll} startIcon={<CloseIcon fontSize="small" />}>
					{t('common:buttons.removeAll')}
				</Button>
			</div>
			{isLoaded && fields?.map((item, index) => {
				const variable = (variables && variables[item]) || { isError: true, label: t('common:error.value_deleted') }
				return (
					<div key={`field#${index}`} className={clsx('flex flex-row items-center justify-between mt-2 bg-bgGray p-2 rounded')}>
						<div className={clsx('flex flex-row items-center text-sm', variable?.isError && 'text-red-500')}>
							{variable?.isError && <ErrorIcon fontSize="small" className="text-red-500 mr-2" />}
							{variable?.label}
						</div>
						<CloseIcon fontSize="small" className="text-textGray ml-2 cursor-pointer w-[15px] h-[15px]" onClick={() => onDeleteField(index)} />
					</div>
				)
			})}
		</>
	)
}
