import CreateSelectCell from 'common/components/grid/cells/CreateSelectCell'

export const cellSelect = {
	component: CreateSelectCell,
	config: {
		alignRight: false,
		formatBlurredInput: ({ value, typeProps }) => value,
		formatCopyValue: ({ value, typeProps }) => value,
		parseUserInput: ({ value, typeProps }) => value,
		parsePastedValue: ({ value, typeProps, localeFallback }) => value.replace(/[\n\r]+/g, ' ').trim() || null
	}
}
