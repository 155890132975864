import { TextField, Button, Accordion, AccordionDetails, AccordionSummary, CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { DATA_TYPES } from 'common/constants/dataTypes'
import TableTypeRefTable from 'table/screens/type/TableTypeRefTable'
import TableTypeRefVariable from 'table/screens/type/TableTypeRefVariable'
import AssetFilters from 'common/components/search/AssetFilters'
import { useState, useEffect } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { validateNotEmpty } from 'common/utils/validate'
import { useDispatch, useSelector } from 'react-redux'
import ModelTableFields from './ModelTableFields'
import { cancelSelectedTable } from 'table/store/tableReducer'
import { loadSelectedTable } from 'table/saga-actions/tableActions'
import Alert from 'common/components/alert/Alert'
import { LoadingButton } from '@mui/lab'
import { createCategory, deleteCategory } from 'model/saga-actions/modelCategoryActions'

export default function ModelCategory({ catKey, aid, onDelete: parentOnDelete }) {
	const dispatch = useDispatch()
	const { t } = useTranslation(['common', 'model'])
	const isNew = catKey === 'new'

	// #### REDUX
	const tid = useSelector((state) => state.auth.tid)
	const teamId = useSelector((state) => state.asset.asset[aid]?.data?.teamId)
	const globalCategories = useSelector((state) => state.team.teamCategories?.result)
	const category = globalCategories && catKey ? globalCategories[catKey] : null
	const messageKey = `modelCategory#${aid}#${catKey}`
	const isCreating = useSelector((state) => state.global.loading[messageKey])

	// #### DEFAULT VALUES
	const defaultForm = {
		name: '',
		selectTable: '',
		selectVariable: '',
		displayField: '',
		hasFilters: false,
		filters: [],
		fields: []
	}

	const defaultValidation = {
		name: { valid: true, error: '' },
		selectTable: { valid: true, error: '' },
		selectVariable: { valid: true, error: '' },
		displayField: { valid: true, error: '' },
	}

	// #### STATE
	const [openPanel, setOpenPanel] = useState('definition')
	const [form, setForm] = useState(defaultForm)
	const [validation, setValidation] = useState(defaultValidation)
	const loadKey = `modelCategory#${aid}#${form.selectTable}`
	const table = useSelector((state) => state.table.selected[loadKey])
	const isVarsLoaded = table?.loaded || false
	const variables = table?.data?.variables

	// #### EFFECTS
	useEffect(() => {
		const lKey = loadKey
		const table = form.selectTable
		if (table) dispatch(loadSelectedTable({ key: lKey, tid, aid: table }))
		return () => dispatch(cancelSelectedTable({ key: lKey }))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loadKey])

	// #### EFFECTS
	// Load item
	useEffect(() => {
		if (category) setForm({ name: category.name, selectTable: category.selectTable, selectVariable: category.selectVariable, displayField: category.displayField, filters: category.filters, fields: category.fields })
		else setForm(defaultForm)
		setValidation(defaultValidation)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [category])

	function onChange(key, value) {
		let relatedChanges = {}
		if (key === 'selectTable') relatedChanges = { selectVariable: null, displayField: null, filters: [], fields: [] }
		if (key === 'selectVariable') relatedChanges = { displayField: value }
		setForm({ ...form, [key]: value, ...relatedChanges })
	}

	// #### FUNCTIONS
	function onUpdate() {
		let nameValid = validateNotEmpty(form.name)
		if (nameValid?.valid) {
			const found = globalCategories && Object.entries(globalCategories)?.filter((el) => el[1].name?.toLowerCase() === form.name?.toLowerCase())
			if (found?.length > 0 && (isNew || found[0][0] !== catKey)) nameValid = { valid: false, error: t('common:error.not_unique') }
			else nameValid = { valid: true, error: '' }
		}
		let tableValid = validateNotEmpty(form.selectTable)
		if (tableValid?.valid) tableValid = table?.notFound ? { valid: false, error: 'error.required' } : { valid: true, error: '' }
		let variableValid = validateNotEmpty(form.selectVariable)
		if (variableValid?.valid) variableValid = table?.data?.variables[form.selectVariable] != null ? { valid: true, error: '' } : { valid: false, error: 'error.required' }
		let displayFieldValid = validateNotEmpty(form.displayField)
		if (displayFieldValid?.valid) displayFieldValid = table?.data?.variables[form.displayField] != null ? { valid: true, error: '' } : { valid: false, error: 'error.required' }

		setValidation({ ...validation, name: nameValid, selectTable: tableValid, selectVariable: variableValid, displayField: displayFieldValid })
		if (nameValid.valid && tableValid.valid && variableValid.valid && displayFieldValid.valid) {
			const content = { name: form.name, selectTable: form.selectTable, selectVariable: form.selectVariable, displayField: form.displayField, filters: form.filters || [], fields: form.fields || [] }
			dispatch(createCategory({ tid, teamId, aid, categoryId: catKey, content, isNew }))
			if (isNew) setForm(defaultForm)
		}
	}

	function onDelete(e) {
		if (e.preventDefault) e.preventDefault()
		if (e.stopPropagation) e.stopPropagation()
		dispatch(deleteCategory({ tid, teamId, aid, categoryId: catKey, selectTable: table?.id }))
		parentOnDelete()
	}

	function togglePanel(key) {
		if (openPanel === key) setOpenPanel(null)
		else setOpenPanel(key)
	}

	return (
		<>
			<Accordion className="mt-3" expanded={openPanel === 'definition'} onChange={() => togglePanel('definition')}>
				<AccordionSummary className="text-sm m-0 min-h-0" classes={{ content: 'my-2 flex flex-col' }} expandIcon={<ExpandMoreIcon fontSize="small" />}>
					{t('model:categories.definition')}
					<div className="text-xs text-textGray">{t('model:categories.definitionDesc')}</div>
				</AccordionSummary>
				<AccordionDetails>
					<TextField
						label={t('model:categories.name')}
						fullWidth
						variant="outlined"
						size="small"
						margin="none"
						value={form.name}
						onChange={(e) => onChange('name', e.target.value)}
						inputProps={{ className: 'text-sm' }}
						InputLabelProps={{ className: 'text-sm' }}
						error={validation?.name && !validation?.name?.valid}
						helperText={t(validation?.name?.error, { field: t('model:categories.name') })}
						sx={{ placeholder: 'text-sm' }}
					/>
					<div className="mt-3" />
					<TableTypeRefTable
						loadKey={loadKey}
						teamId={teamId}
						onChange={(value) => onChange('selectTable', value)}
						value={form.selectTable}
						validation={validation.selectTable}
						fieldName={t('model:categories.table')}
						showLabel={true}
					/>
					<div className="mt-3" />
					<TableTypeRefVariable
						aid={aid}
						variables={variables}
						types={Object.keys(DATA_TYPES)}
						onChange={(value) => onChange('selectVariable', value)}
						value={form.selectVariable}
						validation={validation.selectVariable}
						fieldName={t('model:categories.field')}
						showLabel={true}
					/>
					<div className="mt-3" />
					<TableTypeRefVariable
						aid={aid}
						variables={variables}
						types={Object.keys(DATA_TYPES)}
						onChange={(value) => onChange('displayField', value)}
						value={form.displayField}
						validation={validation.displayField}
						fieldName={t('model:categories.displayField')}
						showLabel={true}
					/>
				</AccordionDetails>
			</Accordion>

			<Accordion expanded={openPanel === 'fields'} onChange={() => togglePanel('fields')}>
				<AccordionSummary className="text-sm m-0 min-h-0" classes={{ content: 'my-2 flex flex-col' }} expandIcon={<ExpandMoreIcon fontSize="small" />}>
					{t('model:categories.optionalFields')}
					<div className="text-xs text-textGray">{t('model:categories.fieldsDesc')}</div>
				</AccordionSummary>
				<AccordionDetails>
					<ModelTableFields aid={aid} loadKey={loadKey} fields={category?.fields} onChange={(fields) => onChange('fields', fields)} />
				</AccordionDetails>
			</Accordion>
			<Accordion expanded={openPanel === 'filters'} onChange={() => togglePanel('filters')}>
				<AccordionSummary className="text-sm m-0 min-h-0" classes={{ content: 'my-2 flex flex-col' }} expandIcon={<ExpandMoreIcon fontSize="small" />}>
					{t('model:categories.optionalFilters')}
					<div className="text-xs text-textGray">{t('model:categories.filtersDesc')}</div>
				</AccordionSummary>
				<AccordionDetails>
					<AssetFilters aid={aid} variables={variables} isLoaded={isVarsLoaded} loadKey={loadKey} filters={form?.filters} onChange={(filters) => onChange('filters', filters)} />
				</AccordionDetails>
			</Accordion>

			<div className="flex flex-row items-center mt-3">
				{!isNew && (
					<Button onClick={onDelete} fullWidth size="small" variant="outlined" className="buttonOutlinedGray mr-4" disabled={!table?.loaded}>
						{t('common:buttons.delete')}
					</Button>
				)}
				<LoadingButton loading={isCreating || false} fullWidth size="small" className="buttonContainedContrast" onClick={onUpdate} loadingIndicator={<CircularProgress size={20} />}>
					{isNew ? t('common:buttons.add') : t('common:buttons.update')}
				</LoadingButton>
			</div>
			<Alert messageKey={messageKey} className="w-full mt-3" />
		</>
	)
}
