// ### SOCKET MANAGEMENT
export const WS_VIZ_CONNECT = 'engineMiddleware/WS_VIZ_CONNECT'
export const WS_VIZ_DISCONNECT = 'engineMiddleware/WS_VIZ_DISCONNECT'
export const WS_ENGINE_CONNECT = 'engineMiddleware/WS_ENGINE_CONNECT'
export const WS_ENGINE_DISCONNECT = 'engineMiddleware/WS_ENGINE_DISCONNECT'

// ### SOCKET MANAGEMENT
export const wsVizConnect = ({ token, tid, teamId, aid }) => ({ type: WS_VIZ_CONNECT, token, tid, teamId, aid })
export const wsVizDisconnect = ({ aid }) => ({ type: WS_VIZ_DISCONNECT, aid })

export const wsEngineConnect = ({ token, tid, teamId, aid, modelProps }) => ({ type: WS_ENGINE_CONNECT, token, tid, teamId, aid, modelProps })
export const wsEngineDisconnect = ({ aid }) => ({ type: WS_ENGINE_DISCONNECT, aid })
