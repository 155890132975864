import { useCallback } from 'react'
import { useNavigate as useNavigateLib } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { goBack as storeGoBack, goForward as storeGoForward, push as storePush, replace as storeReplace } from 'common/store/navigationReducer'

// TODO: synch with browser back and next buttons. https://stackoverflow.com/questions/25806608/how-to-detect-browser-back-button-event-cross-browser
// https://codepen.io/morten-olsen/pen/zGZdRb
export default function useNavigate() {
	const dispatch = useDispatch()
	const navigateLib = useNavigateLib()

	const goBack = useCallback(() => {
		dispatch(storeGoBack())
		navigateLib(-1)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const goForward = useCallback(() => {
		dispatch(storeGoForward())
		navigateLib(1)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const navigate = useCallback((path) => {
		dispatch(storePush())
		navigateLib(path)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const redirect = useCallback((path) => {
		dispatch(storeReplace())
		navigateLib(path, { replace: true })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return { goBack, goForward, navigate, redirect }
}
