import { useTranslation } from 'react-i18next'
import { Dialog, DialogTitle, DialogContent, Divider, DialogActions, CircularProgress, Button, Avatar, Accordion, AccordionSummary, TextField, AccordionDetails, InputAdornment, MenuItem } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { createConnector, disableConnector, enableConnector, refreshSchema } from 'automation/saga-actions/connectorActions'
import { LoadingButton } from '@mui/lab'
import { CONNECTORS } from 'automation/constants/datasources'
import Alert from 'common/components/alert/Alert'
import { updateConnector } from 'automation/saga-actions/connectorActions'
import NewInConnector from './NewInConnector'
import { connectorSchema } from 'automation/utils/schema'

export default function NewConnector({ connector, connection, isOpen, onClose }) {
	const { t } = useTranslation(['common', 'automation'])
	const dispatch = useDispatch()
	let { teamId } = useParams()
	const tid = useSelector((state) => state.auth.tid)
	const uid = useSelector((state) => state.auth.uid)
	const sid = useSelector((state) => state.space.spaceOpen)
	const isEnabling = useSelector((state) => state.connector.enabling)
	const isCreating = useSelector((state) => state.connector.creating)
	const isUpdate = connection ? true : false
	const isEnable = useSelector((state) => state.team.team?.connectors)?.includes(connector)
	const isRefreshing = useSelector((state) => state.global.loading?.refreshSchema)

	const defaultForm = {
		name: '',
		type: 'in',
		sourceProps: {
			table: {},
			schema: [],
			stream: '',
			mode: 'full_refresh',
			sourceId: '',
			cursor: '',
			primaryKey: [],
			strategy: 'overwrite',
			freq: 'manual',
			scheduler: {units: 1, timeUnit: 'days'}
		},
		destinationProps: {
			selectTable: '',
			tableName: '',
			destination: 'singularly'
		},
	}

	const defaultValidation = {
		name: { valid: true, error: '' },
		type: { valid: true, error: '' },
		sourceId: { valid: true, error: '' },
		table: { valid: true, error: '' },
		cursor: { valid: true, error: '' },
		primaryKey: { valid: true, error: '' },
		tableName: { valid: true, error: '' },
		schema: { valid: true, error: '' }
	}
    
	const [form, setForm] = useState(defaultForm)
	const [validation, setValidation] = useState(defaultValidation)	
	const [fields, setFields] = useState(null)

	useEffect(() => {
		if (!fields) return
		const schema = connectorSchema(fields)
		setForm({ ...form, sourceProps: { ...form.sourceProps, schema: schema} })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fields])

	useEffect(() => {
		if(connection) {
			setForm({ ...connection, 
				sourceProps: {
					...connection.sourceProps, 
					stream: connection.sourceProps.table.namespace + '|' + connection.sourceProps.table.name}, 
					primaryKey: [connection.sourceProps?.primaryKey] || []
			})
		}
	}, [connection])

	function onChange(key, value) {
		if (key === 'fields') {
			setFields(value)
		} else setForm({ ...form, [key]: value })
		setValidation(defaultValidation)
	}

	function onCreate() {
		const connection = {...form}
		connection['connector'] = connector
		if (onValidate()) dispatch(createConnector({ tid, teamId, sid, uid, connection }))
	}

	function onEnable() {
		dispatch(enableConnector({ tid, teamId, connectorId: connector }))
	}

	function onDisable() {
		dispatch(disableConnector({ tid, teamId, connectorId: connector }))
	}

	function onUpdate() {
		const connection = {...form}
		if (onValidate()) dispatch(updateConnector({ tid, teamId, cid: connection?.connectorProps?.connection.name, uid, connection }))
	}

	function onRefresh() {
		dispatch(refreshSchema({ tid, teamId, cid: connection?.connectorProps?.connection.name, stream: form.sourceProps.table.name }))
	}

	function onCloseModal() {
		setForm(defaultForm)
		onClose()
	}

	function onValidate() {
		let stepValid = { valid: true }
		if (form.name === '') {
			stepValid = { valid: false, error: 'common:error.required' }
			setValidation({ ...validation, name: stepValid })
			return stepValid.valid
		} else if (form.sourceProps.sourceId === '') {
			stepValid = { valid: false, error: 'common:error.required' }
			setValidation({ ...validation, sourceId: stepValid })
			return stepValid.valid
		} else if (Object.keys(form.sourceProps.table).length === 0) {
			stepValid = { valid: false, error: 'common:error.required' }
			setValidation({ ...validation, table: stepValid })
			return stepValid.valid
		} else if (form.sourceProps.mode === 'incremental' && form.sourceProps.cursor === '') {
			stepValid = { valid: false, error: 'common:error.required' }
			setValidation({ ...validation, cursor: stepValid })
			return stepValid.valid
		} else if (form.sourceProps.mode === 'incremental' && form.sourceProps.primaryKey.length === 0) {
			stepValid = { valid: false, error: 'common:error.required' }
			setValidation({ ...validation, primaryKey: stepValid })
			return stepValid.valid
		} else if (form.destinationProps.tableName === '') {
			stepValid = { valid: false, error: 'common:error.required' }
			setValidation({ ...validation, tableName: stepValid })
			return stepValid.valid
		} 
		return stepValid.valid
	}

	function onSelectDir(event) {
		setForm({...form, type: event.target.value})
	}

	return (
		<>
			<Dialog
				onClose={onCloseModal}
				open={isOpen}
				maxWidth="md"
				fullWidth={true}
				PaperProps={{
					style: {
						minHeight: isEnable ? '75%' : '20%',
						maxHeight: isEnable ? '75%' : '20%'
					}
				}}
			>
				<DialogTitle className="text-sm text-center">
					<span>{t(`automation:create.syncTable`, { field: t('automation:connectors.' + connector) })}&nbsp;</span>
				</DialogTitle>
				<Divider />
				<DialogContent>
					<div className='ml-8 mr-8 mt-2 mb-2'>
						<div className="flex flex-row items-center mb-4">
							<div className='flex flex-row items-center flex-1'>
								<Avatar className={`h-[30px] w-[30px] mr-4 bg-transparent`} variant="rounded">
									{connector && React.createElement(CONNECTORS[connector].icon, { className: 'text-[20px]' })}
								</Avatar>
								<div>
									<div className="text-sm font-medium">
										<>
											<span>{connector && CONNECTORS[connector].name}</span>
										</>
									</div>
									<div className="text-xs text-textGray leading-none truncate">{t(connector && CONNECTORS[connector].desc)}</div>
								</div>
							</div>
							<div className="flex">
								<div className="flex-1">
									{isEnable ? (
										<LoadingButton 
											loading={isEnabling} 
											variant="outlined" 
											className="normal-case" 
											onClick={onDisable} 
											loadingIndicator={<CircularProgress size={20} />}
										>
											{t('automation:buttons.disable')}
										</LoadingButton>
									) : (
										<LoadingButton 
											loading={isEnabling} 
											variant="contained" 
											className="buttonContainedContrast normal-case" 
											onClick={onEnable} 
											loadingIndicator={<CircularProgress size={20} />}
										>
											{t('automation:buttons.enable')}
										</LoadingButton>
									)}
									
								</div>
							</div>
						</div>
						{isEnable && (
							<>
								<div className="flex flex-row items-center">
									<TextField
										id="table"
										select
										label={t("automation:connector.connectorDirection")}
										variant="outlined"
										className='text-sm mt-4'
										InputProps={{
											className: "text-sm"
										}}
										InputLabelProps={{
											className: "text-sm"
										}}
										size="small"
										fullWidth
										value={form.type}
										onChange={onSelectDir}
									>
										<MenuItem key="in" value="in">
											{t("automation:connector.in")}
										</MenuItem>
										<MenuItem key="out" value="out">
											{t("automation:connector.out")}
										</MenuItem>
									</TextField>
								</div>
								{form.type === "in" ? (
									<NewInConnector 
										connector={connector} 
										connection={connection} 
										validation={validation}
										onClose={onClose} 
										onChange={onChange}
										form={form}
									/>
								) : (
									null
								)}
							</>
						)}
					</div>
				</DialogContent>
				{isEnable && (
					<DialogActions className='grid mr-8 ml-8 gap-[32px] grid-cols-smallCards'>
						<div className='mr-8'>
							<Alert messageKey="connectorLimits" />
						</div>
						<div className='col-span-full cursor-pointer flex flex-row items-center justify-between'>
							<div className="flex flex-row items-center flex-1">
								<LoadingButton 
										loading={isRefreshing} 
										variant="outlined"
										className="mb-4 mr-2 normal-case" 
										onClick={onRefresh}
										loadingIndicator={<CircularProgress size={20} />}
									>
										{t('automation:buttons.refresh')}
								</LoadingButton>
							</div>
							<div className="flex">
								<Button
									variant="outlined"
									className="mb-4 mr-2 normal-case"
									onClick={onClose}
								>
									{t('automation:buttons.cancel')}
								</Button>
								{isUpdate ? (
									<LoadingButton 
										loading={isCreating} 
										variant="contained" 
										className="mb-4 mr-2 buttonContainedContrast normal-case" 
										onClick={onUpdate} 
										loadingIndicator={<CircularProgress size={20} />}
									>
										{t('automation:buttons.update')}
									</LoadingButton>
								): (
									<LoadingButton 
										loading={isCreating} 
										variant="contained" 
										className="mb-4 mr-2 buttonContainedContrast normal-case" 
										onClick={onCreate} 
										loadingIndicator={<CircularProgress size={20} />}
									>
										{t('automation:buttons.create')}
									</LoadingButton>
								)}
							</div>
						</div>
					</DialogActions>
				)}
			</Dialog>
		</>
	)
}
