import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { deleteUser } from 'common/saga-actions/userActions'
import ActionConfirm from 'common/components/ActionConfirm'
import LoadingButton from '@mui/lab/LoadingButton'
import { CircularProgress } from '@mui/material'

export default function AccountLeave() {
	const dispatch = useDispatch()
	const { t } = useTranslation('common')

	const isDeleting = useSelector((state) => state.global.loading?.deleteUser)

	const [isDeleteOpen, setIsDeleteOpen] = useState(false)

	function onDeleteAccount() {
		setIsDeleteOpen(false)
		dispatch(deleteUser())
	}

	return (
		<div className="flex flex-col">
			<div>
				<LoadingButton
					loading={isDeleting}
					size="small"
					variant="contained"
					className="buttonContainedContrast"
					onClick={() => setIsDeleteOpen(true)}
					loadingIndicator={<CircularProgress size={20} className="text-white" />}
				>
					{t('common:account.delete')}
				</LoadingButton>
			</div>
			<span className="text-sm mt-4">{t('common:account.delete_info')}</span>
			<ActionConfirm open={isDeleteOpen} content={t('common:messages.deleteConfirm')} onClose={() => setIsDeleteOpen(false)} onConfirm={onDeleteAccount} />
		</div>
	)
}
