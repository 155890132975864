import { put, call, takeEvery, select, spawn, all } from 'redux-saga/effects'
import * as sagaAction from 'model/saga-actions/modelVizActions'
import * as sagaMiddlewareAction from 'model/saga-actions/vizMiddlewareActions'
import * as authApi from 'common/api/authApi'
import { CHART_PAGE_SIZE, PAGE_SIZE } from 'model/constants/modelParameters'
import { fromDateTimeToString, fromDateToKey, fromDateToString, fromTimestampToDate } from 'common/utils/dates'
import * as viewSel from 'common/store/viewSelector'
import * as modelSel from 'model/store/modelSelector'
import * as chartSel from 'model/store/chartSelector'
import { prepareVarFilter } from 'common/utils/views'
import * as globalSagaAction from 'common/saga-actions/globalActions'
import { throwError } from 'common/config/errors'
import * as socketAction from 'model/saga-actions/socketActions'

const FILE_NAME = 'modelVizSagas'
const getModels = (state) => state.model.model

export function* loadVizSocketReq() {
	yield takeEvery(sagaAction.LOAD_VIZ_SOCKET, loadVizSocket)
}

function* loadVizSocket(triggeredAction) {
	try {
		const { tid, teamId, aid } = triggeredAction
		const { token } = yield call(authApi.getAuthToken)
		yield put(socketAction.wsVizConnect({ token, tid, teamId, aid }))
	} catch (err) {
		const content = throwError(err, FILE_NAME, loadVizSocket.name)
		yield put(globalSagaAction.showMessage({ content }))
	}
}

function getBreakdown(breakdown) {
	const breakdownList = []
	breakdown?.forEach((element, index) => {
		if (element.active) breakdownList.push({ name: element.catId, scope: 'all' })
		else breakdownList.push({ name: element.catId, scope: 'aggr' })
	})
	return breakdownList
}

function getBreakdownAggr(breakdown) {
	const breakdownList = []
	breakdown?.forEach((element, index) => {
		breakdownList.push({ name: element.catId, scope: 'aggr' })
	})
	return breakdownList
}

function getConfig(type, vid, confidence, modelProps, varProps, breakdown, page, pageSize, filter, sort, dates, hideZeros, hideNulls) {
	const low = (100 - confidence?.confidence) / 2
	const high = 100 - low
	const statistics = { mean: true, stddev: false, percentiles: [low, high] }
	const config = {
		id: vid,
		vizType: type,
		page,
		pageSize,
		statistics: statistics,
		aggr: varProps?.categoryAggr,
		breakdown,
		filters: filter,
		sort,
		startDate: dates?.startDate ? fromDateTimeToString(dates.startDate, 'yyyyMMddHH') : null,
		endDate: dates?.endDate ? fromDateTimeToString(dates.endDate, 'yyyyMMddHH') : null,
		timeAggFreq: dates?.frequency,
		modelFreq: modelProps?.frequency,
		hideZeros,
		hideNulls
	}
	return config
}

function* updateRowDataReq() {
	yield takeEvery(sagaMiddlewareAction.UPDATE_ROW_DATA, updateRowData)
}

function* updateRowData(triggeredAction) {
	try {
		const {
			tenantId: tid,
			teamId,
			modelId: aid,
			viewId: _viewId,
			rowId: vid,
			page: _page,
			isOpen: _isOpen,
			breakdown: _breakdown,
			hideZeros: _hideZeros,
			hideNulls: _hideNulls,
			modelProps: _modelProps,
			varProps: _varProps,
			filter: _filter,
			sort: _sort,
			itemsPerPage: _itemsPerPage,
			dates: _dates,
			updateCharts
		} = triggeredAction

		const models = yield select(getModels)
		const modelObj = models[aid]
		if (!modelObj) return
		const model = models[aid]?.data
		const confidence = model?.confidence
		// Get data from store if it has not been passed as prop
		var isOpen = _isOpen
		if (isOpen == null) isOpen = yield select((state) => viewSel.selectViewVarIsOpen(state, aid, vid)) || false

		var viewId = _viewId
		if (viewId == null) viewId = yield select((state) => viewSel.selectViewId(state, aid))

		var page = _page

		if (page == null) {
			const selectViewVarPage = viewSel.makeSelectViewVarPage({ aid, vid })
			const pageEl = yield select((state) => selectViewVarPage(state))
			page = pageEl.page
		}
		var breakdown = _breakdown
		if (breakdown === null || breakdown === undefined) {
			const selectViewBreakdown = viewSel.makeSelectVarBreakdown({ aid, vid })
			breakdown = yield select((state) => selectViewBreakdown(state))
		}

		var hideZeros = _hideZeros
		if (hideZeros == null) hideZeros = yield select((state) => viewSel.selectViewVarHideZeros(state, aid, vid)) || false

		var hideNulls = _hideNulls
		if (hideNulls == null) hideNulls = yield select((state) => viewSel.selectViewVarHideNulls(state, aid, vid)) || false

		var modelProps = _modelProps
		if (modelProps == null) modelProps = yield select((state) => modelSel.selectModelProps(state, aid))

		var varProps = _varProps
		if (varProps == null) varProps = yield select((state) => modelSel.selectModelVarProps(state, aid, vid))

		var filter = _filter
		if (filter == null) {
			const modelFilter = yield select((state) => viewSel.selectViewFilter(state, aid))
			filter = prepareVarFilter(breakdown, modelFilter, (el) => el.catId)
		}

		var sort = _sort
		if (sort == null) {
			const modelSort = yield select((state) => viewSel.selectViewSort(state, aid))
			sort = prepareVarFilter(breakdown, modelSort, (el) => el.catId)
		}

		var itemsPerPage = _itemsPerPage
		if (itemsPerPage == null) {
			itemsPerPage = yield select((state) => viewSel.selectViewItemsPerPage(state, aid))
		}

		var dates = _dates
		if (dates == null) {
			const selectVizDates = viewSel.makeSelectVizDates({ aid })
			dates = yield select((state) => selectVizDates(state))
		}

		// Prepare data for query
		//const breakdownList = isOpen ? getBreakdown(breakdown) : []
		const breakdownList = getBreakdown(breakdown)
		const config = getConfig('row', vid, confidence, modelProps, varProps, breakdownList, page, itemsPerPage, filter, sort, dates, hideZeros, hideNulls)

		// Update charts?
		if (updateCharts) {
			const charts = yield select((state) => chartSel.selectChartsByVar(state, aid, vid))
			if (charts) yield all(charts?.map((cid) => put(sagaMiddlewareAction.updateChartData({ tid, teamId, aid, cid, viewId, vid }))))
		}

		yield put(sagaMiddlewareAction.getRowData({ tid, teamId, aid, viewId, config }))
		if (breakdownList?.length > 0) {
			const breakdownAggrList = getBreakdownAggr(breakdown)
			const configAggr = getConfig('row', vid, confidence, modelProps, varProps, breakdownAggrList, 0, itemsPerPage, filter, sort, dates, hideZeros, hideNulls)
			yield put(sagaMiddlewareAction.getRowData({ tid, teamId, aid, viewId, config: configAggr }))
		}
	} catch (err) {
		const content = throwError(err, FILE_NAME, updateRowData.name)
		yield put(globalSagaAction.showMessage({ content }))
	}
}

function* updateChartDataReq() {
	yield takeEvery(sagaMiddlewareAction.UPDATE_CHART_DATA, updateChartData)
}

function* updateChartData(triggeredAction) {
	try {
		const { tid, teamId, aid, cid, viewId: _viewId, vid, page: _page, breakdown: _breakdown, modelProps: _modelProps, varProps: _varProps, filter: _filter, sort: _sort, dates: _dates } = triggeredAction
		const models = yield select(getModels)
		const model = models[aid].data
		const confidence = model?.confidence
		// Get data from store if it has not been passed as prop
		var viewId = _viewId
		if (viewId == null) viewId = yield select((state) => viewSel.selectViewId(state, aid))

		var page = _page
		if (page == null) page = yield select((state) => viewSel.selectViewChartPage(state, aid, cid, vid)) || 0

		var breakdown = _breakdown
		if (breakdown == null) {
			const selectChartBreakdown = viewSel.makeSelectChartBreakdown({ aid, cid, vid })
			breakdown = yield select((state) => selectChartBreakdown(state))
		}

		var modelProps = _modelProps
		if (modelProps == null) modelProps = yield select((state) => modelSel.selectModelProps(state, aid))

		var varProps = _varProps
		if (varProps == null) varProps = yield select((state) => modelSel.selectModelVarProps(state, aid, vid))

		var filter = _filter
		if (filter == null) {
			const modelFilter = yield select((state) => viewSel.selectViewFilter(state, aid))
			filter = prepareVarFilter(breakdown, modelFilter, (el) => el.catId)
		}

		var sort = _sort
		if (sort == null) {
			const modelSort = yield select((state) => viewSel.selectViewSort(state, aid))
			sort = prepareVarFilter(breakdown, modelSort, (el) => el.catId)
		}

		var dates = _dates
		if (dates == null) {
			const selectVizDates = viewSel.makeSelectChartVizDates({ aid, cid })
			dates = yield select((state) => selectVizDates(state))
		}

		// Prepare data for query
		const breakdownList = getBreakdown(breakdown)
		const config = getConfig('chart', vid, confidence, modelProps, varProps, breakdownList, page, CHART_PAGE_SIZE, filter, sort, dates, false, false)
		yield put(sagaMiddlewareAction.getChartData({ tid, teamId, aid, cid, viewId, config }))
	} catch (err) {
		const content = throwError(err, FILE_NAME, updateChartData.name)
		yield put(globalSagaAction.showMessage({ content }))
	}
}

export default function* root() {
	// #### SOCKET MANAGEMENT
	yield spawn(loadVizSocketReq)
	yield spawn(updateRowDataReq)
	yield spawn(updateChartDataReq)
}
