import React, { useEffect } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { cancelCommunityAssets, loadCommunityAssets } from 'common/saga-actions/communityActions'

export default function CommunityPathLoader() {
	const dispatch = useDispatch()
	let { teamId } = useParams()

	const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)
	const tid = useSelector((state) => state.auth.tid)

	// #### EFFECTS
	// Load community assets
	useEffect(() => {
		const lIsLoggedIn = isLoggedIn
		const lTid = tid
		const lTeamId = teamId
		if (lIsLoggedIn && lTid && lTeamId) dispatch(loadCommunityAssets({ tid, teamId }))
		return () => {
			if (lIsLoggedIn && lTid && lTeamId) dispatch(cancelCommunityAssets())
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoggedIn, tid, teamId])

	return <Outlet />
}
