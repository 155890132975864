import { updateRowData } from 'model/saga-actions/vizMiddlewareActions'
import { memo, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as viewSel from 'common/store/viewSelector'
import * as modelSel from 'model/store/modelSelector'
import { prepareVarFilter } from 'common/utils/views'

export const VariableDataLoader = ({ tid, teamId, aid, vid, modelProps, filter, sort, itemsPerPage, dates }) => {
	// Subscribe to data
	const viewVariable = useSelector((state) => viewSel.selectViewVariable(state, aid, vid))
	const selectViewBreakdown = useMemo(() => viewSel.makeSelectVarBreakdown({ aid, vid }), [aid, vid])
	const viewId = useSelector((state) => viewSel.selectViewId(state, aid))
	const breakdown = useSelector((state) => selectViewBreakdown(state))
	const varProps = useSelector((state) => modelSel.selectModelVarProps(state, aid, vid))

	const selectViewVarPage = useMemo(() => viewSel.makeSelectViewVarPage({ aid, vid }), [aid, vid])
	const { page } = useSelector((state) => selectViewVarPage(state))

	const varFilter = useMemo(() => {
		return prepareVarFilter(breakdown, filter, (el) => el.catId)
	}, [breakdown, filter])

	const varSort = useMemo(() => {
		return prepareVarFilter(breakdown, sort, (el) => el.catId)
	}, [breakdown, sort])

	// Execute load if inputs change
	return (
		<Execute
			tid={tid}
			teamId={teamId}
			aid={aid}
			viewId={viewId}
			vid={vid}
			isOpen={viewVariable?.isOpen}
			breakdown={breakdown}
			hideZeros={viewVariable?.hideZeros}
			hideNulls={viewVariable?.hideNulls}
			page={page}
			modelProps={modelProps}
			varProps={varProps}
			filter={varFilter}
			sort={varSort}
			itemsPerPage={itemsPerPage}
			dates={dates}
		/>
	)
}

const Execute = memo(({ tid, teamId, aid, viewId, vid, isOpen, breakdown, hideZeros, hideNulls, page, modelProps, varProps, filter, sort, itemsPerPage, dates }) => {
	const dispatch = useDispatch()
	useEffect(() => {
		if (validFilter(filter))
			dispatch(updateRowData({ tenantId: tid, teamId, modelId: aid, viewId: viewId, rowId: vid, page, isOpen, breakdown, hideZeros, hideNulls, modelProps, varProps, filter, sort, itemsPerPage, dates }))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	})
}, areEqual)

function areEqual(prev, next) {
	return (
		// IDs
		prev.tid === next.tid &&
		prev.teamId === next.teamId &&
		prev.aid === next.aid &&
		prev.vid === next.vid &&
		// View props
		prev.isOpen === next.isOpen &&
		JSON.stringify(prev.breakdown) === JSON.stringify(next.breakdown) &&
		prev.page === next.page &&
		JSON.stringify(prev.filter) === JSON.stringify(next.filter) &&
		JSON.stringify(prev.sort) === JSON.stringify(next.sort) &&
		prev.dates?.startDate === next.dates?.startDate &&
		prev.dates?.endDate === next.dates?.endDate &&
		prev.dates?.frequency === next.dates?.frequency &&
		prev.itemsPerPage === next.itemsPerPage &&
		prev.hideZeros === next.hideZeros &&
		prev.hideNulls === next.hideNulls &&
		// Model props
		//prev.modelProps?.startDate === next.modelProps?.startDate &&
		//prev.modelProps?.endDate === next.modelProps?.endDate &&
		//prev.modelProps?.frequency === next.modelProps?.frequency &&
		prev.modelProps?.confidence?.confidence === next.modelProps?.confidence?.confidence &&
		// Variable props
		prev.varProps?.categoryAggr === next.varProps?.categoryAggr
	)
}

function validFilter(filter) {
	let valid = true
	if (filter.length > 0) {
		for (const column of filter) {
			if ('value' in column && Array.isArray(column['value']) && column['value'].length === 0) valid = false
		}
	}
	return valid
}
