import { IconButton, Tabs, Tab, Popover } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close'
import ModelCategories from 'model/screens/breakdown/ModelCategories'
import ModelRelationships from 'model/screens/breakdown/ModelRelationships'

export default function ModelBreakdown({ aid, anchor, onClose, vertical = 'bottom', horizontal = 'left' }) {
	// const dispatch = useDispatch()
	const { t } = useTranslation(['common', 'model'])

	// #### STATE
	const [tab, setTab] = useState(0)

	// #### POPOVER CONTROL
	const isPopoverOpen = Boolean(anchor)

	return (
		<Popover open={isPopoverOpen} anchorEl={anchor} onClose={onClose} anchorOrigin={{ vertical, horizontal }} classes={{ paper: 'flex w-[800px] flex shadow-md ml-2' }}>
			<div className="flex-1 flex flex-col pb-4">
				<div className="flex flex-row justify-between items-center border-b border-b-borderGray p-2 pl-4">
					<span className="text-md font-medium text-textGray">{t('model:categories.title')}</span>
					<IconButton size="small" className="text-textGray" onClick={onClose}>
						<CloseIcon fontSize="small" />
					</IconButton>
				</div>

				<Tabs value={tab} onChange={(e, value) => setTab(value)} orientation="horizontal" className="tabs shrink-0" classes={{ indicator: 'tabsIndicator' }}>
					<Tab label={t('model:categories.categories')} className="tab" classes={{ selected: 'tabSelected' }} />
					<Tab label={t('model:relationships.relationships')} className="tab" classes={{ selected: 'tabSelected' }} />
				</Tabs>

				<div className="flex-1 px-4 flex flex-col pt-4">{tab === 0 ? <ModelCategories aid={aid} onClose={onClose} /> : <ModelRelationships aid={aid} onClose={onClose} />}</div>
			</div>
		</Popover>
	)
}
