// #### PROCESS HEADERS
export const EXTRACT_FILE_HEADERS = 'fileSaga/EXTRACT_FILE_HEADERS'
// #### MANAGE FILES
export const UPLOAD_FILE = 'fileSaga/UPLOAD_FILE'
export const DELETE_FILE = 'fileSaga/DELETE_FILE'
// #### SEARCH
export const SEARCH_FILES = 'fileSaga/SEARCH_FILES'
// #### SUBSCRIBE TO FILES CHANGES
export const LOAD_FILES_CHANGES = 'fileSaga/LOAD_FILES_CHANGES'
export const LOAD_FILE_CHANGES = 'fileSaga/LOAD_FILE_CHANGES'
export const CANCEL_FILES_CHANGES = 'fileSaga/CANCEL_FILES_CHANGES'
export const CANCEL_FILE_CHANGES = 'fileSaga/CANCEL_FILE_CHANGES'

// #### PROCESS HEADERS
export const extractFileHeaders = (file) => {
	return { type: EXTRACT_FILE_HEADERS, file }
}

// #### MANAGE FILES
export const uploadFile = ({ tid, teamId, sid, file, form }) => {
	return { type: UPLOAD_FILE, tid, teamId, sid, file, form }
}

export const deleteFile = ({ tid, teamId, fid }) => {
	return { type: DELETE_FILE, tid, teamId, fid }
}

// #### SEARCH
export const searchFiles = ({ key, tid, teamId, startAfter, orderBy }) => {
	return { type: SEARCH_FILES, key, tid, teamId, startAfter, orderBy }
}

// #### SUBSCRIBE TO FILES CHANGES
export const loadFilesChanges = ({ tid, teamId }) => {
	return { type: LOAD_FILES_CHANGES, tid, teamId, startDate: new Date() }
}

export const cancelFilesChanges = () => {
	return { type: CANCEL_FILES_CHANGES }
}

export const loadFileChanges = ({ tid, teamId, fileId }) => {
	return { type: LOAD_FILE_CHANGES, tid, teamId, fileId }
}

export const cancelFileChanges = () => {
	return { type: CANCEL_FILE_CHANGES }
}
