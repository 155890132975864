import { useMemo } from 'react'
import * as modelSel from 'model/store/modelSelector'
import useLocale from 'common/hooks/useLocale'
import { DateTime } from 'luxon'
import { useSelector } from 'react-redux'
import { DATE_FORMATS } from 'common/constants/formats'
import { getDatePeriodStart, fromDateToKey, fromTimestampToDate } from 'common/utils/dates'

export const useModelTodayPeriod = (aid, columns) => {
	const modelProps = useSelector((state) => modelSel.selectModelProps(state, aid))
	const frequency = modelProps?.frequency
	const typeProps = modelProps?.typeProps
	const dateProps = typeProps?.dateFormat ? DATE_FORMATS[typeProps.dateFormat] : null
	const locale = useLocale(dateProps?.locale)
	const fixedTime = modelProps?.advanced?.fixedTime

	const date = useMemo(() => {
		if (!frequency || !locale) return null
		const now = DateTime.now()
		const utc = DateTime.utc(now.year, now.month, now.day, 0, 0, 0, 0)
		const date = fixedTime ? fromTimestampToDate(fixedTime).toUTC() : utc
		const period = getDatePeriodStart(date, frequency, locale.locale).toUTC()
		return period
	}, [frequency, locale, fixedTime])

	const key = useMemo(() => fromDateToKey(date), [date])

	const col = useMemo(() => {
		if (!(columns?.length > 0) || !key) return null
		const index = columns.findIndex((el) => el.id === key)
		return index
	}, [key, columns])

	return { date, key, col }
}
