import clsx from 'clsx'
import { InputBase } from '@mui/material'
import { DatePicker, DateTimePicker, TimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DATE_FORMATS, TIME_FORMATS } from 'common/constants/formats'
import { useFirstRender } from 'common/hooks/useFirstRender'
import useLocale from 'common/hooks/useLocale'
import { DateTime } from 'luxon'
import { memo, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { FREQUENCY } from 'common/constants/frequencies'

export default memo(function CreateDateCell({
	item,
	setItem,
	focus = false,
	cancel = false,
	isUppercase = false,
	typeProps,
	config: { alignRight = false, parseUserInput, formatBlurredInput, parsePastedValue, textWrap = false },
	className
}) {
	const datepickerRef = useRef()
	const firstRender = useFirstRender()

	// #### CONSTANTS
	const hasDate = typeProps?.dateFormat
	const hasTime = typeProps?.includeTime

	const DateComponent = hasDate && hasTime ? DateTimePicker : hasDate ? DatePicker : TimePicker
	const dateProps = typeProps?.dateFormat ? DATE_FORMATS[typeProps.dateFormat] : null
	const timeProps = typeProps?.timeFormat ? TIME_FORMATS[typeProps.timeFormat] : null
	const locale = useLocale(dateProps?.locale)
	const hasAmpm = hasTime && timeProps?.hasAmpm
	const includeWeekNumber = typeProps?.includeWeekNumber || false
	const frequency = typeProps?.frequency

	// #### DATETIME MASKS
	const editMask = `${hasDate ? locale.dateMask.mask : ''}${hasDate && hasTime ? ' ' : ''}${hasTime ? timeProps.mask : ''}`
	const placeholderMask = `${hasDate ? locale.dateMask.placeholder : ''}${hasDate && hasTime ? ' ' : ''}${hasTime ? timeProps.placeholder : ''}`

	// #### STATE
	const [currentFocus, setCurrentFocus] = useState({ is: false, cancel: false })
	const [prevFocus, setPrevFocus] = useState({ is: false, cancel: false })
	const [date, setDate] = useState(null)

	useLayoutEffect(() => {
		setPrevFocus(currentFocus)
		setCurrentFocus({ is: focus, cancel: cancel })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [focus])

	function initializeDate() {
		if (!item || item === undefined) return
		const newDate = DateTime.fromISO(item).toUTC()
		setDate(newDate)
	}

	// Load date into state when item changes
	useEffect(() => {
		initializeDate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [item])

	// Save results when focus is lost
	useLayoutEffect(() => {
		if (!currentFocus.is && !firstRender && prevFocus.is) {
			if (!currentFocus.cancel) {
				if (date && date.isValid) {
					const parsedDate = parseUserInput({ value: date, typeProps, localeFallback: locale?.locale })
					if (item !== parsedDate) setItem(parsedDate)
				} else setItem(null)
			} else initializeDate()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentFocus])

	// If calendar is open, do not propagate clicks to parent components
	const onMouseDown = useCallback((e) => {
		if (datepickerRef?.current) {
			if (e.preventDefault) e.preventDefault()
			if (e.stopPropagation) e.stopPropagation()
			return
		}
	}, [])

	// If cell is focused, attach an onMouseDown event to the cell to prevent clicks from propagating to parent components
	useEffect(() => {
		if (currentFocus.is) document.addEventListener('mousedown', onMouseDown, true)
		return () => document.removeEventListener('mousedown', onMouseDown, true)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentFocus])

	// ###########################
	// #### RENDER
	// ###########################
	if (currentFocus.is) {
		return (
			<LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={locale.locale}>
				<DateComponent
					PopperProps={{ popperRef: datepickerRef }}
					ampm={hasAmpm}
					value={date}
					onChange={(newValue) => setDate(newValue)}
					reduceAnimations
					inputFormat={editMask}
					renderInput={({ inputRef, inputProps, InputProps }) => (
						<InputBase
							inputRef={inputRef}
							autoFocus
							inputProps={{ ...inputProps, placeholder: placeholderMask, className: 'text-sm' }}
							endAdornment={InputProps.endAdornment}
							sx={{ svg: { width: '18px', height: '18px' } }}
							onFocus={(e) => e.target.select()}
						/>
					)}
				/>
			</LocalizationProvider>
		)
	} else
		return (
			<span
				className={clsx(
					'text-sm',
					isUppercase ? 'text-textGray font-medium' : 'text-black',
					textWrap ? 'whitespace-normal break-normal' : 'text-ellipsis overflow-hidden',
					includeWeekNumber && 'leading-none',
					className
				)}
			>
				{formatBlurredInput({ value: item, typeProps, localeFallback: locale?.locale })}
				{includeWeekNumber && date && (frequency === FREQUENCY.week.key || frequency === FREQUENCY.day.key) && (
					<>
						<br />
						<span className="text-xs leading-none font-normal">W{date?.weekNumber}</span>
					</>
				)}
			</span>
		)
})
