import { useEffect, useRef, useMemo, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useSplitWindow } from 'common/hooks/useSplitWindow'
import ModelTabs from 'model/screens/ModelTabs'
import { useControlScreenShot } from 'common/hooks/useScreenShot'
import { uploadScreenshot } from 'common/saga-actions/assetActions'
import Grid from 'common/components/grid/Grid'
import { CATEGORY_AGGREGATION, MODEL_PARAMS } from 'model/constants/modelParameters'
import { Cell, CellStickyColumn, CellStickyRow, CellStickyTopLeft } from 'model/screens/ModelCell'
import { useMemoObject } from 'common/hooks/grid/useMemoObject'
import { addRow, updateModel, updateVariable, deleteRow, moveRow, setVariableCategoryAggr, setVariablePublic, changeGroupTab, exportVariable, nextPage } from 'model/saga-actions/modelActions'
import { FormulaBarContext } from 'common/components/formula/FormulaBarContext'
import { mergeSequentialExpressions, updateRowFormulas } from 'model/utils/formulas'
import useModelDataLoader from 'model/loaders/useModelDataLoader'
import { CategoriesIcon, ChartIcon, CloseIcon, HiddenIcon, PublishIcon, TagIcon, EditIcon, BookmarkIcon, ColorIcon, FontIcon, FontSizeIcon, DownloadIcon, ZeroIcon } from 'common/icons/index'
import ActionConfirm from 'common/components/ActionConfirm'
import ModelConnectData from './ModelConnectData'
import TableType from 'table/screens/type/TableType'
import useLocale from 'common/hooks/useLocale'
import { DATA_TYPES } from 'common/constants/dataTypes'
import PanelLayout from 'common/screens/layout/PanelLayout'
import { publishRow, updateCellOverrides, updateFormula } from 'model/saga-actions/engineMiddlewareActions'
import ModelCharts from 'model/screens/charts/ModelCharts'
import LoadingBody from 'common/components/loading/LoadingBody'
import ModelVarCategories from './breakdown/ModelVarCategories'
import AlertDialog from 'common/components/alert/AlertDialog'
import { ModelDrillDown } from 'model/screens/breakdown/ModelDrillDown'
import { createChart } from 'model/saga-actions/chartActions'
import { SYSMSG_MODEL_SCROLL_TO_TODAY, downloadUrl as clearDownloadUrl, popSystemMessage } from 'common/store/globalReducer'
import { CHART_TYPES } from 'common/constants/charts'
import { FORMULA_ATTRIBUTE, FORMULA_VARIABLE } from 'common/constants/formulas'
import { isEqual } from 'lodash'
import { useAssetPermits } from 'common/hooks/useAssetPermits'
import { setInView } from 'common/saga-actions/viewActions'
import * as viewSel from 'common/store/viewSelector'
import * as modelSel from 'model/store/modelSelector'
import * as modelDataSel from 'model/store/modelDataSelector'
import { VariableDataLoader } from 'model/loaders/VariableDataLoader'
import { isGroupOpen as _isGroupOpen, orderBreakdownValues } from 'model/utils/rows'
import ModelSearch from 'model/screens/ModelSearch'
import { trucateDate } from 'common/utils/dates'
// import ModelTour from './ModelTour'
// import { STATUS } from 'react-joyride'
// import { toggleDesignMode } from 'model/store/modelReducer'
// import { updateUser } from 'common/saga-actions/userActions'
import { useStateRef } from 'common/hooks/grid/useStateRef'
import { MODEL_VAR_TYPES } from 'model/constants/modelTypes'
import { BACKGROUND_COLORS, FONT_COLORS, FONT_WEIGHTS } from 'common/constants/colors'
import { useModelTodayPeriod } from 'model/hooks/useModelTodayPeriod'
import { debounce } from 'throttle-debounce'
import ModelConnectApi from './ModelConnectApi'

export default function Model({ windowIndex = 0 }) {
	const { t } = useTranslation('model')
	const dispatch = useDispatch()
	const { id: aid, isSplit } = useSplitWindow(windowIndex)
	const wrapperRef = useRef(null)
	const gridRef = useRef()
	const locale = useLocale()
	useModelDataLoader({ aid })
	const permits = useAssetPermits(aid)
	const { canCreate, canEdit } = permits

	// #### REDUX
	// Asset
	const tid = useSelector((state) => state.auth.tid)
	const uid = useSelector((state) => state.auth.uid)
	// const modelTour = useSelector((state) => state.user.user?.data?.modelTour) || false
	const teamId = useSelector((state) => state.asset.asset[aid]?.data?.teamId)
	const screenShotUpdatedAt = useSelector((state) => state.asset.asset[aid]?.data?.screenShot?.updatedAt)
	const isDesigning = useSelector((state) => (canCreate ? modelSel.selectModelIsDesigning(state, aid) : false))
	// Model
	const isModelLoaded = useSelector((state) => modelSel.selectModelIsLoaded(state, aid))
	const selectModelColumns = useMemo(() => modelSel.makeSelectModelColumns({ aid }), [aid])
	const columns = useSelector((state) => selectModelColumns(state))
	const { key: today, col: todayCol } = useModelTodayPeriod(aid, columns)
	const selectModelRows = useMemo(() => modelSel.makeSelectModelRows({ aid }), [aid])
	const rows = useSelector((state) => selectModelRows(state))
	const groups = useSelector((state) => modelSel.selectModelGroups(state, aid))
	const variables = useSelector((state) => modelSel.selectModelVars(state, aid))
	const tabs = useSelector((state) => modelSel.selectModelTabs(state, aid))
	const modelProps = useSelector((state) => modelSel.selectModelProps(state, aid))
	const highlightRange = useMemo(() => {
		const periods = modelProps?.typeProps?.highlightPeriodsNum
		if (!periods || periods === 0) return null
		else return { start: todayCol + 1, end: todayCol + periods }
	}, [todayCol, modelProps?.typeProps?.highlightPeriodsNum])
	const displayIntervals = useSelector((state) => modelSel.selectModelDisplayIntervals(state, aid))
	const selectModelCategories = useMemo(() => modelSel.makeSelectModelCategories({ aid }), [aid])
	const categories = useSelector((state) => selectModelCategories(state))
	const canGroupsOpen = useSelector((state) => modelSel.selectModelCanGroupsOpen(state, aid))
	const canVarsOpen = useSelector((state) => modelSel.selectModelCanVarsOpen(state, aid))
	const tabIndex = useSelector((state) => modelSel.selectModelTabIndex(state, aid))
	// Model data
	const isVizReady = useSelector((state) => modelDataSel.selectModelIsVizReady(state, aid))
	const data = useSelector((state) => modelDataSel.selectModelData(state, aid))
	const breakdown = useSelector((state) => modelDataSel.selectModelBreakdown(state, aid))
	const errors = useSelector((state) => modelDataSel.selectModelErrors(state, aid))
	const isEngineReady = useSelector((state) => modelDataSel.selectModelIsEngineReady(state, aid))
	const downloadUrl = useSelector((state) => state.global.downloadUrl)
	// View properties
	const chartPanel = useSelector((state) => viewSel.selectViewChartPanel(state, aid))
	const searchPanel = useSelector((state) => viewSel.selectViewSearchPanel(state, aid))
	const colProps = useSelector((state) => viewSel.selectViewColProps(state, aid))
	const viewGroups = useSelector((state) => viewSel.selectViewGroups(state, aid))
	const viewVariables = useSelector((state) => viewSel.selectViewVariables(state, aid))
	const filter = useSelector((state) => viewSel.selectViewFilter(state, aid))
	const sort = useSelector((state) => viewSel.selectViewSort(state, aid))
	const itemsPerPage = useSelector((state) => viewSel.selectViewItemsPerPage(state, aid))
	const _cellSplit = useSelector((state) => viewSel.selectViewCellSplit(state, aid))
	const selectVizDates = useMemo(() => viewSel.makeSelectVizDates({ aid }), [aid])
	const dates = useSelector((state) => selectVizDates(state))
	const isAggregated = useMemo(() => modelProps?.frequency !== dates?.frequency, [modelProps?.frequency, dates?.frequency])
	// Linked tables
	const tables = useSelector((state) => state.table.subscribed)
	// System messages
	const systemMessagesKey = useMemo(() => `model/${aid}`, [aid])
	const systemMessages = useSelector((state) => state.global.systemMessages[systemMessagesKey])

	// #### STATE
	const [openDeleteRow, setOpenDeleteRow] = useState({ grIndex: null, isGroup: false })
	const [getFromApi, setGetFromApi] = useState({ position: null, rowId: null }) // position: {x, y}
	const [connectData, setConnectData] = useState({ position: null, rowId: null }) // position: {x, y}
	const [breakCategories, setBreakCategories] = useState({ position: null, rowId: null }) // position: {x, y}
	const [formatOptions, setFormatOptions] = useState({ position: null, rowId: null }) // position: {x, y}
	const [drillDown, setDrillDown] = useState({ position: null, rowId: null }) // position: {x, y}
	const [formulaVariables, setFormulaVariables] = useState([])
	const [formulaAttributes, setFormulaAttributes] = useState([])
	const [cellSplit, cellSplitRef, setCellSplit] = useStateRef({})
	const [statistics, statisticsRef, setStatistics] = useStateRef(null)
	// const [run, setRun] = useState(false)

	// #### SCREENSHOT
	useControlScreenShot({ ref: wrapperRef, updatedAt: screenShotUpdatedAt, isReady: canCreate && isModelLoaded && !isSplit, callback: (file) => dispatch(uploadScreenshot({ tid, teamId, aid, file })) })

	// #### EFFECTS
	// Process system message actions related to this model
	useEffect(
		() => {
			if (!systemMessages || systemMessages.length <= 0) return
			const message = systemMessages[0]
			if (message === SYSMSG_MODEL_SCROLL_TO_TODAY) scrollToToday()
			dispatch(popSystemMessage({ key: systemMessagesKey, value: SYSMSG_MODEL_SCROLL_TO_TODAY }))
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[systemMessages]
	)

	// Keep cell split up to date
	useEffect(
		() => setCellSplit(_cellSplit || {}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[_cellSplit]
	)

	// Prepare variables for formulas
	useEffect(() => {
		const formulaVars = isDesigning && variables ? Object.values(variables)?.map((variable) => ({ key: variable.id, label: variable.label, apply: `{${variable.id}}`, ...FORMULA_VARIABLE })) : []
		if (!isEqual(formulaVariables, formulaVars)) setFormulaVariables(formulaVars)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDesigning, variables])

	// useEffect(() => {
	// 	if (!modelTour && isModelLoaded && canCreate) setRun(true)
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [isModelLoaded])

	// Prepare category attributes for formulas
	useEffect(() => {
		var formulaAttr = []
		if (isDesigning && categories && tables) {
			Object.entries(categories)?.forEach((category) => {
				const categoryId = category[0]
				const categoryData = category[1]
				const tableVariables = tables[categoryData.selectTable]?.data?.variables
				if (!tableVariables) return
				const selectVariable = categoryData.selectVariable
				const selectVariableLabel = tableVariables[selectVariable]?.label
				formulaAttr.push({ key: `${categoryId}`, label: `${categoryData.name}.${selectVariableLabel}`, apply: `{#${categoryId}}`, ...FORMULA_ATTRIBUTE })
				categoryData.fields?.forEach((field) => {
					const fieldLabel = tableVariables[field]?.label
					if (fieldLabel && field !== selectVariable)
						formulaAttr.push({ key: `${categoryId}#${field}`, label: `${categoryData.name}.${fieldLabel}`, apply: `{#${categoryId}#${field}}`, ...FORMULA_ATTRIBUTE })
				})
			})
		}
		if (!isEqual(formulaAttributes, formulaAttr)) setFormulaAttributes(formulaAttr)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDesigning, categories, tables])

	// Download the row when the url is signed
	useEffect(() => {
		if (downloadUrl?.signedUrl && uid === downloadUrl?.userId) downloadRow()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [downloadUrl])

	const downloadRow = () => {
		window.open(downloadUrl?.signedUrl)
		dispatch(clearDownloadUrl({ userId: null, signedUrl: null }))
	}
	// #### ROW & COL GETTERS
	const rowCount = useMemo(() => (rows?.length || 0) + 1, [rows]) // + 1 for header row
	const getColumn = useCallback((colIndex) => ({ colIndex: colIndex, col: columns[colIndex] }), [columns])
	const getRow = useCallback(
		(rowIndex) => {
			if (rowIndex === 0) return { rowIndex: 0, variable: { id: MODEL_PARAMS.HEAD_ROW_ID }, row: { id: MODEL_PARAMS.HEAD_ROW_ID } }
			const newRowIndex = rowIndex - 1
			const row = rows[newRowIndex]
			const variable = !row ? null : row?.isGroup ? groups[row.id] : variables[row.id]
			return { rowIndex: newRowIndex, row, variable }
		},
		[rows, groups, variables]
	)
	const getVariableView = useCallback(
		(variableId) => {
			if (!viewVariables) return null
			else return viewVariables[variableId]
		},
		[viewVariables]
	)

	const getFirstTimestep = useCallback(() => {
		return columns[2]?.id || null
	}, [columns])

	const getLastTimestep = useCallback(() => {
		const length = columns?.length
		if (!length) return null
		return columns[length - 1]?.id
	}, [columns])

	// #### GRID CUSTOMIZATION
	// Returns the cell type
	const getCellType = useCallback(
		(grIndex, gcIndex) => {
			const { variable, row } = getRow(grIndex)
			const { col } = getColumn(gcIndex)
			if (!row || !col) return null
			if (grIndex === 0) return { type: col.type, typeProps: col.typeProps, columnId: col.id, rowId: row.id }
			else if (gcIndex === 0 || !variable) return { type: DATA_TYPES.text.key, typeProps: DATA_TYPES.text.defaultProps, columnId: col.id, rowId: row.id }
			else return { type: variable.type, typeProps: variable.typeProps, columnId: col.id, rowId: row.id }
		},
		[getRow, getColumn]
	)

	// Indicates when a cell's editing must be disabled
	const disableCell = useCallback(
		(grIndex, gcIndex) => {
			const { variable, row } = getRow(grIndex)
			const { col } = getColumn(gcIndex)
			const isConnected = variable?.sourceProps != null
			const isEditable = (canEdit && variable?.varProps?.isEditable) || false
			const isBreakdown = row?.isBreakdown || false
			const isGroup = row?.isGroup || false
			const isEditingStatic = col.id === MODEL_PARAMS.STATIC_COL_ID
			const isVariableStatic = variable?.varProps?.isStatic || false
			const categories = variable.categories
			const canOpen = categories?.length > 0
			const dataCategories = isBreakdown && breakdown ? breakdown[row.id]?.labels : null
			const notInLowestLevel = !isBreakdown ? false : categories?.length !== dataCategories?.length

			// Aggregated models cannot be edited
			if (isAggregated) return true
			// Row label cells that are not breakdown can be edited in design mode
			if (isDesigning && !isBreakdown && gcIndex === 0 && grIndex !== 0) return false
			// Breakdown values can be edited in the right column (static or dynamic) if edits are at the most granular level
			if ((isDesigning || isEditable) && isBreakdown && gcIndex > 0 && grIndex > 0 && isEditingStatic === isVariableStatic && !notInLowestLevel) return false
			// Connected values can be edited in the right column
			if ((isDesigning || isEditable) && !isBreakdown && gcIndex > 0 && grIndex > 0 && isConnected && isEditingStatic === isVariableStatic && !canOpen) return false
			// Regular formula variables can be edited
			if (isDesigning && !isBreakdown && gcIndex > 0 && grIndex > 0 && !isConnected && !isGroup) return false
			// Regular formula variables can be edited outside design mode in the right column (static or dynamic)
			if (isEditable && !isBreakdown && gcIndex > 0 && grIndex > 0 && !isConnected && !isGroup && isEditingStatic === isVariableStatic && !canOpen) return false
			return true
		},
		[isDesigning, canEdit, getRow, getColumn, breakdown, isAggregated]
	)

	// Returns if a cell accepts formulas, and its current formula
	const cellFormula = useCallback(
		(grIndex, gcIndex) => {
			const { variable, row } = getRow(grIndex)
			const { col } = getColumn(gcIndex)
			// Can't have formula
			if (grIndex === 0 || gcIndex === 0 || row.isGroup || row.isBreakdown || variable?.sourceProps != null || variable?.type === MODEL_VAR_TYPES.checkbox.key)
				return { canHaveFormula: false, formula: null }
			// Can have formula, but it's empty
			const expr = variable?.expressions
			const freq = modelProps.frequency
			if (!(expr?.length > 0)) return { canHaveFormula: true, formula: null }
			// Can have formula, and it's static
			if (col.id === MODEL_PARAMS.STATIC_COL_ID && expr[0]?.start == null) return { canHaveFormula: true, formula: expr[0] }
			// Can have formula, and it's dynamic
			else if (col.id !== MODEL_PARAMS.STATIC_COL_ID && expr[0]?.start > 0) {
				const numColId = Number.isInteger(col.id) ? col.id : Number.parseInt(col.id)
				for (let i = 0; i < expr.length; i++) if (numColId >= trucateDate(freq, expr[i].start) && numColId < trucateDate(freq, expr[i].end)) return { canHaveFormula: true, formula: expr[i] }
			}
			return { canHaveFormula: true, formula: null }
		},
		[getRow, getColumn]
	)

	const getData = useCallback(
		(grIndex, gcIndex) => {
			const { row, variable } = getRow(grIndex)
			const { col } = getColumn(gcIndex)
			// Identification
			const isHeader = row.id === MODEL_PARAMS.HEAD_ROW_ID
			const isLabel = col.id === MODEL_PARAMS.HEAD_COL_ID
			const isBreakdown = row?.isBreakdown || false
			// Formatter
			const type = isHeader ? col.type : variable.type
			const typeProps = isHeader ? col.typeProps : variable.typeProps
			const typeObj = DATA_TYPES[type]
			const cellRenderer = typeObj?.renderer
			const cellConfig = cellRenderer?.config
			const formatCopyValue = cellConfig.formatCopyValue

			// Copy dates from header
			if (isHeader) return { value: col.label, formatValue: formatCopyValue({ value: col.label, typeProps, localeFallback: locale?.locale }) }
			// Copy variable labels
			else if (isLabel && !isBreakdown) return { value: variable.label, formatValue: variable.label }
			// Copy breakdown labels and values
			else if (isBreakdown) {
				const breakdownIndex = row.breakdownIndex
				const meanPos = breakdown[row.id]?.valueNames?.default
				const varData = breakdown[row.id]?.values[breakdownIndex]
				if (isLabel) {
					const label = varData?.l?.join(' - ')
					return { value: label, formatValue: label }
				} else {
					const value = varData?.v && varData?.v[col.id] ? varData?.v[col.id][meanPos] : null
					return { value, formatValue: formatCopyValue({ value, typeProps, localeFallback: locale?.locale }) }
				}
				// Copy non-broken down values
			} else {
				const varData = data[row.id]
				const meanPos = varData?.valueNames?.default
				const value = varData?.values && varData?.values[0]?.v[col.id] ? varData?.values[0]?.v[col.id][meanPos] : null
				return { value, formatValue: formatCopyValue({ value, typeProps, localeFallback: locale?.locale }) }
			}
		},
		[getRow, getColumn, data, breakdown, locale]
	)

	// Returns the right-click menu of a cell
	const cellContextMenu = useCallback(
		({ rowIndex: grIndex, colIndex: gcIndex, position }) => {
			if (grIndex === 0) return { hasMenu: false, items: null }
			const { variable, row } = getRow(grIndex)
			const isGroup = row.isGroup
			const viewVariable = getVariableView(variable.id)
			const hideZeros = viewVariable?.hideZeros || false
			const hideNulls = viewVariable?.hideNulls || false

			if (!isDesigning && !isGroup) {
				return {
					hasMenu: true,
					items: [
						{
							name: t('model:menu.breakdownEmpty'),
							icon: ZeroIcon,
							items: [
								{
									name: t('model:menu.breakdownEmptyShow'),
									isSelectable: true,
									isActive: !hideZeros && !hideNulls,
									action: () => onVariableView(grIndex, false, false)
								},
								{
									name: t('model:menu.breakdownEmptyHideZeros'),
									isSelectable: true,
									isActive: hideZeros && !hideNulls,
									action: () => onVariableView(grIndex, true, false)
								},
								{
									name: t('model:menu.breakdownEmptyHideNulls'),
									isSelectable: true,
									isActive: !hideZeros && hideNulls,
									action: () => onVariableView(grIndex, false, true)
								},
								{
									name: t('model:menu.breakdownEmptyHideAll'),
									isSelectable: true,
									isActive: hideZeros && hideNulls,
									action: () => onVariableView(grIndex, true, true)
								}
							]
						},
						{
							name: t('model:menu.export'),
							icon: DownloadIcon,
							action: () => onExport(grIndex)
						}
					]
				}
			} else if (isDesigning && isGroup)
				return {
					hasMenu: true,
					items: [
						{
							name: t('model:menu.tab'),
							icon: BookmarkIcon,
							items: tabs?.map((tab, targetIndex) => ({
								name: tab.name,
								isSelectable: true,
								isActive: targetIndex === tabIndex,
								action: () => onChangeGroupTab(targetIndex, row.id)
							}))
						},
						{ name: t('model:menu.deleteGroup'), icon: CloseIcon, action: () => setOpenDeleteRow({ grIndex, isGroup }) }
					]
				}
			else if (isDesigning && !isGroup)
				return {
					hasMenu: true,
					items: [
						{
							name: t('model:menu.createChart'),
							icon: ChartIcon,
							items: Object.values(CHART_TYPES).map((chart) => ({ name: chart.label, action: () => onCreateChart(grIndex, chart.key) }))
						},
						{
							name: t('model:menu.format'),
							icon: TagIcon,
							action: () => onFormatOptions(position, row.id)
						},
						{
							name: t('model:menu.font'),
							icon: FontIcon,
							items: [
								{
									name: t('model:menu.color'),
									icon: ColorIcon,
									items: FONT_COLORS.map((color) => ({
										name: color.name,
										isSelectable: true,
										isActive: variable.varProps?.fontColor ? variable.varProps?.fontColor === color.code : color.key === 'black',
										action: () => onUpdateVariable(grIndex, { 'varProps.fontColor': color.code })
									}))
								},
								{
									name: t('model:menu.weight'),
									icon: FontSizeIcon,
									items: FONT_WEIGHTS.map((weight) => ({
										name: weight.name,
										isSelectable: true,
										isActive: variable.varProps?.fontWeight ? variable.varProps?.fontWeight === weight.code : weight.key === 'normal',
										action: () => onUpdateVariable(grIndex, { 'varProps.fontWeight': weight.code })
									}))
								}
							]
						},
						{
							name: t('model:menu.background'),
							icon: ColorIcon,
							items: BACKGROUND_COLORS.map((color) => ({
								name: color.name,
								isSelectable: true,
								isActive: variable.varProps?.backgroundColor ? variable.varProps?.backgroundColor === color.code : color.key === 'white',
								action: () => onUpdateVariable(grIndex, { 'varProps.backgroundColor': color.code })
							}))
						},
						{
							name: t('model:menu.hide'),
							icon: HiddenIcon,
							items: [
								{
									name: t('common:options.yes'),
									isSelectable: true,
									isActive: variable.varProps?.isHidden,
									action: () => onUpdateVariable(grIndex, { 'varProps.isHidden': true })
								},
								{
									name: t('common:options.no'),
									isSelectable: true,
									isActive: !variable.varProps?.isHidden,
									action: () => onUpdateVariable(grIndex, { 'varProps.isHidden': false })
								}
							]
						},
						{
							name: t('model:menu.editable'),
							icon: EditIcon,
							items: [
								{
									name: t('common:options.yes'),
									isSelectable: true,
									isActive: variable.varProps?.isEditable,
									action: () => onUpdateVariable(grIndex, { 'varProps.isEditable': true })
								},
								{
									name: t('common:options.no'),
									isSelectable: true,
									isActive: !variable.varProps?.isEditable,
									action: () => onUpdateVariable(grIndex, { 'varProps.isEditable': false })
								}
							]
						},
						{
							name: t('model:menu.publish'),
							icon: PublishIcon,
							items: [
								{
									name: t('common:options.yes'),
									isSelectable: true,
									isActive: variable.varProps?.isPublish,
									action: () => onPublishVariable(row.id, { 'varProps.isPublish': true })
								},
								{
									name: t('common:options.no'),
									isSelectable: true,
									isActive: !variable.varProps?.isPublish,
									action: () => onPublishVariable(row.id, { 'varProps.isPublish': false })
								}
							]
						},
						{
							name: t('model:menu.categoryAggr'),
							icon: CategoriesIcon,
							items: CATEGORY_AGGREGATION.map((item) => ({
								name: item.label,
								isSelectable: true,
								isActive: variable.varProps?.categoryAggr === item.key,
								action: () => onCategoryAggregation(grIndex, item.key)
							}))
						},
						{
							name: t('model:menu.breakdownEmpty'),
							icon: ZeroIcon,
							items: [
								{
									name: t('model:menu.breakdownEmptyShow'),
									isSelectable: true,
									isActive: !hideZeros && !hideNulls,
									action: () => onVariableView(grIndex, false, false)
								},
								{
									name: t('model:menu.breakdownEmptyHideZeros'),
									isSelectable: true,
									isActive: hideZeros && !hideNulls,
									action: () => onVariableView(grIndex, true, false)
								},
								{
									name: t('model:menu.breakdownEmptyHideNulls'),
									isSelectable: true,
									isActive: !hideZeros && hideNulls,
									action: () => onVariableView(grIndex, false, true)
								},
								{
									name: t('model:menu.breakdownEmptyHideAll'),
									isSelectable: true,
									isActive: hideZeros && hideNulls,
									action: () => onVariableView(grIndex, true, true)
								}
							]
						},
						{
							name: t('model:menu.api'),
							icon: DownloadIcon,
							action: () => onApi(position, row.id)
						},
						{
							name: t('model:menu.export'),
							icon: DownloadIcon,
							action: () => onExport(grIndex)
						},
						{ name: t('model:menu.deleteVariable'), icon: CloseIcon, action: () => setOpenDeleteRow({ grIndex, isGroup }) }
					]
				}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[isDesigning, tabs, tabIndex, getRow, getVariableView]
	)

	// #### ACTIONS CALLED BY GRID
	const consolidateFormula = useCallback(
		(value, grIndex, gcIndex, expressions, isStatic) => {
			const { colIndex, col } = getColumn(gcIndex)
			const firstTimestep = getFirstTimestep()
			const lastTimestep = getLastTimestep()
			// Check if it's static or dynamic
			const isNewStatic = col.id === MODEL_PARAMS.STATIC_COL_ID

			var expression = null
			var newExpressions = []
			if (isNewStatic) {
				expression = { expression: value }
				newExpressions = [expression]
			} else {
				const isFirst = expressions?.length > 0 && !isStatic ? false : true
				// Prepare the new formula data
				const start = !isFirst && col.id !== firstTimestep ? col.id : MODEL_PARAMS.COL_START_TIMESTAMP
				const end = isFirst || col.id === lastTimestep ? MODEL_PARAMS.COL_END_TIMESTAMP : columns[colIndex + 1]?.id
				expression = { expression: value, start: start, end: end }
				// Merge with previous expressions
				newExpressions = isFirst ? [expression] : updateRowFormulas(expression, expressions)
			}
			return { expression, expressions: newExpressions, isStatic: isNewStatic }
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[tid, teamId, aid, columns, getRow, getColumn, cellFormula, getFirstTimestep, getLastTimestep]
	)

	const onChangeCell = useCallback(
		(_value, grIndex, gcIndex, categoryValues = null, categoryIDs = null) => {
			const { row, variable } = getRow(grIndex)
			const { col } = getColumn(gcIndex)

			// Identification
			const value = _value != null ? _value + '' : null // All values and formulas stored as text to avoid codemirror to crash
			const isHeader = row.id === MODEL_PARAMS.HEAD_ROW_ID
			const isNotEmpty = value?.length > 0
			const isLabel = col.id === MODEL_PARAMS.HEAD_COL_ID
			const isGroup = row?.isGroup
			const isBreakdown = row?.isBreakdown || false
			const isConnected = variable?.sourceProps != null

			if (isHeader) return null
			// Update group label
			if (isDesigning && isLabel && isGroup && isNotEmpty) dispatch(updateModel({ tid, aid, content: { [`groups.${variable.id}.label`]: value } }))
			// Update variable label
			else if (isDesigning && isLabel && !isGroup && isNotEmpty && !isBreakdown) dispatch(updateVariable({ tid, aid, vid: variable.id, content: { label: value } }))
			// Update formula
			else if (isDesigning && !isLabel && !isBreakdown && !isConnected) {
				const isPublic = variable?.varProps?.isPublish || false
				const { expression, expressions, isStatic } = consolidateFormula(value, grIndex, gcIndex, variable?.expressions, variable?.varProps?.isStatic)
				if (expressions && !isEqual(variable?.expressions, expressions)) {
					dispatch(updateVariable({ tid, aid, vid: variable.id, content: { expressions, 'varProps.isStatic': isStatic } }))
					dispatch(updateFormula({ tid, teamId, aid, id: variable.id, updates: { [variable.id]: { id: variable.id, expression, isStatic, isPublic } } }))
				}
			}
			// Insert override
			else if (!isLabel && (isBreakdown || isConnected || !isDesigning)) {
				const orderedBreakdown = orderBreakdownValues(variable.categories, categoryIDs, categoryValues)
				const overrides = { id: variable.id, varProps: variable.varProps, overrides: [{ categories: orderedBreakdown || [], values: { [col.id]: value } }] }
				dispatch(updateCellOverrides({ tid, aid, overrides: { [variable.id]: overrides } }))
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[tid, teamId, aid, isDesigning, getRow, getColumn, consolidateFormula]
	)

	const onPaste = useCallback(
		(data, colIndexes) => {
			if (!data) return
			var updateGroups = {}
			var updateVariables = {}
			var updateOverrides = {}
			var updateExpressions = {}

			console.log('onPaste', data)

			Object.keys(data).forEach((grIndexStr) => {
				// Get label, values and choose between static and dynamic
				const rowValues = data[grIndexStr]
				const label = rowValues[MODEL_PARAMS.HEAD_ROW_ID]
				const staticValue = rowValues[MODEL_PARAMS.STATIC_COL_ID]
				let values = { ...rowValues }
				if (staticValue != null && staticValue !== '') values = { [MODEL_PARAMS.STATIC_COL_ID]: staticValue }
				else {
					delete values[MODEL_PARAMS.HEAD_ROW_ID]
					delete values[MODEL_PARAMS.STATIC_COL_ID]
				}
				const hasValues = Object.keys(values)?.length > 0

				//Identification
				const grIndex = parseInt(grIndexStr)
				const { row, variable } = getRow(grIndex)
				if (!row) return
				// Identification
				const isGroup = row?.isGroup
				const isBreakdown = row?.isBreakdown || false
				const isConnected = variable?.sourceProps != null
				const isPublic = variable?.varProps?.isPublish || false

				// Update labels
				if (label && isGroup) updateGroups = { ...updateGroups, [`groups.${variable.id}.label`]: label }
				else if (label && !isGroup) updateVariables = { ...updateVariables, [variable.id]: { label } }
				if (!hasValues) return

				// Insert overrides in connected variable
				if ((isConnected || !isDesigning) && !isBreakdown) {
					updateOverrides[variable.id] = { id: variable.id, varProps: variable.varProps, overrides: [{ categories: [], values }] }
				}
				// Insert overrides in breakdowns
				else if (isBreakdown) {
					const breakdownIndex = row?.breakdownIndex
					const categoriesIds = breakdown[variable.id]?.labels?.map((l) => l[0])
					const categoriesValues = breakdown[variable.id]?.values[breakdownIndex]?.c
					const orderedBreakdown = orderBreakdownValues(variable.categories, categoriesIds, categoriesValues)
					if (!updateOverrides[variable.id]) updateOverrides[variable.id] = { id: variable.id, varProps: variable.varProps, overrides: [] }
					updateOverrides[variable.id].overrides.push({ categories: orderedBreakdown, values })
				}
				// Update formulas
				else if (!isBreakdown && !isConnected && isDesigning) {
					Object.entries(values)?.forEach((entry) => {
						const colId = entry[0]
						const value = entry[1] != null ? entry[1] + '' : null
						const gcIndex = colIndexes[colId]
						const prevExpressions = updateVariables[variable.id]?.expressions || variable?.expressions
						const prevIsStatic = (updateVariables[variable.id] && updateVariables[variable.id]['varProps.isStatic']) || variable?.varProps?.isStatic
						const { expression, expressions, isStatic } = consolidateFormula(value, grIndex, gcIndex, prevExpressions, prevIsStatic)
						if (!isEqual(variable?.expressions, expressions)) {
							if (!updateVariables[variable.id]) updateVariables[variable.id] = {}
							updateVariables[variable.id] = { ...updateVariables[variable.id], expressions, 'varProps.isStatic': isStatic }

							if (!updateExpressions[variable.id]) updateExpressions[variable.id] = { isStatic, isPublic, expressions: [] }
							updateExpressions[variable.id].expressions.push(expression)
						}
					})
				}
			})

			// Update groups
			if (Object.keys(updateGroups)?.length > 0) dispatch(updateModel({ tid, aid, content: updateGroups }))
			// Update variables and formulas
			Object.entries(updateVariables)?.forEach((entry) => {
				const vid = entry[0]
				dispatch(updateVariable({ tid, aid, vid, content: entry[1] }))
				const varExpsDef = updateExpressions[vid]
				const varExps = mergeSequentialExpressions(varExpsDef?.expressions)
				dispatch(updateFormula({ tid, teamId, aid, id: vid, updates: { [vid]: { id: vid, expressions: varExps, isStatic: varExpsDef.isStatic, isPublic: varExpsDef.isPublic } } }))
			})

			// Update overrides
			if (Object.keys(updateOverrides)?.length > 0) dispatch(updateCellOverrides({ tid, aid, overrides: updateOverrides }))
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[tid, teamId, aid, breakdown, getRow, consolidateFormula, isDesigning]
	)

	// Copy a cell to many cells
	const onExpandCell = useCallback(
		(grIndex, gcIndex, destRIndex, destCIndex) => {
			var value = null
			const formula = cellFormula(grIndex, gcIndex)
			if (formula.canHaveFormula) value = formula.formula?.expression || null
			else {
				const valueMap = getData(grIndex, gcIndex)
				value = valueMap?.value
			}

			const colStart = Math.min(gcIndex, destCIndex)
			const colEnd = Math.max(gcIndex, destCIndex)
			var values = { [grIndex]: {} }
			var colIndexes = {}
			for (let index = colStart; index <= colEnd; index++) {
				const { col } = getColumn(index)
				values[grIndex] = { ...values[grIndex], [col.id]: value }
				colIndexes[col.id] = index
			}
			onPaste(values, colIndexes)
		},
		[data, getColumn, onPaste, getData, cellFormula]
	)

	// Delete cell content
	const onDelete = useCallback(
		(min, max) => {
			var updates = {}
			var colIndexes = {}
			for (let grIndex = min.row; grIndex <= max.row; ++grIndex) {
				const { row } = getRow(grIndex)
				if (row.id === MODEL_PARAMS.HEAD_ROW_ID) continue
				updates[grIndex] = {}
				for (let gcIndex = min.col; gcIndex <= max.col; ++gcIndex) {
					const { col } = getColumn(gcIndex)
					if (!disableCell(grIndex, gcIndex)) updates[grIndex][col.id] = null
					colIndexes[col.id] = gcIndex
				}
			}
			if (Object.keys(updates)?.length > 0) onPaste(updates, colIndexes)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[getRow, getColumn, disableCell]
	)

	// Add a new row
	const onAddRow = useCallback(
		(grIndex, isGroup) => {
			const rowId = grIndex ? getRow(grIndex)?.row?.id : null
			dispatch(addRow({ tid, teamId, aid, groupId: rowId, isGroup }))
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[tid, teamId, aid, getRow]
	)

	// Update rowprops
	const onUpdateVariable = useCallback(
		(grIndex, updates) => {
			const { variable } = getRow(grIndex)
			dispatch(updateVariable({ tid, aid, vid: variable.id, content: updates }))
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[tid, teamId, aid, getRow]
	)

	const onCategoryAggregation = useCallback(
		(grIndex, categoryAggregation) => {
			const { variable } = getRow(grIndex)
			dispatch(setVariableCategoryAggr({ tid, aid, vid: variable.id, categoryAggregation }))
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[tid, teamId, aid, getRow]
	)

	// Delete a row
	const onDeleteRow = useCallback(() => {
		const grIndex = openDeleteRow?.grIndex
		const { variable, row } = getRow(grIndex)
		dispatch(deleteRow({ tid, teamId, aid, rowId: variable.id, isGroup: row.isGroup }))
		setOpenDeleteRow({ grIndex: null, isGroup: false })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tid, teamId, aid, openDeleteRow, getRow])

	// Open the connect data modal
	const onConnectData = useCallback(
		(position, grIndex) => {
			if (!grIndex) setConnectData({ position, rowId: null })
			else {
				const { variable } = getRow(grIndex)
				setConnectData({ position, rowId: variable.id })
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[getRow]
	)

	// Break down into categories
	const onBreakCategories = useCallback(
		(position, grIndex) => {
			if (!grIndex) setBreakCategories({ position, rowId: null })
			else {
				const { variable } = getRow(grIndex)
				setBreakCategories({ position, rowId: variable.id })
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[getRow]
	)

	// Toggle row visibility
	const onToggleRow = useCallback(
		(grIndex) => {
			const { variable, row } = getRow(grIndex)
			const isGroup = row.isGroup
			const isGroupOpen = (isGroup && _isGroupOpen(viewGroups, variable.id)) || false
			const isVarOpen = (!isGroup && viewVariables && viewVariables[variable.id]?.isOpen) || false
			const isOpen = isGroupOpen || isVarOpen
			if (isGroup) dispatch(setInView({ aid, changes: [{ path: `groups.${variable.id}.isOpen`, value: !isOpen }], permits }))
			else dispatch(setInView({ aid, changes: [{ path: `variables.${variable.id}.isOpen`, value: !isOpen }], permits }))
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[tid, teamId, aid, getRow, permits, viewGroups, viewVariables]
	)

	const onPageNext = useCallback(
		(grIndex, pageIncrease) => {
			const { variable } = getRow(grIndex)
			const data = breakdown[variable.id]
			const page = data.page + pageIncrease
			if (page < 0) return
			dispatch(nextPage({ aid, vid: variable.id, page, permits }))
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[tid, teamId, aid, getRow, breakdown, permits]
	)

	const onDrillDown = useCallback(
		(position, grIndex) => {
			if (!grIndex) setDrillDown({ position, rowId: null })
			else {
				const { variable } = getRow(grIndex)
				setDrillDown({ position, rowId: variable.id })
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[getRow]
	)

	const onDrillDownChange = useCallback(
		(variableId, breakdown) => {
			dispatch(
				setInView({
					aid,
					changes: [
						{ path: `variables.${variableId}.page`, value: 0 },
						{ path: `variables.${variableId}.breakdown`, value: breakdown }
					],
					permits
				})
			)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[tid, teamId, aid, permits]
	)

	// Resize a column
	const onColumnResize = useCallback(
		(gcIndex, width) => {
			const { col } = getColumn(gcIndex)
			dispatch(setInView({ aid, changes: [{ path: `colProps.${col.id}.width`, value: width }], permits }))
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[aid, getColumn, permits]
	)

	// Move the position of a row
	const onMoveRow = useCallback(
		(gDragIndex, gDropIndex) => {
			const { rowIndex: dragIndex } = getRow(gDragIndex.row)
			const { rowIndex: dropIndex } = getRow(gDropIndex.row)
			dispatch(moveRow({ tid, aid, dragIndex, dropIndex, rows }))
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[tid, aid, getRow, rows]
	)

	// Move a group to another tab
	const onChangeGroupTab = useCallback(
		(tabIndex, groupId) => {
			dispatch(changeGroupTab({ tid, aid, groupId, tabIndex }))
		},
		[tid, aid]
	)

	// Create a chart
	const onCreateChart = useCallback(
		(grIndex, chartType) => {
			const { variable } = getRow(grIndex)
			dispatch(createChart({ tid, teamId, aid, varId: variable.id, chartType }))
			if (!chartPanel?.isOpen)
				dispatch(setInView({ aid, changes: [{ path: 'chartPanel', value: { isOpen: true, direction: 'horizontal', position: 'right', size: MODEL_PARAMS.CHART_PANEL_SIZE } }], permits }))
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[tid, aid, teamId, getRow, permits]
	)

	// Format cells
	const onFormatOptions = useCallback((position, rowId) => setFormatOptions({ position, rowId }), [])

	// Publish variable
	const onPublishVariable = useCallback(
		(rowId, updates) => {
			dispatch(setVariablePublic({ tid, teamId, aid, vid: rowId, updates }))
			dispatch(publishRow({ tid, teamId, aid, vid: rowId, publish: updates['varProps.isPublish'] }))
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[tid, teamId, aid, getRow]
	)

	const onCopy = useCallback(
		(min, max) => {
			var copyData = []
			var copyFormula = isDesigning ? [] : null
			for (let grIndex = min.row; grIndex <= max.row; ++grIndex) {
				copyData.push([])
				if (isDesigning) copyFormula.push([])
				for (let gcIndex = min.col; gcIndex <= max.col; ++gcIndex) {
					const itemDataMap = getData(grIndex, gcIndex)
					const itemData = itemDataMap?.formatValue
					const itemFormula = cellFormula(grIndex, gcIndex).formula?.expression || (itemData != null && itemData !== '' ? itemData : null)
					copyData[grIndex - min.row].push(itemData)
					if (isDesigning) copyFormula[grIndex - min.row].push(itemFormula)
				}
			}
			return { copyData, copyFormula }
		},
		[getData, cellFormula, isDesigning]
	)

	const onChartPanelResize = useCallback(
		(size) => {
			dispatch(setInView({ aid, changes: [{ path: 'chartPanel.size', value: size }], permits }))
		},
		[aid, permits]
	)

	const onSearchPanelResize = useCallback(
		(size) => {
			dispatch(setInView({ aid, changes: [{ path: 'searchPanel.size', value: size }], permits }))
		},
		[aid, permits]
	)

	const onExport = useCallback(
		(grIndex) => {
			const { variable } = getRow(grIndex)
			dispatch(exportVariable({ tid, teamId, aid, vid: variable.id }))
		},
		[tid, teamId, aid, getRow]
	)

	const onApi = useCallback((position, rowId) => setGetFromApi({ position, rowId }), [])

	const onVariableView = useCallback(
		(grIndex, hideZeros, hideNulls) => {
			const { variable } = getRow(grIndex)
			dispatch(
				setInView({
					aid,
					changes: [
						{ path: `variables.${variable.id}.hideZeros`, value: hideZeros },
						{ path: `variables.${variable.id}.hideNulls`, value: hideNulls },
						{ path: `variables.${variable.id}.page`, value: 0 }
					],
					permits
				})
			)
		},
		[aid, getRow, permits]
	)

	// Subcell resize
	var resizePos = useRef()
	var resizeRowId = useRef()
	var resizeElemWidth = useRef()
	var resizeParentWidth = useRef()
	var resizePercentWidth = useRef()

	const onCellResize = useCallback(
		(e, grIndex) => {
			if (e.preventDefault) e.preventDefault()
			if (e.stopPropagation) e.stopPropagation()

			// Current element sizes
			const cellElem = e.target.parentElement
			const parentElem = cellElem.parentElement
			resizeElemWidth.current = parseInt(getComputedStyle(cellElem, '')['width'])
			resizeParentWidth.current = parseInt(getComputedStyle(parentElem, '')['width'])

			// Variable id
			const { variable } = getRow(grIndex)
			resizeRowId.current = variable.id
			resizePos.current = e.x

			// Event listeners
			document.addEventListener('mousemove', cellResize)
			document.addEventListener('mouseup', cellStopResize)
			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		[getRow]
	)

	const cellStopResize = useCallback(
		(e) => {
			if (e.preventDefault) e.preventDefault()
			if (e.stopPropagation) e.stopPropagation()
			document.removeEventListener('mousemove', cellResize)
			document.removeEventListener('mouseup', cellStopResize)

			dispatch(setInView({ aid, changes: [{ path: `cellSplit.${resizeRowId.current}`, value: resizePercentWidth.current }], permits }))

			resizePos.current = 0
			resizeRowId.current = null
			resizeElemWidth.current = 0
			resizeParentWidth.current = 0
			resizePercentWidth.current = 0
			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		[aid, permits]
	)

	const cellResize = useCallback((e) => {
		if (e.preventDefault) e.preventDefault()
		if (e.stopPropagation) e.stopPropagation()

		// Measure movement
		const prevPos = resizePos.current
		const mousePos = e.x
		resizePos.current = mousePos
		if (!prevPos) return
		const dx = prevPos - mousePos

		// Calculate percentage
		resizeElemWidth.current -= dx
		const percentSize = (resizeElemWidth.current / resizeParentWidth.current) * 100.0
		const limitedPercentSize = Math.max(Math.min(percentSize, 90), 10)
		resizePercentWidth.current = limitedPercentSize

		// Save result
		let newCellSplit = cellSplitRef.current ? { ...cellSplitRef.current } : {}
		newCellSplit = { ...(cellSplitRef.current || {}), [resizeRowId.current]: limitedPercentSize }
		setCellSplit(newCellSplit)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const calculateStats = useCallback(
		(selection) => {
			let sum = 0
			let count = 0
			if (selection == null) {
				setStatistics(null)
				return
			}

			// Iterate rows
			for (let grIndex = selection.min.row; grIndex <= selection.max.row; grIndex++) {
				const { row } = getRow(grIndex)
				const isBreakdown = row?.isBreakdown || false

				// if row is a header, continue
				if (row.id === MODEL_PARAMS.HEAD_ROW_ID) {
					setStatistics(null)
					return
				}

				// Iterate columns
				for (let gcIndex = selection.min.col; gcIndex <= selection.max.col; gcIndex++) {
					const { col } = getColumn(gcIndex)

					// if col is a label, continue
					if (col.id === MODEL_PARAMS.HEAD_COL_ID) {
						setStatistics(null)
						return
					}

					// extract data
					let value = null
					if (isBreakdown) {
						const breakdownIndex = row.breakdownIndex
						const meanPos = breakdown[row.id]?.valueNames?.default
						const varData = breakdown[row.id]?.values[breakdownIndex]
						value = varData?.v && varData?.v[col.id] ? varData?.v[col.id][meanPos] : null
					} else {
						const varData = data[row.id]
						const meanPos = varData?.valueNames?.default
						value = varData?.values && varData?.values[0]?.v[col.id] ? varData?.values[0]?.v[col.id][meanPos] : null
					}

					// aggregate
					if (value != null && typeof value === 'number') {
						sum += value
						count += 1
					}
				}
			}

			const average = count > 0 ? sum / count : 0
			setStatistics({ sum, count, average })
		},
		[getRow, getColumn, data, breakdown]
	)

	const onSelection = useCallback(
		debounce(
			500,
			(selection) => {
				calculateStats(selection)
			},
			{ atBegin: false }
		),
		[calculateStats]
	)

	const scrollToToday = useCallback(() => {
		gridRef.current.scrollTo({ col: todayCol })
	}, [gridRef, todayCol])

	// Elements to pass directly to the grid's cell. This is used for data and functions that are not standard of the grid component
	const onChangeCellRef = useRef(onChangeCell)
	const onAddRowRef = useRef(onAddRow)
	const onConnectDataRef = useRef(onConnectData)
	const onBreakCategoriesRef = useRef(onBreakCategories)
	const onToggleRowRef = useRef(onToggleRow)
	const onPageNextRef = useRef(onPageNext)
	const onDrillDownRef = useRef(onDrillDown)
	const onCellResizeRef = useRef(onCellResize)
	onChangeCellRef.current = onChangeCell
	onAddRowRef.current = onAddRow
	onConnectDataRef.current = onConnectData
	onBreakCategoriesRef.current = onBreakCategories
	onToggleRowRef.current = onToggleRow
	onPageNextRef.current = onPageNext
	onDrillDownRef.current = onDrillDown
	onCellResizeRef.current = onCellResize

	// Grid needs refs because it does not re-render when functions change
	const gridItemData = useMemoObject({
		rows,
		groups,
		variables,
		breakdown,
		errors,
		isDesigning,
		canGroupsOpen,
		canVarsOpen,
		viewGroups,
		viewVariables,
		displayIntervals,
		cellSplit,
		categories,
		today,
		highlightRange,
		statistics,
		onChangeCellRef,
		onAddRowRef,
		onConnectDataRef,
		onBreakCategoriesRef,
		onToggleRowRef,
		onPageNextRef,
		onDrillDownRef,
		onCellResizeRef
	})

	// Formula don't need refs because it re-renders normally
	const formulaData = useMemoObject({
		variables: formulaVariables,
		attributes: formulaAttributes,
		getFormulaFunction: cellFormula,
		saveFormula: onChangeCell
	})

	// function handleTourCallback(data) {
	// 	const { status } = data
	// 	const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED]
	// 	if (!isDesigning && data.index === 0 && data.lifecycle === 'complete' && data.action === 'next') dispatch(toggleDesignMode({ id: aid }))
	// 	if (finishedStatuses.includes(status)) {
	// 		setRun(false)
	// 		dispatch(updateUser({ tid, uid, content: { modelTour: true } }))
	// 	}
	// }

	if (isDesigning && !isEngineReady) return <LoadingBody message={t('model:model.engineLoading')} />
	else
		return (
			<div className="flex flex-col relative flex-1 h-full" ref={wrapperRef}>
				{/* <ModelTour run={run} callback={handleTourCallback} /> */}
				{isVizReady &&
					variables &&
					Object.keys(variables).map((vid) => (
						<VariableDataLoader key={vid} tid={tid} teamId={teamId} aid={aid} vid={vid} modelProps={modelProps} filter={filter} sort={sort} itemsPerPage={itemsPerPage} dates={dates} />
					))}
				<AlertDialog messageKey={`modelEngine#${aid}`} />
				<PanelLayout config={searchPanel} blockScroll content={<ModelSearch aid={aid} />} onResize={onSearchPanelResize}>
					<PanelLayout config={chartPanel} blockScroll content={<ModelCharts aid={aid} isVizReady={isVizReady} isDesigning={isDesigning} filter={filter} sort={sort} />} onResize={onChartPanelResize}>
						<ModelTabs aid={aid} isDesigning={isDesigning} />
						{teamId && tabs?.length > 0 && columns?.length > 0 && (
							<FormulaBarContext.Provider value={formulaData}>
								<Grid
									ref={gridRef}
									items={data}
									columns={columns}
									columnProps={colProps}
									rowCount={rowCount}
									colCount={columns?.length || 0}
									defaultColWidth={MODEL_PARAMS.DEFAULT_COLUMN_WIDTH}
									defaultRowHeight={MODEL_PARAMS.DEFAULT_ROW_HEIGHT}
									canPasteExceedGrid={false}
									expandDirection="horizontal"
									intermediateCell={{ col: todayCol }}
									onColumnResizeFunction={onColumnResize}
									onDropFunction={onMoveRow}
									onExpandCell={onExpandCell}
									data={gridItemData}
									getCellTypeFunction={getCellType}
									disableCellFunction={disableCell}
									getContextMenuFunction={cellContextMenu}
									getFormulaFunction={cellFormula}
									showFormulaBar={isDesigning}
									Cell={Cell}
									CellStickyColumn={CellStickyColumn}
									CellStickyRow={CellStickyRow}
									CellStickyTopLeft={CellStickyTopLeft}
									onPasteFunction={onPaste}
									onCopyFunction={onCopy}
									onDeleteFunction={onDelete}
									onSetSelection={onSelection}
								/>
							</FormulaBarContext.Provider>
						)}
						{Boolean(openDeleteRow?.grIndex) && (
							<ActionConfirm
								open={Boolean(openDeleteRow?.grIndex)}
								title={openDeleteRow?.isGroup ? t('model:messages.groupDeleteConfirm') : t('model:messages.varDeleteConfirm')}
								content={openDeleteRow?.isGroup ? t('model:messages.groupDeleteConfirmContent') : t('model:messages.varDeleteConfirmContent')}
								onClose={() => setOpenDeleteRow({ grIndex: null, isGroup: false })}
								onConfirm={onDeleteRow}
							/>
						)}
						{getFromApi.rowId && (
							<ModelConnectApi
								teamId={teamId}
								aid={aid}
								position={getFromApi.position}
								onClose={() => onApi(null, null)}
								varId={getFromApi.rowId}
								variable={getFromApi.rowId ? variables[getFromApi.rowId] : null}
							/>
						)}
						{connectData.rowId && (
							<ModelConnectData
								aid={aid}
								position={connectData.position}
								onClose={() => onConnectData(null, null)}
								varId={connectData.rowId}
								variable={connectData.rowId ? variables[connectData.rowId] : null}
							/>
						)}
						{breakCategories.rowId && (
							<ModelVarCategories
								aid={aid}
								position={breakCategories.position}
								onClose={() => onBreakCategories(null, null)}
								varId={breakCategories.rowId}
								variable={breakCategories.rowId ? variables[breakCategories.rowId] : null}
							/>
						)}
						{formatOptions.rowId && (
							<TableType
								position={formatOptions.position}
								onClose={() => onFormatOptions(null, null)}
								onChangeVarId={(newVarId) => onFormatOptions(formatOptions.position, newVarId)}
								aid={aid}
								varId={formatOptions.rowId}
								variable={formatOptions.rowId ? variables[formatOptions.rowId] : null}
								variables={variables}
								isModelVersion={true}
							/>
						)}
						{drillDown.rowId && <ModelDrillDown aid={aid} varId={drillDown.rowId} position={drillDown.position} onSave={onDrillDownChange} onClose={() => onDrillDown(null, null)} />}
					</PanelLayout>
				</PanelLayout>
			</div>
		)
}
