import { createContext } from 'react'

export const SelectionContext = createContext({
	columnRights: [],
	columnWidths: [],
	rowHeight: 0,
	rowCount: 0,
	stickyCols: 0,
	stickyRows: 0,
	activeCell: null,
	selection: null,
	expansion: null,
	isEditing: false,
	isCellDisabled: () => false,
	expandStickyCol: false
})
