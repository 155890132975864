import CreateTextCell from 'common/components/grid/cells/CreateTextCell'
import { isPointDecimalSeparator, printNumWithProps } from 'common/utils/numbers'
import { NUMBER_FORMATS } from 'common/constants/formats'
import { DATA_TYPES } from 'common/constants/dataTypes'

export const formatNum = ({ value, typeProps }) => {
	return printNumWithProps(value, DATA_TYPES.num.key, typeProps)
}

export const parseNum = ({ value, typeProps, isPercent = false }) => {
	const numberFormat = typeProps?.numberFormat ? NUMBER_FORMATS[typeProps.numberFormat] : null
	const isPointDecimal = isPointDecimalSeparator(numberFormat?.locale)
	const decimalPointValue = isPointDecimal ? value?.replace(',', '') : value?.replace('.', '').replace(',', '.')
	const parsedNumber = parseFloat(decimalPointValue)
	if (isNaN(parsedNumber)) return null
	var number = !typeProps?.allowNegative ? Math.abs(parsedNumber) : parsedNumber
	number = isPercent ? number / 100 : number
	number = !typeProps.allowDecimals ? Math.round(number) : number
	number = typeProps.numOfDecimals ? +number.toFixed(typeProps.numOfDecimals) : number
	return !isNaN(number) ? number : null
}

export const cellNum = {
	component: CreateTextCell,
	config: {
		alignRight: true,
		formatBlurredInput: formatNum,
		formatCopyValue: formatNum,
		parseUserInput: parseNum,
		parsePastedValue: parseNum
	}
}
