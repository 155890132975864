import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { closeMessage } from 'common/store/globalReducer'
import { Collapse, IconButton, Alert as AlertLib } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import clsx from 'clsx'

export default function Alert({ messageKey, className }) {
	const dispatch = useDispatch()
	const messageStore = useSelector((state) => state.global.message[messageKey])

	const [message, setMessage] = useState(null)

	useEffect(() => {
		if (messageStore?.isVisible) setMessage({ ...messageStore })
	}, [messageStore])

	function onClose() {
		dispatch(closeMessage({ key: messageKey }))
	}

	return (
		<Collapse in={messageStore?.isVisible}>
			<AlertLib
				className={clsx('text-sm', className)}
				severity={message?.type}
				action={
					<IconButton aria-label="close" color="inherit" size="small" onClick={onClose}>
						<CloseIcon fontSize="inherit" />
					</IconButton>
				}
			>
				{message?.content}
			</AlertLib>
		</Collapse>
	)
}
