import useLocale from 'common/hooks/useLocale'
import { fromTimestampToDate, printDate } from 'common/utils/dates'

export default function useFormatter() {
	const { locale, dateMask } = useLocale()
	function printTimestamp(timestamp) {
		if (timestamp === null || timestamp === '') return ''
		const dateTime = fromTimestampToDate(timestamp)
		const dateStr = printDate(dateTime, locale, dateMask)
		return dateStr
	}

	return { printTimestamp }
}
