import { FormControlLabel, MenuItem, Switch, TextField, Button } from '@mui/material'
import { DATE_FORMATS } from 'common/constants/formats'
import { FREQUENCY } from 'common/constants/frequencies'
import useLocale from 'common/hooks/useLocale'
import { fromTimestampToDate, getDatePeriodStart } from 'common/utils/dates'
import { updateChart } from 'model/saga-actions/chartActions'
import { useState, useEffect, useMemo } from 'react'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { DateTime } from 'luxon'
import { validateNotEmpty } from 'common/utils/validate'
import { CHART_STACK } from 'common/constants/charts'

export default function ChartSettingsGeneral({ aid, chart, onClose }) {
	const dispatch = useDispatch()
	const { t } = useTranslation(['common', 'model'])

	// #### REDUX
	const tid = useSelector((state) => state.auth.tid)
	const modelProps = useSelector((state) => state.model.model[aid]?.data?.modelProps)
	const typeProps = modelProps?.typeProps
	const dateProps = typeProps?.dateFormat ? DATE_FORMATS[typeProps.dateFormat] : null
	const locale = useLocale(dateProps?.locale)

	const CHART_FREQ = useMemo(() => {
		const modelFreq = FREQUENCY[modelProps.frequency]
		var chartFreq = {}
		Object.keys(FREQUENCY).forEach((key) => {
			const freq = FREQUENCY[key]
			if (freq.index >= modelFreq.index) chartFreq[key] = freq
		})
		return chartFreq
	}, [modelProps?.frequency])

	// #### DEFAULT VALUES
	const defaultForm = {
		hasLegend: true,
		hasIntervals: true,
		hasFrequency: false,
		hasStartDate: false,
		hasEndDate: false,
		frequency: '',
		startDate: '',
		endDate: '',
		stack: CHART_STACK.no.key
	}

	const defaultValidation = {
		frequency: { valid: true, error: '' },
		startDate: { valid: true, error: '' },
		endDate: { valid: true, error: '' }
	}

	// #### STATE
	const [form, setForm] = useState(defaultForm)
	const [validation, setValidation] = useState(defaultValidation)

	// #### EFFECTS
	useEffect(() => {
		if (chart?.generalProps) setForm(initialize())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chart?.generalProps])

	function initialize() {
		const hasLegend = chart?.generalProps.hasLegend || false
		const hasIntervals = chart?.generalProps.hasIntervals || false
		const hasFrequency = chart?.generalProps.hasFrequency || false
		const hasStartDate = chart?.generalProps.hasStartDate || false
		const hasEndDate = chart?.generalProps.hasEndDate || false
		const frequency = hasFrequency ? chart?.generalProps?.frequency : ''
		const startDate = hasStartDate ? fromTimestampToDate(chart?.generalProps?.startDate) : ''
		const endDate = hasEndDate ? fromTimestampToDate(chart?.generalProps?.endDate) : ''
		const stack = chart?.generalProps?.stack || CHART_STACK.no.key
		return { hasLegend, hasIntervals, hasFrequency, hasStartDate, hasEndDate, frequency, startDate, endDate, stack }
	}

	// #### FUNCTIONS
	function onChange(key, value) {
		setForm({ ...form, [key]: value })
	}

	function onAdjustDate(key, date) {
		const freq = form.hasFrequency && form.frequency ? form.frequency : modelProps.frequency
		const utcDate = DateTime.utc(date.year, date.month, date.day, 0, 0, 0, 0)
		var adjustedDate = getDatePeriodStart(utcDate, freq, locale.locale).toUTC()
		onChange(key, adjustedDate)
	}

	function onSave() {
		// Prepare data
		const hasLegend = form.hasLegend
		const hasIntervals = form.hasIntervals
		const hasFrequency = form.hasFrequency
		const hasStartDate = form.hasStartDate
		const hasEndDate = form.hasEndDate
		const frequency = hasFrequency ? form.frequency : null
		const startDate = hasStartDate && form.startDate ? form.startDate.toJSDate() : null
		const endDate = hasEndDate && form.endDate ? form.endDate.toJSDate() : null
		const stack = form.stack

		// Validate data
		const freqValid = hasFrequency ? validateNotEmpty(frequency) : { valid: true, error: '' }
		const startDateValid = hasStartDate ? validateNotEmpty(startDate) : { valid: true, error: '' }
		const endDateValid = hasEndDate ? validateNotEmpty(endDate) : { valid: true, error: '' }
		setValidation({ ...validation, frequency: freqValid, startDate: startDateValid, endDate: endDateValid })

		// Save
		if (freqValid?.valid && startDateValid?.valid && endDateValid?.valid) {
			const generalProps = { ...chart.generalProps, hasLegend, hasIntervals, hasFrequency, hasStartDate, hasEndDate, frequency, startDate, endDate, stack }
			dispatch(updateChart({ tid, aid, cid: chart.id, content: { generalProps } }))
			onClose()
		}
	}

	return (
		<>
			<span className="font-medium text-sm text-textGray">{t('common:chartStack.stackBars')}</span>
			<TextField
				select
				fullWidth
				variant="outlined"
				size="small"
				value={form.stack}
				onChange={(event) => onChange('stack', event.target.value)}
				inputProps={{ className: 'text-sm' }}
				InputLabelProps={{ className: 'text-sm' }}
				className="mt-2"
			>
				{Object.keys(CHART_STACK).map((option) => (
					<MenuItem className="text-sm" key={CHART_STACK[option].key} value={CHART_STACK[option].key}>
						{t(CHART_STACK[option].label)}
					</MenuItem>
				))}
			</TextField>

			<FormControlLabel
				control={<Switch checked={form.hasLegend} onChange={(e) => onChange('hasLegend', e.target.checked)} color="primary" size="small" />}
				label={t('model:chart.legend')}
				classes={{ root: 'ml-[0.5px] mt-4', label: 'ml-2 text-sm font-medium text-textGray' }}
			/>

			<FormControlLabel
				control={<Switch checked={form.hasIntervals} onChange={(e) => onChange('hasIntervals', e.target.checked)} color="primary" size="small" />}
				label={t('model:chart.intervals')}
				classes={{ root: 'ml-[0.5px] mt-4', label: 'ml-2 text-sm font-medium text-textGray' }}
			/>

			<FormControlLabel
				control={<Switch checked={form.hasFrequency} onChange={(e) => onChange('hasFrequency', e.target.checked)} color="primary" size="small" />}
				label={t('model:chart.customGranularity')}
				classes={{ root: 'ml-[0.5px] mt-4', label: 'ml-2 text-sm font-medium text-textGray' }}
			/>
			{form.hasFrequency && (
				<TextField
					select
					fullWidth
					variant="outlined"
					size="small"
					value={form.frequency}
					onChange={(event) => onChange('frequency', event.target.value)}
					inputProps={{ className: 'text-sm' }}
					InputLabelProps={{ className: 'text-sm' }}
					className="mt-2"
					error={!validation.frequency.valid}
					helperText={!validation.frequency.valid && t(validation.frequency.error, { field: t('model:dates.granularity') })}
				>
					{Object.keys(CHART_FREQ).map((option) => (
						<MenuItem className="text-sm" key={CHART_FREQ[option].key} value={CHART_FREQ[option].key}>
							{t(CHART_FREQ[option].label)}
						</MenuItem>
					))}
				</TextField>
			)}
			<LocalizationProvider dateAdapter={AdapterLuxon}>
				<FormControlLabel
					control={<Switch checked={form.hasStartDate} onChange={(e) => onChange('hasStartDate', e.target.checked)} color="primary" size="small" />}
					label={t('model:chart.customStartDate')}
					classes={{ root: 'ml-[0.5px] mt-4', label: 'ml-2 text-sm font-medium text-textGray' }}
				/>

				{form.hasStartDate && (
					<DatePicker
						value={form.startDate}
						onChange={(value) => onChange('startDate', value)}
						inputFormat={locale.dateMask.mask}
						// label={t('model:dates.dateFrom')}
						onAccept={(value) => onAdjustDate('startDate', value)}
						renderInput={(params) => (
							<TextField
								{...params}
								margin="dense"
								size="small"
								fullWidth
								inputProps={{ ...params.inputProps, placeholder: locale.dateMask.placeholder, className: 'text-sm' }}
								InputProps={{ ...params.InputProps, onBlur: () => onAdjustDate('startDate', form.startDate) }}
								sx={{ svg: { width: '18px', height: '18px' } }}
								error={!validation.startDate.valid}
								helperText={!validation.startDate.valid && t(validation.startDate.error, { field: t('model:dates.dateFrom') })}
							/>
						)}
					/>
				)}

				<FormControlLabel
					control={<Switch checked={form.hasEndDate} onChange={(e) => onChange('hasEndDate', e.target.checked)} color="primary" size="small" />}
					label={t('model:chart.customEndDate')}
					classes={{ root: 'ml-[0.5px] mt-4', label: 'ml-2 text-sm font-medium text-textGray' }}
				/>

				{form.hasEndDate && (
					<DatePicker
						value={form.endDate}
						onChange={(value) => onChange('endDate', value)}
						inputFormat={locale.dateMask.mask}
						// label={t('model:dates.dateTo')}
						onAccept={(value) => onAdjustDate('endDate', value)}
						renderInput={(params) => (
							<TextField
								{...params}
								margin="dense"
								size="small"
								fullWidth
								inputProps={{ ...params.inputProps, placeholder: locale.dateMask.placeholder, className: 'text-sm' }}
								InputProps={{ ...params.InputProps, onBlur: () => onAdjustDate('endDate', form.startDate) }}
								sx={{ svg: { width: '18px', height: '18px' } }}
								error={!validation.endDate.valid}
								helperText={!validation.endDate.valid && t(validation.endDate.error, { field: t('model:dates.dateTo') })}
							/>
						)}
					/>
				)}
			</LocalizationProvider>

			<Button onClick={onSave} size="small" className="mt-4 buttonContainedContrast">
				{t('common:buttons.save')}
			</Button>
		</>
	)
}
