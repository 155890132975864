import clsx from 'clsx'

export default function BarButton({ icon: Icon, label, className, isActive = false, isDisabled = false, keepColorDisabled = false, onClick }) {
	return (
		<div
			className={clsx('self-stretch flex flex-col px-2 group justify-center items-center w-[45px] h-topbar', isActive && 'bg-contrast', !isDisabled && 'hover:bg-primaryDark cursor-pointer')}
			onClick={isDisabled ? undefined : onClick}
		>
			<Icon
				className={clsx('w-[28px] h-[28px] text-barTextStrong', className, !isDisabled && 'group-hover:w-[20px] group-hover:h-[20px] transition-all', isDisabled && !keepColorDisabled && 'text-barBorderLight')}
			/>
			<div className={clsx('text-xxs text-white h-0 overflow-hidden', className, !isDisabled && 'group-hover:h-[15px] transition-all')}>{label}</div>
		</div>
	)
}
