import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import AssetFilters from 'common/components/search/AssetFilters'
import AddIcon from '@mui/icons-material/Add'
import { IconButton } from '@mui/material'
import { useState } from 'react'
import AssetSort from 'common/components/search/AssetSort'
import { useAssetPermits } from 'common/hooks/useAssetPermits'
import { setInView } from 'common/saga-actions/viewActions'

export default function TableSearch({ aid, filter, sort }) {
	const { t } = useTranslation('common')
	const dispatch = useDispatch()
	const permits = useAssetPermits(aid)

	// #### REDUX
	const variables = useSelector((state) => state.table.table[aid]?.data?.variables)
	const isLoaded = useSelector((state) => state.table.table[aid]?.loaded) || false

	// #### STATE
	const [isAddFilterOpen, setIsAddFilterOpen] = useState(false)
	const [isAddSortOpen, setIsAddSortOpen] = useState(false)

	// #### FUNCTIONS
	function onChange(attribute, value) {
		dispatch(setInView({ aid, changes: [{ path: attribute, value }], permits }))
	}

	return (
		<>
			<div className="flex flex-col p-4 border-b border-b-borderGray">
				<div className="flex flex-row items-center justify-between">
					<span className="text-xs font-medium text-textGray uppercase">{t('table:search.filters')}</span>
					<IconButton size="small" onClick={() => setIsAddFilterOpen(!isAddFilterOpen)} className={isAddFilterOpen ? 'text-primary' : 'text-textGray'}>
						<AddIcon fontSize="small" />
					</IconButton>
				</div>

				<AssetFilters
					aid={aid}
					variables={variables}
					isLoaded={isLoaded}
					isAddOpen={isAddFilterOpen}
					filters={filter}
					onChange={(filter) => onChange('filter', filter)}
					onCloseAdd={() => setIsAddFilterOpen(false)}
					saveOnBlur={true}
				/>
			</div>
			<div className="flex flex-col p-4 border-b border-b-borderGray">
				<div className="flex flex-row items-center justify-between">
					<span className="text-xs font-medium text-textGray uppercase">{t('table:search.sort')}</span>
					<IconButton size="small" onClick={() => setIsAddSortOpen(!isAddSortOpen)} className={isAddSortOpen ? 'text-primary' : 'text-textGray'}>
						<AddIcon fontSize="small" />
					</IconButton>
				</div>

				<AssetSort aid={aid} variables={variables} isLoaded={isLoaded} isAddOpen={isAddSortOpen} sort={sort} onChange={(sort) => onChange('sort', sort)} onCloseAdd={() => setIsAddSortOpen(false)} />
			</div>
		</>
	)
}
