import { createSlice } from '@reduxjs/toolkit'
import { set } from 'dot-prop-immutable'
import { updateDiff } from 'common/store/helpers/diffHelper'

const initialState = {
	// Views from database
	personal: {},
	shared: {},
	// View currenctly in use
	current: {
		// id: null,
		// type: null,
		// data: {}
	}
}

const KEYS_TO_DIFF = ['variables', 'charts']

export const viewSlice = createSlice({
	name: 'view',
	initialState,
	reducers: {
		// Load views from database
		loadPersonalViews(state, action) {
			state.personal[action.payload.id] = { loaded: true, ...action.payload }
		},
		cancelPersonalViews: (state, action) => {
			state.personal[action.payload.id] = null
		},
		loadSharedViews(state, action) {
			state.shared[action.payload.id] = { loaded: true, ...action.payload }
		},
		cancelSharedViews: (state, action) => {
			state.shared[action.payload.id] = null
		},
		// Manage current view
		selectView(state, action) {
			state.current[action.payload.id] = state.current[action.payload.id] || {}
			state.current[action.payload.id].id = action.payload.vid
			state.current[action.payload.id].type = action.payload.type
			state.current[action.payload.id].loaded = false
		},
		refreshView(state, action) {
			state.current[action.payload.id] = state.current[action.payload.id] || {}
			state.current[action.payload.id].id = state.current[action.payload.id].id || action.payload.vid
			state.current[action.payload.id].type = state.current[action.payload.id].type || action.payload.type
			state.current[action.payload.id].data = state.current[action.payload.id].data || {}
			updateDiff(state.current[action.payload.id].data, action.payload.data, KEYS_TO_DIFF)
			KEYS_TO_DIFF.forEach((key) => {
				state.current[action.payload.id].data[key] = state.current[action.payload.id].data[key] || {}
				updateDiff(state.current[action.payload.id].data[key], action.payload.data[key], null)
			})
			state.current[action.payload.id].loaded = true
		},
		cancelCurrentView: (state, action) => {
			state.current[action.payload.id] = null
		},
		setInView(state, action) {
			action.payload.changes?.map((change) => {
				state.current[action.payload.id] = set(state.current[action.payload.id] || {}, `data.${change.path}`, change.value)
			})
		}
	}
})

export const { loadPersonalViews, cancelPersonalViews, loadSharedViews, cancelSharedViews, selectView, refreshView, cancelCurrentView, setInView } = viewSlice.actions

export default viewSlice.reducer
