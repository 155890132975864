import { io } from 'socket.io-client'
import * as actions from 'model/saga-actions/engineMiddlewareActions'
import * as socketActions from 'model/saga-actions/socketActions'
import { stopDesignMode } from 'model/store/modelReducer'
import { fromDateUTCToString, fromTimestampToDate } from 'common/utils/dates'
import { clearData, setEngineReady } from 'model/store/modelDataReducer'
import { loadingOff, loadingOn } from 'common/store/globalReducer'
import { t } from 'i18next'
import { downloadFile, showMessage } from 'common/saga-actions/globalActions'
import { loadCalculationSocket } from 'model/saga-actions/modelEngineActions'

const EngineMiddleware = () => {
	let socket = {}
	let notifications = {}

	const onError = (error, store, payload) => {
		if(error === '401') {
			store.dispatch(stopDesignMode({ id: payload.aid }))
			store.dispatch(setEngineReady({ id: payload.aid, isEngineReady: false }))
			store.dispatch(loadCalculationSocket({ tid: payload.tid, teamId: payload.teamId, aid: payload.aid }))
		}
	}

	const onNotification = (store, payload) => {
		store.dispatch(showMessage({ key: `modelEngine#${payload.aid}`, content: payload.msg, messageType: payload.code, isPersistent: false }))
	}

	const onDownload = (store, payload) => {
		store.dispatch(downloadFile({ userId: payload.user_id, signedUrl: payload.signed_url }))
	}

	return (store) => (next) => (action) => {
		switch (action.type) {
			case socketActions.WS_ENGINE_CONNECT:
				if (socket[action.aid] && socket[action.aid].connected) socket[action.aid].disconnect()
				if (notifications[action.aid] && notifications[action.aid].connected) notifications[action.aid].disconnect()
				// connect to the remote host
				const mask = 'yyyy-MM-dd HH:mm:ss'
				const newFixedModelTime = action.modelProps?.advanced?.fixedTime
				const fixedTime = (newFixedModelTime !== undefined && newFixedModelTime !== null) ? fromTimestampToDate(newFixedModelTime).toUTC().toFormat(mask) : null
				socket[action.aid] = io(process.env.REACT_APP_ENGINE_WEBSOCKET_ENDPOINT, {
					path: '/worksheet/',
					reconnectionAttempts: 5,
					auth: { token: action.token },
					query: {
						tenantId: action.tid,
						teamId: action.teamId,
						modelId: action.aid,
						freq: action.modelProps['frequency'],
						startTime: fromTimestampToDate(action.modelProps['startDate']).toUTC().toFormat(mask),
						endTime: fromTimestampToDate(action.modelProps['endDate']).toUTC().toFormat(mask),
						timezone: action.modelProps?.advanced?.timezone,
						fixedTime: fixedTime,
					}
				})
				notifications[action.aid] = io(process.env.REACT_APP_NOTIFICATION_WEBSOCKET_ENDPOINT, {
					path: '/notification/',
					reconnectionAttempts: 5,
					auth: { token: action.token },
					query: {
						modelId: action.aid
					}
				})
				notifications[action.aid].on('notification', (payload) => onNotification(store, payload))
				notifications[action.aid].on('download', (payload) => onDownload(store, payload))

				// websocket handlers
				socket[action.aid].on('connect_error', (error) => onError(error.message, store, action))
				store.dispatch(setEngineReady({ id: action.aid, isEngineReady: true }))
        		break
			case socketActions.WS_ENGINE_DISCONNECT:
				if (socket[action.aid]) {
					if (socket[action.aid].connected) socket[action.aid].disconnect()
					if (notifications[action.aid].connected) notifications[action.aid].disconnect()
					delete socket[action.aid]
					delete notifications[action.aid]
          			store.dispatch(setEngineReady({ id: action.aid, isEngineReady: false }))
				}
				break
			case actions.UPDATE_FORMULA:
				socket[action.modelId].emit('update_formula', { ...action, ...action.updates[action.id] })
				break
			case actions.RECOMPUTE_MODEL:
				socket[action.modelId].emit('recompute', { ...action })
				break
			case actions.CREATE_ROW:
				socket[action.modelId].emit('create_row', { ...action })
				break
			case actions.PUBLISH_ROW:
				socket[action.modelId].emit('publish_row', { ...action })
				break
			case actions.SIMULATION:
				socket[action.modelId].emit('update_simulation', { ...action, entity: 'manager' })
				break
			case actions.CONNECT_TABLE:
				const newContent = {...action, ...action.content}
				delete newContent['content']
				socket[action.modelId].emit('connect_table', { ...newContent })
				break
			case actions.DISCONNECT_TABLE:
				store.dispatch(clearData({ id: action.id, aid: action.modelId }))
				socket[action.modelId].emit('disconnect_table', { ...action })
				break
			case actions.CONNECT_VARIABLE:
				const newVarContent = {...action, ...action.content}
				delete newVarContent['content']
				socket[action.modelId].emit('on_check_connectability', { ...newVarContent, entity: 'manager' })
				break
			case actions.DISCONNECT_VARIABLE:
				store.dispatch(clearData({ id: action.id, aid: action.modelId }))
				socket[action.modelId].emit('disconnect_variable', { ...action })
				break
			case actions.DELETE_ROWS:
				socket[action.modelId].emit('delete_rows', { ...action })
				break
			case actions.UPDATE_DATES:
				const dateMask = 'yyyy-MM-dd HH:mm:ss'
				const newModelProps = { ...action.modelProps }
				const startDate = fromDateUTCToString(action.modelProps['startDate'], dateMask)
				const endDate = fromDateUTCToString(action.modelProps['endDate'], dateMask)
				newModelProps['startDate'] = startDate
				newModelProps['endDate'] = endDate
				newModelProps['timezone'] = newModelProps.advanced.timezone
				const newFixedTime = action.modelProps['advanced']['fixedTime'] !== null ? fromDateUTCToString(action.modelProps['advanced']['fixedTime'], dateMask) : null
				newModelProps['fixedTime'] = newFixedTime
				socket[action.modelId].emit('update_dates', { ...action, modelProps: newModelProps })
				break
			case actions.CREATE_CATEGORY:
				store.dispatch(loadingOn({ key: action.messageKey }))
				socket[action.modelId].emit('create_category', { ...action, entity: 'manager' })
				store.dispatch(loadingOff({ key: action.messageKey }))
				break
			case actions.UPDATE_CATEGORY:
				store.dispatch(loadingOn({ key: action.messageKey }))
				socket[action.modelId].emit('update_category', { ...action, entity: 'manager' })
				store.dispatch(loadingOff({ key: action.messageKey }))
				break
			case actions.REMOVE_CATEGORY:
				socket[action.modelId].emit('remove_category', { ...action, entity: 'manager' })
				break
			case actions.CREATE_RELATIONSHIP:
				store.dispatch(loadingOn({ key: action.messageKey }))
				socket[action.modelId].emit('create_relationship', { ...action, entity: 'manager' })
				store.dispatch(loadingOff({ key: action.messageKey }))
				break
			case actions.UPDATE_RELATIONSHIP:
				store.dispatch(loadingOn({ key: action.messageKey }))
				socket[action.modelId].emit('update_relationship', { ...action, entity: 'manager' })
				store.dispatch(loadingOff({ key: action.messageKey }))
				break
			case actions.REMOVE_RELATIONSHIP:
				socket[action.modelId].emit('remove_relationship', { ...action, entity: 'manager' })
				break
			case actions.UPDATE_ROW_LEVEL:
				socket[action.modelId].emit('update_row_level', { ...action })
				break
			case actions.UPDATE_ROW_AGGR:
				socket[action.modelId].emit('update_row_aggr', { ...action })
				break
			case actions.UPDATE_CELL_OVERRIDES:
				socket[action.modelId].emit('update_cell_overrides', { ...action })
				break
			case actions.EXPORT_VARIABLE:
				socket[action.modelId].emit('export_rows', { ...action })
				break
			default:
				return next(action)
		}
	}
}

export default EngineMiddleware()
