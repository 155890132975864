import { Autocomplete, Checkbox,Dialog,DialogContent,DialogTitle,Divider, MenuItem, TextField } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { DATA_TYPES } from "common/constants/dataTypes"
import TableTypeRefVariable from "table/screens/type/TableTypeRefVariable"
import { useDispatch, useSelector } from "react-redux"
import { loadSelectedTable } from "table/saga-actions/tableActions"
import { cancelSelectedTable } from "table/store/tableReducer"
import { useParams } from "react-router-dom"
import { cloneDeep } from 'lodash'
import { DATE_FORMATS } from "automation/constants/datasources"
import { fromDateToString } from "common/utils/dates"

export default function NewSchema({ form, type, disabled, showVariables, validation, onChange }) {
    const dispatch = useDispatch()
    const { t } = useTranslation(['common', 'automation'])
    let { teamId } = useParams()

    const loadKey = `connectorTable#${form.destinationProps.selectTable}`
    const tid = useSelector((state) => state.auth.tid)
    const table = useSelector((state) => state.table.selected[loadKey])

	const [types, setTypes] = useState([])
    const [showDateFormats, setShowDateFormats] = useState(false)
    const [index, setIndex] = useState(null)

    // #### EFFECTS
    useEffect(() => {
		const lKey = loadKey
		const table = form.destinationProps.selectTable
		if (table) dispatch(loadSelectedTable({ key: lKey, tid, aid: table }))
		return () => dispatch(cancelSelectedTable({ key: lKey }))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loadKey])

    useEffect(() => {
		const newTypes = Object.keys(DATA_TYPES).map((key) => DATA_TYPES[key])
		setTypes(newTypes)
	}, [])

    function onInputValue(value, index) {
        const schema = cloneDeep(form.sourceProps.schema)
		const newSchema = schema[index]
        newSchema['labelType'] = value
		schema[index] = newSchema
		onChange('sourceProps', {...form.sourceProps, schema: schema})
	}

    function onSelect(e, value, index) {
        const schema = cloneDeep(form.sourceProps.schema)
		const newSchema = schema[index]
        newSchema['type'] = value.key
        newSchema['selected'] = true
        newSchema['labelType'] = value.label
        newSchema['label'] = newSchema['attr']
        newSchema['typeProps'] = value.defaultProps
        newSchema['backendType'] = value.backendType;
		schema[index] = newSchema
		onChange('sourceProps', {...form.sourceProps, schema: schema})
        if(value.key === 'date' && type === 'csv') {
            setShowDateFormats(true)
            setIndex(index)
        }
    }

	function onSelectColumn(value, index) {
        const variable = table?.data?.variables[value]
		const schema = form.sourceProps.schema
		const newSchema = schema[index]
		newSchema['label'] = DATA_TYPES[variable?.type]?.label
        newSchema['backendType'] = DATA_TYPES[variable?.type]?.backendType
        newSchema['selected'] = true
        newSchema['id'] = variable?.id
		schema[index] = newSchema
		if(value) onChange('sourceProps', {...form.sourceProps, schema: schema})
        if(variable?.type === 'date' && type === 'csv') {
            setShowDateFormats(true)
            setIndex(index)
        }
	}
    
    function onSelectFormat(value) {
        const schema = cloneDeep(form.sourceProps.schema)
		const newSchema = schema[index]
        newSchema['format'] = value?.py
        schema[index] = newSchema
		onChange('sourceProps', {...form.sourceProps, schema: schema})
        onClose()
    }

    function onRemoveColumn(value, index) {
		const schema = form.sourceProps.schema
		const newSchema = schema[index]
		newSchema['selected'] = value;
		schema[index] = newSchema
		onChange('sourceProps', {...form.sourceProps, schema: schema})
	}

    function onClose() {
        setShowDateFormats(false)
    }

    return (
        <div className="m-2">
            <div className='grid grid-cols-10 gap-3 pb-4'>
                <div/>
                <div className="text-sm font-bold col-span-3">{t("automation:schema.importColumn")}</div>
                <div className="text-sm font-bold col-span-3">{t("automation:schema.internalColumn")}</div>
                <div className="text-sm font-bold col-span-3">{t("automation:schema.type")}</div>
            </div>
            <Divider className="mb-2"/>
            {form.sourceProps.schema.map((field, index) => (
                <div key={index} className='grid grid-cols-10 gap-3 rounded-lg items-center'>
                    <div className="">
                        <Checkbox 
                            className="text-sm"
                            size="small"
                            disabled={disabled}
                            checked={field.selected === undefined ? false : field.selected} 
                            onChange={(event) => onRemoveColumn(event.target.checked, index)} 
                            color="primary" 
                        />
                    </div>
                    
                    <div className="text-sm col-span-3">{field.attr}</div>
                    {showVariables ? (
                        <>
                        <div className="col-span-3 mt-2 mr-4">
                            <TableTypeRefVariable
                                aid={teamId}
                                variables={table?.data?.variables}
                                types={Object.keys(DATA_TYPES)}
                                disabled={disabled}
                                onChange={(value) => onSelectColumn(value, index)}
                                fieldName={t('model:categories.field')}
                                showLabel={false}
                                placeholder={t('automation:schema.selectColumn')}
                            />
                        </div>
                        <div className="text-sm col-span-3">{field.label}</div>
                        </>
                    ) : (
                        <>
                            <div className="text-sm col-span-3">{field.attr}</div>
                            <div className="text-sm col-span-3">
                                <Autocomplete
                                    blurOnSelect={true}
                                    clearOnBlur={false}
                                    options={types}
                                    disabled={disabled}
                                    getOptionLabel={(option) => option.label}
                                    value={null}
                                    inputValue={field.labelType || ''}
                                    onChange={(e, value) => onSelect(e, value, index)}
                                    onInputChange={(e, value) => onInputValue(value, index)}
                                    disablePortal
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" fullWidth size="small" placeholder={t('table:typeSelect.select')} inputProps={{ ...params.inputProps, className: 'text-sm px-2 py-1' }} />
                                    )}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            {React.createElement(option.icon, { className: 'mr-2 text-textGray w-[15px] h-[15px]' })}
                                            <span>{option.label}</span>
                                        </li>
                                    )}
                                    classes={{ root: 'mt-2', listbox: 'max-h-[145px] text-sm' }}
                                    openOnFocus
                                />
                            </div>
                        </>
                    )}
                </div>
            ))}
            <Dialog
				open={showDateFormats}
				maxWidth="xs"
				PaperProps={{
					style: {
						minHeight: '15%',
						maxHeight: '15%',
                        minWidth: '30%',
                        maxWidth: '30%'
					}
				}}
			>
                <DialogTitle className="text-sm text-center">
					<span>{t(`automation:create.dateFormat`)}</span>
				</DialogTitle>
                <DialogContent>
                    <TextField 
                        id="format" 
                        select 
                        InputProps={{
                            className: "text-sm"
                        }}
                        InputLabelProps={{
                            className: "text-sm"
                        }}
                        variant="outlined" 
                        className='text-sm grid-50' 
                        size="small" 
                        fullWidth
                        value={''}
                        onChange={event => onSelectFormat(event.target.value)}
                    >
                        {DATE_FORMATS.map((column, i) => (
                            <MenuItem key={i} value={column}>
                                {column['js']}      ({fromDateToString(new Date(), column['js'])})
                            </MenuItem>
                        ))}
                        
                    </TextField>
                </DialogContent>
            </Dialog>
            
        </div>
    )
}