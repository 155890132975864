// #### DIRECT SELECTORS
// Model
export const selectCharts = (state, aid) => state.chart.charts[aid]?.result
export const selectChart = (state, aid, chartId) => state.chart.charts[aid]?.result[chartId]
export const selectChartGeneralProps = (state, aid, chartId) => state.chart.charts[aid]?.result[chartId]?.generalProps
export const selectChartVariables = (state, aid, chartId) => state.chart.charts[aid]?.result[chartId]?.variables
export const selectChartsByVar = (state, aid, vid) => (state.chart.chartsByVar[aid] ? state.chart.chartsByVar[aid][vid] : null)
export const selectChartStartDate = (state, aid, chartId) => (state.chart.charts[aid]?.result[chartId]?.generalProps?.hasStartDate ? state.chart.charts[aid]?.result[chartId]?.generalProps?.startDate : null)
export const selectChartEndDate = (state, aid, chartId) => (state.chart.charts[aid]?.result[chartId]?.generalProps?.hasEndDate ? state.chart.charts[aid]?.result[chartId]?.generalProps?.endDate : null)
export const selectChartFrequency = (state, aid, chartId) => (state.chart.charts[aid]?.result[chartId]?.generalProps?.hasFrequency ? state.chart.charts[aid]?.result[chartId]?.generalProps?.frequency : null)
