import React, { useContext } from 'react'
import { SelectionRect } from 'common/components/grid/SelectionRect'
import { StickyContext } from 'common/components/grid/StickyContext'

export const InnerContainer = React.forwardRef(({ children, ...rest }, ref) => {
	const { CellStickyRow, CellStickyColumn, rowHeight, columnWidths, columnRights } = useContext(StickyContext)

	if (!children || children.length === 0) return null

	const firstChild = children[0].props
	const lastChild = children[children.length - 1].props
	const startColumn = firstChild.columnIndex
	const endColumn = lastChild.columnIndex
	const startRow = firstChild.rowIndex
	const endRow = lastChild.rowIndex
	const data = firstChild.data

	var stickyRow = []
	for (let i = startColumn; i <= endColumn; i++)
		stickyRow.push(
			React.createElement(CellStickyRow, {
				key: `${0}:${i}`,
				columnIndex: i,
				rowIndex: 0,
				data: data,
				style: {
					position: 'absolute',
					top: 0,
					left: columnRights[i - 1] || 0,
					width: columnWidths[i],
					height: rowHeight
				}
			})
		)

	var stickyCol = []
	for (let i = startRow; i <= endRow; i++)
		stickyCol.push(
			React.createElement(CellStickyColumn, {
				key: `${i}:${0}`,
				columnIndex: 0,
				rowIndex: i,
				data: data,
				style: {
					position: 'absolute',
					left: 0,
					top: (i - 1) * rowHeight, // we subtract 1 to account for the header row, which is not included in the sticky column
					width: columnWidths[0],
					height: rowHeight
				}
			})
		)

	return (
		<div ref={ref} {...rest}>
			<div style={{ position: 'sticky', height: rowHeight, zIndex: 4, top: 0 }}>
				{stickyRow}
				<SelectionRect isStickyRow={true} isStickyEdge={true} />
			</div>
			<div style={{ position: 'sticky', width: columnWidths[0], zIndex: 4, left: 0 }}>
				{stickyCol}
				<SelectionRect isStickyCol={true} isStickyEdge={true} />
			</div>
			{children}
			<SelectionRect />
		</div>
	)
})
