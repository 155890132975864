import clsx from 'clsx'
import { memo, useEffect, useRef, useCallback, useState, useLayoutEffect } from 'react'
import { TickIcon } from 'common/icons/index'

export default memo(function CreateCheckCell({
	item,
	setItem,
	focus = false,
	cancel = false,
	isUppercase = false,
	typeProps,
	config: { alignRight = false, parseUserInput, formatBlurredInput, parsePastedValue }
}) {
	const itemRef = useRef(item)
	itemRef.current = item

	const [currentFocus, setCurrentFocus] = useState({ is: false, cancel: false })

	useLayoutEffect(() => {
		setCurrentFocus({ is: focus, cancel: cancel })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [focus])

	// Toggle checkbox
	const toggleCheckbox = useCallback(
		(e) => {
			const newValue = itemRef.current >= 1 ? 0 : 1
			setItem(newValue)
			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		[setItem]
	)

	// If enter is pressed, toggle the checkbox and let the event propagate to the grid
	const onKeyDown = useCallback((e) => {
		if (e.key === 'Enter') toggleCheckbox()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// If cell is focused, attach an onKeyDown event to the cell
	useEffect(() => {
		if (currentFocus.is) document.addEventListener('keydown', onKeyDown, true)
		return () => document.removeEventListener('keydown', onKeyDown, true)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentFocus])

	// ###########################
	// #### RENDER
	// ###########################
	return (
		<div
			className={clsx('flex-1 flex items-center justify-center', focus && 'cursor-pointer')}
			onClick={currentFocus.is ? toggleCheckbox : undefined}
			style={{ pointerEvents: currentFocus.is ? 'auto' : 'none' }} // this prevents focus to activate/deactivate checkbox
		>
			<span className={clsx('w-[20px] h-[20px] flex items-center justify-center', focus && 'rounded border-2 border-borderGray')}>
				{item >= 1 && <TickIcon className="w-[15px] h-[15px] text-green-500" />}
			</span>
		</div>
	)
})
