import React, { useEffect } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { loadTeamUsers, cancelTeamUsers, loadTeamCategories, cancelTeamCategories, loadTeamRelationship, cancelTeamRelationship } from 'common/saga-actions/teamActions'
import { loadAssets, cancelAssets } from 'common/saga-actions/assetActions'
import { setNewTeamId, setTeamId } from 'common/store/teamReducer'
import { cancelCustomer, loadCustomer } from 'common/saga-actions/billingActions'
import { loadSpaces, cancelSpaces } from 'common/saga-actions/spaceActions'
import { useTeamPermits } from 'common/hooks/useTeamPermits'
import * as teamSel from 'common/store/teamSelector'
import { setSpaceId } from 'common/store/spaceReducer'

export default function RootPathLoader() {
	const dispatch = useDispatch()
	let { teamId, sid } = useParams()
	const navigate = useNavigate()
	const { canAdmin } = useTeamPermits()

	// Auth
	const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)
	const tid = useSelector((state) => state.auth.tid)
	const uid = useSelector((state) => state.auth.uid)
	// Teams
	const storeTeamId = useSelector((state) => state.team.teamId)
	const team = useSelector((state) => state.team.team)
	const teamsLoaded = useSelector((state) => state.team.teams?.loaded)
	const newTeamId = useSelector((state) => teamSel.selectNewTeamId(state))
	// Asset
	const aid = useSelector((state) => state.asset.aid)
	const assetTeamId = useSelector((state) => state.asset.asset[aid]?.data?.teamId)
	const assetSpaceId = useSelector((state) => state.asset.asset[aid]?.data?.sid)
	// Space
	const space = useSelector((state) => (sid ? state.space.spaces?.result[sid] : null))
	const spaceLoaded = useSelector((state) => state.space.spaces?.loaded)

	// Set current team id
	useEffect(() => {
		if (isLoggedIn && assetTeamId) dispatch(setTeamId(assetTeamId))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoggedIn, assetTeamId])

	useEffect(() => {
		if (isLoggedIn && teamId) dispatch(setTeamId(teamId))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoggedIn, teamId])

	// Set current space id
	useEffect(() => {
		if (isLoggedIn && assetSpaceId) dispatch(setSpaceId(assetSpaceId))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoggedIn, assetSpaceId])

	useEffect(() => {
		if (isLoggedIn && sid) dispatch(setSpaceId(sid))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoggedIn, sid])

	// Load team data
	useEffect(() => {
		const lIsLoggedIn = isLoggedIn
		const lStoreTeamId = storeTeamId

		if (lIsLoggedIn && lStoreTeamId) {
			dispatch(loadTeamUsers({ tid, teamId: lStoreTeamId }))
			dispatch(loadTeamCategories({ tid, teamId: lStoreTeamId }))
			dispatch(loadTeamRelationship({ tid, teamId: lStoreTeamId }))
			dispatch(loadSpaces({ tid, uid, teamId: lStoreTeamId }))
			dispatch(loadAssets({ tid, uid, teamId: lStoreTeamId }))
		}
		return () => {
			if (lIsLoggedIn && lStoreTeamId) {
				dispatch(cancelTeamUsers())
				dispatch(cancelTeamCategories())
				dispatch(cancelTeamRelationship())
				dispatch(cancelSpaces())
				dispatch(cancelAssets())
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoggedIn, storeTeamId])

	// Catch when a team is created to redirect the user
	useEffect(() => {
		const lTeamId = newTeamId
		if (lTeamId) {
			dispatch(setNewTeamId(null))
			navigate(`/team/${lTeamId}`)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [newTeamId])

	// Catch when a team is deleted to redirect the user
	useEffect(() => {
		if (!team && storeTeamId != null && teamId === storeTeamId && teamsLoaded) {
			navigate('/teams', { replace: true })
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [team, storeTeamId, teamsLoaded])

	// Catch when a space is deleted to redirect the user
	useEffect(() => {
		if (sid && !space && spaceLoaded) {
			if (storeTeamId) navigate(`/team/${storeTeamId}`, { replace: true })
			else navigate('/teams', { replace: true })
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sid, space, spaceLoaded])

	// Load customer
	useEffect(() => {
		const lTid = tid
		const lTeamId = storeTeamId
		// const lCid = cid
		const lAdmin = canAdmin
		if (lTid && lTeamId && lAdmin) dispatch(loadCustomer({ tid, cid: lTeamId }))
		return () => {
			if (lTid && lTeamId && lAdmin) dispatch(cancelCustomer())
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [storeTeamId, canAdmin])

	return <Outlet />
}
