import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Button, IconButton, Popover } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useDispatch, useSelector } from 'react-redux'
import TeamIcon from '@mui/icons-material/GroupsOutlined'
import PersonIcon from '@mui/icons-material/PermIdentityOutlined'
import AssetViewsList from 'common/screens/asset/AssetViewsList'
import { createPersonalView, createSharedView, deletePersonalView, deleteSharedView, updatePersonalView, updateSharedView } from 'common/saga-actions/viewActions'
import { selectView } from 'common/store/viewReducer'
import { useAssetPermits } from 'common/hooks/useAssetPermits'
import * as viewSel from 'common/store/viewSelector'
import { PRODUCTS } from 'common/constants/products'
import { MODEL_NEW_VIEW } from 'model/constants/modelParameters'
import { TABLE_NEW_VIEW } from 'table/constants/tableParameters'

export default function AssetViews({ aid, product }) {
	const { t } = useTranslation(['common'])
	const dispatch = useDispatch()
	const { canCreate, canEdit } = useAssetPermits(aid)

	// #### REDUX
	const tid = useSelector((state) => state.auth.tid)
	const uid = useSelector((state) => state.auth.uid)
	const shared = useSelector((state) => viewSel.selectSharedViews(state, aid))
	const personal = useSelector((state) => viewSel.selectPersonalViews(state, aid))
	const currentName = useSelector((state) => viewSel.selectViewName(state, aid))
	const currentType = useSelector((state) => viewSel.selectViewType(state, aid))

	// #### STATE
	const [isPanelOpen, setIsPanelOpen] = useState(null)

	// #### FUNCTIONS
	function onSave(type, content) {
		const defaultContent = product === PRODUCTS.model.key ? MODEL_NEW_VIEW : product === PRODUCTS.table.key ? TABLE_NEW_VIEW : {}
		const newContent = { ...defaultContent, ...content }
		if (type === 'shared') dispatch(createSharedView({ tid, aid, content: newContent }))
		else if (type === 'personal') dispatch(createPersonalView({ tid, uid, aid, content: newContent }))
	}

	function onDelete(type, vid) {
		if (type === 'shared') dispatch(deleteSharedView({ tid, aid, vid }))
		else if (type === 'personal') dispatch(deletePersonalView({ tid, uid, aid, vid }))
	}

	function onUpdate(type, vid, content) {
		if (type === 'shared') dispatch(updateSharedView({ tid, aid, vid, content }))
		else if (type === 'personal') dispatch(updatePersonalView({ tid, uid, aid, vid, content }))
	}

	function onSelect(type, vid) {
		dispatch(selectView({ id: aid, vid, type }))
		setIsPanelOpen(null)
	}

	return (
		<div className="mb-1 mr-2">
			<Button size="small" variant="contained" endIcon={<ExpandMoreIcon />} className="buttonContainedGray h-[25px] normal-case" onClick={(e) => setIsPanelOpen(e.currentTarget)}>
				{currentType === 'shared' ? <TeamIcon className="mr-2 text-textGray w-[20px] h-[20px]" /> : <PersonIcon className="mr-2 text-textGray w-[20px] h-[20px]" />}
				{currentName} {t('common:views.view')}
			</Button>
			<Popover
				open={Boolean(isPanelOpen)}
				anchorEl={isPanelOpen}
				onClose={() => setIsPanelOpen(null)}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
				classes={{ paper: 'flex w-[400px] flex shadow-md' }}
			>
				<div className="flex-1 flex flex-col pb-4">
					<div className="flex flex-row justify-between items-center border-b border-b-borderGray p-2 pl-4">
						<span className="text-md font-medium text-textGray">{t('common:views.views')}</span>
						<IconButton size="small" className="text-textGray" onClick={() => setIsPanelOpen(null)}>
							<CloseIcon fontSize="small" />
						</IconButton>
					</div>

					<div className="flex-1 px-4 flex flex-col mt-4">
						<AssetViewsList
							aid={aid}
							views={shared?.result}
							title={t('common:views.collaborativeViews')}
							description={t('common:views.visibleAll')}
							icon={TeamIcon}
							onCreate={(content) => onSave('shared', content)}
							onUpdate={(vid, content) => onUpdate('shared', vid, content)}
							onDuplicate={onSave}
							onDelete={(vid) => onDelete('shared', vid)}
							onSelect={(vid, content) => onSelect('shared', vid, content)}
							keepOne={true}
							isDisabled={!canEdit}
							isDuplicateSharedDisabled={!canEdit}
						/>

						<div className="border-b border-b-borderGray my-2"></div>

						<AssetViewsList
							aid={aid}
							views={personal?.result}
							title={t('common:views.personalViews')}
							description={t('common:views.visibleYou')}
							icon={PersonIcon}
							onCreate={(content) => onSave('personal', content)}
							onUpdate={(vid, content) => onUpdate('personal', vid, content)}
							onDuplicate={onSave}
							onDelete={(vid) => onDelete('personal', vid)}
							onSelect={(vid, content) => onSelect('personal', vid, content)}
							isDisabled={!canEdit}
							isDuplicateSharedDisabled={!canEdit}
						/>
					</div>
				</div>
			</Popover>
		</div>
	)
}
