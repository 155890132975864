import { TextField, Button, MenuItem, CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { DATA_TYPES } from 'common/constants/dataTypes'
import TableTypeRefTable from 'table/screens/type/TableTypeRefTable'
import TableTypeRefVariable from 'table/screens/type/TableTypeRefVariable'
import { useState, useEffect } from 'react'
import { validateNotEmpty } from 'common/utils/validate'
import { useDispatch, useSelector } from 'react-redux'
import { cancelSelectedTable } from 'table/store/tableReducer'
import { loadSelectedTable } from 'table/saga-actions/tableActions'
import clsx from 'clsx'
import CloseIcon from '@mui/icons-material/Close'
import Alert from 'common/components/alert/Alert'
import ErrorIcon from '@mui/icons-material/ErrorOutlineOutlined'
import { createRelationship, deleteRelationship } from 'model/saga-actions/modelCategoryActions'
import { LoadingButton } from '@mui/lab'

export default function ModelRelationship({ relKey, aid, onDelete: parentOnDelete }) {
	const dispatch = useDispatch()
	const { t } = useTranslation(['common', 'model'])
	const isNew = relKey === 'new'

	// #### REDUX
	const tid = useSelector((state) => state.auth.tid)
	const teamId = useSelector((state) => state.asset.asset[aid]?.data?.teamId)
	const globalRelationships = useSelector((state) => state.team.teamRelationships?.result)
	const relationship = globalRelationships && relKey ? globalRelationships[relKey] : null
	const globalCategories = useSelector((state) => state.team.teamCategories?.result)

	// #### DEFAULT VALUES
	const defaultForm = {
		selectTable: '',
		selectVariable: '',
		selectCategory: '',
		mapping: []
	}

	const defaultValidation = {
		selectTable: { valid: true, error: '' },
		selectVariable: { valid: true, error: '' },
		selectCategory: { valid: true, error: '' },
		mapping: { valid: true, error: '' }
	}

	// #### STATE
	const [form, setForm] = useState(defaultForm)
	const [validation, setValidation] = useState(defaultValidation)
	const loadKey = `modelRelationship#${aid}#${form.selectTable}`
	const messageKey = `modelRelationship#${aid}#${relKey}`
	const table = useSelector((state) => state.table.selected[loadKey])
	const isLoaded = useSelector((state) => state.table.selected[loadKey]?.loaded) || false
	const variables = useSelector((state) => state.table.selected[loadKey]?.data?.variables)
	const isCreating = useSelector((state) => state.global.loading[messageKey])

	// #### EFFECTS
	useEffect(() => {
		const lKey = loadKey
		const table = form.selectTable
		if (table) dispatch(loadSelectedTable({ key: lKey, tid, aid: table }))
		return () => dispatch(cancelSelectedTable({ key: lKey }))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loadKey])

	// #### EFFECTS
	// Load item
	useEffect(() => {
		if (relationship) setForm({ selectTable: relationship.selectTable, mapping: relationship.mapping })
		else setForm(defaultForm)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [relationship])

	function onChange(key, value) {
		let relatedChanges = {}
		if (key === 'selectTable') relatedChanges = { selectVariable: null, selectCategory: null, mapping: [] }
		setForm({ ...form, [key]: value, ...relatedChanges })
	}

	// #### FUNCTIONS
	function onUpdate() {
		let tableValid = validateNotEmpty(form.selectTable)
		if (tableValid?.valid) tableValid = table?.notFound ? { valid: false, error: 'error.required' } : { valid: true, error: '' }
		let mappingValid = form.mapping?.length >= 2 ? { valid: true, error: '' } : { valid: false, error: 'model:relationships.errorNumCategories' }
		setValidation({ ...validation, selectTable: tableValid, mapping: mappingValid })
		if (tableValid?.valid && mappingValid?.valid) {
			const content = { selectTable: form.selectTable, mapping: form.mapping || [] }
			dispatch(createRelationship({ tid, aid, relationId: relKey, content, isNew }))
			if (isNew) setForm(defaultForm)
		}
	}

	function onDelete() {
		dispatch(deleteRelationship({ tid, aid, relationId: relKey, selectTable: table?.id }))
		parentOnDelete()
	}

	function onAddMapping() {
		let varValid = validateNotEmpty(form.selectVariable)
		if (varValid.valid) varValid = form.mapping.findIndex((el) => el.variable === form.selectVariable) >= 0 ? { valid: false, error: t('common:error.used') } : varValid
		let catValid = validateNotEmpty(form.selectCategory)
		if (catValid.valid) catValid = form.mapping.findIndex((el) => el.category === form.selectCategory) >= 0 ? { valid: false, error: 'common:error.used' } : catValid
		setValidation({ ...validation, selectVariable: varValid, selectCategory: catValid })
		if (varValid.valid && catValid.valid) {
			var newMapping = form.mapping ? [...form.mapping] : []
			newMapping.push({ variable: form.selectVariable, category: form.selectCategory })
			setForm({ ...form, mapping: newMapping, selectVariable: '', selectCategory: '' })
		}
	}

	function onDeleteMapping(index) {
		var newMapping = [...form.mapping]
		newMapping.splice(index, 1)
		setForm({ ...form, mapping: newMapping })
	}

	return (
		<>
			<div className="mb-3 text-sm font-medium text-textGray">{t('model:relationships.tableDesc')}</div>
			<TableTypeRefTable
				loadKey={loadKey}
				teamId={teamId}
				onChange={(value) => onChange('selectTable', value)}
				value={form.selectTable}
				validation={validation.selectTable}
				fieldName={t('model:relationships.table')}
				showLabel={true}
			/>
			<div className="mt-3 mb-3 text-sm font-medium text-textGray">{t('model:relationships.fieldDesc')}</div>
			<div className="flex flex-row items-center mt-3">
				<div className="flex-1 pr-1">
					<TableTypeRefVariable
						aid={aid}
						variables={variables}
						types={Object.keys(DATA_TYPES)}
						onChange={(value) => onChange('selectVariable', value)}
						value={form.selectVariable}
						validation={validation.selectVariable}
						fieldName={t('model:relationships.field')}
						showLabel={true}
					/>
				</div>
				<div className="flex-1 pl-1">
					<TextField
						fullWidth
						select
						label={t('model:relationships.category')}
						variant="outlined"
						size="small"
						margin="none"
						value={form.selectCategory || ''}
						onChange={(e) => onChange('selectCategory', e.target.value)}
						inputProps={{ className: 'text-sm' }}
						InputLabelProps={{ className: 'text-sm' }}
						error={validation?.selectCategory && !validation?.selectCategory?.valid}
						helperText={!validation.selectCategory?.valid && t(validation.selectCategory.error, { field: t('model:relationships.category') })}
						sx={{ placeholder: 'text-sm' }}
					>
						{globalCategories &&
							Object.entries(globalCategories)?.map((category) => (
								<MenuItem key={`selectCategory#${category[0]}`} value={category[0]} className="text-sm">
									{category[1].name}
								</MenuItem>
							))}
					</TextField>
				</div>
				<Button size="small" className="buttonText ml-2 w-[40px] min-w-0" onClick={onAddMapping}>
					{t('common:buttons.add')}
				</Button>
			</div>
			{!validation.mapping?.valid && <div className="mt-3 text-red-500 text-xs">{t(validation.mapping?.error)}</div>}
			{isLoaded &&
				form.mapping?.map((item, index) => {
					const category = globalCategories && globalCategories[item.category]
					const variable = (variables && variables[item.variable]) || { isError: true, label: t('common:error.value_deleted') }
					return (
						<div key={`mapping#${index}`} className={clsx('flex flex-row items-center mt-2 bg-bgGray p-2 rounded')}>
							<div className={clsx('flex-1 flex flex-row items-center truncate text-sm', variable?.isError && 'text-red-500')}>
								{variable?.isError && <ErrorIcon fontSize="small" className="text-red-500 mr-2" />}
								{variable?.label}
							</div>
							<div className="flex-1 truncate text-sm">{category?.name}</div>
							<div className="w-[40px] text-center">
								<CloseIcon fontSize="small" className="text-textGray ml-2 cursor-pointer w-[15px] h-[15px]" onClick={() => onDeleteMapping(index)} />
							</div>
						</div>
					)
				})}

			<div className="flex flex-row items-center mt-3">
				{!isNew && (
					<Button onClick={onDelete} fullWidth size="small" variant="outlined" className="buttonOutlinedGray mr-4">
						{t('common:buttons.delete')}
					</Button>
				)}
				<LoadingButton loading={isCreating || false} fullWidth size="small" className="buttonContainedContrast" onClick={onUpdate} loadingIndicator={<CircularProgress size={20} />}>
					{isNew ? t('common:buttons.add') : t('common:buttons.update')}
				</LoadingButton>
			</div>
			<Alert messageKey={messageKey} className="w-full mt-3" />
		</>
	)
}
