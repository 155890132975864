import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setTheme } from 'common/store/globalReducer'
import { DEFAULT_THEME, THEME_PREFIX } from 'common/constants/themes'

export const useApplyTheme = () => {
	const dispatch = useDispatch()

	const theme = useSelector((state) => state.global.theme)
	const spaceTheme = useSelector((state) => state.space.space?.theme)

	// Update theme classes
	useEffect(() => {
		for (let i = 0; i < document.body.classList.length; i++) if (document.body.classList[i].startsWith(THEME_PREFIX)) document.body.classList.remove(document.body.classList[i])
		if (theme) document.body.classList.add(theme)
	}, [theme])

	// Get theme from loaded team
	useEffect(() => {
		if (spaceTheme) dispatch(setTheme({ theme: spaceTheme }))
		else dispatch(setTheme({ theme: DEFAULT_THEME }))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [spaceTheme])

	return null
}
