import { isEqual } from 'lodash'

export function updateDiff(state, update, keysToSkip) {
	const oldKeys = state ? Object.keys(state) : []
	const newKeys = update ? Object.keys(update) : []
	var merged = {}
	newKeys.forEach((key) => {
		if (keysToSkip?.includes(key)) return
		const prev = state[key]
		const next = update[key]
		if (!isEqual(prev, next)) state[key] = next
		merged[key] = true
	})
	oldKeys.forEach((key) => {
		if (keysToSkip?.includes(key)) return
		if (!merged[key]) delete state[key]
	})
}
