import { backend } from 'common/config/backend'
import { storage } from 'common/config/firebase'
import { ref as storageRef, getDownloadURL, uploadBytes, deleteObject } from 'firebase/storage'
import store from 'common/store/store'
import { fileTransmitSuccess } from 'common/saga-actions/communityActions'
import { throwAPIError } from 'common/config/errors'

const FILE_NAME = 'communityApi'

export async function createCommunityAsset(token, tid, uid, teamId, content) {
	const params = { tenantId: tid, userId: uid, teamId, content }
	const headers = { headers: { Authorization: 'Bearer ' + token } }
	return backend
		.post('/templates', params, headers)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, createCommunityAsset.name)
			return { data: resp.data?.data }
		})
		.catch((error) => {
			throwAPIError(error, error.response?.status, FILE_NAME, createCommunityAsset.name)
		})
}

export async function updateCommunityAsset(token, tid, uid, teamId, assetId, content) {
	const params = { tenantId: tid, userId: uid, teamId, templateId: assetId, content }
	const headers = { headers: { Authorization: 'Bearer ' + token } }
	return backend
		.patch('/templates', params, headers)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, updateCommunityAsset.name)
			return { data: resp.data?.data }
		})
		.catch((error) => {
			throwAPIError(error, error.response?.status, FILE_NAME, updateCommunityAsset.name)
		})
}

export async function deleteCommunityAsset(token, tid, teamId, aid) {
	const params = {
		data: { tenantId: tid, teamId, templateId: aid },
		headers: { Authorization: 'Bearer ' + token }
	}
	return backend
		.delete('/templates', params)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, deleteCommunityAsset.name)
			return { data: resp.data?.data }
		})
		.catch((error) => {
			throwAPIError(error, error.response?.status, FILE_NAME, deleteCommunityAsset.name)
		})
}

export async function uploadFile(tid, teamId, aid, uid, file) {
	const contentType = file.type
	const fileId = file.id
	const filePath = `${tid}/community/${teamId}/${aid}/${fileId}`
	const blob = file.blob
	const metadata = { contentType, customMetadata: { uid } }
	const fileRef = storageRef(storage, filePath)
	return uploadBytes(fileRef, blob, metadata)
		.then((snapshot) => getDownloadURL(snapshot.ref).then((downloadUrl) => store.dispatch(fileTransmitSuccess({ tid, aid, fileId, downloadUrl }))))
		.catch((error) => throwAPIError(error, null, FILE_NAME, uploadFile.name))
}

export async function deleteFile(tid, teamId, aid, fileId, throwError = true) {
	const filePath = `${tid}/community/${teamId}/${aid}/${fileId}`
	const fileRef = storageRef(storage, filePath)
	return deleteObject(fileRef)
		.then(() => {})
		.catch((error) => {
			if (throwError) throwAPIError(error, null, FILE_NAME, deleteFile.name)
		})
}

export async function copyCommunityAsset(token, tenantId, teamId, userId, templateId, spaceId) {
	const params = { tenantId, teamId, userId, templateId, spaceId }
	const headers = { headers: { Authorization: 'Bearer ' + token } }
	return backend
		.post('/templates/use', params, headers)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, copyCommunityAsset.name)
			return { data: resp.data?.data }
		})
		.catch((error) => {
			throwAPIError(error, error.response?.status, FILE_NAME, copyCommunityAsset.name)
		})
}
