import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import useNavigate from 'common/hooks/useNavigate'
import { COMMUNITY } from 'common/constants/products'
import { Avatar, CircularProgress, MenuItem, TextField, IconButton, Menu, ToggleButtonGroup, ToggleButton } from '@mui/material'
import useFormatter from 'common/hooks/useFormatter'
import { sortArrayOfObjects, filterArrayOfObjects } from 'common/utils/arrays'
import MoreIcon from '@mui/icons-material/MoreVert'
import ActionConfirm from 'common/components/ActionConfirm'
import GridViewIcon from '@mui/icons-material/GridView'
import ListViewIcon from '@mui/icons-material/MenuOutlined'
import ContextMenu from 'common/components/ContextMenu'
import { useTeamPermits } from 'common/hooks/useTeamPermits'
import { deleteCommunityAsset } from 'common/saga-actions/communityActions'
import { useParams } from 'react-router-dom'

export default function CommunityAssets() {
	const { t } = useTranslation('common')
	const dispatch = useDispatch()
	const { printTimestamp } = useFormatter()
	const { navigate } = useNavigate()
	let { teamId } = useParams()
	const { canCreate } = useTeamPermits()

	// #### REDUX
	const tid = useSelector((state) => state.auth.tid)
	const communityAssets = useSelector((state) => state.community.assets?.result)
	const teamUsers = useSelector((state) => state.team.teamUsers?.result)
	const isDeleting = useSelector((state) => state.global.loading?.deleteCommunityAsset)
	const isUpdating = useSelector((state) => state.global.loading?.updateCommunityAsset)

	// #### STATE
	const [filter, setFilter] = useState('all')
	const [sort, setSort] = useState({ select: 'updatedDesc', by: 'updatedAt', order: 'desc' })
	const [view, setView] = useState('grid')
	const [assetMenu, setAssetMenu] = useState({ anchor: null, aid: null })
	const [contextMenu, setContextMenu] = useState(null)
	const [isDeleteOpen, setIsDeleteOpen] = useState(null)

	// #### FUNCTIONS
	function onNavigate(id, type) {
		navigate(`${type}/${id}`)
	}

	function onSort(value) {
		if (value === 'nameAsc') setSort({ select: value, by: 'name', order: 'asc' })
		else if (value === 'nameDesc') setSort({ select: value, by: 'name', order: 'desc' })
		else if (value === 'updatedAsc') setSort({ select: value, by: 'updatedAt', order: 'asc' })
		else if (value === 'updatedDesc') setSort({ select: value, by: 'updatedAt', order: 'desc' })
	}

	function onOpenAssetMenu(e, aid, asset, isRightClick = false) {
		if (!canCreate) return
		if (e.preventDefault) e.preventDefault()
		if (e.stopPropagation) e.stopPropagation()
		var items = [{ name: t('common:buttons.delete'), action: () => onDeleteOpen(aid) }]
		const x = isRightClick ? e.clientX : e.currentTarget.getBoundingClientRect().left
		const y = isRightClick ? e.clientY : e.currentTarget.getBoundingClientRect().bottom
		setContextMenu({ x, y, items })
	}

	function onDeleteOpen(aid) {
		setIsDeleteOpen(aid)
		setAssetMenu(null)
	}

	function onDeleteAsset() {
		dispatch(deleteCommunityAsset({ tid, teamId, aid: isDeleteOpen }))
		setIsDeleteOpen(null)
	}

	return (
		<>
			{/* Filter and sort assets */}
			<div className="m-4 flex flex-row items-center justify-between">
				<div className="flex flex-row items-center">
					<span className="text-sm text-textGray font-light">{t('common:assets.filter')}:&nbsp;&nbsp;</span>
					<TextField size="small" select variant="standard" value={filter} InputProps={{ disableUnderline: true, classes: { input: 'p-0 text-sm' } }} onChange={(e) => setFilter(e.target.value)}>
						<MenuItem value="all" className="text-sm">
							{t('common:assets.all')}
						</MenuItem>
						{Object.values(COMMUNITY).map((product) => (
							<MenuItem key={`filter#${product.key}`} value={product.key} className="text-sm">
								{t(product.name)}
							</MenuItem>
						))}
					</TextField>
				</div>
				<div className="flex flex-row items-center">
					<span className="text-sm text-textGray font-light">{t('common:assets.sort')}:&nbsp;&nbsp;</span>
					<TextField size="small" select variant="standard" value={sort.select} InputProps={{ disableUnderline: true, classes: { input: 'p-0 text-sm' } }} onChange={(e) => onSort(e.target.value)}>
						<MenuItem value="nameAsc" className="text-sm">
							{t('common:sort.nameAsc')}
						</MenuItem>
						<MenuItem value="nameDesc" className="text-sm">
							{t('common:sort.nameDesc')}
						</MenuItem>
						<MenuItem value="updatedAsc" className="text-sm">
							{t('common:sort.updatedAsc')}
						</MenuItem>
						<MenuItem value="updatedDesc" className="text-sm">
							{t('common:sort.updatedDesc')}
						</MenuItem>
					</TextField>
					<ToggleButtonGroup
						size="small"
						exclusive
						value={view}
						className="ml-4"
						onChange={(e, value) => {
							if (value != null) setView(value)
						}}
					>
						<ToggleButton value="grid">
							<GridViewIcon className="text-sm" />
						</ToggleButton>
						<ToggleButton value="list">
							<ListViewIcon className="text-sm" />
						</ToggleButton>
					</ToggleButtonGroup>
				</div>
			</div>
			{/* List assets */}
			<div className="m-4 mt-4 grid gap-[32px] grid-cols-cards">
				{communityAssets &&
					sortArrayOfObjects(
						filterArrayOfObjects(Object.entries(communityAssets), filter === 'all' ? '' : filter, (asset) => asset[1].type || ''),
						(asset) => asset[1][sort.by] || '',
						sort.order === 'asc' ? true : false,
						sort.by === 'name' ? true : false
					).map((entry) => {
						const aid = entry[0]
						const asset = entry[1]
						const product = COMMUNITY[asset.type]
						const updated = asset.updatedAt ? printTimestamp(asset.updatedAt) : null
						const updatedBy = asset.updatedBy && teamUsers && teamUsers[asset.updatedBy]
						const updatedByStr = updatedBy ? `${updatedBy.name} ${updatedBy.surname}` : null
						const isLoading = (isDeleting && isDeleting[aid]) || (isUpdating && isUpdating[aid]) || false
						const imageId = asset.images?.length > 0 ? asset.images[0] : null
						const imageURL = (imageId && asset.imagesURL && asset.imagesURL[imageId]) || null
						if (view === 'grid')
							return (
								<div key={aid} className="paper cursor-pointer hover:bg-bgGray" onClick={() => onNavigate(aid, asset.type)} onContextMenu={(e) => onOpenAssetMenu(e, aid, asset, true)}>
									<div className="w-full aspect-screenshot bg-bgGray overflow-hidden">
										{imageURL && <img src={imageURL} loading="lazy" alt={t('common:assets.screenshot')} className="w-full" onError={(e) => (e.target.style.display = 'none')} />}
									</div>
									<div className="flex flex-row items-center justify-between px-4 py-2">
										<div className="flex flex-row items-center">
											<Avatar className={`h-[30px] w-[30px] mr-4 ${product.theme} bg-[var(--bar-bg)]`} variant="rounded">
												{React.createElement(product.icon, { className: 'text-[20px]' })}
											</Avatar>
											<div>
												<div className="text-sm font-medium truncate">{asset.name}</div>
												<div className="text-xs text-textGray leading-none truncate">{updated && t('common:assets.updated', { date: updated })}</div>
											</div>
										</div>
										{canCreate && isLoading ? (
											<CircularProgress size={20} className="text-textGray mr-2" />
										) : (
											canCreate && (
												<IconButton size="small" onClick={(e) => onOpenAssetMenu(e, aid, asset)}>
													<MoreIcon className="text-xl" />
												</IconButton>
											)
										)}
									</div>
								</div>
							)
						else
							return (
								<div key={aid} className="col-span-full paper cursor-pointer hover:bg-bgGray p-4 flex flex-row items-center justify-between " onClick={() => onNavigate(aid, asset.type)}>
									<div className="flex flex-row items-center flex-1">
										<Avatar className={`h-[30px] w-[30px] mr-4 ${product.theme} bg-[var(--bar-bg)]`} variant="rounded">
											{React.createElement(product.icon, { className: 'text-[20px]' })}
										</Avatar>
										<div>
											<div className="text-sm font-medium truncate">{asset.name}</div>
											<div className="text-xs text-textGray leading-none truncate">{t(product.name)}</div>
										</div>
									</div>
									<div className="flex-1">
										<div className="text-xs truncate">{updated && t('common:assets.updated', { date: updated })}</div>
										<div className="text-xs text-textGray truncate">{updatedByStr && t('common:assets.by', { person: updatedByStr })}</div>
									</div>
									{canCreate && isLoading ? (
										<CircularProgress size={20} className="text-textGray mr-2" />
									) : (
										canCreate && (
											<IconButton size="small" onClick={(e) => onOpenAssetMenu(e, aid)}>
												<MoreIcon className="text-xl" />
											</IconButton>
										)
									)}
								</div>
							)
					})}
			</div>
			<Menu anchorEl={assetMenu?.anchor} keepMounted open={Boolean(assetMenu?.anchor)} onClose={() => setAssetMenu(null)}>
				<MenuItem dense onClick={onDeleteOpen}>
					{t('common:buttons.delete')}
				</MenuItem>
			</Menu>
			<ActionConfirm open={Boolean(isDeleteOpen)} content={t('common:messages.deleteConfirm')} onClose={() => setIsDeleteOpen(null)} onConfirm={onDeleteAsset} />
			{contextMenu && <ContextMenu x={contextMenu.x} y={contextMenu.y} items={contextMenu.items} close={() => setContextMenu(null)} />}
		</>
	)
}
