import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Autocomplete, TextField } from '@mui/material'
import useLazyLoading from 'common/hooks/useLazyLoading'
import { useTranslation } from 'react-i18next'
import { throttle } from 'throttle-debounce'
import ErrorIcon from '@mui/icons-material/ErrorOutlineOutlined'
import { loadSelectedVariable, searchPublishedVariables } from 'model/saga-actions/modelActions'
import { cancelSearchVariables, cancelSelectedVariable } from 'model/store/modelReducer'

export default function ModelTypeRefVariable({ aid, teamId, onChange, value, validation, fieldName, showLabel = false, disabled = false, loadKey }) {
	const dispatch = useDispatch()
	const { t } = useTranslation(['common'])

	// #### STORE
	const tid = useSelector((state) => state.auth.tid)
	const uid = useSelector((state) => state.auth.uid)
	const search = useSelector((state) => state.model.search[loadKey])
	const selectVariable = useSelector((state) => state.model.selectedVariable[loadKey])
	const [error, setError] = useState(null)
	const where = [{ attribute: 'modelId', operator: '!=', value: aid }]
	// #### STATE
	const [searchTerm, setSearchTerm] = useState(null)
	const [searchResults, setSearchResults] = useState([])

	// #### EFFECTS
	// Clean search
	useEffect(() => {
		return () => dispatch(cancelSearchVariables({ key: loadKey }))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (selectVariable?.loaded && selectVariable?.notFound) setError(t('common:error.value_deleted_long'))
		else setError(null)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectVariable?.loaded, selectVariable?.notFound])

	// Load selected item
	useEffect(() => {
		const lKey = loadKey
		if (value) dispatch(loadSelectedVariable({ key: lKey, tid, teamId, id: value }))
		return () => dispatch(cancelSelectedVariable({ key: lKey }))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value])

	// Initialize search results
	useEffect(() => {
		var newResults = search?.result || []
		setSearchResults(newResults)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search, selectVariable])

	// Search
	useEffect(() => {
		searchThrottled(loadKey, tid, uid, searchTerm)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchTerm])

	const searchThrottled = throttle(200, (key, tid, uid, searchTerm) => {
		dispatch(searchPublishedVariables({ key, tid, teamId, uid, searchTerm, where }))
	})

	// #### LAZY LOADING
	const triggerFetch = useLazyLoading(() => dispatch(searchPublishedVariables({ key: loadKey, tid, teamId, uid, searchTerm, startAfter: search?.startAfter, where })), search?.isLoading, search?.notFound)

	// #### FUNCTIONS
	const getOptionId = (option) => option?.id || ''
	const getOptionLabel = (option) => option?.data?.label || ''

	function onSearchTerm(value) {
		setSearchTerm(value === '' ? null : value)
	}

	function onSelect(e, item) {
		const itemId = item?.id || null
		if(itemId === value) return
		onChange(itemId)
	}
	
	// ###########################
	// #### RENDER
	// ###########################
	return (
		<>
			<Autocomplete
				options={searchResults}
				getOptionLabel={getOptionLabel}
				isOptionEqualToValue={(option, value) => getOptionId(option) === value}
				value={selectVariable || null}
				inputValue={searchTerm || ''}
				onChange={onSelect}
				onInputChange={(e, value) => onSearchTerm(value)}
				filterOptions={(i) => i}
				renderInput={(params) => (
					<TextField
						{...params}
						label={showLabel ? fieldName : null}
						variant="outlined"
						size="small"
						margin="none"
						fullWidth
						InputProps={{ ...params.InputProps, className: 'text-sm' }}
						InputLabelProps={{ className: 'text-sm' }}
						sx={{ placeholder: 'text-sm' }}
						error={validation && !validation?.selectVariable?.valid}
						helperText={
							validation &&
							!validation.valid &&
							validation.error &&
							t('common:' + validation?.selectVariable.error, {
								field: fieldName
							})
						}
					/>
				)}
				renderOption={(props, option, { selected }) => (
					<li {...props} key={getOptionId(option)}>
						<span ref={searchResults?.length > 0 && getOptionId(option) === getOptionId(searchResults[searchResults.length - 1]) ? triggerFetch : undefined}>{getOptionLabel(option)}</span>
					</li>
				)}
				classes={{ root: 'flex-1', listbox: 'text-sm', noOptions: 'text-sm' }}
				openOnFocus
				disabled={disabled}
			/>
			{error && (
				<div className="flex flex-row items-center text-red-500 mt-1">
					<ErrorIcon fontSize="small" className="text-red-500 mr-2" />
					<span className="text-xs">{error}</span>
				</div>
			)}
		</>
	)
}
