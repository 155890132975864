export const PLANS = {
	free: {
		key: 'free',
		label: 'common:plans.free',
		prices: {
			month: {
				eur: 0,
				usd: 0,
				priceId: null
			},
			year: {
				eur: 0,
				usd: 0,
				priceId: null
			}
		},
		unit: 'common:plans.seatUnit',
		tag: null,
		features: ['1 creator/editor', 'Unlimited viewers', '10 tables & 3 models', '500 rows per table', '50 variables per model', '5 categories per model', '10 category items per category', '1GB of attachments per team'],
		seatSelector: { isEnabled: false, fixedSeats: 1 }
	},
	pro: {
		key: 'pro',
		label: 'common:plans.pro',
		prices: {
			month: {
				eur: 54,
				usd: 54,
				priceId: process.env.REACT_APP_STRIPE === 'prod' ? 'price_1MeHElLnRTfEDQXc9NQB53Xd' : 'price_1MeHHhLnRTfEDQXcimvGjMHE'
			},
			year: {
				eur: 49,
				usd: 49,
				priceId: process.env.REACT_APP_STRIPE === 'prod' ? 'price_1MeHG4LnRTfEDQXcpVyA4FCB' : 'price_1MeHIOLnRTfEDQXcnri0pxmP'
			}
		},
		unit: 'common:plans.seatUnit',
		tag: 'common:plans.recommended',
		features: [
			'Unlimited seats',
			'Unlimited viewers',
			'50 tables & models',
			'10,000 rows per table',
			'200 variables per model',
			'20 categories per model', 
			'1,000 category items per category',
			'10GB of attachments per team',
			'Forecasting & simulation',
			'Embedding on external sites'
		],
		seatSelector: { isEnabled: true, fixedSeats: null }
	},
	business: {
		key: 'business',
		label: 'common:plans.business',
		prices: null,
		unit: 'common:plans.seatUnit',
		tag: null,
		features: [
			'Unlimited seats',
			'Unlimited viewers',
			'Unlimited tables & models',
			'Unlimited rows per table',
			'Unlimited variables per model',
			'Unlimited categories',
			'Unlimited category items',
			'Unlimited attachments per team',
			'Forecasting & simulation',
			'Embedding on external sites',
			'Advanced security',
			'Premium support'
		],

		seatSelector: null
	}
}

export const ADDONS = {
	premiumConnector: {
		key: 'premiumConnector',
		label: 'common:addons.premiumConnector',
		prices: {
			month: {
				eur: 218,
				usd: 218,
				priceId: process.env.REACT_APP_STRIPE === 'prod' ? 'price_1MeHLhLnRTfEDQXcEmAQNAFm' : 'price_1MeHJjLnRTfEDQXcnIyflxPq'
			},
			year: {
				eur: 199,
				usd: 199,
				priceId: process.env.REACT_APP_STRIPE === 'prod' ? 'price_1MeHM9LnRTfEDQXc6hHfVC9N' : 'price_1MeHKgLnRTfEDQXcN3EPWvna'
			}
		},
		tag: 'common:plans.popular',
		description: 'common:addons.premiumConnectorDesc',
		unit: 'common:addons.premiumConnectorUnit'
	}
}

export const BILLING_CURRENCIES = {
	eur: {
		key: 'eur',
		label: 'EUR',
		symbol: '€'
	},
	usd: {
		key: 'usd',
		label: 'USD',
		symbol: '$'
	}
}

export const BILLING_FREQUENCIES = {
	month: {
		key: 'month',
		label: 'common:billingFreq.month',
		freqLabel: 'common:billingFreq.monthFreq'
	},
	year: {
		key: 'year',
		label: 'common:billingFreq.year',
		freqLabel: 'common:billingFreq.yearFreq'
	}
}

export const CONTACT_EMAIL = 'hello@singularly.com'
