import { call, takeEvery, put, spawn, select } from 'redux-saga/effects'
import * as api from 'common/api/teamApi'
import * as authApi from 'common/api/authApi'
import * as storeAction from 'common/store/teamReducer'
import * as sagaAction from 'common/saga-actions/teamActions'
import * as subscribeHelper from 'common/saga/helpers/subscribeHelper'
import * as globalStoreAction from 'common/store/globalReducer'
import * as globalSagaAction from 'common/saga-actions/globalActions'
import { generateKeywords } from 'common/saga/helpers/utils'
import { throwError } from 'common/config/errors'

const FILE_NAME = 'teamSagas'

const getTid = (state) => state.auth.tid
const getUid = (state) => state.auth.uid

// #### PATH TO THE COLLECTION
function defaultPath(includeDocId) {
	const path = [
		{ collection: 'tenants', param: 'tid', value: null },
		{ collection: 'users', param: 'uid', value: null },
		{ collection: 'teams', param: includeDocId ? 'teamId' : null, value: null }
	]
	return path
}

function defaultUsersPath(includeDocId) {
	const path = [
		{ collection: 'tenants', param: 'tid', value: null },
		{ collection: 'teams', param: 'teamId', value: null },
		{ collection: 'users', param: includeDocId ? 'userId' : null, value: null }
	]
	return path
}

function defaultCategoriesPath(includeDocId) {
	const path = [
		{ collection: 'tenants', param: 'tid', value: null },
		{ collection: 'teams', param: 'teamId', value: null },
		{ collection: 'categories', param: includeDocId ? 'categoryId' : null, value: null }
	]
	return path
}

function defaultRelationshipsPath(includeDocId) {
	const path = [
		{ collection: 'tenants', param: 'tid', value: null },
		{ collection: 'teams', param: 'teamId', value: null },
		{ collection: 'relationships', param: includeDocId ? 'relationshipId' : null, value: null }
	]
	return path
}

// #### SUBSCRIBE TO TEAMS
function* loadTeamsReq() {
	const queryConfig = subscribeHelper.queryConfig({ path: defaultPath(false), orderBy: [{ attribute: 'name', order: 'asc' }], returnType: 'map' })
	const sagaConfig = subscribeHelper.sagaConfig({ loadAction: sagaAction.LOAD_TEAMS, loadResponse: storeAction.loadTeams, cancelAction: sagaAction.CANCEL_TEAMS, cancelResponse: storeAction.cancelTeams })
	yield spawn(subscribeHelper.subscribe, sagaConfig, queryConfig)
}

// #### MANAGE TEAMS
function* createTeamReq() {
	yield takeEvery(sagaAction.CREATE_TEAM, createTeam)
}

function* createTeam(triggeredAction) {
	try {
		const { name } = triggeredAction
		yield put(globalStoreAction.loadingOn({ key: 'newTeam' }))
		const tid = yield select(getTid)
		const uid = yield select(getUid)
		const teamId = yield call(createTeamExecute, tid, uid, name)
		yield put(storeAction.setNewTeamId(teamId))
	} catch (err) {
		const content = throwError(err, FILE_NAME, createTeam.name)
		yield put(globalSagaAction.showMessage({ content }))
	} finally {
		yield put(globalStoreAction.loadingOff({ key: 'newTeam' }))
	}
}

export function* createTeamExecute(tid, uid, name) {
	const keywords = generateKeywords(name)
	const content = { name, keywords }
	const { token } = yield call(authApi.getAuthToken)
	const { data } = yield call(api.createTeam, token, tid, uid, content)
	const teamId = data.teamId
	return teamId
}

function* updateTeamReq() {
	yield takeEvery(sagaAction.UPDATE_TEAM, updateTeam)
}

function* updateTeam(triggeredAction) {
	const { teamId, content } = triggeredAction
	try {
		yield put(globalStoreAction.loadingOn({ key: 'updateTeam', item: teamId }))
		const tid = yield select(getTid)
		let newContent = { ...content }
		if (newContent.name) {
			const keywords = generateKeywords(content.name)
			newContent = { ...newContent, keywords }
		}
		const { token } = yield call(authApi.getAuthToken)
		yield call(api.updateTeam, token, tid, teamId, newContent)
	} catch (err) {
		const content = throwError(err, FILE_NAME, updateTeam.name)
		yield put(globalSagaAction.showMessage({ content }))
	} finally {
		yield put(globalStoreAction.loadingOff({ key: 'updateTeam', item: teamId }))
	}
}

function* deleteTeamReq() {
	yield takeEvery(sagaAction.DELETE_TEAM, deleteTeam)
}

function* deleteTeam(triggeredAction) {
	const { tid, teamId } = triggeredAction
	try {
		yield put(globalStoreAction.loadingOn({ key: 'deleteTeam', item: teamId }))
		const { token } = yield call(authApi.getAuthToken)
		yield call(api.deleteTeam, token, tid, teamId)
	} catch (err) {
		const content = throwError(err, FILE_NAME, deleteTeam.name)
		yield put(globalSagaAction.showMessage({ content }))
	} finally {
		yield put(globalStoreAction.loadingOff({ key: 'deleteTeam', item: teamId }))
	}
}

function* generateApiKeyReq() {
	yield takeEvery(sagaAction.GENERATE_API_KEY, generateApiKey)
}

function* generateApiKey(triggeredAction) {
	try {
		const { tid, teamId } = triggeredAction
		const { token } = yield call(authApi.getAuthToken)
		yield put(globalStoreAction.loadingOn({ key: 'newApiKey' }))
		 yield call(api.generateApiKey, token, tid, teamId)
	} catch (err) {
		const content = throwError(err, FILE_NAME, generateApiKey.name)
		yield put(globalSagaAction.showMessage({ content }))
	} finally {
		yield put(globalStoreAction.loadingOff({ key: 'newApiKey' }))
	}
}

function* deleteApiKeyReq() {
	yield takeEvery(sagaAction.DELETE_API_KEY, deleteApiKey)
}

function* deleteApiKey(triggeredAction) {
	try {
		const { tid, teamId, apiId } = triggeredAction
		const { token } = yield call(authApi.getAuthToken)
		yield put(globalStoreAction.loadingOn({ key: 'deleteApiKey' }))
		 yield call(api.deleteApiKey, token, tid, teamId, apiId)
	} catch (err) {
		const content = throwError(err, FILE_NAME, deleteApiKey.name)
		yield put(globalSagaAction.showMessage({ content }))
	} finally {
		yield put(globalStoreAction.loadingOff({ key: 'deleteApiKey' }))
	}
}

// SUBSCRIBE TO USERS
function* loadTeamUsersReq() {
	const queryConfig = subscribeHelper.queryConfig({
		path: defaultUsersPath(false),
		orderBy: [{ attribute: 'email', order: 'asc' }],
		returnType: 'map'
	})
	const sagaConfig = subscribeHelper.sagaConfig({
		loadAction: sagaAction.LOAD_TEAM_USERS,
		loadResponse: storeAction.loadTeamUsers,
		cancelAction: sagaAction.CANCEL_TEAM_USERS,
		cancelResponse: storeAction.cancelTeamUsers
	})
	yield spawn(subscribeHelper.subscribe, sagaConfig, queryConfig)
}

// SUBSCRIBE TO CATEGORIES
function* loadTeamCategoriesReq() {
	const queryConfig = subscribeHelper.queryConfig({
		path: defaultCategoriesPath(false),
		orderBy: [{ attribute: 'name', order: 'asc' }],
		returnType: 'map'
	})
	const sagaConfig = subscribeHelper.sagaConfig({
		loadAction: sagaAction.LOAD_TEAM_CATEGORIES,
		loadResponse: storeAction.loadTeamCategories,
		cancelAction: sagaAction.CANCEL_TEAM_CATEGORIES,
		cancelResponse: storeAction.cancelTeamCategories
	})
	yield spawn(subscribeHelper.subscribe, sagaConfig, queryConfig)
}

// SUBSCRIBE TO RElATIONSHIPS
function* loadTeamRelationshipsReq() {
	const queryConfig = subscribeHelper.queryConfig({
		path: defaultRelationshipsPath(false),
		orderBy: [],
		returnType: 'map'
	})
	const sagaConfig = subscribeHelper.sagaConfig({
		loadAction: sagaAction.LOAD_TEAM_RELATIONSHIPS,
		loadResponse: storeAction.loadTeamRelationships,
		cancelAction: sagaAction.CANCEL_TEAM_RELATIONSHIPS,
		cancelResponse: storeAction.cancelTeamRelationships
	})
	yield spawn(subscribeHelper.subscribe, sagaConfig, queryConfig)
}

// #### MANAGE PERMITS
function* inviteUserReq() {
	yield takeEvery(sagaAction.INVITE_USER, inviteUser)
}

function* inviteUser(triggeredAction) {
	const { tid, uid, teamId, email, role } = triggeredAction
	try {
		yield put(globalStoreAction.loadingOn({ key: 'inviteTeamUser' }))
		const { token } = yield call(authApi.getAuthToken)
		yield call(api.inviteUser, token, tid, teamId, uid, email, role)
	} catch (err) {
		const content = throwError(err, FILE_NAME, inviteUser.name)
		yield put(globalSagaAction.showMessage({ content }))
	} finally {
		yield put(globalStoreAction.loadingOff({ key: 'inviteTeamUser' }))
	}
}

function* updateUserReq() {
	yield takeEvery(sagaAction.UPDATE_USER, updateUser)
}

function* updateUser(triggeredAction) {
	const { tid, teamId, userId, role } = triggeredAction
	try {
		yield put(globalStoreAction.loadingOn({ key: 'updateTeamUser', item: userId }))
		const { token } = yield call(authApi.getAuthToken)
		yield call(api.updateUser, token, tid, teamId, userId, role)
	} catch (err) {
		const content = throwError(err, FILE_NAME, updateUser.name)
		yield put(globalSagaAction.showMessage({ content }))
	} finally {
		yield put(globalStoreAction.loadingOff({ key: 'updateTeamUser', item: userId }))
	}
}

function* deleteUserReq() {
	yield takeEvery(sagaAction.DELETE_USER, deleteUser)
}

function* deleteUser(triggeredAction) {
	const { tid, teamId, userId } = triggeredAction
	try {
		yield put(globalStoreAction.loadingOn({ key: 'deleteTeamUser' }))
		const { token } = yield call(authApi.getAuthToken)
		yield call(api.deleteUser, token, tid, teamId, userId)
	} catch (err) {
		const content = throwError(err, FILE_NAME, deleteUser.name)
		yield put(globalSagaAction.showMessage({ content }))
	} finally {
		yield put(globalStoreAction.loadingOff({ key: 'deleteTeamUser' }))
	}
}

function* acceptInviteReq() {
	yield takeEvery(sagaAction.ACCEPT_INVITE, acceptInvite)
}

function* acceptInvite(triggeredAction) {
	const { teamId } = triggeredAction
	try {
		yield put(globalStoreAction.loadingOn({ key: 'manageTeamInvite', item: teamId }))
		const uid = yield select(getUid)
		const tid = yield select(getTid)
		const { token } = yield call(authApi.getAuthToken)
		yield call(api.acceptInvite, token, tid, teamId, uid)
		yield put(storeAction.setNewTeamId(teamId))
	} catch (err) {
		const content = throwError(err, FILE_NAME, acceptInvite.name)
		yield put(globalSagaAction.showMessage({ content }))
	} finally {
		yield put(globalStoreAction.loadingOff({ key: 'manageTeamInvite', item: teamId }))
	}
}

function* rejectInviteReq() {
	yield takeEvery(sagaAction.REJECT_INVITE, rejectInvite)
}

function* rejectInvite(triggeredAction) {
	const { teamId } = triggeredAction
	try {
		yield put(globalStoreAction.loadingOn({ key: 'manageTeamInvite', item: teamId }))
		const uid = yield select(getUid)
		const tid = yield select(getTid)
		const { token } = yield call(authApi.getAuthToken)
		yield call(api.rejectInvite, token, tid, teamId, uid)
	} catch (err) {
		const content = throwError(err, FILE_NAME, rejectInvite.name)
		yield put(globalSagaAction.showMessage({ content }))
	} finally {
		yield put(globalStoreAction.loadingOff({ key: 'manageTeamInvite', item: teamId }))
	}
}

export default function* root() {
	// #### SUBSCRIBE TO TEAMS
	yield spawn(loadTeamsReq)
	// #### MANAGE TEAMS
	yield spawn(createTeamReq)
	yield spawn(updateTeamReq)
	yield spawn(deleteTeamReq)
	yield spawn(generateApiKeyReq)
	yield spawn(deleteApiKeyReq)
	// SUBSCRIBE TO USERS
	yield spawn(loadTeamUsersReq)
	// SUBSCRIBE TO CATEGORIES
	yield spawn(loadTeamCategoriesReq)
	// SUBSCRIBE TO RELATIONSHIPS
	yield spawn(loadTeamRelationshipsReq)
	// #### MANAGE PERMITS
	yield spawn(inviteUserReq)
	yield spawn(updateUserReq)
	yield spawn(deleteUserReq)
	yield spawn(acceptInviteReq)
	yield spawn(rejectInviteReq)
}
