import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { authenticate, cancelAuthenticate } from 'common/saga-actions/authActions'
import { cancelUser, loadUser } from 'common/saga-actions/userActions'
import { cancelTeams, loadTeams } from 'common/saga-actions/teamActions'

export default function useAuthenticate() {
	const dispatch = useDispatch()

	// Auth
	const checkDone = useSelector((state) => state.auth.checkDone)
	const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)
	const tid = useSelector((state) => state.auth.tid)
	const uid = useSelector((state) => state.auth.uid)

	// Authenticate user when app is loaded
	useEffect(() => {
		const lCheckDone = checkDone
		if (!lCheckDone) dispatch(authenticate())
		return () => {
			if (lCheckDone) dispatch(cancelAuthenticate())
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [checkDone])

	useEffect(() => {
		if (isLoggedIn && tid && uid) {
			dispatch(loadUser({ tid, uid }))
			dispatch(loadTeams({ tid, uid }))
		}
		return () => {
			dispatch(cancelUser())
			dispatch(cancelTeams())
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoggedIn, tid, uid])

	return isLoggedIn
}
