import { memo, useCallback } from 'react'
import { cellText } from 'common/components/grid/cells/Cell'
import FormulaCell from 'common/components/formula/FormulaCell'
import { useTranslation } from 'react-i18next'
import { areEqual } from 'react-window'
import { Button, Tooltip as MuiTooltip } from '@mui/material'
import clsx from 'clsx'
import { AddIcon, SettingsIcon, ConnectIcon, CategoriesIcon, ExpandedIcon, RightIcon, LeftIcon, UnfoldIcon, EditIcon } from 'common/icons/index'
import { DATA_TYPES } from 'common/constants/dataTypes'
import { ERRORS_DATA, ERROR_CELL_PRINT } from 'common/constants/errorsData'
import { printNumWithProps } from 'common/utils/numbers'
import { isGroupOpen as _isGroupOpen } from 'model/utils/rows'
import { MODEL_VAR_TYPES } from 'model/constants/modelTypes'

function Tooltip({ title, children, ...props }) {
	return (
		<MuiTooltip {...props} title={title} TransitionProps={{ timeout: { appear: 200, enter: 200, exit: 0 } }}>
			{children}
		</MuiTooltip>
	)
}

// Do not include: cancel, onChangeCellRef, onOpenContextMenu, onConnectDataRef, onBreakCategoriesRef, onAddRowRef, onToggleRowRef, setActiveCell, onPageNextRef, onDrillDownRef, onCellClick, onDragStart, onDragEnd, onDrop, onDragEnter, onDragLeave, onDragOver, onCellResizeRef
function cellStickyColAreEqual(prev, next) {
	return (
		prev.rIndex === next.rIndex &&
		prev.cIndex === next.cIndex &&
		prev.focus === next.focus &&
		prev.item === next.item &&
		prev.isDesigning === next.isDesigning &&
		prev.isGroup === next.isGroup &&
		prev.isBreakdown === next.isBreakdown &&
		prev.breakdownHasPrev === next.breakdownHasPrev &&
		prev.breakdownHasNext === next.breakdownHasNext &&
		prev.isHidden === next.isHidden &&
		prev.isEditable === next.isEditable &&
		prev.backgroundColor === next.backgroundColor &&
		prev.fontColor === next.fontColor &&
		prev.fontWeight === next.fontWeight &&
		prev.style.width === next.style.width &&
		prev.style.height === next.style.height &&
		prev.style.left === next.style.left &&
		prev.style.right === next.style.right &&
		prev.style.top === next.style.top &&
		prev.loading === next.loading &&
		prev.isConnected === next.isConnected &&
		prev.isDragActive === next.isDragActive &&
		prev.canOpen === next.canOpen &&
		prev.isOpen === next.isOpen &&
		prev.depth === next.depth &&
		prev.hasNext === next.hasNext &&
		prev.hasPrev === next.hasPrev &&
		prev.hasCategories === next.hasCategories &&
		prev.cellSplit === next.cellSplit &&
		JSON.stringify(prev.breakdownExtra) === JSON.stringify(next.breakdownExtra)
	)
}

const CellStickyColRender = memo(
	({
		// Common props
		rIndex,
		cIndex,
		focus,
		cancel,
		item,
		isDesigning,
		isGroup,
		isBreakdown,
		breakdownHasPrev,
		breakdownHasNext,
		isHidden,
		isEditable,
		backgroundColor,
		fontColor,
		fontWeight,
		style,
		// Common function
		onChangeCellRef,
		// Props for sticky column
		loading,
		isConnected,
		canOpen,
		isOpen,
		depth,
		hasNext,
		hasPrev,
		hasCategories,
		onOpenContextMenu,
		onConnectDataRef,
		onBreakCategoriesRef,
		onAddRowRef,
		onToggleRowRef,
		setActiveCell,
		onPageNextRef,
		onDrillDownRef,
		onCellClick,
		onDragStart,
		onDragEnd,
		onDrop,
		onDragEnter,
		onDragLeave,
		onDragOver,
		isDragActive,
		cellSplit,
		onCellResizeRef,
		breakdownExtra
	}) => {
		const id = `cell#${rIndex}#${cIndex}`
		const { t } = useTranslation(['common'])
		// ###########################
		// #### CELL CHANGE HANDLER
		// ###########################
		const setItem = useCallback((value) => onChangeCellRef.current(value, rIndex, cIndex), [rIndex, cIndex, onChangeCellRef])
		// ###########################
		// #### CELL RENDERER
		// ###########################
		const cellRenderer = cellText
		const Cell = cellRenderer?.component
		const cellConfig = cellRenderer?.config
		return (
			<div
				id={id}
				className={clsx('grid-cell-wrapper grid-draggable', isBreakdown && 'font-light', breakdownHasPrev && 'shadow-innerTop', breakdownHasNext && 'shadow-innerBottom')}
				style={{ ...style, backgroundColor }}
				draggable={isDesigning && !isBreakdown ? true : false}
				onDragStart={(e) => onDragStart(e, cIndex, rIndex)}
				onDragEnd={onDragEnd}
				onClick={onCellClick}
			>
				{!isBreakdown && (
					<>
						{/* Row drop area */}
						{isDesigning && isDragActive && (
							<>
								<div
									className="grid-row-drop-top"
									onDrop={isDragActive ? (e) => onDrop(e, cIndex, rIndex - 1) : undefined}
									onDragEnter={isDragActive ? (e) => onDragEnter(e) : undefined}
									onDragLeave={isDragActive ? (e) => onDragLeave(e) : undefined}
									onDragOver={isDragActive ? (e) => onDragOver(e) : undefined}
								/>
								<div
									className="grid-row-drop-bottom"
									onDrop={isDragActive ? (e) => onDrop(e, cIndex, rIndex) : undefined}
									onDragEnter={isDragActive ? (e) => onDragEnter(e) : undefined}
									onDragLeave={isDragActive ? (e) => onDragLeave(e) : undefined}
									onDragOver={isDragActive ? (e) => onDragOver(e) : undefined}
								/>
							</>
						)}
						<div className="grid-row-buttons-wrapper bg-inherit">
							{/* Row hover buttons */}
							{isDesigning && (
								<div className="grid-row-buttons-wrapper-hidden">
									<Tooltip title={t('model:tooltip.settings')} leaveTouchDelay={0}>
										<span>
											<SettingsIcon
												className="grid-button-settings"
												onMouseDown={(e) => {
													if (e.preventDefault) e.preventDefault()
													if (e.stopPropagation) e.stopPropagation()
													setActiveCell({ col: cIndex, row: rIndex })
													onOpenContextMenu({ e, rowIndex: rIndex, colIndex: cIndex })
												}}
												onClick={(e) => {
													if (e.preventDefault) e.preventDefault()
													if (e.stopPropagation) e.stopPropagation()
												}}
											/>
										</span>
									</Tooltip>
									{!isGroup && (
										<>
											<Tooltip title={t('model:tooltip.categories')} leaveTouchDelay={0}>
												<span>
													<CategoriesIcon
														className="grid-button-settings"
														onMouseDown={(e) => {
															if (e.preventDefault) e.preventDefault()
															if (e.stopPropagation) e.stopPropagation()
															setActiveCell({ col: cIndex, row: rIndex })
															onBreakCategoriesRef.current({ x: e.clientX, y: e.clientY }, rIndex)
														}}
														onClick={(e) => {
															if (e.preventDefault) e.preventDefault()
															if (e.stopPropagation) e.stopPropagation()
														}}
													/>
												</span>
											</Tooltip>
											{!isConnected && (
												<Tooltip title={t('model:tooltip.connectData')} leaveTouchDelay={0}>
													<span>
														<ConnectIcon
															className="grid-button-settings"
															onMouseDown={(e) => {
																if (e.preventDefault) e.preventDefault()
																if (e.stopPropagation) e.stopPropagation()
																setActiveCell({ col: cIndex, row: rIndex })
																onConnectDataRef.current(document.getElementById(id), rIndex)
															}}
															onClick={(e) => {
																if (e.preventDefault) e.preventDefault()
																if (e.stopPropagation) e.stopPropagation()
															}}
														/>
													</span>
												</Tooltip>
											)}
										</>
									)}
								</div>
							)}

							{isDesigning && (
								<>
									{isGroup && (
										<Tooltip title={t('model:tooltip.addVariable')} leaveTouchDelay={0}>
											<span>
												<AddIcon
													className="grid-button-add"
													onMouseDown={(e) => {
														if (e.preventDefault) e.preventDefault()
														if (e.stopPropagation) e.stopPropagation()
														setActiveCell({ col: cIndex, row: rIndex })
														onAddRowRef.current(rIndex, false)
													}}
													onClick={(e) => {
														if (e.preventDefault) e.preventDefault()
														if (e.stopPropagation) e.stopPropagation()
													}}
												/>
											</span>
										</Tooltip>
									)}
									{!isGroup && (
										<>
											{isConnected && (
												<Tooltip title={t('model:tooltip.connected')} leaveTouchDelay={0}>
													<span>
														<ConnectIcon
															className="grid-icon-round"
															onMouseDown={(e) => {
																if (e.preventDefault) e.preventDefault()
																if (e.stopPropagation) e.stopPropagation()
																setActiveCell({ col: cIndex, row: rIndex })
																onConnectDataRef.current(document.getElementById(id), rIndex)
															}}
															onClick={(e) => {
																if (e.preventDefault) e.preventDefault()
																if (e.stopPropagation) e.stopPropagation()
															}}
														/>
													</span>
												</Tooltip>
											)}
										</>
									)}
								</>
							)}

							{!isGroup && isEditable && (
								<Tooltip title={t('model:tooltip.editable')} leaveTouchDelay={0}>
									<span>
										<EditIcon className={clsx('grid-icon-round', 'cursor-default')} />
									</span>
								</Tooltip>
							)}

							{hasPrev && (
								<LeftIcon
									className="grid-icon grid-button"
									onMouseDown={(e) => {
										if (e.preventDefault) e.preventDefault()
										if (e.stopPropagation) e.stopPropagation()
										setActiveCell({ col: cIndex, row: rIndex })
										onPageNextRef.current(rIndex, -1)
									}}
									onClick={(e) => {
										if (e.preventDefault) e.preventDefault()
										if (e.stopPropagation) e.stopPropagation()
									}}
								/>
							)}

							{hasCategories && isOpen && (
								<UnfoldIcon
									className="grid-icon grid-button"
									onMouseDown={(e) => {
										if (e.preventDefault) e.preventDefault()
										if (e.stopPropagation) e.stopPropagation()
										setActiveCell({ col: cIndex, row: rIndex })
										onDrillDownRef.current({ x: e.clientX, y: e.clientY }, rIndex)
									}}
									onClick={(e) => {
										if (e.preventDefault) e.preventDefault()
										if (e.stopPropagation) e.stopPropagation()
									}}
								/>
							)}

							{hasNext && (
								<RightIcon
									className="grid-icon grid-button"
									onMouseDown={(e) => {
										if (e.preventDefault) e.preventDefault()
										if (e.stopPropagation) e.stopPropagation()
										setActiveCell({ col: cIndex, row: rIndex })
										onPageNextRef.current(rIndex, 1)
									}}
									onClick={(e) => {
										if (e.preventDefault) e.preventDefault()
										if (e.stopPropagation) e.stopPropagation()
									}}
								/>
							)}
						</div>
						{/* Group start icon */}
						{loading ? (
							<div />
						) : // <CircularProgress size={15} style={{ marginLeft: `${10 * depth}px`, marginRight: '5px', color: ui.color.iconGreyDisabled }} />
						canOpen && isOpen ? (
							<ExpandedIcon
								className="grid-button-expand"
								onMouseDown={(e) => {
									if (e.preventDefault) e.preventDefault()
									if (e.stopPropagation) e.stopPropagation()
									setActiveCell({ col: cIndex, row: rIndex })
									onToggleRowRef.current(rIndex)
								}}
								onClick={(e) => {
									if (e.preventDefault) e.preventDefault()
									if (e.stopPropagation) e.stopPropagation()
								}}
								style={{ marginLeft: `${10 * depth}px`, marginRight: '5px' }}
							/>
						) : canOpen ? (
							<RightIcon
								className="grid-button-expand"
								onMouseDown={(e) => {
									if (e.preventDefault) e.preventDefault()
									if (e.stopPropagation) e.stopPropagation()
									setActiveCell({ col: cIndex, row: rIndex })
									onToggleRowRef.current(rIndex)
								}}
								onClick={(e) => {
									if (e.preventDefault) e.preventDefault()
									if (e.stopPropagation) e.stopPropagation()
								}}
								style={{ marginLeft: `${10 * depth}px`, marginRight: '5px' }}
							/>
						) : (
							<span style={{ marginLeft: `${20 + 10 * depth}px` }} />
						)}
					</>
				)}
				{/* Cell value*/}
				{isBreakdown ? (
					<>
						<div className="w-full h-full flex flex-row items-center">
							<div
								className="h-full border-r border-r-borderGray flex items-center overflow-hidden pr-2 relative shrink-0"
								style={{ width: `${cellSplit || 80}%`, paddingLeft: `${20 + 10 * depth}px` }}
							>
								{Cell &&
									item?.map((el, index) => (
										<div key={`bk#${rIndex}#${cIndex}#${index}`} className={clsx(`rounded px-2 shrink-0 text-sm ${el.theme} bg-[var(--primary-light)]`, index > 0 && 'ml-2')}>
											{el.v}
										</div>
									))}

								<div className="absolute top-0 bottom-0 right-0 w-[6px] cursor-col-resize hover:bg-black" onMouseDown={(e) => onCellResizeRef.current(e, rIndex)}></div>
							</div>
							<div className="h-full flex flex-row items-center overflow-hidden px-2 shrink-0">
								{breakdownExtra?.map((el, index) => (
									<div key={`bkxtra#${rIndex}#${cIndex}#${index}`} className={clsx(`rounded px-2 shrink-0 text-sm ${el.theme} bg-[var(--primary-light)]`, index > 0 && 'ml-2')}>
										{/* <div className="text-xxs text-textGray leading-none">Test</div> */}
										{/* <div className="leading-none">{el}</div> */}
										{el.v}
									</div>
								))}
							</div>
						</div>
					</>
				) : (
					Cell && <Cell item={item} setItem={setItem} focus={focus} cancel={cancel} isUppercase={isGroup} config={cellConfig} style={{ color: fontColor, fontWeight }} />
				)}
			</div>
		)
	},
	cellStickyColAreEqual
)

// Do not include: cancel, autoSelect, onChangeCellRef
function cellRenderAreEqual(prev, next) {
	return (
		prev.rIndex === next.rIndex &&
		prev.cIndex === next.cIndex &&
		prev.focus === next.focus &&
		prev.prevFocus === next.prevFocus &&
		prev.item === next.item &&
		prev.isGroup === next.isGroup &&
		prev.breakdownHasPrev === next.breakdownHasPrev &&
		prev.breakdownHasNext === next.breakdownHasNext &&
		prev.allowFormula === next.allowFormula &&
		prev.isHidden === next.isHidden &&
		prev.isEditable === next.isEditable &&
		prev.backgroundColor === next.backgroundColor &&
		prev.fontColor === next.fontColor &&
		prev.fontWeight === next.fontWeight &&
		prev.style.width === next.style.width &&
		prev.style.height === next.style.height &&
		prev.style.left === next.style.left &&
		prev.style.right === next.style.right &&
		prev.style.top === next.style.top &&
		prev.type === next.type &&
		JSON.stringify(prev.typeProps) === JSON.stringify(next.typeProps) &&
		JSON.stringify(prev.breakDownCatValues) === JSON.stringify(next.breakDownCatValues) &&
		JSON.stringify(prev.breakDownCatIDs) === JSON.stringify(next.breakDownCatIDs) &&
		JSON.stringify(prev.errors) === JSON.stringify(next.errors) &&
		prev.rangeLow === next.rangeLow &&
		prev.rangeHigh === next.rangeHigh &&
		prev.displayIntervals === next.displayIntervals
	)
}

const CellRender = memo(
	({
		// Common props
		rIndex,
		cIndex,
		focus,
		cancel,
		autoSelect,
		prevFocus,
		item,
		isGroup,
		isBreakdown,
		breakdownHasPrev,
		breakdownHasNext,
		allowFormula,
		isHidden,
		isEditable,
		backgroundColor,
		fontColor,
		fontWeight,
		style,
		type,
		typeProps,
		breakDownCatValues,
		breakDownCatIDs,
		// Common function
		onChangeCellRef,
		// Non-header props
		errors,
		rangeLow,
		rangeHigh,
		displayIntervals
	}) => {
		const displayFormula = allowFormula && (focus || prevFocus)

		// ###########################
		// #### CELL CHANGE HANDLER
		// ###########################
		const setItem = useCallback((value) => onChangeCellRef.current(value, rIndex, cIndex, breakDownCatValues, breakDownCatIDs), [rIndex, cIndex, onChangeCellRef, breakDownCatValues, breakDownCatIDs])
		// ###########################
		// #### CELL RENDERER
		// ###########################
		const isInfinite = item === 'Inf' || item === '-Inf'
		const typeObj = isInfinite ? DATA_TYPES.text : type ? DATA_TYPES[type] : null
		const cellRenderer = typeObj?.renderer
		const Cell = cellRenderer?.component
		const cellConfig = cellRenderer?.config

		// ###########################
		// #### ERROR RENDERER
		// ###########################
		const errorRenderer = DATA_TYPES.text.renderer
		const ErrorCell = errorRenderer.component
		const errorCellConfig = errorRenderer.config
		const errorPrint = errors?.map((value) => `- ${ERRORS_DATA[value].label}: ${ERRORS_DATA[value].desc}`)

		return (
			<>
				<div
					id={'cellWrapper#' + cIndex + '#' + rIndex}
					className={clsx('grid-cell-wrapper group', breakdownHasPrev && 'shadow-innerTop', breakdownHasNext && 'shadow-innerBottom')}
					style={{ ...style, backgroundColor, color: fontColor }}
				>
					{errors && !focus ? (
						<Tooltip
							title={
								<ul>
									{errorPrint?.map((el, errorIndex) => (
										<li key={`cellError#${cIndex}#${rIndex}#${errorIndex}`}>{el}</li>
									))}
								</ul>
							}
							leaveTouchDelay={0}
							placement="bottom-start"
							classes={{ tooltip: 'max-w-[400px]' }}
						>
							<div>
								<ErrorCell item={ERROR_CELL_PRINT} setItem={setItem} focus={false} cancel={false} isUppercase={false} config={errorCellConfig} />
							</div>
						</Tooltip>
					) : (
						Cell && (
							<Cell
								item={item}
								setItem={setItem}
								focus={focus && !allowFormula}
								cancel={cancel}
								isUppercase={false}
								typeProps={typeProps}
								config={cellConfig}
								style={{ color: fontColor, fontWeight }}
							/>
						)
					)}
					{rangeLow != null && rangeHigh != null && !errors && (
						<div className={clsx('absolute left-0 top-0 bottom-0 right-0 text-xxs text-textGray ml-2 flex-col justify-center', displayIntervals ? 'flex' : 'hidden group-hover:flex')}>
							<span>{printNumWithProps(rangeHigh, type, typeProps)}</span>
							<span>{printNumWithProps(rangeLow, type, typeProps)}</span>
						</div>
					)}
				</div>
				{displayFormula && (
					<div className={clsx('grid-cell-formula-wrapper', focus ? 'flex' : 'hidden')} style={{ left: style.left, top: style.top, minWidth: style.width, height: style.height }}>
						<FormulaCell rowIndex={rIndex} colIndex={cIndex} focus={focus} autoSelect={autoSelect} visible={focus} cancel={cancel} autocompletePosition={{ x: -20, y: style.height - 7 }} autoFocus />
					</div>
				)}
			</>
		)
	},
	cellRenderAreEqual
)

// Do not include: cancel, onChangeCellRef, onAddRowRef
function cellStickyRowAreEqual(prev, next) {
	return (
		prev.rIndex === next.rIndex &&
		prev.cIndex === next.cIndex &&
		prev.isRowHeader === next.isRowHeader &&
		prev.focus === next.focus &&
		prev.item === next.item &&
		prev.isDesigning === next.isDesigning &&
		prev.style.width === next.style.width &&
		prev.style.height === next.style.height &&
		prev.style.left === next.style.left &&
		prev.style.right === next.style.right &&
		prev.style.top === next.style.top &&
		prev.type === next.type &&
		JSON.stringify(prev.typeProps) === JSON.stringify(next.typeProps) &&
		prev.isToday === next.isToday &&
		prev.isHighlighted === next.isHighlighted &&
		prev.statistics?.sum === next.statistics?.sum &&
		prev.statistics?.count === next.statistics?.count &&
		prev.statistics?.average === next.statistics?.average
	)
}

const CellStickyRowRender = memo(
	({
		// Common props
		rIndex,
		cIndex,
		isRowHeader,
		focus,
		cancel,
		item,
		isDesigning,
		style,
		type,
		typeProps,
		// Common functions
		onChangeCellRef,
		onAddRowRef,
		// Props for sticky row
		onColumnResize,
		isToday,
		isHighlighted,
		// Props for top left cell
		statistics
	}) => {
		const { t } = useTranslation(['common'])

		// ###########################
		// #### CELL CHANGE HANDLER
		// ###########################
		const setItem = useCallback((value) => onChangeCellRef.current(value, rIndex, cIndex), [rIndex, cIndex, onChangeCellRef])

		// ###########################
		// #### CELL RENDERER
		// ###########################
		const typeObj = type ? DATA_TYPES[type] : null
		const cellRenderer = typeObj?.renderer
		const Cell = cellRenderer?.component
		const cellConfig = cellRenderer?.config

		// ###########################
		// #### STATISTICS
		// ###########################
		const sum = statistics?.sum != null ? printNumWithProps(statistics.sum, DATA_TYPES.num.key, DATA_TYPES.num.defaultProps) : null

		return (
			<div id={'cellWrapper#' + cIndex + '#' + rIndex} className={clsx('grid-cell-wrapper', isToday && 'bg-primaryLight', isHighlighted && 'bg-primaryLight')} style={style}>
				{/* Column resize handle */}
				{<div className="grid-column-resize" onMouseDown={(e) => onColumnResize(e, cIndex)} />}

				{/* First cell*/}
				{isRowHeader && isDesigning && (
					<div className="grid-row-buttons-wrapper">
						<Button size="small" startIcon={<AddIcon className="grid-icon" />} className="grid-button" onClick={() => onAddRowRef.current(null, true)}>
							{t('model:model.newGroup')}
						</Button>
					</div>
				)}

				{isRowHeader && !isDesigning && sum != null && (
					<div className="text-xs rounded bg-primaryLight px-2 py-1 truncate w-full">
						{t('model:statistics.sum')}: {sum}
					</div>
				)}

				{/* Cell value*/}
				{Cell && (
					<Cell
						item={item}
						setItem={setItem}
						focus={focus}
						cancel={cancel}
						typeProps={typeProps}
						config={cellConfig}
						className={clsx('font-medium', isToday ? '!text-primaryDark' : '!text-textGray')}
					/>
				)}
			</div>
		)
	},
	cellStickyRowAreEqual
)

function cellDataPreparation({ rIndex, cIndex, data, style, isRowHeader = false, isColHeader = false, CellRender }) {
	// ###########################
	// #### PARAMETERS
	// ###########################
	const rows = data?.rows
	const columns = data?.columns
	const editing = data?.editing
	const items = data?.items
	const isDesigning = data?.isDesigning
	const onChangeCellRef = data?.onChangeCellRef

	// ###########################
	// #### CONSTANTS
	// ###########################
	const viewProps = rows && rows[rIndex - 1]
	const column = columns && columns[cIndex]
	const rId = viewProps && viewProps.id
	const cId = column && column.id
	const focus = editing?.is && editing?.cell && cIndex === editing.cell?.col && rIndex === editing.cell?.row
	const cancel = editing?.cancel
	const isGroup = viewProps?.isGroup || false
	const variable = isGroup ? data?.groups && data?.groups[rId] : data?.variables && data?.variables[rId]
	const isBreakdown = viewProps?.isBreakdown || false
	const hasRowHeaderOptions = isRowHeader && !isBreakdown
	const type = isColHeader ? column?.type : variable?.type
	const typeProps = isColHeader ? column?.typeProps : variable?.typeProps
	const isHidden = variable?.varProps?.isHidden || false
	const backgroundColor = isGroup ? '#f5f5f5' : variable?.varProps?.backgroundColor || '#ffffff'
	const fontColor = isGroup ? '#545454' : focus ? '#000000' : variable?.varProps?.fontColor || '#000000'
	const fontWeight = isGroup ? '500' : variable?.varProps?.fontWeight || '400'

	// ###########################
	// #### PROPS FOR ROW HEADER
	// ###########################
	const loading = hasRowHeaderOptions && data?.loading && data?.loading[rId]
	const onAddRowRef = hasRowHeaderOptions && data?.onAddRowRef
	const onOpenContextMenu = hasRowHeaderOptions && data?.onOpenContextMenu
	const onConnectDataRef = hasRowHeaderOptions && data?.onConnectDataRef
	const onBreakCategoriesRef = hasRowHeaderOptions && data?.onBreakCategoriesRef
	const onToggleRowRef = hasRowHeaderOptions && data?.onToggleRowRef
	const setActiveCell = hasRowHeaderOptions && data?.setActiveCell
	// Drag & drop
	const onCellClick = (isRowHeader && data?.onCellClick) || undefined
	const onDragStart = (hasRowHeaderOptions && data?.onDragStart) || undefined
	const onDragEnd = (hasRowHeaderOptions && data?.onDragEnd) || undefined
	const onDrop = (hasRowHeaderOptions && data?.onDrop) || undefined
	const onDragEnter = (hasRowHeaderOptions && data?.onDragEnter) || undefined
	const onDragLeave = (hasRowHeaderOptions && data?.onDragLeave) || undefined
	const onDragOver = (hasRowHeaderOptions && data?.onDragOver) || undefined
	const isDragActive = hasRowHeaderOptions && data?.dragIndex?.row && data?.dragIndex?.row !== rIndex
	// Inner subcell resize
	const cellSplit = (isRowHeader && isBreakdown && data?.cellSplit && data?.cellSplit[rId]) || null
	const onCellResizeRef = (isRowHeader && isBreakdown && data?.onCellResizeRef) || undefined

	// ###########################
	// #### PROPS FOR COL HEADER
	// ###########################
	const onColumnResize = isColHeader && data?.onColumnResize
	const isToday = isColHeader && data?.today && data?.today === cId
	const isHighlighted = isColHeader && data?.highlightRange != null && cIndex >= data?.highlightRange?.start && cIndex <= data?.highlightRange?.end

	// ###########################
	// #### ROW HEADER EXPANSION
	// ###########################
	const canGroupOpen = (hasRowHeaderOptions && isGroup && data?.canGroupsOpen && data?.canGroupsOpen[rId]) || false
	const canVarOpen = (hasRowHeaderOptions && !isGroup && data?.canVarsOpen && data?.canVarsOpen[rId]) || false
	const canOpen = canGroupOpen || canVarOpen
	const isGroupOpen = hasRowHeaderOptions && isGroup && _isGroupOpen(data.viewGroups, rId)
	const isVariableOpen = (hasRowHeaderOptions && !isGroup && data?.viewVariables && data.viewVariables[rId]?.isOpen) || false
	const isOpen = isGroupOpen || isVariableOpen
	const depth = isRowHeader && viewProps?.depth
	const isConnected = variable?.sourceProps != null

	// Get breakdown data
	const itemsBreakdown = !isGroup && (isOpen || isBreakdown) && data?.breakdown
	const itemBreakdown = itemsBreakdown && itemsBreakdown[rId]
	const breakdownIndex = isBreakdown ? viewProps?.breakdownIndex : null
	const breakdownData = itemBreakdown && itemBreakdown.values && itemBreakdown.values[breakdownIndex]
	const breakdownLabel =
		isRowHeader &&
		isBreakdown &&
		breakdownData?.l?.map((v, index) => {
			const catId = itemBreakdown?.labels[index][0]
			const theme = data?.categories && data?.categories[catId]?.theme
			return { v, theme }
		})

	const breakdownExtra =
		isRowHeader &&
		isBreakdown &&
		breakdownData?.e?.map((v, index) => {
			const catId = itemBreakdown?.extraLabels[index][0]
			const theme = data?.categories && data?.categories[catId]?.theme
			return { v, theme }
		})

	const breakDownCatValues = isBreakdown && breakdownData?.c
	const breakDownCatIDs = isBreakdown && itemBreakdown?.labels?.map((l) => l[0])
	const breakDownMeanPos = !isRowHeader && isBreakdown && itemBreakdown?.valueNames?.default
	const breakDownLowPos = !isRowHeader && isBreakdown && itemBreakdown?.valueNames?.low
	const breakDownHighPos = !isRowHeader && isBreakdown && itemBreakdown?.valueNames?.high
	const breakdownValue = !isRowHeader && isBreakdown && breakdownData?.v && breakdownData?.v[cId] && breakdownData?.v[cId][breakDownMeanPos]
	const breakdownRangeLow = !isRowHeader && isBreakdown && breakdownData?.v && breakdownData?.v[cId] && breakdownData?.v[cId][breakDownLowPos]
	const breakdownRangeHigh = !isRowHeader && isBreakdown && breakdownData?.v && breakdownData?.v[cId] && breakdownData?.v[cId][breakDownHighPos]
	const breakdownCell = isBreakdown && isRowHeader ? breakdownLabel : isBreakdown && breakdownValue

	// Breakdown pagination
	const hasCategories = isRowHeader && variable?.categories?.length > 0
	const hasNext = isRowHeader && !isGroup && isOpen && itemBreakdown?.nextPageExists
	const hasPrev = isRowHeader && !isGroup && isOpen && itemBreakdown?.page > 0
	const breakdownHasPrev = !isGroup && isBreakdown && viewProps?.isFirst && itemBreakdown?.page > 0
	const breakdownHasNext = !isGroup && isBreakdown && viewProps?.isLast && itemBreakdown?.nextPageExists

	const onPageNextRef = (hasNext || hasPrev) && data?.onPageNextRef
	const onDrillDownRef = hasRowHeaderOptions && data?.onDrillDownRef

	// ###########################
	// #### PROPS FOR NON-HEADER CELLS
	// ###########################
	const isNotHeader = !isRowHeader && !isColHeader
	const autoSelect = isNotHeader && editing?.autoSelect
	const prevFocus = isNotHeader && !editing?.is && editing?.prevCell && cIndex === editing.prevCell?.col && rIndex === editing.prevCell?.row
	const errors = isNotHeader && data?.errors
	const cellErrors = errors && errors[rId] && errors[rId][cId]?.length > 0 ? errors[rId][cId] : null
	const allowFormula = isDesigning && isNotHeader && !isBreakdown && !isConnected && type !== MODEL_VAR_TYPES.checkbox.key
	const isEditable = variable?.varProps?.isEditable || false

	// ###########################
	// #### PROPS FOR STICKY TOP CELL
	// ###########################
	const isTopLeftHeader = isColHeader && isRowHeader
	const statistics = isTopLeftHeader && data?.statistics

	// ###########################
	// #### CONTENT
	// ###########################
	const varData = isNotHeader && items && items[rId]
	const meanPos = varData?.valueNames?.default
	const lowPos = varData?.valueNames?.low
	const highPos = varData?.valueNames?.high
	const cellData = varData?.values && varData?.values[0]?.v[cId] && varData?.values[0]?.v[cId][meanPos]
	const aggrRangeLow = varData?.values && varData?.values[0]?.v[cId] && varData?.values[0]?.v[cId][lowPos]
	const aggrRangeHigh = varData?.values && varData?.values[0]?.v[cId] && varData?.values[0]?.v[cId][highPos]

	const item = isColHeader ? column?.label : isBreakdown ? breakdownCell : isRowHeader ? variable?.label : cellData
	const rangeLow = isBreakdown ? breakdownRangeLow : aggrRangeLow
	const rangeHigh = isBreakdown ? breakdownRangeHigh : aggrRangeHigh
	const displayIntervals = isNotHeader && (data?.displayIntervals || false)

	return (
		<CellRender
			// Common props
			rIndex={rIndex}
			cIndex={cIndex}
			isRowHeader={isRowHeader}
			isColHeader={isColHeader}
			focus={focus}
			cancel={cancel}
			autoSelect={autoSelect}
			prevFocus={prevFocus}
			type={type}
			typeProps={typeProps}
			item={item}
			isDesigning={isDesigning}
			isGroup={isGroup}
			isBreakdown={isBreakdown}
			breakdownHasPrev={breakdownHasPrev}
			breakdownHasNext={breakdownHasNext}
			breakDownCatValues={breakDownCatValues}
			breakDownCatIDs={breakDownCatIDs}
			isHidden={isHidden}
			backgroundColor={backgroundColor}
			fontColor={fontColor}
			fontWeight={fontWeight}
			style={style}
			// Common functions
			onChangeCellRef={onChangeCellRef}
			// Props and functions for row header
			loading={loading}
			isConnected={isConnected}
			canOpen={canOpen}
			isOpen={isOpen}
			depth={depth}
			hasCategories={hasCategories}
			hasNext={hasNext}
			hasPrev={hasPrev}
			onPageNextRef={onPageNextRef}
			onDrillDownRef={onDrillDownRef}
			onAddRowRef={onAddRowRef}
			onOpenContextMenu={onOpenContextMenu}
			onConnectDataRef={onConnectDataRef}
			onBreakCategoriesRef={onBreakCategoriesRef}
			onToggleRowRef={onToggleRowRef}
			setActiveCell={setActiveCell}
			onCellClick={onCellClick}
			onDragStart={onDragStart}
			onDragEnd={onDragEnd}
			onDrop={onDrop}
			onDragEnter={onDragEnter}
			onDragLeave={onDragLeave}
			onDragOver={onDragOver}
			isDragActive={isDragActive}
			cellSplit={cellSplit}
			onCellResizeRef={onCellResizeRef}
			breakdownExtra={breakdownExtra}
			// Props for col header
			onColumnResize={onColumnResize}
			isToday={isToday}
			isHighlighted={isHighlighted}
			// Props for top left header
			statistics={statistics}
			// Props for non-header cells
			errors={cellErrors}
			allowFormula={allowFormula}
			rangeLow={rangeLow}
			rangeHigh={rangeHigh}
			displayIntervals={displayIntervals}
			isEditable={isEditable}
		/>
	)
}

export const CellStickyColumn = memo(({ rowIndex, columnIndex, data, style }) => {
	if (data?.stickyRows > 0 && rowIndex < data?.stickyRows) return null
	return cellDataPreparation({ rIndex: rowIndex, cIndex: 0, data, style, isRowHeader: true, CellRender: CellStickyColRender })
}, areEqual)

export const CellStickyRow = memo(({ rowIndex, columnIndex, data, style }) => {
	if (data?.stickyCols > 0 && columnIndex < data?.stickyCols) return null
	return cellDataPreparation({ rIndex: 0, cIndex: columnIndex, data, style, isColHeader: true, CellRender: CellStickyRowRender })
}, areEqual)

export const CellStickyTopLeft = memo(({ data, style }) => {
	return cellDataPreparation({ rIndex: 0, cIndex: 0, data, style, isColHeader: true, isRowHeader: true, CellRender: CellStickyRowRender })
}, areEqual)

export const Cell = memo(({ rowIndex, columnIndex, data, style }) => {
	if (data?.stickyRows > 0 && rowIndex < data?.stickyRows) return null
	if (data?.stickyCols > 0 && columnIndex < data?.stickyCols) return null
	return cellDataPreparation({ rIndex: rowIndex, cIndex: columnIndex, data, style, CellRender: CellRender })
}, areEqual)
