import { backend } from 'common/config/backend'
import { throwAPIError } from 'common/config/errors'

const FILE_NAME = 'tableApi'

export async function createColumn(token, tid, teamId, aid, column, type, typeProps, referenceColumns) {
	let config = { headers: { Authorization: 'Bearer ' + token } }
	let body = {tenantId: tid,  schema: teamId, table: aid, column, type, typeProps, referenceColumns }
	return backend
		.post('/tables/column', body, config)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, createColumn.name)
			return { data: resp.data?.data }
		})
		.catch((error) => {
			throwAPIError(error, error.response.status, FILE_NAME, createColumn.name)
		})
}

export async function updateColumn(token, tid, teamId, aid, column, type, prevType, typeProps, prevTypeProps, referenceColumns) {
	let config = { headers: { Authorization: 'Bearer ' + token } }
	let body = { tenantId: tid, schema: teamId, table: aid, column, type, prevType, typeProps, prevTypeProps, referenceColumns }
	return backend
		.patch('/tables/column', body, config)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, updateColumn.name)
			return { data: resp.data?.data }
		})
		.catch((error) => {
			throwAPIError(error, error.response.status, FILE_NAME, updateColumn.name)
		})
}

export async function deleteColumn(token, tid, teamId, aid, column, prevType, prevTypeProps, referenceColumns) {
	let config = {
		data: { tenantId: tid, schema: teamId, table: aid, column, prevType, prevTypeProps, referenceColumns },
		headers: { Authorization: 'Bearer ' + token }
	}
	return backend
		.delete('/tables/column', config)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, deleteColumn.name)
			return { data: resp.data?.data }
		})
		.catch((error) => {
			throwAPIError(error, error.response.status, FILE_NAME, deleteColumn.name)
		})
}
