import { call, takeEvery, put, select, spawn, all } from 'redux-saga/effects'
import * as api from 'common/api/communityApi'
import * as authApi from 'common/api/authApi'
import * as storeAction from 'common/store/communityReducer'
import * as sagaAction from 'common/saga-actions/communityActions'
import * as subscribeHelper from 'common/saga/helpers/subscribeHelper'
import * as globalStoreAction from 'common/store/globalReducer'
import * as globalSagaAction from 'common/saga-actions/globalActions'
import * as crudHelper from 'common/saga/helpers/crudHelper'
import * as loadHelper from 'common/saga/helpers/loadHelper'
import { createTeamExecute } from 'common/saga/teamSagas'
import { throwError } from 'common/config/errors'
import { t } from 'i18next'
import { createSpaceExecute } from './spaceSagas'

const FILE_NAME = 'communitySagas'

const getUid = (state) => state.auth.uid
const getCommunityAssets = (state) => state.community.assets?.result

// #### PATH TO THE COLLECTION
function defaultPath(includeDocId) {
	const path = [
		{ collection: 'tenants', param: 'tid', value: null },
		{ collection: 'community', param: includeDocId ? 'aid' : null, value: null }
	]
	return path
}

// #### SUBSCRIBE TO COMMUNITY ASSETS
function* loadCommunityAssetsReq() {
	const queryConfig = subscribeHelper.queryConfig({
		path: defaultPath(false),
		where: [{ attribute: 'teamId', operator: '==', param: 'teamId', value: null }],
		orderBy: [{ attribute: 'name', order: 'asc' }],
		returnType: 'map'
	})
	const sagaConfig = subscribeHelper.sagaConfig({
		loadAction: sagaAction.LOAD_COMMUNITY_ASSETS,
		loadResponse: storeAction.loadCommunityAssets,
		cancelAction: sagaAction.CANCEL_COMMUNITY_ASSETS,
		cancelResponse: storeAction.cancelCommunityAssets
	})
	yield spawn(subscribeHelper.subscribe, sagaConfig, queryConfig)
}

// #### LOAD COMMUNITY ASSET
function* loadCommunityAssetReq() {
	const queryConfig = loadHelper.queryConfig({ path: defaultPath(true) })
	const sagaConfig = loadHelper.sagaConfig({ loadAction: sagaAction.LOAD_COMMUNITY_ASSET, loadResponse: storeAction.loadCommunityAsset })
	yield spawn(loadHelper.load, sagaConfig, queryConfig)
}

// #### MANAGE COMMUNITY ASSET
function* updateCommunityAssetDirectReq() {
	const queryConfig = crudHelper.queryConfig({ path: defaultPath(true), returnType: 'doc' })
	const sagaConfig = crudHelper.sagaConfig({ loadAction: sagaAction.UPDATE_COMMUNITY_ASSET_DIRECT })
	const msgConfig = crudHelper.msgConfig({ fileName: FILE_NAME, functionName: updateCommunityAssetDirectReq.name })
	yield spawn(crudHelper.update, sagaConfig, queryConfig, msgConfig)
}

function* createCommunityAssetReq() {
	yield takeEvery(sagaAction.CREATE_COMMUNITY_ASSET, createCommunityAsset)
}

function* createCommunityAsset(triggeredAction) {
	try {
		const { tid, teamId, content, images } = triggeredAction
		const numItemsToSave = (images?.length || 0) + 1
		yield put(storeAction.initIsSaving({ items: numItemsToSave }))
		const uid = yield select(getUid)
		const newContent = { ...content, images: images.map((image) => image.id) }
		const { token } = yield call(authApi.getAuthToken)
		const { data } = yield call(api.createCommunityAsset, token, tid, uid, teamId, newContent)
		const aid = data.templateId
		yield all(images.map((image) => call(api.uploadFile, tid, teamId, aid, uid, image)))
		yield put(storeAction.reduceIsSaving())
	} catch (err) {
		const content = throwError(err, FILE_NAME, createCommunityAsset.name)
		yield put(globalSagaAction.showMessage({ content }))
		yield put(storeAction.cancelIsSaving())
	}
}

function* updateCommunityAssetReq() {
	yield takeEvery(sagaAction.UPDATE_COMMUNITY_ASSET, updateCommunityAsset)
}

function* updateCommunityAsset(triggeredAction) {
	try {
		const { tid, teamId, aid, content, images } = triggeredAction
		const uid = yield select(getUid)
		const _communityAssets = yield select(getCommunityAssets)
		const communityAsset = _communityAssets[aid]

		// Identify changes in images
		const prevImages = communityAsset.images || []
		const nextImages = images.map((image) => image.id) || []
		const imagesToRemove = prevImages?.filter((id) => !nextImages.includes(id))
		const imagesToAdd = images?.filter((image) => !prevImages.includes(image.id))

		// Set loading on
		const numItemsToSave = (imagesToAdd?.length || 0) + 1
		yield put(storeAction.initIsSaving({ items: numItemsToSave }))

		let imagesURL = communityAsset.imagesURL ? { ...communityAsset.imagesURL } : {}
		imagesToRemove?.forEach((id) => (imagesURL[id] = null)) // Needs to be set to null to allow concurrent updates

		// Save community document
		const newContent = { ...content, images: nextImages, imagesURL }
		const { token } = yield call(authApi.getAuthToken)
		yield call(api.updateCommunityAsset, token, tid, uid, teamId, aid, newContent)

		// Save image changes
		yield all(imagesToAdd?.map((image) => call(api.uploadFile, tid, teamId, aid, uid, image)))
		yield all(imagesToRemove?.map((id) => call(api.deleteFile, tid, teamId, aid, id, false)))
		yield put(storeAction.reduceIsSaving())
	} catch (err) {
		const content = throwError(err, FILE_NAME, updateCommunityAsset.name)
		yield put(globalSagaAction.showMessage({ content }))
		yield put(storeAction.cancelIsSaving())
	}
}

function* deleteCommunityAssetReq() {
	yield takeEvery(sagaAction.DELETE_COMMUNITY_ASSET, deleteCommunityAsset)
}

function* deleteCommunityAsset(triggeredAction) {
	const { tid, teamId, aid } = triggeredAction
	try {
		yield put(globalStoreAction.loadingOn({ key: 'deleteCommunityAsset', item: aid }))
		const _communityAssets = yield select(getCommunityAssets)
		const communityAsset = _communityAssets[aid]
		const images = communityAsset.images || []
		const { token } = yield call(authApi.getAuthToken)
		yield call(api.deleteCommunityAsset, token, tid, teamId, aid)
		yield all(images?.map((id) => call(api.deleteFile, tid, teamId, aid, id, false)))
	} catch (err) {
		const content = throwError(err, FILE_NAME, deleteCommunityAsset.name)
		yield put(globalSagaAction.showMessage({ content }))
	} finally {
		yield put(globalStoreAction.loadingOff({ key: 'deleteCommunityAsset', item: aid }))
	}
}

// #### UPLOAD IMAGE
function* fileTransmitSuccessReq() {
	yield takeEvery(sagaAction.FILE_TRASMIT_SUCCESS, fileTransmitSuccess)
}

function* fileTransmitSuccess(triggeredAction) {
	const { tid, aid, fileId, downloadUrl } = triggeredAction
	try {
		yield put(sagaAction.updateCommunityAssetDirect({ tid, aid, content: { [`imagesURL.${fileId}`]: downloadUrl } }))
		yield put(storeAction.reduceIsSaving())
	} catch (err) {
		const content = throwError(err, FILE_NAME, fileTransmitSuccess.name)
		yield put(globalSagaAction.showMessage({ content }))
		yield put(storeAction.cancelIsSaving())
	}
}

// #### USE COMMUNITY ASSET
function* copyCommunityAssetReq() {
	yield takeEvery(sagaAction.COPY_COMMUNITY_ASSET, copyCommunityAsset)
}

function* copyCommunityAsset(triggeredAction) {
	const { tid, aid, targetTeamId, targetTeamName, targetSpaceName } = triggeredAction
	try {
		yield put(globalStoreAction.loadingOn({ key: 'useTemplate' }))
		var teamId = targetTeamId
		var spaceId;
		// Create team if it does not exist
		const uid = yield select(getUid)
		if (!teamId) teamId = yield call(createTeamExecute, tid, uid, targetTeamName)

		if (targetSpaceName) spaceId = yield call(createSpaceExecute, tid, uid, teamId, { name: targetSpaceName })
		// Copy template
		const { token } = yield call(authApi.getAuthToken)
		yield call(api.copyCommunityAsset, token, tid, teamId, uid, aid, spaceId)
		// Message to trigger a redirect
		yield put(globalSagaAction.showMessage({ key: 'useTemplateSuccess', content: 'Success', messageType: 'success' }))
		// Glonal message to inform user
		yield put(globalSagaAction.showMessage({ content: t('common:success.saved'), messageType: 'success' }))
	} catch (err) {
		const content = throwError(err, FILE_NAME, copyCommunityAsset.name)
		yield put(globalSagaAction.showMessage({ content }))
	} finally {
		yield put(globalStoreAction.loadingOff({ key: 'useTemplate' }))
	}
}

export default function* root() {
	// #### SUBSCRIBE TO COMMUNITY ASSETS
	yield spawn(loadCommunityAssetsReq)
	// #### LOAD COMMUNITY ASSET
	yield spawn(loadCommunityAssetReq)
	// #### MANAGE COMMUNITY ASSET
	yield spawn(updateCommunityAssetDirectReq)
	yield spawn(createCommunityAssetReq)
	yield spawn(updateCommunityAssetReq)
	yield spawn(deleteCommunityAssetReq)
	// #### UPLOAD IMAGE
	yield spawn(fileTransmitSuccessReq)
	// #### USE COMMUNITY ASSET
	yield spawn(copyCommunityAssetReq)
}
