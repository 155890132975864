// #### SUBSCRIBE TO TABLE
export const LOAD_TABLE = 'tableSaga/LOAD_TABLE'
export const CANCEL_TABLE = 'tableSaga/CANCEL_TABLE'
// #### MANAGE TABLE
export const UPDATE_TABLE = 'tableSaga/UPDATE_TABLE'
export const DELETE_TAB = 'tableSaga/DELETE_TAB'
// #### ROW AND COLUMN OPERATIONS
export const ADD_COLUMN = 'tableSaga/ADD_COLUMN'
export const DELETE_COLUMN = 'tableSaga/REMOVE_COLUMN'
export const MOVE_COLUMN = 'tableSaga/MOVE_COLUMN'
export const CHANGE_COLUMN_TAB = 'tableSaga/CHANGE_COLUMN_TAB'
export const CHANGE_TYPE = 'tableSaga/CHANGE_TYPE'
// #### DROP-DOWN SELECTION
export const LOAD_SELECTED_TABLE = 'tableSaga/LOAD_SELECTED_TABLE'
export const SUBSCRIBE_SELECTED_TABLE = 'tableSaga/SUBSCRIBE_SELECTED_TABLE'
export const UNSUBSCRIBE_SELECTED_TABLE = 'tableSaga/UNSUBSCRIBE_SELECTED_TABLE'
// #### SYNC TABLES
export const CONNECT_TABLE = 'tableSaga/CONNECT_TABLE'
export const DISCONNECT_TABLE = 'tableSaga/DISCONNECT_TABLE'

// #### SUBSCRIBE TO TABLE
export const loadTable = ({ tid, aid }) => {
	return { type: LOAD_TABLE, tid, aid, id: aid }
}

export const cancelTable = ({ id }) => {
	return { type: CANCEL_TABLE, id }
}

// #### MANAGE TABLE
export const updateTable = ({ tid, aid, content }) => {
	return { type: UPDATE_TABLE, tid, aid, content }
}

export const deleteTab = ({ tid, aid, tabIndex }) => {
	return { type: DELETE_TAB, tid, aid, tabIndex }
}

// #### ROW AND COLUMN OPERATIONS
export const addColumn = ({ tid, teamId, aid, rowUpdateId, rowUpdateIndex, rowUpdateValue }) => {
	return { type: ADD_COLUMN, tid, teamId, aid, rowUpdateId, rowUpdateIndex, rowUpdateValue }
}

export const deleteColumn = ({ tid, teamId, aid, colId }) => {
	return { type: DELETE_COLUMN, tid, teamId, aid, colId }
}

export const moveColumn = ({ tid, teamId, aid, dragId, dragIndex, dropIndex }) => {
	return { type: MOVE_COLUMN, tid, teamId, aid, dragId, dragIndex, dropIndex }
}

export const changeColumnTab = ({ tid, teamId, aid, colId, destTabId }) => {
	return { type: CHANGE_COLUMN_TAB, tid, teamId, aid, colId, destTabId }
}

export const changeType = ({ tid, teamId, aid, varIdOrigin, varIdDest, typeOrigin, typeDestination, typePropsOrigin, typePropsDest }) => {
	return { type: CHANGE_TYPE, tid, teamId, aid, varIdOrigin, varIdDest, typeOrigin, typeDestination, typePropsOrigin, typePropsDest }
}

// #### DROP-DOWN SELECTION
export const loadSelectedTable = ({ key, tid, aid }) => {
	return { type: LOAD_SELECTED_TABLE, key, tid, aid }
}

export const subscribeSelectedTable = ({ tid, aid }) => {
	return { type: SUBSCRIBE_SELECTED_TABLE, tid, aid }
}

export const unsubscribeSelectedTable = ({ aid }) => {
	return { type: UNSUBSCRIBE_SELECTED_TABLE, aid }
}
