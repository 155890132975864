export const FREQUENCY = {
	// hour: {
	// 	key: 'hour',
	// 	label: 'model:frequencies.hour',
	// 	maxVisualizaton: 168,
	// index: 0,
	// mask: 'mask'
	// },
	day: {
		key: 'day',
		label: 'model:frequencies.day',
		maxVisualizaton: 182,
		index: 1,
		mask: 'mask',
		friendlyMask: 'friendly'
	},
	week: {
		key: 'week',
		label: 'model:frequencies.week',
		maxVisualizaton: 104,
		index: 2,
		mask: 'mask',
		friendlyMask: 'friendly'
	},
	month: {
		key: 'month',
		label: 'model:frequencies.month',
		maxVisualizaton: 60,
		index: 3,
		mask: 'maskMonth',
		friendlyMask: 'friendlyMonth'
	},
	year: {
		key: 'year',
		label: 'model:frequencies.year',
		maxVisualizaton: 30,
		index: 4,
		mask: 'maskYear',
		friendlyMask: 'maskYear'
	}
}
