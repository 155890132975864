import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { closeMessage } from 'common/store/globalReducer'
import { Snackbar, Alert as AlertLib } from '@mui/material'

export default function AlertDialog({ messageKey }) {
	const dispatch = useDispatch()
	const messageStore = useSelector((state) => state.global.message[messageKey])

	const [message, setMessage] = useState(null)

	useEffect(() => {
		if (messageStore?.isVisible) setMessage({ ...messageStore })
	}, [messageStore])

	function onClose() {
		dispatch(closeMessage({ key: messageKey }))
	}

	return (
		<Snackbar open={messageStore?.isVisible} onClose={onClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
			<AlertLib onClose={onClose} severity={message?.type} className="text-sm w-full whitespace-nowrap">
				{message?.content}
			</AlertLib>
		</Snackbar>
	)
}
