export const LIMITS_TEAM = {
	users: { key: 'users', label: 'common:limit.users', unit: null },
	tables: { key: 'tables', label: 'common:limit.tables', unit: null },
	models: { key: 'models', label: 'common:limit.models', unit: null },
	connectors: { key: 'connectors', label: 'common:limit.connectors', unit: null },
	storage: { key: 'storage', label: 'common:limit.storage', unit: 'common:limit.storageUnit' },
	categories: { key: 'categories', label: 'common:limit.categories', unit: null },
	categoryItems: { key: 'categoryItems', label: 'common:limit.categoryItems', unit: null }
}

export const LIMITS_TABLE = {
	rows: { key: 'rows', label: 'common:limit.rows', unit: null }
}

export const LIMITS_MODEL = {
	variables: { key: 'variables', label: 'common:limit.variables', unit: null },
	timesteps: { key: 'timesteps', label: 'common:limit.timesteps', unit: null }
}

export const TEAM_STATUS = {
	active: { key: 'active', label: null },
	warning: { key: 'warning', label: 'common:error.teamLimitsWarning' },
	blocked: { key: 'blocked', label: 'common:error.teamLimitsWarning' } // teamLimitsBlock
}
