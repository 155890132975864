import { storage } from 'common/config/firebase'
import { ref as storageRef, uploadString, getDownloadURL, deleteObject, listAll } from 'firebase/storage'
import { backend } from 'common/config/backend'
import store from 'common/store/store'
import { fileTransmitSuccess } from 'common/saga-actions/assetActions'
import { throwAPIError } from 'common/config/errors'

const FILE_NAME = 'assetApi'

export async function createAsset(token, tid, uid, teamId, content, defaults, view) {
	const params = { tenantId: tid, userId: uid, teamId, content, defaults, view }
	const headers = { headers: { Authorization: 'Bearer ' + token } }
	return backend
		.post('/assets', params, headers)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, createAsset.name)
			return { data: resp.data?.data }
		})
		.catch((error) => {
			throwAPIError(error, error.response.status, FILE_NAME, createAsset.name)
		})
}

export async function updateAsset(token, tenantId, userId, assetId, content) {
	const params = { tenantId, userId, assetId, content }
	const headers = { headers: { Authorization: 'Bearer ' + token } }
	return backend
		.patch('/assets', params, headers)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, updateAsset.name)
			return { data: resp.data?.data }
		})
		.catch((error) => {
			throwAPIError(error, error.response.status, FILE_NAME, updateAsset.name)
		})
}

export async function deleteAsset(token, tid, aid) {
	const params = { data: { tenantId: tid, assetId: aid }, headers: { Authorization: 'Bearer ' + token } }
	return backend
		.delete('/assets', params)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, deleteAsset.name)
			return { data: resp.data?.data }
		})
		.catch((error) => {
			throwAPIError(error, error.response.status, FILE_NAME, deleteAsset.name)
		})
}

export async function copyAsset(token, tid, teamId, aid) {
	const params = { tenantId: tid, teamId, assetId: aid }
	const headers = { headers: { Authorization: 'Bearer ' + token } }
	return backend
		.post('/assets/copy', params, headers)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, copyAsset.name)
			return { data: resp.data?.data }
		})
		.catch((error) => {
			throwAPIError(error, error.response.status, FILE_NAME, copyAsset.name)
		})
}

export async function inviteUser(token, tenantId, teamId, userId, assetId, email, role) {
	const params = { tenantId, teamId, userId, assetId, email, role }
	const headers = { headers: { Authorization: 'Bearer ' + token } }
	return backend
		.post('/assets/share', params, headers)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, inviteUser.name)
			return { data: resp.data?.data }
		})
		.catch((error) => {
			throwAPIError(error, error.response.status, FILE_NAME, inviteUser.name)
		})
}

export async function updateUser(token, tenantId, teamId, assetId, uid, role) {
	const params = { tenantId, teamId, assetId, uid, role }
	const headers = { headers: { Authorization: 'Bearer ' + token } }
	return backend
		.patch('/assets/share', params, headers)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, updateUser.name)
			return { data: resp.data?.data }
		})
		.catch((error) => {
			throwAPIError(error, error.response.status, FILE_NAME, updateUser.name)
		})
}

export async function deleteUser(token, tenantId, teamId, assetId, userId) {
	const params = { data: { tenantId, teamId, assetId, uid: userId }, headers: { Authorization: 'Bearer ' + token } }
	return backend
		.delete('/assets/share', params)
		.then((resp) => {
			if (resp.status !== 200) throwAPIError(null, resp.status, FILE_NAME, deleteUser.name)
			return { data: resp.data?.data }
		})
		.catch((error) => {
			throwAPIError(error, error.response.status, FILE_NAME, deleteUser.name)
		})
}

export async function uploadFile(tid, aid, uid, file, filePath, fileType) {
	const metadata = { contentType: fileType, customMetadata: { uid } }
	const fileRef = storageRef(storage, filePath)
	return uploadString(fileRef, file, 'base64', metadata)
		.then((snapshot) => getDownloadURL(snapshot.ref).then((downloadUrl) => store.dispatch(fileTransmitSuccess({ tid, aid, filePath, downloadUrl }))))
		.catch((error) => throwAPIError(error, null, FILE_NAME, uploadFile.name))
}

export async function deleteFolder(folderPath) {
	const listRef = storageRef(storage, folderPath)
	return listAll(listRef)
		.then((res) => {
			res.items.forEach((fileRef) => {
				deleteObject(fileRef)
					.then(() => {})
					.catch((error) => throwAPIError(error, null, deleteFolder.name))
			})
		})
		.catch((error) => throwAPIError(error, null, FILE_NAME, deleteFolder.name))
}
