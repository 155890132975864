import { call, takeEvery, spawn, select, put } from 'redux-saga/effects'
import * as api from 'common/api/billingApi'
import * as authApi from 'common/api/authApi'
import * as sagaAction from 'common/saga-actions/billingActions'
import * as storeAction from 'common/store/billingReducer'
import * as globalStoreAction from 'common/store/globalReducer'
import * as globalSagaAction from 'common/saga-actions/globalActions'
import * as subscribeHelper from 'common/saga/helpers/subscribeHelper'
import { throwError } from 'common/config/errors'

const FILE_NAME = 'billingSagas'

const getEmail = (state) => state.auth.email

// #### PATH TO THE COLLECTION
function defaultCustomer(includeDocId) {
	const path = [
		{ collection: 'tenants', param: 'tid', value: null },
		{ collection: 'customers', param: includeDocId ? 'cid' : null, value: null }
	]
	return path
}

function defaultBillableUsers(includeDocId) {
	const path = [
		{ collection: 'tenants', param: 'tid', value: null },
		{ collection: 'teams', param: 'teamId', value: null },
		{ collection: 'billables', param: includeDocId ? 'uid' : null, value: null }
	]
	return path
}

// #### SUBSCRIBE TO CUSTOMER
function* loadCustomerReq() {
	const queryConfig = subscribeHelper.queryConfig({ path: defaultCustomer(true), returnType: 'doc' })
	const sagaConfig = subscribeHelper.sagaConfig({
		loadAction: sagaAction.LOAD_CUSTOMER,
		loadResponse: storeAction.loadCustomer,
		cancelAction: sagaAction.CANCEL_CUSTOMER,
		cancelResponse: storeAction.cancelCustomer
	})
	yield spawn(subscribeHelper.subscribe, sagaConfig, queryConfig)
}

// #### SUBSCRIBE TO BILLABLE USERS
function* loadBillableUsersReq() {
	const queryConfig = subscribeHelper.queryConfig({ path: defaultBillableUsers(false), returnType: 'list' })
	const sagaConfig = subscribeHelper.sagaConfig({
		loadAction: sagaAction.LOAD_BILLABLE_USERS,
		loadResponse: storeAction.loadBillableUsers,
		cancelAction: sagaAction.CANCEL_BILLABLE_USERS,
		cancelResponse: storeAction.cancelBillableUsers
	})
	yield spawn(subscribeHelper.subscribe, sagaConfig, queryConfig)
}

// #### CHECKOUT
function* checkoutPlanReq() {
	yield takeEvery(sagaAction.CHECKOUT_PLAN, checkoutPlan)
}

function* checkoutPlan(triggeredAction) {
	const { tid, teamId, customerId, planId, priceId, currency, frequency, units } = triggeredAction
	try {
		yield put(globalStoreAction.loadingOn({ key: 'checkout' }))
		const email = yield select(getEmail)
		const { token } = yield call(authApi.getAuthToken)
		const { url } = yield call(api.checkoutPlan, token, tid, teamId, customerId, email, planId, priceId, currency, frequency, units)
		if (url) window.location.href = url
	} catch (err) {
		const content = throwError(err, FILE_NAME, checkoutPlan.name)
		yield put(globalSagaAction.showMessage({ content }))
	} finally {
		yield put(globalStoreAction.loadingOff({ key: 'checkout' }))
	}
}

function* checkoutAddonReq() {
	yield takeEvery(sagaAction.CHECKOUT_ADDON, checkoutAddon)
}

function* checkoutAddon(triggeredAction) {
	const { tid, teamId, customerId, addonId, priceId, currency, frequency, units } = triggeredAction
	try {
		yield put(globalStoreAction.loadingOn({ key: 'checkout' }))
		const email = yield select(getEmail)
		const { token } = yield call(authApi.getAuthToken)
		const { url } = yield call(api.checkoutAddon, token, tid, teamId, customerId, email, addonId, priceId, currency, frequency, units)
		if (url) window.location.href = url
	} catch (err) {
		const content = throwError(err, FILE_NAME, checkoutAddon.name)
		yield put(globalSagaAction.showMessage({ content }))
	} finally {
		yield put(globalStoreAction.loadingOff({ key: 'checkout' }))
	}
}

function* customerPortalReq() {
	yield takeEvery(sagaAction.CUSTOMER_PORTAL, customerPortal)
}

// #### CUSTOMER PORTAL
function* customerPortal(triggeredAction) {
	const { tid, teamId, cid } = triggeredAction
	try {
		yield put(globalStoreAction.loadingOn({ key: 'customerPortal' }))
		const { token } = yield call(authApi.getAuthToken)
		const { url } = yield call(api.customerPortal, token, tid, teamId, cid)
		if (url) window.location.href = url
	} catch (err) {
		const content = throwError(err, FILE_NAME, customerPortal.name)
		yield put(globalSagaAction.showMessage({ content }))
	} finally {
		yield put(globalStoreAction.loadingOff({ key: 'customerPortal' }))
	}
}

export default function* root() {
	// #### SUBSCRIBE TO CUSTOMER
	yield spawn(loadCustomerReq)
	// #### SUBSCRIBE TO BILLABLE USERS
	yield spawn(loadBillableUsersReq)
	// #### CHECKOUT
	yield spawn(checkoutPlanReq)
	yield spawn(checkoutAddonReq)
	// #### CUSTOMER PORTAL
	yield spawn(customerPortalReq)
}
