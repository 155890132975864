import { io } from 'socket.io-client'
import * as actions from 'model/saga-actions/vizMiddlewareActions'
import { loadBreakdown, loadChart, loadData, loadErrors, setVizReady } from 'model/store/modelDataReducer'
import * as socketActions from 'model/saga-actions/socketActions'
import { loadVisualizationSocket } from 'model/saga-actions/modelVizActions'

const VizMiddleware = () => {
	let socket = {}

	const onError = (error, store, payload) => {
		if (error === '401') {
			store.dispatch(setVizReady({ id: payload.aid, isVizReady: false }))
			store.dispatch(loadVisualizationSocket({ tid: payload.tid, teamId: payload.teamId, aid: payload.aid }))
		}
	}

	const onMessage = (store, payload) => {
		switch (payload.type) {
			case 'new_row_updated':
				store.dispatch(actions.updateRowData({ ...payload, updateCharts: true }))
				break
			case 'row_received':
				if (payload?.isBreakdown) store.dispatch(loadBreakdown(payload))
				else store.dispatch(loadData(payload))
				break
			case 'chart_received':
				store.dispatch(loadChart(payload))
				break
			case 'row_errors':
				store.dispatch(loadErrors(payload))
				break
			default:
				break
		}
	}

	// the middleware part of this function
	return (store) => (next) => (action) => {
		switch (action.type) {
			case socketActions.WS_VIZ_CONNECT:
				if (socket[action.aid] && socket[action.aid].connected) socket[action.aid].disconnect()

				// connect to the remote host
				const uri = process.env.REACT_APP_VIZ_WEBSOCKET_ENDPOINT

				socket[action.aid] = io(uri, {
					path: '/viz',
					reconnectionAttempts: 5,
					auth: { token: action.token },
					transports: [ "websocket" ],
					withCredentials: true,
					query: {
						tenantId: action.tid,
						teamId: action.teamId,
						modelId: action.aid
					}
				})

				//join to the model
				socket[action.aid].emit('join', { tenantId: action.tid, teamId: action.teamId, modelId: action.aid })

				// websocket handlers
				socket[action.aid].on('connect_error', (error) => onError(error.message, store, action))
				socket[action.aid].onAny((event, payload) => onMessage(store, { type: event, ...payload }))

				store.dispatch(setVizReady({ id: action.aid, isVizReady: true }))
				break
			case socketActions.WS_VIZ_DISCONNECT:
				if (socket[action.aid]) {
					if (socket[action.aid].connected) socket[action.aid].disconnect()
					delete socket[action.aid]
					store.dispatch(setVizReady({ id: action.aid, isVizReady: false }))
				}
				break
			case actions.GET_ROW_DATA:
				if (socket[action.aid]) socket[action.aid].emit('get_row', { tenantId: action.tid, teamId: action.teamId, modelId: action.aid, viewId: action.viewId, config: action.config })
				break
			case actions.GET_CHART_DATA:
				if (socket[action.aid]) socket[action.aid].emit('get_chart', { tenantId: action.tid, teamId: action.teamId, modelId: action.aid, chartId: action.cid, viewId: action.viewId, config: action.config })
				break
			default:
				return next(action)
		}
	}
}

export default VizMiddleware()
