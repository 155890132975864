import clsx from 'clsx'
import { Autocomplete, InputBase } from '@mui/material'
import { useFirstRender } from 'common/hooks/useFirstRender'
import { memo, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { throttle } from 'throttle-debounce'
import useLazyLoading from 'common/hooks/useLazyLoading'

export default memo(function CreateSearchCell({ item, setItem, focus = false, cancel = false, typeProps, config: { alignRight = false, parseUserInput, formatBlurredInput, parsePastedValue, textWrap = false }, search, className }) {
	const popperRef = useRef()
	const firstRender = useFirstRender()

	// Info to make the search
	const selectTable = typeProps?.selectTable
	const selectVariable = typeProps?.selectVariable

	// Search props
	const searchResults = search?.searchResults
	const onSearchRef = search?.onSearchRef
	const onCancelSearchRef = search?.onCancelSearchRef
	const results = searchResults?.result
	const nextPage = searchResults?.nextPage
	const isLoading = searchResults?.isLoading
	const notFound = searchResults?.notFound

	// #### STATE
	const [currentFocus, setCurrentFocus] = useState({ is: false, cancel: false })
	const [prevFocus, setPrevFocus] = useState({ is: false, cancel: false })
	const [selectedValue, setSelectedValue] = useState(null)
	const [inputValue, setInputValue] = useState('')
	const [isPopperOpen, setIsPopperOpen] = useState(false)
	const [collection, setCollection] = useState([])

	// Update focus
	useLayoutEffect(() => {
		setPrevFocus(currentFocus)
		setCurrentFocus({ is: focus, cancel: cancel })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [focus])

	// Update item when it changes
	function initialize() {
		if (!item) return
		setInputValue(item?.value || '')
		setSelectedValue(item)
	}

	useEffect(() => {
		initialize()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [item])

	// Update collection when it changes
	useEffect(() => {
		var newCollection = results ? [...results] : []
		// Add selected item to results if it is not there - needed for autocomplete to work properly
		const hasSelectedValue = (selectedValue && newCollection.findIndex((el) => el.id === selectedValue?.id) >= 0) || false
		if (selectedValue?.id != null && !hasSelectedValue) newCollection.push(selectedValue)
		setCollection(newCollection)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [results, selectedValue])

	// Save results when focus is lost
	useLayoutEffect(() => {
		if (!currentFocus.is && !firstRender && prevFocus.is) {
			if (!currentFocus.cancel) {
				if (item?.id !== selectedValue?.id) setItem(selectedValue)
				// setItem(selectedValue?.id || null)
			} else initialize()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentFocus])

	// Search throttle
	const searchThrottled = throttle(200, (inputValue, isInitial, startAfter, selectTable, selectVariable) => {
		onSearchRef.current(inputValue, isInitial, startAfter, selectTable, selectVariable)
	})

	// Lazy loading
	const triggerFetch = useLazyLoading(() => onSearchRef.current(inputValue, false, nextPage, selectTable, selectVariable), isLoading, notFound)

	// Trigger search when input value changes
	useEffect(() => {
		if (currentFocus.is) searchThrottled(inputValue, true, null, selectTable, selectVariable)
		else if (prevFocus.is) onCancelSearchRef.current()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentFocus, inputValue])

	// If select popper is open, do not propagate clicks to parent components
	const onMouseDown = useCallback((e) => {
		if (popperRef?.current) {
			if (e.preventDefault) e.preventDefault()
			if (e.stopPropagation) e.stopPropagation()

			const clickInside = popperRef?.current?.state?.elements?.popper?.contains(e.target)
			if (!clickInside) setIsPopperOpen(false)
			return
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// If cell is focused, attach an onMouseDown event to the cell to prevent clicks from propagating to parent components
	useEffect(() => {
		if (currentFocus.is) document.addEventListener('mousedown', onMouseDown, true)
		return () => {
			document.removeEventListener('mousedown', onMouseDown, true)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentFocus])

	// ###########################
	// #### RENDER
	// ###########################
	if (currentFocus.is) {
		return (
			<Autocomplete
				componentsProps={{ popper: { popperRef: popperRef } }}
				open={isPopperOpen}
				options={collection}
				getOptionLabel={(item) => item.value || ''}
				isOptionEqualToValue={(option, value) => option?.id === value?.id}
				value={selectedValue || null}
				inputValue={inputValue}
				onChange={(e, value) => setSelectedValue(value)}
				onInputChange={(e, value) => setInputValue(value)}
				// loading={isLoading}
				renderInput={({ inputProps, InputProps }) => (
					<InputBase
						inputRef={InputProps.ref}
						autoFocus
						fullWidth
						margin="none"
						inputProps={{ ...inputProps, className: 'text-sm px-2 py-1' }}
						endAdornment={InputProps.endAdornment}
						onFocus={(e) => {
							e.target.select()
						}}
						classes={{ root: 'flex items-center justify-center' }}
					/>
				)}
				renderOption={(props, option, { selected }) => (
					<li {...props} key={option.id}>
						<span ref={collection?.length > 0 && option?.id === collection[collection.length - 1]?.id ? triggerFetch : undefined}>{option.value}</span>
					</li>
				)}
				classes={{ root: 'flex-1', listbox: 'text-sm', noOptions: 'text-sm' }}
				openOnFocus
				onOpen={() => setIsPopperOpen(true)}
				onClose={() => setIsPopperOpen(false)}
			/>
		)
	} else return <span className={clsx("text-sm", textWrap ? 'whitespace-normal break-normal' : 'text-ellipsis overflow-hidden', className)}>{item?.value}</span>
})
