// #### AUTHENTICATION
export const AUTHENTICATE = 'authSaga/AUTHENTICATE'
export const AUTHENTICATE_RES = 'authSaga/AUTHENTICATE_RES'
export const CANCEL_AUTHENTICATE = 'authSaga/CANCEL_AUTHENTICATE'
export const LOGOUT = 'authSaga/LOGOUT'
export const LOGIN_EMAIL = 'authSaga/LOGIN_EMAIL'
export const RESET_PWD = 'authSaga/RESET_PWD'
export const SIGNUP = 'authSaga/SIGNUP'
export const SIGNIN_WITH_GOOGLE = 'authSaga/SIGNIN_WITH_GOOGLE'

// #### AUTHENTICATION
export const authenticate = () => {
	return { type: AUTHENTICATE }
}

export const authenticateRes = () => {
	return { type: AUTHENTICATE_RES }
}

export const cancelAuthenticate = () => {
	return { type: CANCEL_AUTHENTICATE }
}

export const logout = () => {
	return { type: LOGOUT }
}

export const loginEmail = ({ email, password }) => {
	return { type: LOGIN_EMAIL, email, password }
}

export const resetPassword = ({ email }) => {
	return { type: RESET_PWD, email }
}

export const signup = ({ name, surname, email, password, locale }) => {
	return { type: SIGNUP, name, surname, email, password, locale }
}

export const signInWithGoogle = (locale) => {
	return { type: SIGNIN_WITH_GOOGLE, locale }
}
