// #### SUBSCRIBE TO SPACES
export const LOAD_SPACES = 'spaceSaga/LOAD_SPACES'
export const CANCEL_SPACES = 'spaceSaga/CANCEL_SPACES'
// #### MANAGE SPACES
export const CREATE_SPACE = 'spaceSaga/CREATE_SPACE'
export const UPDATE_SPACE = 'spaceSaga/UPDATE_SPACE'
export const DELETE_SPACE = 'spaceSaga/DELETE_SPACE'

// #### SUBSCRIBE TO SPACES
export const loadSpaces = ({ tid, uid, teamId }) => {
	return { type: LOAD_SPACES, tid, uid, teamId }
}

export const cancelSpaces = () => {
	return { type: CANCEL_SPACES }
}

// #### MANAGE SPACES
export const createSpace = ({ content }) => {
	return { type: CREATE_SPACE, content }
}

export const updateSpace = ({ sid, content }) => {
	return { type: UPDATE_SPACE, sid, content }
}

export const deleteSpace = ({ sid }) => {
	return { type: DELETE_SPACE, sid }
}
