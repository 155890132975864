import { useState } from 'react'
import Signup from 'common/screens/auth/Signup'
import Login from 'common/screens/auth/Login'
import { Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'

export default function Authentication() {
	const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)

	const [page, setPage] = useState('login')

	function goToLogin() {
		setPage('login')
	}

	function goToSignup() {
		setPage('signup')
	}

	if (isLoggedIn) return <Outlet />
	else if (page === 'signup') return <Signup loginLink={goToLogin} />
	else return <Login signupLink={goToSignup} />
}
