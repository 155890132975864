import { t } from 'i18next'

export const CHART_TYPES = {
	line: { key: 'line', label: t('common:chartTypes.line'), varLabel: t('common:chartVarTypes.line'), chartJsType: 'line' },
	bar: { key: 'bar', label: t('common:chartTypes.bar'), varLabel: t('common:chartVarTypes.bar'), chartJsType: 'bar' }
}

export const CHART_AXIS = {
	left: { key: 'left', label: t('common:chartAxis.left') },
	right: { key: 'right', label: t('common:chartAxis.right') }
}

export const CHART_SHAPES = {
	straight: { key: 'straight', label: t('common:chartShapes.straight') },
	curve: { key: 'curve', label: t('common:chartShapes.curve') }
}

export const CHART_STACK = {
	no: { key: 'no', label: t('common:chartStack.no') },
	categories: { key: 'categories', label: t('common:chartStack.categories') },
	variables: { key: 'variables', label: t('common:chartStack.variables') }
}
