import clsx from 'clsx'
import { useRef, useCallback, useEffect } from 'react'
import { useResize } from 'common/hooks/useResize'
import AutoSizer from 'react-virtualized-auto-sizer'

export default function PanelLayout({ config, content, onResize, children, blockScroll = false }) {
	const isOpen = config?.isOpen || false
	const direction = config?.direction || 'horizontal'
	const position = config?.position || 'right'
	const size = config?.size || 50
	const isFirst = position === 'left' || position === 'top'

	// #### REFS
	const panelRef = useRef()
	const panelParentRef = useRef()

	// #### EFFECTS - INITIALIZE
	// Keep in sync with the store
	useEffect(() => {
		if (isOpen) setPanelSize(size)
	}, [size])

	// #### EFFECTS - CONTROL PANEL ANIMATION
	function afterTransition() {
		panelRef.current.removeEventListener('transitionend', afterTransition)
		panelRef.current.classList.remove('transition-[width]')
		panelRef.current.classList.remove('transition-[height]')
		if (!isOpen) {
			panelRef.current.style.height = '0px'
			panelRef.current.style.width = '0px'
		}
	}

	useEffect(() => {
		if (!panelRef.current) return
		const panel = panelRef.current
		if (isOpen && direction === 'horizontal') {
			panelRef.current.classList.add('transition-[width]')
			panelRef.current.style.width = `${size || 50}%`
			panelRef.current.style.height = '100%'
		} else if (isOpen && direction === 'vertical') {
			panelRef.current.classList.add('transition-[height]')
			panelRef.current.style.height = `${size || 50}%`
			panelRef.current.style.width = '100%'
		} else if (!isOpen && direction === 'horizontal') {
			panelRef.current.classList.add('transition-[width]')
			panelRef.current.style.width = '0px'
		} else if (!isOpen && direction === 'vertical') {
			panelRef.current.classList.add('transition-[height]')
			panelRef.current.style.height = '0px'
		}
		panel.addEventListener('transitionend', afterTransition)
		return () => panel.removeEventListener('transitionend', afterTransition)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen, direction])

	// #### SCREEN SPLIT RESIZING
	const getPanelSize = useCallback(() => {
		return {
			size: parseInt(getComputedStyle(panelRef.current, '')[direction === 'horizontal' ? 'width' : 'height']),
			parentSize: parseInt(getComputedStyle(panelParentRef.current, '')[direction === 'horizontal' ? 'width' : 'height'])
		}
	}, [panelRef, direction])

	const setPanelSize = useCallback((size) => (panelRef.current.style[direction === 'horizontal' ? 'width' : 'height'] = `${size}%`), [panelRef, direction])

	const changePanelSize = useCallback(
		(dx) => {
			let { size, parentSize } = getPanelSize()
			const signedDx = position === 'left' || position === 'top' ? -dx : dx
			size += signedDx
			const percentSize = (size / parentSize) * 100.0
			const limitedPercentSize = Math.max(Math.min(percentSize, 90), 10)
			setPanelSize(limitedPercentSize)
		},
		[getPanelSize, setPanelSize, position]
	)

	const savePanelSize = useCallback(() => {
		const { size, parentSize } = getPanelSize()
		const percentSize = (size / parentSize) * 100.0
		onResize(percentSize)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getPanelSize, onResize])

	const { onMouseDown: onPanelResize } = useResize(changePanelSize, savePanelSize, direction, position)

	return (
		<div ref={panelParentRef} className={clsx('flex-1 relative flex w-full h-full', direction === 'horizontal' ? 'flex-row' : 'flex-col')}>
			{/* Content panel 1 */}
			<div className={clsx('flex-auto relative', isFirst ? 'order-2' : 'order-1')}>
				<AutoSizer>
					{({ height, width }) => (
						<div className={clsx('flex flex-col', blockScroll ? 'overflow-hidden overscroll-none' : 'overflow-x-hidden overflow-y-scroll overscroll-y-contain')} style={{ width, height }}>
							{children}
						</div>
					)}
				</AutoSizer>
			</div>
			{/* Content panel 2 */}
			{content && (
				<div
					ref={panelRef}
					className={clsx(
						'shrink-0 flex flex-col overflow-x-hidden overflow-y-scroll overscroll-y-contain',
						isFirst ? 'order-1' : 'order-2',
						direction === 'horizontal' && isFirst && 'border-r border-r-borderGray',
						direction === 'horizontal' && !isFirst && 'border-l border-l-borderGray',
						direction === 'vertical' && isFirst && 'border-b border-b-borderGray',
						direction === 'vertical' && !isFirst && 'border-t border-t-borderGray'
					)}
				>
					<div className={clsx('relative min-h-full')}>
						{content}
						<div
							className={clsx(
								'absolute hover:bg-textGray z-20',
								direction === 'horizontal' ? 'w-[5px] hover:cursor-col-resize' : 'h-[5px] hover:cursor-row-resize',
								direction === 'horizontal' && isFirst && 'top-0 right-0 bottom-0',
								direction === 'horizontal' && !isFirst && 'top-0 left-0 bottom-0',
								direction === 'vertical' && isFirst && 'bottom-0 left-0 right-0',
								direction === 'vertical' && !isFirst && 'top-0 left-0 right-0'
							)}
							onMouseDown={onPanelResize}
						/>
					</div>
				</div>
			)}
		</div>
	)
}
