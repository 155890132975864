import { memo, useContext, useMemo } from 'react'
import { SelectionContext } from 'common/components/grid/SelectionContext'
import clsx from 'clsx'

const buildSquare = (top, right, bottom, left) => {
	return [
		[left, top],
		[right, top],
		[right, bottom],
		[left, bottom],
		[left, top]
	]
}

const buildClipPath = (top, right, bottom, left) => {
	const values = [...buildSquare(0, '100%', '100%', 0), ...buildSquare(top, right, bottom, left)]
	return `polygon(evenodd, ${values.map((pair) => pair.map((value) => (typeof value === 'number' && value !== 0 ? value + 'px' : value)).join(' ')).join(',')})`
}

export const SelectionRect = memo(({ stickyIndex = 0, isStickyAll = false, isStickyRow = false, isStickyCol = false, isStickyEdge = false }) => {
	const { columnWidths, columnRights, rowHeight, rowCount, stickyCols, stickyRows, activeCell, selection, expansion, isEditing, isCellDisabled, expandStickyCol } = useContext(SelectionContext)

	const isActive =
		activeCell &&
		((isStickyRow && activeCell.row == stickyIndex && activeCell.col >= stickyCols) ||
			(isStickyCol && activeCell.col == stickyIndex && activeCell.row >= stickyRows) ||
			(isStickyAll && activeCell.row < stickyRows && activeCell.col < stickyCols) ||
			(!isStickyAll && !isStickyCol && !isStickyRow && activeCell.row >= stickyRows && activeCell.col >= stickyCols))

	const isSelected =
		selection &&
		((isStickyRow && stickyIndex >= selection.min.row && stickyIndex <= selection.max.row) ||
			(isStickyCol && stickyIndex >= selection.min.col && stickyIndex <= selection.max.col) ||
			(isStickyAll && selection.min.row < stickyRows && selection.min.col < stickyCols) ||
			(!isStickyAll && !isStickyCol && !isStickyRow && selection.max.row >= stickyRows && selection.max.col >= stickyCols))

	const isExpanded =
		expansion &&
		((isStickyRow && stickyIndex >= expansion.min.row && stickyIndex <= expansion.max.row) ||
			(isStickyCol && stickyIndex >= expansion.min.col && stickyIndex <= expansion.max.col) ||
			(isStickyAll && expansion.min.row < stickyRows && expansion.min.col < stickyCols) ||
			(!isStickyAll && !isStickyCol && !isStickyRow && expansion.max.row >= stickyRows && expansion.max.col >= stickyCols))

	const activeCellIsDisabled = activeCell ? isCellDisabled(activeCell) : false

	const selectionIsDisabled = useMemo(() => {
		if (!selection) return activeCellIsDisabled
		for (let col = selection.min.col; col <= selection.max.col; ++col) for (let row = selection.min.row; row <= selection.max.row; ++row) if (!isCellDisabled({ col, row })) return false
		return true
	}, [activeCellIsDisabled, isCellDisabled, selection])

	const isRowEdge = isStickyRow && isStickyEdge && activeCell && activeCell.col >= stickyCols
	const isColEdge = isStickyCol && isStickyEdge && activeCell && activeCell.row >= stickyRows

	const topPadding = isStickyCol ? -rowHeight : 0

	const activeCellRect = activeCell && {
		width: columnWidths[activeCell.col],
		height: rowHeight,
		left: columnRights[activeCell.col - 1] || 0,
		top: rowHeight * activeCell.row + topPadding,
		zIndex: isStickyAll ? 5 : undefined
	}

	const selectionRect = selection && {
		width: columnRights[isStickyCol || isStickyAll ? stickyIndex : selection.max.col] - (columnRights[selection.min.col - 1] || 0),
		height: rowHeight * (isStickyRow || isStickyAll ? stickyIndex : selection.max.row) - rowHeight * (selection.min.row - 1),
		left: columnRights[selection.min.col - 1] || 0,
		top: rowHeight * selection.min.row + topPadding,
		zIndex: isStickyAll ? 5 : undefined
	}

	const expandIndicator = activeCell &&
		!isStickyRow &&
		!isStickyAll &&
		(!isStickyCol || expandStickyCol) && {
			left: columnRights[activeCell.col],
			top: rowHeight * (activeCell.row + 1) + topPadding,
			transform: `translate(-${activeCell.col < columnRights.length - 1 ? 50 : 100}%, -${activeCell.row < rowCount - 1 ? 50 : 100}%)`,
			zIndex: isStickyAll ? 5 : undefined
		}

	const expandRect = expansion && {
		width: columnRights[expansion.max.col] - (columnRights[expansion.min.col - 1] || 0),
		height: rowHeight * expansion.max.row - rowHeight * (expansion.min.row - 1),
		left: columnRights[expansion.min.col - 1] || 0,
		top: rowHeight * expansion.min.row + topPadding,
		zIndex: isStickyAll ? 5 : undefined
	}

	return (
		<>
			{isActive && activeCellRect && (
				<div
					className={clsx('grid-active-cell', {
						'grid-active-cell-focus': isEditing,
						'grid-active-cell-disabled': activeCellIsDisabled
					})}
					style={activeCellRect}
				/>
			)}

			{isSelected && selectionRect && (
				<div
					className={clsx('grid-selection-rect', selectionIsDisabled && 'grid-selection-rect-disabled')}
					style={{
						...selectionRect,
						clipPath:
							isActive &&
							buildClipPath(
								activeCellRect.top - selectionRect.top,
								activeCellRect.left - selectionRect.left,
								activeCellRect.top + activeCellRect.height - selectionRect.top,
								activeCellRect.left + activeCellRect.width - selectionRect.left
							),
						borderTop: isStickyRow && selection.min.row < stickyIndex && '0px',
						borderBottom: isStickyRow && selection.max.row > stickyIndex && '0px',
						borderLeft: isStickyCol && selection.min.col < stickyIndex && '0px',
						borderRight: isStickyCol && selection.max.col > stickyIndex && '0px'
					}}
				/>
			)}

			{isRowEdge && !isActive && !isSelected && (selectionRect || activeCellRect) && (
				<div
					className={clsx('grid-selection-col-marker', selectionIsDisabled && 'grid-selection-col-marker-disabled')}
					style={{
						left: selectionRect?.left ?? activeCellRect?.left,
						width: selectionRect?.width ?? activeCellRect?.width,
						// height: rowHeights[stickyIndex],
						height: rowHeight,
						top: 0
					}}
				/>
			)}

			{isColEdge && !isActive && !isSelected && (selectionRect || activeCellRect) && (
				<div
					className={clsx('grid-selection-row-marker', selectionIsDisabled && 'grid-selection-row-marker-disabled')}
					style={{
						top: selectionRect?.top ?? activeCellRect?.top,
						height: selectionRect?.height ?? activeCellRect?.height,
						width: columnWidths[stickyIndex],
						left: 0
					}}
				/>
			)}

			{isActive && !isSelected && !isEditing && expandIndicator && (
				<div className={clsx('grid-expand-rows-indicator', selectionIsDisabled && 'grid-expand-rows-indicator-disabled')} style={expandIndicator} />
			)}
			{!isSelected && !isEditing && isExpanded && expandRect && (
				<div
					className="grid-expand-rows-rect"
					style={{
						...expandRect,
						clipPath: buildClipPath(
							activeCellRect.top - expandRect.top,
							activeCellRect.left - expandRect.left,
							activeCellRect.top + activeCellRect.height - expandRect.top,
							activeCellRect.left + activeCellRect.width - expandRect.left
						)
					}}
				/>
			)}
		</>
	)
})

SelectionRect.displayName = 'SelectionRect'
