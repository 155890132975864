import { db } from 'common/config/firebase'
import { doc as dbDoc, collection as dbCollection, getDoc, getDocs } from 'firebase/firestore'

export async function loadDoc({ path }) {
	// Document path
	const queryPath = path?.reduce((prev, el) => `${prev}/${el.collection}/${el.value}`, '')
	// Query
	const doc = dbDoc(db, queryPath)
	// Execute
	return getDoc(doc)
		.then((doc) => ({ id: doc.id, data: doc.data(), notFound: doc.data() == null }))
		.catch((error) => ({ notFound: true }))
}

export async function loadCol({ path, returnType }) {
	// Document path
	const queryPath = path?.reduce((prev, el) => (el.value ? `${prev}/${el.collection}/${el.value}` : `${prev}/${el.collection}`), '')
	// Query
	const collection = dbCollection(db, queryPath)
	// Execute
	return getDocs(collection)
		.then((snapshot) => {
			var result = returnType === 'map' ? {} : []
			let notFound = true
			snapshot.forEach((doc) => {
				if (returnType === 'map') result[doc.id] = doc.data()
				else result.push({ id: doc.id, data: doc.data() })
				notFound = false
			})
			return { result, notFound }
		})
		.catch((error) => ({ result: null, notFound: true }))
}
