import { call, put, spawn, takeEvery } from 'redux-saga/effects'
import * as sagaAction from 'automation/saga-actions/sourceActions'
import * as api from 'automation/api/sourceApi'
import * as authApi from 'common/api/authApi'
import * as storeAction from 'automation/store/sourceReducer'
import * as globalStoreAction from 'common/store/globalReducer'
import * as globalSagaAction from 'common/saga-actions/globalActions'
import * as subscribeHelper from 'common/saga/helpers/subscribeHelper'
import { throwError } from 'common/config/errors'

const FILE_NAME = 'sourceSagas'

// #### PATH TO THE COLLECTION
function defaultPath(includeDocId) {
	const path = [
		{ collection: 'tenants', param: 'tid', value: null },
		{ collection: 'teams', param: 'teamId', value: null },
		{ collection: 'sources', param: includeDocId ? 'sourceId' : null, value: null }
	]
	return path
}

// #### SUBSCRIBE TO ASSETS
function* loadSourcesReq() {
	const queryConfig = subscribeHelper.queryConfig({
		path: defaultPath(true),
		orderBy: [{ attribute: 'name', order: 'asc' }],
		returnType: 'map'
	})
	const sagaConfig = subscribeHelper.sagaConfig({ loadAction: sagaAction.LOAD_SOURCES, loadResponse: storeAction.loadSources, cancelAction: sagaAction.CANCEL_SOURCES, cancelResponse: storeAction.cancelSources })
	yield spawn(subscribeHelper.subscribe, sagaConfig, queryConfig)
}

// #### API MANAGEMENT
function* createReq() {
	yield takeEvery(sagaAction.CREATE_REQ, create)
}

function* create(triggeredAction) {
	const { tid, teamId, uid, source, config } = triggeredAction
	try {
		yield put(globalStoreAction.loadingOn({ key: 'newSource' }))
		const { token } = yield call(authApi.getAuthToken)
		const { data } = yield call(api.create, token, tid, teamId, uid, source, config)
		yield put(storeAction.loadSource(data))
		if (data) yield put(sagaAction.check({ tid, teamId, sourceId: data.sourceId }))
	} catch (err) {
		const content = throwError(err, FILE_NAME, create.name)
		yield put(globalSagaAction.showMessage({ content }))
		yield put(globalStoreAction.loadingOff({ key: 'newSource' }))
	}
}

function* checkReq() {
	yield takeEvery(sagaAction.CHECK_REQ, check)
}

function* check(triggeredAction) {
	const { tid, teamId, sourceId } = triggeredAction
	try {
		const { token } = yield call(authApi.getAuthToken)
		const { data } = yield call(api.check, token, tid, teamId, sourceId)
		yield put(storeAction.sourceStatus(data.status))
		yield put(globalStoreAction.loadingOff({ key: 'newSource' }))
	} catch (err) {
		const content = throwError(err, FILE_NAME, check.name)
		yield put(globalSagaAction.showMessage({ content }))
	}
}

function* updateReq() {
	yield takeEvery(sagaAction.UPDATE_REQ, update)
}

function* update(triggeredAction) {
	const { tenantId, teamId, sourceId, source, config } = triggeredAction
	try {
		const { token } = yield call(authApi.getAuthToken)
		yield call(api.update, token, tenantId, teamId, source, sourceId, config)
	} catch (err) {
		const content = throwError(err, FILE_NAME, update.name)
		yield put(globalSagaAction.showMessage({ content }))
	}
}

function* discoverReq() {
	yield takeEvery(sagaAction.DISCOVER_REQ, discover)
}

function* discover(triggeredAction) {
	const { teamId, sourceId } = triggeredAction
	try {
		const { token } = yield call(authApi.getAuthToken)
		yield put(globalStoreAction.loadingOn({ key: 'discover' }))
		const { data } = yield call(api.discover, token, teamId, sourceId)
		if (data) yield put(storeAction.loadDiscoverSource(data))
	} catch (err) {
		const content = throwError(err, FILE_NAME, discover.name)
		yield put(globalSagaAction.showMessage({ content }))
	} finally {
		yield put(globalStoreAction.loadingOff({ key: 'discover' }))
	}
}

function* removeReq() {
	yield takeEvery(sagaAction.REMOVE_REQ, remove)
}

function* remove(triggeredAction) {
	const { tenantId, teamId, sourceId } = triggeredAction
	try {
		yield put(globalStoreAction.loadingOn({ key: 'deleteSource', item: sourceId }))
		const { token } = yield call(authApi.getAuthToken)
		yield call(api.remove, token, tenantId, teamId, sourceId)
	} catch (err) {
		const content = throwError(err, FILE_NAME, remove.name)
		yield put(globalSagaAction.showMessage({ content }))
	} finally {
		yield put(globalStoreAction.loadingOff({ key: 'deleteSource', item: sourceId }))
	}
}

export default function* root() {
	// #### SUBSCRIBE TO SOURCES
	yield spawn(loadSourcesReq)
	// #### API MANAGEMENT
	yield spawn(createReq)
	yield spawn(checkReq)
	yield spawn(discoverReq)
	yield spawn(updateReq)
	yield spawn(removeReq)
}
