import { db } from 'common/config/firebase'
import { collection, getDocs, where as dbWhere, orderBy as dbOrderBy, query as dbQuery, limit as dbLimit, startAfter as dbStartAfter } from 'firebase/firestore'
import { throwAPIError } from 'common/config/errors'

export async function search({ path, where, orderBy, startAfter, limit, returnType }, msgConfig) {
	// Document path
	const queryPath = path?.reduce((prev, el) => (el.value ? `${prev}/${el.collection}/${el.value}` : `${prev}/${el.collection}`), '')
	// Query where
	const queryWhere = where?.map((el) => dbWhere(el.attribute, el.operator, el.value)) || []
	// Query orderBy
	const queryOrderBy = orderBy?.map((el) => dbOrderBy(el.attribute, el.order)) || []
	// Query limit
	const queryLimit = limit ? [dbLimit(limit)] : []
	// Start after (pagination)
	const queryStartAfter = startAfter ? [dbStartAfter(startAfter)] : []
	// Query
	const queryContraints = [...queryWhere, ...queryOrderBy, ...queryLimit, ...queryStartAfter]
	const query = dbQuery(collection(db, queryPath), ...queryContraints)
	// Execute
	return getDocs(query)
		.then((snapshot) => {
			var result = returnType === 'map' ? {} : []
			let notFound = true
			let startAfter = null
			snapshot.forEach((doc) => {
				if (returnType === 'map') result[doc.id] = doc.data()
				else result.push({ id: doc.id, data: doc.data() })
				notFound = false
				startAfter = doc
			})
			return { result: result, notFound, startAfter }
		})
		.catch((error) => {
			throwAPIError(error, null, msgConfig.fileName, msgConfig.functionName)
		})
}
