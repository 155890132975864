import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import { LogoIcon, SingularlyIcon } from 'common/icons/index'
import { setElementSize, toggleMenu } from 'common/store/globalReducer'
import AccountButton from 'common/screens/account/AccountButton'
import React, { useRef, useCallback, useEffect } from 'react'
import { useResize } from 'common/hooks/useResize'
import BarButton from 'common/screens/layout/BarButton'
import { t } from 'i18next'
import useNavigate from 'common/hooks/useNavigate'
import { useBanner } from 'common/hooks/useBanner'
import TeamButton from '../team/TeamButton'

export default function Layout({
	isMenuCollapsible = true,
	barContent: BarContent,
	menuContent: MenuContent,
	secondaryBar: SecondaryBar,
	secondaryContent: SecondaryContent,
	children,
	isBodyScrollable = true
}) {
	const dispatch = useDispatch()
	const { navigate } = useNavigate()
	const banner = useBanner()

	// #### REFS
	const menuRef = useRef()
	const menuBarRef = useRef()
	const panelRef = useRef()
	const panelBarRef = useRef()
	const panelParentRef = useRef()

	// #### STORE
	const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)
	const _isMenuOpen = useSelector((state) => state.global.isMenuOpen)
	const isMenuOpen = isLoggedIn && _isMenuOpen
	const menuWidth = useSelector((state) => state.global.elementSize.menu)
	const panelWidth = useSelector((state) => state.global.elementSize.splitScreen)
	const team = useSelector((state) => state.team.team)

	// #### EFFECTS - INITIALIZATION
	// Initialize menu hiding
	useEffect(() => {
		if ((isMenuOpen || !isMenuCollapsible) && menuRef && menuBarRef) {
			menuRef.current.style.marginLeft = '0px'
			menuBarRef.current.style.marginLeft = '0px'
		} else if (menuRef && menuBarRef) {
			menuRef.current.style.marginLeft = `${-menuWidth}px`
			menuBarRef.current.style.marginLeft = `${-menuWidth}px`
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isMenuOpen, menuRef, menuBarRef])

	// Initialize menu width
	useEffect(() => {
		if (menuWidth && menuRef?.current && menuBarRef?.current) setMenuWidth(menuWidth)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [menuWidth, menuRef, menuBarRef])

	// Initialize panel width
	useEffect(() => {
		if (panelWidth && panelRef?.current && panelBarRef?.current) setPanelWidth(panelWidth)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [panelWidth, panelRef, panelBarRef, SecondaryBar])

	// #### MENU HIDING
	function onToggleMenu() {
		if (!isLoggedIn) navigate('/')
		else if (isMenuCollapsible) dispatch(toggleMenu())
	}

	// #### MENU RESIZING
	const getMenuWidth = useCallback(() => {
		return parseInt(getComputedStyle(menuRef.current, '').width)
	}, [menuRef])

	const setMenuWidth = useCallback(
		(width) => {
			menuRef.current.style.width = width + 'px'
			menuBarRef.current.style.width = width + 'px'
		},
		[menuRef, menuBarRef]
	)

	const changeMenuWidth = useCallback(
		(dx) => {
			let width = getMenuWidth()
			width -= dx
			setMenuWidth(width)
		},
		[getMenuWidth, setMenuWidth]
	)

	const saveMenuWidth = useCallback(() => {
		const width = getMenuWidth()
		dispatch(setElementSize({ key: 'menu', value: width }))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getMenuWidth])

	const { onMouseDown: onMenuResize } = useResize(changeMenuWidth, saveMenuWidth)

	// #### SCREEN SPLIT RESIZING
	const getPanelWidth = useCallback(() => {
		return { panelWidth: parseInt(getComputedStyle(panelRef.current, '').width), parentWidth: parseInt(getComputedStyle(panelParentRef.current, '').width) }
	}, [panelRef])

	const setPanelWidth = useCallback(
		(width) => {
			panelRef.current.style.width = `${width}%`
			panelBarRef.current.style.width = `${width}%`
		},
		[panelRef, panelBarRef]
	)

	const changePanelWidth = useCallback(
		(dx) => {
			let { panelWidth, parentWidth } = getPanelWidth()
			panelWidth += dx
			const percentWidth = (panelWidth / parentWidth) * 100.0
			setPanelWidth(percentWidth)
		},
		[getPanelWidth, setPanelWidth]
	)

	const savePanelWidth = useCallback(() => {
		const { panelWidth, parentWidth } = getPanelWidth()
		const percentWidth = (panelWidth / parentWidth) * 100.0
		dispatch(setElementSize({ key: 'splitScreen', value: percentWidth }))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getPanelWidth])

	const { onMouseDown: onPanelResize } = useResize(changePanelWidth, savePanelWidth)

	return (
		<>
			{banner.isVisible && (
				<div className={clsx('fixed top-0 left-0 right-0 h-banner flex flex-row items-center justify-center', `bg-${banner.color}`)}>
					{React.createElement(banner.icon, { className: 'text-[18px] mr-2' })}
					<span className="text-sm">{banner.message}</span>
				</div>
			)}

			<div className={clsx('fixed left-0 z-10 h-topbar flex flex-row items-center text-barTextStrong group pl-2', banner.isVisible ? 'top-banner' : 'top-0')}>
				<BarButton
					icon={LogoIcon}
					label={isLoggedIn ? t('common:buttons.menu') : t('common:login.title')}
					isDisabled={!isMenuCollapsible}
					onClick={isMenuCollapsible ? onToggleMenu : undefined}
					keepColorDisabled
				/>
			</div>

			{/* Top bar */}
			<div className={clsx('fixed left-0 right-0 h-topbar bg-barBg flex flex-row items-center', banner.isVisible ? 'top-banner' : 'top-0')}>
				{/* Your account */}
				<div ref={menuBarRef} className="h-topbar overflow-hidden shrink-0 transition-[margin]">
					<div className={clsx('ml-10 h-topbar px-4 border-r border-r-barBorderLight flex flex-col justify-center')}>
						{isLoggedIn ? <TeamButton /> : <SingularlyIcon className="w-[90px] h-[20px]" />}
					</div>
				</div>
				<div className="flex-1 relative flex flex-row">
					{/* Top bar panel 1 */}
					<div className={clsx('h-topbar overflow-hidden flex-1 shrink-0 flex flex-row items-center')}>
						<div className={clsx('transition-[margin] flex-1 h-topbar', !isMenuOpen && isMenuCollapsible ? 'ml-10' : 'ml-0')}>
							{BarContent && <BarContent windowIndex={0} reduced={SecondaryBar != null} />}
						</div>
					</div>
					{/* Top bar panel 2 */}
					{SecondaryBar && (
						<div ref={panelBarRef} className="h-topbar overflow-hidden shrink-0 flex flex-row items-center border-l border-l-barBorderLight" style={{ width: '50%' }}>
							<SecondaryBar windowIndex={1} reduced={true} />
						</div>
					)}
				</div>
			</div>

			<div className={clsx('h-full flex flex-row', banner.isVisible ? 'pt-topbarWithBanner' : 'pt-topbar')}>
				{/* Menu */}
				<div ref={menuRef} className="overflow-x-hidden overflow-y-scroll overscroll-y-contain shrink-0 flex flex-col transition-[margin]">
					<div className="flex-1 border-r border-r-borderGray bg-white relative">
						{MenuContent && <MenuContent />}
						<div className="absolute top-0 bottom-0 right-0 w-[5px] hover:bg-textGray hover:cursor-col-resize" onMouseDown={onMenuResize} />
					</div>
				</div>
				<div ref={panelParentRef} className="flex-1 relative flex flex-row">
					{/* Content panel 1 */}
					<div className={clsx('overflow-x-hidden flex-1 shrink-0 flex flex-col', isBodyScrollable ? 'overflow-y-scroll overscroll-y-contain' : 'overflow-y-hidden')}>{children}</div>
					{/* Content panel 2 */}
					{SecondaryContent && (
						<div
							ref={panelRef}
							className={clsx('overflow-x-hidden shrink-0 flex flex-col border-l border-l-borderGray relative', isBodyScrollable ? 'overflow-y-scroll overscroll-y-contain' : 'overflow-y-hidden')}
							style={{ width: '50%' }}
						>
							<SecondaryContent windowIndex={1} />
							<div className="absolute top-0 bottom-0 left-0 w-[5px] hover:bg-textGray hover:cursor-col-resize z-20" onMouseDown={onPanelResize} />
						</div>
					)}
				</div>
			</div>
		</>
	)
}
