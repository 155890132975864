import { useRef, useMemo, useCallback, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import TableTabs from 'table/screens/TableTabs'
import { useSplitWindow } from 'common/hooks/useSplitWindow'
import { useControlScreenShot } from 'common/hooks/useScreenShot'
import { uploadScreenshot } from 'common/saga-actions/assetActions'
import Grid from 'common/components/grid/Grid'
import { TABLE_PARAMS } from 'table/constants/tableParameters'
import { Cell, CellStickyColumn, CellStickyRow, CellStickyTopLeft } from 'table/screens/TableCell'
import { DATA_TYPES } from 'common/constants/dataTypes'
import { useMemoObject } from 'common/hooks/grid/useMemoObject'
import { useMemoCompare } from 'common/hooks/grid/useMemoCompare'
import { addRows, deleteFile, deleteRow, loadData, searchColumn, updateRows, uploadFile } from 'table/saga-actions/tableDataActions'
import { addColumn, changeColumnTab, deleteColumn, moveColumn, updateTable } from 'table/saga-actions/tableActions'
import { useLazyLoading } from 'common/hooks/useLazyLoadingRelational'
import { cancelSearch, clearAllData, clearData } from 'table/store/tableDataReducer'
import ActionConfirm from 'common/components/ActionConfirm'
import TableType from 'table/screens/type/TableType'
import { Alert, LinearProgress, Snackbar } from '@mui/material'
import useLocale from 'common/hooks/useLocale'
import { useAssetPermits } from 'common/hooks/useAssetPermits'
import { setInView } from 'common/saga-actions/viewActions'
import PanelLayout from 'common/screens/layout/PanelLayout'
import TableSearch from 'table/screens/TableSearch'
import * as viewSel from 'common/store/viewSelector'
import * as tableSel from 'table/store/tableSelector'
import * as tableDataSel from 'table/store/tableDataSelector'
import TableCard from 'table/screens/card/TableCard'
import DemoSeq from './DemoSeq'
import AlertDialog from 'common/components/alert/AlertDialog'
// import TableTour from './TableTour'
// import { STATUS } from 'react-joyride'
// import { updateUser } from 'common/saga-actions/userActions'

export default function Table({ windowIndex = 0 }) {
	const { t } = useTranslation('model')
	const dispatch = useDispatch()
	const { id: aid, isSplit } = useSplitWindow(windowIndex)
	const wrapperRef = useRef(null)
	const locale = useLocale()
	const permits = useAssetPermits(aid)
	const { canCreate, canEdit } = permits

	// #### REDUX
	// Asset
	const tid = useSelector((state) => state.auth.tid)
	const uid = useSelector((state) => state.auth.uid)
	// const tableTour = useSelector((state) => state.user.user?.data?.tableTour) || false
	const teamId = useSelector((state) => state.asset.asset[aid]?.data?.teamId)
	const screenShotUpdatedAt = useSelector((state) => state.asset.asset[aid]?.data?.screenShot?.updatedAt)
	const isDesigning = useSelector((state) => (canCreate ? tableSel.selectTableIsDesigning(state, aid) : false))
	// Table
	const isTableLoaded = useSelector((state) => state.table.table[aid]?.loaded) || false
	const tabs = useSelector((state) => tableSel.selectTableTabs(state, aid))
	const variables = useSelector((state) => tableSel.selectTableVariables(state, aid))
	const tabColumns = useSelector((state) => tableSel.selectTableTabColumns(state, aid))
	const deleteColumnLoading = useSelector((state) => state.global.loading?.deleteColumn)
	// Table data
	const isDataLoaded = useSelector((state) => state.tableData.isLoaded[aid])
	const data = useSelector((state) => tableDataSel.selectTableData(state, aid))
	const totalRows = useSelector((state) => state.tableData.totalRows[aid])
	const fileTransmit = useSelector((state) => state.tableData.fileTransmit[aid])
	const variableSearch = useSelector((state) => state.tableData.search[aid])
	// View
	const isViewLoaded = useSelector((state) => viewSel.selectViewLoaded(state, aid))
	const colProps = useSelector((state) => viewSel.selectViewColProps(state, aid))
	const searchPanel = useSelector((state) => viewSel.selectViewSearchPanel(state, aid))
	const cardPanel = useSelector((state) => viewSel.selectViewCardPanel(state, aid))
	const selectTableFilter = useMemo(() => viewSel.makeSelectTableFilter({ aid }), [aid])
	const filter = useSelector((state) => selectTableFilter(state))
	const selectTableSort = useMemo(() => viewSel.makeSelectTableSort({ aid }), [aid])
	const sort = useSelector((state) => selectTableSort(state))

	// #### CONTROL CHANGES THAT TRIGGER DATA LOAD
	const searchConfig = useMemoCompare(
		{ isViewLoaded, isTableLoaded, tid, teamId, aid, sort, filter },
		(prev, next) =>
			(prev == null && next == null) ||
			(prev != null &&
				next != null &&
				prev.isViewLoaded === next.isViewLoaded &&
				prev.isTableLoaded === next.isTableLoaded &&
				prev.tid === next.tid &&
				prev.teamId === next.teamId &&
				prev.aid === next.aid &&
				JSON.stringify(prev.sort) === JSON.stringify(next.sort) &&
				JSON.stringify(prev.filter) === JSON.stringify(next.filter))
	)

	// #### SCREENSHOT
	useControlScreenShot({ ref: wrapperRef, updatedAt: screenShotUpdatedAt, isReady: canCreate && isTableLoaded && !isSplit, callback: (file) => dispatch(uploadScreenshot({ tid, teamId, aid, file })) })

	// #### STATE
	const [tableType, setTableType] = useState({ position: null, varId: null }) // position: {x, y}
	const [openDeleteRow, setOpenDeleteRow] = useState(null)
	const [openDeleteColumn, setOpenDeleteColumn] = useState(null)
	const [itemsPerPage, setItemsPerPage] = useState(0)
	const [activeRow, setActiveRow] = useState(null)
	// const [run, setRun] = useState(false)

	// #### ROW & COL GETTERS
	const rowCount = useMemo(() => totalRows + (canEdit ? 2 : 1), [totalRows, canEdit]) // + 2 for header and new row
	const colCount = useMemo(() => (tabColumns?.length || 0) - (isDesigning ? 0 : 1), [tabColumns, isDesigning]) // + 2 for header and new row
	const getColumn = useCallback((colIndex) => ({ colIndex: colIndex, col: tabColumns[colIndex] }), [tabColumns])
	const getRow = useCallback(
		(rowIndex) =>
			rowIndex === 0
				? { rowIndex: 0, row: { id: TABLE_PARAMS.HEAD_ROW_ID } }
				: rowIndex >= rowCount - 1
				? { rowIndex: rowIndex - 1, row: { id: TABLE_PARAMS.NEW_ROW_ID } }
				: { rowIndex: rowIndex - 1, row: data[rowIndex - 1] },
		[data, rowCount]
	)

	// #### DATA LOADING
	// Initial load
	useEffect(() => {
		const { tid, teamId, aid, sort, filter, isViewLoaded, isTableLoaded } = searchConfig
		const id = aid
		if (tid && teamId && aid && isViewLoaded && isTableLoaded) triggerPagination(0, 20, true)
		return () => {
			if (tid && teamId && aid && isViewLoaded) dispatch(clearAllData({ id }))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchConfig])

	// useEffect(() => {
	// 	if (!tableTour && isTableLoaded) setRun(true)
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [isTableLoaded])

	// Backend call to fetch data
	const onFetchData = useCallback(
		({ page, limit, isInitial }) => {
			const { tid, teamId, aid, sort, filter, isViewLoaded, isTableLoaded } = searchConfig
			if (isViewLoaded && isTableLoaded) {
				dispatch(loadData({ tid, teamId, aid, sort, filter, page, limit, isInitial }))
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[searchConfig]
	)

	// Backend call to clean data
	const onCleanData = useCallback(
		({ pages, limit }) => {
			dispatch(clearData({ id: aid, pages, limit }))
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[aid]
	)

	// Lazy loading
	const triggerPagination = useLazyLoading({ fetch: onFetchData, clean: onCleanData, itemsPerPage, totalItems: totalRows })

	// On grid size change - used for lazy loading
	const onGridSizeChange = useCallback(({ height, width }) => {
		setItemsPerPage(Math.ceil(height / TABLE_PARAMS.DEFAULT_ROW_HEIGHT))
	}, [])

	// On grid scroll - used for lazy loading
	const onGridItemsRendered = useCallback(
		({ rowStartIndex, rowStopIndex }) => {
			triggerPagination(rowStartIndex - 1, rowStopIndex - 1, false) // substract initial header
		},
		[triggerPagination]
	)

	// #### GRID CUSTOMIZATION
	// Returns the cell type
	const getCellType = useCallback(
		(grIndex, gcIndex) => {
			const { row } = getRow(grIndex)
			const { col } = getColumn(gcIndex)
			if (grIndex === 0) return { type: DATA_TYPES.text.key, typeProps: DATA_TYPES.text.defaultProps, columnId: col.id, rowId: row.id }
			else return { type: col.type, typeProps: col.typeProps, columnId: col.id, rowId: row.id }
		},
		[getRow, getColumn]
	)

	// Indicates when a cell's editing must be disabled
	const disableCell = useCallback(
		(grIndex, gcIndex) => {
			// In view mode, disable everything
			if (!canEdit) return true
			// In non-creation mode, disable headers
			if (!canCreate && grIndex === 0) return true
			// In non-creation mode, disable new columns
			const { col } = getColumn(gcIndex)
			if (!canCreate && col.id === TABLE_PARAMS.NEW_COL_ID) return true
			// Disable new column + new row cell (create one or the other, not both)
			const { row } = getRow(grIndex)
			if (col.id === TABLE_PARAMS.NEW_COL_ID && row.id === TABLE_PARAMS.NEW_ROW_ID) return true
			// Some variable types are not editable
			const type = col.type ? DATA_TYPES[col.type] : null
			if (type?.isDisabled && grIndex !== 0) return true
			// Otherwise, enable editing
			return false
		},
		[canCreate, canEdit, getColumn, getRow]
	)

	// Returns the right-click menu of a cell
	const cellContextMenu = useCallback(
		({ rowIndex: grIndex, colIndex: gcIndex }) => {
			const { col } = getColumn(gcIndex)
			const { row } = getRow(grIndex)
			if (isDesigning && canCreate && grIndex === 0 && col.id !== TABLE_PARAMS.NEW_COL_ID) {
				return {
					hasMenu: true,
					items: [
						{
							name: t('table:menu.moveToTab'),
							items: tabs?.length > 0 ? tabs?.map((tab) => ({ name: tab.name, isSelectable: true, isActive: col.tabId === tab.id, action: () => onMoveToTab(gcIndex, tab.id) })) : undefined
						},
						{ name: t('table:menu.deleteVariable'), action: () => setOpenDeleteColumn(gcIndex) }
					]
				}
			} else if ((canCreate || canEdit) && gcIndex === 0 && grIndex !== 0 && row.id !== TABLE_PARAMS.NEW_ROW_ID) {
				return {
					hasMenu: true,
					items: [{ name: t('table:menu.deleteRow'), action: () => setOpenDeleteRow(grIndex) }]
				}
			}

			return { hasMenu: false, items: null }
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[isDesigning, canCreate, canEdit, tabs, getColumn, getRow]
	)

	// #### ACTIONS CALLED BY GRID
	// Update data
	const onChangeCell = useCallback(
		(value, grIndex, gcIndex) => {
			const { rowIndex, row } = getRow(grIndex)
			const { col } = getColumn(gcIndex)
			// New row - create
			if (row.id === TABLE_PARAMS.NEW_ROW_ID) dispatch(addRows({ tid, teamId, aid, inserts: [{ [col.id]: value }] }))
			// New column - create
			else if (col.id === TABLE_PARAMS.NEW_COL_ID) dispatch(addColumn({ tid, teamId, aid, rowUpdateId: row.id, rowUpdateIndex: rowIndex, rowUpdateValue: value }))
			// Column header - update
			else if (row.id === TABLE_PARAMS.HEAD_ROW_ID) dispatch(updateTable({ tid, aid, content: { [`variables.${col.id}.label`]: value } }))
			// Row - update
			else dispatch(updateRows({ tid, teamId, aid, updates: { [rowIndex]: { id: row.id, [col.id]: value } } }))
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[tid, teamId, aid, getRow, getColumn]
	)

	// Upload a file
	const onChangeFile = useCallback(
		(grIndex, gcIndex, file, fileId, fileName, fileSize, fileType) => {
			const { rowIndex, row } = getRow(grIndex)
			const { col } = getColumn(gcIndex)
			if (fileId) dispatch(deleteFile({ tid, teamId, aid, rowId: row.id, colId: col.id, rowIndex, fileId }))
			else dispatch(uploadFile({ tid, teamId, aid, rowId: row.id, colId: col.id, rowIndex, file, fileName, fileSize, fileType }))
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[tid, teamId, aid, getRow, getColumn]
	)

	const onPaste = useCallback(
		(values, _) => {
			if (!values) return
			var updateHeader = {}
			var inserts = []
			var updates = {}
			Object.keys(values).map((grIndex) => {
				const { rowIndex, row } = getRow(parseInt(grIndex))
				if (row.id === TABLE_PARAMS.HEAD_ROW_ID) Object.entries(values[grIndex]).map((update) => (updateHeader[`variables.${update[0]}.label`] = update[1]))
				else if (row.id === TABLE_PARAMS.NEW_ROW_ID) inserts.push(values[grIndex])
				else updates[rowIndex] = { id: row.id, ...values[grIndex] }
				return null
			})
			if (Object.keys(updateHeader)?.length > 0) dispatch(updateTable({ tid, aid, content: updateHeader }))
			if (inserts?.length > 0) dispatch(addRows({ tid, teamId, aid, inserts }))
			if (Object.keys(updates)?.length > 0) dispatch(updateRows({ tid, teamId, aid, updates }))
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[tid, teamId, aid, getRow]
	)

	const onCopy = useCallback(
		(min, max) => {
			var copyData = []
			var copyFormula = []
			for (let grIndex = min.row; grIndex <= max.row; ++grIndex) {
				const { rowIndex, row } = getRow(grIndex)
				const isHeader = row.id === TABLE_PARAMS.HEAD_ROW_ID
				copyData.push([])
				copyFormula.push([])
				for (let gcIndex = min.col; gcIndex <= max.col; ++gcIndex) {
					const { col } = getColumn(gcIndex)
					const colId = col?.id

					const typeProps = col.typeProps
					const typeObj = DATA_TYPES[col.type]
					const cellRenderer = typeObj?.renderer
					const cellConfig = cellRenderer?.config
					const formatCopyValue = cellConfig.formatCopyValue

					const itemContent = isHeader ? col.label : formatCopyValue({ value: data[rowIndex][colId], typeProps, localeFallback: locale?.locale })
					const isReference = col.type === DATA_TYPES.reference.key
					const itemData = isReference ? itemContent?.value : itemContent
					const itemFormula = isReference ? JSON.stringify(itemContent) : itemContent

					copyData[grIndex - min.row].push(itemData)
					copyFormula[grIndex - min.row].push(itemFormula)
				}
			}
			return { copyData, copyFormula }
		},
		[getRow, getColumn, data, locale]
	)

	const onDelete = useCallback(
		(min, max) => {
			var updateHeader = {}
			var updates = {}
			for (let grIndex = min.row; grIndex <= max.row; ++grIndex) {
				const { rowIndex, row } = getRow(grIndex)

				for (let gcIndex = min.col; gcIndex <= max.col; ++gcIndex) {
					const { col } = getColumn(gcIndex)
					const colId = col?.id
					if (disableCell(grIndex, gcIndex) || col.type === DATA_TYPES.file.key || colId === TABLE_PARAMS.NEW_COL_ID) continue

					if (colId === TABLE_PARAMS.HEAD_ROW_ID) updateHeader[`variables.${colId}.label`] = ''
					else {
						updates[rowIndex] = updates[rowIndex] || {}
						updates[rowIndex] = { ...updates[rowIndex], id: row.id, [colId]: null }
					}
				}
			}
			if (Object.keys(updateHeader)?.length > 0) dispatch(updateTable({ tid, aid, content: updateHeader }))
			if (Object.keys(updates)?.length > 0) dispatch(updateRows({ tid, teamId, aid, updates }))
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[getRow, getColumn]
	)

	// Copy a cell to many cells
	const onExpandCell = useCallback(
		(grIndex, gcIndex, destRIndex, destCIndex) => {
			const { rowIndex } = getRow(grIndex)
			const { col } = getColumn(gcIndex)
			const value = data[rowIndex][col.id]
			const rowStart = Math.min(grIndex, destRIndex)
			const rowEnd = Math.max(grIndex, destRIndex)
			var values = {}
			for (let index = rowStart; index <= rowEnd; index++) {
				const { row } = getRow(index)
				if (row.id !== TABLE_PARAMS.NEW_ROW_ID) values[index] = { [col.id]: value }
			}
			onPaste(values)
		},
		[data, getRow, getColumn, onPaste]
	)

	// Resize a column
	const onColumnResize = useCallback(
		(gcIndex, width) => {
			const { col } = getColumn(gcIndex)
			dispatch(setInView({ aid, changes: [{ path: `colProps.${col.id}.width`, value: width }], permits }))
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[aid, getColumn, permits]
	)

	// Search a column
	const onSearchColumn = useCallback(
		(search, isInitial, nextPage, table, column) => {
			dispatch(searchColumn({ key: aid, tid, teamId, table, column, search, isInitial, nextPage }))
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[tid, teamId, aid]
	)

	const onCancelSearchColumn = useCallback(() => {
		dispatch(cancelSearch({ key: aid }))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [aid])

	// Move column to another tab
	const onMoveToTab = useCallback(
		(gcIndex, destTabId) => {
			const { col } = getColumn(gcIndex)
			const originTabId = col.tab
			if (originTabId !== destTabId) dispatch(changeColumnTab({ tid, teamId, aid, colId: col.id, destTabId }))
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[tid, teamId, aid, getColumn]
	)

	// Change variable type
	const onOpenTableType = useCallback(
		(position, gcIndex) => {
			const { col } = getColumn(gcIndex)
			setTableType({ position, varId: col.id })
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[getColumn]
	)

	const onTableType = useCallback((position, colId) => {
		setTableType({ position, varId: colId })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// Delete a variable
	const onDeleteColumn = useCallback(() => {
		const gcIndex = openDeleteColumn
		const { col } = getColumn(gcIndex)
		dispatch(deleteColumn({ tid, teamId, aid, colId: col.id }))
		// setOpenDeleteColumn(null)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tid, teamId, aid, openDeleteColumn, getColumn])

	// Delete a row
	const onDeleteRow = useCallback(() => {
		const grIndex = openDeleteRow
		const { rowIndex, row } = getRow(grIndex)
		dispatch(deleteRow({ tid, teamId, aid, rowId: row.id, rowIndex }))
		setOpenDeleteRow(null)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tid, teamId, aid, openDeleteRow, getRow])

	// Move the position of a column
	const onMoveCol = useCallback(
		(gDragIndex, gDropIndex) => {
			const { colIndex, col } = getColumn(gDragIndex.col)
			const dropIndex = getColumn(gDropIndex.col).colIndex
			dispatch(moveColumn({ tid, teamId, aid, dragId: col.id, dragIndex: colIndex, dropIndex }))
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[tid, teamId, aid, getColumn]
	)

	const onSearchPanelResize = useCallback(
		(size) => {
			dispatch(setInView({ aid, changes: [{ path: 'searchPanel.size', value: size }], permits }))
		},
		[aid, permits]
	)

	const onCardPanelResize = useCallback(
		(size) => {
			dispatch(setInView({ aid, changes: [{ path: 'cardPanel.size', value: size }], permits }))
		},
		[aid, permits]
	)

	const onSetActiveCell = useCallback(
		(cell) => {
			if (!cell) setActiveRow(null)
			else {
				const { rowIndex } = getRow(cell.row)
				setActiveRow(rowIndex)
			}
		},
		[getRow]
	)

	// Elements to pass directly to the grid's cell. This is used for data and functions that are not standard of the grid component
	const onChangeCellRef = useRef(onChangeCell)
	const onTableTypeRef = useRef(onOpenTableType)
	const onSearchColumnRef = useRef(onSearchColumn)
	const onCancelSearchColumnRef = useRef(onCancelSearchColumn)
	const onChangeFileRef = useRef(onChangeFile)
	onChangeCellRef.current = onChangeCell
	onTableTypeRef.current = onOpenTableType
	onSearchColumnRef.current = onSearchColumn
	onCancelSearchColumnRef.current = onCancelSearchColumn
	onChangeFileRef.current = onChangeFile

	const gridItemData = useMemoObject({
		rowCount,
		canEdit,
		searchResults: variableSearch,
		isDesigning,
		fileTransmit,
		// Functions
		onChangeCellRef,
		onTableTypeRef,
		onSearchColumnRef,
		onCancelSearchColumnRef,
		onChangeFileRef
	})

	// function handleTourCallback(data) {
	// 	const { status } = data
	// 	const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED]

	// 	if (finishedStatuses.includes(status)) {
	// 		setRun(false)
	// 		dispatch(updateUser({ tid, uid, content: { tableTour: true } }))
	// 	}
	// }

	const PanelContent = () => {
		if (aid === 'Sg3fgKSvZp6pZ9nV5IMg' || aid === 'nsjngrd8JgibMdVjKBOS' || aid === 'AtKT7yxX0fQjWf4kW3p0' || aid === 'ynuiFW2O82u5EOKBf1WO') return <DemoSeq aid={aid} />
		else return <TableCard aid={aid} rowIndex={activeRow} rowData={data && activeRow != null ? data[activeRow] : null} tabs={tabs} variables={variables} />
	}

	return (
		<div className="flex flex-col relative flex-1 overflow-hidden overscroll-none" ref={wrapperRef}>
			{/* <TableTour run={run} callback={handleTourCallback} /> */}
			<PanelLayout config={searchPanel} blockScroll content={<TableSearch aid={aid} filter={filter} sort={sort} />} onResize={onSearchPanelResize}>
				{/* <PanelLayout
					config={cardPanel}
					blockScroll
					content={<TableCard aid={aid} rowIndex={activeRow} rowData={data && activeRow != null ? data[activeRow] : null} tabs={tabs} variables={variables} />}
					onResize={onCardPanelResize}
				> */}
				<PanelLayout config={cardPanel} blockScroll content={<PanelContent />} onResize={onCardPanelResize}>
					<TableTabs aid={aid} isDesigning={isDesigning} />
					{!isDataLoaded && <LinearProgress classes={{ colorPrimary: 'bg-primary', barColorPrimary: 'bg-primaryDark' }} />}
					{teamId && colCount > 0 && (
						<Grid
							items={data}
							columns={tabColumns}
							columnProps={colProps}
							rowCount={rowCount}
							colCount={colCount}
							defaultColWidth={TABLE_PARAMS.DEFAULT_COLUMN_WIDTH}
							defaultRowHeight={TABLE_PARAMS.DEFAULT_ROW_HEIGHT}
							canPasteExceedGrid={true}
							expandDirection="vertical"
							onColumnResizeFunction={onColumnResize}
							onDropFunction={onMoveCol}
							onExpandCell={onExpandCell}
							expandStickyCol={true}
							hasInsertRow={true}
							data={gridItemData}
							getCellTypeFunction={getCellType}
							disableCellFunction={disableCell}
							getContextMenuFunction={cellContextMenu}
							Cell={Cell}
							CellStickyColumn={CellStickyColumn}
							CellStickyRow={CellStickyRow}
							CellStickyTopLeft={CellStickyTopLeft}
							showAddRowButton={true}
							onGridSizeChange={onGridSizeChange}
							onGridItemsRendered={onGridItemsRendered}
							onPasteFunction={onPaste}
							onCopyFunction={onCopy}
							onDeleteFunction={onDelete}
							onSetActiveCell={onSetActiveCell}
						/>
					)}
					<Snackbar open={rowCount >= TABLE_PARAMS.GRID_MAX_ITEMS} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
						<Alert severity="info" className="text-sm whitespace-nowrap">
							{t('table:messages.tableLength', { num: TABLE_PARAMS.GRID_MAX_ITEMS })}
						</Alert>
					</Snackbar>
					<TableType
						position={tableType.position}
						onClose={() => onTableType(null, null)}
						onChangeVarId={(newVarId) => onTableType(tableType.position, newVarId)}
						aid={aid}
						varId={tableType.varId}
						variable={tableType.varId ? variables[tableType.varId] : null}
						variables={variables}
					/>
					<ActionConfirm
						open={Boolean(openDeleteRow)}
						title={t('table:messages.rowDeleteConfirm')}
						content={t('table:messages.rowDeleteConfirmContent')}
						onClose={() => setOpenDeleteRow(null)}
						onConfirm={onDeleteRow}
					/>
					<ActionConfirm
						open={Boolean(openDeleteColumn)}
						title={t('table:messages.colDeleteConfirm')}
						content={t('table:messages.colDeleteConfirmContent')}
						onClose={() => setOpenDeleteColumn(null)}
						onConfirm={onDeleteColumn}
						isLoading={deleteColumnLoading}
					/>
				</PanelLayout>
			</PanelLayout>
			<AlertDialog messageKey={`downloadCSV#${aid}`} />
		</div>
	)
}
