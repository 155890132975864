import { createSelector } from '@reduxjs/toolkit'
import { FREQUENCY } from 'common/constants/frequencies'
import { fromDateToDateTime, fromTimestampToDate, getDatePeriodStart } from 'common/utils/dates'
import { prepareVarFilter } from 'common/utils/views'
import { DateTime } from 'luxon'
import * as modelSel from 'model/store/modelSelector'
import * as tableSel from 'table/store/tableSelector'
import * as chartSel from 'model/store/chartSelector'
import * as userSel from 'common/store/userSelector'
import { PAGE_SIZE } from 'model/constants/modelParameters'

// #### DIRECT SELECTORS
// Current view
export const selectViewId = (state, aid) => state.view.current[aid]?.id
export const selectViewType = (state, aid) => state.view.current[aid]?.type
export const selectViewName = (state, aid) => state.view.current[aid]?.data?.name
export const selectViewColProps = (state, aid) => state.view.current[aid]?.data?.colProps
export const selectViewSearchPanel = (state, aid) => state.view.current[aid]?.data?.searchPanel
export const selectViewLoaded = (state, aid) => state.view.current[aid]?.loaded
export const selectViewCellSplit = (state, aid) => state.view.current[aid]?.data?.cellSplit
// Current view model properties
export const selectViewGroups = (state, aid) => state.view.current[aid]?.data?.groups
export const selectViewVariables = (state, aid) => state.view.current[aid]?.data?.variables
export const selectViewChartPanel = (state, aid) => state.view.current[aid]?.data?.chartPanel
export const selectViewDates = (state, aid) => state.view.current[aid]?.data?.dates
export const selectViewDatesFrequency = (state, aid) => state.view.current[aid]?.data?.dates?.frequency
export const selectViewItemsPerPage = (state, aid) => state.view.current[aid]?.data?.pagination?.itemsPerPage || PAGE_SIZE
export const selectViewPages = (state, aid) => state.view.current[aid]?.data?.pagination?.page
// Current view table properties
export const selectViewFilter = (state, aid) => state.view.current[aid]?.data?.filter
export const selectViewSort = (state, aid) => state.view.current[aid]?.data?.sort
export const selectViewCardPanel = (state, aid) => state.view.current[aid]?.data?.cardPanel
// Current view variables
export const selectViewVariable = (state, aid, vid) => (state.view.current[aid]?.data?.variables ? state.view.current[aid]?.data?.variables[vid] : null)
export const selectViewVarBreakdown = (state, aid, vid) => (state.view.current[aid]?.data?.variables ? state.view.current[aid]?.data?.variables[vid]?.breakdown : null)
export const selectViewVarIsOpen = (state, aid, vid) => (state.view.current[aid]?.data?.variables ? state.view.current[aid]?.data?.variables[vid]?.isOpen : false)
export const selectViewVarHideZeros = (state, aid, vid) => (state.view.current[aid]?.data?.variables ? state.view.current[aid]?.data?.variables[vid]?.hideZeros : false)
export const selectViewVarHideNulls = (state, aid, vid) => (state.view.current[aid]?.data?.variables ? state.view.current[aid]?.data?.variables[vid]?.hideNulls : false)
// Current view charts
export const selectViewChartBreakdown = (state, aid, chartId, vid) =>
	state.view.current[aid]?.data?.charts && state.view.current[aid]?.data?.charts[chartId]?.variables ? state.view.current[aid]?.data?.charts[chartId]?.variables[vid]?.breakdown : null
export const selectViewChartPage = (state, aid, chartId, vid) =>
	state.view.current[aid]?.data?.charts && state.view.current[aid]?.data?.charts[chartId]?.variables ? state.view.current[aid]?.data?.charts[chartId]?.variables[vid]?.page : null
// Shared and personal views
export const selectSharedViews = (state, aid) => state.view.shared[aid]
export const selectPersonalViews = (state, aid) => state.view.personal[aid]

// #### DERIVATIVE SELECTORS
export const makeSelectSharedInUse = ({ aid }) => {
	const _selectViewId = (state) => selectViewId(state, aid)
	const _selectSharedViews = (state) => selectSharedViews(state, aid)

	const selectSharedInUse = createSelector([_selectViewId, _selectSharedViews], (viewId, sharedViews) => {
		return viewId != null && sharedViews?.loaded && sharedViews?.result != null ? sharedViews?.result[viewId] : null
	})
	return selectSharedInUse
}

export const makeSelectPersonalInUse = ({ aid }) => {
	const _selectViewId = (state) => selectViewId(state, aid)
	const _selectPersonalViews = (state) => selectPersonalViews(state, aid)

	const selectPersonalInUse = createSelector([_selectViewId, _selectPersonalViews], (viewId, personalViews) => {
		return viewId != null && personalViews?.loaded && personalViews?.result != null ? personalViews?.result[viewId] : null
	})
	return selectPersonalInUse
}

export const makeSelectVarBreakdown = ({ aid, vid }) => {
	const _selectModelVarCategories = (state) => modelSel.selectModelVarCategories(state, aid, vid)
	const _selectViewVarBreakdown = (state) => selectViewVarBreakdown(state, aid, vid)

	const selectMergedBreakdown = createSelector([_selectModelVarCategories, _selectViewVarBreakdown], (varCategories, viewBreakdown) => {
		var newItems = []
		var included = {}
		var isActive = viewBreakdown?.some((el) => el.active) || false
		const varCategoryIds = varCategories?.map((el) => el.id)

		viewBreakdown?.forEach((el) => {
			if (varCategoryIds?.includes(el.catId)) {
				newItems.push({ ...el, active: el.active || !isActive })
				included[el.catId] = true
				if (el.active) isActive = true
			}
		})
		varCategoryIds?.forEach((catId) => !included[catId] && newItems.push({ catId, active: !isActive }))

		return newItems
	})
	return selectMergedBreakdown
}

export const makeSelectChartBreakdown = ({ aid, cid, vid }) => {
	const _selectModelVarCategories = (state) => modelSel.selectModelVarCategories(state, aid, vid)
	const _selectViewChartBreakdown = (state) => selectViewChartBreakdown(state, aid, cid, vid)

	const selectMergedBreakdown = createSelector([_selectModelVarCategories, _selectViewChartBreakdown], (varCategories, viewBreakdown) => {
		var newItems = []
		var included = {}
		const varCategoryIds = varCategories?.map((el) => el.id)

		viewBreakdown?.forEach((el) => {
			if (varCategoryIds?.includes(el.catId)) {
				newItems.push({ ...el, active: el.active })
				included[el.catId] = true
			}
		})
		varCategoryIds?.forEach((catId) => !included[catId] && newItems.push({ catId, active: false }))

		return newItems
	})
	return selectMergedBreakdown
}

export const makeSelectTableFilter = ({ aid }) => {
	const _selectTableColumns = (state) => tableSel.selectTableColumns(state, aid)
	const _selectViewFilter = (state) => selectViewFilter(state, aid)

	const selectTableFilter = createSelector([_selectTableColumns, _selectViewFilter], (columns, filter) => {
		const result = prepareVarFilter(columns, filter, (el) => el.id)
		return result
	})
	return selectTableFilter
}

export const makeSelectTableSort = ({ aid }) => {
	const _selectTableColumns = (state) => tableSel.selectTableColumns(state, aid)
	const _selectViewSort = (state) => selectViewSort(state, aid)

	const selectTableSort = createSelector([_selectTableColumns, _selectViewSort], (columns, sort) => {
		return prepareVarFilter(columns, sort, (el) => el.id)
	})
	return selectTableSort
}

export const makeSelectVizDates = ({ aid }) => {
	const _selectModelIsDesigning = (state) => modelSel.selectModelIsDesigning(state, aid)
	const _selectModelDates = (state) => modelSel.selectModelDates(state, aid)
	const _selectViewDates = (state) => selectViewDates(state, aid)

	const selectVizDates = createSelector([_selectModelIsDesigning, _selectModelDates, _selectViewDates], (isDesigning, modelDates, viewDates) => {
		let startDate = !isDesigning && viewDates?.startDate ? viewDates.startDate : modelDates?.startDate ? modelDates.startDate : null
		let endDate = !isDesigning && viewDates?.endDate ? viewDates.endDate : modelDates?.endDate ? modelDates.endDate : null

		startDate = !startDate ? null : startDate instanceof Date ? fromDateToDateTime(startDate) : startDate instanceof DateTime ? startDate : fromTimestampToDate(startDate)
		endDate = !endDate ? null : endDate instanceof Date ? fromDateToDateTime(endDate) : endDate instanceof DateTime ? endDate : fromTimestampToDate(endDate)

		const frequency =
			!isDesigning && viewDates?.frequency != null && modelDates?.frequency != null && FREQUENCY[viewDates.frequency].index >= FREQUENCY[modelDates.frequency].index
				? viewDates.frequency
				: modelDates?.frequency
		return { startDate, endDate, frequency }
	})
	return selectVizDates
}

export const makeSelectChartVizDates = ({ aid, cid }) => {
	const _makeSelectLocale = userSel.makeSelectLocale({})
	const _selectLocale = (state) => _makeSelectLocale(state)
	const _makeSelectVizDates = makeSelectVizDates({ aid })
	const _selectVizDates = (state) => _makeSelectVizDates(state)
	const _selectChartStartDate = (state) => chartSel.selectChartStartDate(state, aid, cid)
	const _selectChartEndDate = (state) => chartSel.selectChartEndDate(state, aid, cid)
	const _selectChartFrequency = (state) => chartSel.selectChartFrequency(state, aid, cid)

	const selectVizDates = createSelector([_selectLocale, _selectVizDates, _selectChartStartDate, _selectChartEndDate, _selectChartFrequency], (locale, vDates, cStartDate, cEndDate, cFrequency) => {
		let startDate = cStartDate || vDates?.startDate
		let endDate = cEndDate || vDates?.endDate
		const frequency = cFrequency != null && FREQUENCY[cFrequency].index >= FREQUENCY[vDates.frequency].index ? cFrequency : vDates?.frequency

		// convert format to DateTime
		startDate = !startDate ? null : startDate instanceof Date ? fromDateToDateTime(startDate) : startDate instanceof DateTime ? startDate : fromTimestampToDate(startDate)
		endDate = !endDate ? null : endDate instanceof Date ? fromDateToDateTime(endDate) : endDate instanceof DateTime ? endDate : fromTimestampToDate(endDate)

		// adjust startDate in case frequency has changed
		if (startDate && frequency) startDate = getDatePeriodStart(startDate, frequency, locale?.locale).toUTC()

		return { startDate, endDate, frequency, locale }
	})
	return selectVizDates
}

export const makeSelectViewVarPage = ({ aid, vid }) => {
	const _selectViewVariable = (state) => selectViewVariable(state, aid, vid)
	const _selectViewPages = (state) => selectViewPages(state, aid)

	const selectVarPage = createSelector([_selectViewVariable, _selectViewPages], (viewVar, viewPages) => {
		if (!viewVar || !viewVar.isOpen || !viewPages) return { key: null, page: 0 }

		// If hideZeros or hideNulls is enables, we need to handle page independently
		const hideZeros = viewVar.hideZeros || false
		const hideNulls = viewVar.hideNulls || false
		if (hideZeros || hideNulls) {
			const viewPage = viewVar?.page
			if (!viewPage) return { key: null, page: 0 }
			else return { key: null, page: viewPage }
		}

		// Else, we sync page across all variables with same breakdown
		const key = viewVar?.breakdown?.filter((el) => el.active)?.reduce((acum, value) => `${acum}${acum?.length > 0 ? '#' : ''}${value.catId}`, '')
		const page = viewPages[key] != null ? viewPages[key] : 0
		return { key, page }
	})
	return selectVarPage
}
