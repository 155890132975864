import { DateTime } from 'luxon'
import CreateDateCell from 'common/components/grid/cells/CreateDateCell'
import { DATE_FORMATS, TIME_FORMATS } from 'common/constants/formats'
import { LOCALE_DATE_MASK } from 'common/constants/locales'
import { printDateWithProps } from 'common/utils/dates'

export const formatDate = ({ value, typeProps, localeFallback }) => {
	if (!value || value === '') return ''
	const date = DateTime.fromISO(value).toUTC()
	if (!date) return ''
	return printDateWithProps(date, typeProps, localeFallback)
}

export const parseDate = ({ value, typeProps }) => {
	// Set non-selected date parameters to zero. Note: 1970 is the year that sets millisecons to zero
	const hasDate = typeProps?.dateFormat
	const hasTime = typeProps?.includeTime
	const timeProps = typeProps?.timeFormat ? TIME_FORMATS[typeProps.timeFormat] : null
	const hasSeconds = hasTime && timeProps?.hasSeconds
	const newDate = DateTime.utc(hasDate ? value.year : 1970, hasDate ? value.month : 1, hasDate ? value.day : 1, hasTime ? value.hour : 0, hasTime ? value.minute : 0, hasSeconds ? value.second : 0, 0)
	return newDate
}

export const pasteDate = ({ value, typeProps, localeFallback }) => {
	const hasDate = typeProps?.dateFormat
	const hasTime = typeProps?.includeTime
	const dateProps = typeProps?.dateFormat ? DATE_FORMATS[typeProps.dateFormat] : null
	const timeProps = typeProps?.timeFormat ? TIME_FORMATS[typeProps.timeFormat] : null
	const hasSeconds = hasTime && timeProps?.hasSeconds
	const locale = dateProps?.locale || localeFallback
	const dateMask = LOCALE_DATE_MASK[locale]
	const mask = `${hasDate ? dateMask.mask : ''}${hasDate && hasTime ? ' ' : ''}${hasTime ? timeProps.mask : ''}`

	// Convert string to datetime
	const date = DateTime.fromFormat(value, mask, { locale })
	if (!date || isNaN(date)) return null

	// Reset non-used date fields
	const newDate = DateTime.utc(hasDate ? date.year : 1970, hasDate ? date.month : 1, hasDate ? date.day : 1, hasTime ? date.hour : 0, hasTime ? date.minute : 0, hasSeconds ? date.second : 0, 0)
	const dateUTC = newDate.toUTC()
	return dateUTC
}

export const cellDate = {
	component: CreateDateCell,
	config: {
		alignRight: false,
		formatBlurredInput: formatDate,
		formatCopyValue: formatDate,
		parseUserInput: parseDate,
		parsePastedValue: pasteDate
	}
}
