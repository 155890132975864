import i18n from 'common/config/i18n'
import { DATA_TYPES } from '../../common/constants/dataTypes'

export const TABLE_PARAMS = {
	DEFAULT_COLUMN_WIDTH: 200,
	DEFAULT_ROW_HEIGHT: 40,
	DEFAULT_FIRST_COLUMN_WIDTH: 250,
	NEW_ROW_ID: '_newRow',
	NEW_COL_ID: '_newCol',
	HEAD_ROW_ID: '_head',
	HEAD_COL_ID: '_main',
	HEAD_TAB_ID: '_all',
	LIST_MAX_LENGTH: 50,
	GRID_MAX_ITEMS: 5000,
	SEARCH_PANEL_SIZE: 20,
	CARD_PANEL_SIZE: 40
}

export const TABLE_NEW = {
	tabs: [{ id: TABLE_PARAMS.HEAD_TAB_ID, name: i18n.t('table:defaults.mainTab'), variables: [TABLE_PARAMS.HEAD_COL_ID, '_text', '_num'] }],
	variables: {
		[TABLE_PARAMS.HEAD_COL_ID]: {
			id: TABLE_PARAMS.HEAD_COL_ID,
			label: i18n.t('table:defaults.variableName'),
			tabId: TABLE_PARAMS.HEAD_TAB_ID,
			type: DATA_TYPES.text.key,
			typeProps: DATA_TYPES.text.defaultProps,
			backendType: DATA_TYPES.text.backendType
		},
		_text: {
			id: '_text',
			label: i18n.t('table:defaults.variableDesc'),
			tabId: TABLE_PARAMS.HEAD_TAB_ID,
			type: DATA_TYPES.text.key,
			typeProps: DATA_TYPES.text.defaultProps,
			backendType: DATA_TYPES.text.backendType
		},
		_num: {
			id: '_num',
			label: i18n.t('table:defaults.variableValue'),
			tabId: TABLE_PARAMS.HEAD_TAB_ID,
			type: DATA_TYPES.num.key,
			typeProps: DATA_TYPES.num.defaultProps,
			backendType: DATA_TYPES.num.backendType
		}
	}
}

export const TABLE_NEW_VIEW = {
	name: i18n.t('common:views.defaultName')
}

export const TABLE_NEW_VAR = { label: i18n.t('table:defaults.newVariable'), type: DATA_TYPES.text.key, typeProps: DATA_TYPES.text.defaultProps, backendType: DATA_TYPES.text.backendType }
