import { Popover, Tab, Tabs, IconButton } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close'
import TableTypeSelection from 'table/screens/type/TableTypeSelection'
import TableTypeSettings from 'table/screens/type/TableTypeSettings'

export default function TableType({ position, onClose, onChangeVarId, aid, varId, variable, variables, isModelVersion = false }) {
	const { t } = useTranslation(['common', 'table'])

	// #### POPOVER CONTROL
	const isPopoverOpen = Boolean(position)

	// #### STATE
	const [tab, setTab] = useState(0)

	return (
		<Popover
			open={isPopoverOpen}
			anchorReference="anchorPosition"
			anchorPosition={position ? { top: position.y, left: position.x } : null}
			onClose={onClose}
			anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
			transformOrigin={{ vertical: 'top', horizontal: 'left' }}
			classes={{ paper: 'flex w-[350px] h-[400px] shadow-md overflow-y-hidden' }}
		>
			{isPopoverOpen && (
				<div className="flex-1 flex flex-col">
					<div className="flex flex-row justify-between items-center border-b border-b-borderGray p-2 pl-4">
						<span className="text-md font-medium text-textGray">{isModelVersion ? t('model:menu.format') : t('table:typeSelect.title')}</span>
						<IconButton size="small" className="text-textGray" onClick={onClose}>
							<CloseIcon fontSize="small" />
						</IconButton>
					</div>
					<Tabs value={tab} variant="fullWidth" onChange={(e, value) => setTab(value)} orientation="horizontal" className="tabs shrink-0" classes={{ indicator: 'tabsIndicator' }}>
						<Tab label={t('table:typeSelect.selection')} className="tab" classes={{ selected: 'tabSelected' }} />
						<Tab label={t('table:typeSelect.settings')} className="tab" classes={{ selected: 'tabSelected' }} />
					</Tabs>
					<div className="flex-1 px-4 overflow-y-scroll flex flex-col">
						{tab === 0 ? (
							<TableTypeSelection aid={aid} varId={varId} variable={variable} variables={variables} isModelVersion={isModelVersion} onChangeVarId={onChangeVarId} onClose={onClose} />
						) : (
							<TableTypeSettings aid={aid} varId={varId} variable={variable} isModelVersion={isModelVersion} />
						)}
					</div>
				</div>
			)}
		</Popover>
	)
}
