// #### SUBSCRIBE TO ASSETS
export const LOAD_SOURCES = 'sourceSaga/LOAD_SOURCES'
export const CANCEL_SOURCES = 'sourceSaga/CANCEL_SOURCES'
// #### API MANAGEMENT
// Requests from screens
export const CREATE_REQ = 'sourceSaga/CREATE'
export const UPDATE_REQ = 'sourceSaga/UPDATE'
export const CHECK_REQ = 'sourceSaga/CHECK'
export const DISCOVER_REQ = 'sourceSaga/DISCOVER'
export const REMOVE_REQ = 'sourceSaga/REMOVE'
// Responses used in helpers (not implemented here)
export const CREATE_RES = 'sourceSaga/CREATE_RES'
export const CHECK_RES = 'sourceSaga/CHECK_RES'
export const DISCOVER_RES = 'sourceSaga/DISCOVER_RES'
export const UPDATE_RES = 'sourceSaga/UPDATE_RES'

// #### SUBSCRIBE TO COLLECTION
// #### SUBSCRIBE TO ASSETS
export const loadSources = ({ tid, teamId }) => {
	return { type: LOAD_SOURCES, tid, teamId }
}

export const cancelSources = () => {
	return { type: CANCEL_SOURCES }
}

// #### API MANAGEMENT
// Requests from screens
export const createSource = ({tid, teamId, uid, source, config}) => {
	return { type: CREATE_REQ, tid, teamId, uid, source, config }
}

export const updateSource = ({tenantId, teamId, sourceId, source, config}) => {
	return { type: UPDATE_REQ, tenantId, teamId, sourceId, source, config }
}

export const check = ({tid, teamId, sourceId}) => {
	return { type: CHECK_REQ, tid, teamId, sourceId }
}

export const discover = ({teamId, sourceId}) => {
	return { type: DISCOVER_REQ, teamId, sourceId }
}

export const deleteSource = ({tenantId, teamId, sourceId}) => {
	return { type: REMOVE_REQ, tenantId, teamId, sourceId }
}
