// #### MANAGE CATEGORIES
export const CREATE_CATEGORY = 'modelCategorySaga/CREATE_CATEGORY'
export const DELETE_CATEGORY = 'modelCategorySaga/DELETE_CATEGORY'
export const CREATE_RELATIONSHIP = 'modelCategorySaga/CREATE_RELATIONSHIP'
export const DELETE_RELATIONSHIP = 'modelCategorySaga/DELETE_RELATIONSHIP'

// #### MANAGE CATEGORIES
export const createCategory = ({ tid, teamId, aid, categoryId, content, isNew }) => {
	return { type: CREATE_CATEGORY, tid, teamId, aid, categoryId, content, isNew }
}

export const deleteCategory = ({ tid, teamId, aid, categoryId, selectTable }) => {
	return { type: DELETE_CATEGORY, tid, teamId, aid, categoryId, selectTable }
}

export const createRelationship = ({ tid, aid, relationId, content, isNew }) => {
	return { type: CREATE_RELATIONSHIP, tid, aid, relationId, content, isNew }
}

export const deleteRelationship = ({ tid, aid, relationId, selectTable }) => {
	return { type: DELETE_RELATIONSHIP, tid, aid, relationId, selectTable }
}